export const initial = 'initial';
export const tiny = 'ty';
export const small = 'sm';
export const medium = 'md';
export const large = 'lg';
export const giant = 'gt';

// vertical ipad
export const verticalIpad = 'ip';

export const breakpoints = {
    initial,
    tiny,
    small,
    medium,
    large,
    giant,

    // vertical ipad
    verticalIpad
};

export const breakpointValues = {
    initial: 0,
    tiny: 370,
    small: 450,
    medium: 800,
    large: 1100,
    giant: 2160,

    // vertical ipad
    verticalIpad: 768
};

export const FORM_MAX_WIDTH = '750px';

// Use http://chir.ag/projects/name-that-color to name unknown colors
export const theme = {
    color: {
        monza: '#d0021b',
        milanoRed: '#d81e05',
        pink: '#ffbccb',
        shockingPink: '#ff00b6',
        bone: '#dfc7b7',
        black: '#000000',
        codGray: '#1d1d1d',
        mineShaft: '#333333',
        warmGrey: '#767676',
        adaGrey: '#757575',
        silverChalice: '#a1a1a1',
        dustyGrey: '#9b9b9b',
        alto: '#d8d8d8',
        alto2: '#dadada',
        concrete: '#f2f2f2',
        mercury: '#e6e6e6',
        white: '#ffffff',
        seriousGray: '#616161',
        transparent: 'rgba(0, 0, 0, 0)',
        whiteTransparent: 'rgba(255, 255, 255, 0.8)',
        whiteGradient: 'linear-gradient(to bottom, rgba(255,255,255,1) 87%,rgba(255,255,255,0.96) 89%,rgba(255,255,255,0.89) 92%,rgba(255,255,255,0.86) 94%,rgba(255,255,255,0.81) 7%,rgba(255,255,255,0) 100%)',
        grey: '#878787',
        gallery: '#ececec',
        default: 'inherit',
        lightGray: '#d7d7d7',
        errorRed: '#e01a2e',
        red: '#ff0000',
        blackOverlay: '#00000082',
        loaderGray: '#f9f9f9',
        tundora: '#4a4a4a',
        finn: '#5f2950',
        killarney: '#31662b',
        bianca: '#faf6eb',
        wildSand: '#f6f6f6',
        cherokee: '#fbd68f',
        sundance: '#d2ae63',
        copper: '#b6823a'
    },
    font: {
        ABChanelPBL: "ABChanelPBL, 'Helvetica Neue', sans-serif",
        ABChanelPBM: "ABChanelPBM, 'Helvetica Neue', sans-serif",
        ABChanelPBS: "ABChanelPBS, 'Helvetica Neue', sans-serif",
        ABChanelCorpo: "ABChanelCorpo, 'Helvetica Neue', sans-serif",
        ChanelFolioBol: "ChanelFolioBol, 'Helvetica Neue', sans-serif",
        ChanelFolioMed: "ChanelFolioMed, 'Helvetica Neue', sans-serif",
        ChanelFolioLig: "ChanelFolioLig, 'Helvetica Neue', sans-serif",
        SofiaPro: "SofiaPro, 'Helvetica Neue', sans-serif",
        CervoNeueNeue: "CervoNeue-Neue, 'Helvetica Neue', sans-serif",
        CervoNeueNeueThin: "CervoNeue-Neue-Thin, 'Helvetica Neue', sans-serif",
        Chromosome: "'Chromosome', sans-serif",
        default: "'Helvetica Neue', sans-serif"
    },
    breakpoints: {
        [breakpoints.initial]: `${breakpointValues.initial}px`,
        [breakpoints.tiny]: `${breakpointValues.tiny}px`,
        [breakpoints.small]: `${breakpointValues.small}px`,
        [breakpoints.medium]: `${breakpointValues.medium}px`,
        [breakpoints.large]: `${breakpointValues.large}px`,
        [breakpoints.giant]: `${breakpointValues.giant}px`, // Touchscreen
        [breakpoints.verticalIpad]: `${breakpointValues.verticalIpad}px` // vertical ipad
    },
    global: {
        rootFontSize: 16,
        maxWidth: '1440px',
        headerHeight: '60px',
        desktopHeaderHeight: '80px',
        headerShrinkTransition: 'all 0.6s;'
    }
};
