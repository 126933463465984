import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { PrimaryModal } from 'components/GlobalComponents/Modal/PrimaryModal';
import { Flex } from 'components/GlobalComponents/FlexBox';
import { fetchEventListing } from 'reducers/events';
import Loader from 'components/GlobalComponents/Loader/Loader';
import { greaterThan, rem, styledProps } from 'core/styled';
import Button from 'components/GlobalComponents/Button/Button';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { getEventDatas } from 'constants/events';
import { breakpoints } from 'constants/theme';

const DEFAULT_IMAGE = 'https://images.prismic.io/atelier/d73574e1-2d6f-411d-83bc-ee40cdc71536_The+Atelier.jpg?auto=compress,format';

const ModalHeader = styled.h3`
    margin: ${rem(0)};
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-weight: 400;
    font-size: ${rem(20)};
    text-align: center;
    padding: ${rem(30, 0, 20)};
`;

const ResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${greaterThan(breakpoints.verticalIpad)(css`
        flex-wrap: wrap;
        flex-direction: row;
    `)}
`;

const ResultItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: ${rem(20, 10)};
    margin-bottom: ${rem(20)};
    position: relative;

    p.bp--eventmemberonly {
        position: absolute;
        top: ${rem(5)};
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        color: ${styledProps('color', 'milanoRed')};
        font-size: ${rem(10)};
    }

    img {
        width: ${rem(150)};
        height: ${rem(150)};
        object-fit: cover;
        margin-bottom: ${rem(10)};
    }

    p.bp--eventname {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        font-size: ${rem(14)};
        margin: ${rem(2, 0, 0)};
    }

    p.bp--eventdatetime {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(10)};
        margin: ${rem(10, 0, 0)};
    }

    p.bp--eventwith {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(10)};
        margin: ${rem(2, 0, 0)};
    }

    button {
        margin-top: ${rem(10)}
    }

    ${greaterThan(breakpoints.verticalIpad)(css`
        width: 50%;
    `)}
`;

class SimpleSubSectionEventModal extends Component {

    static propTypes = {
        getSectionIndex: PropTypes.func.isRequired,
        isFetching: PropTypes.bool.isRequired,
        addEvent: PropTypes.func.isRequired,
        events: PropTypes.array.isRequired,
        fetchEventListing: PropTypes.func.isRequired
    };

    state = {
        showModal: false,
        events: []
    };

    constructor(props) {
        super(props);

        props.fetchEventListing(true).then(() => {
            this.setState({
                events: this.getFilteredEvents()
            });
        });
    }

    addEvent = event => {
        // console.log(event)
        this.props.addEvent(event);
        this.toggleModal();
    };

    getFilteredEvents = () => {
        const { events } = this.props;

        const eventDatas = getEventDatas();

        return events.filter((event) => !event.hidden)
            .map(event => ({
                ...event,
                eventData: eventDatas[event.detailId]
            }));
    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    renderEvent = (event, index) => {
        const eventData = event.eventData;

        const image = eventData && eventData.obsess_image && eventData.obsess_image.url ?
            eventData.obsess_image.url : DEFAULT_IMAGE;

        const isMemberOnly = eventData && eventData.service_type && eventData.service_type === 'member_only';
        const artist = eventData && eventData.event_artist_name ? eventData.event_artist_name : event.staffName;
        const eventType = eventData ? eventData.new_listing_event_sub_name : null;
        const date = `${event.weekDay}, ${event.month} ${event.day}`;
        const name = event.name.replace(/N5/g, 'N°5').replace(/N1/g, 'N°1');

        const eventDataToStore = {
            detailId: event.detailId,
            eventId: event.id,
            staffName: artist,
            name,
            date
        };

        return (
            <ResultItemWrapper key={index}>
                {isMemberOnly && <p className={'bp--eventmemberonly'}>Members only</p>}
                <img src={image} alt={''} />
                <p className={'bp--eventdatetime'}>{date}</p>
                <p className={'bp--eventname'}>{name}</p>
                {eventType && <p className={'bp--eventwith'}>{eventType}</p>}
                <p className={'bp--eventwith'}>With {artist}</p>
                <Button variant={'primary'} type={'button'} small onClick={() => this.addEvent(eventDataToStore)}>Add Event</Button>
            </ResultItemWrapper>
        );
    };

    renderEvents = () => {
        const { events } = this.state;

        return (
            <ResultsWrapper>
                {events.map(this.renderEvent)}
            </ResultsWrapper>
        );
    };

    render() {
        const { showModal, isFetching } = this.state;

        if (!showModal) {
            return null;
        }

        return (
            <PrimaryModal onRequestClose={this.toggleModal}
                showClose={true}
                flexBody={false}
                fullScreen={true}
                previousScrollDisabledState={false}
                preventScrollToTopOnOpen={true}>
                <Flex flexDirection='column' justifyContent='flex-start' alignItems={'center'}>
                    <ModalHeader>Recommend an event</ModalHeader>
                    {isFetching && <Loader />}
                    {!isFetching && this.renderEvents()}
                </Flex>
            </PrimaryModal>
        );
    }
}

const mapStateToProps = ({ events: { events, isFetching } }) => ({
    isFetching,
    events
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchEventListing
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SimpleSubSectionEventModal);
