import notifyNewrelic from './newrelic';

/**
 * Write log message to given output stream and append prefix
 * @param {file} f - File stream to write to
 * @param {string} prefix - Prefix to prepend to log statement [info,warn,error]
 * @param {string} msg - Log message
 * @param {any} data - Extra data such as exception or context info
 */
const write = (f, prefix, msg, data) => {
    if (data) {
        if (!(data instanceof Error)) {
            data = JSON.stringify(data, null, 4);
        }
        f(`${prefix}: ${msg}`, data);
    } else {
        f(`${prefix}: ${msg}`);
    }
};

/**
 * Log info level data
 * @param {string} msg
 * @param {any} data
 */
export function info(msg, data) {
    // eslint-disable-next-line no-console
    write(console.info, 'info', msg, data);
}


/**
 * Log error. In additon to outputting message, also sends it to Newrelic Browser as a JS error
 * @param {string} msg
 * @param {any} data
 */
export function error(msg, data) {
    notifyNewrelic(msg, data);
    // eslint-disable-next-line no-console
    write(console.error, 'error', msg, data);
}

const log = {
    info, error
};

export default log;
