import newBadge from 'assets/badges/new.svg';
import favortiesBadge from 'assets/badges/favorites.svg';
import newShadesBadge from 'assets/badges/new-shades.svg';
import newShadeBadge from 'assets/badges/new-shade.svg';

export const BADGE = {
    NEW: 'NEW',
    NEW_SHADES: 'NEW_SHADES',
    FAVORITES: 'FAVORITES',
    NEW_SHADE: 'NEW_SHADE'
};

export const BADGE_IMAGE = {
    [BADGE.NEW]: newBadge,
    [BADGE.NEW_SHADES]: newShadesBadge,
    [BADGE.FAVORITES]: favortiesBadge,
    [BADGE.NEW_SHADE]: newShadeBadge
};

export const BADGE_ALT = {
    [BADGE.NEW]: 'New',
    [BADGE.NEW_SHADES]: 'New Shades',
    [BADGE.FAVORITES]: 'Soho Favorite',
    [BADGE.NEW_SHADE]: 'New Shade'
};
