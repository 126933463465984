import { ZONES } from './zones';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';

// Curiosity Images
import MAKEUP_DARK from 'assets/questionnaire/curiosity/makeup-dark.png';
import BEIGE from 'assets/questionnaire/curiosity/beige.png';
import BEIGE_LIGHT from 'assets/questionnaire/curiosity/beige-light.png';
import NAIL_DRIP from 'assets/questionnaire/curiosity/nail-drip.png';
import SKINCARE_LIGHT from 'assets/questionnaire/curiosity/skincare-light.png';
import EYELINER from 'assets/questionnaire/curiosity/eyeliner.jpg';

// Lip Color Images
import NUDES from 'assets/questionnaire/lip-colors/nudes.png';
import PINKS from 'assets/questionnaire/lip-colors/pinks.png';
import BERRY from 'assets/questionnaire/lip-colors/berry.png';
import REDS from 'assets/questionnaire/lip-colors/reds.png';
import CORALS from 'assets/questionnaire/lip-colors/corals.png';

// Lip Finish Images
import MATTE from 'assets/questionnaire/lip-finishes/matte.png';
import SHIMMER from 'assets/questionnaire/lip-finishes/shimmer.png';
import SHEER from 'assets/questionnaire/lip-finishes/sheer.png';
import SATIN from 'assets/questionnaire/lip-finishes/satin.png';
import GLOSS from 'assets/questionnaire/lip-finishes/gloss.png';

// Eye Color Images
import BROWNS_BEIGE from 'assets/questionnaire/eye-colors/browns.png';
import PINKS_VIOLETS from 'assets/questionnaire/eye-colors/pinks.png';
import SILVERS_GREYS from 'assets/questionnaire/eye-colors/silvers.png';
import BRONZES_GOLDS from 'assets/questionnaire/eye-colors/bronzes.png';
import BLUES_NAVIES from 'assets/questionnaire/eye-colors/blues.png';

// Eye Finish Images
import EYE_MATTE from 'assets/questionnaire/eye-finishes/matte.png';
import EYE_SHIMMER from 'assets/questionnaire/eye-finishes/shimmer.png';
import EYE_METALLIC from 'assets/questionnaire/eye-finishes/metallic.png';
import EYE_GLITTER from 'assets/questionnaire/eye-finishes/glitter.png';

// Brows Images
import BROWS_GEL from 'assets/questionnaire/brows/gel.png';
import BROWS_PENCIL from 'assets/questionnaire/brows/pencil.png';
import BROWS_POWDER from 'assets/questionnaire/brows/powder.png';
import BROWS_GROWTH_SERUM from 'assets/questionnaire/brows/growth-serum.png';
import BROWS_CONDITIONER from 'assets/questionnaire/brows/conditioner.png';

// Brows Placeholder
import FULL_FACE from 'assets/questionnaire/brows-placeholder/full-face.jpg';
import FULL_BROW from 'assets/questionnaire/brows-placeholder/full-brow.jpg';
import LEFT_BROW from 'assets/questionnaire/brows-placeholder/left-brow.jpg';
import RIGHT_BROW from 'assets/questionnaire/brows-placeholder/right-brow.jpg';

// Nail Colors
import BALLERINA_BLACK from 'assets/questionnaire/nail-colors/ballerina-black.jpg';
import BALLERINA_VIBRATION from 'assets/questionnaire/nail-colors/ballerina-vibration.jpg';
import BLANK_WHITE_HARMONY from 'assets/questionnaire/nail-colors/blanc-white-harmony.jpg';
import DAYDREAM_ENERGY from 'assets/questionnaire/nail-colors/daydream-energy.jpg';
import MELODY_RHYTHM from 'assets/questionnaire/nail-colors/melody-rhythm.jpg';
import BALLERINA_PIRATE from 'assets/questionnaire/nail-colors/ballerina-pirate.png';
import EGERIE_CRUISE from 'assets/questionnaire/nail-colors/egerie-cruise.png';
import HARMONY_BLANCWHITE from 'assets/questionnaire/nail-colors/harmony-blancwhite.png';
import NEWDAWN_CANOTIER from 'assets/questionnaire/nail-colors/newdawn-canotier.png';
import VIBRATION_DAYDREAM from 'assets/questionnaire/nail-colors/vibration-daydream.png';

// Boy Eyebrow Pencils
import EYEBROW_LIGHT_BROWN from 'assets/questionnaire/boy-eyebrow-pencil/202-light-brown.png';
import EYEBROW_GREY from 'assets/questionnaire/boy-eyebrow-pencil/204-grey.jpg';
import EYEBROW_DEEP_BROWN from 'assets/questionnaire/boy-eyebrow-pencil/206-deep-brown.jpg';
import EYEBROW_BLACK from 'assets/questionnaire/boy-eyebrow-pencil/208-black.jpg';

// Stylo Ombre
import STYLO_OMBRE_BLACK from 'assets/questionnaire/stylo-ombre/stylo-ombre-black.png';
import STYLO_OMBRE_BROWN from 'assets/questionnaire/stylo-ombre/stylo-ombre-brown.png';
import STYLO_OMBRE_NAVY from 'assets/questionnaire/stylo-ombre/stylo-ombre-navy.png';

// Bronzing Cream
import BRONZING_CREAM_BRONZE from 'assets/questionnaire/bronzing-cream/bronzing-cream-bronze.jpg';
import BRONZING_CREAM_DEEP_BRONZE from 'assets/questionnaire/bronzing-cream/bronzing-cream-deep-bronze.jpg';

// Stylo Yeux
import STYLO_YEUX_NOIR_INTENSE_VERT_EMERAUDE from 'assets/questionnaire/stylo-yeux/stylo-yeux-noir-intense-vert-emeraude.png';
import STYLO_YEUX_PRUNE_INTENSE_EROS from 'assets/questionnaire/stylo-yeux/stylo-yeux-prune-intense-eros.png';

// Les 4 Ombres
import LES_4_OMBRES_BLURRY_GREY from 'assets/questionnaire/les-4-ombres/les-4-ombres-blurry-grey.png';
import LES_4_OMBRES_CANDEUR_ET_PROVOCATION from 'assets/questionnaire/les-4-ombres/les-4-ombres-candeur-et-provocation.png';
import LES_4_OMBRES_CLAIR_OBSCUR from 'assets/questionnaire/les-4-ombres/les-4-ombres-clair-obscur.png';

// Les Beiges
import LES_BEIGES_DEEP_BRONZE from 'assets/questionnaire/les-beiges/les-beiges-deep-bronze.png';
import LES_BEIGES_BRONZE from 'assets/questionnaire/les-beiges/les-beiges-bronze.png';

// Fragrance Questionnaire Images for preload
import bgMobile1 from 'assets/fragranceExperience/fragrance-xp-question-1-bg-mobile.jpg';
import bgDesktop1 from 'assets/fragranceExperience/fragrance-xp-question-1-bg-vines-desktop.png';
import flowerMobile1 from 'assets/fragranceExperience/fragrance-xp-question-1-bg-flowers-mobile.png';
import flowerDesktop1 from 'assets/fragranceExperience/fragrance-xp-question-1-bg-flowers-desktop.png';
import bgMobile2 from 'assets/fragranceExperience/fragrance-xp-question-2-bg-vines-mobile.jpg';
import bgDesktop2 from 'assets/fragranceExperience/fragrance-xp-question-2-bg-vines-desktop.png';
import flowerMobile2 from 'assets/fragranceExperience/fragrance-xp-question-2-bg-flowers-mobile.png';
import flowerDesktop2 from 'assets/fragranceExperience/fragrance-xp-question-2-bg-flowers-desktop.png';
import bgMobile3 from 'assets/fragranceExperience/fragrance-xp-question-3-bg-vines-mobile.jpg';
import bgDesktop3 from 'assets/fragranceExperience/fragrance-xp-question-3-bg-vines-desktop.jpg';
import flowerMobile3 from 'assets/fragranceExperience/fragrance-xp-question-3-bg-flowers-mobile.png';
import flowerDesktop3 from 'assets/fragranceExperience/fragrance-xp-question-3-bg-flowers-desktop.png';
import bgMobile4 from 'assets/fragranceExperience/fragrance-xp-question-4-bg-vines-mobile.png';
import bgDesktop4 from 'assets/fragranceExperience/fragrance-xp-question-4-bg-vines-desktop.png';
import flowerMobile4 from 'assets/fragranceExperience/fragrance-xp-question-4-bg-flowers-mobile.png';
import flowerDesktop4 from 'assets/fragranceExperience/fragrance-xp-question-4-bg-flowers-desktop.png';
import bgMobile5 from 'assets/fragranceExperience/fragrance-xp-question-5-bg-vines-mobile.jpg';
import bgDesktop5 from 'assets/fragranceExperience/fragrance-xp-question-5-bg-vines-desktop.jpg';
import flowerMobile5 from 'assets/fragranceExperience/fragrance-xp-question-5-bg-flowers-mobile.png';
import flowerDesktop5 from 'assets/fragranceExperience/fragrance-xp-question-5-bg-flowers-desktop.png';
import bgMobile6 from 'assets/fragranceExperience/fragrance-xp-question-6-bg-vines-mobile.png';
import bgDesktop6 from 'assets/fragranceExperience/fragrance-xp-question-6-bg-vines-desktop.png';
import flowerMobile6 from 'assets/fragranceExperience/fragrance-xp-question-6-bg-flowers-mobile.png';
import flowerDesktop6 from 'assets/fragranceExperience/fragrance-xp-question-6-bg-flowers-desktop.png';
import bgMobile7 from 'assets/fragranceExperience/fragrance-xp-question-7-bg-vines-mobile.png';
import bgDesktop7 from 'assets/fragranceExperience/fragrance-xp-question-7-bg-vines-desktop.png';
import flowerMobile7 from 'assets/fragranceExperience/fragrance-xp-question-7-bg-flowers-mobile.png';
import flowerDesktop7 from 'assets/fragranceExperience/fragrance-xp-question-7-bg-flowers-desktop.png';
import singleVineDesktop2 from 'assets/fragranceExperience/fragrance-xp-question-2-single-vine-desktop.png';
import singleFlowerDesktop2 from 'assets/fragranceExperience/fragrance-xp-question-2-single-flower-desktop.png';

import { isGreaterThan } from 'core/styled';
import { breakpoints } from './theme';
import preloadImages from 'core/utils/preloadImages';
import { ROUTES } from './routes';
import { DISCOVER_TABS } from './discover';

export const QUESTIONNAIRE_TYPES = {
    getUrl: (id) => {
        return id.toLowerCase().replace(/_/g, '-');
    },
    getId: (url) => {
        return url.toUpperCase().replace(/-/g, '_');
    },
    MAKEUP_SKINCARE: {
        id: 'MAKEUP_SKINCARE',
        url: 'makeup-skincare'
    },
    MAKEUP_APPLICATION: {
        id: 'MAKEUP_APPLICATION',
        url: 'makeup-application'
    },
    SKIN_CHAT: {
        id: 'SKIN_CHAT',
        url: 'skin-chat'
    },
    MAKEUP_CHAT: {
        id: 'MAKEUP_CHAT',
        url: 'makeup-chat'
    },
    SKINCARE_WORKSHOP: {
        id: 'SKINCARE_WORKSHOP',
        url: 'skincare-workshop'
    },
    BROW_GOALS: {
        id: 'BROW_GOALS',
        url: 'brow-goals'
    },
    SKIN_GOALS: {
        id: 'SKIN_GOALS',
        url: 'skin-goals'
    },
    VIRTUAL_BROW_GOALS: {
        id: 'VIRTUAL_BROW_GOALS',
        url: 'virtual-brow-goals'
    },
    MIND_BODY_TALK: {
        id: 'MIND_BODY_TALK',
        url: 'mind-body-talk'
    },
    AT_HOME_FACIAL: {
        id: 'AT_HOME_FACIAL',
        url: 'at-home-facial'
    },
    VIRTUAL_MEETING_MAKEUP: {
        id: 'VIRTUAL_MEETING_MAKEUP',
        url: 'virtual-meeting-makeup'
    },
    OFFICE_HOURS: {
        id: 'OFFICE_HOURS',
        url: 'office-hours'
    },
    EFFORTLESS_FRENCH_MAKEUP: {
        id: 'EFFORTLESS_FRENCH_MAKEUP',
        url: 'effortless-french-makeup'
    },
    NAIL_ART_TECHNIQUES_TRENDS: {
        id: 'NAIL_ART_TECHNIQUES_TRENDS',
        url: 'nail-art-techniques-trends'
    },
    FACIAL_SELF_CARE: {
        id: 'FACIAL_SELF_CARE',
        url: 'facial-self-care'
    },
    ART_FACIAL_MASSAGE: {
        id: 'ART_FACIAL_MASSAGE',
        url: 'art-facial-massage'
    },
    FRAGRANCE_EXPERIENCE: {
        id: 'FRAGRANCE_EXPERIENCE',
        url: 'fragrance-experience'
    },
    SKINCARE_GROOMING_MEN: {
        id: 'SKINCARE_GROOMING_MEN',
        url: 'skincare-grooming-men'
    },
    SIDE_FRENCH_MANICURE: {
        id: 'SIDE_FRENCH_MANICURE',
        url: 'side-french-manicure'
    },
    BEAUTY_LIFETIME: {
        id: 'BEAUTY_LIFETIME',
        url: 'beauty-lifetime'
    },
    ICONIC_CHANEL_MAKEUP_LOOK: {
        id: 'ICONIC_CHANEL_MAKEUP_LOOK',
        url: 'iconic-chanel-makeup-look'
    },
    FLAWLESS_COMPLEXION_ESSENTIALS: {
        id: 'FLAWLESS_COMPLEXION_ESSENTIALS',
        url: 'flawless-complexion-essentials'
    },
    N1_AT_HOME_FACIAL: {
        id: 'N1_AT_HOME_FACIAL',
        url: 'n1-at-home-facial'
    },
    GRAPHIC_EYE_LOOK: {
        id: 'GRAPHIC_EYE_LOOK',
        url: 'graphic-eye-look'
    },
    TONE_ON_TONE: {
        id: 'TONE_ON_TONE',
        url: 'tone-on-tone'
    },
    MUA_SECRETS: {
        id: 'MUA_SECRETS',
        url: 'mua-secrets'
    },
    FRAGRANCE_DISCOVERY: {
        id: 'FRAGRANCE_DISCOVERY',
        url: 'fragrance-discovery'
    },
    EVENT_QUESTIONNAIRE: {
        id: 'EVENT_QUESTIONNAIRE',
        url: 'event-questionnaire'
    }
};

export const isAppointmentQuestionnaire = url => {
    return [
        QUESTIONNAIRE_TYPES.MAKEUP_APPLICATION.url,
        QUESTIONNAIRE_TYPES.MAKEUP_SKINCARE.url,
        QUESTIONNAIRE_TYPES.SKIN_CHAT.url,
        QUESTIONNAIRE_TYPES.MAKEUP_CHAT.url,
        QUESTIONNAIRE_TYPES.SKINCARE_WORKSHOP.url,
        QUESTIONNAIRE_TYPES.BROW_GOALS.url,
        QUESTIONNAIRE_TYPES.SKIN_GOALS.url,
        QUESTIONNAIRE_TYPES.VIRTUAL_BROW_GOALS.url,
        QUESTIONNAIRE_TYPES.FRAGRANCE_EXPERIENCE.url
    ].includes(url);
};

export const QUESTIONNAIRE_IDS = [
    QUESTIONNAIRE_TYPES.MAKEUP_APPLICATION,
    QUESTIONNAIRE_TYPES.MAKEUP_SKINCARE,
    QUESTIONNAIRE_TYPES.SKIN_CHAT,
    QUESTIONNAIRE_TYPES.MAKEUP_CHAT,
    QUESTIONNAIRE_TYPES.SKINCARE_WORKSHOP,
    QUESTIONNAIRE_TYPES.BROW_GOALS,
    QUESTIONNAIRE_TYPES.SKIN_GOALS,
    QUESTIONNAIRE_TYPES.VIRTUAL_BROW_GOALS,
    QUESTIONNAIRE_TYPES.MIND_BODY_TALK,
    QUESTIONNAIRE_TYPES.AT_HOME_FACIAL,
    QUESTIONNAIRE_TYPES.VIRTUAL_MEETING_MAKEUP,
    QUESTIONNAIRE_TYPES.OFFICE_HOURS,
    QUESTIONNAIRE_TYPES.EFFORTLESS_FRENCH_MAKEUP,
    QUESTIONNAIRE_TYPES.NAIL_ART_TECHNIQUES_TRENDS,
    QUESTIONNAIRE_TYPES.FACIAL_SELF_CARE,
    QUESTIONNAIRE_TYPES.ART_FACIAL_MASSAGE,
    QUESTIONNAIRE_TYPES.SKINCARE_GROOMING_MEN,
    QUESTIONNAIRE_TYPES.SIDE_FRENCH_MANICURE,
    QUESTIONNAIRE_TYPES.BEAUTY_LIFETIME,
    QUESTIONNAIRE_TYPES.ICONIC_CHANEL_MAKEUP_LOOK,
    QUESTIONNAIRE_TYPES.FLAWLESS_COMPLEXION_ESSENTIALS,
    QUESTIONNAIRE_TYPES.N1_AT_HOME_FACIAL,
    QUESTIONNAIRE_TYPES.GRAPHIC_EYE_LOOK,
    QUESTIONNAIRE_TYPES.TONE_ON_TONE,
    QUESTIONNAIRE_TYPES.MUA_SECRETS,
    QUESTIONNAIRE_TYPES.FRAGRANCE_DISCOVERY,
    QUESTIONNAIRE_TYPES.EVENT_QUESTIONNAIRE
];

export const QUESTION_TYPES = {
    MULTI_SELECT: 'MULTI_SELECT',
    MULTI_SELECT_LONG: 'MULTI_SELECT_LONG',
    SINGLE_SELECT: 'SINGLE_SELECT',
    SINGLE_SELECT_LONG: 'SINGLE_SELECT_LONG',
    FREE_FORM_ENTRY: 'FREE_FORM_ENTRY',
    PICTURE_UPLOAD: 'PICTURE_UPLOAD',
    FIXED_PICTURE_UPLOAD: 'FIXED_PICTURE_UPLOAD',
    SUB_TITLE: 'SUB_TITLE',
    TEXT_AND_CTA: 'TEXT_AND_CTA'
};

export const AGGREGATE_TYPES = {
    SELECT_ALL: 'SELECT_ALL',
    SELECT_NONE: 'SELECT_NONE'
};

export const RESULT_SIGNATURES = {
    confidence: {
        quote: 'To be irreplaceable, one must always be different.'
    },
    creativity: {
        quote: 'My life didn\'t please me, so I created my life.'
    },
    empowerment: {
        quote: 'I decided who I wanted to be and that is who I am.'
    },
    playfulness: {
        quote: 'If you are born without wings, don\'t do anything to prevent them from growing.'
    },
    simplicity: {
        quote: 'Elegance is being as beautiful on the inside as on the outside.'
    },
    efficiency: {
        quote: 'I want to be part of what is to come.'
    }
};

export const QUESTIONNAIRE_CONFIG = {
    [QUESTIONNAIRE_TYPES.MAKEUP_SKINCARE.id]: {
        sections: [
            {
                title: 'Beauty Goals',
                description: '',
                subtitle: '',

                questions: [
                    {
                        id: 'BEAUTY_GOAL',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'My beauty goal is...',
                        preText: 'Select one',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Confidence', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3 },
                            { text: 'Creativity', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Empowerment', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_12 },
                            { text: 'Simplicity', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_10 },
                            { text: 'Playfulness', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_NAILS_5 },
                            { text: 'Efficiency', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2 }
                        ]
                    },
                    {
                        id: 'CURIOSITY',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'The biggest beauty mystery to me is...',
                        preText: 'Select all that apply',
                        zone: ZONES.CARE,
                        options: [
                            // displayedText is only used in the frontend, text is saved in database.
                            // if displayed text is not defined, text is displayed in the frontend
                            { text: 'Eye makeup', imageUrl: MAKEUP_DARK, displayedText: 'Eyes' },
                            { text: 'Shade selection', imageUrl: NAIL_DRIP, displayedText: 'Lips' },
                            { text: 'Foundation', imageUrl: BEIGE },
                            { text: 'Contouring Highlighting', imageUrl: BEIGE_LIGHT },
                            { text: 'Skincare', imageUrl: SKINCARE_LIGHT }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us your beauty mysteries!',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'SECRET_WEAPON',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'My secret beauty weapon is...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            { text: 'Lipstick', swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_2 },
                            { text: 'Mascara', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3 },
                            { text: 'Concealer', swatchImageName: SWATCH_CHECKBOX_NAMES.GREEN_SKIN_ENHANCERS_5 },
                            { text: 'Liner', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2 },
                            { text: 'Foundation', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2 },
                            { text: 'Bronzer', swatchImageName: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_6 },
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us your beauty weapon!',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'IDOLS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'People whose beauty look I covet:',
                        preText: 'Consider celebrities and other cultural icons, past, present or future. What do you love about their look?',
                        placeholderText: 'Tell us who and why!',
                        hasCheckBox: true,
                        zone: ZONES.LIP_BAR,
                        checkBoxLabel: 'Myself',
                        checkBoxDescription: 'Why would I want to look like anyone else?',
                        swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                    }
                ]
            },
            {
                title: 'Makeup Routine',
                description: 'Tell us about your makeup routine, the products you use every day, and what you\'d like to learn more about.',
                subtitle: '',

                questions: [
                    {
                        id: 'BEAUTY_LEVEL',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'When it comes to makeup, I consider myself...',
                        preText: 'Select one',
                        zone: ZONES.COLOR_WALL,
                        options: [
                            {
                                text: 'A beginner',
                                description: 'I\'d like to start with the basics.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            },
                            {
                                text: 'A novice',
                                description: 'I usually stay with my everyday look, but I can turn it up a notch.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            },
                            {
                                text: 'An enthusiast',
                                description: 'I like to change up my look, and I know a thing or two.' ,
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            },
                            {
                                text: 'An expert',
                                description: 'I love transforming myself, and I know what I\'m doing.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            }
                        ]
                    },
                    {
                        id: 'MAKEUP_PRODUCTS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        preText: 'Select all that apply',
                        text: 'The products I use for my complexion every day are...',
                        zone: ZONES.SKIN_CARE,
                        options: [
                            { text: 'Foundation primer', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKIN_ENHANCERS_7 },
                            { text: 'Liquid foundation', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2 },
                            { text: 'Powder foundation', swatchImageName: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_9 },
                            { text: 'Concealer', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8 },
                            { text: 'Blush', swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_POWDER_11 },
                            { text: 'Bronzer', swatchImageName: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_6 },
                            { text: 'Highlighter', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_POWDER_8 },
                            { text: 'Setting powder', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_POWDER_3 },
                            { text: 'Setting spray', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what other products you use',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'LIP_PRODUCTS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'The products I use on my lips every day are...',
                        preText: 'Select all that apply',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Lip balm', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_FACE_DEFINERS_1 },
                            { text: 'Lip liner', swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_6 },
                            { text: 'Lipstick', swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_10 },
                            { text: 'Lip gloss', swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_13 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what other products you use',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'EYE_PRODUCTS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'The products I use on my eyes every day are...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_COLOR,
                        options: [
                            { text: 'Eyeshadow primer', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_7 },
                            { text: 'Mascara primer', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_13 },
                            { text: 'Eyeshadow', swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_POWDER_1 },
                            { text: 'Mascara', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3 },
                            { text: 'Eyeliner pencil', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_9 },
                            { text: 'Liquid eyeliner', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2 },
                            { text: 'Brow powder', swatchImageName: SWATCH_CHECKBOX_NAMES.BROWN_FACE_DEFINERS_6 },
                            { text: 'Brow pencil', swatchImageName: SWATCH_CHECKBOX_NAMES.BROWN_EYE_5 },
                            { text: 'Brow gel', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_14 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what other products you use',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'MAKEUP_TOOLS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: true,
                        text: 'The makeup tools I use every day are...',
                        preText: 'Select all that apply',
                        zone: ZONES.FACE_DEFINITION,
                        options: [
                            { text: 'Face brushes', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Eye brushes', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3 },
                            { text: 'Lip brush', swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_LIPS_5 },
                            { text: 'Eyelash curler', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_EYE_1 },
                            { text: 'Makeup sponges', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2 },
                            { text: 'Eyeshadow foam applicators', swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_POWDER_1 },
                            { text: 'Q-Tips', swatchImageName: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_4 },
                            { text: 'My fingers', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what other tools you use',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    }
                ]
            },
            {
                title: 'My Colours',
                description: 'Tell us about your favorite colours and finishes.',
                subtitle: '',

                questions: [
                    {
                        id: 'LIP_COLORS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        preText: 'Select all that apply',
                        text: 'My favorite colours for lips are...',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Nudes', imageUrl: NUDES },
                            { text: 'Pinks', imageUrl: PINKS },
                            { text: 'Plum/Berry', imageUrl: BERRY },
                            { text: 'Reds', imageUrl: REDS },
                            { text: 'Corals', imageUrl: CORALS },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_LIPS_14 }
                        ]
                    },
                    {
                        id: 'LIP_FINISHES',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: true,
                        text: 'My favorite lip finishes are...',
                        preText: 'Select all that apply',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Matte', imageUrl: MATTE },
                            { text: 'Shimmer', imageUrl: SHIMMER },
                            { text: 'Sheer', imageUrl: SHEER },
                            { text: 'Satin', imageUrl: SATIN },
                            { text: 'Gloss', imageUrl: GLOSS },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_LIPS_14 }
                        ]
                    },
                    {
                        id: 'EYE_COLORS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'My favorite eye colours are...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_COLOR,
                        options: [
                            { text: 'Browns + Beiges', imageUrl: BROWNS_BEIGE },
                            { text: 'Pinks + Violets', imageUrl: PINKS_VIOLETS },
                            { text: 'Silvers + Greys', imageUrl: SILVERS_GREYS },
                            { text: 'Bronzes + Gold', imageUrl: BRONZES_GOLDS },
                            { text: 'Blues + Navies', imageUrl: BLUES_NAVIES },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.YELLOW_POWDER_12 },
                        ]
                    },
                    {
                        id: 'EYE_FINISHES',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: true,
                        text: 'My favorite eyeshadow finishes are...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_COLOR,
                        options: [
                            { text: 'Matte', imageUrl: EYE_MATTE },
                            { text: 'Shimmer', imageUrl: EYE_SHIMMER },
                            { text: 'Metallic', imageUrl: EYE_METALLIC },
                            { text: 'Glitter', imageUrl: EYE_GLITTER },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.YELLOW_POWDER_12 }
                        ]
                    }
                ]
            },
            {
                title: 'Skincare Routine',
                description: 'We believe that skincare is the foundation of beauty. Flawless makeup begins with flawless skin. We\'d like to learn more about your skincare routine.',
                subtitle: 'Almost there!',

                questions: [
                    {
                        id: 'SKINCARE_CLEANSERS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        preText: 'Select all that apply',
                        text: 'Products I currently use to cleanse my skin each day are:',
                        zone: ZONES.CARE,
                        options: [
                            { text: 'Cream cleanser', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2 },
                            { text: 'Gel cleanser', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_14 },
                            { text: 'Makeup remover wipes', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_5 },
                            { text: 'Eye makeup remover', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6 },
                            { text: 'Makeup remover', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2 },
                            { text: 'Micellar water', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_1 },
                            { text: 'Toner', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_4 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what other products you use',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'SKINCARE_PRODUCTS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        preText: 'Select all that apply',
                        text: 'Products I currently use to care for my skin each day are...',
                        zone: ZONES.SKIN_CARE,
                        options: [
                            { text: 'Serum', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8 },
                            { text: 'Moisturizer', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2 },
                            { text: 'Eye cream', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3 },
                            { text: 'Eye serum', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1 },
                            { text: 'Face oil', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_4 },
                            { text: 'Sunscreen', swatchImageName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_9 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what other products you use',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'NOTES',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'I\'d also like to note...',
                        placeholderText: 'What else should we know about you?',
                    }
                ]
            },
        ]
    },

    [QUESTIONNAIRE_TYPES.MAKEUP_APPLICATION.id]: {
        sections: [
            {
                title: 'All about me',
                description: '',
                questions: [
                    {
                        id: 'MY_OCCASION',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'My occasion or event is:',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Wedding', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3 },
                            { text: 'Date night', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Black tie event', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_12 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'MY_LOOK',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'I am planning on wearing:',
                        preText: 'Tell us about your outfit, jewelry, or anything else that will be key to your look.',
                        placeholderText: 'Tell us about your look',
                        zone: ZONES.EYE_COLOR,
                    },
                    {
                        id: 'MY_GOAL',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'I want my look to...',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Make me feel powerful', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Make me feel confident', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Match my outfit', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Transform my image completely', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Make me the best version of myself', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                        ]
                    },
                    {
                        id: 'UNCERTAINTY',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'My secret beauty weapon is...',
                        preText: 'Select all that apply',
                        zone: ZONES.COLOR_WALL,
                        options: [
                            { text: 'Lipstick', swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_2 },
                            { text: 'Mascara', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3 },
                            { text: 'Concealer', swatchImageName: SWATCH_CHECKBOX_NAMES.GREEN_SKIN_ENHANCERS_5 },
                            { text: 'Liner', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2 },
                            { text: 'Foundation', swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2 },
                            { text: 'Bronzer', swatchImageName: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_6 },
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us your beauty weapon!',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                        }
                    },
                    {
                        id: 'IDOLS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'People whose beauty look I covet:',
                        preText: 'Consider celebrities and other cultural icons, past, present or future. What do you love about their look?',
                        placeholderText: 'Tell us who and why!',
                        zone: ZONES.EYE_COLOR,
                        hasCheckBox: true,
                        checkBoxLabel: 'Myself!',
                        checkBoxDescription: 'Why would I want to look like anyone else?',
                        swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                    }
                ]
            },
            {
                title: 'My Colours',
                description: 'Tell us about your favorite colours and finishes.',
                subtitle: '',
                questions: [
                    {
                        id: 'LIP_COLORS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        preText: 'Select all that apply',
                        text: 'My favorite colours for lips are...',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Nudes', imageUrl: NUDES },
                            { text: 'Pinks', imageUrl: PINKS },
                            { text: 'Plum/Berry', imageUrl: BERRY },
                            { text: 'Reds', imageUrl: REDS },
                            { text: 'Corals', imageUrl: CORALS },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_LIPS_14 }
                        ]
                    },
                    {
                        id: 'LIP_FINISHES',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: true,
                        text: 'My favorite lip finishes are...',
                        preText: 'Select all that apply',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Matte', imageUrl: MATTE },
                            { text: 'Shimmer', imageUrl: SHIMMER },
                            { text: 'Sheer', imageUrl: SHEER },
                            { text: 'Satin', imageUrl: SATIN },
                            { text: 'Gloss', imageUrl: GLOSS },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_LIPS_14 }
                        ]
                    },
                    {
                        id: 'EYE_COLORS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'My favorite eye colours are...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_COLOR,
                        options: [
                            { text: 'Browns + Beiges', imageUrl: BROWNS_BEIGE },
                            { text: 'Pinks + Violets', imageUrl: PINKS_VIOLETS },
                            { text: 'Silvers + Greys', imageUrl: SILVERS_GREYS },
                            { text: 'Bronzes + Gold', imageUrl: BRONZES_GOLDS },
                            { text: 'Blues + Navies', imageUrl: BLUES_NAVIES },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.YELLOW_POWDER_12 }
                        ]
                    },
                    {
                        id: 'EYE_FINISHES',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: true,
                        text: 'My favorite eyeshadow finishes are...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            { text: 'Matte', imageUrl: EYE_MATTE },
                            { text: 'Shimmer', imageUrl: EYE_SHIMMER },
                            { text: 'Metallic', imageUrl: EYE_METALLIC },
                            { text: 'Glitter', imageUrl: EYE_GLITTER },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'I\'m up for anything', isAggregate: true, type: AGGREGATE_TYPES.SELECT_ALL, swatchImageName: SWATCH_CHECKBOX_NAMES.YELLOW_POWDER_12 }
                        ]
                    },
                    {
                        id: 'NOTES',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'I\'d also like to note...',
                        placeholderText: 'What else should we know about you?',
                    }
                ]
            }
        ]

    },

    [QUESTIONNAIRE_TYPES.SKIN_CHAT.id]: {
        sections: [
            {
                title: 'Skincare Goals',
                questions: [
                    {
                        id: 'MAIN_GOAL',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'The main goal for my session is...',
                        preText: 'Select the one that suits you best',
                        zone: ZONES.LIP_BAR,
                        options: [
                            {
                                text: 'Develop a routine',
                                description: 'I am new to skincare. Where should I begin?',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6
                            },
                            {
                                text: 'Improve my current routine',
                                description: 'There are some products I can\'t live without, but others have not been working for my skin.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1
                            },
                            {
                                text: 'Discover new products',
                                description: 'I love finding new favorites.' ,
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2
                            },
                            {
                                text: 'Be social',
                                description: 'I am here to talk beauty!',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8
                            }
                        ]
                    },
                    {
                        id: 'SKINCARE_QUESTIONS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'My skincare questions are related to...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            { text: 'HYPER-PIGMENTATION', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2 },
                            { text: 'ACNE', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2 },
                            { text: 'DRYNESS', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3 },
                            { text: 'FINE LINES & WRINKLES', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8 },
                            { text: 'LOSS OF ELASTICITY OR FIRMNESS', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_1 },
                            { text: 'ELASTICITY', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1 },
                            { text: 'REDNESS', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6 },
                            { text: 'DARK CIRCLES OR BAGS IN EYE AREA', swatchImageName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_9 },
                            { text: 'FINE LINES AROUND LIPS', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_5 },
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what topics',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_9,
                            keepSelected: true
                        },
                        moveDottedLine: true
                    },
                    {
                        id: 'PRODUCT_TO_DISCUSS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'Is there any specific product you would like to discuss?',
                        placeholderText: 'List the products here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        preText: 'Allergies, recent procedures, medication...',
                        text: 'Is there anything else we should know?',
                        placeholderText: 'List the products here',
                    },
                    {
                        id: 'PRODUCTS_I_USE',
                        type: QUESTION_TYPES.PICTURE_UPLOAD,
                        isOptional: true,
                        text: 'These are the <br/> products I use.',
                        preText: 'Uploading a photo of your current skincare routine will allow our team to prepare for your session.'
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.MAKEUP_CHAT.id]: {
        sections: [
            {
                title: 'Beauty Goals',
                questions: [
                    {
                        id: 'MAIN_GOAL',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'The main goal for my session is...',
                        preText: 'Select the one that suits you best',
                        zone: ZONES.LIP_BAR,
                        options: [
                            {
                                text: 'Learn a skill',
                                freeFormText: true,
                                description: 'Tell us what skill you had in mind',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.PINK_POWDER_11
                            },
                            {
                                text: 'Learn how to use a certain product',
                                freeFormText: true,
                                description: 'List the product(s) here',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2
                            },
                            {
                                text: 'Improve my technique',
                                description: 'I\'d love some tips from an expert.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BROWN_FACE_DEFINERS_6
                            },
                            {
                                text: 'Be social',
                                description: 'I am here to talk beauty!',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_9
                            }
                        ]
                    },
                    {
                        id: 'CURIOSITY',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'The biggest beauty mystery to me is...',
                        preText: 'Select all that apply',
                        zone: ZONES.CARE,
                        options: [
                            // displayedText is only used in the frontend, text is saved in database.
                            // if displayed text is not defined, text is displayed in the frontend
                            { text: 'Eyeliner', imageUrl: EYELINER },
                            { text: 'Eyeshadow', imageUrl: MAKEUP_DARK },
                            { text: 'Contouring Highlighting', imageUrl: BEIGE_LIGHT },
                            { text: 'Shade selection', imageUrl: NAIL_DRIP }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us your beauty mysteries!',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15,
                            keepSelected: true
                        }
                    },
                    {
                        id: 'IDOLS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'Whose beauty look I covet:',
                        preText: 'Consider celebrities and other cultural icons, past, present or future. What do you love about their look?',
                        placeholderText: 'List them all here!',
                        zone: ZONES.EYE_COLOR,
                        hasCheckBox: true,
                        checkBoxLabel: 'Myself!',
                        checkBoxDescription: 'Why would I want to look like anyone else?',
                        swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        preText: 'Allergies, recent procedures, medication...',
                        text: 'Is there anything else we should know?',
                        placeholderText: 'List the products here',
                    },
                    {
                        id: 'PRODUCTS_DISCUSS',
                        type: QUESTION_TYPES.PICTURE_UPLOAD,
                        isOptional: true,
                        text: 'These are the products I would like to discuss',
                        preText: 'Uploading a photo of any products you would like to discuss will allow our team to prepare for your session.'
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.SKINCARE_WORKSHOP.id]: {
        sections: [
            {
                title: 'Skincare Goals',
                questions: [
                    {
                        id: 'MAIN_GOAL',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'The main goal for my session is...',
                        preText: 'Select the one that suits you best',
                        zone: ZONES.LIP_BAR,
                        options: [
                            {
                                text: 'Develop a routine',
                                description: 'I am new to skincare. Where should I begin?',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6
                            },
                            {
                                text: 'Improve my current routine',
                                description: 'There are some products I can\'t live without, but others have not been working for my skin.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1
                            },
                            {
                                text: 'Discover new products',
                                description: 'I love finding new favorites.' ,
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2
                            },
                            {
                                text: 'Be social',
                                description: 'I am here to talk beauty!',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8
                            }
                        ]
                    },
                    {
                        id: 'SKINCARE_QUESTIONS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'My skincare questions are related to...',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            { text: 'HYPER-PIGMENTATION', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2 },
                            { text: 'ACNE', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2 },
                            { text: 'DRYNESS', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3 },
                            { text: 'FINE LINES & WRINKLES', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8 },
                            { text: 'LOSS OF ELASTICITY OR FIRMNESS', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_1 },
                            { text: 'ELASTICITY', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1 },
                            { text: 'REDNESS', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6 },
                            { text: 'DARK CIRCLES OR BAGS IN EYE AREA', swatchImageName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_9 },
                            { text: 'FINE LINES AROUND LIPS', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_5 },
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Tell us what topics',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_9,
                            keepSelected: true
                        },
                        moveDottedLine: true
                    },
                    {
                        id: 'PRODUCT_TO_DISCUSS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'Is there any specific product you would like to discuss?',
                        placeholderText: 'List the products here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        preText: 'Allergies, recent procedures, medication...',
                        text: 'Is there anything else we should know?',
                        placeholderText: 'List the products here',
                    },
                    {
                        id: 'PRODUCTS_I_USE',
                        type: QUESTION_TYPES.PICTURE_UPLOAD,
                        isOptional: true,
                        text: 'These are the <br/> products I use.',
                        preText: 'Uploading a photo of your current skincare routine will allow our team to prepare for your session.'
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.BROW_GOALS.id]: {
        sections: [
            {
                title: 'Brow Goals',
                description: 'Tell us a little bit about yourself and your brows.',
                questions: [
                    {
                        id: 'BROWS_QUESTIONS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'What questions do you have about your brows?',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            // displayedText is only used in the frontend, text is saved in database.
                            // if displayed text is not defined, text is displayed in the frontend
                            { text: 'Growth', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'Shape', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Hair Texture', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_HAIR_TEXTURE_1 },
                            { text: 'Unevenness', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2 },
                            { text: 'Products and Shades', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_6 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Please specify',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15,
                            keepSelected: true
                        }
                    },
                    {
                        id: 'BROWS_YOU_LIKE',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Name someone with brows that you love.',
                        placeholderText: 'Add their name here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know?',
                        placeholderText: 'Add any additional questions or comments',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.SKIN_GOALS.id]: {
        sections: [
            {
                title: 'Skin Goals',
                description: 'Tell us a little bit about yourself and your skin.',
                questions: [
                    {
                        id: 'SKIN_QUESTIONS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'What questions do you have about your skin?',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            // displayedText is only used in the frontend, text is saved in database.
                            // if displayed text is not defined, text is displayed in the frontend
                            { text: 'Hydratation', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2 },
                            { text: 'Acne', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2 },
                            { text: 'Fine Lines', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3 },
                            { text: 'Discoloration', swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6 },
                            { text: 'Scarring', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Please specify',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15,
                            keepSelected: true
                        }
                    },
                    {
                        id: 'SKIN_HOPE_TO_ACHIEVE',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'What are you hoping to achieve during today\'s class?',
                        placeholderText: 'List your goals here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know?',
                        placeholderText: 'Add any additional questions or comments',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.VIRTUAL_BROW_GOALS.id]: {
        sections: [
            {
                title: 'Brow Goals',
                description: 'Tell us a little bit about yourself and your brows.',
                questions: [
                    {
                        id: 'BROWS_QUESTIONS',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'What questions do you have about your brows?',
                        preText: 'Select all that apply',
                        zone: ZONES.EYE_DEFINITION,
                        options: [
                            // displayedText is only used in the frontend, text is saved in database.
                            // if displayed text is not defined, text is displayed in the frontend
                            { text: 'Growth', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'Shape', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11 },
                            { text: 'Hair Texture', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_HAIR_TEXTURE_1 },
                            { text: 'Unevenness', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2 },
                            { text: 'Products and Shades', swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_EYE_6 }
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Please specify',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15,
                            keepSelected: true
                        }
                    },
                    {
                        id: 'LAST_TIME_CARED_BROWS',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'When was the last time you tweezed, trimmed, threaded, sugared, waxed or shaved your brows?',
                        preText: 'Select the one that suits you best',
                        zone: ZONES.FACE_DEFINITION,
                        options: [
                            { text: 'Within the last two weeks', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_HAIR_TEXTURE_2 },
                            { text: 'Within the last month', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                            { text: 'Within the last 3 months', swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_HAIR_TEXTURE_1 }
                        ]
                    },
                    {
                        id: 'PRODUCTS_USED',
                        type: QUESTION_TYPES.MULTI_SELECT,
                        isOptional: false,
                        text: 'What brow products do you use?',
                        preText: 'Select all that apply',
                        zone: ZONES.FACE_DEFINITION,
                        options: [
                            // displayedText is only used in the frontend, text is saved in database.
                            // if displayed text is not defined, text is displayed in the frontend
                            { text: 'Gel', imageUrl: BROWS_GEL },
                            { text: 'Pencil', imageUrl: BROWS_PENCIL },
                            { text: 'Powder', imageUrl: BROWS_POWDER },
                            { text: 'Growth Serum', imageUrl: BROWS_GROWTH_SERUM },
                            { text: 'Conditioner', imageUrl: BROWS_CONDITIONER },
                            { text: 'None', isAggregate: true, type: AGGREGATE_TYPES.SELECT_NONE, swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3 },
                        ],
                        freeFormOption: {
                            text: 'Other',
                            preText: 'Please specify',
                            swatchImageName: SWATCH_CHECKBOX_NAMES.RED_LIPS_15,
                            keepSelected: true
                        }
                    },
                    {
                        id: 'HAD_PERMANENT_MAKEUP',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: false,
                        text: 'Have you ever had permanent makeup or microblading? When did you receive this technique?',
                        placeholderText: 'Tell us which technique and when',
                    },
                    {
                        id: 'BROWS_YOU_LIKE',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Name someone with brows that you love.',
                        placeholderText: 'Add their name here',
                    },
                    {
                        id: 'SHOW_BROWS',
                        type: QUESTION_TYPES.FIXED_PICTURE_UPLOAD,
                        isOptional: true,
                        text: 'Show us your brows.',
                        preText: 'Jimena will create a brow chart to share with you prior to your session. Tap the plus icon below to upload 4 photos.',
                        photos: [
                            {
                                buttonText: 'Upload a full face photo',
                                buttonDescription: 'See the above example for reference',
                                placeholderImage: FULL_FACE
                            },
                            {
                                buttonText: 'Upload a full brow photo',
                                buttonDescription: 'See the above example for reference',
                                placeholderImage: FULL_BROW
                            },
                            {
                                buttonText: 'Upload a left brow photo',
                                buttonDescription: 'See the above example for reference',
                                placeholderImage: LEFT_BROW
                            },
                            {
                                buttonText: 'Upload a right brow photo',
                                buttonDescription: 'See the above example for reference',
                                placeholderImage: RIGHT_BROW
                            }
                        ]
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.MIND_BODY_TALK.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for “The Mind-Body Connection and Your Skin” with Dr. Amy Wechsler.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                questions: [
                    {
                        id: 'SKIN_HOPE_TO_ACHIEVE',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this Atelier Talk?',
                        placeholderText: 'Maybe you want to build a routine? Or learn about stress-induced aging? Anything else?',
                    },
                    {
                        id: 'SPECIFIC_QUESTIONS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Dr. Wechsler?',
                        placeholderText: 'Ready to adjust your skin routine for Fall? Is your skin looking tired from staying indoors or wearing a mask? What else?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.AT_HOME_FACIAL.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for “Mastering the <span style="white-space: nowrap;">At-Home</span> Facial” with Melanie Grant.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                questions: [
                    {
                        id: 'FACIAL_FREQUENCY',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'How often do you normally get a facial?',
                        placeholderText: 'Monthly? Weekly? Rarely?',
                    },
                    {
                        id: 'WHERE_GETTING_FACIAL',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Where do you usually like to go to get a facial?',
                        placeholderText: 'Name your favorite places.',
                    },
                    {
                        id: 'EXPECT_DO_MORE_AT_HOME_FACIAL',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you expect you\'ll make a habit to do more at-home facials?',
                        placeholderText: 'Love the idea of doing it yourself? Can\'t wait to get back to your facialist? Undecided?',
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this master class?',
                        placeholderText: 'A step-by-step how-to for facials? Massage techniques? Anything else?',
                    },
                    {
                        id: 'MELANIE_QUESTIONS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Melanie?',
                        placeholderText: 'How often should I get a facial? Are face oils OK for acne-prone skin? What else?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.VIRTUAL_MEETING_MAKEUP.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for “Perfect Your Virtual Meeting Makeup” with Tyron Machhausen.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                questions: [
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this atelier talk?',
                        placeholderText: 'What are the best features to focus on? Any secret, go-to products? Anything else?',
                    },
                    {
                        id: 'PROFESSIONAL_OR_PERSONAL_PURPOSES',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Are you learning for professional or personal purposes?',
                        placeholderText: 'Attending work meetings? Interviewing for jobs? Working the virtual happy hour circuit?',
                    },
                    {
                        id: 'TYRON_QUESTIONS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Tyron?',
                        placeholderText: 'How do I create a natural look on screen? What\'s the best way to reduce shine? Anything else?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know about you?',
                        placeholderText: 'Think about your lifestyle, habits or interests…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.OFFICE_HOURS.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for “Office Hours” with Dr. Amy Wechsler.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                questions: [
                    {
                        id: 'INTERESTED_TOPICS',
                        type: QUESTION_TYPES.MULTI_SELECT_LONG,
                        text: 'Which topics interest you the most?',
                        preText: 'Select 3. Dr. Wechsler will cover the topics that are the most requested by this group.',
                        zone: ZONES.COLOR_WALL,
                        maxSelection: 3,
                        options: [
                            {
                                text: 'Premature Aging',
                                description: 'How do I prevent… or counteract?',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6
                            },
                            {
                                text: 'The Mind-Body Connection',
                                description: 'I\'d like to go deep on how my mindset affects the health of my skin.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1
                            },
                            {
                                text: 'Quarantine Care',
                                description: 'How do I protect and repair my skin from mask time and being indoors?' ,
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2
                            },
                            {
                                text: 'Skin Age',
                                description: 'How do I find out what mine is and adapt my routine to address it?',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8
                            },
                            {
                                text: 'Dermatology 101',
                                description: 'I\'m into the science of it all and want to better understand my own skin.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_1
                            },
                            {
                                text: 'Building a Routine',
                                description: 'Give me the what, where, when, how, and why to care for my skin.',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3
                            }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this Atelier Talk?',
                        placeholderText: 'Ready to adjust your skin routine for cold weather? Interested in stress-induced aging? Anything else?',
                    },
                    {
                        id: 'AMY_QUESTIONS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Dr. Wechsler?',
                        placeholderText: 'Is your skin looking tired from staying indoors? Any new skin concerns?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions...',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.EFFORTLESS_FRENCH_MAKEUP.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for “The Secrets of Effortless French Makeup” with Yacine Diallo.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                questions: [
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this Master Class?',
                        placeholderText: 'How to look polished but not overdone? Skin tips for great makeup? Anything else?',
                    },
                    {
                        id: 'YACINE_QUESTIONS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Yacine?',
                        placeholderText: 'The best shades for your skin tone? Covering imperfections (or not)? Anything else?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        isOptional: true,
                        text: 'Is there anything else we should know about you or your beauty regimen?',
                        placeholderText: 'Think about your lifestyle, habits, the condition of your skin…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.NAIL_ART_TECHNIQUES_TRENDS.id]: {
        sections: [
            {
                title: 'Get ready for',
                description: '"Nail Art:<br/>Techniques & Trends for Spring"<br/>with Tom Bachik.',
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'VERNIS_SHADES',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'First, select your two shades of Le Vernis',
                        description: 'You will need one base colour and one accent colour for your manicure.',
                        preHeader: 'Which pairing do you prefer?',
                        preText: 'Choose one',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Ballerina + Black', imageUrl: BALLERINA_BLACK, highlight: 'Tom\'s</br>Pick' },
                            { text: 'Melody + Rhythm', imageUrl: MELODY_RHYTHM },
                            { text: 'Ballerina + Vibration', imageUrl: BALLERINA_VIBRATION },
                            { text: 'Blanc White + Harmony', imageUrl: BLANK_WHITE_HARMONY },
                            { text: 'Daydream + Energy', imageUrl: DAYDREAM_ENERGY }
                        ]
                    },
                    {
                        id: 'TELL_YOURSELF_TITLE',
                        type: QUESTION_TYPES.SUB_TITLE,
                        text: 'Tell us about yourself',
                        isOptional: true // always true for SUB_TITLE
                    },
                    {
                        id: 'MANICURE_FREQUENCY',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'How often do you get a manicure?',
                        placeholderText: 'Monthly? Weekly? Rarely?'
                    },
                    {
                        id: 'MANICURE_WHERE',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Where do you usually like to get a manicure?',
                        placeholderText: 'Name your favorite places.',
                    },
                    {
                        id: 'MANICURE_AT_HOME',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Would you make it a habit to do more manicures at home?',
                        placeholderText: 'Love the idea of doing it yourself? Can\'t wait to get back to your manicurist? Undecided?',
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you looking forward to learning about the most in this class?',
                        placeholderText: 'A step-by-step nail design technique? Spring nail trends? Hand massage techniques? Other?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else we should know about you?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions...',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.FACIAL_SELF_CARE.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for<br/>“Facial Techniques & Self-Care Essentials”<br/>with Melissa Bunning.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you most looking forward to learning in this class?',
                        placeholderText: 'A step-by-step routine? Facial massage techniques? Anything else?',
                    },
                    {
                        id: 'SKIN_CONCERNS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific skin concerns?',
                        placeholderText: 'Large pores, dryness, fine lines, adult acne…',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else you’d like us to know about?',
                        placeholderText: 'Think about your lifestyle, habits or allergies…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.ART_FACIAL_MASSAGE.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for<br/>“The Art of Facial Massage”<br/>with Melanie Grant.',
                longTitle: true, // just to handle the extra height of a two line title in mobile
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you most looking forward to learning in this class?',
                        placeholderText: 'A step-by-step routine? Facial massage techniques? Anything else?',
                    },
                    {
                        id: 'SKIN_CONCERNS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific skin concerns?',
                        placeholderText: 'Large pores, dryness, fine lines, adult acne…',
                    },
                    {
                        id: 'MELANIE_QUESTIONS',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Melanie?',
                        placeholderText: 'How often should I get a facial? Are face oils OK for acne-prone skin? What else?'
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else you’d like us to know about?',
                        placeholderText: 'Think about your lifestyle, habits or allergies…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.SKINCARE_GROOMING_MEN.id]: {
        sections: [
            {
                title: 'Tell Us About Yourself',
                description: 'Help us prepare for<br/>"Skincare & Grooming Essentials for Men"<br/>with Tasha Reiko Brown.',
                longTitle: true,
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'EYEBROW_PENCIL_SHADE',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        allowUnselect: true, // clicking same option unselects option
                        // isOptional: true,
                        // hideSkipText: true, // if optional, hide skip question button
                        text: 'SELECT A BOY DE CHANEL EYEBROW PENCIL SHADE',
                        // description: 'You will need one base colour and one accent colour for your manicure.',
                        // preHeader: 'Which pairing do you prefer?',
                        // preText: 'Choose one',
                        zone: ZONES.LIP_BAR,
                        imageWidth: 130,
                        options: [
                            { text: '202 Light Brown', imageUrl: EYEBROW_LIGHT_BROWN },
                            { text: '204 Grey', imageUrl: EYEBROW_GREY },
                            { text: '206 Deep Brown', imageUrl: EYEBROW_DEEP_BROWN },
                            { text: '208 Black', imageUrl: EYEBROW_BLACK }
                        ]
                    },
                    {
                        id: 'HELP_ME_CHOOSE',
                        type: QUESTION_TYPES.TEXT_AND_CTA,
                        text: 'Not sure which shade to choose? Connect with an Atelier Beauty Guide to find your best match.',
                        ctaText: 'HELP ME CHOOSE',
                        ctaRoute: `${ROUTES.DISCOVER}/${DISCOVER_TABS.SERVICES}`,
                        center: true,
                        isOptional: true // always true for HELP_ME_CHOOSE
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this master class?',
                        placeholderText: 'Developing a skincare routine? Grooming techniques?',
                    },
                    {
                        id: 'QUESTIONS_FOR_TASHA',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any specific questions for Tasha?',
                        placeholderText: 'Tips to combat acne-prone skin? How to achieve an even complexion?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.SIDE_FRENCH_MANICURE.id]: {
        sections: [
            {
                title: 'Get ready for',
                description: '"Mastering the Side French Manicure"<br/>with Tom Bachik.',
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'VERNIS_SHADES',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'First, select your two shades of Le Vernis',
                        description: 'You will need one base colour and one accent colour for your manicure.',
                        preHeader: 'Which pairing do you prefer?',
                        preText: 'Choose one',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Pirate + Ballerina', imageUrl: BALLERINA_PIRATE, highlight: 'Tom\'s</br>Pick' },
                            { text: 'New Dawn + Canotier', imageUrl: NEWDAWN_CANOTIER },
                            { text: 'Vibration + Daydream', imageUrl: VIBRATION_DAYDREAM },
                            { text: 'Harmony + Blanc White', imageUrl: HARMONY_BLANCWHITE },
                            { text: 'Égérie + Cruise', imageUrl: EGERIE_CRUISE }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you looking forward to learning about the most in this class?',
                        placeholderText: 'A step-by-step nail design technique? Hand massage techniques? Other?',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.BEAUTY_LIFETIME.id]: {
        sections: [
            {
                title: 'Get Ready for',
                description: '"Beauty for a Lifetime"<br/>with Dr. Amy Wechsler.',
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'TOOL_KIT_SELECT',
                        type: QUESTION_TYPES.SINGLE_SELECT_LONG,
                        isOptional: false,
                        text: 'Select your product tool kit',
                        options: [
                            {
                                text: 'Early Beauty',
                                description: 'Set up basic routine',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            },
                            {
                                text: 'Prevention',
                                description: 'Delay signs of aging',
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            },
                            {
                                text: 'Rejuvenation',
                                description: 'Revitalize & restore your skin' ,
                                swatchImageName: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11
                            }
                        ]
                    },
                    {
                        id: 'HELP_ME_CHOOSE',
                        type: QUESTION_TYPES.TEXT_AND_CTA,
                        text: 'Not sure which kit to choose? Connect with an Atelier Beauty Guide to find your best match.',
                        ctaText: 'HELP ME CHOOSE',
                        ctaRoute: `${ROUTES.DISCOVER}/${DISCOVER_TABS.SERVICES}`,
                        center: true,
                        isOptional: true // always true for TEXT_AND_CTA
                    },
                    {
                        id: 'TELL_YOURSELF_TITLE',
                        type: QUESTION_TYPES.SUB_TITLE,
                        text: 'Tell us about yourself',
                        padding: [50, 10, 10, 20],
                        isOptional: true // always true for SUB_TITLE
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this master class?',
                        placeholderText: 'How to best evolve your current routine? Learn about stress-induced aging? Anything else?',
                    },
                    {
                        id: 'QUESTIONS_FOR_AMY',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Do you have any questions for Dr. Amy Wechsler?',
                        placeholderText: 'Tips to combat acne-prone skin? How to achieve an even complexion? What else?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.ICONIC_CHANEL_MAKEUP_LOOK.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Let us know your questions and interests so we can better prepare our topics',
                longTitle: true,
                questions: [
                    {
                        id: 'TOOL_KIT_SELECT',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Select your shade of Stylo Ombre et Contour',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: '614 Brown', imageUrl: STYLO_OMBRE_BROWN },
                            { text: '616 Navy', imageUrl: STYLO_OMBRE_NAVY },
                            { text: '618 Black', imageUrl: STYLO_OMBRE_BLACK }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn?',
                        placeholderText: 'Add your response here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What questions do you have for the expert?',
                        placeholderText: 'Add your response here',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.FLAWLESS_COMPLEXION_ESSENTIALS.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Help us prepare for<br/>"Flawless Complexion Essentials"<br/>with Atelier Master Artist<br/>Abby Jones.',
                longTitle: true,
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'TOOL_KIT_SELECT',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Select a shade of Bronzing Cream to sample',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Bronze', imageUrl: BRONZING_CREAM_BRONZE },
                            { text: 'Deep Bronze', imageUrl: BRONZING_CREAM_DEEP_BRONZE }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this master class?',
                        placeholderText: 'How to achieve an even complexion? What else?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.N1_AT_HOME_FACIAL.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Help us prepare for<br/>"At-Home Facial with N°1 de CHANEL"<br/>with Atelier Master Artist<br/>Abby Jones.',
                longTitle: true,
                hideServiceInfo: true, // hide 'FOR YOUR SERVICE' section
                questions: [
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn in this master class?',
                        placeholderText: 'Which product to use for specific skin concerns? When and how to use each product?',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'Is there anything else we should know about you or your skin?',
                        placeholderText: 'Think about your lifestyle, habits or skin conditions…',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.GRAPHIC_EYE_LOOK.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Let us know your questions and interests so we can better prepare our topics',
                longTitle: true,
                questions: [
                    {
                        id: 'TOOL_KIT_SELECT',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Select your choice of Stylo Yeux Waterproof',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: '68 Noir Intense + 46 Vert Emeraude', imageUrl: STYLO_YEUX_NOIR_INTENSE_VERT_EMERAUDE },
                            { text: '36 Prune Intense + 928 Eros', imageUrl: STYLO_YEUX_PRUNE_INTENSE_EROS }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn?',
                        placeholderText: 'Add your response here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What questions do you have for the expert?',
                        placeholderText: 'Add your response here',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.TONE_ON_TONE.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Let us know your questions and interests so we can better prepare our topics',
                longTitle: true,
                questions: [
                    {
                        id: 'TOOL_KIT_SELECT',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Select your choice of Les 4 Ombres',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: '362 Candeur Et Provocation', imageUrl: LES_4_OMBRES_CANDEUR_ET_PROVOCATION },
                            { text: '322 Blurry Grey', imageUrl: LES_4_OMBRES_BLURRY_GREY },
                            { text: '308 Clair-Obscur', imageUrl: LES_4_OMBRES_CLAIR_OBSCUR }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn?',
                        placeholderText: 'Add your response here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What questions do you have for the expert?',
                        placeholderText: 'Add your response here',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.MUA_SECRETS.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Let us know your questions and interests so we can better prepare our topics',
                longTitle: true,
                questions: [
                    {
                        id: 'TOOL_KIT_SELECT',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Select your choice of Les Beiges Healthy Glow Bronzing Cream',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: '390 Bronze', imageUrl: LES_BEIGES_BRONZE },
                            { text: '395 Deep Bronze', imageUrl: LES_BEIGES_DEEP_BRONZE }
                        ]
                    },
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn?',
                        placeholderText: 'Add your response here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What questions do you have for the expert?',
                        placeholderText: 'Add your response here',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.FRAGRANCE_DISCOVERY.id]: {
        sections: [
            {
                title: 'Tell us about you',
                longTitle: true,
                questions: [
                    {
                        id: 'PURCHASED_CHANEL_FRAGRANCE',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Have you previously purchased a CHANEL fragrance?',
                        preText: 'Choose one option',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Yes', swatchImageName: SWATCH_CHECKBOX_NAMES.OTHERS_3 },
                            { text: 'No', swatchImageName: SWATCH_CHECKBOX_NAMES.OTHERS_3 },
                        ]
                    },
                    {
                        id: 'FIRST_ATELIER_VISIT',
                        type: QUESTION_TYPES.SINGLE_SELECT,
                        isOptional: false,
                        text: 'Will this be your first visit to the Atelier?',
                        preText: 'Choose one option',
                        zone: ZONES.LIP_BAR,
                        options: [
                            { text: 'Yes', swatchImageName: SWATCH_CHECKBOX_NAMES.OTHERS_3 },
                            { text: 'No', swatchImageName: SWATCH_CHECKBOX_NAMES.OTHERS_3 },
                        ]
                    },
                    {
                        id: 'HEARD_ABOUT_THIS_EVENT',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'How did you hear about this event?',
                        placeholderText: 'Add your response here',
                    }
                ]
            }
        ]
    },

    [QUESTIONNAIRE_TYPES.EVENT_QUESTIONNAIRE.id]: {
        sections: [
            {
                title: 'Tell us about yourself',
                description: 'Let us know your questions and interests so we can better prepare our topics',
                longTitle: true,
                questions: [
                    {
                        id: 'HOPE_TO_LEARN',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What are you hoping to learn?',
                        placeholderText: 'Add your response here',
                    },
                    {
                        id: 'WE_SHOULD_KNOW',
                        type: QUESTION_TYPES.FREE_FORM_ENTRY,
                        text: 'What questions do you have for the expert?',
                        placeholderText: 'Add your response here',
                    }
                ]
            }
        ]
    }
};

export const FRAGRANCE_EXPERIENCE_QUESTION_IDS = {
    question1: 'FOCUS_OUR_DISCUSSION',
    question2: 'DESCRIBE_STYLE_', // dynamic end
    question3: 'FRAGRANCE_MAKES_YOU_FEEL',
    question4: 'SCENTS_DRAWN_TO',
    question5: 'NOTICEABLE_FRAGRANCE',
    question6: 'FRAGRANCE_CURRENTLY_WEAR',
    question6b: 'WHAT_ARE_YOU_LOOKING_FOR'
};

const fragranceImages = {
    1: {
        mobile: [bgMobile1, flowerMobile1],
        desktop: [bgDesktop1, flowerDesktop1]
    },
    2: {
        mobile: [bgMobile2, flowerMobile2],
        desktop: [bgDesktop2, flowerDesktop2, singleVineDesktop2, singleFlowerDesktop2]
    },
    3: {
        mobile: [bgMobile3, flowerMobile3],
        desktop: [bgDesktop3, flowerDesktop3]
    },
    4: {
        mobile: [bgMobile4, flowerMobile4],
        desktop: [bgDesktop4, flowerDesktop4]
    },
    5: {
        mobile: [bgMobile5, flowerMobile5],
        desktop: [bgDesktop5, flowerDesktop5]
    },
    6: {
        mobile: [bgMobile6, flowerMobile6],
        desktop: [bgDesktop6, flowerDesktop6]
    },
    7: {
        mobile: [bgMobile7, flowerMobile7],
        desktop: [bgDesktop7, flowerDesktop7]
    }
};

export const preloadFragranceQuestionnaireImages = questionNumber => {
    if (questionNumber > 0 && questionNumber <= 7) {
        const isDesktop = isGreaterThan(breakpoints.small);
        const images = fragranceImages[questionNumber][isDesktop ? 'desktop' : 'mobile'];

        preloadImages(images);
    }
};
