import SKINCARE_TALK from 'assets/events/skincare-talk.png';
import SKINCARE_TALK_2X from 'assets/events/skincare-talk@2x.png';
import SKINCARE_TALK_3X from 'assets/events/skincare-talk@3x.png';
import FRAGRANCE_TALK from 'assets/events/fragrance-talk.png';
import FRAGRANCE_TALK_2X from 'assets/events/fragrance-talk@2x.png';
import FRAGRANCE_TALK_3X from 'assets/events/fragrance-talk@3x.png';
import FRAGRANCE_CARD_LEFT from 'assets/events/fragrance-event-left.png';
import FRAGRANCE_CARD_LEFT_2X from 'assets/events/fragrance-event-left@2x.png';
import FRAGRANCE_CARD_LEFT_3X from 'assets/events/fragrance-event-left@3x.png';
import FRAGRANCE_CARD_RIGHT from 'assets/events/fragrance-event-right.png';
import FRAGRANCE_CARD_RIGHT_2X from 'assets/events/fragrance-event-right@2x.png';
import FRAGRANCE_CARD_RIGHT_3X from 'assets/events/fragrance-event-right@3x.png';
import SKINCARE_CARD from 'assets/events/skincare-event.png';
import SKINCARE_CARD_2X from 'assets/events/skincare-event@2x.png';
import SKINCARE_CARD_3X from 'assets/events/skincare-event@3x.png';
import PAYMENT_METHODS from 'assets/payment-methods.png';
import PAYMENT_METHODS_2X from 'assets/payment-methods@2x.png';
import PAYMENT_METHODS_3X from 'assets/payment-methods@3x.png';
import isNumeric from 'core/utils/isNumeric';
import { store } from 'store';
import { LOCATIONS, LOCATION_TEXT, VIRTUAL_LOCATION } from './locations';

export const EventTypes = {
    SkincareTalk: {
        id: 30,
        images: {
            width: 180,
            src: SKINCARE_TALK,
            double: SKINCARE_TALK_2X,
            triple: SKINCARE_TALK_3X
        },
        eventCard: {
            width: 77,
            src: SKINCARE_CARD,
            double: SKINCARE_CARD_2X,
            triple: SKINCARE_CARD_3X
        }
    },
    FragranceTalk: {
        id: 31,
        images: {
            width: 234,
            src: FRAGRANCE_TALK,
            double: FRAGRANCE_TALK_2X,
            triple: FRAGRANCE_TALK_3X
        },
        eventCardLeft: {
            width: 60,
            src: FRAGRANCE_CARD_LEFT,
            double: FRAGRANCE_CARD_LEFT_2X,
            triple: FRAGRANCE_CARD_LEFT_3X
        },
        eventCardRight: {
            width: 120,
            src: FRAGRANCE_CARD_RIGHT,
            double: FRAGRANCE_CARD_RIGHT_2X,
            triple: FRAGRANCE_CARD_RIGHT_3X
        }
    }
};

export const computeEventTax = price => {
    if (!isNumeric(price)) {
        return 0;
    }

    return (parseFloat(price) * 0.045).toFixed(2);
};

export const PaymentMethods = {
    width: 306,
    src: PAYMENT_METHODS,
    double: PAYMENT_METHODS_2X,
    triple: PAYMENT_METHODS_3X
};

/**
 * Generates object with detailId key to get event information from prismic
 * @return {object} object containing prismic information for each defined detailId in service config
 */
export const getEventDatas = () => {
    const { definitions } = store.getState().services;

    return Object.keys(definitions)
        .filter(key => definitions[key].type === 'event')
        .reduce((res, key) => {
            if (definitions[key].event_types) {
                return { ...res, ...definitions[key].event_types };
            } else {
                return res;
            }
        }, {});
};

export const getLocationFromPrereq = prereq => {
    let location = [LOCATIONS.NYC];
    let locationText = LOCATION_TEXT[LOCATIONS.NYC];

    if (prereq.includes('location=1,2') || prereq.includes('location=2,1')) {
        location = [LOCATIONS.NYC, LOCATIONS.AUSTIN];
        locationText = VIRTUAL_LOCATION;
    } else if (prereq.includes('location=1')) {
        location = [LOCATIONS.NYC];
        locationText = LOCATION_TEXT[LOCATIONS.NYC];
    } else if (prereq.includes('location=2')) {
        location = [LOCATIONS.AUSTIN];
        locationText = LOCATION_TEXT[LOCATIONS.AUSTIN];
    }

    return { location, locationText };
};
