import { SEARCH } from 'constants/search';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import normalizeProduct from 'core/utils/normalizeProduct';
import { createAction, handleActions } from 'redux-actions';
import { searchProducts } from 'reducers/elasticsearch';
import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';
// import { pushInteraction } from './recommendations';
// import log from 'core/log';
// import { INTERACTION_TYPES } from 'constants/recommendations';

let abortController;

const initialState = {
    products: [],
    searchTerm: '',
    isFetching: false,
    autocompleteResults: []
};

export const isFetching = createAction('IS_FETCHING');
export const setFoundProducts = createAction('SET_FOUND_PRODUCTS');
export const setAutocompleteResults = createAction('SET_AUTOCOMPLETE_RESULTS');
export const resetAutocompleteResults = createAction(
    'RESET_AUTOCOMPLETE_RESULTS'
);
export const setSearchTerm = createAction('SET_SEARCH_TERM');

export const abortAutocompleteRequest = () => {
    !!abortController && abortController.abort();
};

export const triggerAutocompleteSearch = (search, currentPage = 0) => {
    return dispatch => {
        // allow abort controller
        !!abortController && abortController.abort();

        const request = {
            bodyRequest: elasticSearchQueries.searchAutocompleteQuery(search),
            params: {
                perPage: SEARCH.SEARCH.perPage,
                currentPage: currentPage
            }
        };

        const endpoint = SEARCH.URL.DEFAULT;

        abortController = new AbortController();
        dispatch(
            searchProducts({
                endpoint,
                request,
                signal: abortController.signal
            })
        ).then(response => {
            if (response) {
                const results = response.suggest.products[0].options.map(
                    result => result.text
                );

                dispatch(setAutocompleteResults(results));
            }
        });
    };
};

/**
 * Trigger search
 * @param {string} search - user request
 * @param {boolean} isStaff - staff searching
 * @param {number} currentPage - curent page for search
 * @returns {function(*, *): *} promise
 */
export const fetchProductSearch = (
    search,
    isStaff = false,
    currentPage = 0
) => {
    return dispatch => {
        dispatch(isFetching(true));
        dispatch(setSearchTerm(search));

        const request = {
            bodyRequest: elasticSearchQueries.searchQuery(search, isStaff),
            params: {
                perPage: SEARCH.SEARCH.perPage,
                currentPage: currentPage
            }
        };

        const endpoint = SEARCH.URL.DEFAULT;

        dispatch(searchProducts({ endpoint, request })).then(
            ({
                aggregations: {
                    products: { buckets: results }
                }
            }) => {
                const searchResults = results.map(item =>
                    item.top_products.hits.hits.map(normalizeProduct)
                );
                dispatch(
                    analyticsTag(
                        {
                            event: EVENTS.GA,
                            eventCategory: 'product_search',
                            eventAction: search,
                            eventLabel: searchResults.length
                        },
                        { userInfo: true }
                    )
                );

                // const interaction = {
                //     skus: searchResults.flat().map(sr => sr.sku),
                //     interactionType: INTERACTION_TYPES.SEARCH
                // };

                // if (interaction.skus.length > 0) {
                //     dispatch(pushInteraction(interaction)).catch(() => {
                //         log.info('Could not send interaction for recommendations:', interaction);
                //     });
                // }

                dispatch(setFoundProducts(searchResults));
                dispatch(isFetching(false));
            }
        );
    };
};

export default handleActions(
    {
        [isFetching]: (state, { payload }) => ({
            ...state,
            isFetching: payload
        }),
        [setFoundProducts]: (state, { payload }) => ({
            ...state,
            products: payload
        }),
        [setAutocompleteResults]: (state, { payload }) => ({
            ...state,
            autocompleteResults: payload
        }),
        [resetAutocompleteResults]: (state) => ({
            ...state,
            autocompleteResults: []
        }),
        [setSearchTerm]: (state, { payload }) => ({
            ...state,
            searchTerm: payload
        })
    },
    initialState
);
