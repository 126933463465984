import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inputStyle, styledProps, rem } from 'core/styled';
import entries from 'core/utils/entries';

const SelectContainer = styled.div`
    position: relative;

    ${(props) => props.showCaret ? `
        &:after,
        &:before {
            display: block;
            position: absolute;
            top: 50%;
            width: 10px;
            height: 2px;
            background-color: ${styledProps('color', 'black')(props)};
            content: '';
        }
        
        &:before {
            right: 10px;
            transform: rotate(45deg);
        }
        
        &:after {
            right: 4px;
            transform: rotate(-45deg);
        }
    ` : ''}
`;

const Select = styled.select`
    ${inputStyle};

    -moz-appaerance: none;
    -webkit-appearance: none;
    appaerance: none;

    ${({ noOutline }) => noOutline && 'outline: none;'}
    font-family: ${({ inputfont }) => inputfont ? styledProps('font', inputfont) : ''};
    ${({ inputfontsize }) => inputfontsize ? `font-size: ${rem(inputfontsize)};` : ''}
    ${({ inputfontweight }) => inputfontweight ? `font-weight: ${inputfontweight};` : ''}
`;

export default class SelectInput extends Component {
    static propTypes = {
        /**
         * Accepts an object:
         * ```
         * {
         *   A: 'Option A',
         *   B: 'Option B'
         * }
         * ```
         * Or an array:
         * ```
         * ['A', 'B']
         * ```
         */
        onSelect: PropTypes.func.isRequired,
        options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        hideSelect: PropTypes.bool,
        ariaLabel: PropTypes.string,
        inputfont: PropTypes.string,
        inputfontsize: PropTypes.number,
        inputfontweight: PropTypes.string,
        showCaret: PropTypes.bool,
        textValueOption: PropTypes.bool, // true to handle a an array of object { text: 'Display', value: 'event value' }
        showOptGroup: PropTypes.bool, // hack to wrap options in iphone
        useEmptyOption: PropTypes.bool
    };

    static defaultProps = {
        hideSelect: false,
        showCaret: false,
        textValueOption: false,
        showOptGroup: false,
        useEmptyOption: false
    };

    handleChange(e) {
        const { options, onSelect } = this.props;

        onSelect(options[e.target.value].value);
    }

    render() {
        const { options, hideSelect, ariaLabel, showCaret, textValueOption, showOptGroup, useEmptyOption, ...rest } = this.props;
        const optionsList = Array.isArray(options)
            ? options
            : entries(options);
        return (
            <SelectContainer showCaret={showCaret}>
                <Select
                    onChange={(e) => this.handleChange(e)}
                    aria-label={ariaLabel}
                    {...rest}>
                    { !hideSelect && (!textValueOption || useEmptyOption) && <option value="" /> }
                    { !hideSelect && textValueOption && !useEmptyOption && <option>Select...</option> }
                    {optionsList.map((option, key) => (
                        <option key={key} value={textValueOption ? option.value : option}>
                            {textValueOption ? option.text : option}
                        </option>
                    ))}
                    {showOptGroup && <optgroup label={''}></optgroup>}
                </Select>
            </SelectContainer>
        );
    }
}
