import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styledProps, rem } from 'core/styled';
import { SWATCH_CHECKBOXES } from 'constants/swatchCheckbox/checkboxes';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import isNumeric from 'core/utils/isNumeric';
import { KEY_CODES } from 'constants/keycodes';

const CheckboxWrapper = styled.div`
    display: ${({ display }) => display};
    flex-direction: ${({ flexDirection }) => flexDirection};
    text-align: ${({ textAlign }) => textAlign};
    align-items: ${({ boxAlign }) => boxAlign};
    cursor: pointer;
    opacity: ${({ opacity }) => opacity};
`;

const Text = styled.div`
    font-family: ${({ font }) => styledProps('font', font)};
    font-size: ${ ({ fontSize }) => rem(fontSize) };
    padding: ${rem(5, 10)};
    margin: ${({ textMargin }) => rem(...textMargin)};
`;

const StyledCheckboxImage = styled.img`
    position: ${({ active }) => active ? 'unset' : 'absolute'};
    opacity: ${({ active }) => active ? 1 : 0};
    height: ${({ active, cssHeight }) => active ? rem(cssHeight) : 0};
    width: ${({ active }) => active ? 'auto' : 0};
    order: ${({ imageOrder }) => imageOrder};
    transition: opacity 0.2s;
`;

export default class SwatchCheckbox extends Component {
    static propTypes = {
        value: PropTypes.any.isRequired,
        name: PropTypes.string.isRequired,
        onSelect: PropTypes.func,
        isSelected: PropTypes.bool,
        text: PropTypes.string,
        children: PropTypes.object,
        flexDirection: PropTypes.string,
        imageOrder: PropTypes.number,
        textAlign: PropTypes.string,
        disabled: PropTypes.bool,
        boxAlign: PropTypes.string,
        textTilt: PropTypes.number,
        fontSize: PropTypes.number,
        display: PropTypes.string,
        textMargin: PropTypes.array,
        font: PropTypes.string,
        ariaExtraDescription: PropTypes.string,
        opacity: PropTypes.number,
        cssHeight: PropTypes.number
    };

    static defaultProps = {
        flexDirection: 'column',
        textAlign: 'center',
        imageOrder: 0,
        isSelected: false,
        disabled: false,
        boxAlign: 'center',
        fontSize: 11,
        display: 'inline-flex',
        textMargin: [0, 0, 0, 0],
        font: 'Chromosome',
        opacity: 1,
        cssHeight: 54
    };

    state = {
        checkbox: [],
        selected: this.props.isSelected
    };

    componentDidMount() {
        const { name } = this.props;
        const checkbox = SWATCH_CHECKBOXES.find(checkbox => checkbox.name === name);

        // forces the browser to preload the image
        const img = new Image();
        img.src = checkbox.selected;

        // Get image for selected product name
        this.setState({
            checkbox
        });
    }

    static getDerivedStateFromProps({ isSelected }, { selected }) {
        if (selected !== isSelected) {
            return { selected: isSelected };
        }
        return null;
    }

    /**
     * Toggle selection and send selection up to parent
     */
    onClick = () => {
        const { onSelect, value, disabled } = this.props;
        if (!disabled) {
            onSelect(value);
            this.setState({
                selected: !this.state.selected
            });
        }
    };

    /**
     * Handle enter and space keys
     * @param {object} e - event
     */
    onKeyDown = e => {
        if (e.keyCode === KEY_CODES.ENTER || e.keyCode === KEY_CODES.SPACE) {
            e.preventDefault();
            this.onClick();
        }
    };

    render() {
        const { text, children, flexDirection, imageOrder, textAlign, disabled, boxAlign, textTilt, fontSize, display, textMargin, font, ariaExtraDescription, opacity, cssHeight } =  this.props;
        const { checkbox, selected } = this.state;
        const TextComponent = selected && isNumeric(textTilt) ? TiltedText : Text;

        // Even though the images are cached, there is a brief moment where the image has not been added
        // and causes the surrounding elements to collapse. Having the images already in place but hidden with
        // opacity solves this issue.
        const blankImage = checkbox.unselected;
        const checkedImage = checkbox.selected;

        return (
            <CheckboxWrapper
                boxAlign={boxAlign}
                disabled={disabled}
                flexDirection={flexDirection}
                textAlign={textAlign}
                onClick={this.onClick}
                onKeyDown={e => {
                    this.onKeyDown(e);
                }}
                display={display}
                role={'checkbox'}
                aria-checked={selected}
                tabIndex={0}
                aria-disabled={disabled}
                opacity={opacity}
            >
                <StyledCheckboxImage active={!selected} key={blankImage} imageOrder={imageOrder} src={blankImage} alt={''} role={'img'} cssHeight={cssHeight} />
                <StyledCheckboxImage active={selected} key={checkedImage} imageOrder={imageOrder} src={checkedImage} alt={''} role={'img'} cssHeight={cssHeight} />
                {children ? children : (<TextComponent textMargin={textMargin} style={ { marginLeft: '-6px' } } fontSize={fontSize} rotate={textTilt} textPadding={[0, 8]} lineHeight={1} font={font}>{text}<span className={'sr-only'}>. {ariaExtraDescription}</span></TextComponent>)}
            </CheckboxWrapper>
        );
    }
}
