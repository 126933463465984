/**
 * Check a file extension by name.
 * @param {string} fileName - fileName checked
 * @returns {boolean} if true the type is allowed
 */
const checkImageFileExtension = fileName => {
    const extensions = ['.jpg', '.jpeg', '.png'];
    const pattern = '(' + extensions.join('|').replace(/\./g, '\\.') + ')$';
    return new RegExp(pattern, 'i').test(fileName);
};

export default checkImageFileExtension;
