import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styledProps, rem, greaterThan } from 'core/styled';
import styled, { css } from 'styled-components';
import Header from 'components/GlobalComponents/Fonts/Header';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import SimpleBeautyPlanProductCard from './SimpleBeautyPlanProductCard';
import { breakpoints } from 'constants/theme';

const CategoryContainer = styled.div`
    padding-top: ${rem(5)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${rem(0, 30)};
    max-width: ${rem(1098)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${rem(20, 'auto', 60)};
`;

const SubSectionContainer = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledHeader = styled(Header).attrs({
    as: 'h3'
})`
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-weight: 400;
    padding: 0 ${rem(20, 5, 20)};
    position: relative;
    font-size: ${rem(22)};
`;

const NoteWrapper = styled.div`
    padding-top: ${rem(30)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Note = styled.div`
    width: 100%;
    max-width: ${rem(300)};
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    text-align: center;
    line-height: 1.5;
    margin: ${rem(-10, 'auto', 5)};
`;

const StyledDivider = styled.div`
    width: 100%;
    height: ${rem(1)};
    background-color: ${styledProps('color', 'black')};
    margin-top: ${rem(10)};

    &.bp--ttabg-servicedivider {
        margin: ${rem(10, 'auto', 30)};
    }

    &.bp--ttabg-notedivider {
        margin: ${rem(10, 'auto', 30)};
    }
`;

const ProductsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${greaterThan(breakpoints.large)(css`
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    `)}
`;

class AtHomeFacialCustomer extends Component {
    static propTypes = {
        products: PropTypes.object,
        push: PropTypes.func.isRequired,
        staffFirstName: PropTypes.string.isRequired,
    };

    renderProducts = products => {
        const { staffFirstName } = this.props;

        return (
            <SubSectionContainer>
                <StyledHeader>Products Used</StyledHeader>
                <ProductsWrapper>
                    {products.map((product, index) => (
                        <SimpleBeautyPlanProductCard
                            isEven={index % 2 === 0}
                            key={index}
                            product={product}
                            withProductNote
                            artist={staffFirstName}
                            cssMaxWidth={346}
                        />
                    ))}
                </ProductsWrapper>
            </SubSectionContainer>
        );
    };

    renderCategories = () => {
        const { products } = this.props;

        if (!products.length) {
            return null;
        }

        return (
            <CategoryContainer>
                {products.length && this.renderProducts(products)}
            </CategoryContainer>
        );
    };

    renderNote = (note, index, title) => (
        <NoteWrapper key={index}>
            {index > 0 && <StyledDivider className={'bp--ttabg-notedivider'} />}
            <StyledHeader>{title}</StyledHeader>
            <Note dangerouslySetInnerHTML={{ __html: note.note }} />
        </NoteWrapper>
    );

    render() {
        const { products } = this.props;

        if (!products) {
            return null;
        }

        return (
            <Wrapper id='atelier-workplan--athomefacial'>
                {this.renderCategories()}
            </Wrapper>
        );
    }
}

const mapStateToProps = ({ beautyplan: { currentPlan: { atHomeFacialProducts, makeupArtistName } } }) => {
    return {
        products: atHomeFacialProducts,
        staffFirstName: makeupArtistName.split(' ')[0]
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AtHomeFacialCustomer);
