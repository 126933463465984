import { QUESTIONNAIRE_TYPES } from 'constants/questionnaire';
import { store } from 'store';
import { LOCATIONS, LOCATION_TEXT, VIRTUAL_LOCATION } from './locations';
export const LocationId = '1';
export const ARTIST_PARAM = 'artist';
export const SESSION_TYPES_PARAM = 'sessionTypes';

/**
 * Compute the questionnaire type given a session type
 * @param {number} sessionType
 * @return {object} questionnaire type
 */
export const getQuestionnaireTypeForSessionType = (sessionType) => {
    const sessions = store.getState().booking.sessions;

    const ApplicationQuestionnaire = {
        id: sessions.Application.All.id,
        questionnaireType: QUESTIONNAIRE_TYPES.MAKEUP_APPLICATION
    };
    const LessonQuestionnaire = {
        id: sessions.Lesson.All.id,
        questionnaireType: QUESTIONNAIRE_TYPES.MAKEUP_SKINCARE
    };
    const SkincareChatQuestionnaire = {
        id: sessions.SkincareChat.All.id,
        questionnaireType: QUESTIONNAIRE_TYPES.SKIN_CHAT
    };
    const MakeupChatQuestionnaire = {
        id: sessions.MakeupChat.All.id,
        questionnaireType: QUESTIONNAIRE_TYPES.MAKEUP_CHAT
    };
    const SkincareWorkshopQuestionnaire = {
        id: sessions.GroupChat.All.id,
        questionnaireType: QUESTIONNAIRE_TYPES.SKINCARE_WORKSHOP
    };
    const VirtualBrowServiceQuestionnaire = {
        id: sessions.VirtualBrowService.All.id,
        questionnaireType: QUESTIONNAIRE_TYPES.VIRTUAL_BROW_GOALS
    };
    const FragranceExperienceQuestionnaire = {
        id: [
            ...sessions.FragranceDiscoveryInPerson.All.id,
            ...sessions.FragranceDiscoveryVirtual.All.id,
            ...sessions.FragranceImmersion.All.id,
            ...sessions.GroupFragranceDiscoveryInPerson.All.id
        ],
        questionnaireType: QUESTIONNAIRE_TYPES.FRAGRANCE_EXPERIENCE
    };

    if (ApplicationQuestionnaire.id.includes(sessionType)) {
        return ApplicationQuestionnaire.questionnaireType;
    }

    if (SkincareChatQuestionnaire.id.includes(sessionType)) {
        return SkincareChatQuestionnaire.questionnaireType;
    }

    if (MakeupChatQuestionnaire.id.includes(sessionType)) {
        return MakeupChatQuestionnaire.questionnaireType;
    }

    if (SkincareWorkshopQuestionnaire.id.includes(sessionType)) {
        return SkincareWorkshopQuestionnaire.questionnaireType;
    }

    if (VirtualBrowServiceQuestionnaire.id.includes(sessionType)) {
        return VirtualBrowServiceQuestionnaire.questionnaireType;
    }

    if (FragranceExperienceQuestionnaire.id.includes(sessionType)) {
        return FragranceExperienceQuestionnaire.questionnaireType;
    }

    return LessonQuestionnaire.questionnaireType;
};

export const getBookingLocation = (sessionTypeId) => {
    const { sessions, idToSessionMap } = store.getState().booking;

    let location = LOCATION_TEXT[LOCATIONS.NYC];
    const sessionKey = idToSessionMap[sessionTypeId];

    if (sessionKey) {
        const session = sessions[sessionKey];

        if (session && session.All) {
            const isInNycIds = session.All.nycId ? [...session.All.nycId, session.All.nycCompId].includes(sessionTypeId) : false;
            const isInAustinIds = session.All.austinId ? [...session.All.austinId, session.All.austinCompId].includes(sessionTypeId) : false;

            if (session.All.isVirtualService || (isInNycIds && isInAustinIds)) {
                location = VIRTUAL_LOCATION;
            } else if (!isInNycIds && isInAustinIds) {
                location = LOCATION_TEXT[LOCATIONS.AUSTIN];
            }
        }
    }

    return location;
};
