import styled from 'styled-components';
import CloseIcon from 'assets/close-icon-modal.svg';
import { LAYER } from 'constants/layers';
const size = 24;

export default styled.div`
    position: absolute;
    cursor: pointer;
    right: ${({ center }) => center ? '50%' : `${size}px`};
    top: ${({ center }) => center ? '10%' : `${size}px`};
    ${({ center }) => center ? 'transform: translate(50%, -50%)' : ''};
    width: ${size}px;
    height: ${size}px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    background: url(${CloseIcon}) no-repeat; 
    &:hover {
        opacity: 0.5;
    }
    z-index: ${LAYER.HEADER} 
`;
