import React, { Component } from 'react';
import LayoutContainer from 'containers/LayoutContainer/LayoutContainer';
import Loader from 'components/GlobalComponents/Loader/Loader';

export default class SuspensePage extends Component {

    render() {
        return (
            <LayoutContainer showFooter={false} showEmptyFooter={false}>
                <Loader fullScreen/>
            </LayoutContainer>
        );
    }
}
