import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { searchProductsBySkus } from './elasticsearch';
import normalizeProduct from 'core/utils/normalizeProduct';
import moment from 'moment-timezone';
import normalizeBookings from 'core/utils/normalizeBookings';
import isProd from 'core/utils/isProd';
import { getEventDatas } from 'constants/events';

const VIDEO_PARAMETERS = 'VIDEO_PARAMETERS';
const THANKYOU_PARAMETERS = 'THANKYOU_PARAMETERS';
const VIDEO_THANK_YOU_PRODUCTS = 'VIDEO_THANK_YOU_PRODUCTS';
const VIDEO_FETCH_BOOKINGS = 'VIDEO_FETCH_BOOKINGS';
const VIDEO_RESET = 'VIDEO_RESET';
const SET_VIDEO_PARAMS = 'SET_VIDEO_PARAMS';
const SEND_TRACKING_NUMBER = 'SEND_TRACKING_NUMBER';
const GET_VIDEO_RECORDING = 'GET_VIDEO_RECORDING';
const GET_VIDEO_RECORDING_ID = 'GET_VIDEO_RECORDING_ID';

const initialState = {
    productsFetching: false,
    products: [],
    bookings: [],
    bookingsFetching: false,
    bookingsError: '',
    videoParams: null,
    emailSendingFetching: false,
    videoRecordingFetchingId: null
};

export const resetVideo = createAction(VIDEO_RESET);
const setVideoParams = createAction(SET_VIDEO_PARAMS);

const getVideoParameters = createAction(FETCH, (videoId, host, guestToken) => (
    {
        prefix: VIDEO_PARAMETERS,
        endpoint: `${CONFIG.API_URL}${host ? '/staff' : ''}/video/params/${videoId}${guestToken && !host ? `/${guestToken}` : ''}`,
        options: {
            method: 'GET'
        }
    }
));

const getThankYouParams = createAction(FETCH, videoId => (
    {
        prefix: THANKYOU_PARAMETERS,
        endpoint: `${CONFIG.API_URL}/video/thankyou/${videoId}`,
        options: {
            method: 'GET'
        }
    }
));

const searchVideoBookings = createAction(FETCH, (startDate, endDate, type) => {
    const queryParams = new URLSearchParams();

    if (startDate) {
        queryParams.append('startDate', startDate);
    }

    if (endDate) {
        queryParams.append('endDate', endDate);
    }

    if (type) {
        queryParams.append('type', type);
    }

    return {
        prefix: VIDEO_FETCH_BOOKINGS,
        endpoint: `${CONFIG.API_URL}/video/search${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
        options: {
            method: 'GET'
        }
    };
});

export const sendTrackingEmail = createAction(FETCH, (isAppointment, customerId, id, trackingNumber, trackingNumber2) => ({
    prefix: SEND_TRACKING_NUMBER,
    endpoint: `${CONFIG.API_URL}${isAppointment ? '/appointment/shipping' : '/masterclassbox/shipping'}`,
    options: {
        method: 'POST',
        body: JSON.stringify({
            customerId,
            [isAppointment ? 'appointmentId' : 'eventId']: id,
            trackingNumber,
            trackingNumber2
        })
    }
}));

export const setVideoRecordingSearchId = createAction(GET_VIDEO_RECORDING_ID);

export const getVideoRecording = createAction(FETCH, videoId => ({
    prefix: GET_VIDEO_RECORDING,
    endpoint: `${CONFIG.API_URL}/video/recording/${videoId}`,
    options: {
        method: 'GET'
    }
}));

const getAppointmentArtistName = (artistFullName, thankYou) => {
    if (thankYou) {
        return artistFullName;
    }

    const splitName = artistFullName.split(' ');

    return `${splitName[0]} ${splitName[1][0]}`;
};

const getPolaroidForVideo = (getState, artistName) => {
    const { makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();

    const artists = [...artistData, ...residencyArtistData, ...virtualArtistData];

    let polaroid = 'https://images.prismic.io/atelier%2Fad277a59-e8f5-4249-bbac-fdc57f72d003_stamp-pink.png?auto=compress,format&w=300';

    const artist = artists.find(artist => artist.name.toLowerCase().includes(artistName.toLowerCase().split(' ')[0]));

    if (artist && artist.polaroid) {
        polaroid = artist.polaroid;
    }

    return polaroid;
};

const getWaitroomPicture = (getState, artistName, isAppointment, params) => {
    const { makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();

    const artists = [...artistData, ...residencyArtistData, ...virtualArtistData];

    let polaroid = 'https://images.prismic.io/atelier/d7becf61-68d1-4bb5-b672-cc1794ff520b_generic-waitroom.jpg?auto=compress,format';

    if (!isAppointment) {
        const eventData = getEventDatas()[params.classDescriptionID];

        if (!!eventData && eventData.waitroom_image && eventData.waitroom_image.url) {
            return eventData.waitroom_image.url;
        }
    }

    const artist = artists.find(artist => artist.name.toLowerCase().includes(artistName.toLowerCase().split(' ')[0]));

    if (artist && artist.waitroomPicture) {
        polaroid = artist.waitroomPicture;
    }

    return polaroid;
};

const getArtistChatIcon = (getState, artistName, isAppointment, params) => {
    const { makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();

    const artists = [...artistData, ...residencyArtistData, ...virtualArtistData];

    let chatIcon;

    if (!isAppointment) {
        const eventData = getEventDatas()[params.classDescriptionID];

        if (!!eventData && eventData.artist_chat_icon && eventData.artist_chat_icon.url) {
            return eventData.artist_chat_icon.url;
        }
    }

    const artist = artists.find(artist => artist.name.toLowerCase().includes(artistName.toLowerCase().split(' ')[0]));

    if (artist && artist.chatIcon) {
        chatIcon = artist.chatIcon;
    }

    return chatIcon;
};

const getPrismicFullName = (artistName, getState) => {
    const { makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();
    const artists = [...artistData, ...residencyArtistData, ...virtualArtistData];

    const artist = artists.find(artist => artist.name.toLowerCase().includes(artistName.toLowerCase()));

    if (artist) {
        return artist.name;
    } else {
        return artistName;
    }
};

const getSessionInfoText = (type, params, isAppointment, getState) => {
    let waitroomTexts;

    if (isAppointment) {
        const { booking: { sessions, idToSessionMap } } = getState();
        waitroomTexts = sessions[idToSessionMap[params.appointmentSessionTypeID]].All.waitroom;
    } else {
        const eventData = getEventDatas()[params.classDescriptionID];
        waitroomTexts = {
            guest: eventData.waitroom_guest,
            host: eventData.waitroom_host,
            artist: eventData.waitroom_artist,
            staff: eventData.waitroom_staff
        };
    }

    return waitroomTexts[type];
};

const getAppointmentSessionEndDate = (sessionType, startDateTime, getState) => {
    const { services: { definitions } } = getState();

    const sessionLength = definitions && definitions[sessionType] && definitions[sessionType].appointmentType && definitions[sessionType].appointmentType.session_length ?
        definitions[sessionType].appointmentType.session_length : 30; // defaulting 30 min appt

    return moment.tz(startDateTime, 'America/New_York').add(sessionLength, 'm');
};

const getUserName = (type, artistName, firstName, lastName, thankYou) => {
    let userName;

    switch (type) {
        case 'artist':
            userName = artistName;
            break;
        case 'host':
            userName = `${firstName} ${lastName}`;
            break;
        default:
            userName = thankYou ? `${firstName} ${lastName}` : `${firstName} ${lastName[0]}`;
    }

    return userName;
};

const getHostNameAndChatIcon = (type, isAppointment, userName, artistName, params) => {
    let hostName, hostChatIcon;

    switch (type) {
        case 'host':
        //case 'staff':
            hostName = userName;
            break;
        default:
            hostName = artistName;

            if (!isAppointment && !!params) {
                const eventData = getEventDatas()[params.classDescriptionID];
                if (!!eventData && eventData.event_host_name) {
                    hostName = eventData.event_host_name;
                }
                if (!!eventData && eventData.host_chat_icon && eventData.host_chat_icon.url) {
                    hostChatIcon = eventData.host_chat_icon.url;
                }
            }
    }

    return {
        hostName,
        hostChatIcon
    };
};

const generateVideoParams = (response, getState, type = 'guest', isAppointment = false, thankYou = false, forcedGuest = false) => {
    const { user: { auth: { profile: { firstName, lastName } } } } = getState();

    //const isHostOrStaff = ['host', 'staff'].includes(type);
    const params = isAppointment ? response.appointment[0] : response.event[0];

    let artistName, videoUserId;

    artistName = isAppointment ? getAppointmentArtistName(params.appointmentStaffName, true) : `${params.staffFirstName} ${params.staffLastName}`;
    artistName = getPrismicFullName(artistName, getState);

    if (thankYou) {
        let products = [];

        if (isAppointment) {
            const apptWithProducts = response.appointment.find(appt => !!appt.products && appt.products.length);
            products = apptWithProducts ? apptWithProducts.products : products;
        } else {
            const eventWithProducts = response.event.find(event => !!event.products && event.products.length);
            products = eventWithProducts ? eventWithProducts.products : products;
        }

        return {
            type: type,
            class_name: isAppointment ? params.appointmentSessionTypeName : params.name,
            artist_name: artistName,
            time_start: moment.tz(isAppointment ? params.appointmentStartDateTime : params.startDateTime, 'America/New_York').unix(),
            artist_photo_url: getPolaroidForVideo(getState, isAppointment ? params.appointmentStaffName : params.staffFirstName), // need to save and retrieve this from Prismic
            artist_photo: '', // only for base64
            products,
            serviceType: params.type,
            endDateTime: isAppointment ? getAppointmentSessionEndDate(params.type, params.appointmentStartDateTime, getState) : moment.tz(params.endDateTime, 'America/New_York'),
            sessionTypeID: !isAppointment ? params.classDescriptionID : undefined
        };
    }

    //const userName = type === 'artist' ? artistName : `${firstName} ${thankYou ? lastName : lastName[0]}`;
    const userName = getUserName(type, artistName, firstName, lastName, thankYou);
    //const hostName = isHostOrStaff ? userName : artistNameInitial;
    const { hostName, hostChatIcon } = getHostNameAndChatIcon(type, isAppointment, userName, artistName, params);

    const artistChatIcon = getArtistChatIcon(getState, artistName, isAppointment, params);

    switch (type) {
        case 'host':
            videoUserId = `host${Math.floor(Math.random() * 100)}`;
            break;
        case 'staff':
            videoUserId = `staff${Math.floor(Math.random() * 100)}`;
            break;
        case 'artist':
            videoUserId = `artist${Math.floor(Math.random() * 100)}`;
            break;
        default:
            videoUserId = forcedGuest ? `guest${Math.floor(Math.random() * 100)}` : params.video.userId;
    }

    const isFragrance = ['fragrance_discovery_virtual', 'fragrance_immersion_virtual', 'group_fragrance_discovery_virtual'].includes(params.type);
    const isVirtualGroup = ['group_fragrance_discovery_virtual', 'group_skincare_chat', 'group_makeup_chat'].includes(params.type);

    return {
        API_Key: response.jWTToken.API_Key,
        token: params.video.token,
        roomName: `${params.video.roomId}`,
        type: type,
        class_name: isAppointment ? params.appointmentSessionTypeName.replace(' (virtual)', '') : params.name,
        artist_name: artistName,
        host_name: hostName,
        time_start: moment.tz(isAppointment ? params.appointmentStartDateTime : params.startDateTime, 'America/New_York').unix(),
        username: userName,
        session_info: getSessionInfoText(type, params, isAppointment, getState),
        redirect_url: '',
        host_thankyou_url: `https://${isProd() ? '' : 'onehope:3e2LgA3P7n@'}${window.location.hostname}/api/v1/video/hostredirect`,
        guest_thankyou_url: `https://${isProd() ? '' : 'onehope:3e2LgA3P7n@'}${window.location.hostname}/api/v1/video/guestredirect`,
        artist_photo_url: getWaitroomPicture(getState, isAppointment ? params.appointmentStaffName : params.staffFirstName, isAppointment, params), // need to save and retrieve this from Prismic
        artist_photo: '', // only for base64
        video_user_id_oh: videoUserId,
        client_type: isAppointment ? (isVirtualGroup ? 'class' : 'chat') : 'conference',
        products: params.products,
        serviceType: params.type,
        endDateTime: isAppointment ? getAppointmentSessionEndDate(params.type, params.appointmentStartDateTime, getState) : moment.tz(params.endDateTime, 'America/New_York'),
        hostChatIcon: isAppointment ? artistChatIcon : hostChatIcon,
        artistChatIcon: artistChatIcon,
        isFragrance
    };
};

export const navigateToVideo = (videoId, type = 'guest', thankYou = false, guestToken) => {
    return (dispatch, getState) => {
        return dispatch(thankYou ? getThankYouParams(videoId) : getVideoParameters(videoId, ['host', 'staff'].includes(type), guestToken))
            .then(response => {
                const isAppointment = videoId.startsWith('a');
                const responseItems = isAppointment ? response.appointment : response.event;

                if (!responseItems || !responseItems.length) {
                    return Promise.reject();
                }

                const videoParams = generateVideoParams(response, getState, type, isAppointment, thankYou, type === 'guest' && !!guestToken);
                return Promise.resolve(dispatch(setVideoParams(videoParams)));
            });
    };
};

export const fetchThankYouItems = (videoId, type) => {
    return (dispatch, getState) => {
        return dispatch(navigateToVideo(videoId, type, true, undefined))
            .then(() => {
                const { video: { videoParams: { products } } } = getState();

                if (products && products.length) {
                    const productsSkus = products.reduce((accum, selectedProducts) => {
                        accum.push(...selectedProducts.sku);
                        return accum;
                    }, []);

                    const uniqueSkus = productsSkus.filter((sku, index) => productsSkus.indexOf(sku) === index);
                    return dispatch(searchProductsBySkus(uniqueSkus, VIDEO_THANK_YOU_PRODUCTS));
                } else {
                    return Promise.resolve();
                }
            });
    };
};

export const fetchVideoBookings = (startDate, endDate, type) => {
    return (dispatch) => {
        return dispatch(searchVideoBookings(startDate, endDate, type));
    };
};

export default handleActions({
    [`${VIDEO_THANK_YOU_PRODUCTS}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        productsFetching: true,
    }),
    [`${VIDEO_THANK_YOU_PRODUCTS}/${FETCH_SUCCESS}`]: (state, { payload: { docs } }) => ({
        ...state,
        productsFetching: false,
        products: docs.map(product => [normalizeProduct(product)]).filter(p => p.length),
    }),
    [`${VIDEO_FETCH_BOOKINGS}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        bookingsFetching: true,
    }),
    [`${VIDEO_FETCH_BOOKINGS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        bookingsFetching: false,
        bookings: normalizeBookings(payload.result),
    }),
    [`${VIDEO_FETCH_BOOKINGS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        bookingsFetching: false,
        bookingsError: payload,
    }),
    [`${SEND_TRACKING_NUMBER}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        emailSendingFetching: true
    }),
    [`${SEND_TRACKING_NUMBER}/${FETCH_SUCCESS}`]: (state) => ({
        ...state,
        emailSendingFetching: false
    }),
    [`${SEND_TRACKING_NUMBER}/${FETCH_ERROR}`]: (state) => ({
        ...state,
        emailSendingFetching: false
    }),
    [GET_VIDEO_RECORDING_ID]: (state, { payload }) => ({
        ...state,
        videoRecordingFetchingId: payload
    }),
    [`${GET_VIDEO_RECORDING}/${FETCH_SUCCESS}`]: (state) => ({
        ...state,
        videoRecordingFetchingId: null
    }),
    [`${GET_VIDEO_RECORDING}/${FETCH_ERROR}`]: (state) => ({
        ...state,
        videoRecordingFetchingId: null
    }),
    [SET_VIDEO_PARAMS]: (state, { payload }) => ({
        ...state,
        videoParams: payload
    }),
    [VIDEO_RESET]: (state) => ({
        ...state,
        initialState
    })
}, initialState);
