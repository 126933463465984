import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import styled, { css } from 'styled-components';
import { addSample, toggleLikeList, updateFavouriteList } from 'reducers/userList';
import { addToCart } from 'reducers/cart';
import { styledProps, rem, greaterThan } from 'core/styled';
import { LAYER } from 'constants/layers';
import SpecialTag from 'components/GlobalComponents/Fonts/product/SpecialTag';
import { analyticsTag } from 'reducers/analytics';
import { isPurchaseLimitReached, qtyItemInCart } from 'reducers/cart';
import Button from 'components/GlobalComponents/Button/Button';
import SampleModal from 'components/ProductComponents/LikeListButtons/SampleModal';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import { eCommAddToBag, EVENTS } from 'constants/analytics';
import { breakpoints } from 'constants/theme';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import externalLinkIcon from 'assets/icons/external-link-light.svg';
import { LOCATIONS } from 'constants/locations';

const LikeListButtonsWrapper = styled(Flex)`
`;

const IconWrapper = styled.div`
    width: ${({ isVto }) => rem(isVto ? 55 : 20)};
    height: auto;
    max-height: 22px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const SpecialCaseWrapper = styled.div`
    position: absolute;
    top: 60px;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
    
    ${greaterThan(breakpoints.medium)(css`
        top: 35px;
    `)}
`;

const SpecialCaseArrow = styled.div`
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 11px;
    overflow: hidden;
    z-index: ${LAYER.BOX_BORDER_ARROW};
    
    &:after {
        content: '';
        top: 6px;
        width: 30px;
        height: 30px;
        background: ${styledProps('color', 'white')};
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border: 1px solid ${styledProps('color', 'silverChalice')};  
    } 
`;

const SpecialCaseArrowMask = styled.div`
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 11px;
    overflow: hidden;
    z-index: ${LAYER.BOX_BORDER_ARROW};
    
    &:after {
        content: '';
        top: 6px;
        width: 30px;
        height: 30px;
        background: ${styledProps('color', 'white')};
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border: 1px solid ${styledProps('color', 'white')};  
    } 
`;

const StyledBox = styled(Box)`
    padding: ${({ iconPadding }) => rem(...iconPadding)};
    ${({ isVto }) => isVto ? `padding-left: ${rem(0)}; padding-right: ${rem(0)};` : ''}
`;

const BrowseAsButton = styled(Button)`
    min-width: ${rem(220)};
    margin: ${rem(0, 5)};
`;

const VtoText = styled.span`
    font-family: ${styledProps('font', 'ABChanelPBS')};
    font-weight: 600;
    font-size: ${rem(8)};
    display: inline-blocK;
`;

const VtoImg = styled.img`
    vertical-align: middle;
`;

class LikeListButtons extends Component {
    static propTypes = {
        addToCart: PropTypes.func.isRequired,
        addSample: PropTypes.func.isRequired,
        product: PropTypes.object.isRequired,
        toggleLikeList: PropTypes.func.isRequired,
        isLiked: PropTypes.bool.isRequired,
        isDisliked: PropTypes.bool.isRequired,
        isSampled: PropTypes.bool.isRequired,
        isFavourite: PropTypes.bool.isRequired,
        isStaff: PropTypes.bool.isRequired,
        isImpersonating: PropTypes.bool.isRequired,
        userName: PropTypes.string.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        browseAs: PropTypes.func,
        isSpecialCasePurchase: PropTypes.bool,
        specialCasePurchaseLabel: PropTypes.string,
        canAddToBag: PropTypes.bool,
        qtyItemInCart: PropTypes.number,
        iconPadding: PropTypes.array,
        renderLikeButton: PropTypes.bool,
        renderDislikeButton: PropTypes.bool,
        renderAddToBagButton: PropTypes.bool,
        renderSampleButton: PropTypes.bool,
        renderVTOButton: PropTypes.bool,
        iconHeight: PropTypes.number, // height of icon
        showFavouriteButton: PropTypes.bool,
        favouriteMeetingId: PropTypes.string,
        updateFavouriteList: PropTypes.func.isRequired,
        hasVto: PropTypes.bool.isRequired,
        authenticated: PropTypes.bool.isRequired,
        isQuickShop: PropTypes.bool,
        isRecommended: PropTypes.bool
    };

    static defaultProps = {
        isLiked: false,
        isDisliked: false,
        isFavourite: false,
        canAddToBag: true,
        qtyItemInCart: 0,
        isSpecialCasePurchase: false,
        iconPadding: [30, 15],
        renderLikeButton: true,
        renderDislikeButton: false,
        renderAddToBagButton: true,
        renderSampleButton: true,
        renderVTOButton: false,
        iconHeight: 22,
        showFavouriteButton: false,
        isQuickShop: false,
        isRecommended: false
    };

    state = {
        hasBeenAddedToLikeList: false,
        hasBeenAddedToDislike: false,
        hasBeenRemovedFromLikeList: false,
        hasBeenRemovedFromDislikeList: false,
        hasBeenAddedToCart: false,
        hasBeenAddedToFavourite: false
    };

    constructor(props) {
        super(props);
        this.sampleModal = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLiked !== this.props.isLiked) {
            this.setState({
                hasBeenAddedToLikeList: this.props.isLiked
            });
        }

        if (prevProps.isDisliked !== this.props.isDisliked) {
            this.setState({
                hasBeenAddedToDislike: this.props.isDisliked
            });
        }

        if (prevProps.qtyItemInCart < this.props.qtyItemInCart) {
            this.setState({
                hasBeenAddedToCart: true
            });
        }

        if (prevProps.isFavourite !== this.props.isFavourite) {
            this.setState({
                hasBeenAddedToFavourite: this.props.isFavourite
            });
        }
    }

    /**
     * Add product to cart
     * @param {object} product
     */
    addProductToCart = (product) => {
        const { addToCart, analyticsTag, authenticated, isQuickShop, isRecommended } = this.props;
        this.setState({
            hasBeenAddedToCart: false
        }, () => {
            if (authenticated) {
                addToCart(product, isQuickShop);
            } else {
                addToCart(product, isQuickShop);
            }
            analyticsTag(eCommAddToBag(product), { userInfo: true });

            if (isRecommended) {
                // handle recommended analytics
                analyticsTag(
                    {
                        event: EVENTS.GA,
                        eventCategory: 'recommendedproduct',
                        eventAction: isQuickShop ? 'quickshopaddtobag' : 'addtobag',
                        eventLabel: product.sku
                    },
                    { userInfo: true }
                );
            }
        });
    };

    handleBrowseAs() {
        this.props.analyticsTag({
            event: EVENTS.GA,
            eventCategory: 'impersonate',
            eventAction: this.props.product.sku,
            eventLabel: 'browseas'
        }, { userInfo: true });
        this.props.browseAs();
    }

    handleLikeClick = nextLike => () => {
        const { product, showFavouriteButton, toggleLikeList, favouriteMeetingId, updateFavouriteList, isQuickShop, isRecommended } = this.props;

        if (showFavouriteButton) {
            updateFavouriteList([product.sku], favouriteMeetingId);
        } else {
            toggleLikeList(product, nextLike, true, isQuickShop, isRecommended);
        }
    };

    handleVtoClick = () => {
        const { product: { sku }, analyticsTag } = this.props;

        analyticsTag({
            event: EVENTS.GA,
            eventCategory: 'product module',
            eventLabel: sku,
            eventAction: 'tryon'
        }, { userInfo: true });

        window.open(`https://chanel.com/us/p/${sku}?utm_source=atelier&utm_campaign=vto&utm_medium=referral`, '_blank');
    };

    render() {
        const { product, isSpecialCasePurchase, specialCasePurchaseLabel, isLiked, isSampled, isDisliked, canAddToBag,
            qtyItemInCart, iconPadding, renderLikeButton, renderDislikeButton, renderAddToBagButton, isStaff,
            isImpersonating, addSample, userName, renderSampleButton, iconHeight, showFavouriteButton, isFavourite,
            renderVTOButton, hasVto } = this.props;

        const { hasBeenAddedToLikeList, hasBeenAddedToDislike, hasBeenAddedToCart, hasBeenAddedToFavourite } = this.state;

        if (isStaff && !isImpersonating) {
            return (
                <Flex justifyContent={'center'} marginBottom={rem(20)} marginTop={rem(15)}>
                    <BrowseAsButton onClick={() => this.handleBrowseAs()}>Browse as</BrowseAsButton>
                </Flex>

            );
        }

        const shopOnClick = () => canAddToBag ? this.addProductToCart(product) : {};

        return (
            <LikeListButtonsWrapper justifyContent={'center'} alignItems={'center'} fullHeight={false}>
                {renderLikeButton &&
                <StyledBox iconPadding={iconPadding}>
                    <IconWrapper>
                        <NoStyleButton type={'button'} onClick={this.handleLikeClick(true)} aria-pressed={showFavouriteButton ? isFavourite : isLiked}>
                            <Icon type={ICON_TYPES.LIKE}
                                selected={showFavouriteButton ? isFavourite : isLiked}
                                successPositive={showFavouriteButton ? hasBeenAddedToFavourite : hasBeenAddedToLikeList}
                                pixelHeight={iconHeight}
                                renderSuccessMessage={true}
                                alt={showFavouriteButton ? 'add to favourites' : 'add to wishlist'}/>
                        </NoStyleButton>
                    </IconWrapper>
                </StyledBox>
                }
                {renderDislikeButton && !showFavouriteButton &&
                <StyledBox iconPadding={iconPadding}>
                    <IconWrapper>
                        <NoStyleButton type={'button'} onClick={this.handleLikeClick(false)} aria-pressed={isDisliked}>
                            <Icon type={ICON_TYPES.DISLIKE} selected={isDisliked} successPositive={hasBeenAddedToDislike} pixelHeight={iconHeight} renderSuccessMessage={true} alt={'add to undesired list'}/>
                        </NoStyleButton>
                    </IconWrapper>
                </StyledBox>
                }
                {renderVTOButton && hasVto &&
                <StyledBox iconPadding={iconPadding} isVto>
                    <IconWrapper isVto>
                        <NoStyleButton type={'button'} onClick={this.handleVtoClick} aria-pressed={showFavouriteButton ? isFavourite : isLiked}>
                            <Icon type={ICON_TYPES.VTO}
                                pixelHeight={iconHeight + 4}
                                alt={'try on'}/>
                            <VtoText>Try On <VtoImg src={externalLinkIcon} alt={''}/></VtoText>
                        </NoStyleButton>
                    </IconWrapper>
                </StyledBox>
                }
                {renderAddToBagButton &&
                <StyledBox iconPadding={iconPadding}>
                    <IconWrapper>
                        <NoStyleButton type={'button'} onClick={shopOnClick} aria-pressed={qtyItemInCart > 0}>
                            <Icon type={ICON_TYPES.SHOP} pixelHeight={iconHeight} successPositive={hasBeenAddedToCart} selected={qtyItemInCart > 0} renderSuccessMessage={true} disabled={!canAddToBag} alt={'add to cart'}/>
                        </NoStyleButton>
                        {isSpecialCasePurchase &&
                        <SpecialCaseWrapper>
                            <SpecialCaseArrow />
                            <SpecialCaseArrowMask />
                            <SpecialTag fontSize={9}>{specialCasePurchaseLabel}</SpecialTag>
                        </SpecialCaseWrapper>
                        }
                    </IconWrapper>
                </StyledBox>
                }
                {isImpersonating && renderSampleButton &&
                <StyledBox iconPadding={iconPadding}>
                    <IconWrapper>
                        <NoStyleButton type={'button'} onClick={() => this.sampleModal.current.toggleModal()} aria-pressed={isSampled}>
                            <Icon
                                type={ICON_TYPES.SAMPLE}
                                pixelHeight={iconHeight}
                                selected={isSampled}
                                renderSuccessMessage={true}
                                alt={'add to sampled list'}/>
                        </NoStyleButton>
                    </IconWrapper>
                </StyledBox>
                }
                { isImpersonating && renderSampleButton &&
                <SampleModal product={product}
                    userName={userName}
                    addSample={addSample}
                    ref={this.sampleModal}
                />
                }
            </LikeListButtonsWrapper>
        );
    }
}

const mapStateToProps = ({ userList: { like, sampled, favourites }, cart: { items, cartLocation }, user: { auth }, products: { vtoProducts } }, { product, showFavouriteButton, favouriteMeetingId }) => {
    const favouriteMeeting = showFavouriteButton ? favourites.find(fav => fav.meetingid === favouriteMeetingId) : undefined;

    const isOutOfStock = cartLocation === LOCATIONS.AUSTIN ? product.isOutOfStock2 : product.isOutOfStock;

    return {
        isLiked: like.some(l => l.sku === product.id && l.userdata.like),
        isDisliked: like.some(l => l.sku === product.id && !l.userdata.like),
        isSampled: sampled.some(s => s.sku === product.id),
        isFavourite: favouriteMeeting ? favouriteMeeting.skus.some(sku => sku === product.id) : false,
        canAddToBag: !isOutOfStock && !isPurchaseLimitReached(items, product.id),
        qtyItemInCart: qtyItemInCart(items, product.id),
        userName: `${auth.profile.firstName} ${auth.profile.lastName}`,
        hasVto: vtoProducts.includes(product.sku),
        ...auth
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ push, toggleLikeList, addToCart, addSample, analyticsTag, updateFavouriteList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LikeListButtons);
