const loadScript = (scriptUrl, content) => {
    const script = document.createElement('script');
    script.src = scriptUrl;

    if (content) {
        script.innerHTML = content;
    }

    document.body.appendChild(script);

    return new Promise((res, rej) => {
        script.onload = function() {
            res();
        };
        script.onerror = function () {
            rej();
        };
    });
};

export default loadScript;


// This is an enhacement to only load script once :)
// Keeping here for reference if requried

/* const loadScript = (scriptUrl, content, uniqueId) => {
    if (!uniqueId || !document.getElementById(uniqueId)) {
        const script = document.createElement('script');

        if (scriptUrl) {
            script.src = scriptUrl;
        }

        if (!!content) {
            script.innerHTML = content;
        }

        if (uniqueId) {
            script.id = uniqueId;
        }

        document.body.appendChild(script);

        return new Promise((res, rej) => {
            script.onload = function() {
                res();
            };
            script.onerror = function () {
                rej();
            };
        });
    } else {
        return Promise.resolve();
    }
};

export default loadScript; */
