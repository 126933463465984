import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { inputStyle, rem, styledProps } from 'core/styled';
import { phonePattern } from 'constants/regex';
import PropTypes from 'prop-types';

const StyledInputMask = styled(InputMask)`
    ${inputStyle};
    outline: none;
`;


export const HintWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 28px;
    height: 18px;
    width: 18px;      
`;

export const HintData = styled.div`
    position: absolute;
    background: ${styledProps('color', 'black')};
    color: ${styledProps('color', 'white')};
    top: -18px;
    right: -10px;
    transform: translate(0, -100%);
    width: max-content;
    max-width: 70vw;
    min-width: 280px;
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(12)};
    padding: ${rem(20)};
    opacity: 0;
    z-index: -1;
    
    &:after {
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background: ${styledProps('color', 'black')};
        transform: rotate(45deg);
        position: absolute;
        right: 6px;
        bottom: -2px;
    }
`;

export const PhoneHint = styled.span`
    border: 1px solid ${styledProps('color', 'black')};
    border-radius: 50%;
    height: 18px;
    width: 18px;
    font-size: ${rem(12)};
    line-height: ${rem(14)};
    text-align: center;
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    color: ${styledProps('color', 'black')};
    z-index: 2;
    display: block;
    
    &:focus, &:hover {
        background: ${styledProps('color', 'black')};    
        color: ${styledProps('color', 'white')};
        
        + div {
           opacity: 1;
           z-index: 2;
        }   
    }
`;

export const PhoneInputWrapper = styled.div`
    position: relative;
`;

//todo: the phone hint should be part of this component. For now, let's just put the styles here to avoid repeating them.
export default class PhoneInput extends Component {

    static propTypes = {
        ariaLabel: PropTypes.string,
        alwaysMobile: PropTypes.bool
    };

    render() {
        const { ariaLabel, alwaysMobile, ...rest } = this.props;

        return (
            <StyledInputMask
                {...rest}
                aria-label={ariaLabel}
                pattern={phonePattern}
                mask="+1 (999) 999-9999"
                maskChar="_"
            />
        );
    }
}
