import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { LAYER } from 'constants/layers';
import { rem, styledProps } from 'core/styled';
import { ROUTES } from 'constants/routes';
import { breakpointValues } from 'constants/theme';
import BACK_ICON from 'assets/back-caret.svg';
import CLOSE_ICON from 'assets/close-icon.svg';
import LOGO from 'assets/logo.svg';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';

const HeaderWrapper = styled.header`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${LAYER.HEADER};
    background-color: ${styledProps('color', 'white')};
    height: ${rem(80)};
    border-bottom: ${rem(1)} solid ${styledProps('color', 'lightGray')};
    transform: translateY(${({ expanded }) => (expanded ? rem(0) : rem(-80))});
    transition: transform 0.2s ease;

    .header-content {
        width: 100%;
        height: 100%;
        max-width: ${rem(breakpointValues.small)};
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const ButtonWrapper = styled(Link)`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${({ $rightButton }) =>
        $rightButton ? 'flex-end' : 'flex-start'};
    height: 100%;
    padding: ${rem(0, 25)};
    width: ${rem(68)};
`;

const Icon = styled.img`
    height: ${rem(18)};
    width: auto;
    display: block;
`;

const LogoWrapper = styled(Link)`
    cursor: pointer;
`;

const Logo = styled.img`
    height: ${rem(50)};
    width: auto;
    display: block;
`;

class QuickShopHeader extends Component {
    static propTypes = {
        state: PropTypes.oneOf(['expanded', 'collapsed']).isRequired,
        history: PropTypes.object.isRequired
    };

    render() {
        const { state, history } = this.props;

        return (
            <HeaderWrapper expanded={state === 'expanded'}>
                <div className={'header-content'}>
                    <ButtonWrapper as={NoStyleButton} onClick={() => history.goBack()}>
                        <Icon src={BACK_ICON} alt={'go back'} />
                    </ButtonWrapper>
                    <LogoWrapper to={ROUTES.HOME}>
                        <Logo src={LOGO} alt={'go home'} />
                    </LogoWrapper>
                    <ButtonWrapper to={ROUTES.HOME} $rightButton>
                        <Icon src={CLOSE_ICON} alt={'go home'} />
                    </ButtonWrapper>
                </div>
            </HeaderWrapper>
        );
    }
}

export default withRouter(QuickShopHeader);
