export const ServiceType = {
    Application: 'makeup_application',
    FocusedApplication: 'focused_makeup_application',
    Lesson: 'makeup_lesson',
    FocusedLesson: 'focused_makeup_lesson',
    HappyHour: 'makeup_happy_hour',
    SkincareConsultation: 'skincare_consultation',
    GiftedSkincareConsultation: 'gifted_skincare_consultation',
    MemberSkincareConsultation: 'member_skincare_consultation',
    MasterClass: 'master_class',
    SkincareChat: 'skincare_chat',
    MakeupChat: 'makeup_chat',
    GroupChat: 'group_chat',
    AtHomeFacial: 'at_home_facial',
    FragranceDiscoveryInPerson: 'fragrance_discovery_in_person',
    FragranceDiscoveryGroup: 'fragrance_discovery_group',
    FragranceDiscoveryVirtual: 'fragrance_discovery_virtual',
    GroupFragranceDiscoveryInPerson: 'group_fragrance_discovery_in_person',
    FragranceImmersion: 'fragrance_immersion_virtual',
    VirtualBrowService: 'virtual_brow_service',
    MemberEvent: 'member_event',
    EyeMakeupApplication: 'eye_makeup_application',
    SkinConsultationInPerson: 'skin_consultation_in_person',
    Residency: 'residency',
    Unknown: 'unknown'
};

export const PassState = {
    New: 'new',
    Expired: 'expired',
    Voided: 'voided',
    Consumed: 'consumed'
};

export const DisplayServiceType = {
    'makeup_application': 'Makeup Application',
    'focused_makeup_application': 'Focused Makeup Application',
    'makeup_lesson': 'Lesson',
    'focused_makeup_lesson': 'Focused Makeup Lesson',
    'makeup_happy_hour': 'Makeup Happy Hour',
    'skincare_consultation': 'Skin Service',
    'gifted_skincare_consultation': 'Gifted Skin Service',
    'member_skincare_consultation': 'Skin Service',
    'master_class': 'Master Class',
    'skincare_chat': 'Virtual Skin Consultation',
    'makeup_chat': 'Makeup Chat',
    'group_chat': 'Skincare Workshop',
    'at_home_facial': 'At-Home Facial 101',
    'fragrance_discovery_in_person': 'Fragrance Discovery (in person)',
    'fragrance_discovery_group': 'Fragrance Discovery (Group)',
    'fragrance_discovery_virtual': 'Fragrance Discovery (virtual)',
    'fragrance_immersion_virtual': 'Fragrance Immersion',
    'group_fragrance_discovery_in_person': 'Group Fragrance Discovery (in person)',
    'virtual_brow_service': 'Brow Consultation',
    'member_event': 'Member Event',
    'eye_makeup_application': 'Eye Makeup Application',
    'skin_consultation_in_person': 'In-Person Skin Consultation',
    'residency': 'Residency',
    'unknown': 'Unknown'
};

export const DefaultDisplayError = 'We are currently experiencing some technical issues. We apologize for the inconvenience. Please try again later.';

export const ErrorMapping = [
    {
        backendError: ' has expired ',
        displayError: 'This code has already expired.',
        errorKey: 'expired'
    },
    {
        backendError: 'has already redeemed the maximum number of codes',
        displayError: 'The maximum number of complimentary passes allowed per user per period has been redeemed.',
        errorKey: 'maxcodes'
    },
    {
        backendError: 'has already been redeemed by user',
        displayError: 'This code has already been redeemed.',
        errorKey: 'used'
    },
    {
        backendError: 'Failed to locate voucher for code',
        displayError: 'Enter a valid code.',
        errorKey: 'not found'
    },
    {
        backendError: 'has reached maximum issue count',
        displayError: 'This code has already been redeemed.',
        errorKey: 'used'
    },
    {
        backendError: 'Failed to submit questionnaire',
        displayError: DefaultDisplayError
    }
];

export const CompProductIdPrice = [
    {
        productSku: 'BC',
        originalPrice: 35
    },
    {
        productSku: 'BD',
        originalPrice: 35
    }
];
