import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { rem } from 'core/styled';
import styled, { css } from 'styled-components';

import BeautyPlanProductCard from './BeautyPlanProductCard';
import SubHeader from 'components/GlobalComponents/Fonts/SubHeader';
import Header from 'components/GlobalComponents/Fonts/Header';
import SkincareTabs from 'components/BeautyPlans/Common/SkincareTabs';
import ProductSectionWrapper from 'components/BeautyPlans/Common/ProductSectionWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import NonChanelProduct from 'components/BeautyPlans/Common/NonChanelProduct';
import { greaterThan } from 'core/styled/index';
import { breakpoints } from 'constants/theme';
import {
    BEAUTYPLAN_CATEGORY_BACKGROUND,
    BEAUTYPLAN_CONFIG,
    BEAUTYPLAN_SECTIONS,
    BEAUTYPLAN_SKINCARE_TYPE, NON_CHANEL, sectionTitle
} from 'constants/beautyplan';
import groupBy from 'core/utils/groupBy';
import Notes from 'components/BeautyPlans/Common/Notes';
import StaffQuestionView from 'components/BeautyPlans/Common/StaffQuestionView';

const ProductCategory = styled.div`
    padding: ${rem(75)} 0;
    margin: ${rem(10, 0, 40, 0)};
    text-align: center;
    background: url(${({ categoryBgMobile }) => categoryBgMobile}) 0 0 no-repeat;
    background-size: cover;
    width: 100%; 
    
    ${greaterThan(breakpoints.medium)(css`
      background-image: url(${({ categoryBgTablet }) => categoryBgTablet});
    `)}; 
    
    ${greaterThan(breakpoints.large)(css`
      background-image: url(${({ categoryBgDesktop }) => categoryBgDesktop});
    `)}; 
`;

class SkincareCustomer extends Component {
    static propTypes = {
        products: PropTypes.array,
        note: PropTypes.string,
        isImpersonating: PropTypes.bool.isRequired,
        staffQuestions: PropTypes.array
    };

    /**
     * Render non chanel product
     * @param {number} key
     * @return {*} component
     */
    renderNonChanelProduct = (key) => (
        <NonChanelProduct key={key}/>
    );

    /**
     * Render product list for each routing
     * @param {object} products - grouped by subsection (Cleanse, Tone, etc.)
     * @param {string} routine - am or pm
     * @returns {component} component
     */
    renderProducts(products, routine) {
        return (
            Object.keys(products).map((subSection, key) => {
                return (
                    <ProductSectionWrapper flexDirection={'column'} key={key}>
                        <ProductCategory
                            categoryBgMobile={BEAUTYPLAN_CATEGORY_BACKGROUND[subSection][routine].mobile}
                            categoryBgDesktop={BEAUTYPLAN_CATEGORY_BACKGROUND[subSection][routine].desktop}
                            categoryBgTablet={BEAUTYPLAN_CATEGORY_BACKGROUND[subSection][routine].tablet}

                        >
                            <Header cssMargin={[0]} fontSize={32}> {sectionTitle[subSection]} </Header>
                        </ProductCategory>
                        {products[subSection].map((product, key) => {
                            if (product.sku === NON_CHANEL) {
                                return this.renderNonChanelProduct(key);
                            }
                            return (<BeautyPlanProductCard isEven={key % 2 === 0} key={key} product={product} />);
                        })}
                    </ProductSectionWrapper>
                );
            })
        );
    }

    /**
     * Render AM routine
     * @returns {component} component
     */
    renderAMRoutine() {
        const { products } = this.props;
        if (products.some(p => p.category === BEAUTYPLAN_SKINCARE_TYPE.AM)) {

            const amProductsGroupedBySubSection = groupBy(products.filter(p => p.category === BEAUTYPLAN_SKINCARE_TYPE.AM), 'subSection');

            return (
                <ProductSectionWrapper flexDirection='column' alignItems='center' justifyContent='center'>
                    <SubHeader marginBottom={[0]}> MORNING </SubHeader>
                    {this.renderProducts(amProductsGroupedBySubSection, 'am')}
                </ProductSectionWrapper>
            );
        }

        return null;
    }

    /**
     * Render PM routine
     * @returns {component} component
     */
    renderPMRoutine() {
        const { products } = this.props;
        if (products.some(p => p.category === BEAUTYPLAN_SKINCARE_TYPE.PM)) {
            const pmProductsGroupedBySubSection = groupBy(products.filter(p => p.category === BEAUTYPLAN_SKINCARE_TYPE.PM), 'subSection');

            return (
                <ProductSectionWrapper flexDirection='column' alignItems='center' justifyContent='center'>
                    <SubHeader marginBottom={[0]}> EVENING </SubHeader>
                    {this.renderProducts(pmProductsGroupedBySubSection, 'pm')}
                </ProductSectionWrapper>
            );
        }

        return null;
    }

    render() {
        const { products, note, isImpersonating, staffQuestions } = this.props;

        if (!products) {
            return null;
        }

        return (
            <div id='atelier-workplan--skincarecontent'>
                {isImpersonating && <StaffQuestionView staffQuestions={staffQuestions} />}
                <SkincareTabs amTab={this.renderAMRoutine()} pmTab={this.renderPMRoutine()} />
                <Notes note={note} />
            </div>
        );
    }
}


const mapStateToProps = ({ beautyplan: { currentPlan: {  notes, skinRoutineProducts, staffQuestions } }, user: { auth: { isImpersonating } } }) => {

    const sectionNotes = notes ? notes.find(n => n.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE].id) : null;
    const staffQuestionsConfig = BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE].staffQuestions;

    const normalizedQuestions = staffQuestions.map(staffQuestion => {
        const staffQuestionConfig = staffQuestionsConfig.find(question => question.id === staffQuestion.question);

        if (staffQuestionConfig) {
            return {
                question: staffQuestionConfig.title,
                answer: staffQuestion.answer,
                type: staffQuestionConfig.type,
                allOptions: staffQuestionConfig.questions.map(item => item.value)
            };
        }

        return null;
    }).filter(question => !!question);

    return {
        products: skinRoutineProducts,
        note: sectionNotes ? sectionNotes.note : '',
        staffQuestions: normalizedQuestions,
        isImpersonating
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SkincareCustomer);
