import { combineActions, createAction, handleActions } from 'redux-actions';
import { FETCH, FETCH_SUCCESS } from 'middlewares/fetch';
import { searchProducts } from 'reducers/elasticsearch';
import { CONFIG } from 'constants/config';
import { packageLikeListPayload } from 'core/utils/packageListPayload';
import { SEARCH } from 'constants/search';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import normalizeProduct from 'core/utils/normalizeProduct';
import { analyticsTag } from 'reducers/analytics';
import moment from 'moment';
import { EVENTS } from 'constants/analytics';
import { INTERACTION_TYPES } from 'constants/recommendations';
import { pushInteraction } from './recommendations';
import log from 'core/log';
// import { setPostAddRedirectUrl } from './PDPProduct';
// import { returnAfterLogin } from './user';
// import { ROUTES } from 'constants/routes';
// import { push } from 'redux-first-history';

export const LIKE_LIST = 'LIKE_LIST';
export const SAMPLED_LIST = 'SAMPLED_LIST';
export const SET_PRODUCT_DATA = 'LIKE_LIST/SET_PRODUCT_DATA';
export const RESET_LISTS = 'RESET_LISTS';
export const FETCHING_LISTS = 'FETCHING_LISTS';
export const FAVOURITES_LIST = 'FAVOURITES_LIST';
export const ADD_REMOVE_FAVOURITES = 'ADD_REMOVE_FAVOURITES';
export const UPDATE_FAVOURITES_LIST = 'UPDATE_FAVOURITES_LIST';
export const SET_LIKE_LIST = 'SET_LIKE_LIST';

const initialState = {
    like: [],
    sampled: [],
    productData: [],
    favourites: [],
    isFetching: false
};

export const setProductData = createAction(SET_PRODUCT_DATA);
export const resetLists = createAction(RESET_LISTS);
const updateFavouritesList = createAction(UPDATE_FAVOURITES_LIST);
const fetchingLists = createAction(FETCHING_LISTS);
const setLikeList = createAction(SET_LIKE_LIST);

const fetchLikeList = createAction(FETCH, likeListId => ({
    prefix: LIKE_LIST,
    endpoint: `${CONFIG.API_URL}/lists/${likeListId}`,
    options: {
        method: 'GET'
    }
}));

const fetchSampledList = createAction(FETCH, sampledListId => ({
    prefix: SAMPLED_LIST,
    endpoint: `${CONFIG.API_URL}/lists/${sampledListId}`,
    options: {
        method: 'GET'
    }
}));

const addToSampleList = createAction(FETCH, (sampledListId, request) => ({
    prefix: SAMPLED_LIST,
    endpoint: `${CONFIG.API_URL}/lists/${sampledListId}/items`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

export const addToLikeList = createAction(FETCH, (likeListId, request) => ({
    prefix: LIKE_LIST,
    endpoint: `${CONFIG.API_URL}/lists/${likeListId}/items`,
    options: {
        method: 'POST',
        body: JSON.stringify(packageLikeListPayload('add', request))
    }
}));

// TODO: addbatchtolikelist
const addMultipleToLikeList = createAction(FETCH, (likeListId, request) => ({
    prefix: LIKE_LIST,
    endpoint: `${CONFIG.API_URL}/lists/${likeListId}/batchItems`,
    options: {
        method: 'POST',
        body: JSON.stringify(request.map(item => packageLikeListPayload('add', item)))
    }
}));

export const removeFromLikeList = createAction(FETCH, (likeListId, skuId) => ({
    prefix: LIKE_LIST,
    endpoint: `${CONFIG.API_URL}/lists/${likeListId}/items/${skuId}`,
    options: {
        method: 'DELETE'
    }
}));

export const updateLikeList = createAction(
    FETCH,
    (likeListId, skuId, request) => ({
        prefix: LIKE_LIST,
        endpoint: `${CONFIG.API_URL}/lists/${likeListId}/items/${skuId}`,
        options: {
            method: 'PUT',
            body: JSON.stringify(packageLikeListPayload('update', request))
        }
    })
);

const fetchFavouritesList = createAction(FETCH, () => ({
    prefix: FAVOURITES_LIST,
    endpoint: `${CONFIG.API_URL}/video/favoriteproduct`,
    options: {
        method: 'GET'
    }
}));

const addRemoveFavourites = createAction(FETCH, (updatedFavourites) => ({
    prefix: ADD_REMOVE_FAVOURITES,
    endpoint: `${CONFIG.API_URL}/video/favoriteproduct`,
    options: {
        method: 'POST',
        body: JSON.stringify(updatedFavourites)
    }
}));

export const removeFromList = ({ sku }) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { likedListId } }, authenticated }, userList: { like } } = getState();

        if (!authenticated) {
            dispatch(setLikeList({
                items: like.filter(item => item.sku !== sku)
            }));
        }

        return dispatch(removeFromLikeList(likedListId, sku));
    };
};

export const fetchUserLists = (isLoginOrRegister = false) => {
    return (dispatch, getState) => {
        const {
            user: {
                auth: {
                    profile: { likedListId, sampledListId },
                    authenticated
                }
            },
            userList: { like }
        } = getState();
        dispatch(fetchingLists(true));

        const promises = [];

        if (authenticated) {
            promises.push(dispatch(fetchSampledList(sampledListId)));
            promises.push(dispatch(fetchFavouritesList()));

            if (isLoginOrRegister) {
                promises.push(
                    dispatch(
                        addMultipleToLikeList(
                            likedListId,
                            like.map(likeItem => ({
                                sku: likeItem.sku,
                                like: likeItem.userdata.like
                            }))
                        )
                    )
                );
            } else {
                promises.push(dispatch(fetchLikeList(likedListId)));
            }
        } else {
            promises.push(Promise.resolve());
        }

        return Promise.all(promises)
            .then(() => {
                // product data is required, let's search
                const {
                    userList: { like, sampled, favourites }
                } = getState();
                if (
                    like.length === 0 &&
                    sampled.length === 0 &&
                    favourites.length === 0
                ) {
                    return null;
                }

                const endpoint = SEARCH.URL.MGET;

                const favouriteSkus = favourites.reduce((accum, item) => {
                    return item.skus ? accum.concat(item.skus) : accum;
                }, []);

                const skus = new Set([
                    ...like.map(l => l.sku),
                    ...sampled.map(s => s.sku),
                    ...favouriteSkus
                ]);
                const request = {
                    bodyRequest: elasticSearchQueries.queryBySkus([...skus])
                };

                return dispatch(searchProducts({ endpoint, request }));
            })
            .then(response => {
                if (response != null) {
                    const { docs: results } = response;
                    // persists product data
                    dispatch(setProductData(results.map(normalizeProduct)));
                }
                dispatch(fetchingLists(false));
            })
            .catch(() => {
                dispatch(fetchingLists(false));
            });
    };
};

/**
 * Add a product to the user's sampled products list
 * @param {object} product
 * @return {Function} AddSample action
 */
export const addSample = (product) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { sampledListId } } } } = getState();
        // that's a bit of a hack (sku + date), but it's currently the only way to store several skus on different days
        const request = {
            sku: `${product.sku}_${moment().format('MM-DD-YYYY')}`,
            merge: true,
            userdata: {
                qty: 1
            }
        };
        dispatch(analyticsTag({
            event: EVENTS.GA,
            eventCategory: 'product module',
            eventAction: 'addsample',
            eventLabel: product.sku
        }, { userInfo: true }));
        return dispatch(addToSampleList(sampledListId, request));
    };
};

/**
 * Given a sku and a like (true/false) update the userList
 * @param {object} product
 * @param {boolean} nextlike
 * @param {boolean} tagEvent
 * @param {boolean} quickshop
 * @param {boolean} isRecommended
 * @returns {Function} ToggleLike action
 */
export const toggleLikeList = (product, nextlike, tagEvent = true, quickshop, isRecommended = false) => {
    return (dispatch, getState) => {
        const { sku } = product;
        const promises = [];
        const tag = {
            event: EVENTS.GA,
            eventCategory: quickshop ? 'quickshop' : 'product module',
            eventLabel: sku
        };

        // recommendation logic
        const interaction = {
            skus: [sku]
        };

        // Handle add to cart for non logged in users. Ask to log in first
        // if (!getState().user.auth.authenticated) {
        //     dispatch(setPostAddRedirectUrl(getState().router.location.pathname + getState().router.location.search));
        //     dispatch(returnAfterLogin(`${ROUTES.PRODUCT_DESCRIPTION}/${product.productCode}/${product.sku}?addToLiked=true`));
        //     return dispatch(push(ROUTES.LOGIN));
        // }

        const { user: { auth: { authenticated } },userList: { like }, user: { auth: { profile: { likedListId } } } } = getState();
        const item = like.find(l => l.sku === sku);

        if (item) { // item is in list
            if (item.userdata.like !== nextlike) { // update like <-> dislike
                tag.eventAction = nextlike ? 'like' : 'notforme';
                interaction.interactionType = INTERACTION_TYPES.LIKE;
                if (authenticated) {
                    promises.push(dispatch(updateLikeList(likedListId, sku, { like: nextlike })));
                } else {
                    // handle update list
                    const updatedItem = {
                        ...item,
                        userdata: {
                            ...item.userdata,
                            like: nextlike,
                            lastUpdated: moment().toISOString()
                        }
                    };
                    promises.push(dispatch(setLikeList({
                        items: [
                            ...like.filter(item => item.sku !== sku),
                            updatedItem
                        ]
                    })));
                }
            } else { // remove
                tag.eventAction = nextlike ? 'undolike' : 'undonotforme';
                interaction.interactionType = INTERACTION_TYPES.UNDO_LIKE;
                if (authenticated) {
                    promises.push(dispatch(removeFromLikeList(likedListId, sku)));
                } else {
                    // handle remove from list
                    promises.push(dispatch(setLikeList({
                        items: like.filter(item => item.sku !== sku)
                    })));
                }
            }
        } else {
            // item isn't in userList, add it
            tag.eventAction = nextlike ? 'like' : 'notforme';
            interaction.interactionType = INTERACTION_TYPES.LIKE;
            if (authenticated) {
                promises.push(dispatch(addToLikeList(likedListId, { sku, like: nextlike })));
            } else {
                // handle add to list
                const now = moment().toISOString();
                const newItem = {
                    sku,
                    userdata: {
                        like: nextlike
                    },
                    created: now,
                    lastUpdated: now
                };
                promises.push(dispatch(setLikeList({
                    items: [...like, newItem]
                })));
            }
        }

        if (tagEvent) {
            promises.push(dispatch(analyticsTag(tag, { userInfo: true })));

            if (isRecommended) {
                promises.push(dispatch(analyticsTag(
                    {
                        ...tag,
                        eventCategory: 'recommendedproduct',
                        eventAction: `${quickshop ? 'quickshop' : ''}${tag.eventAction}`
                    },
                    {
                        userInfo: true
                    }
                )));
            }
        }

        dispatch(pushInteraction(interaction)).catch(() => {
            log.info('Could not send interaction for recommendations:', interaction);
        });

        return Promise.all(promises);
    };
};

export const getLikedItems = ({ productData, like }) => {
    return productData.filter(p => {
        const item = like.find(l => p && l && l.sku === p.id);
        if (item) {
            return item.userdata.like;
        }
        return false;
    });
};

export const getDislikedItems = ({ productData, like }) => {
    return productData.filter(p => {
        const item = like.find(l => p && l && l.sku === p.id);
        if (item) {
            return !item.userdata.like;
        }
        return false;
    });
};

export const getSampledItems = ({ productData, sampled }) => {
    // sort sampled items by date descending
    // then group samples by sample date
    return sampled.sort((a, b) => -1 * moment(a.created).diff(moment(b.created), 'days'))
        .reduce((samples, x) => {
            const value = moment(x.created).format('MM.DD.YYYY');
            const el = samples.find(r => r && r.sampleDate === value);
            const product = productData.find(p => p && x && p.sku === x.sku);
            if (!product) {
                return samples;
            }
            if (el) {
                el.products.push(product);
            } else {
                samples.push({ sampleDate: value, products: [product] });
            }
            return samples;
        }, []);
};

export const getFavouriteItems = ({ productData, favourites }) => {
    // sort favourites items by date time descending
    // then group items by event/appointment
    return favourites.filter(favourite => favourite.skus && favourite.skus.length)
        .sort((a, b) => -1 * moment(a.meetingdatetime).diff(moment(b.meetingdatetime), 'minutes'))
        .map(favourite => {
            const { meetingid, meetingdatetime, meetingname, skus } = favourite;
            const products = productData.filter(product => product && product.sku && skus.includes(product.sku));

            if (products.length) {
                return {
                    meetingid,
                    meetingname,
                    meetingdatetime,
                    products
                };
            } else {
                return null;
            }
        })
        .filter(favourite => favourite); // filter favourites without items
};


export const updateFavouriteList = (skuList, meetingId, notRemove = false) => {
    return (dispatch, getState) => {
        const { userList: { favourites } } = getState();

        let skus = [];

        const meetingFavourites = favourites.find(favourite => favourite.meetingid === meetingId);

        if (meetingFavourites) {
            skus = meetingFavourites.skus;
        }

        const uniqueSkus = [...new Set(skuList)];

        for (const sku of uniqueSkus) {
            if (skus.some(favouriteSku => favouriteSku === sku)) {
                if (!notRemove) {
                    skus = skus.filter(favouriteSku => favouriteSku !== sku);
                }
            } else {
                skus.push(sku);
            }
        }

        return dispatch(addRemoveFavourites({
            skus,
            meetingId
        })).then(() => {
            // update redux state to contain the new skus so the item heart can be displayed
            const updatedFavouriteIndex = favourites.indexOf(meetingFavourites);

            if (updatedFavouriteIndex >= 0) {
                favourites[updatedFavouriteIndex].skus = skus;
            } else {
                favourites.push({
                    meetingid: meetingId,
                    skus
                });
            }

            dispatch(analyticsTag({
                event: EVENTS.GA,
                eventCategory: 'product module',
                eventLabel: `${meetingId};${skus.join(',')}`,
                eventAction: 'updatefavorites'
            }, { userInfo: true }));

            return dispatch(updateFavouritesList(favourites));
        });
    };
};

export default handleActions(
    {
        [fetchingLists]: (state, { payload }) => ({ ...state, isFetching: payload }),
        [combineActions(
            `${LIKE_LIST}/${FETCH_SUCCESS}`,
            setLikeList
        )]: (state, { payload }) => ({
            ...state,
            like: payload.items || [],
        }),
        [`${SAMPLED_LIST}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            sampled: payload.items.map(i => {
                return {
                    ...i, ...{
                        sku: i.sku.substring(0, i.sku.indexOf('_')),
                        key: i.sku
                    }
                };
            })
        }),
        [`${FAVOURITES_LIST}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            favourites: payload.favoriteProducts || []
        }),
        [setProductData]: (state, { payload }) => ({
            ...state,
            productData: payload
        }),
        [updateFavouritesList]: (state, { payload }) => ({
            ...state,
            favourites: payload
        }),
        [resetLists]: () => ({ ...initialState })
    },
    initialState
);
