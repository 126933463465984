import { CONFIG } from 'constants/config';
import {
    MEMBERS_LEVEL,
    normalizeMyActivityResponse
} from 'constants/membership';
import {
    FETCH,
    FETCH_ERROR,
    FETCH_PENDING,
    FETCH_RESET,
    FETCH_SUCCESS
} from 'middlewares/fetch';
import { createAction, handleActions } from 'redux-actions';
import { fetchPageByName, fetchPageByUID } from './prismicFetch';
import { setCurrentGroup } from './membership';
import isProd from 'core/utils/isProd';

const initialState = {
    petals: {
        level: MEMBERS_LEVEL.RED,
        petals: 0,
        nft: undefined,
        loading: false,
        loaded: false,
        error: ''
    },
    myActivity: {
        beautyServices: 0,
        events: 0,
        friendPasses: 0,
        privateEvent: false,
        welcomeKit: false,
        completeProfile: false,
        introductoryConsultation: false,
        rsvpWelcome: false,
        fragranceDiscovery: false,
        skinConsultation: false,
        makeupApplication: false,
        makeupLesson: false,
        recycle: false,
        poll: false,
        loading: false,
        loaded: false,
        error: ''
    },
    mentor: {
        mentorName: undefined,
        mentorEmail: undefined,
        mentorChatRoom: undefined,
        loading: false,
        loaded: false,
        error: ''
    },
    newMessages: {
        announcements: false,
        generalDiscussion: false,
        favoriteProducts: false
    },
    friendPasses: {
        codes: {}, // memberId?: number; referralCode?: string;
        loading: false,
        error: ''
    }
};

const MEMBER_HUB_RESET = 'MEMBER_HUB_RESET';
const MEMBER_HUB_PETALS = 'MEMBER_HUB_PETALS';
const MEMBER_HUB_ACTIVITY = 'MEMBER_HUB_ACTIVITY';
const MEMBER_HUB_MENTOR = 'MEMBER_HUB_MENTOR';
const MEMBER_HUB_SET_MENTOR = 'MEMBER_HUB_SET_MENTOR';
const MEMBER_HUB_SERVICES = 'MEMBER_HUB_SERVICES';
const MEMBER_HUB_CHAT_TOKEN = 'MEMBER_HUB_CHAT_TOKEN';
const MEMBER_HUB_FRIEND_PASSES = 'MEMBER_HUB_FRIEND_PASSES';
const MEMBER_HUB_INVITE_CODE = 'MEMBER_HUB_INVITE_CODE';
const MEMBER_HUB_GET_SOCIAL = 'MEMBER_HUB_GET_SOCIAL';
const MEMBER_HUB_SET_SOCIAL = 'MEMBER_HUB_SET_SOCIAL';
const MEMBER_HUB_CHAT_NOTIFICATIONS = 'MEMBER_HUB_CHAT_NOTIFICATIONS';
const MEMBER_HUB_CHAT_LAST_SEEN = 'MEMBER_HUB_CHAT_LAST_SEEN';

const setMentor = createAction(MEMBER_HUB_SET_MENTOR);
const setMentorPrismicError = createAction(
    `${MEMBER_HUB_MENTOR}/${FETCH_ERROR}`
);
const setFriendPassPrismicError = createAction(
    `${MEMBER_HUB_FRIEND_PASSES}/${FETCH_ERROR}`
);

const getPetals = createAction(FETCH, currentGroup => ({
    prefix: MEMBER_HUB_PETALS,
    endpoint: `${CONFIG.API_URL}/membership/petals?group=${encodeURIComponent(
        currentGroup
    )}`,
    options: {
        method: 'GET'
    }
}));

const getActivity = createAction(FETCH, currentGroup => ({
    prefix: MEMBER_HUB_ACTIVITY,
    endpoint: `${
        CONFIG.API_URL
    }/membership/myactivity?group=${encodeURIComponent(currentGroup)}`,
    options: {
        method: 'GET'
    }
}));

const getMentor = createAction(FETCH, currentGroup => ({
    prefix: MEMBER_HUB_MENTOR,
    endpoint: `${CONFIG.API_URL}/membership/mentor?group${encodeURIComponent(
        currentGroup
    )}`,
    options: {
        method: 'GET'
    }
}));

const getFriendPasses = createAction(FETCH, currentGroup => ({
    prefix: MEMBER_HUB_FRIEND_PASSES,
    endpoint: `${
        CONFIG.API_URL
    }/membership/friend-pass?group=${encodeURIComponent(currentGroup)}`,
    options: {
        method: 'GET'
    }
}));

export const getInviteCode = createAction(FETCH, (memberId, eventId) => ({
    prefix: MEMBER_HUB_INVITE_CODE,
    endpoint: `${CONFIG.API_URL}/membership/invite/code`,
    options: {
        method: 'POST',
        body: JSON.stringify({ memberId, eventId })
    }
}));

export const getMemberServices = createAction(FETCH, () => ({
    prefix: MEMBER_HUB_SERVICES,
    endpoint: `${CONFIG.API_URL}/scheduling/memberservices`,
    options: {
        method: 'GET'
    }
}));

const getSocialHandles = createAction(FETCH, currentGroup => ({
    prefix: MEMBER_HUB_GET_SOCIAL,
    endpoint: `${CONFIG.API_URL}/membership/social?group=${encodeURIComponent(
        currentGroup
    )}`,
    options: {
        method: 'GET'
    }
}));

const setSocialHandles = createAction(FETCH, request => ({
    prefix: MEMBER_HUB_SET_SOCIAL,
    endpoint: `${CONFIG.API_URL}/membership/social`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

const getChatToken = createAction(FETCH, group => ({
    prefix: MEMBER_HUB_CHAT_TOKEN,
    endpoint: `${CONFIG.API_URL}/membership/chat/login`,
    options: {
        method: 'POST',
        body: JSON.stringify({ group })
    }
}));

export const getNewChatNotifications = createAction(FETCH, currentGroup => ({
    prefix: MEMBER_HUB_CHAT_NOTIFICATIONS,
    endpoint: `${CONFIG.API_URL}/membership/group/lastseen?group=${encodeURIComponent(
        currentGroup
    )}`,
    options: {
        method: 'GET'
    }
}));

export const setChatLastSeen = createAction(FETCH, request => ({
    prefix: MEMBER_HUB_CHAT_LAST_SEEN,
    endpoint: `${CONFIG.API_URL}/membership/group/lastseen`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

export const fetchMyPetals = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    return dispatch(getPetals(currentGroup));
};

export const fetchMyActivity = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    return dispatch(getActivity(currentGroup));
};

export const fetchMyMentor = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    return dispatch(getMentor(currentGroup)).then(response => {
        return Promise.resolve(
            dispatch(fetchPageByUID('membership_mentors', 'membership-mentors'))
                .then(prismicResponse => {
                    const mentor = prismicResponse.data.mentors.find(
                        m => m.mentor_id === response.mentorId
                    );

                    dispatch(
                        setMentor({
                            mentorName: mentor?.mentor_name,
                            mentorEmail: mentor?.mentor_email,
                            mentorChatRoom: `https://atelierbeautechanel.rocket.chat/direct/${response.chatRoomId}`
                        })
                    );
                })
                .catch(err => {
                    dispatch(setMentorPrismicError(err));
                })
        );
    });
};

export const fetchMyFriendPasses = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    if (!currentGroup) {
        return Promise.resolve(
            dispatch(fetchPageByName('membership_landing_2023'))
                .then(prismicResponse => {
                    const group = prismicResponse.data.current_group_number;
                    dispatch(setCurrentGroup(group));

                    return dispatch(getFriendPasses(group));
                })
                .catch(err => {
                    dispatch(setFriendPassPrismicError(err));
                })
        );
    }

    return dispatch(getFriendPasses(currentGroup));
};

export const fetchMyChatToken = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    return dispatch(getChatToken(currentGroup));
};

export const fetchMySocialHandles = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    return dispatch(getSocialHandles(currentGroup));
};

export const updateMySocialHandles = (instagram, tiktok) => (
    dispatch,
    getState
) => {
    const {
        membership: { currentGroup }
    } = getState();

    const request = { group: currentGroup };

    if (instagram) {
        request.instagram = instagram;
    }

    if (tiktok) {
        request.tiktok = tiktok;
    }

    return dispatch(setSocialHandles(request));
};

export const fetchChatNotifications = () => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    return dispatch(getNewChatNotifications(currentGroup));
};

export const updateChatLastSeen = (room) => (dispatch, getState) => {
    const {
        membership: { currentGroup }
    } = getState();

    const request = {
        group: currentGroup,
        [room]: Date.now()
    };

    return dispatch(setChatLastSeen(request));
};

export const resetMemberHub = createAction(MEMBER_HUB_RESET);

export default handleActions(
    {
        [MEMBER_HUB_RESET]: state => ({
            ...state,
            ...initialState
        }),
        [`${MEMBER_HUB_PETALS}/${FETCH_PENDING}`]: state => ({
            ...state,
            petals: {
                ...state.petals,
                error: '',
                loading: true
            }
        }),
        [`${MEMBER_HUB_PETALS}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            petals: {
                ...state.petals,
                error: payload,
                loading: false
            }
        }),
        [`${MEMBER_HUB_PETALS}/${FETCH_RESET}`]: state => ({
            ...state,
            petals: {
                ...state.petals,
                error: '',
                loading: false
            }
        }),
        [`${MEMBER_HUB_PETALS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            petals: {
                ...state.petals,
                error: '',
                loading: false,
                loaded: true,
                ...payload
            }
        }),
        [`${MEMBER_HUB_ACTIVITY}/${FETCH_PENDING}`]: state => ({
            ...state,
            myActivity: {
                ...state.myActivity,
                error: '',
                loading: true
            }
        }),
        [`${MEMBER_HUB_ACTIVITY}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            myActivity: {
                ...state.myActivity,
                error: payload,
                loading: false
            }
        }),
        [`${MEMBER_HUB_ACTIVITY}/${FETCH_RESET}`]: state => ({
            ...state,
            myActivity: {
                ...state.myActivity,
                error: '',
                loading: false
            }
        }),
        [`${MEMBER_HUB_ACTIVITY}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            myActivity: {
                ...state.myActivity,
                error: '',
                loading: false,
                loaded: true,
                ...normalizeMyActivityResponse(payload)
            }
        }),
        [`${MEMBER_HUB_MENTOR}/${FETCH_PENDING}`]: state => ({
            ...state,
            mentor: {
                ...state.mentor,
                error: '',
                loading: true
            }
        }),
        [`${MEMBER_HUB_MENTOR}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            mentor: {
                ...state.mentor,
                error: payload,
                loading: false
            }
        }),
        [`${MEMBER_HUB_MENTOR}/${FETCH_RESET}`]: state => ({
            ...state,
            mentor: {
                ...state.mentor,
                error: '',
                loading: false
            }
        }),
        [MEMBER_HUB_SET_MENTOR]: (state, { payload }) => ({
            ...state,
            mentor: {
                ...state.mentor,
                error: '',
                loading: false,
                loaded: true,
                ...payload
            }
        }),
        [`${MEMBER_HUB_FRIEND_PASSES}/${FETCH_PENDING}`]: state => ({
            ...state,
            friendPasses: {
                ...state.friendPasses,
                error: '',
                loading: true
            }
        }),
        [`${MEMBER_HUB_FRIEND_PASSES}/${FETCH_ERROR}`]: (
            state,
            { payload }
        ) => ({
            ...state,
            friendPasses: {
                ...state.friendPasses,
                error: payload,
                loading: false
            }
        }),
        [`${MEMBER_HUB_FRIEND_PASSES}/${FETCH_RESET}`]: state => ({
            ...state,
            friendPasses: {
                ...state.friendPasses,
                error: '',
                loading: false
            }
        }),
        [`${MEMBER_HUB_FRIEND_PASSES}/${FETCH_SUCCESS}`]: (
            state,
            { payload }
        ) => ({
            ...state,
            friendPasses: {
                ...state.friendPasses,
                error: '',
                loading: false,
                codes: payload
            }
        }),
        [`${MEMBER_HUB_CHAT_NOTIFICATIONS}/${FETCH_SUCCESS}`]: (state, { payload }) => {
            const prod = isProd();

            return {
                ...state,
                newMessages: {
                    announcements:
                        payload[prod ? 'Announcements' : 'QA-Announcements'] ||
                        false,
                    generalDiscussion:
                        payload[
                            prod
                                ? 'General-Discussions'
                                : 'QA-General-Discussions'
                        ] || false,
                    favoriteProducts:
                        payload[
                            prod
                                ? 'Fav-Beauty-Products'
                                : 'QA-Fav-Beauty-Products'
                        ] || false
                }
            };
        }
    },
    initialState
);
