import { boxStyle, styledProps, rem } from 'core/styled';
import styled from 'styled-components';

const SubHeader = styled.div`
    ${boxStyle};
    font-family: ${styledProps('font', 'ABChanelPBS')};
    color: ${({ cssColor }) => cssColor ? styledProps('color', cssColor) : styledProps('color', 'black')};
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(14)};
    line-height: 1.29;
    text-align: center;
    margin-bottom: ${({ marginBottom }) => marginBottom ? rem(marginBottom) : rem(22)};
    margin-top: ${({ marginTop }) => marginTop ? rem(marginTop) : '0'};    
    text-transform: uppercase;
`;

export default SubHeader;
