import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styledProps, rem, greaterThan } from 'core/styled';
import styled, { css } from 'styled-components';
import BeautyPlanProductCard from './BeautyPlanProductCard';
import Header from 'components/GlobalComponents/Fonts/Header';
import ProductSectionWrapper from 'components/BeautyPlans/Common/ProductSectionWrapper';
import { BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS, sectionTitle } from 'constants/beautyplan';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import Notes from 'components/BeautyPlans/Common/Notes';
import { breakpoints } from 'constants/theme';

const StyledHeader = styled(Header)`
    padding: 0 ${rem(20, 20, 20)};
    position: relative;
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        border-bottom: 2px solid ${styledProps('color', 'black')};
        width: calc(100% - ${rem(40)});
        margin: 0 auto;
    }
    
     ${greaterThan(breakpoints.medium)(css`
        width: 50%;
        margin: auto;
    `)};  
`;

const BottomBorder = styled.div`
    width: calc(100% - ${rem(40)});
    border-bottom: 2px solid ${styledProps('color', 'black')};
    margin-top: ${rem(40)};
`;

class NewBrowLookCustomer extends Component {
    static propTypes = {
        products: PropTypes.object,
        note: PropTypes.string,
        noteTitle: PropTypes.string
    };

    render() {
        const { products, note, noteTitle } = this.props;

        if (!products) {
            return null;
        }

        return (
            <div id='atelier-workplan--browlookcontent'>
                <Notes note={note} title={noteTitle} noBorder newBeautyPlan />
                { Object.keys(products).map((subSection, key) => {
                    return (
                        <ProductSectionWrapper flexDirection={'column'} key={key} alignItems={'center'}>
                            <StyledHeader fontSize={34} cssMargin={[20, 0, 45]}>{sectionTitle[subSection]}</StyledHeader>
                            {products[subSection].map((product, key) => {
                                return (<BeautyPlanProductCard isEven={key % 2 === 0} key={key} product={product} withProductNote />);
                            })}
                            <BottomBorder />
                        </ProductSectionWrapper>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = ({ beautyplan: { currentPlan: { browLookProducts, notes } } }) => {

    const sectionNotes = notes ? notes.find(n => n.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_BROW_LOOK].id) : null;

    return {
        products: browLookProducts,
        note: sectionNotes ? sectionNotes.note : '',
        noteTitle: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_BROW_LOOK].textSections[0].title,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NewBrowLookCustomer);
