import log from 'core/log';
import { CONFIG } from 'constants/config';
import { loadScript } from 'core/utils';

/* eslint-disable */
/**
 * This contains functions which handle the handshake for Single-Sign-on between
 * chanel.com and the atelier site. This is dependant upon the gigya library
 * already present in the global context.
 */
export default class Gigya {

    init = () => {
        // chanel.com to one-hope
        loadScript(`https://cdns.gigya.com/js/gigya.js?apikey=${CONFIG.GIGYA_API_KEY}`, "window.__gigyaConf = { enableSSOToken: true };")
            .then(() => {
                window.gigya.accounts.addEventHandlers({
                    onLogin: this.loginCallback,
                    callback: this.generalCallback
                });
            })
            .catch(() => {
                console.error('Gigya script loading failed!');
            });
    };

    /**
     * Set the SSO token from Gigya
     * @param {string} redirectURL
     */
    transferCart = (redirectURL) => {
        this.getAccountInformation().then(hasGigyaSession => {
            if (hasGigyaSession) {
                window.gigya.setSSOToken({ redirectURL });
                return new Promise(r => r());
            }
            return this.refreshGigyaSession();
        }).then(() => {
            return this.getAccountInformation();
        }).then(hasGigyaSession => {
            if (hasGigyaSession) {
                window.gigya.setSSOToken({ redirectURL });
            } else {
                log.error('No gigya session available');
            }
        });
    };

    /**
     * Login callback (chanel -> one)
     * @param {object} eventObj
     */
    loginCallback = (eventObj) =>  {
        log.info(`Onlogin callback`, eventObj);
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status === 200) {
                const authData = JSON.parse(xmlhttp.response);
                authData.authenticated = authData.status === 'authenticated';

                const stringData = JSON.stringify({ auth: authData });

                // Set authentication data in local storage
                localStorage.setItem('persist:user', stringData);
                window.location.href = '/';
            }
        };
        xmlhttp.open('POST', '/api/v1/auth/sso');
        xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xmlhttp.send(JSON.stringify(eventObj));
    };

    /**
     * callback
     * @param {object} r
     */
    generalCallback = (r) => {
        // Registered event handler
        console.log('general callback', r);
    };

    postLogin = (callback = () => {}) => {
        window.gigya.accounts.getAccountInfo({ callback });
    };

    getAccountInformation = () => {
        return new Promise(resolve => {
            window.gigya.accounts.getAccountInfo({ callback: (gigyaStatus) => {
                if (gigyaStatus.errorCode === 0 && gigyaStatus.UID) {
                    console.log(`Gigya session is fresh, good to go`);
                    return resolve(true);
                }
                // Gigya session needs to be refreshed
                return resolve(false);
            }
            });
        });
    };

    refreshGigyaSession = () => {
        return new Promise(resolve => {
            console.log(`Refreshing Gigya session`);
            const xmlhttp = new XMLHttpRequest();
            xmlhttp.onreadystatechange = () => {
                if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status === 200) {
                    console.log('login put response', xmlhttp.response);
                    // call set SSO Token
                    resolve();
                }
            };
            xmlhttp.open('PUT', '/api/v1/auth/session');
            xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            xmlhttp.send();
        });
    }

}
/* eslint-disable */