import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inputStyle } from 'core/styled';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import ParenthesesButton from 'components/GlobalComponents/FormInput/ParenthesesButton';

const Input = styled.input`
    ${inputStyle};
    outline: none;
`;

export default class PasswordInput extends Component {
    static propTypes = {
        displaypassword: PropTypes.bool,
        ariaLabel: PropTypes.string
    };

    static defaultProps = {
        displaypassword: false
    };

    state = {
        display: this.props.displaypassword
    };


    // Toggle the display of the password text value
    toggleDisplay = () => {
        this.setState({
            display: !this.state.display
        });
    };

    render() {
        const { ariaLabel, ...rest } = this.props;

        return (
            <Flex>
                <Box flex="auto">
                    <Input
                        {...rest}
                        aria-label={ariaLabel}
                        type={this.state.display ? 'text' : 'password'}
                    />
                </Box>
                <Box flex="none" marginBottom="5px">
                    <ParenthesesButton
                        onClick={this.toggleDisplay}
                    >
                        {this.state.display ? 'Hide' : 'Show'} <span className={'sr-only'}>password</span>
                    </ParenthesesButton>
                </Box>
            </Flex>
        );
    }
}
