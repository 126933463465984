import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PrimaryModal } from 'components/GlobalComponents/Modal/PrimaryModal';
import { greaterThan, rem, styledProps } from 'core/styled';
import { breakpoints } from 'constants/theme';
import Button from 'components/GlobalComponents/Button/Button';

const RemoveModalContentBodyWrapper = styled.div`
    padding: ${rem(0, 0, 15, 0)};
    text-align: center;
`;

const RemoveModalBodyText = styled.p`
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: ${rem(0.9)};
    padding-top: ${rem(5)};
`;

const ProductThumbnailModal = styled.img`
    display: block;
    width: 50%;
    max-width: ${rem(200)};
    height: auto;
    margin: 0 auto;
`;

const RemoveModalHeader = styled.p`
    margin: 0;
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    font-size: ${rem(54)};
    font-weight: 300;
    line-height: 0.93;
    letter-spacing: ${rem(1)};
    padding-bottom: ${rem(5)};
    text-transform: uppercase;
`;

const ModalBodyText = styled.p`
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: ${rem(0.2)};
    padding-bottom: ${rem(10)};

    ${greaterThan(breakpoints.small)(css`
        padding-bottom: ${rem(25)};
        font-size: ${rem(14)};
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: ${rem(0.2)};
    `)}
`;

const RemoveProductButton = styled(Button)`
    margin-top: ${rem(20)};
`;

const CancelButton = styled(Button)`
    margin-top: ${rem(20)};
    border: none;
`;

export default class CartRemoveModal extends Component {
    static propTypes = {
        showRemoveConfirmationModal: PropTypes.bool.isRequired,
        toggleRemoveProductModal: PropTypes.func.isRequired,
        productToRemove: PropTypes.shape({
            name: PropTypes.string.isRequired,
            packshot: PropTypes.shape({
                medium: PropTypes.func.isRequired
            }),
            id: PropTypes.string.isRequired
        }),
        removeProductFromCart: PropTypes.func.isRequired
    };

    renderRemoveModalContent = () => {
        const {
            productToRemove,
            showRemoveConfirmationModal,
            removeProductFromCart,
            toggleRemoveProductModal
        } = this.props;

        return (
            <RemoveModalContentBodyWrapper>
                <RemoveModalBodyText>
                    You are about to remove
                </RemoveModalBodyText>
                {showRemoveConfirmationModal && (
                    <ProductThumbnailModal
                        src={productToRemove.packshot.medium()}
                        alt={productToRemove.name}
                    />
                )}
                <RemoveModalHeader>{productToRemove.name}</RemoveModalHeader>
                <ModalBodyText>from your bag</ModalBodyText>
                <RemoveProductButton
                    onClick={() => {
                        removeProductFromCart(productToRemove.id);
                        toggleRemoveProductModal();
                    }}
                    block
                    paddingTop
                >
                    Remove
                </RemoveProductButton>
                <CancelButton
                    onClick={toggleRemoveProductModal}
                    block
                    variant={'pseudoLink'}
                >
                    Cancel
                </CancelButton>
            </RemoveModalContentBodyWrapper>
        );
    };

    render() {
        const {
            productToRemove,
            showRemoveConfirmationModal,
            toggleRemoveProductModal
        } = this.props;

        if (!productToRemove || !showRemoveConfirmationModal) {
            return null;
        }

        return (
            <PrimaryModal
                isOpen={showRemoveConfirmationModal}
                closePadding={[0, 0, 19, 0]}
                onRequestClose={toggleRemoveProductModal}
                isDialogue
                previousScrollDisabledState={false}
            >
                {this.renderRemoveModalContent()}
            </PrimaryModal>
        );
    }
}
