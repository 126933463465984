import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'components/GlobalComponents/FlexBox/index';
import { styledProps, rem, greaterThan, lessThan } from 'core/styled';
import styled, { css } from 'styled-components';
import { Accordion, AccordionItemButton } from 'react-accessible-accordion';
import StyledAccordionItem from 'components/BeautyPlans/Common/Accordion/StyledAccordionItem';
import StyledAccordionItemHeadingWrapper from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemHeadingWrapper';
import StyledAccordionItemPanel from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemPanel';
import Description from 'components/GlobalComponents/Fonts/Description';
import { breakpoints } from 'constants/theme';
import { Separator } from 'components/BeautyPlans/BeautyPlans.styled';
import { connect } from 'react-redux';
import { PRISMIC_ARTIST_TYPE } from 'constants/prismic';
import abbyPic from 'assets/beautyplan/headshot/Abby.png';
import adriellePic from 'assets/beautyplan/headshot/Adrielle.png';
import alexPic from 'assets/beautyplan/headshot/Alex.png';
import joelPic from 'assets/beautyplan/headshot/Joel.png';
import lauriePic from 'assets/beautyplan/headshot/Laurie.png';

const NotesSection = styled(Flex)`
    padding: ${rem(40, 0, 10, 0)};
    ${({ noBorder }) => noBorder ? `padding-top: ${rem(10)};` : `
        border-top: 2px solid black;
        border-bottom: 2px solid black;
    `};
    margin: ${({ newBeautyPlan }) => rem(0, 20, newBeautyPlan ? 0 : 20)};
    width: calc(100% - 40px);
    min-height: auto;
    
     ${greaterThan(breakpoints.medium)(css`
          text-align: center;
          width: calc(50% - 40px);
          margin: auto;
          .accordionArrow {
            display: relative;
            right: unset;
          }
    `)}; 
    
    ${lessThan(breakpoints.tiny)(css`
        .accordionArrow {
            right: 5px;
        }
    `)}
    
    ${({ noBorder }) => noBorder ? `
        .accordionArrow {
            right: 50%;
            transform: translate(50%, 0);
            bottom: ${rem(-10)};
            top: unset;
        }
    ` : ''}
`;

const ApplicationNoteTitle = styled.div`
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    font-size: ${rem(20)};
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: center;
    ${greaterThan(breakpoints.medium)(css`
      display: inline-block
    `)};  
`;

const ApplicationNoteTitleNew = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(16)};
    text-align: center;
    height: ${rem(42)};
`;

const NotesArtistPicture = styled.img`
    margin-bottom: ${rem(10)};
`;

class Notes extends Component {

    static propTypes = {
        note: PropTypes.string,
        title: PropTypes.string,
        noBorder: PropTypes.bool,
        newBeautyPlan: PropTypes.bool,
        artists: PropTypes.array,
        makeupArtistName: PropTypes.string,
        noteTitleArtistReplace: PropTypes.bool,
        showArtistPicture: PropTypes.bool
    };

    static defaultProps = {
        noBorder: false,
        newBeautyPlan: false,
        noteTitleArtistReplace: false,
        showArtistPicture: false
    };

    renderTitle = () => {
        const { noteTitleArtistReplace, title, makeupArtistName, artists } = this.props;

        if (!noteTitleArtistReplace) {
            return title;
        }

        const splitName = makeupArtistName.split(' ');
        const lastName = splitName[splitName.length - 1];

        const artist = artists.find(a => a.name.toLowerCase().includes(lastName.toLowerCase()));

        if (artist) {
            const artistType = artist.artistType === PRISMIC_ARTIST_TYPE.MASTER_ARTIST ? 'master artist' : 'beauty guide';

            return `${title}<br/>${artistType}`;
        }

        return `${title}<br/>beauty guide`;
    };

    getArtistPicture = () => {
        const { makeupArtistName } = this.props;

        const splitName = makeupArtistName.split(' ');
        const firstName = splitName[0].toLowerCase();

        if (firstName.includes('abby') || firstName.includes('shahnaz')) {
            return abbyPic;
        }
        if (firstName.includes('adrielle') || firstName.includes('charlie')) {
            return adriellePic;
        }
        if (firstName.includes('alex')) {
            return alexPic;
        }
        if (firstName.includes('joel') || firstName.includes('nikith')) {
            return joelPic;
        }
        if (firstName.includes('laurie')) {
            return lauriePic;
        }

        return null;
    };

    render() {
        const { note, title, noBorder, newBeautyPlan, showArtistPicture } = this.props;

        if (!note) {
            if (noBorder) {
                return null;
            }

            return (
                <Separator marginBottom={15}/>
            );
        }

        const artistPicUrl = showArtistPicture && this.getArtistPicture();

        return (
            <NotesSection flexDirection='column' noBorder={noBorder} newBeautyPlan={newBeautyPlan}>
                {artistPicUrl && <Flex justifyContent={'center'} alignItems={'center'}>
                    <NotesArtistPicture src={artistPicUrl} alt={''} />
                </Flex>}
                <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['application-notes']}>
                    <StyledAccordionItem right={noBorder ? 0 : 35} uuid={'application-notes'}>
                        <StyledAccordionItemHeadingWrapper>
                            <AccordionItemButton>
                                {title ?
                                    <ApplicationNoteTitleNew dangerouslySetInnerHTML={{ __html: this.renderTitle() }}/> :
                                    <ApplicationNoteTitle>Application Notes</ApplicationNoteTitle>}
                                <div className={'accordionArrow'} />
                            </AccordionItemButton>
                        </StyledAccordionItemHeadingWrapper>
                        <StyledAccordionItemPanel>
                            <Description fontSize={14} lineHeight={1.5} dangerouslySetInnerHTML={{ __html: note }} />
                        </StyledAccordionItemPanel>
                    </StyledAccordionItem>
                </Accordion>
            </NotesSection>
        );
    }

}

const mapStateToProps = ({ beautyplan: { currentPlan: { makeupArtistName } }, makeupArtists: { artistData, residencyArtistData, virtualArtistData } }) => {

    return {
        artists: [...artistData, ...residencyArtistData, ...virtualArtistData],
        makeupArtistName
    };
};

export default connect(mapStateToProps, null)(Notes);
