/**
 * Generate a random number between 0 (or offset) and max
 * @param {number} max
 * @param {number} offset
 * @return {number} number
 */
const getRandomInt = (max, offset = 0) => {
    return Math.floor(Math.random() * Math.floor(max - offset)) + offset;
};

export default getRandomInt;
