/**
 * Group a collection by key
 * @param {array} collection
 * @param {string} key
 * @return {object} collection grouped by key
 */
const groupBy = (collection, key) => {
    return collection.reduce((result, elt) => {
        (result[elt[key]] = result[elt[key]] || []).push(elt);
        return result;
    }, {});
};

export default groupBy;
