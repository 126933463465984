import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';
import { AccordionItem } from 'react-accessible-accordion';

const AccordionItemStyled = styled(AccordionItem)`
    cursor: pointer;

    > div {
        outline: none;
    }
    .accordionArrow {
        display: inline-block;
        width: ${rem(24)};
        height: ${rem(12)};
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: ${rem(-6)};
        
            &:after,
            &:before {
                display: block;
                position: absolute;
                top: 50%;
                width: ${rem(10)};
                height: ${rem(2)};
                background-color: ${styledProps('color', 'black')};
                content: '';
                transition: transform 0.25s ease, -webkit-transform 0.25s ease;
            }
            
            &:before {
                left: ${rem(4)};
                transform: rotate(45deg);
            }
            
            &:after {
                right: ${rem(4)};
                transform: rotate(-45deg);
            }
    }
    
    [aria-expanded='true'], 
    [aria-selected='true'] {
    
        .accordionArrow {
             &:before {
                left: 4px;
                transform: rotate(-45deg);
            }
            
            &:after {
                right: 4px;
                transform: rotate(45deg);
            }
        }
    }
`;

export default AccordionItemStyled;
