import { theme } from 'constants/theme';

/**
 * Generate rem value from pixel value
 * @param {(number|string)} pixels/auto
 * @returns {string} rem value
 */
export const rem = (...pixels) => {
    const root = theme.global.rootFontSize;
    return pixels.map((value) => {
        // check for 'auto' values as rem() is used in padding's & margins
        return isNaN(value) ? value : `${value / root}rem`;
    }).join(' ');
};
