import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inputStyle, rem, styledProps } from 'core/styled';
import { Box } from 'components/GlobalComponents/FlexBox';
import itemSort from 'core/utils/itemSort';

const Select = styled.select`
    ${inputStyle};
    background-color: ${ styledProps('color', 'white') };
    color: ${ styledProps('color', 'adaGrey') };
    font-size: ${rem(14)};
    font-weight: normal;
    border-radius: 0;
    border: 1px solid ${ styledProps('color', 'black') };
    width: 300px;
    text-align-last: center;
    appearance: initial;
`;

const SelectArrow = styled.div`
    width: 15px;
    height: 15px;
    position: relative;
    margin: 0 0 0 auto;
    top: 21px;
    right: 10px;
    overflow: hidden;
    
    &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        bottom: 6px;
        left: 0;
        border: 1px solid ${styledProps('color', 'black')};
        transform: rotate(45deg);
    }
    
`;

// This isn't using the SelectInput component because it could not deal with the type of items we're handling here (products)
export default class ProductDropdown extends Component {

    static propTypes = {
        products: PropTypes.array,
        addProduct: PropTypes.func.isRequired
    };

    static defaultProps = {
        products: []
    };

    handleChange = (e) => {
        const selectedSku = e.target.value;
        if (selectedSku === '0') {
            return;
        }

        const { products, addProduct } = this.props;
        addProduct(products.find(p => p.sku === selectedSku));
        // reset the select to Choose Product
        e.target.value = 0;
    };

    render() {
        const { products } = this.props;

        return (
            <Box>
                <SelectArrow/>
                <Select onChange={(e) => this.handleChange(e)} width={0.6}>
                    <option value='0'>Choose products to add </option>
                    {products.sort(itemSort('name')).map((p, i) => (
                        <option key={i} value={p.sku}>
                            {p.name} {p.variantInfo}
                        </option>
                    ))}
                </Select>
            </Box>
        );
    }

}
