import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styledProps, rem } from 'core/styled';
import FormWithValidation from 'components/GlobalComponents/FormWithValidation/FormWithValidation';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import Button from 'components/GlobalComponents/Button/Button';
import validationMessages from 'constants/validationMessages';
import EnvelopeConfirmationModal from './EnvelopeConfirmationModal';

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${rem(30)};
    text-align: left;
`;

const EnvelopeHeader = styled.h3`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(12)};
    letter-spacing: ${rem(.5)};
`;

const EnvelopeLabel = styled.label`
    margin-top: ${rem(10)};
    width: 100%;
    text-align: center;
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(12)};
    display: block;
`;

const StyledButton = styled(Button)`
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

class EnvelopeForm extends Component {

    static propTypes = {
        saveEnvelopeDetails: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        startSubmitted: PropTypes.bool.isRequired
    };

    state = {
        error: '',
        isInvalid: true,
        submitted: this.props.startSubmitted
    };

    envelopeModal = React.createRef();

    _handleSubmit = () => {
        this.envelopeModal.current.toggleModal();
    };

    _handleInputChange = validateForm => event => {
        const { form, value, id } = event.target;

        this.setState({
            [id]: value,
            isInvalid: validateForm(form)
        });
    };

    _confirmEnvelope = () => {
        const { date, number } = this.state;
        this.setState({
            submitted: true
        });

        this.props.saveEnvelopeDetails(date, number)
            .then(() => {
                this.envelopeModal.current.toggleModal();
            })
            .catch(() => {
                this.envelopeModal.current.toggleModal();
            });
    };

    render() {
        const { isLoading } = this.props;
        const { error, isInvalid, date, number, submitted } = this.state;

        return (
            <div>
                {!submitted && <FormWrapper>
                    <EnvelopeHeader>Envelope Details</EnvelopeHeader>
                    <FormWithValidation onSubmit={this._handleSubmit} error={error}>
                        {({ validateForm, onSubmit, onChange }) => {
                            const formInputProps = {
                                required: true,
                                onChange: this._handleInputChange(validateForm),
                                labelFontSize: 12,
                                inputfont: 'ABChanelPBM',
                                labelFont: 'SofiaPro'
                            };

                            return (
                                <form {...{ onSubmit, onChange }} noValidate>
                                    <EnvelopeLabel for={'date'}>Date Issued</EnvelopeLabel>
                                    <FormInput
                                        {...formInputProps}
                                        id="date"
                                        type="date"
                                        alwaysMobile
                                        validationMessageOverwrite={{
                                            patternMismatch: validationMessages.invalidEmailFormat,
                                            valueMissing: validationMessages.invalidCharacters('Date')
                                        }}
                                    />
                                    <EnvelopeLabel for={'number'}>Envelope Number</EnvelopeLabel>
                                    <FormInput
                                        {...formInputProps}
                                        id="number"
                                        label="Enter Envelope Number"
                                        type="text"
                                        labelActiveByDefault
                                        validationMessageOverwrite={{
                                            valueMissing: validationMessages.invalidCharacters('Envelope Number')
                                        }}
                                    />
                                    <StyledButton
                                        type="submit"
                                        id="envelope-submit"
                                        block
                                        variant={'primary'}
                                        disabled={isInvalid}
                                        marginTop={rem(26)}
                                        marginBottom={rem(26)}
                                    >
                                        Submit
                                    </StyledButton>
                                </form>
                            );
                        }}
                    </FormWithValidation>
                </FormWrapper>}
                <EnvelopeConfirmationModal
                    ref={this.envelopeModal}
                    date={date}
                    number={number}
                    handleConfirm={this._confirmEnvelope}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

export default EnvelopeForm;
