import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'constants/routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';
import { Flex } from 'components/GlobalComponents/FlexBox';
import LikeListButtons from 'components/ProductComponents/LikeListButtons/LikeListButtons';
import PriceTag from 'components/GlobalComponents/Fonts/product/PriceTag';
import SimpleProductNote from 'components/BeautyPlans/Common/SimpleProductNote';

const BeautyplanProductCardWrapper = styled(Flex)`
    text-align: center;
    min-height: auto;
    margin-bottom: ${rem(20)};
    ${({ cssMaxWidth }) =>
        cssMaxWidth ? `max-width: ${rem(cssMaxWidth)};` : null}
`;

const IncludedInKit = styled.p`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(8)};
    line-height: ${rem(11)};
    padding: ${rem(2, 10)};
    background-color: ${styledProps('color', 'lightGray')};
`;

const ProductImage = styled.img`
    ${({ isFragranceFlight }) =>
        `max-width: ${rem(isFragranceFlight ? 250 : 120)};`}
    width: 100%;
`;

const ProductName = styled.p`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(10)};
    line-height: ${rem(13)};
    width: ${rem(160)};
    margin-top: ${rem(5)};
`;

const ProductSubTitle = styled.p`
    font-family: ${styledProps('font', 'default')};
    font-size: ${rem(12)};
    line-height: ${rem(17)};
    margin: ${rem(5, 0, 0)};
    width: ${rem(160)};
    text-align: center;
`;

const ProductVariant = styled(ProductSubTitle)`
    margin: ${rem(8, 0, 0)};
    font-size: ${rem(10)};
    line-height: ${rem(12)};
`;

const BatchCodeText = styled(ProductSubTitle)`
    color: ${styledProps('color', 'adaGrey')};
`;

const StyledPriceTag = styled(PriceTag)`
    margin: ${rem(15, 0, 5)};
`;

const CardFlex = styled(Flex)`
    padding: 0 ${rem(60)};
`;

const NonChanelBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(153)};
    height: ${rem(153)};
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(10)};
    line-height: ${rem(13)};
    background-color: ${styledProps('color', 'wildSand')};
    margin: ${rem(80, 'auto')};
`;

class SimpleBeautyPlanProductCard extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        isStaffProfile: PropTypes.bool.isRequired,
        authenticated: PropTypes.bool.isRequired,
        withProductNote: PropTypes.bool,
        showBatchCode: PropTypes.bool,
        isFragranceFlight: PropTypes.bool,
        artist: PropTypes.string,
        cssMaxWidth: PropTypes.number
    };

    render() {
        const {
            product,
            push,
            isStaffProfile,
            authenticated,
            withProductNote,
            showBatchCode,
            isFragranceFlight,
            artist,
            cssMaxWidth
        } = this.props;

        if (product.sku === 'non-chanel') {
            return (
                <BeautyplanProductCardWrapper
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    cssMaxWidth={cssMaxWidth}
                >
                    <CardFlex
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <NonChanelBox>
                            Your<br/>
                            Current<br/>
                            Product
                        </NonChanelBox>
                    </CardFlex>
                </BeautyplanProductCardWrapper>
            );
        }

        return (
            <BeautyplanProductCardWrapper
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                cssMaxWidth={cssMaxWidth}
            >
                <CardFlex
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() =>
                        push(
                            `${ROUTES.PRODUCT_DESCRIPTION}/${product.productCode}`
                        )
                    }
                >
                    {product.inKit && <IncludedInKit>Included in kit</IncludedInKit>}
                    <ProductImage
                        isFragranceFlight
                        src={
                            isFragranceFlight && product.fragranceImage
                                ? product.fragranceImage
                                : product.mediumImages[0]
                        }
                        alt={''}
                    />
                    <ProductName>
                        <span translate={'no'} className={'notranslate'}>
                            {product.name}
                        </span>
                    </ProductName>
                    <ProductSubTitle
                        dangerouslySetInnerHTML={{
                            __html: product.subName
                        }}
                    />
                    {product.variantInfo && (
                        <ProductVariant
                            dangerouslySetInnerHTML={{
                                __html: product.variantInfo
                            }}
                        />
                    )}
                    {showBatchCode && product.batchCode && (
                        <BatchCodeText marginTop={10} marginBottom={5} fontSize={8}>
                            Sample Number {product.batchCode}
                        </BatchCodeText>
                    )}
                    <StyledPriceTag>${product.price}</StyledPriceTag>
                </CardFlex>
                {!isStaffProfile && authenticated && (
                    <LikeListButtons
                        iconPadding={[20, 15]}
                        product={product}
                        renderDislikeButton={false}
                    />
                )}
                {withProductNote && product.note && (
                    <SimpleProductNote
                        note={product.note}
                        artist={artist}
                        cssMarginTop={-20}
                    />
                )}
            </BeautyplanProductCardWrapper>
        );
    }
}

const mapStateToProps = ({
    user: {
        auth: { authenticated, isStaff, isImpersonating }
    }
}) => ({
    isStaffProfile: isStaff && !isImpersonating,
    authenticated
});

const mapDispatchToProps = dispatch => bindActionCreators({ push }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SimpleBeautyPlanProductCard);
