import merge from 'core/utils/merge';

/**
 * Map error to field errors
 * @param {object} error
 * @return {object} fieldErrors
 */
export default function mapFieldErrors(error) {
    return error.details
        .map(({ property, message }) => ({ [property]: message }))
        .reduce(merge, {});
}
