import { createAction, handleActions } from 'redux-actions';
import { FETCH, FETCH_PENDING, FETCH_SUCCESS, FETCH_ERROR } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';

const initialState = {
    isFetching: false,
    response: null,
    error: null
};

export const QUESTIONNAIRE = 'QUESTIONNAIRE';

export const fetchAnswers = createAction(FETCH, (profileId) => {
    return {
        prefix: QUESTIONNAIRE,
        endpoint: `${CONFIG.API_URL}/questionnaires?profileId=${profileId}`,
        options: {
            method: 'GET'
        }
    };
});

export const setAnswers = createAction(FETCH, (profileId, payload) => ({
    prefix: QUESTIONNAIRE,
    endpoint: `${CONFIG.API_URL}/questionnaires?profileId=${profileId}`,
    options: {
        method: 'POST',
        body: JSON.stringify(payload)
    }
}));

export const deleteAnswers = createAction(FETCH, (profileId) => {
    return {
        prefix: QUESTIONNAIRE,
        endpoint: `${CONFIG.API_URL}/questionnaires?profileId=${profileId}`,
        options: {
            method: 'DELETE'
        }
    };
});

/**
 * Returns the formatted saved answers for the specified questionnaire.
 * @param {string} questionnaireId
 * @param {string} appointmentId
 * @param {boolean} includeCompleted - Include the "complete" value from api response
 * @returns {function(*, *): *} - Answer promise
 */
export const getAnswers = (questionnaireId, appointmentId, includeCompleted = false) => {
    return (dispatch, getState) => {
        const profileId = getState().user.auth.profile.id;

        return dispatch(fetchAnswers(profileId)).then(answerData => {
            const answers = {};

            answerData
                .filter(answer => appointmentId ?
                    answer.questionnaireId === `${questionnaireId}-${appointmentId}` :
                    answer.questionnaireId === questionnaireId)
                .forEach(question => {
                    answers[question.questionId] = {
                        selected: question.answers,
                        freeFormValue: question.freeText,
                        freeFormChecked: !!question.freeText,
                        selectedFreeFormValue: question.selectedFreeText || '',
                        questionnaireCompleted: question.complete
                    };

                    if (includeCompleted) {
                        answers[question.questionId].complete = question.complete;
                    }
                });

            return answers;
        });
    };
};

/**
 * Returns the formatted saved answers that not match to the specified appointmentId.
 * @param {string} appointmentId
 * @returns {function(*, *): *} - Answer promise
 */
export const getOtherAnswers = (appointmentId) => {
    return (dispatch, getState) => {
        const profileId = getState().user.auth.profile.id;

        return dispatch(fetchAnswers(profileId)).then(answerData => {
            const answers = {};

            answerData
                .filter(answer => !answer.questionnaireId.includes(appointmentId))
                .forEach(question => {
                    answers[question.questionId] = {
                        selected: question.answers,
                        freeFormValue: question.freeText,
                        freeFormChecked: !!question.freeText,
                        selectedFreeFormValue: question.selectedFreeText || '',
                        questionnaireCompleted: question.complete
                    };
                });

            return answers;
        });
    };
};


export const setAppointmentContext = createAction('SET_APPOINTMENT');

export default handleActions(
    {
        [`${QUESTIONNAIRE}/${FETCH_PENDING}`]: (state) => ({
            ...state,
            ...initialState,
            isFetching: true
        }),
        [`${QUESTIONNAIRE}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            ...initialState,
            response: payload
        }),
        [`${QUESTIONNAIRE}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            ...initialState,
            error: payload
        }),
        [setAppointmentContext]: (state, { payload }) => ({
            ...state,
            ...initialState,
            appointmentContext: payload
        })
    },
    initialState
);
