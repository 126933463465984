import React from 'react';
import * as Prismic from '@prismicio/client';
import { linkResolver } from 'core/utils/linkResolver';
import { CONFIG } from 'constants/config';

export default class Preview extends React.Component {
    componentDidMount() {
        this.preview(this.props);
    }

    preview(props) {
        const params = new URLSearchParams(window.location.search);
        const previewToken = params.get('token');
        const documentID = params.get('documentId');

        const prismicClient = Prismic.createClient(CONFIG.PRISMIC_API_URL, {
            accessToken: CONFIG.PRISMIC_ACCESS_TOKEN
        });

        prismicClient
            .resolvePreviewURL({
                linkResolver,
                defaultURL: '/',
                previewToken,
                documentID
            })
            .then(url => {
                props.history.push(url);
            });
    }

    render() {
        return <p>Loading previews...</p>;
    }
}
