/**
 * Format a string according to a mask using #
 * Ex: a phone number 9171231122 -> (917) 123-1122, the mask is (###) ###-####
 * @param {string} value - string to format
 * @param {string} pattern - mask
 * @return {string} formatted string
 */
const formatMask = (value, pattern) => {
    let i = 0;
    const v = value.toString();

    return pattern.replace(/#/g, () => v[i++]);
};

export default formatMask;
