export const PRISMIC_ENDPOINTS = {
    GET_BY_UID: 'getByUID',
    GET_BY_IDS: 'getByIDs',
    GET_SINGLE: 'getSingle',
    GET_BY_DOCTYPE: 'get'
};

export const PRISMIC_PAGE_IDS = {
    ATELIER_SOHO: 'atelier-sohoh-120-wooster-st'
};

export const PRISMIC_DATA_FORMAT = {
    FOOTER: 'FOOTER',
    MAKEUP_ARTISTS: 'MAKEUP_ARTISTS',
    MAP_CONTENT_PAGE: 'MAP_CONTENT_PAGE',
    HOSTS: 'HOSTS'
};

export const PRISMIC_ARTIST_TYPE = {
    MASTER_ARTIST: 'master-artist',
    RESIDENCY_ARTIST: 'residency-artist',
    VIRTUAL_ARTIST: 'virtual-artist'
};
