import { updateProfile, setGuestFragrance } from './user';
import { toggleLikeList } from 'reducers/userList';
import { fetchFragranceExperienceProducts } from 'reducers/elasticsearch';
import { createAction, handleActions } from 'redux-actions';

const initialState = { products: [] };

/**
 * Sets the fragrance unlocked property on the profile.
 * @param {bool} status
 * @returns {function} Dispatched function
 */
export const setFragranceUnlocked = (status) => {
    return (dispatch, getState) => {
        const { user: { auth: { authenticated, profile: { id } } } } = getState();

        if (authenticated) {
            return dispatch(
                updateProfile(id,
                    {
                        fragrance: {
                            unlocked: status
                        }
                    })
            );
        } else {
            return dispatch(setGuestFragrance({ unlocked: status }));
        }
    };
};

const setFragranceProducts = createAction('SET_FRAGRANCE_PRODUCTS');

/**
 * Load products for the fragrance experience.
 * @returns {Function} - dispatched products
 */
export const loadFragranceProducts = () => {
    return (dispatch) => {
        dispatch(fetchFragranceExperienceProducts()).then(
            products => dispatch(setFragranceProducts(products))
        );
    };
};

/**
 * Sets the fragrance reveal property on the profile.
 * @param {array} products
 * @returns {function} Dispatched function
 */
export const setFragranceReveal = (products) => {
    return (dispatch, getState) => {
        const { user: { auth: { authenticated, profile: { id } } }, checkIn: { checkInData } } = getState();

        if (authenticated) {
            return dispatch(
                updateProfile(id,
                    {
                        fragrance: {
                            unlocked: true,
                            checkin: checkInData && checkInData.status ? checkInData.token : undefined,
                            timestamp: Date.now(),
                            products
                        }
                    })
            );
        } else {
            return dispatch(setGuestFragrance({
                unlocked: true,
                timestamp: Date.now(),
                products
            }));
        }
    };
};

/**
 * Fetches the product data from the provided skuIds and places
 * each product in the Like list in preparation for the fragrance app
 * reveal.
 * @param {number[]} skuIds
 * @returns {function} Dispatched function
 */
export const prepareSelectedFragranceProducts = (skuIds) => {
    return (dispatch, getState) => {
        const authenticated = getState().user.auth.authenticated;
        const fragranceProducts = getState().fragranceApp.products;
        const likeList = getState().userList.like;
        const selectedProducts = fragranceProducts.filter(product => skuIds.includes(parseInt(product.id, 10)));

        /// Add each product to the like list
        if (authenticated) {
            selectedProducts.forEach(product => {
                if (!likeList.some(l => l.sku === product.id && l.userdata.like)) {
                    dispatch(toggleLikeList(product, true));
                }
            });
        }

        return selectedProducts;
    };
};

export default handleActions(
    {
        [setFragranceProducts]: (state, { payload }) => ({ products: payload }),
    },
    initialState
);

