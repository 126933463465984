import { styledProps } from 'core/styled';
import styled from 'styled-components';

const FormWrapper = styled.div`
    background: ${styledProps('color', 'white')};
    padding: 40px 8px;
    text-align: center;
`;

export default FormWrapper;
