import styled from 'styled-components';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { rem } from 'core/styled';

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
    margin: 0 ${rem(30)};
    padding: ${rem(20)} 0;
    &.accordion__body--hidden {
        display: none;
    }
`;

export default StyledAccordionItemPanel;
