export const BEAUTYPLAN_EXIT_CONFIRMATION = 'beautyplanExit';
export const QUESTIONNAIRE_EXIT_CONFIRMATION = 'questionnaireExit';
export const QUICK_SHOP_EXIT_CONFIRMATION = 'quickShopExit';

export const confirmModalOptions = {
    [BEAUTYPLAN_EXIT_CONFIRMATION]: (name) => ({
        title: 'Are you sure?',
        confirmation: `By exiting you will lose all progress on ${name}'s current beauty plan.`,
        cancelLabel: 'Continue editing',
        proceedLabel: 'Yes, reset beauty plan'
    }),

    [QUESTIONNAIRE_EXIT_CONFIRMATION]: () => ({
        title: 'Are you sure?',
        confirmation: `By exiting you will lose all progress on your Beauty Questionnaire.`,
        cancelLabel: 'Continue editing',
        proceedLabel: 'Yes, cancel changes'
    }),

    [QUICK_SHOP_EXIT_CONFIRMATION]: () => ({
        title: 'Leave Shopping Experience?',
        confirmation: `Your activity will not be saved.`,
        cancelLabel: 'Nevermind',
        proceedLabel: 'Confirm',
        newDesign: true
    }),
};
