const preloadImages = (imgSources) => {
    let sources = [];
    if (!Array.isArray(imgSources)) {
        sources.push(imgSources);
    } else {
        sources = [...imgSources];
    }

    sources.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.alt = '';
    });
};

export default preloadImages;
