/**
 * Returns an array whose elements are arrays corresponding to the
 * enumerable property [key, value] pairs found directly upon object.
 * The ordering of the properties is the same as that given by looping
 * over the property values of the object manually.
 * @param {object} obj
 * @return {[{key, value}]} [[key, value],...]
 */
export default function entries(obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array

    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }

    return resArray;
}
