import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'components/GlobalComponents/FlexBox/index';
import { styledProps, rem, greaterThan, lessThan } from 'core/styled';
import styled, { css } from 'styled-components';
import BeautyPlanProductCard from './BeautyPlanProductCard';
import StyledAccordionItem from 'components/BeautyPlans/Common/Accordion/StyledAccordionItem';
import StyledAccordionItemPanel from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemPanel';
import StyledAccordionItemHeadingWrapper from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemHeadingWrapper';
import { Accordion, AccordionItemButton } from 'react-accessible-accordion';
import Description from 'components/GlobalComponents/Fonts/Description';
import ProductSectionWrapper from 'components/BeautyPlans/Common/ProductSectionWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import { breakpoints } from 'constants/theme';

const NotesSection = styled(Flex)`
    margin: 0 ${rem(20)};
    padding-bottom: ${rem(55)};  
   
    ${greaterThan(breakpoints.medium)(css`
          text-align: center;
    
          .accordionArrow {
            display: relative;
            right: unset;
          }
    `)}; 
    
    ${lessThan(breakpoints.tiny)(css`
        
        .accordionArrow {
            right: 15px;
        }
        
    `)}
`;

const TrialKitWrapperContainer = styled.div``;
const TrialKitWrapper = styled(ProductSectionWrapper)`
    border-top: 1px dashed ${styledProps('color', 'black')};
    padding-bottom: ${rem(10)};
    margin: 0 ${rem(21)};
    justify-content: flex-start;
    width: initial;
    min-height: auto;

    ${greaterThan(breakpoints.medium)(css`
        border-top: 1px solid ${styledProps('color', 'black')};
        border-bottom: none;
        width: 50%;
        margin: auto;
    `)};  
    
`;

const TrialKitElementWrapper = styled.div`
    border-bottom: 2px solid ${styledProps('color', 'black')};
    padding-top: ${rem(50)};
    
     ${greaterThan(breakpoints.medium)(css`
        flex-direction: column;
        border-bottom: 1px solid ${styledProps('color', 'black')};
    `)};  
`;

const ApplyNoteTitle = styled.div`
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    font-size: ${rem(20)};
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: center;
    
   ${greaterThan(breakpoints.medium)(css`
      display: inline-block
  `)};  
`;

class TrialKitCustomer extends Component {
    static propTypes = {
        products: PropTypes.array
    };

    render() {
        const { products } = this.props;
        if (!products) {
            return null;
        }

        return (
            <TrialKitWrapperContainer id='atelier-workplan--trialkitcontent'>
                <TrialKitWrapper flexDirection={'column'}>
                    {products.map((product, key) => {
                        return (
                            <TrialKitElementWrapper key={key}>
                                <BeautyPlanProductCard isEven={key % 2 === 0} product={product} showBatchCode />
                                <NotesSection flexDirection='column' >
                                    <Accordion allowMultipleExpanded allowZeroExpanded>
                                        <StyledAccordionItem right={40}>
                                            <StyledAccordionItemHeadingWrapper>
                                                <AccordionItemButton>
                                                    <ApplyNoteTitle>How to apply</ApplyNoteTitle>
                                                    <div className={'accordionArrow'} />
                                                </AccordionItemButton>
                                            </StyledAccordionItemHeadingWrapper>
                                            <StyledAccordionItemPanel>
                                                <Description fontSize={14} lineHeight={1.5} dangerouslySetInnerHTML={{ __html: product.howToUse }} />
                                            </StyledAccordionItemPanel>
                                        </StyledAccordionItem>
                                    </Accordion>
                                </NotesSection>
                            </TrialKitElementWrapper>
                        );
                    })}
                </TrialKitWrapper>
            </TrialKitWrapperContainer>
        );
    }
}


const mapStateToProps = ({ beautyplan: { currentPlan: { trialKitProducts } } }) => ({
    products: trialKitProducts
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TrialKitCustomer);
