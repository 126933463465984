import { styledProps } from 'core/styled';
import { getNestedValue } from 'core/utils';

export const variantStyles = {
    backgroundColor: {
        normal: {
            primary: 'transparent',
            primaryInverted: 'transparent',
            pseudoLink: 'transparent',
            pseudoLinkInverted: 'transparent',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'transparent',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'transparent',
            underlineSelected: 'transparent'
        },
        loading: {
            primary: 'transparent',
            primaryInverted: 'transparent',
            pseudoLink: 'transparent',
            pseudoLinkInverted: 'transparent',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'transparent',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'transparent',
            underlineSelected: 'transparent'
        },
        disabled: {
            primary: 'transparent',
            primaryInverted: 'transparent',
            pseudoLink: 'transparent',
            pseudoLinkInverted: 'transparent',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'transparent',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'transparent',
            underlineSelected: 'transparent'
        },
        isHovered: {
            primary: 'black',
            primaryInverted: 'white',
            pseudoLink: 'transparent',
            pseudoLinkInverted: 'transparent',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'white',
            secondaryRed: 'milanoRed',
            underlineSelected: 'transparent'
        },
        isClicked: {
            primary: 'black',
            primaryInverted: 'white',
            pseudoLink: 'transparent',
            pseudoLinkInverted: 'transparent',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'white',
            secondaryRed: 'milanoRed',
            underlineSelected: 'transparent'
        },
        selected: {
            primary: 'black',
            primaryInverted: 'white',
            pseudoLink: 'transparent',
            pseudoLinkInverted: 'transparent',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'white',
            secondaryRed: 'milanoRed',
            underlineSelected: 'transparent'
        },
    },
    color: {
        normal: {
            primary: 'black',
            primaryInverted: 'white',
            pseudoLink: 'black',
            pseudoLinkInverted: 'white',
            simpleGrey: 'silverChalice',
            simpleBlack: 'black',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'white',
            secondaryRed: 'milanoRed',
            underlineSelected: 'black'
        },
        loading: {
            primary: 'warmGrey',
            primaryInverted: 'warmGrey',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'warmGrey',
            simpleGrey: 'silverChalice',
            simpleBlack: 'black',
            warmGrey: 'warmGrey',
            primaryWhite: 'warmGrey',
            primaryRed: 'white',
            secondaryRed: 'milanoRed',
            underlineSelected: 'warmGrey'
        },
        disabled: {
            primary: 'warmGrey',
            primaryInverted: 'warmGrey',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'warmGrey',
            simpleGrey: 'silverChalice',
            simpleBlack: 'silverChalice',
            warmGrey: 'warmGrey',
            primaryWhite: 'warmGrey',
            primaryRed: 'warmGrey',
            secondaryRed: 'warmGrey',
            underlineSelected: 'warmGrey'
        },
        isClicked: {
            primary: 'white',
            primaryInverted: 'black',
            pseudoLink: 'black',
            pseudoLinkInverted: 'white',
            simpleGrey: 'black',
            simpleBlack: 'black',
            warmGrey: 'white',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'white',
            underlineSelected: 'black'
        },
        isHovered: {
            primary: 'white',
            primaryInverted: 'black',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'white',
            simpleGrey: 'black',
            simpleBlack: 'black',
            warmGrey: 'white',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'white',
            underlineSelected: 'warmGrey'
        },
        selected: {
            primary: 'white',
            primaryInverted: 'black',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'white',
            simpleGrey: 'black',
            simpleBlack: 'black',
            warmGrey: 'white',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'white',
            underlineSelected: 'black'
        }
    },
    borderColor: {
        normal: {
            primary: 'black',
            primaryInverted: 'white',
            pseudoLink: 'black',
            pseudoLinkInverted: 'white',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'warmGrey',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'milanoRed',
            underlineSelected: 'transparent'
        },
        loading: {
            primary: 'warmGrey',
            primaryInverted: 'warmGrey',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'warmGrey',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'warmGrey',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'milanoRed',
            underlineSelected: 'transparent'
        },
        disabled: {
            primary: 'warmGrey',
            primaryInverted: 'warmGrey',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'warmGrey',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'warmGrey',
            primaryWhite: 'white',
            primaryRed: 'milanoRed',
            secondaryRed: 'milanoRed',
            underlineSelected: 'trasnparent'
        },
        isHovered: {
            primary: 'black',
            primaryInverted: 'black',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'warmGrey',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'milanoRed',
            secondaryRed: 'milanoRed',
            underlineSelected: 'warmGrey'
        },
        isClicked: {
            primary: 'black',
            primaryInverted: 'black',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'white',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'milanoRed',
            secondaryRed: 'milanoRed',
            underlineSelected: 'warmGrey'
        },
        selected: {
            primary: 'black',
            primaryInverted: 'black',
            pseudoLink: 'warmGrey',
            pseudoLinkInverted: 'warmGrey',
            simpleGrey: 'transparent',
            simpleBlack: 'transparent',
            warmGrey: 'black',
            primaryWhite: 'black',
            primaryRed: 'milanoRed',
            secondaryRed: 'milanoRed',
            underlineSelected: 'black'
        }
    },
    iconInvertFilter: {
        normal: {
            primary: 0,
            primaryInverted: 0,
            pseudoLink: 0,
            pseudoLinkInverted: 0,
            simpleGrey: 0,
            simpleBlack: 0,
            warmGrey: 0,
            primaryWhite: 0,
            primaryRed: 0,
            secondaryRed: 0,
            underlineSelected: 0
        },
        loading: {
            primary: 50,
            primaryInverted: 50,
            pseudoLink: 50,
            pseudoLinkInverted: 50,
            simpleGrey: 0,
            simpleBlack: 0,
            warmGrey: 50,
            primaryWhite: 50,
            primaryRed: 0,
            secondaryRed: 0,
            underlineSelected: 50
        },
        disabled: {
            primary: 50,
            primaryInverted: 50,
            pseudoLink: 50,
            pseudoLinkInverted: 50,
            simpleGrey: 0,
            simpleBlack: 0,
            warmGrey: 50,
            primaryWhite: 50,
            primaryRed: 50,
            secondaryRed: 50,
            underlineSelected: 50
        },
        isClicked: {
            primary: 100,
            primaryInverted: 100,
            pseudoLink: 100,
            pseudoLinkInverted: 100,
            simpleGrey: 0,
            simpleBlack: 0,
            warmGrey: 0,
            primaryWhite: 50,
            primaryRed: 0,
            secondaryRed: 0,
            underlineSelected: 50
        },
        isHovered: {
            primary: 100,
            primaryInverted: 100,
            pseudoLink: 50,
            pseudoLinkInverted: 0,
            simpleGrey: 50,
            simpleBlack: 50,
            warmGrey: 50,
            primaryWhite: 100,
            primaryRed: 0,
            secondaryRed: 0,
            underlineSelected: 50
        },
        selected: {
            primary: 100,
            primaryInverted: 100,
            pseudoLink: 50,
            pseudoLinkInverted: 0,
            simpleGrey: 50,
            simpleBlack: 50,
            warmGrey: 50,
            primaryWhite: 100,
            primaryRed: 0,
            secondaryRed: 0,
            underlineSelected: 0
        }
    }
};

const resolveConditionalStyle = (bool, properties) =>
    bool && getNestedValue(variantStyles, properties);

export const resolvePropStyle = (props, propStyle) => {
    const { isClicked, isHovered, disabled, loading, variant, selected } = props;

    return (
        resolveConditionalStyle(loading, [propStyle, 'loading', variant]) ||
        resolveConditionalStyle(disabled, [propStyle, 'disabled', variant]) ||
        resolveConditionalStyle(isClicked, [propStyle, 'isClicked', variant]) ||
        resolveConditionalStyle(isHovered, [propStyle, 'isHovered', variant]) ||
        resolveConditionalStyle(selected, [propStyle, 'selected', variant]) ||
        getNestedValue(variantStyles, [propStyle, 'normal', variant])
    );
};

export const width = ({ block }) => {
    return block ? '100%' : 'initial';
};

export const backgroundColor = props => {
    const backgroundColor = resolvePropStyle(props, 'backgroundColor');
    return styledProps('color', backgroundColor)(props);
};

export const color = props => {
    const color = resolvePropStyle(props, 'color');
    return styledProps('color', color)(props);
};

export const borderColor = props => {
    const borderColor = resolvePropStyle(props, 'borderColor');
    return styledProps('color', borderColor)(props);
};

export const iconInvertFilter = props => {
    return resolvePropStyle(props, 'iconInvertFilter');
};

export const cursor = ({ disabled, loading }) =>
    disabled ? 'not-allowed' : loading ? 'progress' : 'pointer';
