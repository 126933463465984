// These zone strings match the data from BE
// TODO: update to latest values when BE is done
export const ZONES = {
    SKIN_CARE: 'Skin Care',
    FRAGRANCE: 'Fragrance',
    CARE: 'Care',
    LIP_BAR: 'Lip Colour',
    CLEANSE: 'Cleanse',
    CARE_ENHANCE: 'Care & Enhance',
    COLOR_WALL: 'Colour Wall',
    EYE_DEFINITION: 'Eye Definers',
    FACE_DEFINITION: 'Face Definers',
    EYE_COLOR: 'Eye Colour',
    WORKSHOP: 'Workshop',
    NAIL_COLOR: 'Nail Colour',
    SKIN_ENHANCERS: 'Skin Enhancers',
    SELF_CARE: 'Self-Care',
    COCO_LAB: 'Coco Lab',
    NEW: 'New',
    FAVORITES: 'Soho Favorites',
    N5_HOLIDAY_2021: 'Holiday 2021 Collection N5'
};

export const IGNORE_BROWSABLE_FLAG = [
    ZONES.N5_HOLIDAY_2021
];

export const LIST_ZONES = {
    NEW: {
        title: 'New Products',
        link: ZONES.NEW
    },
    CLEANSE: {
        title: 'Cleanse',
        link: ZONES.CLEANSE
    },
    CARE: {
        title: 'Care',
        link: ZONES.CARE
    },
    SKIN_ENHANCERS: {
        title: 'Skin Enhancers',
        link: ZONES.SKIN_ENHANCERS
    },
    SELF_CARE: {
        title: 'Self-Care',
        link: ZONES.SELF_CARE
    },
    FACE_DEFINERS: {
        title: 'Face Definers',
        link: ZONES.FACE_DEFINITION
    },
    EYE_DEFINERS: {
        title: 'Eye Definers',
        link: ZONES.EYE_DEFINITION
    },
    // WORKSHOP: {
    //     title: 'Workshop',
    //     link: ZONES.WORKSHOP
    // },
    NAIL_COLOR: {
        title: 'Nail Colour',
        link: ZONES.NAIL_COLOR
    },
    LIP_COLOUR: {
        title: 'Lip Colour',
        link: ZONES.LIP_BAR
    },
    FRAGRANCE: {
        title: 'Fragrance',
        link: ZONES.FRAGRANCE
    },
    // FAVORITES: {
    //     title: 'Soho Favorites',
    //     link: ZONES.FAVORITES,
    //     subHeader: 'The Atelier community has spoken! Tap the heart icon if you love these products too.'
    // },
    N5_HOLIDAY_2021: {
        title: 'Holiday 2021 Collection N°5',
        link: ZONES.N5_HOLIDAY_2021,
        hidden: true
    }
};
