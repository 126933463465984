import { css } from 'styled-components';
import { borderColor } from './Button.styled';
import { rem } from 'core/styled';

const margin = '20px';
const pseudoMargin = '-14px';

const iconStyle = css`
    position: relative;

    &::before,
    &::after {
        color: ${borderColor};
        position: absolute;
        bottom: -2px;
    }
`;

const beforeIconStyle = css`
    ${iconStyle};
    margin-left: ${margin};

    &::before {
        content: '⌃';
        transform: rotate(-90deg);
        left: ${pseudoMargin};
    }
`;

const afterIconStyle = css`
    ${iconStyle};
    margin-right: ${margin};

    &::after {
        content: '⌃';
        transform: rotate(90deg);
        right: ${pseudoMargin};
    }
`;
// button styled like a link inverted
export const pseudoLinkInverted = ({ variant, beforeIcon, afterIcon }) =>
    variant === 'pseudoLinkInverted' &&
    css`
        height: auto;
        font-size: ${rem(9)};
        border-left: 0;
        border-right: 0;
        border-top: 0;
        padding: ${rem(4)} 0 0;

        &::before,
        &::after {
            font-size: ${rem(9)};
            transition: 0.2s color ease-in-out;
        }

        ${beforeIcon && beforeIconStyle};
        ${afterIcon && afterIconStyle};
    `;
