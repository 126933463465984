import moment from 'moment';
import { createAction } from 'redux-actions';
import { getCheckinMethod } from 'reducers/checkIn';
import { ROUTES } from 'constants/routes';
import { EVENTS } from 'constants/analytics';
import VIP from 'constants/vip';
import { getAttendedAppointments } from 'reducers/profileModules/myAppointments';
import { getAttendedEvents } from 'reducers/events';
import { lookupCustomerProfile } from 'reducers/customerProfileLookup';
import { setCheckinTimes } from 'reducers/user';

export const ANALYTICS = 'ANALYTICS';
const analytics = createAction(ANALYTICS);

/**
 * Get Current Analytics timestamp
 * @return {string} timestamp
 */
const getTimestamp = () => {
    return moment().format();
};

/**
 * Returns the page type for analytics based on the current path
 * @param {string} pathname
 * @return {string} page type
 */
export const getPageType = ({ pathname }) => {

    if (pathname === ROUTES.PRODUCT_SEARCH) {
        return 'search_results';
    } else if (pathname.startsWith(`${ROUTES.PRODUCT_DESCRIPTION}/P`)) {
        return 'pdp';
    } else if (pathname.startsWith(`${ROUTES.PRODUCT_LISTING}/`)) {
        return 'plp';
    }

    return pathname;
};

/**
 * Get User information from state for analytics
 * @param {object} state
 * @return {object} user information
 */
const getUserInformation = ({ user: { auth }, checkIn: { checkInData } }) => {
    const { isFirstTime, authenticated, profile, staffProfile, isImpersonating, accountType, isStaff, isFreelancer, attendedAppointments, attendedEvents, checkinTimes } = auth;

    if (authenticated) {
        const isCheckedIn = checkInData !== null && checkInData.status;

        const checkinInfo = {
            checkin: isCheckedIn ? 1 : 0,
            checkin_method: getCheckinMethod(checkInData),
            tourist: 0, // todo: update when available in the profile
            repeatCheckin: isFirstTime ? 0 : 1,
            loggedin: authenticated ? 1 : 0,
            accountType: accountType || undefined,
            checkin_type: isCheckedIn ? 'digital' : undefined,
            hasAppointmentToday: !checkInData ? null : checkInData.hasAppointmentToday,
            hasEventToday: !checkInData ? null : checkInData.hasEventToday,
            events_attended: attendedEvents || 0,
            appointments_attended: attendedAppointments || 0,
            number_of_checkins: checkinTimes || 0
        };

        return {
            gigyaId: profile.externalId,
            userId: profile.id,
            receiveTextMessages: profile.receiveTextMessages ? 1 : 0,
            receiveEmailMarketing: profile.receiveEmailMarketing ? 1 : 0,
            // if staff and impersonation, id is under staffProfile, otherwise under profile
            staffidentifier: isStaff ? isImpersonating ? staffProfile.id : profile.id : undefined,
            impersonation: isImpersonating ? 1 : 0,
            userType: isStaff ? (isFreelancer ? 'freelancer' : 'staff') : 'client',
            locale: 'en',
            region_chanel: 'us',
            membershipType: !profile.vip ? VIP.NON_MEMBER.STRING : profile.vip.status,
            ...(!isStaff || (isStaff && isImpersonating)) ? checkinInfo : {}
        };
    } else {
        // user information when not authenticated is very limited
        return {
            gigyaId: undefined,
            userId: undefined,
            staffId: undefined,
            impersonation: 0,
            userType: undefined,
            accountType: undefined,
            checkin: 0,
            checkin_type: undefined,
            checkin_method: undefined,
            tourist: 0,
            repeatCheckin: 0,
            locale: 'en',
            region_chanel: 'us',
            loggedin: 0,
        };
    }
};

/**
 * Analytics tag action
 * @param {object} payload
 * @param {object} config
 * @return {function(*, *): *} Promise
 */
export const analyticsTag = (payload, config = {}) => (dispatch, getState) => {
    const { globalLayout: { webView } } = getState();

    const timestamp = { timestamp: getTimestamp() };

    const { url, ...analyticsPayload } = payload;
    let userInformation = {};

    // send webview dimension
    analyticsPayload.webview = webView;

    if (Object.prototype.hasOwnProperty.call(config, 'userInfo')) {
        userInformation = getUserInformation(getState());
    }

    // if there was a url property in the analytics tag, strip it and send it as a separate event
    if (url) {
        const gigyaId = getUserInformation(getState()).gigyaId;

        dispatch(analytics({ event: EVENTS.Pageview, url, gigyaId, ...timestamp }));
        if (config.urlOnly) {
            return;
        }
    }

    return dispatch(analytics({ ...timestamp, ...analyticsPayload, ...userInformation }));
};

export const getAnalyticsInfo = () => (dispatch, getState) => {
    const { user: { auth: { authenticated, isStaff, isImpersonating, profile: { externalId } } } } = getState();

    if (authenticated && (!isStaff || (isStaff && isImpersonating))) {
        dispatch(getAttendedAppointments());
        dispatch(getAttendedEvents());
        dispatch(lookupCustomerProfile(externalId))
            .then(response => {
                if (response && response.length && response[0].checkins) {
                    dispatch(setCheckinTimes(response[0].checkins.visits));
                }
            });
    }
};
