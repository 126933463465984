import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import { SmallHeader } from 'components/GlobalComponents/Fonts/Header';
import { rem, greaterThan } from 'core/styled/index';
import { breakpoints } from 'constants/theme';
import { STAFF_QUESTION_TYPE } from 'constants/beautyplan';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import getRandomTiltedTextRotation from 'core/utils/getRandomTiltedTextRotation';
import { addStaffQuestions } from 'reducers/beautyplan';

const StaffQuestionsContainer = styled(Flex)``;

const StyledHeader = styled(SmallHeader)`
    padding: 0 ${rem(20, 15, 20)};
    position: relative;
    font-size: ${rem(32)};

    ${greaterThan(breakpoints.large)(css`
        font-size: ${rem(50)}; 
    `)}; 
`;

const QuestionsWrapper = styled(Flex)``;

const SwatchCheckboxWrapper = styled(Flex)`
    width: ${rem(95)};
`;

class StaffQuestions extends Component {

    static propTypes = {
        question: PropTypes.object.isRequired,
        addStaffQuestions: PropTypes.func.isRequired,
        currentPlan: PropTypes.object
    };

    state = {
        selectedOption: null
    };

    constructor(props) {
        super(props);

        const { question: { id, type }, currentPlan } = this.props;

        if (type === STAFF_QUESTION_TYPE.RADIO) {

            const savedValue = currentPlan.staffQuestions ? currentPlan.staffQuestions.find(item => item.question === id) : undefined;

            if (savedValue) {
                this.state = {
                    selectedOption: savedValue.answer
                };
            }
        }
    }

    handleCheckboxSelect = value => () => {
        const { addStaffQuestions, question: { id } } = this.props;

        this.setState({ selectedOption: value });
        addStaffQuestions({
            question: id,
            answer: value
        });
    };

    renderQuestions = () => {
        const { question: { questions, type } } = this.props;

        return questions.map((questionItem, key) => {

            if (type === STAFF_QUESTION_TYPE.RADIO) {
                return (
                    <SwatchCheckboxWrapper key={key} justifyContent={'center'}>
                        <SwatchCheckbox
                            name={questionItem.checkboxName}
                            text={questionItem.value}
                            value={questionItem.value}
                            textTilt={getRandomTiltedTextRotation()}
                            isSelected={this.state.selectedOption === questionItem.value}
                            onSelect={this.handleCheckboxSelect(questionItem.value)}/>
                    </SwatchCheckboxWrapper>
                );
            }

            return null;
        });
    };

    render() {
        const { question } = this.props;

        return (
            <StaffQuestionsContainer flexDirection={'column'}>
                <StyledHeader>{question.title}</StyledHeader>
                <QuestionsWrapper justifyContent={'space-around'}>
                    { this.renderQuestions() }
                </QuestionsWrapper>
            </StaffQuestionsContainer>
        );
    }

}

const mapStateToProps = ({ beautyplan: { currentPlan } }) => ({ currentPlan });

const mapDispatchToProps = dispatch =>
    bindActionCreators({ addStaffQuestions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StaffQuestions);
