import { nonDigitsRegex } from 'constants/regex';

/**
 * Format a US phone number to (ddd) ddd-dddd format.
 *
 * @param {string} number
 * @returns {String} phone
 */
export default function formatPhoneNumber(number) {
    const s2 = ('' + number).replace(/\D/g, '');
    const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
}

/**
 * Cleans the phone number from inputs to return a plain phone number
 * @param {string} phoneNumber
 * @return {string} sanitized phone number
 */
export const sanitizePhoneNumber = (phoneNumber) => {
    return phoneNumber.replace('+1', '').replace(nonDigitsRegex, '');
};
