import { DISCOVER_TABS } from './discover';
import { ROUTES } from './routes';

const TITLE_SUFFIX = '| ATELIER BEAUTÉ CHANEL';
export const ARTIST_NAME = 'ARTIST_NAME';

export const DEFAULT_META_DATA = {
    route: ROUTES.HOME,
    title: `Beauty Workshop by CHANEL ${TITLE_SUFFIX}`,
    description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`,
    image: 'https://atelierbeaute.chanel.com/static/media/logo.2be0883e.svg'
};

export const META_DATA = [
    {
        route: ROUTES.VISIT_ATELIER,
        title: `Visit the Atelier  ${TITLE_SUFFIX}`,
        description: `Located in the heart of Soho in New York City, the ATELIER BEAUTÉ CHANEL is open every day except on Mondays. For further information please visit our website.`
    },
    {
        route: ROUTES.NEW_TO_ATELIER,
        title: `New to the Atelier  ${TITLE_SUFFIX}`,
        description: `Located in the heart of Soho in New York City, the ATELIER BEAUTÉ CHANEL is open every day except on Mondays. For further information please visit our website.`
    },
    {
        route: ROUTES.SERVICE_OFFER,
        title: `Services  ${TITLE_SUFFIX}`,
        description: 'At the ATELIER BEAUTÉ CHANEL, you can learn how to create the look you want or you can book an appointment for a full-service makeup treatment.'
    },
    {
        route: `${ROUTES.DISCOVER}/${DISCOVER_TABS.SERVICES}`,
        title: `Services  ${TITLE_SUFFIX}`,
        description: 'At the ATELIER BEAUTÉ CHANEL, you can learn how to create the look you want or you can book an appointment for a full-service makeup treatment.'
    },
    {
        route: ROUTES.EVENTS,
        title: `The Atelier Upcoming Events  ${TITLE_SUFFIX}`,
        description: `There's always something happening at the ATELIER BEAUTÉ CHANEL. Visit our website to discover upcoming events and makeup happy hours.`
    },
    {
        route: `${ROUTES.DISCOVER}/${DISCOVER_TABS.CALENDAR}`,
        title: `The Atelier Upcoming Events  ${TITLE_SUFFIX}`,
        description: `There's always something happening at the ATELIER BEAUTÉ CHANEL. Visit our website to discover upcoming events and makeup happy hours.`
    },
    {
        route: ROUTES.CAREERS,
        title: `Careers at the Atelier - Join our Team  ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a place of learning, creation, and community, where masters and apprentices alike come to hone skills and share the process of discovery. Explore career opportunities today.`
    },
    {
        route: ROUTES.CONTACT,
        title: `Contact the Atelier  ${TITLE_SUFFIX}`,
        description: `Contact us anytime via email or text messages. You can also follow the ATELIER BEAUTÉ CHANEL on social media channels to get the latest updates or share your experience.`
    },
    {
        route: ROUTES.LOGIN,
        title: `Login  ${TITLE_SUFFIX}`,
        description: `Log in using your Chanel.com account to unlock all the benefits of the ATELIER BEAUTÉ CHANEL.`
    },
    {
        route: ROUTES.REGISTER,
        title: `Register and Create your Profile ${TITLE_SUFFIX}`,
        description: `Create your profile to unlock all the benefits of the ATELIER BEAUTÉ CHANEL, and we'll get you going. Register today!`
    },
    {
        route: ROUTES.HELP_FAQ,
        title: `The Atelier FAQ & Common Questions  ${TITLE_SUFFIX}`,
        description: `Here you will find answers to the most frequently asked questions about the ATELIER BEAUTÉ CHANEL.`
    },
    {
        route: ROUTES.ACCOUNT_INFORMATION,
        title: `My Account ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.PRODUCTS,
        title: `Products ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.PROFILE,
        title: `Profile ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.CHECK_IN,
        title: `Check-In ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.TRIED_LIST,
        title: `Favorites List  ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.FORGOT_PASSWORD,
        title: `Forgot Password ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.PRODUCT_SEARCH,
        title: `Product Search ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.WORKPLANS,
        title: `Beauty Plans ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.PRODUCT_LISTING,
        title: `Product Listing ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.PRODUCT_DESCRIPTION,
        title: `Product Description ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.RESET_PASSWORD,
        title: `Reset Password ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.CART,
        title: `Cart ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.BEAUTY_PLAN,
        title: `Beauty Plan ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.QUESTIONNAIRE,
        title: `Questionnaire ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.CHECKOUT,
        title: `Checkout ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.FRAGRANCE,
        title: `Parfum ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.PAST_PURCHASES,
        title: `Past Purchases ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.MY_EVENTS,
        title: `My Events ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.BOOKINGS,
        title: `Bookings ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.APPOINTMENT_BOOKING,
        title: `Appointment Booking ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.BOOKING,
        title: `Service Booking ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.VISIT_BOOKING,
        title: `Visit Booking ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.LEGAL_STATEMENT,
        title: `Legal Statement ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.APPOINTMENTS,
        title: `Appointments ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.GIFTS,
        title: `Gifts ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.SUBMIT_PAYMENT,
        title: `Payments ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.RSVP,
        title: `RSVP ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    },
    {
        route: ROUTES.VIDEO_THANK_YOU,
        title: `Thank You ${TITLE_SUFFIX}`,
        description: `The ATELIER BEAUTÉ CHANEL is a beauty workshop, designed for you to create–and recreate–yourself. Here, you will hone your skills, learn new makeup techniques, and discover things you love.`
    }
];
