import React from 'react';
import moment from 'moment-timezone';
import { BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS, BEAUTYPLAN_TYPE } from 'constants/beautyplan';
import TrialKitCustomer from 'components/BeautyPlans/Customer/TrialKitCustomer';
import SkincareCustomer from 'components/BeautyPlans/Customer/SkincareCustomer';
import NewSkincareCustomer from 'components/BeautyPlans/Customer/NewSkincareCustomer';
import MakeupCustomer from 'components/BeautyPlans/Customer/MakeupCustomer';
import NewMakeupCustomer from 'components/BeautyPlans/Customer/NewMakeupCustomer';
import BrowLookCustomer from 'components/BeautyPlans/Customer/BrowLookCustomer';
import NewBrowLookCustomer from 'components/BeautyPlans/Customer/NewBrowLookCustomer';
import TrialKit from 'components/BeautyPlans/Staff/TrialKit';
import Skincare from 'components/BeautyPlans/Staff/Skincare';
import NewSkincare from 'components/BeautyPlans/Staff/NewSkincare';
import Makeup from 'components/BeautyPlans/Staff/Makeup';
import NewMakeup from 'components/BeautyPlans/Staff/NewMakeup';
import BrowLook from 'components/BeautyPlans/Staff/BrowLook';
import NewBrowLook from 'components/BeautyPlans/Staff/NewBrowLook';
import FragranceExperience from 'components/BeautyPlans/Staff/FragranceExperience';
import FragranceExperienceCustomer from 'components/BeautyPlans/Customer/FragranceExperienceCustomer';
import BeautyGuideTalk from 'components/BeautyPlans/Staff/BeautyGuideTalk';
import BeautyGuideTalkCustomer from 'components/BeautyPlans/Customer/BeautyGuideTalkCustomer';
import AtHomeFacial from 'components/BeautyPlans/Staff/AtHomeFacial';
import AtHomeFacialCustomer from 'components/BeautyPlans/Customer/AtHomeFacialCustomer';
import NewSimpleRoutine from 'components/BeautyPlans/Staff/NewSimpleRoutine';
import NewSimpleRoutineCustomer from 'components/BeautyPlans/Customer/NewSimpleRoutineCustomer';

/**
 * Get the correct component based on the beauty plan step to render
 * @param {string} step
 * @param {bool} staff
 * @return {component} React Component
 */
export const getStepSection = (step, staff = false) => {
    switch (step) {

        case BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5:
        case BEAUTYPLAN_SECTIONS.SAMPLE_KIT_3:
            return staff ? <TrialKit /> : <TrialKitCustomer />;
        case BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE:
            return staff ? <Skincare /> : <SkincareCustomer />;
        case BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE:
            return staff ? <Makeup /> : <MakeupCustomer />;
        case BEAUTYPLAN_SECTIONS.BROW_LOOK:
            return staff ? <BrowLook /> : <BrowLookCustomer />;
        case BEAUTYPLAN_SECTIONS.NEW_BROW_LOOK:
            return staff ? <NewBrowLook /> : <NewBrowLookCustomer />;
        case BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE:
            return staff ? <NewMakeup /> : <NewMakeupCustomer />;
        case BEAUTYPLAN_SECTIONS.NEW_SKINCARE_ROUTINE:
            return staff ? <NewSkincare /> : <NewSkincareCustomer />;
        case BEAUTYPLAN_SECTIONS.FRAGRANCE_EXPERIENCE:
            return staff ? <FragranceExperience /> : <FragranceExperienceCustomer sectionConfig={step} />;
        case BEAUTYPLAN_SECTIONS.BEAUTY_GUIDE_TALK:
            return staff ? <BeautyGuideTalk /> : <BeautyGuideTalkCustomer />;
        case BEAUTYPLAN_SECTIONS.AT_HOME_FACIAL:
            return staff ? <AtHomeFacial /> : <AtHomeFacialCustomer />;
        case BEAUTYPLAN_SECTIONS.NEW_SKIN_ROUTINE:
        case BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE:
            return staff ? <NewSimpleRoutine sectionConfig={step} /> : <NewSimpleRoutineCustomer sectionConfig={step} />;
        default:
            return null;
    }
};

export const normalizeBeautyPlan = () => (plan) => {

    let beautyplan = plan;
    if (plan && Array.isArray(plan)) {
        if (plan.length === 0) {
            return null; // no beauty plan associated with appointment
        }
        beautyplan = plan[0];
    }

    const { id, owner, created, serviceType, appointmentId, makeupArtistName, eventId,
        makeupArtistId, products, notes, imageUrl, ownerFirstName, ownerLastName, staffQuestions, subSectionNotes, events, services } = beautyplan;

    const service = BEAUTYPLAN_TYPE[serviceType];

    // Ignore if beautyplan is not found in our page
    if (!service) {
        return null;
    }

    // figure out which tab(s) to include for this beauty plan
    const tabs = service.steps.map(step => {
        const config = BEAUTYPLAN_CONFIG[step];
        if (products.some(p => p.section === config.id)) {
            return {
                id: config.id,
                title: config.title,
                description: config.description,
                content: (getStepSection(step)),
                analytics: config.analytics
            };
        }
        return null;
    }).filter(t => t);

    const createdMoment = moment.tz(created, 'America/New_York');

    return {
        id,
        owner,
        eventId,
        appointmentId,
        createdDateTime: created,
        created: createdMoment.format('MM.DD.YYYY'),
        createdSimple: createdMoment.format('MMMM D'),
        createdSimpleYear: createdMoment.format('MMMM D, YYYY'),
        image: imageUrl ? imageUrl : service.image,
        hasCustomImage: !!imageUrl,
        imageDesktop: service.imageDesktop,
        imageTablet: service.imageTablet,
        service,
        ownerName: `${ownerFirstName} ${ownerLastName}`,
        makeupArtistName,
        makeupArtistId,
        tabs,
        products,
        notes,
        staffQuestions,
        subSectionNotes: subSectionNotes.map(note => ({
            note: note.note,
            id: note.section,
            sectionId: note.subSection,
            categoryId: note.category
        })),
        events,
        services
    };
};
