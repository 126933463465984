import { createAction } from 'redux-actions';
import createFetchReducer from 'reducers/createFetchReducer';
import { FETCH } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';

export const CUSTOMER_LOOKUP = 'CUSTOMER_LOOKUP';

export const lookupCustomerProfile = createAction(FETCH, id => ({
    prefix: CUSTOMER_LOOKUP,
    endpoint: `${CONFIG.API_URL}/profiles?externalId=${id}`,
    options: {
        method: 'GET'
    }
}));

export default createFetchReducer(CUSTOMER_LOOKUP);
