import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';
import { internetExplorerOnlyStyles, rem, styledProps } from 'core/styled';
import {
    getCartExternalCheckoutBase64Hash,
    updateCartQuantity,
    removeProductFromCart,
    getCart,
    getSubtotal,
    reset,
    checkout,
    getOnlineProducts,
    getAtelierProducts,
    setSelectedCheckoutMethod,
    setCartLocation,
    getAtelierProducts2,
    resetOrder
} from 'reducers/cart';
import { push, replace } from 'redux-first-history';
import { analyticsTag } from 'reducers/analytics';
import { LOCATIONS } from 'constants/locations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CHECKOUT_METHOD } from 'constants/checkoutMethods';
import { returnAfterLogin } from 'reducers/user';
import { FormInput } from 'components/GlobalComponents/FormInput/FormInput';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';
import Button from 'components/GlobalComponents/Button/Button';
import CartRemoveModal from './CartRemoveModal';
import CartCheckoutModal from './CartCheckoutModal';
import { ROUTES } from 'constants/routes';
import { eCommCheckout, EVENTS } from 'constants/analytics';
import Loader from 'components/GlobalComponents/Loader/Loader';
import { CompProductIdPrice } from 'constants/myPasses';

const CartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${rem(20, 30)};

    ${({ loading }) =>
        loading
            ? css`
                  height: calc(100vh - ${rem(50)});
                  justify-content: center;
              `
            : ''}

    &.cart--empty-wrapper {
        justify-content: center;
        height: calc(100vh - ${rem(100)});

        .cart--empty-title {
            font-family: ${styledProps('font', 'ABChanelPBM')};
            font-weight: bold;
            font-size: ${rem(18)};
            text-align: center;
        }

        .cart--empty-text {
            font-family: ${styledProps('font', 'default')};
            font-size: ${rem(12)};
            text-align: center;
            margin-top: ${rem(5)};
        }
    }

    .cart--items-in-bag {
        width: 100%;
        text-align: left;
        font-family: ${styledProps('font', 'default')};
        color: ${styledProps('color', 'mineShaft')};
        font-size: ${rem(14)};
    }

    .cart--subtotal {
        margin-top: ${rem(16)};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: ${styledProps('font', 'default')};
        color: ${styledProps('color', 'mineShaft')};
        font-size: ${rem(14)};
        font-weight: bold;
        padding-bottom: ${rem(18)};
        border-bottom: ${rem(1)} solid ${styledProps('color', 'mercury')};
    }
`;

const ProductsWrapper = styled.div`
    padding: ${rem(10, 0)};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const ProductItem = styled.div`
    display: flex;
    width: 100%;
    gap: ${rem(10)};
    margin: ${rem(10, 0)};
    position: relative;

    .cart--checkout-tag {
        position: absolute;
        left: ${rem(90)};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${rem(0, 10)};
        height: ${rem(15)};
        font-size: ${rem(8)};
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        background-color: ${styledProps('color', 'lightGray')};
    }

    .cart--product-image {
        width: ${rem(80)};
        height: ${rem(80)};
        display: block;
        opacity: ${({ greyedOut }) => (greyedOut ? 0.5 : 1)};
    }

    .cart--product-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        text-align: left;
        font-family: ${styledProps('font', 'default')};
        font-size: ${rem(12)};
        opacity: ${({ greyedOut }) => (greyedOut ? 0.5 : 1)};
        ${({ withTag }) => (withTag ? `margin-top: ${rem(20)};` : '')}

        .cart--product-name {
            font-family: ${styledProps('font', 'ABChanelPBM')};
            font-weight: bold;
        }

        .cart--product-text {
            margin-top: ${rem(5)};
            line-height: ${rem(16)};
            color: ${styledProps('color', 'mineShaft')};
            text-transform: uppercase;
        }

        .cart--product-qty-wrapper {
            margin-top: ${rem(10)};
            color: ${styledProps('color', 'mineShaft')};

            p {
                margin-top: ${rem(5)};
            }
        }

        .cart--bottom-block {
            margin: ${rem(10, 0)};
            display: flex;
            justify-content: space-between;

            .cart--bottom-block-remove {
                text-decoration: underline;
                color: ${styledProps('color', 'adaGrey')};
                font-size: ${rem(12)};
                cursor: pointer;
            }

            .cart--bottom-block-unit-price {
                text-align: right;
                color: ${styledProps('color', 'mineShaft')};
                font-weight: bold;
                flex-grow: 1;
            }
        }
    }
`;

const QtyWrapper = styled.div`
    position: relative;
    width: ${rem(60)};

    > div {
        > div {
            display: flex;
            padding: 0;
            align-items: center;

            > div {
                width: ${rem(40)};
            }

            label {
                transform: none;
                font-family: ${styledProps('font', 'default')};
                color: ${styledProps('color', 'mineShaft')};
                font-size: ${rem(12)};
                text-transform: uppercase;
            }

            select {
                margin: ${rem(0, 3)};
                appearance: none;
                border-radius: unset;
                font-size: ${rem(12)};
                color: ${styledProps('color', 'mineShaft')};
                font-weight: normal;
                height: ${rem(26)};
                padding-left: ${rem(7)};
                cursor: pointer;
            }

            ${internetExplorerOnlyStyles(css`
                select {
                    padding-left: ${rem(6)};
                    width: 27px;
                }
            `)}

            // Hide IE select appearance
           select::-ms-expand {
                display: none;
            }
        }
    }
`;

const SelectArrow = styled.div`
    width: 9px;
    height: 6px;
    position: absolute;
    margin: 0 auto;
    top: 10px;
    right: 0px;
    overflow: hidden;
    pointer-events: none;

    &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: -12px;
        right: -2.5px;
        border: 1px solid ${styledProps('color', 'mineShaft')};
        transform: rotate(45deg);
    }
`;

const ProductStateDisclaimer = styled.p`
    width: 100%;
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    border: ${rem(1)} solid ${styledProps('color', 'mercury')};
    padding: ${rem(15)};
    margin: ${rem(10, 0)};
`;

const CheckoutMethodsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${rem(10, 0)};

    .cart--checkout-title {
        width: 100%;
        text-align: left;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        font-size: ${rem(14)};
        margin: 0;
    }

    .cart--checkout-divider {
        margin: ${rem(12, 0, 0)};
        width: 100%;
        height: ${rem(4)};
        background-color: ${styledProps('color', 'black')};
    }

    .cart--checkout-option-wrapper {
        width: 100%;
        margin: ${rem(25, 0, 0)};
    }

    .cart--checkout-text-wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-left: ${rem(15)};
        margin-top: ${rem(-4)};

        .cart--checkout-text-main {
            font-family: ${styledProps('font', 'ABChanelPBM')};
            font-size: ${rem(12)};
            font-weight: bold;
            text-align: left;
        }

        .cart--checkout-text-description {
            font-family: ${styledProps('font', 'default')};
            font-size: ${rem(13)};
            text-align: left;
            margin-top: ${rem(5)};
        }
    }
`;

const ProceedButton = styled(Button)`
    margin-top: ${rem(35)};
    height: ${rem(38)};
    font-size: ${rem(11)};
    width: calc(100% - ${rem(40)});
`;

const cartAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'cart'
};

class CartSection extends Component {
    static propTypes = {
        updateCartQuantity: PropTypes.func.isRequired,
        setSelectedCheckoutMethod: PropTypes.func.isRequired,
        onlineProducts: PropTypes.array,
        analyticsTag: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        toggleCart: PropTypes.func.isRequired,
        selectedCheckoutMethod: PropTypes.string,
        cartLocation: PropTypes.number.isRequired,
        atelierSubtotal: PropTypes.number.isRequired,
        onlineSubtotal: PropTypes.number.isRequired,
        subtotal: PropTypes.number.isRequired,
        products: PropTypes.array.isRequired,
        items: PropTypes.array.isRequired,
        removeProductFromCart: PropTypes.func.isRequired,
        authenticated: PropTypes.bool.isRequired,
        returnAfterLogin: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        isStaff: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        getCart: PropTypes.func.isRequired,
        resetOrder: PropTypes.func.isRequired,
        customEmptyComponent: PropTypes.elementType,
        openingTimes: PropTypes.array.isRequired
    };

    state = {
        productToRemove: null,
        showRemoveConfirmationModal: false,
        showModal: false
    };

    componentDidMount() {
        const { getCart, setSelectedCheckoutMethod } = this.props;

        getCart().then(() => {
            setSelectedCheckoutMethod(this.getSelectedCheckoutmethod());
        });
    }

    toggleRemoveProductModal = () => {
        this.setState({
            showRemoveConfirmationModal: !this.state.showRemoveConfirmationModal
        });
    };

    removeProduct = product => () => {
        this.setState({
            productToRemove: product,
            showRemoveConfirmationModal: true
        });
    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    /**
     * Check if store is open to enable/disable instore checkout options
     * @returns {boolean} - open or close
     */
    isStoreOpen = () => {
        const { openingTimes } = this.props;

        //moment().tz('America/New_York').hour(9).minute(30).isBefore(moment())
        const now = moment();
        const nowInNYC = moment().tz('America/New_York');
        const weekDay = nowInNYC.day(); // 0 is sunday, 1 monday ...
        const todayOpening = openingTimes[weekDay];

        return (
            todayOpening.isAvailable &&
            nowInNYC
                .hour(todayOpening.openHour)
                .minute(todayOpening.openMinute)
                .isBefore(now) &&
            nowInNYC
                .hour(todayOpening.closeHour)
                .minute(todayOpening.closeMinute)
                .isAfter(now)
        );
    };

    /**
     * Get selected checkout method, defaulting to pos checkout method, if possible
     * @returns {string} checkout method
     */
    getSelectedCheckoutmethod = () => {
        const { cartLocation } = this.props;

        let availableInStore;

        if (cartLocation === LOCATIONS.AUSTIN) {
            availableInStore = this.props.products.filter(
                p => p.availableInStore2
            );
        } else {
            availableInStore = this.props.products.filter(
                p => p.availableInStore
            );
        }

        const availableOnline = this.props.products.filter(
            p => p.availableOnline
        );

        // don't select allow atelier checkout if user isn't checked-in or no item is available in store
        const isAtelierCheckoutDisabled =
            !this.isStoreOpen() || availableInStore.length === 0;
        // don't select online checkout if no item is available online
        const isOnlineCheckoutDisabled = availableOnline.length === 0;

        switch (true) {
            case !isAtelierCheckoutDisabled:
                return CHECKOUT_METHOD.IN_STORE;
            case !isOnlineCheckoutDisabled:
                return CHECKOUT_METHOD.ONLINE;
            default:
                return null;
        }
    };

    handleQtyUpdate = (sku, qty) => e => {
        const { updateCartQuantity } = this.props;
        const selection = parseInt(e.target.value, 10) || 0;
        const delta = selection - qty;

        updateCartQuantity(sku, delta);
    };

    handleCheckoutSelect = selected => {
        const { setSelectedCheckoutMethod } = this.props;

        setSelectedCheckoutMethod(selected);
    };

    handleOnlineCheckout = () => {
        const {
            onlineProducts,
            analyticsTag,
            push,
            reset,
            toggleCart
        } = this.props;

        analyticsTag(eCommCheckout('online', onlineProducts), {
            userInfo: true
        });
        analyticsTag(
            {
                ...cartAnalytics,
                eventAction: 'online',
                eventLabel: 'continuetochanel'
            },
            { userInfo: true }
        );

        window.open(
            `${window.location.origin + ROUTES.CART}?cartTransfer=true`
        );
        reset();
        push(ROUTES.PROFILE);
        toggleCart();
    };

    /**
     * Handle proceed to checkout button click, open online modal or go to atelier checkout page
     */
    handleProceedToCheckout = () => {
        const {
            selectedCheckoutMethod,
            cartLocation,
        } = this.props;
        let anyNotAvailableInStore;

        if (cartLocation === LOCATIONS.AUSTIN) {
            anyNotAvailableInStore = this.props.products.some(
                p => !p.availableInStore2
            );
        } else {
            anyNotAvailableInStore = this.props.products.some(
                p => !p.availableInStore
            );
        }

        if (
            selectedCheckoutMethod === CHECKOUT_METHOD.ONLINE ||
            (selectedCheckoutMethod !== CHECKOUT_METHOD.ONLINE &&
                anyNotAvailableInStore)
        ) {
            this.setState({
                showModal: true
            });
        } else {
            this.proceedToCheckoutPage();
        }
    };

    /**
     * Proceed to atelier checkout page
     */
    proceedToCheckoutPage = () => {
        const { push, resetOrder /* , analyticsTag */ } = this.props;

        resetOrder();
        push(ROUTES.CHECKOUT);
        // analyticsTag(
        //     {
        //         ...cartAnalytics,
        //         url: window.location.href,
        //         eventAction: 'atelier',
        //         eventLabel: 'revieworder'
        //     },
        //     { userInfo: true }
        // );
    };

    handleExploreProducts = () => {
        const { push, toggleCart } = this.props;

        push(ROUTES.PRODUCTS);
        toggleCart();
    };

    renderSubtotal = () => {
        const {
            selectedCheckoutMethod,
            atelierSubtotal,
            onlineSubtotal,
            subtotal
        } = this.props;

        switch (true) {
            case selectedCheckoutMethod === CHECKOUT_METHOD.IN_STORE:
                return atelierSubtotal;
            case selectedCheckoutMethod === CHECKOUT_METHOD.ONLINE:
                return onlineSubtotal;
            default:
                return subtotal;
        }
    };

    renderProductItem = product => {
        const {
            items,
            isStaff,
            cartLocation,
            selectedCheckoutMethod
        } = this.props;

        const cartItem = items.find(item => item.sku === product.sku);

        if (!cartItem) {
            return null;
        }

        const qty = cartItem.userdata.qty;
        const isStatic = product.staffManaged && !isStaff;

        let availableForCheckout = false;

        if (selectedCheckoutMethod === CHECKOUT_METHOD.IN_STORE) {
            availableForCheckout =
                product[
                    cartLocation === LOCATIONS.AUSTIN
                        ? 'availableInStore2'
                        : 'availableInStore'
                ];
        } else if (selectedCheckoutMethod === CHECKOUT_METHOD.ONLINE) {
            availableForCheckout = product.availableOnline;
        }

        const greyedOut =
            product[
                cartLocation === LOCATIONS.AUSTIN
                    ? 'isOutOfStock2'
                    : 'isOutOfStock'
            ] || !availableForCheckout;
        const checkoutTag =
            product[
                cartLocation === LOCATIONS.AUSTIN
                    ? 'checkoutTag2'
                    : 'checkoutTag'
            ];

        const isComplimentaryProduct = !!CompProductIdPrice.find(
            pip => pip.productSku === product.sku
        );

        return (
            <ProductItem
                key={product.sku}
                greyedOut={greyedOut}
                withTag={!!checkoutTag}
            >
                {checkoutTag && (
                    <p className={'cart--checkout-tag'}>{checkoutTag}</p>
                )}
                <img
                    className={'cart--product-image'}
                    src={product.packshot.medium()}
                    alt={''}
                />
                <div className={'cart--product-info'}>
                    <p className={'cart--product-name'}>{product.name}</p>
                    <p className={'cart--product-text'}>{product.subName}</p>
                    {product.variantInfo && (
                        <p className={'cart--product-text'}>
                            {product.variantInfo}
                        </p>
                    )}
                    <div className={'cart--product-qty-wrapper'}>
                        {!isStatic && (
                            <QtyWrapper>
                                <FormInput
                                    onChange={this.handleQtyUpdate(
                                        product.sku,
                                        qty
                                    )}
                                    id="qty"
                                    label="Qty"
                                    type="select"
                                    fullHeight={false}
                                    initialValue={qty}
                                    options={[1, 2, 3]}
                                    hideSelect={true}
                                    renderUnderline={false}
                                    srOnlyLabel={'Quantity'}
                                    ariaLabel={`Quantity ${product.name}`}
                                />
                                <SelectArrow />
                            </QtyWrapper>
                        )}
                        {isStatic && <p>QTY&nbsp;&nbsp;&nbsp;{qty}</p>}
                        <div className={'cart--bottom-block'}>
                            {!isStatic && (
                                <NoStyleButton
                                    type={'button'}
                                    className={'cart--bottom-block-remove'}
                                    onClick={this.removeProduct(product)}
                                >
                                    Remove
                                </NoStyleButton>
                            )}
                            <p className={'cart--bottom-block-unit-price'}>
                                {!product.price ||
                                product.price === '0' ||
                                isComplimentaryProduct
                                    ? 'Complimentary'
                                    : `$${product.price}`}
                            </p>
                        </div>
                    </div>
                </div>
            </ProductItem>
        );
    };

    renderOnlineOnlyDisclaimer = () => {
        return (
            <ProductStateDisclaimer>
                The following items are currently not available for purchase at
                the Atelier. Checkout on Chanel.com and we will deliver your
                items to your door step.
            </ProductStateDisclaimer>
        );
    };

    renderOutOfStockDisclaimer = () => {
        return (
            <ProductStateDisclaimer>
                The following items are currently out of stock. Talk to a team
                member to find an alternative.
            </ProductStateDisclaimer>
        );
    };

    renderProducts = () => {
        const { products, cartLocation } = this.props;

        const atelierProducts = products.filter(
            p =>
                p[
                    cartLocation === LOCATIONS.AUSTIN
                        ? 'availableInStore2'
                        : 'availableInStore'
                ]
        );
        const onlineOnlyProducts = products.filter(
            p =>
                p[
                    cartLocation === LOCATIONS.AUSTIN
                        ? 'onlineOnly2'
                        : 'onlineOnly'
                ]
        );
        const outOfStockProducts = products.filter(
            p =>
                p[
                    cartLocation === LOCATIONS.AUSTIN
                        ? 'isOutOfStock2'
                        : 'isOutOfStock'
                ]
        );

        return (
            <ProductsWrapper>
                {atelierProducts.map(this.renderProductItem)}
                {onlineOnlyProducts.length > 0 &&
                    this.renderOnlineOnlyDisclaimer()}
                {onlineOnlyProducts.map(this.renderProductItem)}
                {outOfStockProducts.length > 0 &&
                    this.renderOutOfStockDisclaimer()}
                {outOfStockProducts.map(this.renderProductItem)}
            </ProductsWrapper>
        );
    };

    renderCheckbox = (value, selected, title, description, disabled) => {
        return (
            <div className={'cart--checkout-option-wrapper'}>
                <SwatchCheckbox
                    name={SWATCH_CHECKBOX_NAMES.OTHERS_5}
                    display={'flex'}
                    flexDirection={'row'}
                    isSelected={selected}
                    cssHeight={8}
                    boxAlign={'flex-start'}
                    disabled={disabled}
                    opacity={disabled ? '0.5' : '1'}
                    value={value}
                    onSelect={this.handleCheckoutSelect}
                >
                    <div className={'cart--checkout-text-wrapper'}>
                        <p className={'cart--checkout-text-main'}>{title}</p>
                        <p className={'cart--checkout-text-description'}>
                            {description}
                        </p>
                    </div>
                </SwatchCheckbox>
            </div>
        );
    };

    renderCheckoutMethods = () => {
        const {
            selectedCheckoutMethod,
            products,
            items: cartItems,
            cartLocation
        } = this.props;

        const productsToCheckout = products.filter(
            product => !!cartItems.find(cartItem => cartItem.sku === product.id)
        );

        let notAvailableInStore;
        const notAvailableOnline = productsToCheckout.filter(
            p => !p.availableOnline
        );

        if (cartLocation === LOCATIONS.AUSTIN) {
            notAvailableInStore = productsToCheckout.filter(
                p => !p.availableInStore2
            );
        } else {
            notAvailableInStore = productsToCheckout.filter(
                p => !p.availableInStore
            );
        }

        const totalNumberProducts = productsToCheckout.length;

        // disable atelier checkout if user isn't checked-in or all items not available in store
        const isAtelierCheckoutDisabled =
            !this.isStoreOpen() ||
            notAvailableInStore.length === totalNumberProducts;
        // disable online checkout if all items not available online
        const isOnlineCheckoutDisabled =
            notAvailableOnline.length === totalNumberProducts;

        return (
            <CheckoutMethodsWrapper>
                <h3 className={'cart--checkout-title'}>
                    Select your checkout method
                </h3>
                <div className={'cart--checkout-divider'} />
                {this.renderCheckbox(
                    CHECKOUT_METHOD.IN_STORE,
                    selectedCheckoutMethod === CHECKOUT_METHOD.IN_STORE,
                    'Checkout at the register',
                    'Pay at the pickup counter in the Atelier',
                    isAtelierCheckoutDisabled
                )}
                {this.renderCheckbox(
                    CHECKOUT_METHOD.ONLINE,
                    selectedCheckoutMethod === CHECKOUT_METHOD.ONLINE,
                    'Checkout on chanel.com',
                    'Pay online and ship home',
                    isOnlineCheckoutDisabled
                )}
                <ProceedButton
                    type={'button'}
                    disabled={!selectedCheckoutMethod}
                    onClick={this.handleProceedToCheckout}
                >
                    Proceed to checkout
                </ProceedButton>
            </CheckoutMethodsWrapper>
        );
    };

    renderEmptyCart = () => {
        if (this.props.customEmptyComponent) {
            return this.props.customEmptyComponent;
        }

        return (
            <CartWrapper className={'cart--empty-wrapper'}>
                <p className={'cart--empty-title'}>Your cart is empty</p>
                <p className={'cart--empty-text'}>
                    Start shopping by adding items to your cart
                </p>
                <ProceedButton
                    type={'button'}
                    onClick={this.handleExploreProducts}
                >
                    Explore products
                </ProceedButton>
            </CartWrapper>
        );
    };

    render() {
        const {
            items,
            removeProductFromCart,
            authenticated,
            returnAfterLogin,
            replace,
            selectedCheckoutMethod,
            cartLocation,
            products,
            isFetching
        } = this.props;
        const {
            productToRemove,
            showRemoveConfirmationModal,
            showModal
        } = this.state;

        if (isFetching) {
            return (
                <CartWrapper loading>
                    <Loader />
                </CartWrapper>
            );
        }

        if (!isFetching && !products.length) {
            return this.renderEmptyCart();
        }

        return (
            <CartWrapper>
                <p className="cart--items-in-bag">
                    {items.length} item{items.length !== 1 ? 's' : ''} in your
                    bag
                </p>
                <div className="cart--subtotal">
                    <p>Subtotal</p>
                    <p>${this.renderSubtotal()}</p>
                </div>
                {this.renderProducts()}
                {this.renderCheckoutMethods()}
                <CartRemoveModal
                    productToRemove={productToRemove}
                    showRemoveConfirmationModal={showRemoveConfirmationModal}
                    removeProductFromCart={removeProductFromCart}
                    toggleRemoveProductModal={this.toggleRemoveProductModal}
                />
                <CartCheckoutModal
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                    authenticated={authenticated}
                    returnAfterLogin={returnAfterLogin}
                    replace={replace}
                    selectedCheckoutMethod={selectedCheckoutMethod}
                    cartLocation={cartLocation}
                    handleOnlineCheckout={this.handleOnlineCheckout}
                    products={products}
                    proceedToCheckoutPage={this.proceedToCheckoutPage}
                />
            </CartWrapper>
        );
    }
}

const mapStateToProps = ({
    elasticsearch,
    cart,
    user,
    router: {
        location: { pathname }
    }
}) => ({
    ...elasticsearch,
    ...user.auth,
    ...cart,
    subtotal: getSubtotal({
        products: cart.products,
        items: cart.items
    }),
    atelierSubtotal: getSubtotal({
        products: cart.products.filter(
            cart.cartLocation === LOCATIONS.AUSTIN
                ? p => p && p.availableInStore2
                : p => p && p.availableInStore
        ),
        items: cart.items
    }),
    onlineSubtotal: getSubtotal({
        products: cart.products.filter(p => p && p.availableOnline),
        items: cart.items
    }),
    cartBase64Hash: getCartExternalCheckoutBase64Hash(cart),
    onlineProducts: getOnlineProducts(cart),
    atelierProducts:
        cart.cartLocation === LOCATIONS.AUSTIN
            ? getAtelierProducts2(cart)
            : getAtelierProducts(cart),
    pathname
});

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            push,
            updateCartQuantity,
            removeProductFromCart,
            getCart,
            checkout,
            reset,
            analyticsTag,
            replace,
            returnAfterLogin,
            setSelectedCheckoutMethod,
            setCartLocation,
            resetOrder
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartSection);
