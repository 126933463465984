import styled from 'styled-components';
import PropTypes from 'prop-types';
import { boxStyle, styledBreakpointProp, styledProps } from 'core/styled';

const FULL_WIDTH = 100;

const defaultProps = {
    width: 'auto',
    flex: 'initial',
    gutter: null, // Padding to the sides
    padding: null,
    display: 'block',
    scrollable: false
};

const widthMapper = (value, { gutter }) => {
    if (value === 'auto') {
        return;
    } else if (value === 'initial') {
        value = 100;
    }
    const width = value * FULL_WIDTH;
    const flexBasis = `
        flex-basis: calc(${width}% - ${gutter} - ${gutter});
    `;

    return `
        ${width}%;
        ${gutter && flexBasis};
    `;
};

const Box = styled.div`
    ${boxStyle};
    // Gutter has to match padding to the sides
    padding-left: ${styledProps('gutter')};
    padding-right: ${styledProps('gutter')};
    ${styledBreakpointProp('flex', defaultProps)};
    ${styledBreakpointProp('width', defaultProps, widthMapper)};
    ${styledBreakpointProp('display', defaultProps)};
    ${styledBreakpointProp('overflowY', defaultProps)};
`;

Box.propTypes = {
    width: (props, propName, componentName) => {
        const width = props[propName];
        if (typeof width === 'object') {
            return;
        }
        if (typeof width === 'string') {
            if (!['auto', 'initial'].includes(width)) {
                return new TypeError(
                    `'${componentName}.${propName}' should be a 'auto' or 'initial'.`
                );
            }
            return;
        }
        if (typeof width === 'number') {
            if (width < 0 || width > 1) {
                return new TypeError(
                    `'${componentName}.${propName}' should be a number between 0 and 1.`
                );
            }
            return;
        }
        return new TypeError(
            `'${componentName}.${propName}' should be of type 'number', 'object' or 'string'.`
        );
    },
    flex: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    gutter: PropTypes.string,
    padding: PropTypes.string,
    display: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    scrollable: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

Box.defaultProps = defaultProps;

export default Box;
