import moment from 'moment-timezone';
import { getEventDatas, getLocationFromPrereq } from 'constants/events';
import { LOCATIONS, LOCATION_TEXT, LOCATION_TIMEZONE } from 'constants/locations';

let eventDatas;

export const normalizeEvent = (event, index, eventsArray) => {

    const { ClassDescription, ClassDescription: { Program: { ID: ProgramID, Name: ProgramName }, Description, Prereq },
        StartDateTime, EndDateTime, ID, IsWaitlistAvailable, MaxCapacity, TotalBooked, Price, Staff: { Name: staffName, ID: staffId } } = event;
    const startDateTime = moment.tz(StartDateTime, 'America/New_York');
    const endDateTime = moment.tz(EndDateTime, 'America/New_York');

    const { location, locationText } = getLocationFromPrereq(Prereq);
    const isAustin = locationText === LOCATION_TEXT[LOCATIONS.AUSTIN];

    const timezonedStartDateTime = moment(startDateTime).tz(isAustin ? LOCATION_TIMEZONE[LOCATIONS.AUSTIN] : LOCATION_TIMEZONE[LOCATIONS.NYC]);
    const timezonedEndDateTime = moment(endDateTime).tz(isAustin ? LOCATION_TIMEZONE[LOCATIONS.AUSTIN] : LOCATION_TIMEZONE[LOCATIONS.NYC]);

    const isLivestream = ProgramName.includes('Livestream');
    const isHappyHour = ProgramName.includes('Makeup Happy Hour') || ProgramName.includes('Special Happy Hour');

    if (!eventDatas || Object.keys(eventDatas).length === 0) {
        eventDatas = getEventDatas();
    }

    const eventData = eventDatas[ClassDescription.ID];

    const normalizedEventData = eventData ? {
        listingImage: eventData.mobile_listing_image ? eventData.mobile_listing_image.url : null,
        detailsImage: eventData.mobile_details_image ? eventData.mobile_details_image.url : null,
        detailsImageDesktop: eventData.desktop_details_image ? eventData.desktop_details_image.url : null,
        hideLandingName: eventData.hide_details_name_app,
        listingDescription: eventData.description_app,
        landingDescriptionTop: eventData.details_top_app,
        landingDescriptionBottom: eventData.details_bottom_app,
        artistDetailsTitle: eventData.artist_details_app && eventData.artist_details_app.length ? eventData.artist_details_app[0].text : null,
        artistDetailsContent: eventData.artist_details_app && eventData.artist_details_app.length ? eventData.artist_details_app.slice(1) : null,
        artistImage: eventData.profile_picture ? eventData.profile_picture.url : null,
        artistName: eventData.event_artist_name,
        artistSocialAccount: eventData.profile_link_text,
        artistSocialAccountUrl: eventData.profile_link_url,
        kitInfo: eventData.additional_information,
        listingGroup: eventData.listing_group,
        isMemberOnly: ['member_only'].includes(eventData.service_type),
        kitImage: eventData.note_image ? eventData.note_image.url : null,
        requireShipping: eventData.require_shipping_address,
        restrictNonContiguousStates: eventData.restrict_non_contiguous_states,
        eventDisclaimer: eventData.review_page_event_disclainer ? eventData.review_page_event_disclainer : eventData.event_disclaimer,
        showRedeemCode: !eventData.hide_redeem_code,
        questionnaireId: eventData.questionnaire_id,
        durationOffset: eventData.duration_offset
    } : {};

    // logic to get the id of the associated event if event is also available virtual
    const associatedEventData = {};

    if (eventsArray && eventsArray.length && !isLivestream && !isHappyHour) {
        const associatedEvent = eventsArray.find(
            ev =>
                ev &&
                ev.ClassDescription &&
                ev.ClassDescription.Name === ClassDescription.Name &&
                ev.StartDateTime === StartDateTime &&
                ev.Staff &&
                ev.Staff.ID === staffId &&
                ev.ID !== ID
        );

        if (
            associatedEvent &&
            eventDatas[associatedEvent.ClassDescription.ID]
        ) {
            associatedEventData.associatedEventId = associatedEvent.ID;
            associatedEventData.associatedEventListingGroup =
                eventDatas[associatedEvent.ClassDescription.ID].listing_group;
        }
    }

    return {
        id: ID,
        name: ClassDescription.Name?.replace(/-/g, '\u2011').replace(/N5/g, 'N°5').replace(/N1/g, 'N°1'),
        startDateTime: timezonedStartDateTime,
        endDateTime: timezonedEndDateTime,
        duration: timezonedEndDateTime.diff(timezonedStartDateTime, 'm'),
        weekDay: timezonedStartDateTime.format('dddd'),
        month: timezonedStartDateTime.format('MMMM'),
        day: timezonedStartDateTime.format('D'),
        startTime: timezonedStartDateTime.format('LT'),
        endTime: timezonedEndDateTime.format('LT'),
        programId: ProgramID,
        detailId: ClassDescription.ID,
        description: Description,
        isOnWaitlist: IsWaitlistAvailable && (MaxCapacity <= TotalBooked),
        availableSpots: MaxCapacity - TotalBooked,
        isPast: moment().isSameOrAfter(timezonedEndDateTime, 'minute'),
        hasStarted: moment().isSameOrAfter(timezonedStartDateTime, 'minute'),
        price: Price,
        staffName,
        staffId,
        isLivestream,
        isHappyHour,
        location,
        locationText,
        hidden: Prereq.includes('hidden=true'),
        memberOnly: Prereq.includes('member=true'),
        needsCustomerInfo: true,
        ...normalizedEventData,
        ...associatedEventData
    };

};

export const normalizeEventDetails = (event) => {

    const { ClassDescription: { Notes }, FreePasses, Pricing, TotalBookedByMe, OnWaitlist } = event;

    return {
        ...normalizeEvent(event),
        descriptionDetails: Notes,
        hasComplimentaryPass: FreePasses && FreePasses.length > 0,
        complimentaryPass: FreePasses && FreePasses.length > 0 ? FreePasses[0] : null,
        price: Pricing.length === 0 ? 0 : parseFloat(Pricing[0].Price, 10).toFixed(0),
        ticketBooked: TotalBookedByMe,
        onWaitlist: OnWaitlist,
        needsCustomerInfo: !Object.prototype.hasOwnProperty.call(event, 'TotalBookedByMe')  // event details contain customer info if logged in
    };
};

export const normalizeEventBooking = ({ event, transaction }) => {
    const { Pricing, giftedEvent } = event;

    return {
        ...normalizeEvent(event),
        price: Pricing.length === 0 ? 0 : parseFloat(Pricing[0].Price, 10).toFixed(0),
        giftedEvent,
        ...transaction
    };

};

export const normalizeMyEvent = (event) => {

    const { myTickets, Pricing } = event;
    const price = !Pricing || Pricing.length === 0 ? 0 : parseFloat(Pricing[0].Price, 10).toFixed(0);

    let transactionSummary = null;

    if (price > 0) {
        const paidTransactions = myTickets.map(t => t.transaction).filter(t => t.totalAmount);
        const numberOfPaidTickets = paidTransactions.reduce((acc, t) => acc + t.numberOfTickets, 0);
        const totalAmountPaid = paidTransactions.reduce((acc, t) => acc + parseFloat(t.totalAmount), 0);

        const transactions = myTickets.map((ticket) => {
            const { transaction: { requestID, numberOfTickets, freePass, unpaid, details } } = ticket;

            const eventUnitPrice = details ? parseFloat(details.item_0_unitPrice) : parseFloat(price);
            const taxAmount = details ? parseFloat(details.item_0_taxAmount) : 0;
            const transactionPrice = numberOfTickets * eventUnitPrice;
            const transactionPriceDisplay = freePass ? 'Free pass' : `$${transactionPrice}`;

            // this is a corner case: there is an unpaid ticket - show the amount later
            if (unpaid) {
                return null;
            }

            return { requestID, transactionPriceDisplay, transactionPrice: freePass ? 0 : transactionPrice, numberOfTickets, taxAmount };
        }).filter(t => t); // format transactions and filter nulls
        const eventTaxes = transactions.reduce((tax, t) => tax += t.taxAmount, 0);
        const eventSubtotal = transactions.reduce((subtotal, t) => subtotal += t.transactionPrice, 0);
        transactionSummary = {
            numberOfPaidTickets,
            totalAmountPaid,
            eventTaxes,
            eventSubtotal,
            transactions
        };
    }


    return {
        ...normalizeEvent(event),
        price,
        numberOfTickets: myTickets.map(t => t.visits).reduce((acc, v) => acc + v.length, 0),
        myTickets,
        transactionSummary,
        visitIds: myTickets.map(t => t.visits).reduce((acc, val) => acc.concat(val), []).map(v => v.VisitId)
    };

};
