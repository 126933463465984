import React, { Component } from 'react';
import Header from 'components/GlobalComponents/Fonts/Header';
import Button from 'components/GlobalComponents/Button/Button';
import PropTypes from 'prop-types';
import { styledProps, rem, greaterThan } from 'core/styled/index';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import Loader from 'components/GlobalComponents/Loader/Loader';
import ProductListing from 'components/ProductListing/ProductListing';
import SubHeader from 'components/GlobalComponents/Fonts/SubHeader';
import PrimaryModal from 'components/GlobalComponents/Modal/PrimaryModal';
import Text from 'components/GlobalComponents/Text/Text';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchWorkplanProducts,
    addSelectedProduct,
    removeSelectedProduct,
    addSubsectionNote,
    removeSubsectionNote,
    updateProductNote,
    updateProductBatchCode,
    removeProductBatchCode
} from 'reducers/beautyplan';
import BeautyplanProductCard from 'components/BeautyPlans/Staff/BeautyplanProductCard';

import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import ProductDropdown from 'components/BeautyPlans/Staff/ProductDropdown';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';
import { breakpoints } from 'constants/theme';
import { triggerAutocompleteSearch, resetAutocompleteResults } from 'reducers/productSearch';
import { BEAUTYPLAN_SEARCH_TYPE, NON_CHANEL, SECTION_ID, SECTION_ANALYTICS, BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS, BEAUTYPLAN_SKINCARE_TYPE } from 'constants/beautyplan';
import { setIsHeaderSearchOpen } from 'reducers/globalLayout';

const SubSectionHeaderOuter = styled(Flex)`
  width: 100%;
  padding-top: ${rem(20)};
`;

const SubSectionContainer = styled(Flex)`
  text-align: center;
  margin-top: ${({ first }) => first ? '0' : rem(40)};
`;

const StyledHeader = styled(Header)`
  padding: 0 ${rem(20, 15, 20)};
  position: relative;
  font-size: ${rem(32)};
  ${greaterThan(breakpoints.large)(css`
      font-size: ${rem(50)}; 
  `)}; 
`;

const ButtonWrapper = styled.div`
    text-align: center;
    margin: ${rem(50, 0)};
`;

const SearchAgainContainer = styled(Box)`
  text-align: center; 
  padding-top: ${rem(60)};
`;

const SearchAgainText = styled(Text)`
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    color: ${styledProps('color', 'black')};
`;

const StyledSearchAgainButton = styled(Button)`
  margin-top: ${rem(20)};
`;

const SearchButton = styled(Button)`
    min-width: 233px;
`;

const StyledPlusIcon = styled(Box)`
  margin-top: ${rem(20)};
`;

const AddProductButtonWrapper = styled(Flex)`
    width: 80%;
`;


const AutocompleteSuggestions = styled.div`    
    ul {
        li {
            display: block;
            padding: ${rem(10)};
            cursor: pointer;
            
            div {
                box-sizing: border-box;
            }
        }
    }
`;

const AutocompleteText = styled.div`
    text-transform: uppercase;
`;

const StyledHr = styled.hr`
    width: 84%;
    margin-top: ${({ isLast }) => isLast ? rem(40) : 0};
`;

const SubSectionNoteContainer = styled(Flex)`
    width: 100%;
`;

const SubSectionNoteHeader = styled.p`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(16)};
`;

const NotesContainer = styled(Box)`
    width: 80%;
    margin: auto;
    text-align: left;
`;

const StyledFormInput = styled(FormInput)`
    padding-bottom: ${rem(10)};
`;

const StyledNonChanelInput = styled(FormInput)`
    width: ${rem(120)} !important;
`;

class SubSection extends Component {

    static propTypes = {
        subSection: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        category: PropTypes.object.isRequired,
        addSelectedProduct: PropTypes.func.isRequired,
        removeSelectedProduct: PropTypes.func.isRequired,
        fetchWorkplanProducts: PropTypes.func.isRequired,
        addSubsectionNote: PropTypes.func.isRequired,
        removeSubsectionNote: PropTypes.func.isRequired,
        products: PropTypes.array.isRequired,
        productsInCategory: PropTypes.array.isRequired,
        subSectionNotes: PropTypes.array,
        isFetching: PropTypes.bool.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        currentPlan: PropTypes.object.isRequired,
        first: PropTypes.bool,
        last: PropTypes.bool,
        autocompleteResults: PropTypes.array.isRequired,
        triggerAutocompleteSearch: PropTypes.func.isRequired,
        resetAutocompleteResults: PropTypes.func.isRequired,
        setIsHeaderSearchOpen: PropTypes.func.isRequired,
        updateProductNote: PropTypes.func.isRequired,
        updateProductBatchCode: PropTypes.func.isRequired,
        removeProductBatchCode: PropTypes.func.isRequired
    };

    static defaultProps = {
        first: false
    };

    state = {
        nonChanelNote: ''
    };

    componentDidUpdate(prevProps, prevState) {
        // if search is cleared out, then reset autocomplete as well
        if (prevState.search !== this.state.search && this.state.search.trim().length === 0) {
            this.props.resetAutocompleteResults();
        }
    }

    getTimeOfDayFromCategoryId = categoryId => {
        let timeOfDay = null;

        if (categoryId === BEAUTYPLAN_SKINCARE_TYPE.AM) {
            timeOfDay = 'morning';
        } else if (categoryId === BEAUTYPLAN_SKINCARE_TYPE.PM) {
            timeOfDay = 'evening';
        }

        return timeOfDay;
    };

    analyticsAddRemoveProduct = (product, isAdd = false, categoryId) => {
        const { analyticsTag, currentPlan: { service }, subSection: { id: subSectionId }, id: sectionId } = this.props;
        let eventLabel = '';

        const action = isAdd ? 'add' : 'remove';
        if (sectionId === SECTION_ID.TRIAL) {
            eventLabel = `${action}-${product.sku}`;
        } else if (sectionId === SECTION_ID.MAKEUP) {
            eventLabel = `${subSectionId}-${action}-${product.sku}`;
        } else if (sectionId === SECTION_ID.SKINCARE) {
            const timeOfDay = this.getTimeOfDayFromCategoryId(categoryId);
            eventLabel = `${timeOfDay ? `${timeOfDay}-` : ''}${subSectionId}-${action}-${product.sku}`;
        }

        analyticsTag({
            event: EVENTS.GA,
            eventCategory: service.analyticsCategory,
            eventAction: SECTION_ANALYTICS[sectionId],
            eventLabel
        }, { userInfo: true });
    };


    constructor(props) {
        super(props);

        this.state = {
            showProductsModal: false,
            search: '',
            showProducts: false,
            showNonChanelProduct: this.productsInSection(true).length > 0
        };
    }

    /**
     * This function shows the products modal when search.
     * @return {void} shows the product search modal
     */
    showProductsModal = () => {
        this.props.resetAutocompleteResults();
        this.props.setIsHeaderSearchOpen(false);
        this.setState({ showProductsModal: true, search: '', showProducts: false });
    };

    /**
     * This function closes the products modal when you tap on X button at the top of the modal.
     *  @return {void} closes the products modal
     */
    closeProductsModal = () => {
        this.props.resetAutocompleteResults();
        this.setState({
            showProductsModal: false
        });
    };

    /**
     * Index or product in the store
     * @return {object} product index
     */
    getSectionIndex = () => {
        const { subSection: { id: sectionId }, category: { id: categoryId }, id } = this.props;
        return { id, sectionId, categoryId };
    };

    /**
     * Products added to current section
     * @param {boolean} nonChanel - if true, returns non chanel product in section, if false, filter them out
     * @return {array} products
     */
    productsInSection = (nonChanel = false) => {
        const index = this.getSectionIndex();
        return this.props.items.filter(i => i.id === index.id && i.sectionId === index.sectionId)
            .map(i => {
                return { ...i.product, note: i.note, batchCode: i.batchCode, ...{ categoryId: i.categoryId } };
            }).filter(p => nonChanel ? p.sku.startsWith(NON_CHANEL) : !p.sku.startsWith(NON_CHANEL));
    };

    /**
     * It handles the search input onChange and set the value.
     * @param {function} event - onChange input
     */
    handleInputChange = event => {
        const target = event.target;
        const value = (target.textContent || target.innerText || '').trim();

        // trigger autocomplete search when more than 2 characters entered
        if (value.length > 2) {
            this.props.triggerAutocompleteSearch(value);
        }

        this.setState({
            search: value
        });
    };

    /**
     * Handle user search clear button Tap
     */
    handleInputReset = () => {
        this.setState({
            search: '',
        });
    };

    /**
     * It fetches and shows the products.
     * @param {string} search
     */
    handleSubmit = (search) => {
        if (this.state.search.length <= 0) {
            return;
        }
        this.props.fetchWorkplanProducts(search, this.props.subSection.isFragranceFlight);
        this.setState({
            showProducts: true,
            search
        });
    };

    /**
     * It renders the product modal.
     * @returns {Component} Products search modal
     */
    renderProductsModal = () => {
        const { search, showProducts } = this.state;
        if (!this.state.showProductsModal) {
            return null;
        }

        return (
            <PrimaryModal onRequestClose={this.closeProductsModal}
                showClose={true}
                flexBody={false}
                fullScreen={true}
                preventScrollToTopOnOpen={true}>
                <Flex flexDirection='column' justifyContent='center'>
                    {this.handleShowProducts(search, showProducts)}
                </Flex>
            </PrimaryModal>
        );
    };

    /**
     * If you search products it will render them instead of render the input search.
     * @param {string} search
     * @param {boolean} showProducts
     * @returns {Component} Search input or products
     */
    handleShowProducts(search, showProducts) {
        const { autocompleteResults } = this.props;

        if (showProducts) {
            return this.renderContent();
        } else {
            return (<Flex flexDirection="column" justifyContent={'center'} paddingTop={rem(40)}>
                <Flex flexDirection="column">
                    <form>
                        <FormInput
                            id="search"
                            label="Search, type, find your beauty weapons!"
                            inputfont={'ABChanelPBL'}
                            inputfontsize={33}
                            labelFont={'SofiaPro'}
                            labelFontSize={14}
                            clearOption={true}
                            maxLength={200}
                            multiLine
                            onSubmit={() => this.handleSubmit(search)}
                            onChange={this.handleInputChange}
                            onClear={this.handleInputReset}
                            value={search}
                        />
                    </form>
                    {autocompleteResults.length > 0 && <AutocompleteSuggestions>
                        <ul>
                            {
                                autocompleteResults.map((result, index) => {
                                    return (
                                        <li key={index} onClick={() => this.handleSubmit(result)}>
                                            <AutocompleteText>
                                                <strong>{result.substring(0, search.length)}</strong>{result.substring(search.length)}
                                            </AutocompleteText>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </AutocompleteSuggestions>
                    }

                    <ButtonWrapper>
                        <SearchButton
                            type='submit'
                            variant={'primary'}
                            disabled={!search.length}
                            onClick={() => this.handleSubmit(search)}
                        > SEARCH </SearchButton>
                    </ButtonWrapper>
                </Flex>
            </Flex>);
        }
    }

    /**
     * Add product to the selectedProducts
     * Products can now be added/removed across sections, so need an override
     * @param {Object} product - selected product
     * @param {object} sectionIndexOverride
     */
    addProduct = (product, sectionIndexOverride) => {
        const sectionIndex = sectionIndexOverride ? sectionIndexOverride : this.getSectionIndex();
        this.props.addSelectedProduct({ product, note: product.note, ...sectionIndex });

        this.analyticsAddRemoveProduct(product, true, sectionIndex.categoryId);
        this.setState({
            showProductsModal: false
        });
    };

    /**
     * Remove product
     * Products can now be added/removed across sections, so need an override
     * @param {object} product
     * @param {object} sectionIndexOverride
     */
    removeProduct = (product, sectionIndexOverride) => {
        const sectionIndex = sectionIndexOverride ? sectionIndexOverride : this.getSectionIndex();

        const { removeSelectedProduct } = this.props;

        this.analyticsAddRemoveProduct(product, false, sectionIndex.categoryId);
        removeSelectedProduct({ product, note: product.note, ...sectionIndex });
    };

    /**
     * Render the search result in the modal. Sets the subsectionId to the search result.
     * @return {component} Search result
     */
    renderContent() {
        const { products, isFetching, subSection } = this.props;

        if (isFetching) {
            return <Loader/>;
        }

        return (
            <Flex flexDirection="column">
                <Header>“{this.state.search}”</Header>
                <SubHeader>Results ({products.length})</SubHeader>
                <ProductListing
                    renderLikeListButtons={false}
                    products={products}
                    withShades={false}
                    onProductSelect={this.addProduct}
                    overridePDPRedirect={true}
                    backToTop={false}
                    isFragranceFlight={!!subSection.isFragranceFlight}
                    mediumColumnWidth={'50%'}
                    largeColumnWidth={'50%'}
                    giantColumnWidth={'50%'}
                />

                <SearchAgainContainer>
                    <SearchAgainText> Didn’t find what you were looking for? </SearchAgainText>
                    <StyledSearchAgainButton variant={'primary'}
                        onClick={() => this.setState({ showProducts: false, search: '' })}>Search
                        Again</StyledSearchAgainButton>
                </SearchAgainContainer>
            </Flex>);
    }

    /**
     * Render producst for given section
     * @returns {Component} - Beauty plan product card
     */
    renderSelectedProducts() {
        const { currentPlan, items, id, subSection, updateProductNote, updateProductBatchCode, removeProductBatchCode } = this.props;

        const currentPlanTrialKit = currentPlan.service.steps[0];
        const planHasTrialKit = currentPlanTrialKit === BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5;
        const trialKitProductLimit = planHasTrialKit ? BEAUTYPLAN_CONFIG[currentPlanTrialKit].section.limit : 0;
        const trialKitProducts = items.filter(item => item.id === SECTION_ID.TRIAL).map(section => section.product);

        return (
            <BeautyplanProductCard
                products={this.productsInSection()}
                nonChanelProducts={this.productsInSection(true)}
                showNonChanelProduct={this.state.showNonChanelProduct}
                onProductRemove={this.removeProduct}
                onProductAdd={this.addProduct}
                trialKitProducts={trialKitProducts}
                trialKitLimit={trialKitProductLimit}
                categoryId={id}
                sectionIndex={this.getSectionIndex()}
                allowNonChanelNote={subSection.allowNonChanelNote}
                productNote={subSection.productNote}
                updateProductNote={updateProductNote}
                updateProductBatchCode={updateProductBatchCode}
                removeProductBatchCode={removeProductBatchCode}
                isFragranceFlight={subSection.isFragranceFlight}
            />
        );
    }

    renderProductPicker = () => {
        const { type, limit } = this.props.subSection;

        if (type === BEAUTYPLAN_SEARCH_TYPE.SEARCH || type === BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE) {

            // do not display the + if limit has been reached
            if (limit && this.productsInSection().length === limit) {
                return null;
            }

            return (
                <div>
                    <StyledPlusIcon>
                        <Icon type={ICON_TYPES.PLUS} pixelHeight={32} onClick={() => this.showProductsModal()}/>
                        <ChromosomeTitle fontSize={12} display={'block'}>
                            add a new<br/>product
                        </ChromosomeTitle>
                    </StyledPlusIcon>
                    {this.renderProductsModal()}
                </div>
            );
        } else if (type === BEAUTYPLAN_SEARCH_TYPE.LIST) {
            const { productsInCategory } = this.props;
            return (
                <div>
                    <ProductDropdown addProduct={this.addProduct} products={productsInCategory}/>
                </div>
            );
        }

        return null;

    };

    renderNonChanelProductCheckbox = () => {
        return (

            <StyledPlusIcon>
                <Icon type={ICON_TYPES.PLUS} pixelHeight={32} onClick={() => this.toggleShowNonChanelProduct()}/>
                <ChromosomeTitle fontSize={12} display={'block'}>
                    add a non<br/>chanel product
                </ChromosomeTitle>
            </StyledPlusIcon>
        );
    };

    renderNonChanelProductNote = () => {
        const { nonChanelNote } = this.state;
        const { subSection: { title } } = this.props;

        return (
            <StyledPlusIcon>
                <Icon type={ICON_TYPES.PLUS} pixelHeight={32} onClick={() => {
                    this.addShowNonChanelProduct(nonChanelNote);
                    this.setState({ nonChanelNote: '' });
                }}/>
                <ChromosomeTitle fontSize={12} display={'block'}>
                    add a non<br/>chanel product
                </ChromosomeTitle>
                <StyledNonChanelInput
                    id={`non-chanel-input_${title}`}
                    placeholder={'Product'}
                    value={nonChanelNote}
                    onChange={e => {
                        this.setState({ nonChanelNote: e.target.value });
                    }}/>
            </StyledPlusIcon>
        );
    };

    addShowNonChanelProduct = note => {
        const { addSelectedProduct } = this.props;

        if (note) {
            const customSku = `${NON_CHANEL}_${note.replace(/\s/g, '')}`;

            addSelectedProduct({
                product: { sku: customSku },
                note,
                ...this.getSectionIndex()
            });
            this.analyticsAddRemoveProduct({ sku: 'nonchanel' }, true);
        }
    };

    toggleShowNonChanelProduct() {
        const { removeSelectedProduct, addSelectedProduct } = this.props;
        // remove non-chanel product
        if (this.state.showNonChanelProduct) {
            removeSelectedProduct({ product: { sku: NON_CHANEL }, ...this.getSectionIndex() });
            this.analyticsAddRemoveProduct({ sku: 'nonchanel' });
        } else { // add non-chanel product
            addSelectedProduct({ product: { sku: NON_CHANEL }, ...this.getSectionIndex() });
            this.analyticsAddRemoveProduct({ sku: 'nonchanel' }, true);
        }

        this.setState({
            showNonChanelProduct: !this.state.showNonChanelProduct
        });
    }

    noteOnBlur = (e) => {
        const note = e.target.innerHTML;

        if (note) {
            this.props.addSubsectionNote({ note: note, ...this.getSectionIndex() });
        } else {
            this.noteOnClear();
        }
    };

    noteOnClear = () => {
        this.props.removeSubsectionNote(this.getSectionIndex());
    };

    renderSubSectionNote = () => {
        const { subSection: { title }, subSectionNotes } = this.props;

        const savedValue = subSectionNotes.find(n => n.id === this.getSectionIndex().id && n.categoryId === this.getSectionIndex().categoryId && n.sectionId === this.getSectionIndex().sectionId);
        const value = savedValue ? savedValue.note : '';

        return (
            <SubSectionNoteContainer flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <SubSectionNoteHeader>Category Notes</SubSectionNoteHeader>
                <NotesContainer>
                    <StyledFormInput
                        id={`notes_${title}`}
                        placeholder='Type any additional notes'
                        inputfontsize={14}
                        clearOption={true}
                        label='notes'
                        onBlur={this.noteOnBlur}
                        onClear={this.noteOnClear}
                        value={value}
                        multiLine
                        hideLabelOnFocus
                    />
                </NotesContainer>
            </SubSectionNoteContainer>
        );
    };

    render() {
        const { id, subSection: { title, allowNonChanel, allowNonChanelNote, type }, first, last } = this.props;

        return (
            <SubSectionContainer first={first} flexDirection='column' justifyContent='center' alignItems='center'>
                {!first && id !== SECTION_ID.TRIAL && <StyledHr/>}
                {title &&
                <SubSectionHeaderOuter
                    justifyContent='center'
                    alignItems='center'
                >
                    <StyledHeader as={'h3'}>
                        {title}
                    </StyledHeader>
                </SubSectionHeaderOuter>
                }
                {type === BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE && this.renderSubSectionNote()}
                {this.renderSelectedProducts()}
                <AddProductButtonWrapper justifyContent={'space-around'}>
                    {this.renderProductPicker()}
                    {allowNonChanel && !allowNonChanelNote && this.renderNonChanelProductCheckbox()}
                    {allowNonChanel && allowNonChanelNote && this.renderNonChanelProductNote()}
                </AddProductButtonWrapper>
                {last && id !== SECTION_ID.TRIAL && <StyledHr isLast={last}/>}
            </SubSectionContainer>
        );
    }
}

const mapStateToProps = ({ beautyplan: { category: id,  subSection: category }, beautyplan, productSearch: { autocompleteResults } }) => ({
    ...beautyplan,
    productsInCategory: beautyplan.workplanProducts.filter(p => p[id] && p[id] === category),
    autocompleteResults
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchWorkplanProducts,
            addSelectedProduct,
            removeSelectedProduct,
            analyticsTag,
            triggerAutocompleteSearch,
            resetAutocompleteResults,
            setIsHeaderSearchOpen,
            addSubsectionNote,
            removeSubsectionNote,
            updateProductNote,
            updateProductBatchCode,
            removeProductBatchCode
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubSection);
