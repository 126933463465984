import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import { styledProps, rem, boxStyle } from 'core/styled';
import Loader from 'components/GlobalComponents/Loader/Loader';
import Clickable from 'components/GlobalComponents/Clickable/Clickable';
import { pseudoLink } from './pseudoLink.styled';
import { pseudoLinkInverted } from './pseudoLinkInverted.styled';
import {
    backgroundColor,
    color,
    width,
    cursor,
    borderColor,
    iconInvertFilter
} from './Button.styled';

const StyledButton = styled.button`
    ${boxStyle};
    font-family: ${({ font, small }) => font ? styledProps('font', font) : styledProps('font', small ? 'ABChanelPBM' : 'ABChanelPBL')};
    text-transform: uppercase;
    background: ${backgroundColor};
    color: ${({ textColor }) => textColor ? styledProps('color', textColor) : color};
    width: ${width};
    cursor: ${cursor};
    border: ${({ small }) => rem(small ? 1 : 2)} solid ${({ textColor }) => textColor ? styledProps('color', textColor) : borderColor};
    height: ${({ small }) => rem(small ? 30 : 48)};
    padding: 0 ${({ small }) => rem(small ? 15 : 20)};
    transition: 0.2s color ease-in-out, 0.2s background ease-in-out,
        0.2s border-color ease-in-out;
    max-width: 350px;
    ${pseudoLink};
    ${pseudoLinkInverted};
    font-size: ${({ fontSize, small }) => rem(small ? 8 : fontSize)};
    ${({ small }) => small ? 'font-weight: bold;' : null}
    
    ${greaterThan(breakpoints.small)(css`
        font-size: ${({ mediumScreensFontSize, fontSize, small }) => small ? rem(8) : mediumScreensFontSize ? rem(mediumScreensFontSize) : rem(fontSize)}
    `)}

    .atelier-button-icon {
        filter: invert(${iconInvertFilter}%);
        transition: .2s filter ease-in-out;
    }
`;

export default class Button extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        onClick: PropTypes.func,
        type: PropTypes.string,
        variant: PropTypes.oneOf(['primary', 'primaryInverted', 'pseudoLink', 'pseudoLinkInverted', 'simpleGrey', 'simpleBlack', 'warmGrey', 'primaryWhite', 'primaryRed', 'secondaryRed', 'underlineSelected']),
        block: PropTypes.bool,
        beforeIcon: PropTypes.bool,
        afterIcon: PropTypes.bool,
        fontSize: PropTypes.number,
        mediumScreensFontSize: PropTypes.number,
        textColor: PropTypes.string,
        small: PropTypes.bool
    };

    static defaultProps = {
        type: 'button',
        variant: 'primary',
        block: false,
        fontSize: 12,
        mediumScreensFontSize: 12,
        small: false
    };

    render() {
        const { fontSize, variant, mediumScreensFontSize, textColor, small } = this.props;
        const useDarkLoader = ['primary', 'pseudoLink', 'simpleGrey', 'warmGrey', 'underlineSelected'].includes(variant);

        return (
            <Clickable {...this.props}>
                {({ loading, ...clickableProps }) => (
                    <StyledButton fontSize={fontSize} mediumScreensFontSize={mediumScreensFontSize} {...clickableProps} textColor={textColor} small={small}>
                        {loading ? (
                            <Loader forButton dark={useDarkLoader}/>
                        ) : (
                            this.props.children
                        )}
                    </StyledButton>
                )}
            </Clickable>
        );
    }
}
