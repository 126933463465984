import { createAction } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { CONFIG, SESSION_ID_LOCALSTORAGE_KEY } from 'constants/config';
import { FETCH } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';
import { postAuthCheckin } from 'reducers/checkIn';
import { analyticsTag, getAnalyticsInfo } from 'reducers/analytics';
import { navigateAfterLogin } from 'reducers/user';
import { EVENTS } from 'constants/analytics';
import { push } from 'redux-first-history';
import { ROUTES } from 'constants/routes';
import { fetchAtelier } from 'reducers/prismicFetch';
import { PRISMIC_PAGE_IDS } from 'constants/prismic';
import { transferAnonymousInteractionsToUser } from './recommendations';
import { NEED_CODE_STATUS } from 'constants/auth';
import { resetMemberHub } from './memberHub';

export const LOGIN = 'LOGIN';
export const REQUEST_CODE = 'REQUEST_CODE';

export const loginUser = createAction(FETCH, user => ({
    prefix: LOGIN,
    endpoint: `${CONFIG.API_URL}/auth/login2`,
    options: {
        method: 'POST',
        body: JSON.stringify(user)
    }
}));

export const loginUserWithCode = createAction(FETCH, user => ({
    prefix: LOGIN,
    endpoint: `${CONFIG.API_URL}/auth/otplogin`,
    options: {
        method: 'POST',
        body: JSON.stringify(user)
    }
}));

export const requestNewCode = createAction(FETCH, user => ({
    prefix: REQUEST_CODE,
    endpoint: `${CONFIG.API_URL}/auth/sendcode`,
    options: {
        method: 'POST',
        body: JSON.stringify(user)
    }
}));

export const refreshSession = createAction(FETCH, () => ({
    prefix: LOGIN,
    endpoint: `${CONFIG.API_URL}/auth/session`,
    options: {
        method: 'PUT'
    }
}));

const handleLoginSuccess = (dispatch, response) => {
    const {
        profile: { needsVerification, isStaff },
        isFirstTime
    } = response;

    dispatch(
        analyticsTag(
            {
                event: EVENTS.GA,
                eventAction: 'login',
                eventCategory: 'profile',
                eventLabel: 'completed'
            },
            { userInfo: true }
        )
    );

    // on login, fetch all necessary Atelier data
    // TODO: this could be further broken out to fetch only needed data per page while browsing, however performance isn't an issue at this time so not needed
    dispatch(fetchAtelier(PRISMIC_PAGE_IDS.ATELIER_SOHO));

    // reset hub
    dispatch(resetMemberHub());

    // Handle navigation to correct profile state after login
    if (needsVerification) {
        dispatch(push(ROUTES.CONFIRM_PROFILE));
    } else if (isStaff) {
        dispatch(push(ROUTES.STAFF_LOGIN));
    } else {
        // navigate to profile unless user tried to go somewhere else before login
        dispatch(
            navigateAfterLogin(
                isFirstTime ? ROUTES.HOUSE_RULES : ROUTES.PROFILE_WELCOME_BACK
            )
        );
    }

    dispatch(getAnalyticsInfo());
    dispatch(transferAnonymousInteractionsToUser());
    return dispatch(postAuthCheckin());
};

const handleLoginError = (dispatch, error) => {
    if (error.status === 401) {
        dispatch(
            analyticsTag(
                {
                    event: EVENTS.GA,
                    eventCategory: 'profile',
                    eventAction: 'login',
                    eventLabel: 'error-authentication'
                },
                { userInfo: true }
            )
        );
    }

    dispatch(
        analyticsTag(
            {
                event: EVENTS.GA,
                eventCategory: 'login',
                eventAction: 'profile',
                eventLabel: 'error'
            },
            { userInfo: true }
        )
    );
    throw error;
};

export const login = ({ username, password }) => {
    return (dispatch, getState) => {
        const {
            user: {
                auth: {
                    authenticated,
                    profile: { cartId }
                }
            }
        } = getState();

        let deviceId = localStorage.getItem(SESSION_ID_LOCALSTORAGE_KEY);

        if (!deviceId) {
            deviceId = uuidv4();
            window.localStorage.setItem(SESSION_ID_LOCALSTORAGE_KEY, deviceId);
        }

        const loginRequest = { username, password, deviceId };

        // send guestCartId so it can be merged after login
        if (!authenticated && cartId) {
            loginRequest.guestCartId = cartId;
        }

        return dispatch(loginUser(loginRequest))
            .then(response => {
                if (response.status === NEED_CODE_STATUS) {
                    return Promise.resolve({
                        needsCode: true,
                        vToken: response.vToken
                    });
                } else {
                    return handleLoginSuccess(dispatch, response);
                }
            })
            .catch(error => {
                handleLoginError(dispatch, error);
            });
    };
};

export const validateCodeAndLogin = ({ code, vToken }) => (
    dispatch,
    getState
) => {
    const {
        user: {
            auth: {
                authenticated,
                profile: { cartId }
            }
        }
    } = getState();

    let deviceId = localStorage.getItem(SESSION_ID_LOCALSTORAGE_KEY);

    if (!deviceId) {
        deviceId = uuidv4();
        window.localStorage.setItem(SESSION_ID_LOCALSTORAGE_KEY, deviceId);
    }

    const loginRequest = { code, vToken, deviceId };

    // send guestCartId so it can be merged after login
    if (!authenticated && cartId) {
        loginRequest.guestCartId = cartId;
    }

    return dispatch(loginUserWithCode(loginRequest))
        .then(response => {
            return handleLoginSuccess(dispatch, response);
        })
        .catch(error => {
            return handleLoginError(dispatch, error);
        });
};

export const requestCode = ({ username }) => {
    return dispatch => {
        return dispatch(requestNewCode({ username }));
    };
};

export default createFetchReducer(LOGIN);
