import { FETCH, FETCH_SUCCESS, FETCH_PENDING, FETCH_ERROR } from 'middlewares/fetch';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { createAction } from 'redux-actions';
import { CONFIG } from 'constants/config';
import createFetchReducer from 'reducers/createFetchReducer';
import { push } from 'redux-first-history';
import { LOGIN } from 'reducers/login';
import { REGISTRATION } from 'reducers/registration';
import { ROUTES } from 'constants/routes';
import { getCart, resetCart } from 'reducers/cart';
import { fetchUserLists, resetLists } from 'reducers/userList';
import { resetMyAppointments } from 'reducers/profileModules/myAppointments';
import { LOCATIONS } from 'constants/locations';
import { NEED_CODE_STATUS } from 'constants/auth';
import { resetMemberHub } from './memberHub';

export const PROFILE = 'PROFILE';
const LOGOUT = 'LOGOUT';
const RETURN_AFTER_LOGIN = 'RETURN_AFTER_LOGIN';
const CLEAR_RETURN_URL = 'CLEAR_RETURN_URL';
const STAFF = 'STAFF';
const IMPERSONATE_START = 'IMPERSONATE_START';
const IMPERSONATE_END = 'IMPERSONATE_END';
const UPDATE_VIP = 'UPDATE_VIP';
const GET_VIP = 'GET_VIP';
const UPDATE_FREELANCE = 'UPDATE_FREELANCE';
const SAVE_ENVELOPE = 'SAVE_ENVELOPE';
const SET_ATTENDED_APPOINTMENTS = 'SET_ATTENDED_APPOINTMENTS';
const SET_ATTENDED_EVENTS = 'SET_ATTENDED_EVENTS';
const SET_CHECKIN_TIMES = 'SET_CHECKIN_TIMES';
const SET_GUEST_FRAGRANCE = 'SET_GUEST_FRAGRANCE';
const SET_CART_ID = 'SET_CART_ID';
const SET_CART_EXPIRATION = 'SET_CART_EXPIRATION';
const SET_STAFF_LOCATION = 'SET_STAFF_LOCATION';
const FETCH_PROFILE_PICTURE = 'FETCH_PROFILE_PICTURE';
const SET_EMPLOYEE_STATUS = 'SET_EMPLOYEE_STATUS';

export const clearReturnUrl = createAction(CLEAR_RETURN_URL);
export const returnAfterLogin = createAction(RETURN_AFTER_LOGIN);
export const setLoginEmail = createAction('SET_LOGIN_EMAIL');
export const setAttendedAppointments = createAction(SET_ATTENDED_APPOINTMENTS);
export const setAttendedEvents = createAction(SET_ATTENDED_EVENTS);
export const setCheckinTimes = createAction(SET_CHECKIN_TIMES);
export const setGuestFragrance = createAction(SET_GUEST_FRAGRANCE);
export const setCartId = createAction(SET_CART_ID);
export const setCartExpiration = createAction(SET_CART_EXPIRATION); // for non-logged guests
export const setStaffLocation = createAction(SET_STAFF_LOCATION);

const initialState = {
    authenticated: false,
    isStaff: false,
    staffLocation: LOCATIONS.NYC,
    returnUrl: null,
    isFirstTime: false,
    isImpersonating: false,
    impersonateFetching: false,
    fetchingProfile: false,
    neverCheckedIn: true,
    profile: {},
    loginEmail: '',
    isFreelancer: false,
    attendedAppointments: 0,
    attendedEvents: 0,
    checkinTimes: 0,
    savingEnvelope: false,
    profilePicture: null
};

const impersonateUser = createAction(
    FETCH,
    (id) => ({
        prefix: IMPERSONATE_START,
        endpoint: `${CONFIG.API_URL}/auth/session/${id}`,
        options: {
            method: 'POST'
        }
    })
);

const stopImpersonation = createAction(
    FETCH,
    (id) => ({
        prefix: IMPERSONATE_END,
        endpoint: `${CONFIG.API_URL}/auth/session/${id}`,
        options: {
            method: 'DELETE'
        }
    })
);

const setVipStatus = createAction(
    FETCH,
    (id, request) => ({
        prefix: UPDATE_VIP,
        endpoint: `${CONFIG.API_URL}/profiles/${id}/vip`,
        options: {
            method: 'PUT',
            body: JSON.stringify(request)
        }
    })
);

export const getVipStatus = createAction(
    FETCH,
    () => ({
        prefix: GET_VIP,
        endpoint: `${CONFIG.API_URL}/profiles/vip`,
        options: {
            method: 'GET'
        }
    })
);

const setEnvelopeStatus = createAction(
    FETCH,
    request => ({
        prefix: SAVE_ENVELOPE,
        endpoint: `${CONFIG.API_URL}/profiles/envelope`,
        options: {
            method: 'POST',
            body: JSON.stringify(request)
        }
    })
);

export const setEmployeeStatus = createAction(FETCH, (profileId, isEmployee) => ({
    prefix: SET_EMPLOYEE_STATUS,
    endpoint: `${CONFIG.API_URL}/profiles/${profileId}/employee`,
    options: {
        method: 'POST',
        body: JSON.stringify({ isEmployee })
    }
}));

export const logoutUser = (returnUrl) => {
    return (dispatch, getState) => {
        //clear session data
        const { user: { auth: { authenticated, isStaff, isFreelancer } } } = getState();
        localStorage.removeItem('persist:user');
        if (isStaff && !isFreelancer) {
            window.location = `${CONFIG.API_URL}/auth/saml/logout`;
        } else {
            if (authenticated) {
                dispatch({ type: LOGOUT, payload: returnUrl });
            }
        }
        dispatch(resetMemberHub());
        dispatch(resetLists());
    };
};

export const navigateAfterLogin = (route) => {
    return (dispatch, getState) => {
        const { user: { auth: { returnUrl } } } = getState();
        if (returnUrl) {
            dispatch(clearReturnUrl());
            dispatch(push(returnUrl));
        } else {
            dispatch(push(route));
        }
    };
};

export const updateProfile = createAction(
    FETCH,
    (id, profile) => ({
        prefix: PROFILE,
        endpoint: `${CONFIG.API_URL}/profiles/${id}`,
        options: {
            method: 'PUT',
            body: JSON.stringify(profile)
        }
    })
);

const getProfile = createAction(
    FETCH,
    (id) => ({
        prefix: PROFILE,
        endpoint: `${CONFIG.API_URL}/profiles/${id}`,
        options: {
            method: 'GET'
        }
    })
);

const updateFreelance = createAction(
    FETCH,
    (externalId, request) => ({
        prefix: UPDATE_FREELANCE,
        endpoint: `${CONFIG.API_URL}/profiles/${externalId}/staff`,
        options: {
            method: 'PUT',
            body: JSON.stringify(request)
        }
    })
);

export const getProfilePicture = createAction(
    FETCH,
    () => ({
        prefix: FETCH_PROFILE_PICTURE,
        endpoint: `${CONFIG.API_URL}/files/profile/downloadImage`,
        options: {
            method: 'GET',
            blob: true
        }
    })
);

/**
 * Used to refresh the user's profile information
 * @return {Function} Thunk
 */
export const fetchProfile = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { isStaff, isImpersonating, id } } } } = getState();
        if (isStaff && !isImpersonating) {
            return null;
        } // shouldn't happen; there's no reason for this to happen
        return dispatch(getProfile(id));
    };
};

/**
 * Checks the local storage state for session data set via
 * Single-Sign-On.
 *
 * @returns {object} userData
 */
const fetchLocalStorageState = () => {
    const state = { ...initialState };

    const sessionData = JSON.parse(localStorage.getItem('persist:user'));
    if (sessionData && sessionData.auth && sessionData.auth.profile) {
        state.profile = sessionData.auth.profile;
        state.authenticated = sessionData.auth.authenticated;
    }

    return state;
};

export const checkStaffAuth = createAction(
    FETCH,
    () => ({
        prefix: STAFF,
        endpoint: `${CONFIG.API_URL}/auth/saml/check`,
        options: {
            method: 'GET'
        }
    })
);

export const impersonate = (userId) => {
    return (dispatch, getState) => {
        const { router: { location: { pathname } } } = getState();

        return dispatch(impersonateUser(userId)).then(() => {
            dispatch(resetMyAppointments());
            if (pathname === ROUTES.PROFILE) {
                dispatch(push(ROUTES.PROFILE));
            }
            dispatch(getCart());
            dispatch(fetchUserLists());
        });
    };
};

/**
 * TODO: Call this function with proper userId
 * @param {string} userId
 * @returns {function(*, *): *} promise
 */
export const endImpersonation = (userId) => {
    return (dispatch) => {
        return dispatch(stopImpersonation(userId)).then(() => {
            dispatch(push(ROUTES.PROFILE));
            dispatch(resetLists()); // reset list data
            dispatch(resetCart()); // and reset cart
        });
    };
};

export const updateVipStatus = (id, memberStatus) => {
    return (dispatch) => {
        const now = new Date(Date.now());
        const request = {
            status: memberStatus,
            start: now.getTime(),
            // end: new Date().setMonth(new Date().getMonth() + 3)
            end: Math.max(now.setMonth(now.getMonth + 6), 1717300799000) // end of day JUN 1st 2024
        };

        return dispatch(setVipStatus(id, request));
    };
};

export const updateFreelancerStatus = (externalId, freelancerStatus) => {
    return (dispatch) => {
        const request = {
            isStaff: freelancerStatus,
            staffRole: freelancerStatus ? 'Freelancer' : null
        };

        return dispatch(updateFreelance(externalId, request));
    };
};

export const saveEnvelopeDetails = (profileId, envelopeDate, envelopeNumber) => {
    return (dispatch) => {
        const request = {
            profileId: profileId,
            date: envelopeDate,
            number: envelopeNumber
        };

        return dispatch(setEnvelopeStatus(request));
    };
};

/**
 * Computes the account type based on wether the user was created in gigya and onehope
 * @param {boolean} gigya - created in gigya
 * @param {boolean} onehope - created in onehope
 * @return {string} accountType
 */
const getAccountType = (gigya, onehope) => {

    // created in gigya
    if (gigya) {
        return onehope ? 'newChanel' : 'newOneHopeNonAssociated';
    }

    // existing in gigya
    return onehope ? 'newOneHopeAssociated' : 'existingClient';
};

export default combineReducers({
    fetch: createFetchReducer(PROFILE),
    auth: handleActions(
        {
            [`${LOGIN}/${FETCH_SUCCESS}`]: (
                state,
                { payload }
            ) => {

                if (payload.status === NEED_CODE_STATUS) {
                    return state;
                }

                const { profile, created: { onehope, gigya } } = payload;

                return {
                    ...state,
                    profile: { ...profile },
                    neverCheckedIn: !profile.lastCheckin,
                    authenticated: true,
                    isStaff: false,
                    isFirstTime: onehope,
                    accountType: getAccountType(gigya, onehope)
                };
            },
            [`${REGISTRATION}/${FETCH_SUCCESS}`]: (
                state,
                { payload }
            ) => {

                if (payload.status === NEED_CODE_STATUS) {
                    return state;
                }

                const { profile, created: { onehope, gigya } } = payload;

                return {
                    ...state,
                    profile: { ...profile },
                    neverCheckedIn: !profile.lastCheckin,
                    authenticated: true,
                    isStaff: false,
                    isFirstTime: onehope,
                    accountType: getAccountType(gigya, onehope)
                };
            },
            [`${PROFILE}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
                ...state,
                profile: payload,
                neverCheckedIn: !payload.lastCheckin,
                authenticated: true
            }),
            [LOGOUT]: (state, { payload }) => ({
                ...state,
                profile: {},
                authenticated: false,
                isImpersonating: false,
                isStaff: false,
                isFreelancer: false,
                returnUrl: payload,
                attendedAppointments: 0,
                attendedEvents: 0,
                checkinTimes: 0,
                profilePicture: null
            }),
            [returnAfterLogin]: (state, { payload }) => ({
                ...state,
                returnUrl: payload
            }),
            [clearReturnUrl]: (state) => ({
                ...state,
                returnUrl: null
            }),[`${STAFF}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
                ...state,
                authenticated: payload.authenticated,
                isStaff: payload.authenticated,
                profile: payload.authenticated ? { ...payload } : {},
                isFreelancer: payload.staffRole === 'Freelancer'
            }),
            [`${IMPERSONATE_START}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
                ...state,
                staffProfile: state.profile,
                profile: payload.profile,
                isImpersonating: true,
                impersonateFetching: false
            }),
            [`${IMPERSONATE_START}/${FETCH_PENDING}`]: (state) => ({
                ...state,
                impersonateFetching: true
            }),
            [`${IMPERSONATE_START}/${FETCH_ERROR}`]: (state) => ({
                ...state,
                impersonateFetching: false
            }),
            [`${IMPERSONATE_END}/${FETCH_SUCCESS}`]: (state) => ({
                ...state,
                profile: state.staffProfile || state.profile,
                isImpersonating: false,
                staffProfile: null
            }),
            [`${STAFF}/${FETCH_ERROR}`]: (state) => ({
                ...state,
                authenticated: false
            }),
            SET_LOGIN_EMAIL: (state, { payload }) => ({ ...state, loginEmail: payload }),
            [SET_ATTENDED_APPOINTMENTS]: (state, { payload }) => ({
                ...state,
                attendedAppointments: payload
            }),
            [SET_ATTENDED_EVENTS]: (state, { payload }) => ({
                ...state,
                attendedEvents: payload
            }),
            [SET_CHECKIN_TIMES]: (state, { payload }) => ({
                ...state,
                checkinTimes: payload
            }),
            [`${SAVE_ENVELOPE}/${FETCH_PENDING}`]: state => ({
                ...state,
                savingEnvelope: true
            }),
            [`${SAVE_ENVELOPE}/${FETCH_SUCCESS}`]: state => ({
                ...state,
                savingEnvelope: false
            }),
            [`${SAVE_ENVELOPE}/${FETCH_ERROR}`]: state => ({
                ...state,
                savingEnvelope: false
            }),
            [SET_GUEST_FRAGRANCE]: (state, { payload }) => ({
                ...state,
                profile: {
                    ...state.profile,
                    fragrance: {
                        ...state.profile.fragrance,
                        ...payload
                    }
                }
            }),
            [SET_CART_ID]: (state, { payload }) => ({
                ...state,
                profile: {
                    ...state.profile,
                    cartId: payload
                }
            }),
            [SET_CART_EXPIRATION]: (state, { payload }) => ({
                ...state,
                profile: {
                    ...state.profile,
                    cartExpiration: payload
                }
            }),
            [`${GET_VIP}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
                ...state,
                profile: {
                    ...state.profile,
                    vip: payload
                }
            }),
            [SET_STAFF_LOCATION]: (state, { payload }) => ({
                ...state,
                staffLocation: payload
            }),
            [`${FETCH_PROFILE_PICTURE}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
                ...state,
                profilePicture: payload
            })
        },
        fetchLocalStorageState()
    )
});
