import { createAction, handleActions } from 'redux-actions';
import { FETCH_SUCCESS } from 'middlewares/fetch';
import { SEARCH } from 'constants/search';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import { searchProducts } from 'reducers/elasticsearch';
import normalizeProduct from 'core/utils/normalizeProduct';
import { ZONES } from 'constants/zones';
import { AMBER_FILTER_VALUE, FILTER_TEXT, FLORAL_FILTER_VALUE } from 'constants/filters';
import { BADGE } from 'constants/badges';

export const CART = 'CART';
const initialState = {
    products: [],
    zoneFilters: []
};

export const productsFetching = createAction('IS_FETCHING', isFetching => isFetching);
export const productsData = createAction(
    'SET_PLP_PRODUCTS',
    productsData => productsData
);
export const setZoneFilters = createAction('SET_ZONE_FILTERS');

const fetchProducts = (bodyRequest) => {
    // Generate search request params
    const request = {
        bodyRequest
    };

    const endpoint = SEARCH.URL.DEFAULT;

    return searchProducts({ endpoint, request });
};

/**
 * Allow different query request depending on zone
 * @param {string} zone
 * @returns {object} request
 */
const getZoneQueryRequest = (zone) => {
    switch (zone) {
        case ZONES.NEW:
            return elasticSearchQueries.queryNewProducts(zone);
        default:
            return elasticSearchQueries.queryByZone(zone);
    }
};

/**
 * Indicates if there are new products in the response
 * @param {array} newProducts
 * @returns {boolean} request
 */
const containsNewProducts = (newProducts) => {
    let newProductsAvailable = false;

    if (newProducts.length) {
        newProductsAvailable = newProducts[0].doc_count > 0;
    }

    return newProductsAvailable;
};

/**
 * Indicates if products contains items marked as FAVORITES
 * @param {array} products
 * @returns {boolean} request
 */
const containsFavoritesProducts = (products) => {
    return products.flat().some((item) => item.badge === BADGE.FAVORITES);
};

/**
 * Fetch products by zone
 * @param {string} zone
 * @returns {function(*, *): *} promise
 */
export const getProductsByZone = (zone) => {
    return (dispatch) => {
        dispatch(productsFetching(true));
        const request = getZoneQueryRequest(zone);

        dispatch(fetchProducts(request))
            .then(({ aggregations: { products: { buckets: results }, categories: { buckets = [] } = {}, new: { buckets: newProducts = [] } = {} } }) => {
                const products = results.map((item) => item.top_products.hits.hits.map(normalizeProduct));
                const zoneFilters = buckets.map(filter => filter.key);

                if (containsNewProducts(newProducts)) {
                    zoneFilters.push(FILTER_TEXT.NEW);
                }

                if (containsFavoritesProducts(products)) {
                    zoneFilters.push(FILTER_TEXT.LOVED);
                }

                dispatch(setZoneFilters(zoneFilters));
                dispatch(productsData(products));
                dispatch(productsFetching(false));
            })
            .catch(() => dispatch(productsFetching(false)));
    };
};

/**
 * Fetch all products
 * @returns {function(*, *): *} promise
 */
export const getAllProducts = () => {
    return (dispatch) => {
        dispatch(productsFetching(true));
        const request = elasticSearchQueries.queryAll();

        dispatch(fetchProducts(request))
            .then(({ hits: { hits: results } }) => {
                dispatch(productsData(results.map(item => [normalizeProduct(item)])));
                dispatch(productsFetching(false));
            })
            .catch(() => dispatch(productsFetching(false)));
    };
};

/**
 * Fetch categories by zone
 * @param {string} zone
 * @returns {function(*, *): *} promise
 */
const getCategoriesByZone = (zone) => {
    return (dispatch) => {
        const request = getZoneQueryRequest(zone);

        dispatch(fetchProducts(request))
            .then(({ aggregations: { products: { buckets: results }, categories: { buckets = [] } = {}, new: { buckets: newProducts = [] } = {} } }) => {
                const products = results.map((item) => item.top_products.hits.hits.map(normalizeProduct));
                const zoneFilters = buckets.map(filter => filter.key);

                if (containsNewProducts(newProducts)) {
                    zoneFilters.push(FILTER_TEXT.NEW);
                }

                if (containsFavoritesProducts(products)) {
                    zoneFilters.push(FILTER_TEXT.LOVED);
                }

                dispatch(setZoneFilters(zoneFilters));
            });
    };
};

/**
 * Filter products by zone and filters
 * // TODO: Possibly combine both fetch methods into one, depending on how filtering will work
 * @param {string} zone
 * @param {array} filters
 * @returns {function(*, *): *} promise
 */
export const filterProducts = (zone, filters) => {
    return (dispatch, getState) => {
        // display all products in the zone when there is no filter selected.
        if (!filters.length) {
            return dispatch(getProductsByZone(zone));
        }

        // fix for refreshing fragrance plp page when having a multi string filter.
        if (zone === ZONES.FRAGRANCE) {
            filters = filters.map(filter => {
                if (filter.string === 'Floral') {
                    return FLORAL_FILTER_VALUE;
                }

                if (filter.string === 'Amber') {
                    return AMBER_FILTER_VALUE;
                }

                return filter;
            });
        }

        dispatch(productsFetching(true));
        const request = elasticSearchQueries.filterByZone(zone, filters);

        dispatch(fetchProducts(request))
            .then(({ aggregations: { products: { buckets: results } } }) => {
                dispatch(productsData(results.map((item) => item.top_products.hits.hits.map(normalizeProduct))));

                // Fetch categories when filters are not present. Fixes refreshing PLP with category selected
                if (!getState().productListing.zoneFilters.length) {
                    dispatch(getCategoriesByZone(zone));
                }

                dispatch(productsFetching(false));
            })
            .catch(() => dispatch(productsFetching(false)));
    };
};

export default handleActions(
    {
        [productsFetching]: (state, { payload }) => ({ ...state, isFetching: payload }),
        [`${SEARCH}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            items: [...payload.items]
        }),
        [productsData]: (state, { payload }) => ({
            ...state,
            products: payload
        }),
        [setZoneFilters]: (state, { payload }) => ({ ...state, zoneFilters: payload })
    },
    initialState
);
