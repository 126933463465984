const openSlashRegex = /^\//;
const endSlashRegex = /\/$/;
export const regexToPattern = regex =>
    regex
        .toString()
        .replace(openSlashRegex, '')
        .replace(endSlashRegex, '')
        .replace('\\/', '/');

/* eslint-disable no-useless-escape */

// Regular expressions
export const nonDigitsRegex = /\D*/g;
export const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'\-]+$/;
export const passwordRegex = /(?=^\S*$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,255}/;
export const emailRegex = /\b[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,4}\b/;
export const phoneRegex = /\+1 \(\d{3}\)\s\d{3}-\d{4}/;
export const finalDotRegex = /\.$/;
export const zipCodeRegex = /^\d{5}$|^\d{5}-\d{4}$/;
export const onlyDigitsRegex = /^\d+$/;
export const instagramRegex = /^[a-zA-Z0-9._]{2,30}$/;
export const tiktokRegex = /^[a-zA-Z0-9._]{2,}$/;

/* eslint-enable no-useless-escape */

// Input patterns
export const namePattern = regexToPattern(nameRegex);
export const passwordPattern = regexToPattern(passwordRegex);
export const emailPattern = regexToPattern(emailRegex);
export const phonePattern = regexToPattern(phoneRegex);
export const zipCodePattern = regexToPattern(zipCodeRegex);
export const onlyDigitsPattern = regexToPattern(onlyDigitsRegex);
export const instagramPattern = regexToPattern(instagramRegex);
export const tiktokPattern = regexToPattern(tiktokRegex);
