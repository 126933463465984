import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import { rem, styledProps } from 'core/styled/index';
import Header from 'components/GlobalComponents/Fonts/Header';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import { connect } from 'react-redux';
import { addNotes } from 'reducers/beautyplan';
import { bindActionCreators } from 'redux';
import { PRISMIC_ARTIST_TYPE } from 'constants/prismic';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SubSectionHeaderOuter = styled(Flex)`
  width: 100%;
  padding-top: ${rem(20)};
`;

const StyledHeader = styled(Header)`
  font-family: ${styledProps('font', 'ABChanelCorpo')};
  font-weight: 400;
  padding: 0 ${rem(20, 20, 20)};
  font-size: ${rem(18)};
  line-height: 1.3;
`;

const NotesContainer = styled(Box)`
    width: 100%;
    max-width: ${rem(400)};
    margin: auto;
`;

const StyledFormInput = styled(FormInput)`
    padding-bottom: ${rem(10)};
    line-height: 1.5;
`;

const StyledFormInputWrapper = styled.div`
    margin: ${rem(0, 0, 30)};
    text-align: left;
    border: ${rem(1)} solid black;
    min-height: ${rem(120)};

    > div {
        margin-top: ${rem(-15)};
    }
`;

const StyledHr = styled.hr`
    width: 100%;
    margin-top: ${rem(30)};
`;

class SimpleTextSection extends Component {

    static propTypes = {
        section: PropTypes.object.isRequired,
        sectionId: PropTypes.string.isRequired,
        addNotes: PropTypes.func.isRequired,
        note: PropTypes.string,
        lastName: PropTypes.string.isRequired,
        artists: PropTypes.array.isRequired
    };

    /**
     * Event handler on Blur to save field value
     * @param {object} e
     */
    onBlur = (e) => {
        const { sectionId, section: { id } } = this.props;
        const note = e.target.innerHTML;

        this.props.addNotes({ section: sectionId, id, note });
    };

    renderTitle = () => {
        const { section: { titleArtistType, title }, artists, lastName } = this.props;

        if (!titleArtistType) {
            return title;
        }

        const artist = artists.find(a => a.name.toLowerCase().includes(lastName.toLowerCase()));

        if (artist) {
            const artistType = artist.artistType === PRISMIC_ARTIST_TYPE.MASTER_ARTIST ? 'master artist' : 'beauty guide';

            return `${title}<br/>${artistType}`;
        }

        return `${title}<br/>beauty guide`;
    };

    render() {

        const { note, section: { placeholder } } = this.props;

        return (
            <Wrapper>
                <StyledHr/>
                <SubSectionHeaderOuter justifyContent='center' alignItems='center'>
                    <StyledHeader dangerouslySetInnerHTML={{ __html: this.renderTitle() }}/>
                </SubSectionHeaderOuter>
                <NotesContainer>
                    <StyledFormInputWrapper>
                        <StyledFormInput
                            id='notes'
                            placeholder={placeholder ? placeholder : 'Type any additional notes'}
                            inputfontsize={14}
                            clearOption={true}
                            label='notes'
                            onBlur={this.onBlur}
                            value={note}
                            renderUnderline={false}
                            hideLabelOnFocus
                            multiLine
                            multiLineInitialBlockHeight={rem(100)}
                            inputPadding={[5, 5]}
                            inputFullWidth
                            inputfont={'default'}
                        />
                    </StyledFormInputWrapper>
                </NotesContainer>
            </Wrapper>
        );
    }

}

const mapStateToProps =  ({ beautyplan: { currentPlan: { notes } }, user: { auth: { staffProfile: { lastName } = {} } }, makeupArtists: { artistData, residencyArtistData, virtualArtistData } }, { sectionId, section }) => {
    let note = '';
    if (notes) {
        const noteInSection = notes.find(n => n.section === sectionId && n.id === section.id);
        note = noteInSection ? noteInSection.note : '';
    }

    return {
        note,
        lastName,
        artists: [...artistData, ...residencyArtistData, ...virtualArtistData],
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { addNotes },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTextSection);

