import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import styled from 'styled-components';
import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import { rem, styledProps } from 'core/styled';

const BatchCodeWrapper = styled.div`
    margin-top: ${rem(30)};
`;

const BatchCodeTitle = styled.p`
    text-align: center;
    font-family: ${({ simpleUI }) =>
        styledProps('font', simpleUI ? 'ABChanelPBM' : 'CervoNeueNeue')};
    font-size: ${({ simpleUI }) => rem(simpleUI ? 10 : 20)};
    font-weight: ${({ simpleUI }) => (simpleUI ? 'bold' : '200')};
`;

const StyledSwatchCheckboxWrapper = styled.div`
    display: flex;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
    justify-content: center;
    padding-left: ${rem(8)};

    > div {
        padding: 0 20px;
    }
`;

const StyledChromosomeTitle = styled(ChromosomeTitle)`
    padding: ${rem(2)} ${rem(10)};
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ${rem(20)};
`;

const CodeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: ${rem(200)};
    cursor: text;
`;

const CodeNumber = styled.div`
    display: flex;
    justify-content: center;
    width: 50px;
    margin: ${rem(0, 5)};
    height: 35px;
    border-bottom: solid 1px ${styledProps('color', 'black')};
    text-align: center;
`;

const QuemeroNumero = styled.div`
    font-size: ${rem(20)};
    padding: ${rem(7)};
    font-family: ${({ simpleUI }) =>
        styledProps('font', simpleUI ? 'ABChanelPBM' : 'CervoNeueNeue')};
`;

const CheckboxLabel = styled.div`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(9)};
    font-weight: bold;
    text-align: center;
    width: 100%;
    padding-right: ${rem(6)};
    margin-top: ${rem(5)};
    opacity: 0.7;
    ${({ selected }) => (selected ? 'opacity: 1;' : null)}
`;

// The input has styling to ensure that if the
// mobile device zooms and sets focus, it is on
// the right area of the screen.
const HiddenInput = styled.div`
    position: absolute;
    opacity: 0;

    input {
        font-size: ${rem(67)};
        width: 75vw;
        border: none;
        color: transparent;
        transform: scale(0);

        &:focus {
            outline: none;
        }
    }
`;

export default class BatchCodeOptions extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        updateProductBatchCode: PropTypes.func.isRequired,
        removeProductBatchCode: PropTypes.func.isRequired,
        simpleUI: PropTypes.bool
    };

    state = {
        isDrammed: !!this.props.product.batchCode,
        batchCode: this.props.product.batchCode
    };

    codeInput = React.createRef();

    _handleBatchCodeChange = event => {
        const enteredCode = event.target.value.replace(/[^0-9]/g, '');

        this.setState({
            batchCode: enteredCode
        });

        if (enteredCode.length === 4) {
            const { product, updateProductBatchCode } = this.props;

            updateProductBatchCode({
                sku: product.sku,
                batchCode: enteredCode
            });
        }
    };

    _handleBatchCodeInputBlur = event => {
        const { product, updateProductBatchCode } = this.props;

        updateProductBatchCode({
            sku: product.sku,
            batchCode: event.target.value
        });
    };

    _handleNotDrammedProduct = () => {
        const { isDrammed } = this.state;

        if (isDrammed) {
            const { product, removeProductBatchCode } = this.props;

            removeProductBatchCode({
                sku: product.sku
            });

            this.setState({
                batchCode: '',
                isDrammed: false
            });
        }
    };

    _handleDrammedProduct = () => {
        const { isDrammed } = this.state;

        if (!isDrammed) {
            const { product, updateProductBatchCode } = this.props;

            updateProductBatchCode({
                sku: product.sku,
                batchCode: ''
            });

            this.setState({
                isDrammed: true
            });
        }
    };

    _renderCheckboxLabel = (text, isSelected, rotation) => {
        if (isSelected) {
            return (
                <TiltedText
                    fontSize={12}
                    rotate={rotation}
                    bgColor={'black'}
                    fontColor={'white'}
                >
                    {text}
                </TiltedText>
            );
        }

        return (
            <StyledChromosomeTitle fontSize={12} display={'block'}>
                {text}
            </StyledChromosomeTitle>
        );
    };

    _renderBatchCodeInput = () => {
        const { batchCode } = this.state;
        const { simpleUI } = this.props;

        return (
            <InputWrapper onClick={() => this.codeInput.current.focus()}>
                <BatchCodeTitle simpleUI={simpleUI}>
                    ENTER 4 DIGIT BATCH CODE
                </BatchCodeTitle>
                <CodeContainer>
                    <CodeNumber>
                        <QuemeroNumero simpleUI={simpleUI}>
                            {batchCode ? batchCode[0] : ''}
                        </QuemeroNumero>
                    </CodeNumber>
                    <CodeNumber>
                        <QuemeroNumero simpleUI={simpleUI}>
                            {batchCode ? batchCode[1] : ''}
                        </QuemeroNumero>
                    </CodeNumber>
                    <CodeNumber>
                        <QuemeroNumero simpleUI={simpleUI}>
                            {batchCode ? batchCode[2] : ''}
                        </QuemeroNumero>
                    </CodeNumber>
                    <CodeNumber>
                        <QuemeroNumero simpleUI={simpleUI}>
                            {batchCode ? batchCode[3] : ''}
                        </QuemeroNumero>
                    </CodeNumber>
                </CodeContainer>
                <HiddenInput>
                    <input
                        maxLength={4}
                        pattern="\d*"
                        ref={this.codeInput}
                        value={batchCode}
                        onChange={this._handleBatchCodeChange}
                        onBlur={this._handleBatchCodeInputBlur}
                    />
                </HiddenInput>
            </InputWrapper>
        );
    };

    render() {
        const { isDrammed } = this.state;
        const { simpleUI } = this.props;

        return (
            <BatchCodeWrapper>
                <BatchCodeTitle simpleUI={simpleUI}>
                    IS THIS SAMPLE DRAMMED?
                </BatchCodeTitle>
                <StyledSwatchCheckboxWrapper>
                    <SwatchCheckbox
                        name={
                            simpleUI
                                ? SWATCH_CHECKBOX_NAMES.OTHERS_4
                                : SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                        }
                        value={'yes'}
                        flexDirection={'column'}
                        imageOrder={0}
                        onSelect={this._handleDrammedProduct}
                        textAlign={'center'}
                        display={'flex'}
                        isSelected={isDrammed}
                    >
                        {!simpleUI &&
                            this._renderCheckboxLabel('Yes', isDrammed, 2)}
                        {simpleUI && (
                            <CheckboxLabel selected={isDrammed}>
                                Yes
                            </CheckboxLabel>
                        )}
                    </SwatchCheckbox>

                    <SwatchCheckbox
                        name={
                            simpleUI
                                ? SWATCH_CHECKBOX_NAMES.OTHERS_4
                                : SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                        }
                        value={'no'}
                        flexDirection={'column'}
                        imageOrder={0}
                        onSelect={this._handleNotDrammedProduct}
                        textAlign={'center'}
                        display={'flex'}
                        isSelected={!isDrammed}
                    >
                        {!simpleUI &&
                            this._renderCheckboxLabel('No', !isDrammed, -2)}
                        {simpleUI && (
                            <CheckboxLabel selected={!isDrammed}>
                                No
                            </CheckboxLabel>
                        )}
                    </SwatchCheckbox>
                </StyledSwatchCheckboxWrapper>
                {isDrammed && this._renderBatchCodeInput()}
            </BatchCodeWrapper>
        );
    }
}
