import styled from 'styled-components';
import { styledProps, rem } from 'core/styled';

const Header = styled.h1`
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) :  rem(54)};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 300};
    color: ${({ cssColor }) => cssColor ? styledProps('color', cssColor) : styledProps('color', 'black')};
    ${({ cssMargin }) => cssMargin ? `margin:  ${rem(...cssMargin)}` : ''};
    ${({ marginTop }) => marginTop ? `margin-top:  ${rem(marginTop)}` : ''};
    ${({ marginBottom }) => marginBottom ? `margin-bottom:  ${rem(marginBottom)}` : ''};
    ${({ marginLeft }) => marginLeft ? `margin-left: ${rem(marginLeft)}` : ''};
    ${({ marginRight }) => marginRight ? `margin-right: ${rem(marginRight)}` : ''};
    ${({ padding }) => padding ? `padding:  ${rem(...padding)}` : ''};
    ${({ lineHeight }) => lineHeight ? `line-height: ${lineHeight}` : 'line-height: 0.89'};
    text-transform: uppercase;
    width: 100%;
    display: block;
    white-space: pre-line;
`;

export const SmallHeader = styled(Header).attrs(() => ({ as: 'h2' }))``;

export default Header;
