import moment from 'moment';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import {
    FETCH,
    FETCH_ERROR,
    FETCH_PENDING,
    FETCH_SUCCESS
} from 'middlewares/fetch';
import { LocationId } from 'constants/booking';
import {
    filterOutPastTimeslots,
    normalizeBookingAvailability,
    normalizeConfirmedBooking
} from 'core/utils/normalizeAppointment';
import { BEAUTYPLAN_TYPE } from 'constants/beautyplan';
import { setDefinitions } from 'reducers/services';
import isProd from 'core/utils/isProd';
import log from 'core/log';
import { isAustin } from 'constants/locations';
import isNumeric from 'core/utils/isNumeric';
import { pickMultipleRandomArrayItems } from 'core/utils/pickRandomArrayItem';
import { fetchAtelierData } from './prismicFetch';

const BOOKING = 'BOOKING';
const APT_BOOKING = 'APPOINTMENT_BOOKING';
const APT_CANCEL = 'APPOINTMENT_CANCEL';
const BOOKING_UPDATE = 'BOOKING_UPDATE';
const SESSION_TYPES_SELECTED = 'SESSION_TYPES_SELECTED';
const STAFF_SELECTED = 'STAFF_SELECTED';
const CURRENT_BOOKING = 'CURRENT_BOOKING';
const CURRENT_BOOKINGS = 'CURRENT_BOOKINGS';
const NUMBER_OF_GUESTS_AND_LOCATION = 'NUMBER_OF_GUESTS_AND_LOCATION';
const BOOKING_TIMESPAN = 'BOOKING_TIMESPAN';
const RESET_TIMESPAN = 'RESET_TIMESPAN';
const FREE_PASS = 'FREE_PASS';
const CONFIRMED_BOOKING = 'CONFIRMED_BOOKING';
const ONLY_CONFIRMED_BOOKING = 'ONLY_CONFIRMED_BOOKING';
const SESSIONS = 'SESSIONS';
const SET_SESSIONS = 'SET_SESSIONS';
const SET_ID_TO_SESSION_MAP = 'SET_ID_TO_SESSION_MAP';
const RESET_STAFF_SELECTED = 'RESET_STAFF_SELECTED';
const SET_GUEST_INFO = 'SET_GUEST_INFO';
const SET_ORIGINAL_BOOKING_DATE = 'SET_ORIGINAL_BOOKING_DATE';
const RESET_PAYMENT_INFO_AND_SHIPPING_ADDRESS =
    'RESET_PAYMENT_INFO_AND_SHIPPING_ADDRESS';
const RESET_PAYMENT_INFO = 'RESET_PAYMENT_INFO';
const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
const SET_BOOKING_SELECTED = 'SET_BOOKING_SELECTED';
const SET_BOOKING_GIFT_APPLIED = 'SET_BOOKING_GIFT_APPLIED';
const RESET_AVAILABLE_BOOKINGS = 'RESET_AVAILABLE_BOOKINGS';
const GET_GUEST_BOOKING = 'GET_GUEST_BOOKING';
const PAY_GUEST_BOOKING = 'PAY_GUEST_BOOKING';
const NO_ATTEND_GUEST_BOOKING = 'NO_ATTEND_GUEST_BOOKING';
const SET_BOOKING_LOCATION = 'SET_BOOKING_LOCATION';
const SET_SHOW_AUSTIN = 'SET_SHOW_AUSTIN';
const SET_BOOKING_COVID_LEGALS = 'SET_BOOKING_COVID_LEGALS';
const SET_BOOKING_FROM_APP = 'SET_BOOKING_FROM_APP';
const SET_BOOKING_KEY = 'SET_BOOKING_KEY';
const SET_BOOKING_APP_MODE = 'SET_BOOKING_APP_MODE';
const SET_NUMBER_OF_GUESTS = 'SET_NUMBER_OF_GUESTS';
const SET_BOOKING_DATE = 'SET_BOOKING_DATE';
const SET_BOOKING_SLOT = 'SET_BOOKING_SLOT';
const SET_ASSOCIATIONS_FETCHED = 'SET_ASSOCIATIONS_FETCHED';
const SET_BOOKING_GUEST_DATA = 'SET_BOOKING_GUEST_DATA';
const BOOKING_RSVP = 'BOOKING_RSVP';

const resetState = {
    isFetching: false,
    error: null,
    sessionTypes: [],
    staffIds: null,
    locationIds: [LocationId],
    startDateMin: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
    startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
    endDate: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    endDateMax: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    availableBookings: [],
    currentBooking: {},
    currentBookings: [], // similar as the above but for group/multiple bookings
    numberOfGuests: 0,
    bookingLocation: null,
    bookingSelected: true, // by default we try to pick first booking
    confirmedBooking: null,
    // hasApplicationFreePass: false,
    // hasLessonFreePass: false,
    // hasSkinChatFreePass: false,
    // hasMakeupChatFreePass: false,
    // hasGroupChatFreePass: false,
    // hasFragranceDiscoveryInPersonFreePass: false,
    // hasGroupFragranceDiscoveryInPersonFreePass: false,
    // hasFragranceDiscoveryVirtualFreePass: false,
    // hasFragranceImmersionVirtualFreePass: false,
    // hasVirtualBrowServiceFreePass: false,
    // hasAtHomeFacialFreePass: false,
    // hasEyeMakeupApplicationFreePass: false,
    // hasSkinConsultationInPersonFreePass: false,
    freePasses: {},
    guestInfo: [],
    originalBookingDate: null,
    paymentInformation: null,
    shippingAddress: null,
    giftApplied: false,
    fromApp: false,
    // new booking tool
    bookingKey: null,
    bookingDate: null,
    bookingSlot: null,
    guestApptId: null,
    guestToken: null
};

const initialState = {
    ...resetState,
    sessions: null,
    idToSessionMap: null,
    showAustin: false,
    covidLegals: null,
    associationsFetched: false
};

export const setBookingLocation = createAction(SET_BOOKING_LOCATION);
const setSessionTypes = createAction(SESSION_TYPES_SELECTED);
const setSelectedStaff = createAction(STAFF_SELECTED);
const setCurrentBooking = createAction(CURRENT_BOOKING);
const setCurrentBookings = createAction(CURRENT_BOOKINGS);
const setNumberOfGuestsAndLocation = createAction(
    NUMBER_OF_GUESTS_AND_LOCATION
);
const setBookingInterval = createAction(BOOKING_TIMESPAN);
const resetBookingInterval = createAction(RESET_TIMESPAN);
const setConfirmedBooking = createAction(CONFIRMED_BOOKING);
const setOnlyConfirmedBooking = createAction(ONLY_CONFIRMED_BOOKING);
const setSessions = createAction(SET_SESSIONS);
const setIdToSessionMap = createAction(SET_ID_TO_SESSION_MAP);
export const resetSelectedStaff = createAction(RESET_STAFF_SELECTED);
export const setGuestInfo = createAction(SET_GUEST_INFO);
const setOriginalBookingDate = createAction(SET_ORIGINAL_BOOKING_DATE);
export const resetPaymentInfoAndShippingAddress = createAction(
    RESET_PAYMENT_INFO_AND_SHIPPING_ADDRESS
);
export const resetPaymentInfo = createAction(RESET_PAYMENT_INFO);
export const setPaymentInfo = createAction(SET_PAYMENT_INFO);
export const setShippingAddress = createAction(SET_SHIPPING_ADDRESS);
export const setBookingSelected = createAction(SET_BOOKING_SELECTED);
export const setGiftApplied = createAction(SET_BOOKING_GIFT_APPLIED);
export const resetAvailableBookings = createAction(RESET_AVAILABLE_BOOKINGS);
export const setShowAustin = createAction(SET_SHOW_AUSTIN);
export const setBookingCovidLegals = createAction(SET_BOOKING_COVID_LEGALS);
export const setBookingFromApp = createAction(SET_BOOKING_FROM_APP);

// new booking
const setBookingKey = createAction(SET_BOOKING_KEY);
const setBookingAppMode = createAction(SET_BOOKING_APP_MODE);
export const setNumberOfGuests = createAction(SET_NUMBER_OF_GUESTS);
export const setBookingDate = createAction(SET_BOOKING_DATE);
export const setBookingSlot = createAction(SET_BOOKING_SLOT);
const setAssociationsFetched = createAction(SET_ASSOCIATIONS_FETCHED);
export const setGuestData = createAction(SET_BOOKING_GUEST_DATA);

let abortController;

/**
 * Get SessionTypes
 */
export const getServices = createAction(FETCH, () => ({
    prefix: SESSIONS,
    endpoint: `${CONFIG.API_URL}/services`,
    options: {
        method: 'GET'
    }
}));

const buildBookableEndpoint = (
    sessionTypes,
    staffIds,
    locationIds,
    startDate,
    endDate
) => {
    let endpoint =
        `${CONFIG.API_URL}/scheduling/bookable` +
        `?sessionTypeIds=${sessionTypes.join(',')}` +
        `&locationIds=${locationIds.join(',')}` +
        `&startDate=${startDate}` +
        `&endDate=${endDate}`;

    if (staffIds) {
        endpoint += `&staffIds=${staffIds.join(',')}`;
    }

    return endpoint;
};

/**
 * Get booking information
 */
const getBookingItems = createAction(
    FETCH,
    (
        sessionTypes,
        staffIds,
        locationIds,
        startDate,
        endDate,
        merge,
        signal
    ) => ({
        prefix: merge ? BOOKING_UPDATE : BOOKING,
        endpoint: buildBookableEndpoint(
            sessionTypes,
            staffIds,
            locationIds,
            startDate,
            endDate
        ),
        options: {
            method: 'GET',
            signal // to abort request
        }
    })
);

/*
 * Book an appointment
 */
const bookAppointment = createAction(
    FETCH,
    (
        startDateTime,
        locationId,
        staffId,
        customerId,
        sessionTypeId,
        guestInfo,
        paymentInformation,
        shippingAddress,
        giftCode,
        location,
        appointmentId,
        originalDate
    ) => {
        const body = {
            startDateTime,
            locationId,
            staffId,
            customerId,
            sessionTypeId,
            appointmentId,
            location,
            fromApp: false
        };

        if (guestInfo.length) {
            body.guestInfo = guestInfo;
        }

        if (appointmentId) {
            body.originalDate = originalDate;
        }

        if (paymentInformation) {
            body.creditCardData = paymentInformation;
        }

        if (shippingAddress) {
            body.shippingAddress = shippingAddress;
        }

        if (giftCode) {
            body.giftCode = giftCode;
        }

        return {
            prefix: APT_BOOKING,
            endpoint: `${CONFIG.API_URL}/scheduling/appointments`,
            options: {
                method: 'POST',
                body: JSON.stringify(body)
            }
        };
    }
);

const bookAppointments = createAction(FETCH, bookingRequest => ({
    prefix: APT_BOOKING,
    endpoint: `${CONFIG.API_URL}/appointments/book`,
    options: {
        method: 'POST',
        body: JSON.stringify(bookingRequest)
    }
}));

/**
 * Cancel Appointment
 */
const cancelAppointment = createAction(
    FETCH,
    (customerId, appointmentDate, appointmentId, sessionTypeId, isGuest) => ({
        prefix: APT_CANCEL,
        endpoint:
            `${CONFIG.API_URL}/scheduling/appointments?customerId=${customerId}` +
            `&date=${appointmentDate}&appointmentId=${appointmentId}&sessionTypeId=${sessionTypeId}${
                isGuest ? '&isGuest=true' : ''
            }`,
        options: {
            method: 'DELETE'
        }
    })
);

/**
 * Get Free passes (if any) for the current user
 */
export const getFreePasses = createAction(FETCH, (force) => ({
    prefix: FREE_PASS,
    endpoint: `${CONFIG.API_URL}/scheduling/freepasses${force ? '?force=true' : ''}`,
    options: {
        method: 'GET'
    }
}));

const fetchGuestAppointment = createAction(FETCH, (appointmentId, token) => ({
    prefix: GET_GUEST_BOOKING,
    endpoint: `${CONFIG.API_URL}/guest-book/review/${encodeURI(
        appointmentId
    )}/${encodeURI(token)}`,
    options: {
        method: 'GET'
    }
}));

const submitGuestPayment = createAction(FETCH, request => ({
    prefix: PAY_GUEST_BOOKING,
    endpoint: `${CONFIG.API_URL}/guest-book/review/submit`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

const cancelAttendingGuestBooking = createAction(
    FETCH,
    (appointmentId, token) => ({
        prefix: NO_ATTEND_GUEST_BOOKING,
        endpoint: `${CONFIG.API_URL}/guest-book/not-attend/${encodeURI(
            appointmentId
        )}/${encodeURI(token)}`,
        options: {
            method: 'PUT'
        }
    })
);

export const rsvpGuestBooking = createAction(FETCH, (appointmentId, token) => ({
    prefix: BOOKING_RSVP,
    endpoint: `${CONFIG.API_URL}/appointments/guest/rsvp?apptId=${appointmentId}&token=${token}`,
    options: {
        method: 'GET'
    }
}));

/**
 * Create object of session info and copy mapping from Prismic
 * @param {object} service
 * @param {string} serviceKey
 * @return {object} session info
 */
const getSessionInfo = (service, serviceKey) => {
    const sessionInfo = {};

    if (!service) {
        sessionInfo.All = {};
        log.error(`Missing service info for ${serviceKey}`);
        return sessionInfo;
    }

    const {
        booking_title,
        booking_sub_heading,
        booking_notification,
        subheading,
        footnote,
        is_available,
        is_available_non_prod,
        austin_is_available,
        austin_is_avaliable_non_prod,
        is_my_atelier_hidden_service,
        profile_card_name,
        session_length,
        analytics_prefix,
        price,
        staff_mindbody_ids,
        austin_staff_mindbody_ids,
        staff_ordered,
        is_virtual_service,
        payment_required,
        artist_title_override_text,
        pre_payment_form_text,
        require_shipping_address,
        restrict_non_contiguous_states,
        artist_picker_title_override,
        waitroom_guest,
        waitroom_host,
        waitroom_artist,
        waitroom_staff,
        hide_artist_picker,
        image,
        staff_only,
        staff_only_non_prod,
        description,
        show_in_beautyplan,
        beautyplan_name,
        beautyplan_subname,
        beauty_preference_key,
        booking_start_date,
        max_group_size,
        employee_discount,
        group_employee_discount,
        discount_percentage
    } = service.appointment_type;

    sessionInfo.All = {
        id: service.session_types,
        nycId: service.nyc_session_ids,
        austinId: service.austin_session_ids,
        nycCompId: service.comp_session_id,
        austinCompId: service.austin_comp_session_id,
        name: `${analytics_prefix}-all`,
        text: `${session_length} minute ${profile_card_name}`,
        cardDisplay: profile_card_name,
        subText: subheading,
        price: `$${price}`,
        baseAnalytics: analytics_prefix,
        analytics: `${analytics_prefix}-all`,
        length: session_length,
        is_available: isProd()
            ? is_available === 'Yes'
            : is_available_non_prod === 'Yes',
        austin_is_available: isProd()
            ? austin_is_available
            : austin_is_avaliable_non_prod,
        isMyAtelierHiddenService: is_my_atelier_hidden_service,
        staffOnly: isProd() ? staff_only : staff_only_non_prod,
        isRequest: service.booking_type === 'request',
        nycStaffIDs: staff_mindbody_ids ? staff_mindbody_ids.split(',') : [],
        austinStaffIDs: austin_staff_mindbody_ids
            ? austin_staff_mindbody_ids.split(',')
            : [],
        staffOrdered: !!staff_ordered,
        isVirtualService: !!is_virtual_service,
        paymentRequired: !!payment_required,
        unitPrice: isNumeric(price) ? parseFloat(price) : 0,
        sessionPickerText: {
            booking_title,
            booking_sub_heading,
            booking_notification,
            subheading,
            footnote
        },
        reviewPageText: {
            artistTitleOverrideText: artist_title_override_text,
            prePaymentFormText: pre_payment_form_text
        },
        requireShippingAddress: !!require_shipping_address,
        restrictNonContiguousStates: !!restrict_non_contiguous_states,
        artistPickerTitleOverride: artist_picker_title_override,
        waitroom: {
            guest: waitroom_guest,
            host: waitroom_host,
            artist: waitroom_artist,
            staff: waitroom_staff
        },
        hideArtistPicker: hide_artist_picker,
        image: image && image.url ? image.url : undefined,
        description,
        showInBeautyplan: show_in_beautyplan,
        beautyplanName: beautyplan_name,
        beautyplanSubname: beautyplan_subname,
        beautyPreferenceKey: beauty_preference_key,
        bookingStartDate: booking_start_date,
        maxGroupSize: max_group_size,
        employeeDiscount: employee_discount,
        groupEmployeeDiscount: group_employee_discount,
        discountMultiplier: discount_percentage ? (100 - discount_percentage) / 100 : 1
    };

    return sessionInfo;
};

/**
 * Create object of followUp session info and copy mapping from Prismic
 * @param {object} service
 * @return {object} session info for follow ups
 */
const getFollowUpInfo = service => {
    const sessionInfo = {};

    if (!service) {
        sessionInfo.All = {};
        log.error(`Missing service info for follow_up`);
    } else {
        sessionInfo.All = {
            id: service.session_types,
            is_available: false // do not display on /book page
        };
    }

    return sessionInfo;
};

const getIdToSessionMap = sessionTypeData => {
    const idToSessionMap = {};
    const keys = Object.keys(sessionTypeData);

    keys.forEach(key => {
        if (key === 'FollowUp') {
            sessionTypeData[key].All.id?.forEach(id => {
                idToSessionMap[id] = key;
            });

            return;
        }

        sessionTypeData[key].All.nycId?.forEach(id => {
            idToSessionMap[id] = key;
        });

        sessionTypeData[key].All.austinId?.forEach(id => {
            idToSessionMap[id] = key;
        });

        if (sessionTypeData[key].All.nycCompId) {
            idToSessionMap[sessionTypeData[key].All.nycCompId] = key;
        }

        if (sessionTypeData[key].All.austinCompId) {
            idToSessionMap[sessionTypeData[key].All.austinCompId] = key;
        }
    });

    return idToSessionMap;
};

export const sessionKeyToSessionType = {
    makeup_lesson: 'Lesson',
    focused_makeup_lesson: 'FocusedLesson',
    makeup_application: 'Application',
    focused_makeup_application: 'FocusedApplication',
    eye_makeup_application: 'EyeMakeupApplication',
    skin_consultation_in_person: 'SkinConsultationInPerson',
    residency: 'Residency',
    virtual_brow_service: 'VirtualBrowService',
    makeup_chat: 'MakeupChat',
    group_makeup_chat: 'GroupMakeupChat',
    skincare_chat: 'SkincareChat',
    group_skincare_chat: 'GroupSkincareChat',
    group_chat: 'GroupChat',
    fragrance_discovery_in_person: 'FragranceDiscoveryInPerson',
    fragrance_discovery_virtual: 'FragranceDiscoveryVirtual',
    group_fragrance_discovery_in_person: 'GroupFragranceDiscoveryInPerson',
    group_fragrance_discovery_virtual: 'GroupFragranceDiscoveryVirtual',
    fragrance_immersion_virtual: 'FragranceImmersion',
    at_home_facial: 'AtHomeFacial',
    beauty_intake_consultation: 'BeautyIntakeConsultation',
    beauty_intake_consultation_virtual: 'BeautyIntakeConsultationVirtual',
    experience_happy_hour: 'ExperienceHappyHour',
    experience_iconic_look: 'ExperienceIconicLook',
    experience_iconic_look_deluxe: 'ExperienceIconicLookDeluxe',
    experience_day_night_beauty: 'ExperienceDayNightBeauty',
    experience_day_night_beauty_deluxe: 'ExperienceDayNightBeautyDeluxe',
    follow_up: 'FollowUp',
    getKeyFromSessionType: sessionType =>
        Object.keys(sessionKeyToSessionType).find(
            key => sessionKeyToSessionType[key] === sessionType
        )
};

export const getSessionTypes = () => {
    return dispatch => {
        return dispatch(getServices()).then(services => {
            const sessionTypeData = {};

            sessionTypeData.Lesson = getSessionInfo(
                services?.definitions?.makeup_lesson,
                'makeup_lesson'
            );
            sessionTypeData.FocusedLesson = getSessionInfo(
                services?.definitions?.focused_makeup_lesson,
                'focused_makeup_lesson'
            );
            sessionTypeData.Application = getSessionInfo(
                services?.definitions?.makeup_application,
                'makeup_application'
            );
            sessionTypeData.FocusedApplication = getSessionInfo(
                services?.definitions?.focused_makeup_application,
                'focused_makeup_application'
            );
            sessionTypeData.EyeMakeupApplication = getSessionInfo(
                services?.definitions?.eye_makeup_application,
                'eye_makeup_application'
            );
            sessionTypeData.SkinConsultationInPerson = getSessionInfo(
                services?.definitions?.skin_consultation_in_person,
                'skin_consultation_in_person'
            );
            sessionTypeData.Residency = getSessionInfo(
                services?.definitions?.residency,
                'residency'
            );
            sessionTypeData.VirtualBrowService = getSessionInfo(
                services?.definitions?.virtual_brow_service,
                'virtual_brow_service'
            );
            sessionTypeData.MakeupChat = getSessionInfo(
                services?.definitions?.makeup_chat,
                'makeup_chat'
            );
            sessionTypeData.GroupMakeupChat = getSessionInfo(
                services?.definitions?.group_makeup_chat,
                'group_makeup_chat'
            );
            sessionTypeData.SkincareChat = getSessionInfo(
                services?.definitions?.skincare_chat,
                'skincare_chat'
            );
            sessionTypeData.GroupSkincareChat = getSessionInfo(
                services?.definitions?.group_skincare_chat,
                'group_skincare_chat'
            );
            sessionTypeData.GroupChat = getSessionInfo(
                services?.definitions?.group_chat,
                'group_chat'
            );
            sessionTypeData.FragranceDiscoveryInPerson = getSessionInfo(
                services?.definitions?.fragrance_discovery_in_person,
                'fragrance_discovery_in_person'
            );
            sessionTypeData.FragranceDiscoveryVirtual = getSessionInfo(
                services?.definitions?.fragrance_discovery_virtual,
                'fragrance_discovery_virtual'
            );
            sessionTypeData.GroupFragranceDiscoveryInPerson = getSessionInfo(
                services?.definitions?.group_fragrance_discovery_in_person,
                'group_fragrance_discovery_in_person'
            );
            sessionTypeData.GroupFragranceDiscoveryVirtual = getSessionInfo(
                services?.definitions?.group_fragrance_discovery_virtual,
                'group_fragrance_discovery_virtual'
            );
            sessionTypeData.FragranceImmersion = getSessionInfo(
                services?.definitions?.fragrance_immersion_virtual,
                'fragrance_immersion_virtual'
            );
            sessionTypeData.AtHomeFacial = getSessionInfo(
                services?.definitions?.at_home_facial,
                'at_home_facial'
            );
            sessionTypeData.BeautyIntakeConsultation = getSessionInfo(
                services?.definitions?.beauty_intake_consultation,
                'beauty_intake_consultation'
            );
            sessionTypeData.BeautyIntakeConsultationVirtual = getSessionInfo(
                services?.definitions?.beauty_intake_consultation_virtual,
                'beauty_intake_consultation_virtual'
            );
            sessionTypeData.ExperienceHappyHour = getSessionInfo(
                services?.definitions?.experience_happy_hour,
                'experience_happy_hour'
            );
            sessionTypeData.ExperienceIconicLook = getSessionInfo(
                services?.definitions?.experience_iconic_look,
                'experience_iconic_look'
            );
            sessionTypeData.ExperienceIconicLookDeluxe = getSessionInfo(
                services?.definitions?.experience_iconic_look_deluxe,
                'experience_iconic_look_deluxe'
            );
            sessionTypeData.ExperienceDayNightBeauty = getSessionInfo(
                services?.definitions?.experience_day_night_beauty,
                'experience_day_night_beauty'
            );
            sessionTypeData.ExperienceDayNightBeautyDeluxe = getSessionInfo(
                services?.definitions?.experience_day_night_beauty_deluxe,
                'experience_day_night_beauty_deluxe'
            );
            sessionTypeData.FollowUp = getFollowUpInfo(
                services?.definitions?.follow_up
            );

            const idToSessionMap = getIdToSessionMap(sessionTypeData);

            dispatch(setSessions(sessionTypeData));
            dispatch(setIdToSessionMap(idToSessionMap));
            dispatch(setDefinitions(services.definitions));
        });
    };
};

const getSessionTypeId = sessionType => {
    return (dispatch, getState) => {
        const {
            booking: { sessions, idToSessionMap }
        } = getState();
        const sessionTypeName = idToSessionMap[sessionType];

        if (sessionTypeName) {
            return sessions[sessionTypeName].All.nycId;
        } else {
            return [];
        }
    };
};

export const getSessionType = sessionType => {
    return (dispatch, getState) => {
        const {
            booking: { sessions, idToSessionMap }
        } = getState();
        // If the sessionType is from the new ones in MindBody, retrieve type from idToSessionMap
        const type = idToSessionMap[sessionType];

        if (type) {
            return sessions[type].All;
        }

        //TODO: remove this hard coding
        switch (sessionType) {
            case 7:
            case 5:
                return sessions.Lesson.All;
            case 19:
            case 6:
                return sessions.Application.All;
            default:
                return [];
        }
    };
};

export const getAssociatedBeautyPlanType = sessionType => {
    return (dispatch, getState) => {
        const { idToSessionMap } = getState().booking;

        switch (idToSessionMap[sessionType]) {
            case 'Lesson':
            case 'FocusedLesson':
            case 'MakeupChat':
            case 'GroupMakeupChat':
                return BEAUTYPLAN_TYPE.NEW_MAKEUP_LESSON;
            case 'Application':
            case 'FocusedApplication':
                return BEAUTYPLAN_TYPE.NEW_MAKEUP_APPLICATION;
            case 'SkincareChat':
            case 'SkincareConsultation':
            case 'GroupSkincareChat':
            case 'SkinConsultationInPerson':
                return BEAUTYPLAN_TYPE.NEW_SKIN_ROUTINE;
            case 'GroupChat':
                return BEAUTYPLAN_TYPE.NEW_SKINCARE_CHAT;
            case 'VirtualBrowService':
                return BEAUTYPLAN_TYPE.NEW_BROW_LOOK;
            case 'FragranceDiscoveryVirtual':
            case 'FragranceDiscoveryInPerson':
            case 'FragranceImmersion':
            case 'GroupFragranceDiscoveryInPerson':
                return BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE;
            case 'AtHomeFacial':
                return BEAUTYPLAN_TYPE.AT_HOME_FACIAL;
            case 'EyeMakeupApplication':
                return BEAUTYPLAN_TYPE.EYE_MAKEUP_APPLICATION;
            case 'ExperienceHappyHour':
            case 'ExperienceIconicLook':
            case 'ExperienceIconicLookDeluxe':
            case 'ExperienceDayNightBeauty':
            case 'ExperienceDayNightBeautyDeluxe':
                return BEAUTYPLAN_TYPE.BEAUTY_GUIDE_TALK;
            default:
                return null;
        }
    };
};

/**
 * Get booking availabilities, with optional merge
 * @param {boolean} merge
 * @param {boolean} ignoreArtists
 * @return {function(*, *): *} Promise
 */
const getBookingAvailability = (merge = false, ignoreArtists = false) => {
    return (dispatch, getState) => {
        const {
            booking: {
                sessionTypes,
                locationIds,
                startDate,
                endDate,
                idToSessionMap,
                sessions,
                bookingLocation
            },
            makeupArtists: {
                artistData,
                residencyArtistData,
                freelancerStaffId
            }
        } = getState();
        let staffIds;

        if (!ignoreArtists) {
            const isAustinSelected = isAustin(bookingLocation);

            if (
                residencyArtistData &&
                sessionTypes.some(
                    sessionType => idToSessionMap[sessionType] === 'Residency'
                )
            ) {
                staffIds = residencyArtistData.map(a => a.staffid); // only fetch data for residency staff
            } else if (
                sessions.Application.All.isRequest &&
                freelancerStaffId.length &&
                sessionTypes.some(
                    sessionType => idToSessionMap[sessionType] === 'Application'
                )
            ) {
                staffIds = freelancerStaffId;
            } else if (
                sessions[idToSessionMap[parseInt(sessionTypes[0], 10)]].All[
                    isAustinSelected ? 'austinStaffIDs' : 'nycStaffIDs'
                ].length
            ) {
                staffIds =
                    sessions[idToSessionMap[parseInt(sessionTypes[0], 10)]].All[
                        isAustinSelected ? 'austinStaffIDs' : 'nycStaffIDs'
                    ];
            } else {
                staffIds = artistData.map(a => a.staffid); // always fetch data for all staff
            }
        }

        abortController = new AbortController();
        return dispatch(
            getBookingItems(
                sessionTypes,
                staffIds,
                locationIds,
                startDate,
                endDate,
                merge,
                abortController.signal
            )
        );
    };
};

/**
 * When session type changes, set it and check availabilities
 * @param {array} sessionTypes
 * @param {boolean} forGift - Use for gift purpose, display this and next 3 months slots
 * @return {function(*): *} Promise
 */
export const pickSessionTypes = (sessionTypes, forGift = false) => {
    return (dispatch, getState) => {
        const {
            booking: { idToSessionMap }
        } = getState();

        const validSessions = Object.keys(idToSessionMap).map(id =>
            id.toString()
        );
        const filteredSessions = sessionTypes.filter(id =>
            validSessions.includes(id)
        );

        // allow abort request
        !!abortController && abortController.abort();
        dispatch(resetAvailableBookings());

        dispatch(setSessionTypes(filteredSessions));
        dispatch(resetBookingInterval());
        if (forGift) {
            const { booking: startDate } = getState();
            dispatch(
                setBookingInterval({
                    startDate,
                    endDate: moment(startDate)
                        .add(3, 'month')
                        .endOf('month')
                })
            );
        }

        return dispatch(getBookingAvailability());
    };
};

/**
 * When artist is selected, set it
 * @param {array} staffIds
 * @return {function(*): *} Promise
 */
export const pickArtist = staffIds => {
    return dispatch => {
        // Update available bookings
        dispatch(setSelectedStaff(staffIds));
    };
};

/**
 * Select artist by artistId (name) instead of staffId (MindBody id)
 * @param {string} artistId
 * @return {Function} Action
 */
export const pickArtistByName = artistId => {
    return (dispatch, getState) => {
        const {
            makeupArtists: { artistData, residencyArtistData }
        } = getState();
        const artist = [...artistData, ...residencyArtistData].find(
            a => a.id === artistId
        );
        if (artist) {
            return dispatch(pickArtist([artist.staffid]));
        }
    };
};

/**
 * Select a date / time / staff corresponding to an appointment, in order to book it.
 * @param {object} booking
 * @return {Function} Promise
 */
export const pickAvailableBooking = booking => {
    return (dispatch, getState) => {
        const {
            booking: { currentBooking }
        } = getState();
        if (currentBooking && currentBooking.updating) {
            dispatch(setCurrentBooking({ ...currentBooking, ...booking })); // preserve original booking information
        } else {
            dispatch(setCurrentBooking(booking)); // locationId is hardcoded at the moment
        }
    };
};

/**
 * Get availabilities given a new time span
 * @param {date} startDate
 * @param {date} endDate
 * @param {boolean} ignoreArtists
 * @return {Function} Promise
 */
export const refreshAvailability = (startDate, endDate, ignoreArtists) => {
    return (dispatch, getState) => {
        const {
            booking: {
                startDateMin,
                endDateMax,
                currentBooking: { updating = false } = {}
            }
        } = getState();
        // update the bounds
        dispatch(setBookingInterval({ startDate, endDate }));
        const start = moment(startDate);
        const end = moment(endDate);
        const startMin = moment(startDateMin);
        const endMax = moment(endDateMax);
        // looking for data that is not yet available and requires fetching (earlier than startMin && >= today or later than endMax)
        if (
            (start.isBefore(startMin, 'day') &&
                (updating || start.isSameOrAfter(moment(), 'day'))) ||
            end.isAfter(endMax, 'day')
        ) {
            // !!abortController && abortController.abort();
            return dispatch(getBookingAvailability(true, ignoreArtists));
        }
        // no fetching necessary, data is already available
        return Promise.resolve();
    };
};

/**
 * Book a service (optionally cancel a previous booking in case of a re-scheduling)
 * @return {function(*, *): *} Thunk
 */
export const bookService = () => {
    return (dispatch, getState) => {
        const {
            booking: {
                currentBooking: {
                    startTime,
                    locationId,
                    staffId,
                    sessionType,
                    updating,
                    originalBooking
                },
                guestInfo,
                originalBookingDate,
                paymentInformation,
                shippingAddress,
                giftApplied,
                bookingLocation
            },
            user: {
                auth: {
                    profile: { externalId }
                }
            },
            gifts: { validCode }
        } = getState();
        const startDateTime = moment(startTime)
            .tz('America/New_York')
            .format('YYYY-MM-DDTHH:mm:ss');
        const bookingArguments = [
            startDateTime,
            locationId,
            staffId,
            externalId,
            sessionType,
            guestInfo,
            paymentInformation,
            shippingAddress,
            giftApplied ? validCode : null,
            bookingLocation
        ];
        if (updating && originalBooking) {
            bookingArguments.push(`${originalBooking.id}`);
            bookingArguments.push(originalBookingDate);

            if (originalBooking.isComplimentary) {
                bookingArguments[4] = originalBooking.sessionType;
            }
        }
        return dispatch(bookAppointment(...bookingArguments)).then(booking => {
            const {
                makeupArtists: { artistData }
            } = getState();
            dispatch(resetPaymentInfo());
            dispatch(
                setConfirmedBooking(
                    normalizeConfirmedBooking(artistData, booking)
                )
            );
        });
    };
};

/**
 * Book multiple services // created for app
 *
 * @param {boolean} employeeDiscount
 * @param {boolean} isNewBookingTool
 * @param {string} giftCode
 * @return {function(*, *): *} Thunk
 */
export const bookServices = (employeeDiscount = false, isNewBookingTool = false, giftCode = undefined) => {
    return (dispatch, getState) => {
        const {
            booking: {
                currentBookings,
                paymentInformation,
                shippingAddress,
                numberOfGuests,
                bookingLocation,
                fromApp
            },
            user: {
                auth: {
                    profile: { externalId }
                }
            },
            makeupArtists: { artistData }
        } = getState();

        const services = isNewBookingTool
            ? currentBookings.map(service => ({
                locationId: service.locationId,
                staffId: service.staffId,
                sessionTypeId: parseInt(service.sessionType),
                startDateTime: moment(service.startTime)
                    .tz('America/New_York')
                    .format('YYYY-MM-DDTHH:mm:ss')
            }))
            : currentBookings.map(service => ({
                ...service,
                startDateTime: moment(service.startDateTime)
                    .tz('America/New_York')
                    .format('YYYY-MM-DDTHH:mm:ss')
            }));

        const bookingRequest = {
            services,
            location: bookingLocation,
            guestCount: numberOfGuests,
            customerId: externalId,
            creditCardData: paymentInformation,
            shippingAddress,
            fromApp,
            giftCode,
            employeeDiscount
        };

        // modify will not be covered right now
        return dispatch(bookAppointments(bookingRequest)).then(response => {
            dispatch(resetPaymentInfo());
            if (currentBookings.length === 1 && numberOfGuests === 0) {
                dispatch(setOnlyConfirmedBooking(normalizeConfirmedBooking(artistData, response)));
            }
            return Promise.resolve(response);
        });
    };
};

export const startUpdateBooking = booking => {
    return (dispatch, getState) => {
        // allow abort request
        !!abortController && abortController.abort();
        dispatch(resetAvailableBookings());

        dispatch(
            setSessionTypes(
                getSessionTypeId(booking.sessionType)(null, getState)
            )
        );
        dispatch(setSelectedStaff([booking.staffId]));
        dispatch(
            setOriginalBookingDate(
                moment(booking.startTime).format('YYYY-MM-DD')
            )
        );
        dispatch(
            setCurrentBooking({
                ...booking,
                updating: true,
                originalBooking: booking
            })
        );
        if (moment().month() !== moment(booking.startTime).month()) {
            dispatch(
                setBookingInterval({
                    startDate: moment(booking.startTime)
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                    endDate: moment(booking.startTime)
                        .endOf('month')
                        .format('YYYY-MM-DD'),
                    isUpdate: true
                })
            );
        }

        return dispatch(getBookingAvailability());
    };
};

export const cancelService = (appointment, isGuest = false) => {
    return (dispatch, getState) => {
        const {
            user: {
                auth: {
                    profile: { externalId }
                }
            }
        } = getState();
        const appointmentDate = moment(appointment.startDateTime).format(
            'YYYY-MM-DD'
        );
        return dispatch(
            cancelAppointment(
                externalId,
                appointmentDate,
                appointment.id,
                appointment.typeId,
                isGuest
            )
        );
    };
};

export const getGuestBooking = (appointmentId, token) => {
    return dispatch => {
        dispatch(setCurrentBooking({}));
        return dispatch(fetchGuestAppointment(appointmentId, token));
    };
};

export const payGuestBooking = token => {
    return (dispatch, getState) => {
        const {
            booking: {
                currentBooking: { appointmentID },
                paymentInformation
            }
        } = getState();
        const request = {
            appointmentID,
            token,
            creditCardData: paymentInformation
        };
        return dispatch(submitGuestPayment(request));
    };
};

export const notAttendGuestBooking = (appointmentId, token) => {
    return dispatch => {
        return dispatch(cancelAttendingGuestBooking(appointmentId, token));
    };
};

/**
 * Given a list of free passes, find out if user has free application or lesson
 * @param {array} freepasses
 * @return {object} hasApplicationFreePass and hasLessonFreePass boolean props
 */
const normalizeFreePasses = freepasses => {
    return {
        Application: freepasses.some(
            p => p.ServiceKey === 'makeup_application'
        ),
        FocusedApplication: freepasses.some(p => p.ServiceKey === 'focused_makeup_application'),
        Lesson: freepasses.some(p => p.ServiceKey === 'makeup_lesson'),
        FocusedLesson: freepasses.some(p => p.ServiceKey === 'focused_makeup_lesson'),
        SkincareChat: freepasses.some(p => p.ServiceKey === 'skincare_chat'),
        MakeupChat: freepasses.some(p => p.ServiceKey === 'makeup_chat'),
        GroupChat: freepasses.some(p => p.ServiceKey === 'group_chat'),
        GroupFragranceDiscoveryInPerson: freepasses.some(
            p => p.ServiceKey === 'group_fragrance_discovery_in_person'
        ),
        FragranceDiscoveryInPerson: freepasses.some(
            p => p.ServiceKey === 'fragrance_discovery_in_person'
        ),
        FragranceDiscoveryVirtual: freepasses.some(
            p => p.ServiceKey === 'fragrance_discovery_virtual'
        ),
        FragranceImmersion: freepasses.some(
            p => p.ServiceKey === 'fragrance_immersion_virtual'
        ),
        VirtualBrowService: freepasses.some(
            p => p.ServiceKey === 'virtual_brow_service'
        ),
        AtHomeFacial: freepasses.some(p => p.ServiceKey === 'at_home_facial'),
        EyeMakeupApplication: freepasses.some(
            p => p.ServiceKey === 'eye_makeup_application'
        ),
        SkinConsultationInPerson: freepasses.some(
            p => p.ServiceKey === 'skin_consultation_in_person'
        ),
        Residency: freepasses.some(
            p => p.ServiceKey === 'residency'
        ),
        hasFetched: true
    };
};

export const initCurrentBookingFromApp = (
    location,
    numberOfGuests,
    services,
    fromApp = false
) => {
    return dispatch => {
        dispatch(
            setCurrentBookings(
                services.map(service => ({
                    startDateTime: service.startTime,
                    sessionTypeId: parseInt(service.sessionType),
                    staffId: service.staffId,
                    locationId: 1
                }))
            )
        );
        dispatch(setNumberOfGuestsAndLocation({ numberOfGuests, location }));
        dispatch(setBookingFromApp(!!fromApp));
        return Promise.resolve();
    };
};

// new booking tool related
export const setBookingKeyPromise = serviceKey => {
    return dispatch => {
        return Promise.resolve(dispatch(setBookingKey(serviceKey)));
    };
};

// sessionTypes already non-complimentary
export const setBookingAppSessionTypes = (sessionTypes, startBookingDate) => {
    return (dispatch) => {

        const validDate = !!startBookingDate && startBookingDate.match(/^\d{4}-\d{2}-\d{2}$/);

        dispatch(setBookingAppMode(validDate ? startBookingDate : undefined));
        dispatch(setNumberOfGuests(0));


        // allow abort request
        !!abortController && abortController.abort();
        dispatch(resetAvailableBookings());

        dispatch(setSessionTypes(sessionTypes));
        dispatch(resetBookingInterval());

        // if (forGift) {
        //     const { booking: startDate } = getState();
        //     dispatch(setBookingInterval({ startDate, endDate: moment(startDate).add(3, 'month').endOf('month') }));
        // }

        return dispatch(getBookingAvailability(false, true));
    };
};

export const selectBookingAppSlot = slot => {
    return (dispatch) => {
        dispatch(setBookingSlot(slot));
    };
};

export const prepareBookingAppPayment = () => {
    return (dispatch, getState) => {
        const {
            booking: { bookingSlot, numberOfGuests, sessions, idToSessionMap }
        } = getState();

        const groupSameService =
            sessions[idToSessionMap[bookingSlot.sessionType]].All
                .groupSameService;

        // let bookings;

        // TODO ADD HERE THE LOGIC FOR SINGLE BOOKING GROUP (like virtual ones)
        const bookings = pickMultipleRandomArrayItems(
            bookingSlot.staff,
            groupSameService ? numberOfGuests + 1 : 1
        ).map(staff => ({ ...bookingSlot, staffId: staff.staffId }));
        // } else {
        // }

        // TODO ADD HERE THE OTHER SERVICES

        return Promise.resolve(dispatch(setCurrentBookings(bookings)));
    };
};

export const getAppointmentAssociationsConfig = () => (dispatch, getState) => {
    const {
        booking: { associationsFetched, sessions }
    } = getState();

    if (!associationsFetched) {
        // call prismic
        return dispatch(
            fetchAtelierData(
                'app_appointment_associations',
                isProd()
                    ? 'web_appointment_associations'
                    : 'web_appointment_association_non_prod'
            )
        ).then(res => {
            const newSessions = { ...sessions };

            res.data.appointment_mapping.forEach(
                ({
                    in_person,
                    use_in_person_for_group,
                    group_in_person,
                    virtual,
                    group_virtual
                }) => {
                    if (in_person) {
                        newSessions[sessionKeyToSessionType[in_person]].All = {
                            ...newSessions[sessionKeyToSessionType[in_person]]
                                .All,
                            groupAllowed:
                                use_in_person_for_group || !!group_in_person,
                            groupSameService: use_in_person_for_group,
                            groupService:
                                sessionKeyToSessionType[
                                    use_in_person_for_group
                                        ? in_person
                                        : group_in_person
                                ]
                        };

                        if (group_in_person) {
                            newSessions[
                                sessionKeyToSessionType[group_in_person]
                            ].All = {
                                ...newSessions[
                                    sessionKeyToSessionType[group_in_person]
                                ].All,
                                groupAllowed: true,
                                groupOnly: false
                            };
                        }
                    }

                    if (!in_person && !!group_in_person) {
                        newSessions[
                            sessionKeyToSessionType[group_in_person]
                        ].All = {
                            ...newSessions[
                                sessionKeyToSessionType[group_in_person]
                            ].All,
                            groupAllowed: true,
                            groupOnly: true,
                            groupService: sessionKeyToSessionType[group_in_person]
                        };
                    }

                    if (virtual) {
                        newSessions[sessionKeyToSessionType[virtual]].All = {
                            ...newSessions[sessionKeyToSessionType[virtual]]
                                .All,
                            groupAllowed: !!group_virtual,
                            groupSameService: false,
                            groupService: sessionKeyToSessionType[group_virtual]
                        };

                        if (group_virtual) {
                            newSessions[
                                sessionKeyToSessionType[group_virtual]
                            ].All = {
                                ...newSessions[
                                    sessionKeyToSessionType[group_virtual]
                                ].All,
                                groupAllowed: true,
                                groupOnly: false
                            };
                        }
                    }

                    if (!virtual && !!group_virtual) {
                        newSessions[
                            sessionKeyToSessionType[group_virtual]
                        ].All = {
                            ...newSessions[
                                sessionKeyToSessionType[group_virtual]
                            ].All,
                            groupAllowed: true,
                            groupOnly: true,
                            groupService: sessionKeyToSessionType[group_virtual]
                        };
                    }
                }
            );

            dispatch(setAssociationsFetched(true));
            return Promise.resolve(dispatch(setSessions(newSessions)));
        });
    }

    return Promise.resolve();
};

export const changeServiceAvailability = sessionTypes => {
    return dispatch => {
        dispatch(setSessionTypes(sessionTypes));
        dispatch(resetBookingInterval());

        // if (forGift) {
        //     const { booking: startDate } = getState();
        //     dispatch(setBookingInterval({ startDate, endDate: moment(startDate).add(3, 'month').endOf('month') }));
        // }

        return dispatch(getBookingAvailability(false, true));
    };
};

export default handleActions(
    {
        [setBookingLocation]: (state, { payload }) => ({
            ...state,
            bookingLocation: payload
        }),
        [setSessionTypes]: (state, { payload }) => ({
            ...state,
            staffIds: null,
            sessionTypes: payload,
            currentBooking: state.currentBooking.updating
                ? {
                    updating: true,
                    originalBooking: state.currentBooking.originalBooking
                }
                : {}
        }),
        [setSelectedStaff]: (state, { payload }) => ({
            ...state,
            staffIds: payload.map(id => id.toString()),
            currentBooking: state.currentBooking.updating
                ? {
                    updating: true,
                    originalBooking: state.currentBooking.originalBooking
                }
                : {}
        }),
        [setBookingInterval]: (
            state,
            { payload: { startDate, endDate, isUpdate = false } }
        ) => ({
            ...state,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            startDateMin: isUpdate
                ? moment(startDate).format('YYYY-MM-DD')
                : moment(
                    moment.min(state.startDateMin, moment(startDate))
                ).format('YYYY-MM-DD'),
            endDateMax: isUpdate
                ? moment(endDate).format('YYYY-MM-DD')
                : moment(moment.max(state.endDateMax, moment(endDate))).format(
                    'YYYY-MM-DD'
                )
        }),
        [resetBookingInterval]: state => ({
            ...state,
            startDateMin: state.startDate,
            endDateMax: state.endDate
        }),
        [`${FREE_PASS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            freePasses: normalizeFreePasses(payload)
        }),
        [`${BOOKING}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            error: null,
            availableBookings: payload
                .map(normalizeBookingAvailability)
                .reduce((acc, val) => acc.concat(val), [])
                .filter(filterOutPastTimeslots)
        }),
        [SET_SESSIONS]: (state, { payload }) => {
            return {
                ...state,
                sessions: payload
            };
        },
        [SET_ID_TO_SESSION_MAP]: (state, { payload }) => {
            return {
                ...state,
                idToSessionMap: payload
            };
        },
        [`${BOOKING_UPDATE}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            error: null,
            availableBookings: [
                ...state.availableBookings,
                ...payload
                    .map(normalizeBookingAvailability)
                    .reduce((acc, val) => acc.concat(val), [])
            ].filter(filterOutPastTimeslots)
        }),
        [setConfirmedBooking]: (state, { payload }) => ({
            ...state,
            ...resetState,
            confirmedBooking: payload
        }),
        [setOnlyConfirmedBooking]: (state, { payload }) => ({
            ...state,
            confirmedBooking: payload
        }),
        [combineActions(
            `${BOOKING_UPDATE}/${FETCH_PENDING}`,
            `${BOOKING}/${FETCH_PENDING}`,
            `${APT_BOOKING}/${FETCH_PENDING}`,
            `${APT_CANCEL}/${FETCH_PENDING}`,
            `${GET_GUEST_BOOKING}/${FETCH_PENDING}`,
            `${PAY_GUEST_BOOKING}/${FETCH_PENDING}`,
            `${NO_ATTEND_GUEST_BOOKING}/${FETCH_PENDING}`
        )]: state => ({
            ...state,
            error: null,
            isFetching: true
        }),
        [combineActions(
            `${BOOKING}/${FETCH_ERROR}`,
            `${APT_BOOKING}/${FETCH_ERROR}`,
            `${APT_CANCEL}/${FETCH_ERROR}`,
            `${GET_GUEST_BOOKING}/${FETCH_ERROR}`,
            `${PAY_GUEST_BOOKING}/${FETCH_ERROR}`,
            `${NO_ATTEND_GUEST_BOOKING}/${FETCH_ERROR}`
        )]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            error: payload
        }),
        [`${APT_CANCEL}/${FETCH_SUCCESS}`]: state => ({
            ...state,
            isFetching: false,
            error: null
        }),
        [`${GET_GUEST_BOOKING}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            error: null,
            currentBooking: payload
        }),
        [setCurrentBooking]: (state, { payload }) => ({
            ...state,
            currentBooking: payload
        }),
        [setCurrentBookings]: (state, { payload }) => ({
            ...state,
            currentBookings: payload
        }),
        [setNumberOfGuestsAndLocation]: (state, { payload }) => ({
            ...state,
            numberOfGuests: payload.numberOfGuests,
            bookingLocation: payload.location
        }),
        [setGuestInfo]: (state, { payload }) => ({
            ...state,
            guestInfo: payload
        }),
        [setOriginalBookingDate]: (state, { payload }) => ({
            ...state,
            originalBookingDate: payload
        }),
        [resetSelectedStaff]: state => ({
            ...state,
            staffIds: null
        }),
        [resetPaymentInfoAndShippingAddress]: state => ({
            ...state,
            paymentInformation: null,
            shippingAddress: null,
            giftApplied: false
        }),
        [resetPaymentInfo]: state => ({
            ...state,
            paymentInformation: null
        }),
        [setPaymentInfo]: (state, { payload }) => ({
            ...state,
            paymentInformation: payload
        }),
        [setShippingAddress]: (state, { payload }) => ({
            ...state,
            shippingAddress: payload
        }),
        [SET_BOOKING_GIFT_APPLIED]: (state, { payload }) => ({
            ...state,
            giftApplied: payload
        }),
        [SET_BOOKING_SELECTED]: (state, { payload }) => ({
            ...state,
            bookingSelected: payload
        }),
        [SET_SHOW_AUSTIN]: (state, { payload }) => ({
            ...state,
            showAustin: payload
        }),
        [RESET_AVAILABLE_BOOKINGS]: state => ({
            ...state,
            isFetching: false,
            error: null,
            availableBookings: [],
            sessionTypes: [],
            staffIds: null
        }),
        [SET_BOOKING_COVID_LEGALS]: (state, { payload }) => ({
            ...state,
            covidLegals: payload
        }),
        [SET_BOOKING_FROM_APP]: (state, { payload }) => ({
            ...state,
            fromApp: payload
        }),
        [SET_BOOKING_KEY]: (state, { payload }) => ({
            ...state,
            bookingKey: payload
        }),
        [SET_BOOKING_APP_MODE]: (state, { payload }) => {
            // payload to be initial start date for service
            let startDateMin, startDate, endDateMax, endDate, bookingDate;

            if (payload && moment(payload).isAfter(moment())) {
                const today = moment().startOf('day');
                const startDateMoment = moment(payload).startOf('day');

                const daysDifference = startDateMoment.diff(today, 'days');

                const remainer = daysDifference % 4;

                const startDateString = moment(payload)
                    .add(0 - remainer, 'd')
                    .format('YYYY-MM-DD');
                const endDateString = moment(payload)
                    .add(3 - remainer, 'd')
                    .format('YYYY-MM-DD');

                startDateMin = startDateString;
                startDate = startDateString;
                endDate = endDateString;
                endDateMax = endDateString;
                bookingDate = payload;
            } else {
                const startDateString = moment().format('YYYY-MM-DD');
                const endDateString = moment()
                    .add(3, 'd')
                    .format('YYYY-MM-DD');

                startDateMin = startDateString;
                startDate = startDateString;
                endDate = endDateString;
                endDateMax = endDateString;
                bookingDate = null;
            }

            return {
                ...state,
                startDateMin,
                startDate,
                endDate,
                endDateMax,
                numberOfGuests: 0,
                bookingDate,
                bookingSlot: null,
                guestApptId: null,
                guestToken: null
            };
        },
        [SET_NUMBER_OF_GUESTS]: (state, { payload }) => ({
            ...state,
            numberOfGuests: payload
        }),
        [SET_BOOKING_DATE]: (state, { payload }) => ({
            ...state,
            bookingDate: payload
        }),
        [SET_BOOKING_SLOT]: (state, { payload }) => ({
            ...state,
            bookingSlot: payload
        }),
        [SET_ASSOCIATIONS_FETCHED]: (state, { payload }) => ({
            ...state,
            associationsFetched: payload
        }),
        [SET_BOOKING_GUEST_DATA]: (state, { payload }) => ({
            ...state,
            guestApptId: payload.apptId,
            guestToken: payload.token
        })
    },
    initialState
);
