import Fragrance1 from 'assets/fragranceApp/products/1.jpg';
import Fragrance2 from 'assets/fragranceApp/products/2.jpg';
import Fragrance3 from 'assets/fragranceApp/products/3.jpg';
import Fragrance4 from 'assets/fragranceApp/products/4.jpg';
import Fragrance5 from 'assets/fragranceApp/products/5.jpg';
import Fragrance6 from 'assets/fragranceApp/products/6.jpg';
import Fragrance7 from 'assets/fragranceApp/products/7.jpg';
import Fragrance8 from 'assets/fragranceApp/products/8.jpg';
import Fragrance9 from 'assets/fragranceApp/products/9.jpg';
import Fragrance10 from 'assets/fragranceApp/products/10.jpg';
import Fragrance11 from 'assets/fragranceApp/products/11.jpg';
import Fragrance12 from 'assets/fragranceApp/products/12.jpg';
import Fragrance13 from 'assets/fragranceApp/products/13.jpg';
import Fragrance14 from 'assets/fragranceApp/products/14.jpg';
import Fragrance15 from 'assets/fragranceApp/products/15.jpg';
import Fragrance16 from 'assets/fragranceApp/products/16.jpg';
import Fragrance17 from 'assets/fragranceApp/products/17.jpg';
import Fragrance18 from 'assets/fragranceApp/products/18.jpg';
import Fragrance19 from 'assets/fragranceApp/products/19.jpg';
import Fragrance20 from 'assets/fragranceApp/products/20.jpg';
import Fragrance21 from 'assets/fragranceApp/products/21.jpg';
import Fragrance22 from 'assets/fragranceApp/products/22.jpg';
import Fragrance23 from 'assets/fragranceApp/products/23.jpg';
import Fragrance24 from 'assets/fragranceApp/products/24.jpg';
import Fragrance25 from 'assets/fragranceApp/products/25.jpg';
import Fragrance26 from 'assets/fragranceApp/products/26.jpg';
import Fragrance27 from 'assets/fragranceApp/products/27.jpg';
import Fragrance28 from 'assets/fragranceApp/products/28.jpg';
import Fragrance29 from 'assets/fragranceApp/products/29.jpg';
import Fragrance30 from 'assets/fragranceApp/products/30.jpg';
import Fragrance31 from 'assets/fragranceApp/products/31.jpg';
import Fragrance32 from 'assets/fragranceApp/products/32.jpg';
import Fragrance33 from 'assets/fragranceApp/products/33.jpg';
import Fragrance34 from 'assets/fragranceApp/products/34.jpg';
import Fragrance35 from 'assets/fragranceApp/products/35.jpg';
import Fragrance36 from 'assets/fragranceApp/products/36.jpg';
import Fragrance37 from 'assets/fragranceApp/products/37.jpg';
import Fragrance38 from 'assets/fragranceApp/products/38.jpg';
import Fragrance39 from 'assets/fragranceApp/products/39.jpg';
import Fragrance40 from 'assets/fragranceApp/products/40.jpg';
import Fragrance41 from 'assets/fragranceApp/products/41.jpg';
import Fragrance42 from 'assets/fragranceApp/products/42.jpg';
import Fragrance43 from 'assets/fragranceApp/products/43.jpg';
import Fragrance44 from 'assets/fragranceApp/products/44.jpg';
import Fragrance45 from 'assets/fragranceApp/products/45.jpg';
import Fragrance46 from 'assets/fragranceApp/products/46.jpg';
import Fragrance47 from 'assets/fragranceApp/products/47.jpg';
import Fragrance48 from 'assets/fragranceApp/products/48.jpg';
import Fragrance49 from 'assets/fragranceApp/products/49.jpg';
import Fragrance50 from 'assets/fragranceApp/products/50.jpg';
import Fragrance51 from 'assets/fragranceApp/products/51.jpg';

const fragranceMap = {
    1: Fragrance1,
    2: Fragrance2,
    3: Fragrance3,
    4: Fragrance4,
    5: Fragrance5,
    6: Fragrance6,
    7: Fragrance7,
    8: Fragrance8,
    9: Fragrance9,
    10: Fragrance10,
    11: Fragrance11,
    12: Fragrance12,
    13: Fragrance13,
    14: Fragrance14,
    15: Fragrance15,
    16: Fragrance16,
    17: Fragrance17,
    18: Fragrance18,
    19: Fragrance19,
    20: Fragrance20,
    21: Fragrance21,
    22: Fragrance22,
    23: Fragrance23,
    24: Fragrance24,
    25: Fragrance25,
    26: Fragrance26,
    27: Fragrance27,
    28: Fragrance28,
    29: Fragrance29,
    30: Fragrance30,
    31: Fragrance31,
    32: Fragrance32,
    33: Fragrance33,
    34: Fragrance34,
    35: Fragrance35,
    36: Fragrance36,
    37: Fragrance37,
    38: Fragrance38,
    39: Fragrance39,
    40: Fragrance40,
    41: Fragrance41,
    42: Fragrance42,
    43: Fragrance43,
    44: Fragrance44,
    45: Fragrance45,
    46: Fragrance46,
    47: Fragrance47,
    48: Fragrance48,
    49: Fragrance49,
    50: Fragrance50,
    51: Fragrance51
};

export const getFragranceImage = fragranceNumber => {
    if (Number.isInteger(fragranceNumber)) {
        return fragranceMap[fragranceNumber.toString()] || null;
    } else {
        return null;
    }
};
