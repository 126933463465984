import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'components/GlobalComponents/FlexBox';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import styled from 'styled-components';
import Button from 'components/GlobalComponents/Button/Button';
import { rem, styledProps } from 'core/styled';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import Header from 'components/GlobalComponents/Fonts/Header';
import Loader from 'components/GlobalComponents/Loader/Loader';
import SubHeader from 'components/GlobalComponents/Fonts/SubHeader';
import { impersonate } from 'reducers/user';
import { searchCustomers } from 'reducers/customerSearch';
import Description from 'components/GlobalComponents/Fonts/Description';
import formatPhoneNumber from 'core/utils/formatPhoneNumber';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import { withRouter } from 'react-router-dom';
import ProfileSnapShot from 'components/ProfileComponents/ProfileSnapshot';
import VIP from 'constants/vip';
import { getLocationByID, LOCATIONS, LOCATION_TEXT } from 'constants/locations';

const PageWrapper = styled(Flex)``;
const SearchWrapper = styled.div`
    position: relative;
    padding: 0 ${rem(25)};
    margin-top: 20vh;
`;

const ButtonWrapper = styled.div`
    text-align: center;
    margin: ${({ margin }) => rem(...margin)};
`;

const SearchAgainWrapper = styled.div`
    text-align: center;
    border-top: 1px solid ${styledProps('color', 'black')};
    margin-bottom: ${rem(65)};
`;

const CustomerSearchCard = styled(Flex)`
    margin: ${({ margin }) => rem(...margin)};
    border-bottom: 2px solid ${styledProps('color', 'mercury')};
`;

const StyledTiltedText = styled(TiltedText)`
    margin: ${({ margin }) => rem(...margin)};
    text-align: center;
`;

const StyledButton = styled(Button)`
    min-width: 230px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    margin: 0 ${rem(30)};
    height: 100%;
`;

const StyledFormInput = styled(FormInput)`
    word-break: break-all;
    padding-right: 0;
`;

const StyledSubHeader = styled(SubHeader)`
    color: ${({ color }) => styledProps('color', color)};
`;

const StyledHeader = styled(Header)`
    cursor: pointer;
`;

class CustomerSearch extends Component {
    static propTypes = {
        push: PropTypes.func.isRequired,
        impersonate: PropTypes.func.isRequired,
        searchCustomers: PropTypes.func.isRequired,
        isFetching: PropTypes.bool.isRequired,
        onRequestClose: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        tag: PropTypes.func,
        response: PropTypes.array,
        error: PropTypes.object,
        isModalOpen: PropTypes.bool
    };

    static defaultProps = {
        tag: () => {}
    };

    state = {
        search: '',
        showForm: true,
        showResults: false,
        showSnapshot: false,
        snapshotProfile: null,
        isModalOpen: false,
        now: new Date().getTime()
    };

    componentDidMount() {
        this.props.tag('searchoverlay-initiated');
    }

    /**
     * reset customer search to initial state
     */
    resetSearch() {
        this.setState({
            search: '',
            showForm: true,
            showResults: false
        });
    }

    /**
     * On search input change
     * @param {object} event
     */
    handleInputChange = event => {
        const target = event.target;
        const value = (target.textContent || target.innerText || '').trim();

        this.setState({
            search: value
        });
    };

    /**
     * Handle user search clear button Tap
     */
    handleInputReset = () => {
        this.setState({
            search: ''
        });
    };

    /**
     * Handle form submit and start searching user
     */
    handleSubmit() {
        const { search } = this.state;
        const { searchCustomers, tag } = this.props;

        if (search.length <= 0) {
            return;
        }

        this.setState({ showForm: false, showResults: true });
        tag('searchoverlay-search');
        searchCustomers(search);
    }

    /**
     * Show user profile snapshot
     * @param {object} customer
     */
    displaySnapshot(customer) {
        this.setState({
            snapshotProfile: customer,
            showForm: false,
            showResults: false,
            showSnapshot: true
        });
    }

    /**
     * restore customer display in search results
     */
    closeSnapshot() {
        this.props.tag('customersnapshot-close');
        this.setState({
            showResults: true,
            showSnapshot: false
        });
    }

    getMemberDisplayColor(vipStatus, isEnvelopeReceived) {
        switch (vipStatus) {
            case VIP.FREELANCER.STRING:
                return VIP.FREELANCER.COLOR;
            case VIP.FOUNDER.STRING:
                return VIP.FOUNDER.COLOR;
            case VIP.REGULAR.STRING:
                return VIP.REGULAR.COLOR;
            case VIP.MY_ATELIER.STRING:
                return VIP.MY_ATELIER.COLOR;
            default:
                return isEnvelopeReceived ? 'killarney' : 'transparent';
        }
    }

    /**
     * Starts customer impersonation. Behaviour slighty varies based on the current page
     * @param {string} userId
     * @private {*}
     */
    _impersonate = userId => {
        const { impersonate, onRequestClose, tag } = this.props;
        tag('searchoverlay-browseas');
        impersonate(userId).then(onRequestClose);
    };

    /**
     * Render search form
     * @returns {component} component
     */
    _renderSearchForm = () => {
        const { search } = this.state;

        return (
            <Flex flex={1} flexDirection={'column'}>
                <form onSubmit={() => this.handleSubmit()}>
                    <SearchWrapper>
                        <StyledFormInput
                            id="search"
                            label="Search for guest"
                            inputfont={'ABChanelPBL'}
                            inputfontsize={33}
                            labelFont={'SofiaPro'}
                            labelFontSize={14}
                            onChange={this.handleInputChange}
                            onSubmit={() => this.handleSubmit()}
                            value={search}
                            clearOption={true}
                            onClear={this.handleInputReset}
                            maxLength={200}
                            multiLine
                            resetValue={!this.props.isModalOpen}
                        />
                    </SearchWrapper>
                </form>

                <ButtonWrapper margin={[50, 0]}>
                    <StyledButton
                        type="submit"
                        variant={'primary'}
                        disabled={!search.length}
                        onClick={() => this.handleSubmit()}
                    >
                        {' '}
                        Search{' '}
                    </StyledButton>
                </ButtonWrapper>
            </Flex>
        );
    };

    /**
     * Render list of customers
     * @param {object} response
     * @returns {component} component
     */
    _renderCustomers(response) {
        return response.map((customer, index) => {
            const { profile, checkins } = customer;
            const customerIsCheckedIn =
                checkins && checkins.inStore && checkins.inStore.length > 0;
            const color =
                profile.vip && profile.vip.end > this.state.now
                    ? this.getMemberDisplayColor(
                        profile.vip.status,
                        profile.isEnvelopeReceived
                    )
                    : profile.isEnvelopeReceived
                        ? 'killarney'
                        : 'black';
            const displayLocation = getLocationByID(profile.location) || 'NYC';

            return (
                <CustomerSearchCard
                    margin={[33, 0, 0, 0]}
                    flexDirection="column"
                    justifyContent={'center'}
                    key={index}
                >
                    <StyledSubHeader
                        marginBottom={10}
                        cssColor={color}
                        fontSize={12}
                    >
                        {LOCATION_TEXT[LOCATIONS[displayLocation]]}
                    </StyledSubHeader>
                    {profile.isEmployee && (
                        <StyledSubHeader
                            marginBottom={2}
                            cssColor={color}
                            fontSize={10}
                        >
                            CHANEL EMPLOYEE
                        </StyledSubHeader>
                    )}
                    <StyledHeader
                        cssColor={color}
                        onClick={() => this.displaySnapshot(customer)}
                        fontSize={26}
                        cssMargin={[0, 0, 5, 0]}
                    >
                        <span translate={'no'} className={'notranslate'}>
                            {profile.firstName} {profile.lastName}
                        </span>
                    </StyledHeader>
                    {profile.email && (
                        <StyledSubHeader
                            cssColor={color}
                            marginBottom={[2.5]}
                            fontSize={10}
                        >
                            {profile.email}
                        </StyledSubHeader>
                    )}
                    {profile.phone && (
                        <StyledSubHeader
                            cssColor={color}
                            marginBottom={[0]}
                            fontSize={10}
                        >
                            {formatPhoneNumber(profile.phone)}
                        </StyledSubHeader>
                    )}
                    {customerIsCheckedIn && (
                        <StyledTiltedText margin={[5, 0, 20, 0]} rotate={-3}>
                            Checked-in
                        </StyledTiltedText>
                    )}
                    <ButtonWrapper margin={[0, 0, 40, 0]}>
                        <Button
                            fontSize={9}
                            variant={'pseudoLink'}
                            onClick={() =>
                                this._impersonate(profile.externalId)
                            }
                        >
                            Browse as
                        </Button>
                    </ButtonWrapper>
                </CustomerSearchCard>
            );
        });
    }

    /**
     * Render either search results to profile snapshot
     * //TODO: animate state change
     * @param {object} response
     * @returns {component} component
     * @private
     */
    _renderCustomerList(response) {
        return (
            <div>
                <SubHeader>Results ({response.length})</SubHeader>
                {this._renderCustomers(response)}
            </div>
        );
    }

    /**
     * Render search results data
     * @returns {Component} results container
     */
    _renderResults = () => {
        const { isFetching, response, tag } = this.props;
        const { search } = this.state;
        const hasResults = !isFetching && response && response.length > 0;

        return (
            <ContentWrapper>
                <Header marginTop={35}>“{search}”</Header>
                {isFetching && <Loader />}
                {!hasResults && (
                    <SubHeader marginTop={40}>
                        No matching customer found.
                    </SubHeader>
                )}

                {hasResults && this._renderCustomerList(response)}

                <SearchAgainWrapper>
                    <Description padding={[80, 0, 15, 0]}>
                        Didn&apos;t find who you were looking for?
                    </Description>
                    <StyledButton
                        onClick={() => {
                            this.resetSearch();
                            tag('searchoverlay-searchagain');
                        }}
                    >
                        Search again
                    </StyledButton>
                </SearchAgainWrapper>
            </ContentWrapper>
        );
    };

    componentDidUpdate() {
        if (this.props.isModalOpen !== this.state.isModalOpen) {
            this.resetSearch();
            this.setState({
                isModalOpen: this.props.isModalOpen,
                showSnapshot: false
            });
        }
    }

    render() {
        const {
            showForm,
            showSnapshot,
            showResults,
            snapshotProfile
        } = this.state;

        return (
            <PageWrapper
                flex={1}
                alignItems={'center'}
                justifyContent={'center'}
            >
                {showForm && this._renderSearchForm()}
                {showResults && this._renderResults()}
                {showSnapshot && (
                    <ProfileSnapShot
                        userInfo={snapshotProfile}
                        onRequestClose={() => this.closeSnapshot()}
                        tag={this.props.tag}
                    />
                )}
            </PageWrapper>
        );
    }
}

const mapStateToProps = ({ customerSearch }) => ({ ...customerSearch });

const mapDispatchToProps = dispatch =>
    bindActionCreators({ push, impersonate, searchCustomers }, dispatch);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CustomerSearch)
);
