import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import { PrimaryModal } from 'components/GlobalComponents/Modal/PrimaryModal';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import Button from 'components/GlobalComponents/Button/Button';
import styled, { ThemeProvider } from 'styled-components';
import { rem, styledProps } from 'core/styled';
import Header from 'components/GlobalComponents/Fonts/Header';
import Description from 'components/GlobalComponents/Fonts/Description';
import { theme } from 'constants/theme';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import CLOSE_ICON from 'assets/icons/close/close-light-default.svg';

const ModalButtons = styled(Flex)`
    padding: ${rem(40, 0, 20, 0)};
    button {
        margin-bottom: ${rem(10)};
    }
`;

const ModalContentBodyWrapper = styled.div`
    padding: ${rem(5)} 0 0 0;
    text-align: center;
`;

const NewDesignHeader = styled.h1`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(24)};
    line-height: ${rem(28)};
    text-align: center;
    margin: ${rem(45, 0, 0)};
`;

const NewDesignDescription = styled.p`
    font-family: ${styledProps('font', 'default')};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    margin-top: ${rem(15)};
    text-align: center;
`;

const NewDesignButton = styled(Button)`
    border-width: ${rem(1)};
    height: ${rem(43)};
    width: ${rem(200)};
    font-size: ${rem(10)};
`;

const CloseButton = styled(NoStyleButton)`
    cursor: pointer;
    position: absolute;
    top: ${rem(5)};
    right: ${rem(5)};
    padding: ${rem(10)};

    img {
        display: block;
        height: ${rem(30)};
        width: ${rem(30)};
    }
`;

const ConfirmationModal = ({
    show,
    dismiss,
    cancel,
    proceed,
    cancelLabel,
    proceedLabel,
    title,
    confirmation,
    newDesign
}) => (
    <ThemeProvider theme={theme}>
        {show &&
            (newDesign ? (
                <PrimaryModal
                    atScreenCenter
                    showClose={false}
                    isDialogue
                    onRequestClose={dismiss}
                    connected={false}
                    previousScrollDisabledState={false}
                    showBorder
                    overrideMaxWidth={335}
                >
                    <ModalContentBodyWrapper>
                        <CloseButton onClick={dismiss}>
                            <img src={CLOSE_ICON} alt={'close'} />
                        </CloseButton>
                        <NewDesignHeader>{title}</NewDesignHeader>
                        <NewDesignDescription>
                            {confirmation}
                        </NewDesignDescription>
                        <ModalButtons
                            flexDirection="column"
                            alignItems="center"
                        >
                            <NewDesignButton onClick={proceed}>
                                {proceedLabel}
                            </NewDesignButton>
                            <NewDesignButton
                                variant="simpleBlack"
                                onClick={cancel}
                            >
                                {cancelLabel}
                            </NewDesignButton>
                        </ModalButtons>
                    </ModalContentBodyWrapper>
                </PrimaryModal>
            ) : (
                <PrimaryModal
                    atScreenCenter
                    showClose
                    isDialogue
                    onRequestClose={dismiss}
                    connected={false}
                    previousScrollDisabledState={false}
                >
                    <ModalContentBodyWrapper>
                        <Header marginBottom={20} fontSize={50}>
                            {title}
                        </Header>
                        <Description fontSize={14} lineHeight={1.5}>
                            {confirmation}
                        </Description>
                        <ModalButtons
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Button onClick={cancel}>{cancelLabel}</Button>
                            <Button variant="simpleBlack" onClick={proceed}>
                                {proceedLabel}
                            </Button>
                        </ModalButtons>
                    </ModalContentBodyWrapper>
                </PrimaryModal>
            ))}
    </ThemeProvider>
);

ConfirmationModal.propTypes = {
    proceedLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.string,
    newDesign: PropTypes.bool,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    cancel: PropTypes.func, // called when cancel button is clicked.
    dismiss: PropTypes.func // called when backdrop is clicked or escaped.
};

export default confirmable(ConfirmationModal);
