import { combineActions, createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import {
    FETCH,
    FETCH_ERROR,
    FETCH_PENDING,
    FETCH_RESET,
    FETCH_SUCCESS
} from 'middlewares/fetch';

const MEMBERSHIP_APPLY = 'MEMBERSHIP_APPLY';
const MEMBERSHIP_GET_MY_APPLICATIONS = 'MEMBERSHIP_GET_MY_APPLICATIONS';
const MEMBERSHIP_SUBMIT_FORM = 'MEMBERSHIP_SUBMIT_FORM';
const MEMBERSHIP_FETCH_FORM = 'MEMBERSHIP_FETCH_FORM';
const MEMBERSHIP_SUBMIT_CONFIG = 'MEMBERSHIP_SUBMIT_CONFIG';
const MEMBERSHIP_FETCH_CONFIG = 'MEMBERSHIP_FETCH_CONFIG';
const MEMBERSHIP_SET_CURRENT_GROUP = 'MEMBERSHIP_SET_CURRENT_GROUP';
const MEMBERSHIP_SET_DISPLAY = 'MEMBERSHIP_SET_DISPLAY';
const MEMBERSHIP_SEARCH = 'MEMBERSHIP_SEARCH';
const MEMBERSHIP_PAY = 'MEMBERSHIP_PAY';
const SET_MEMBERSHIP_STATUS = 'SET_MEMBERSHIP_STATUS';
const GET_MEMBERSHIP_STATUS = 'GET_MEMBERSHIP_STATUS';
const UPDATE_MEMBERSHUP_CONFIG = 'UPDATE_MEMBERSHIP_CONFIG';
const MEMBERSHIP_EXISTS = 'MEMBERSHIP_EXISTS';
const GET_MEMBERSHIP_VISIT_TOKEN = 'GET_MEMBERSHIP_VISIT_TOKEN';
const GET_MEMBERSHIP_RECYCLE_TOKEN = 'GET_MEMBERSHIP_RECYCLE_TOKEN';
const CHECK_MEMBERSHIP_VISIT_TOKEN = 'CHECK_MEMBERSHIP_VISIT_TOKEN';
const CHECK_MEMBERSHIP_RECYCLE_TOKEN = 'CHECK_MEMBERSHIP_RECYCLE_TOKEN';
const MEMBERSHIP_SET_MENTOR = 'MEMBERSHIP_SET_MENTOR';
const CHECK_MEMBERSHIP_INVITE_CODE = 'CHECK_MEMBERSHIP_INVITE_CODE';
const RSVP_MEMBERSHIP_INVITE = 'RSVP_MEMBERSHIP_INVITE';
const MEMBERSHIP_REFERRAL_CODE_SUBMIT = 'MEMBERSHIP_REFERRAL_CODE_SUBMIT';
const MEMBERSHIP_MINT_TOKENS = 'MEMBERSHIP_MINT_TOKENS';
const SET_RETURNING_MEMBER = 'SET_RETURNING_MEMBER';

export const setCurrentGroup = createAction(MEMBERSHIP_SET_CURRENT_GROUP);
export const setDisplayMembershipPage = createAction(MEMBERSHIP_SET_DISPLAY);

// const myPassesReady = createAction(MY_PASSES_READY);

const fetchMyApplications = createAction(FETCH, () => ({
    prefix: MEMBERSHIP_GET_MY_APPLICATIONS,
    endpoint: `${CONFIG.API_URL}/membership/apply`
}));

const applyToMembership = createAction(FETCH, application => ({
    prefix: MEMBERSHIP_APPLY,
    endpoint: `${CONFIG.API_URL}/membership/apply`,
    options: {
        method: 'POST',
        body: JSON.stringify(application)
    }
}));

export const submitIntakeForm = createAction(FETCH, request => ({
    prefix: MEMBERSHIP_SUBMIT_FORM,
    endpoint: `${CONFIG.API_URL}/membership/form`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

export const fetchIntakeForm = createAction(FETCH, groupNumber => ({
    prefix: MEMBERSHIP_FETCH_FORM,
    endpoint: `${CONFIG.API_URL}/membership/form/${groupNumber}`,
    options: {
        method: 'GET'
    }
}));

export const submitMemberConfiguration = createAction(FETCH, request => ({
    prefix: MEMBERSHIP_SUBMIT_CONFIG,
    endpoint: `${CONFIG.API_URL}/membership/config`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

export const fetchMemberConfiguration = createAction(FETCH, groupNumber => ({
    prefix: MEMBERSHIP_FETCH_CONFIG,
    endpoint: `${CONFIG.API_URL}/membership/config/${groupNumber}`,
    options: {
        method: 'GET'
    }
}));

export const searchMembers = createAction(FETCH, groupNumber => ({
    prefix: MEMBERSHIP_SEARCH,
    endpoint: `${CONFIG.API_URL}/membership/search?group=${encodeURIComponent(
        groupNumber
    )}`,
    options: {
        method: 'GET'
    }
}));

export const payMembership = createAction(FETCH, paymentInformation => ({
    prefix: MEMBERSHIP_PAY,
    endpoint: `${CONFIG.API_URL}/membership/payment`,
    options: {
        method: 'POST',
        body: JSON.stringify({ creditCardData: paymentInformation })
    }
}));

export const setMembershipStatus = createAction(FETCH, (id, status) => ({
    prefix: SET_MEMBERSHIP_STATUS,
    endpoint: `${CONFIG.API_URL}/membership/status`,
    options: {
        method: 'POST',
        body: JSON.stringify({ id, status })
    }
}));

export const getMyStatus = createAction(FETCH, group => ({
    prefix: GET_MEMBERSHIP_STATUS,
    endpoint: `${CONFIG.API_URL}/membership/status?group=${encodeURIComponent(
        group
    )}`,
    options: {
        method: 'GET'
    }
}));

export const updateMemberConfiguration = createAction(FETCH, (id, config) => ({
    prefix: UPDATE_MEMBERSHUP_CONFIG,
    endpoint: `${CONFIG.API_URL}/membership/configuration`,
    options: {
        method: 'POST',
        body: JSON.stringify({ id, ...config })
    }
}));

export const checkMemberExists = createAction(FETCH, currentGroup => ({
    prefix: MEMBERSHIP_EXISTS,
    endpoint: `${CONFIG.API_URL}/membership/exists?group=${encodeURIComponent(
        currentGroup
    )}`,
    options: {
        method: 'GET'
    }
}));

export const getVisitQRToken = createAction(FETCH, () => ({
    prefix: GET_MEMBERSHIP_VISIT_TOKEN,
    endpoint: `${CONFIG.API_URL}/membership/visit-qr`,
    options: {
        method: 'GET'
    }
}));

export const getRecycleQRToken = createAction(FETCH, () => ({
    prefix: GET_MEMBERSHIP_RECYCLE_TOKEN,
    endpoint: `${CONFIG.API_URL}/membership/recycle-qr`,
    options: {
        method: 'GET'
    }
}));

export const checkVisitQRToken = createAction(FETCH, token => ({
    prefix: CHECK_MEMBERSHIP_VISIT_TOKEN,
    endpoint: `${CONFIG.API_URL}/membership/visit-qr`,
    options: {
        method: 'POST',
        body: JSON.stringify({ token })
    }
}));

export const checkRecycleQRToken = createAction(FETCH, token => ({
    prefix: CHECK_MEMBERSHIP_RECYCLE_TOKEN,
    endpoint: `${CONFIG.API_URL}/membership/recycle-qr`,
    options: {
        method: 'POST',
        body: JSON.stringify({ token })
    }
}));

export const setMemberMentor = createAction(
    FETCH,
    (id, mentorId, mentorChatId) => ({
        prefix: MEMBERSHIP_SET_MENTOR,
        endpoint: `${CONFIG.API_URL}/membership/set-mentor`,
        options: {
            method: 'POST',
            body: JSON.stringify({ id, mentorId, mentorChatId })
        }
    })
);

export const checkInviteCode = createAction(FETCH, code => ({
    prefix: CHECK_MEMBERSHIP_INVITE_CODE,
    endpoint: `${CONFIG.API_URL}/membership/invite/check`,
    options: {
        method: 'POST',
        body: JSON.stringify({ code })
    }
}));

export const rsvpInvite = createAction(FETCH, code => ({
    prefix: RSVP_MEMBERSHIP_INVITE,
    endpoint: `${CONFIG.API_URL}/membership/invite/rsvp`,
    options: {
        method: 'POST',
        body: JSON.stringify({ code })
    }
}));

export const submitReferralCode = createAction(
    FETCH,
    (appointmentId, code) => ({
        prefix: MEMBERSHIP_REFERRAL_CODE_SUBMIT,
        endpoint: `${CONFIG.API_URL}/membership/referral/submit`,
        options: {
            method: 'POST',
            body: JSON.stringify({ appointmentId, code })
        }
    })
);

// export const mintTokens = createAction(
//     FETCH,
//     (id, petals, reason) => ({
//         prefix: MEMBERSHIP_MINT_TOKENS,
//         endpoint: `${CONFIG.API_URL}/membership/mint`,
//         options: {
//             method: 'POST',
//             body: JSON.stringify({ id, petals, reason })
//         }
//     })
// );

export const triggerTokenCampaign = createAction(FETCH, (id, campaign) => ({
    prefix: MEMBERSHIP_MINT_TOKENS,
    endpoint: `${CONFIG.API_URL}/membership/trigger-campaign`,
    options: {
        method: 'POST',
        body: JSON.stringify({ id, campaign })
    }
}));

export const setReturningMember = createAction(
    FETCH,
    (id) => ({
        prefix: SET_RETURNING_MEMBER,
        endpoint: `${CONFIG.API_URL}/membership/returning-member`,
        options: {
            method: 'POST',
            body: JSON.stringify({ id })
        }
    })
);

export const getMyApplications = () => {
    return dispatch => {
        return dispatch(fetchMyApplications());
    };
};

export const postApplication = applicationData => {
    return dispatch => {
        return dispatch(applyToMembership(applicationData));
    };
};

export const submitMembershipPayment = paymentInformation => {
    return dispatch => {
        return dispatch(payMembership(paymentInformation));
    };
};

const initialState = {
    isFetching: false,
    postFetching: false,
    error: null,
    applications: [],
    currentGroup: null,
    displayMembershipPage: false,
    members: [],
    submittingPayment: false
};

export default handleActions(
    {
        [`${MEMBERSHIP_APPLY}/${FETCH_PENDING}`]: state => ({
            ...state,
            error: null,
            postFetching: true
        }),
        [`${MEMBERSHIP_APPLY}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            postFetching: false,
            error: payload
        }),
        [`${MEMBERSHIP_APPLY}/${FETCH_SUCCESS}`]: state => ({
            ...state,
            error: null,
            postFetching: false
        }),
        [`${MEMBERSHIP_APPLY}/${FETCH_RESET}`]: state => ({
            ...state,
            error: null,
            postFetching: false
        }),
        [`${MEMBERSHIP_GET_MY_APPLICATIONS}/${FETCH_SUCCESS}`]: (
            state,
            { payload }
        ) => ({
            ...state,
            isFetching: false,
            applications: payload
        }),
        [combineActions(
            `${MEMBERSHIP_GET_MY_APPLICATIONS}/${FETCH_PENDING}`,
            `${MEMBERSHIP_SUBMIT_FORM}/${FETCH_PENDING}`,
            `${MEMBERSHIP_FETCH_FORM}/${FETCH_PENDING}`,
            `${MEMBERSHIP_SUBMIT_CONFIG}/${FETCH_PENDING}`,
            `${MEMBERSHIP_FETCH_CONFIG}/${FETCH_PENDING}`,
            `${MEMBERSHIP_SEARCH}/${FETCH_PENDING}`,
            `${GET_MEMBERSHIP_STATUS}/${FETCH_PENDING}`,
            `${MEMBERSHIP_EXISTS}/${FETCH_PENDING}`,
            `${GET_MEMBERSHIP_VISIT_TOKEN}/${FETCH_PENDING}`,
            `${GET_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_PENDING}`,
            `${CHECK_MEMBERSHIP_VISIT_TOKEN}/${FETCH_PENDING}`,
            `${CHECK_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_PENDING}`,
            `${MEMBERSHIP_SET_MENTOR}/${FETCH_PENDING}`,
            `${CHECK_MEMBERSHIP_INVITE_CODE}/${FETCH_PENDING}`,
            `${RSVP_MEMBERSHIP_INVITE}/${FETCH_PENDING}`,
            `${MEMBERSHIP_REFERRAL_CODE_SUBMIT}/${FETCH_PENDING}`,
            `${MEMBERSHIP_MINT_TOKENS}/${FETCH_PENDING}`,
            `${SET_RETURNING_MEMBER}/${FETCH_PENDING}`
        )]: state => ({
            ...state,
            error: null,
            isFetching: true
        }),
        [combineActions(
            `${MEMBERSHIP_GET_MY_APPLICATIONS}/${FETCH_ERROR}`,
            `${MEMBERSHIP_SUBMIT_FORM}/${FETCH_ERROR}`,
            `${MEMBERSHIP_FETCH_FORM}/${FETCH_ERROR}`,
            `${MEMBERSHIP_SUBMIT_CONFIG}/${FETCH_ERROR}`,
            `${MEMBERSHIP_FETCH_CONFIG}/${FETCH_ERROR}`,
            `${MEMBERSHIP_SEARCH}/${FETCH_ERROR}`,
            `${GET_MEMBERSHIP_STATUS}/${FETCH_ERROR}`,
            `${MEMBERSHIP_EXISTS}/${FETCH_ERROR}`,
            `${GET_MEMBERSHIP_VISIT_TOKEN}/${FETCH_ERROR}`,
            `${GET_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_ERROR}`,
            `${CHECK_MEMBERSHIP_VISIT_TOKEN}/${FETCH_ERROR}`,
            `${CHECK_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_ERROR}`,
            `${MEMBERSHIP_SET_MENTOR}/${FETCH_ERROR}`,
            `${CHECK_MEMBERSHIP_INVITE_CODE}/${FETCH_ERROR}`,
            `${RSVP_MEMBERSHIP_INVITE}/${FETCH_ERROR}`,
            `${MEMBERSHIP_REFERRAL_CODE_SUBMIT}/${FETCH_ERROR}`,
            `${MEMBERSHIP_MINT_TOKENS}/${FETCH_ERROR}`,
            `${SET_RETURNING_MEMBER}/${FETCH_ERROR}`
        )]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            error: payload
        }),
        [combineActions(
            `${MEMBERSHIP_SUBMIT_FORM}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_FETCH_FORM}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_SUBMIT_CONFIG}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_FETCH_CONFIG}/${FETCH_SUCCESS}`,
            `${GET_MEMBERSHIP_STATUS}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_EXISTS}/${FETCH_SUCCESS}`,
            `${GET_MEMBERSHIP_VISIT_TOKEN}/${FETCH_SUCCESS}`,
            `${GET_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_SUCCESS}`,
            `${CHECK_MEMBERSHIP_VISIT_TOKEN}/${FETCH_SUCCESS}`,
            `${CHECK_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_SET_MENTOR}/${FETCH_SUCCESS}`,
            `${CHECK_MEMBERSHIP_INVITE_CODE}/${FETCH_SUCCESS}`,
            `${RSVP_MEMBERSHIP_INVITE}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_REFERRAL_CODE_SUBMIT}/${FETCH_SUCCESS}`,
            `${MEMBERSHIP_MINT_TOKENS}/${FETCH_SUCCESS}`,
            `${SET_RETURNING_MEMBER}/${FETCH_SUCCESS}`
        )]: state => ({
            ...state,
            isFetching: false
        }),
        [combineActions(
            `${MEMBERSHIP_GET_MY_APPLICATIONS}/${FETCH_RESET}`,
            `${MEMBERSHIP_SUBMIT_FORM}/${FETCH_RESET}`,
            `${MEMBERSHIP_FETCH_FORM}/${FETCH_RESET}`,
            `${MEMBERSHIP_SUBMIT_CONFIG}/${FETCH_RESET}`,
            `${MEMBERSHIP_FETCH_CONFIG}/${FETCH_RESET}`,
            `${MEMBERSHIP_SEARCH}/${FETCH_RESET}`,
            `${GET_MEMBERSHIP_STATUS}/${FETCH_RESET}`,
            `${MEMBERSHIP_EXISTS}/${FETCH_RESET}`,
            `${GET_MEMBERSHIP_VISIT_TOKEN}/${FETCH_RESET}`,
            `${GET_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_RESET}`,
            `${CHECK_MEMBERSHIP_VISIT_TOKEN}/${FETCH_RESET}`,
            `${CHECK_MEMBERSHIP_RECYCLE_TOKEN}/${FETCH_RESET}`,
            `${MEMBERSHIP_SET_MENTOR}/${FETCH_RESET}`,
            `${CHECK_MEMBERSHIP_INVITE_CODE}/${FETCH_RESET}`,
            `${RSVP_MEMBERSHIP_INVITE}/${FETCH_RESET}`,
            `${MEMBERSHIP_REFERRAL_CODE_SUBMIT}/${FETCH_RESET}`,
            `${MEMBERSHIP_MINT_TOKENS}/${FETCH_RESET}`,
            `${SET_RETURNING_MEMBER}/${FETCH_RESET}`
        )]: state => ({
            ...state,
            error: null,
            isFetching: false
        }),
        [MEMBERSHIP_SET_CURRENT_GROUP]: (state, { payload }) => ({
            ...state,
            currentGroup: payload
        }),
        [MEMBERSHIP_SET_DISPLAY]: (state, { payload }) => ({
            ...state,
            displayMembershipPage: payload
        }),
        [`${MEMBERSHIP_SEARCH}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
            ...state,
            error: null,
            isFetching: false,
            members: payload.result
        }),
        [`${MEMBERSHIP_PAY}/${FETCH_PENDING}`]: state => ({
            ...state,
            submittingPayment: true
        }),
        [`${MEMBERSHIP_PAY}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            error: payload,
            submittingPayment: false
        }),
        [`${MEMBERSHIP_PAY}/${FETCH_SUCCESS}`]: state => ({
            ...state,
            error: null,
            submittingPayment: false
        }),
        [`${MEMBERSHIP_PAY}/${FETCH_RESET}`]: state => ({
            ...state,
            error: null,
            submittingPayment: false
        })
    },
    initialState
);
