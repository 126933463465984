import React, { Component } from 'react';
import { rem } from 'core/styled/index';
import styled  from 'styled-components';
import { Flex } from 'components/GlobalComponents/FlexBox';
import SubSection from './SubSection';
import TextSection from 'components/BeautyPlans/Staff/TextSection';
import StaffQuestions from 'components/BeautyPlans/Staff/StaffQuestions';
import { BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS } from 'constants/beautyplan';

const CategoryContainer = styled.div`
  padding-top: ${rem(40)};
  
  :nth-child(2) {
    padding-top: 0;
  }
`;

export default class Makeup extends Component {

    stepConfig = BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE];

    /**
     * Render the categories for choosing products.
     * @returns {object[]} - Rendered categories with subsections
     */
    renderCategories = () => {

        return this.stepConfig.categories.map((category, key) => {
            return (
                <CategoryContainer key={key}>

                    { this.renderSubSections(category.subSections, category) }

                </CategoryContainer>
            );
        });
    };

    /**
     * Render the free-form text areas for the step.
     *
     * @returns {object[]} - Rendered text sections
     */
    renderTextSections = () => {
        return this.stepConfig.textSections.map((section, key) => {
            return (
                <TextSection sectionId={this.stepConfig.id} section={section} key={key} />
            );
        });
    };

    /**
     * Render the subsections for a category.
     * @param {object[]} subSections
     * @param {object} category
     * @returns {object[]} - Rendered subsections
     */
    renderSubSections = (subSections, category) => {

        return subSections.map((subSection, key) => {
            return (
                <SubSection id={this.stepConfig.id}
                    category={category}
                    subSection={subSection}
                    key={key}/>
            );
        });
    };

    renderStaffFacingQuestions = () => {
        const staffQuestions = this.stepConfig.staffQuestions;

        if (!staffQuestions || !staffQuestions.length) {
            return;
        }

        return staffQuestions.map((question, key) => {
            return (
                <StaffQuestions
                    question={question}
                    key={key}
                />
            );
        });
    };

    render() {
        return (
            <Flex flexDirection='column' justifyContent='center'>
                { this.renderStaffFacingQuestions() }
                { this.renderCategories() }
                { this.renderTextSections() }
            </Flex>
        );

    }

}
