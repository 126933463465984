import { createAction } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';

export const WELCOME = 'WELCOME';

export const fetchProfileWelcome = createAction(FETCH, () => ({
    prefix: WELCOME,
    endpoint: `${CONFIG.API_URL}/profiles/modules/welcome`
}));

export default createFetchReducer(WELCOME);
