import styled from 'styled-components';
import { boxStyle, fontStyle, styledProps } from 'core/styled';

const Text = styled.div`
    ${boxStyle};
    ${fontStyle};
    color: ${styledProps('color', 'adaGrey')};
`;

export default Text;
