import VIP from 'constants/vip';
import isProd from 'core/utils/isProd';

export const INTAKE_QUESTION_TYPE = {
    SINGLE_SELECT: 'SINGLE_SELECT',
    MULTI_SELECT: 'MULTI_SELECT',
    FREE_FORM: 'FREE_FORM'
};

export const isMyAtelierMember = vip => {
    return (
        !!vip &&
        !!vip.status &&
        vip.status === VIP.MY_ATELIER.STRING &&
        vip.end > new Date().getTime()
    );
};

export const MEMBERSHIP_STATUS = {
    APPLIED: 'applied',
    REVIEWED: 'reviewed',
    ACCEPTED: 'accepted',
    PAID: 'paid',
    MEMBER: 'member',
    ENDED: 'ended',
    REJECTED: 'rejected',
    REVOKED: 'revoked'
};

export const MEMBERS_LEVEL = {
    RED: 'RED',
    WHITE: 'WHITE',
    GOLD: 'GOLD'
};

export const normalizeMyActivityResponse = payload => ({
    beautyServices: payload.beautyServices || 0,
    events: payload.events || 0,
    friendPasses: payload.friendPasses || 0,
    privateEvent: !!payload.privateEvent,
    welcomeKit: !!payload.welcomeKit,
    completeProfile: !!payload.completeProfile,
    introductoryConsultation: !!payload.introductoryConsultation,
    rsvpWelcome: !!payload.rsvpWelcome,
    fragranceDiscovery: !!payload.fragranceDiscovery,
    skinConsultation: !!payload.skinConsultation,
    makeupApplication: !!payload.makeupApplication,
    makeupLesson: !!payload.makeupLesson,
    recycle: !!payload.recycle,
    poll: !!payload.poll
});

export const memberHubScrollTop = () => {
    document
        .getElementById('member-hub-top')
        .scrollIntoView({ behavior: 'smooth' });
};

export const CHAT_BASE_URL = 'https://atelierbeautechanel.rocket.chat';

export const getChatIframeURL = (param, mentorChatRoom) => {
    const prod = isProd();

    switch (param) {
        case 'announcements':
            return `${CHAT_BASE_URL}/group/${
                prod ? '' : 'QA-'
            }Announcements?layout=embedded`;
        case 'general-discussion':
            return `${CHAT_BASE_URL}/group/${
                prod ? '' : 'QA-'
            }General-Discussions?layout=embedded`;
        case 'favorite-products':
            return `${CHAT_BASE_URL}/group/${
                prod ? '' : 'QA-'
            }Fav-Beauty-Products?layout=embedded`;
        case 'beauty-mentor':
            return mentorChatRoom
                ? `${mentorChatRoom}?layout=embedded`
                : null;
        default:
            return null;
    }
};

export const getChatRoomName = (param) => {
    const prod = isProd();

    switch (param) {
        case 'announcements':
            return prod ? 'Announcements' : 'QA-Announcements';
        case 'general-discussion':
            return prod ? 'General-Discussions' : 'QA-General-Discussions';
        case 'favorite-products':
            return prod ? 'Fav-Beauty-Products' : 'QA-Fav-Beauty-Products';
        default:
            return null;
    }
};
