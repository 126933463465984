import { createAction, handleActions } from 'redux-actions';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { searchProductsBySkus, SEARCH } from 'reducers/elasticsearch';
import normalizeProduct from 'core/utils/normalizeProduct';
import { CONFIG } from 'constants/config';

const PAST_PURCHASES = 'PAST_PURCHASES';

const initialState = {
    fetchingProducts: false,
    fetchingPastPurchases: false,
    pastPurchases: [],
    productsData: [],
    hasErrors: false
};

const _getPastPurchases = createAction(FETCH, externalId => ({
    prefix: PAST_PURCHASES,
    endpoint: `${CONFIG.API_URL}/purchases/${externalId}`,
    options: {
        method: 'GET'
    }
}));

export const fetchPastPurchases = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        return dispatch(_getPastPurchases(externalId));
    };
};

/**
 * Fetch user's past purchases and if any, fetch product information
 * @return {function(*, *): *} Promise
 */
export const getPastPurchases = () => {
    return (dispatch) => {
        return dispatch(fetchPastPurchases()).then(({ transactions }) => {
            let productSkus;

            if (transactions) {
                productSkus = new Set(transactions.map(t => {
                    return t.items.map(i => i.sku ? i.sku : i.itemId);
                }).reduce((acc, val) => [...acc, ...val] , [])
                    .filter(v => v));
            }

            return productSkus && productSkus.size > 0
                ? dispatch(searchProductsBySkus([...productSkus], `${SEARCH}_${PAST_PURCHASES}`))
                : null;
        });
    };
};

export default handleActions(
    {
        [`${SEARCH}_${PAST_PURCHASES}/${FETCH_PENDING}`]: (state) => ({
            ...initialState,
            ...state,
            fetchingProducts: true
        }),
        [`${SEARCH}_${PAST_PURCHASES}/${FETCH_ERROR}`]: (state) => ({
            ...initialState,
            ...state,
            fetchingProducts: false
        }),
        [`${SEARCH}_${PAST_PURCHASES}/${FETCH_SUCCESS}`]: (state, { payload: { docs } }) => ({
            ...initialState,
            ...state,
            productsData: docs.map(normalizeProduct).filter(p => p),
            fetchingProducts: false
        }),
        [`${PAST_PURCHASES}/${FETCH_PENDING}`]: (state) => ({
            ...initialState,
            ...state,
            fetchingPastPurchases: true
        }),
        [`${PAST_PURCHASES}/${FETCH_SUCCESS}`]: (state, { payload: { errors, transactions } }) => ({
            ...initialState,
            ...state,
            hasErrors: errors.length > 0,
            pastPurchases: transactions ? transactions : [],
            fetchingPastPurchases: false
        }),
        [`${PAST_PURCHASES}/${FETCH_ERROR}`]: (state) => ({
            ...initialState,
            ...state,
            isFetching: false,
            hasErrors: true,
            pastPurchases: [],
            fetchingPastPurchases: false
        }),

    },
    initialState
);
