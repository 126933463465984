export const LAYER = {
    FOOTER_LINK: 4,
    BODY: 2,
    BODY_SCROLL_OVERLAY: 3,
    HEADER: 6,
    IMPERSONATION_HEADER: 7,
    FIXED_TABS: 5,
    MODAL: 20,
    BOX_BORDER_ARROW: 3,
    MAKEUP_ARTIST_QUOTE: 2,
    HAMBURGER_MENU: 9,
    HAMBURGER_MENU_OVERLAY: 8,
    FOOTER_NAV: 8
};
