import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrimaryModal from 'components/GlobalComponents/Modal/PrimaryModal';
import Description from 'components/GlobalComponents/Fonts/Description';
import Button from 'components/GlobalComponents/Button/Button';
import { rem } from 'core/styled';
import styled from 'styled-components';
import LazyLoadImage from 'components/GlobalComponents/LazyLoadImage/LazyLoadImage';
import Header from 'components/GlobalComponents/Fonts/Header';
import Flex from 'components/GlobalComponents/FlexBox/Flex';

const ProductThumbnail = styled(LazyLoadImage)`
    width: 60%;
    height: auto;
`;

const ModalContentBodyWrapper = styled.div`
    margin-top: ${rem(-20)};
    text-align: center;
    max-height: 75vh;
    overflow: visible;
`;

const StyledBackButton = styled(Button)`
    margin: 0 auto;
    min-width: 175px;
`;

const StyledDescription = styled(Description)`
  margin-top: ${rem(25)};
  font-size: ${rem(15)};
`;

const Confirmation = styled(Description)`
    font-size: ${rem(15)};
    line-height: 1.4;  
`;

const AddButton = styled(Button)`
    min-width: 230px;
`;

export default class SampleModal extends Component {

    static propTypes = {
        product: PropTypes.object.isRequired,
        userName: PropTypes.string.isRequired,
        addSample: PropTypes.func.isRequired
    };

    state = {
        isModalVisible: false,
        added: false
    };

    /**
     * Toggle for search modal display state
     */
    toggleModal() {
        const isVisible = this.state.isModalVisible;
        this.setState({
            isModalVisible: !isVisible,
        });

        // closing the modal, reset the added state
        if (isVisible) {
            this.setState({ added: false });
        }
    }

    _addSample = () => {
        const { addSample, product } = this.props;
        addSample(product).then(() => this.setState({ added: true }));
    };

    render() {
        const { added, isModalVisible } = this.state;
        const { product, userName } = this.props;

        if (!isModalVisible) {
            return null;
        }

        return (
            <PrimaryModal
                onRequestClose={() => this.toggleModal()}
                isDialogue
                showClose
            >
                <ModalContentBodyWrapper>
                    {!added &&
                    <div>
                        <StyledDescription>You are about to add</StyledDescription>
                        { isModalVisible && <ProductThumbnail
                            src={product.packshot.medium()}
                            alt={product.name}
                        /> }
                        <Header lineHeight={1}>
                            <span translate={'no'} className={'notranslate'}>{product.name}</span>
                        </Header>
                        <Confirmation padding={[15, 0, 25, 0]}>to {userName}&apos;s profile.</Confirmation>
                        <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} >
                            <AddButton onClick={this._addSample}>Add sample</AddButton>
                            <Button variant={'simpleGrey'} onClick={() => this.toggleModal()}>Cancel</Button>
                        </Flex>
                    </div>
                    }
                    { added &&
                    <div>
                        <Header padding={[30, 0, 15]}>Added!</Header>
                        <Confirmation padding={[0, 10, 30, 10]}>Product has been added to {userName}&apos;s profile.</Confirmation>
                        <Flex justifyContent={'center'} flexDirection={'column'} >
                            <StyledBackButton onClick={() => this.toggleModal()}>Done</StyledBackButton>
                        </Flex>
                    </div>
                    }
                </ModalContentBodyWrapper>

            </PrimaryModal>
        );
    }
}
