import { logoutUser } from 'reducers/user';
import { ROUTES } from 'constants/routes';
import { go } from 'redux-first-history';

export const UNAUTHORIZED = 'UNAUTHORIZED';

const auth = ({ dispatch, getState }) => next => action => {

    const { user: { auth: { authenticated } } } = getState();

    if (authenticated && action.type === UNAUTHORIZED) {
        dispatch(logoutUser(action.location));

        if (!action.location.startsWith(ROUTES.LOGIN)) {
            dispatch(go(0));
        }
    }

    next(action);
};

export default auth;
