import { createAction } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';

export const RESET_PASSWORD = 'RESET_PASSWORD';

export const resetPassword = createAction(
    FETCH,
    user => ({
        prefix: RESET_PASSWORD,
        endpoint: `${CONFIG.API_URL}/auth/passwords`,
        options: {
            method: 'POST',
            body: JSON.stringify(user)
        }
    })
);

export default createFetchReducer(RESET_PASSWORD);
