import { css, createGlobalStyle } from 'styled-components';
import { styledProps } from './props';
import { theme } from 'constants/theme';

// Global css styles applied to the whole page
export const GlobalStyle = createGlobalStyle`
html, body {
    margin: 0;
    padding: 0;
    font-family: ${theme.font.default};
    font-size: ${styledProps('global', 'rootFontSize')}px;
    color: ${styledProps('color', 'black')};
    max-width: none !important;
    width: 100%;
}

body {
    display: flex;
    // allow pinch and zoom, but disable double tap to zoom in (https://developer.mozilla.org/en-US/docs/Web/CSS/touch-action)
    touch-action: manipulation;
    
    // hide all iframes not in root to give better visual results to mobile bot crawlers
    :not(.root) iframe {
         height: 0;
         width: 0;
         border: none;
    }

    &.atelier-tabbing :focus {
        -moz-outile-radius: 2px;
        outline: Highlight auto 2px;
        outline: -webkit-focus-ring-color auto 1px;
        outline-color: black;
    }
}

#root {
    flex: 1;
}

* {
    box-sizing: border-box;
    &::before,
    &::after {
        box-sizing: border-box;
    }
    outline: none;
}

a {
  text-decoration: none;
  color: ${styledProps('color', 'black')};
}

button {
    color: ${styledProps('color', 'black')};
    border-color: ${styledProps('color', 'black')};
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
        display: inline-block;
    }
}

p {
    margin: 0;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}

`;

export const fontStyle = css`
    text-align: ${styledProps('textAlign')};
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.5px;
`;

export const inputStyle = css`
    color: ${styledProps('color', 'black')};
    height: 25px;
    background: none;
    border: none;
    padding: 2px 7px;
    font-family: ${styledProps('font', 'ChanelFolioMed')};
    font-size: 16px;
    font-weight: bold;
    &::placeholder {
        color: ${styledProps('color', 'adaGrey')};
        ${fontStyle};
    }
`;

export const boxStyle = css`
    padding: ${styledProps('padding')};
    padding-top: ${styledProps('paddingTop')};
    padding-right: ${styledProps('paddingRight')};
    padding-bottom: ${styledProps('paddingBottom')};
    padding-left: ${styledProps('paddingLeft')};
    margin: ${styledProps('margin')};
    margin-top: ${styledProps('marginTop')};
    margin-right: ${styledProps('marginRight')};
    margin-bottom: ${styledProps('marginBottom')};
    margin-left: ${styledProps('marginLeft')};
`;
