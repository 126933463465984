import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem, styledProps } from 'core/styled';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Icon from 'components/GlobalComponents/Icons/Icon';
import {
    ICON_TYPES,
    ICON_VARIANTS
} from 'components/GlobalComponents/Icons/IconVariants';
import { LAYER } from 'constants/layers';
import connect from 'react-redux/es/connect/connect';

const Wrapper = styled.footer`
    width: 100%;
    height: calc(${rem(65)} + env(safe-area-inset-bottom, 0px));
    background-color: ${styledProps('color', 'black')};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${LAYER.FOOTER_NAV};
    padding-bottom: env(safe-area-inset-bottom, 0px);
`;

const FooterItem = styled(Link)`
    width: ${rem(85)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${styledProps('color', 'white')};
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(8)};
    font-weight: bold;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0.6)};
    transition: opacity 0.4s ease;

    span {
        display: block;
        margin-top: ${rem(6)};
    }
`;

const ICON_HEIGHT = 20;

class QuickShopFooter extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        hasCartItems: PropTypes.bool.isRequired,
        hasLikedItems: PropTypes.bool.isRequired
    };

    render() {
        const {
            location: { pathname },
            hasCartItems,
            hasLikedItems
        } = this.props;

        return (
            <Wrapper>
                <FooterItem
                    to={ROUTES.QUICK_SHOP}
                    $isActive={
                        pathname === ROUTES.QUICK_SHOP ||
                        pathname.includes(ROUTES.QUICK_SHOP_PDP)
                    }
                >
                    <Icon
                        type={ICON_TYPES.SEARCH}
                        variant={ICON_VARIANTS.DARK}
                        pixelHeight={ICON_HEIGHT}
                    />
                    <span>SEARCH</span>
                </FooterItem>
                <FooterItem
                    to={ROUTES.QUICK_SHOP_FAVORITES}
                    $isActive={pathname === ROUTES.QUICK_SHOP_FAVORITES}
                >
                    <Icon
                        type={ICON_TYPES.STYLED_LIKE}
                        variant={ICON_VARIANTS.DARK}
                        pixelHeight={ICON_HEIGHT}
                        ignoreSelectedForDot
                        successPositive={hasLikedItems}
                        showAnimatedDot={hasLikedItems}
                    />
                    <span>FAVORITES</span>
                </FooterItem>
                <FooterItem
                    to={ROUTES.QUICK_SHOP_BAG}
                    $isActive={pathname === ROUTES.QUICK_SHOP_BAG}
                >
                    <Icon
                        type={ICON_TYPES.BAG}
                        variant={ICON_VARIANTS.DARK}
                        pixelHeight={ICON_HEIGHT}
                        ignoreSelectedForDot
                        successPositive={hasCartItems}
                        showAnimatedDot={hasCartItems}
                    />
                    <span>BAG</span>
                </FooterItem>
            </Wrapper>
        );
    }
}

const mapStateToProps = ({ cart: { items }, userList: { like } }) => ({
    hasCartItems: items && items.length > 0,
    hasLikedItems: like && like.length > 0
});

export default connect(mapStateToProps)(QuickShopFooter);
