import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styledProps, rem } from 'core/styled';
import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';

const TiltedTextWrapper = styled.div`
    position: relative;
    transform: rotate(${({ rotate }) => rotate}deg);
    ${({ display }) => display !== '' ? `display: ${display};` : ''}
`;

const TiltedTextParagraph = styled.p`
    position: relative;
    transform: rotate(${({ rotate }) => rotate}deg);
    ${({ display }) => display !== '' ? `display: ${display};` : ''}
`;

const TiltedTextBlock = styled.span`
    margin: ${({ textMargin }) => rem(...textMargin)};
    position: relative;
    z-index: 2;
    background: ${({ bgColor }) => styledProps('color', bgColor)};
    padding: ${({ textPadding }) => rem(...textPadding)};
    color: ${({ fontColor }) => styledProps('color', fontColor)};
    font-family: ${({ font }) => styledProps('font', font)};
    font-size: ${({ fontSize }) => rem(fontSize)};
    line-height: ${({ fontSize, lineHeight }) => lineHeight ? lineHeight : rem(fontSize * 2)}; // if editing line height multiplier check on real device.
    font-weight: ${({ fontWeight }) => fontWeight};
    text-align: center;
    box-decoration-break: clone;
    white-space: ${({ whiteSpace }) => whiteSpace ? whiteSpace : 'pre-line'};
    word-break: normal;
    max-width: 100%;
    
    ${greaterThan(breakpoints.small)(css`
        font-size: ${({ mediumScreensFontSize }) => rem(mediumScreensFontSize)}
    `)}
`;

export default class TiltedText extends Component {

    static propTypes = {
        children: PropTypes.any, // component content
        rotate: PropTypes.number, // rotate container with text
        bgColor: PropTypes.string, // set bg color for text block
        fontColor: PropTypes.string, // set font color
        font: PropTypes.string, // set font family
        fontSize: PropTypes.number, // set font size
        fontWeight: PropTypes.number, // set font wight
        textPadding: PropTypes.array, // set padding's for texts
        whiteSpace: PropTypes.string,
        innerHtml: PropTypes.any, // if set, set innerHtml
        mediumScreensFontSize: PropTypes.number,
        lineHeight: PropTypes.number,
        display: PropTypes.string,
        textMargin: PropTypes.array,
        forwardRef: PropTypes.object,
        asParagraph: PropTypes.bool
    };

    static defaultProps = {
        fontColor: 'white',
        bgColor: 'black',
        font: 'Chromosome',
        textPadding: [2, 10],
        fontSize: 14,
        fontWeight: 400,
        rotate: 0,
        display: '',
        textMargin: [0, 0, 0, 0],
        asParagraph: false
    };

    render() {
        const {
            children,
            rotate,
            bgColor,
            fontColor,
            font,
            fontSize,
            fontWeight,
            textPadding,
            whiteSpace,
            innerHtml,
            mediumScreensFontSize,
            lineHeight,
            display,
            textMargin,
            forwardRef,
            asParagraph
        } = this.props;

        const WrapperElement = asParagraph ? TiltedTextParagraph : TiltedTextWrapper;

        return (
            <WrapperElement
                rotate={rotate}
                display={display}
                ref={forwardRef}
                {...this.props}
            >
                { innerHtml &&
                    <TiltedTextBlock
                        bgColor={bgColor}
                        fontColor={fontColor}
                        font={font}
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        textPadding={textPadding}
                        textMargin={textMargin}
                        whiteSpace={whiteSpace}
                        mediumScreensFontSize={mediumScreensFontSize}
                        lineHeight={lineHeight}
                        dangerouslySetInnerHTML={{ __html: innerHtml }}
                    />
                }
                { !innerHtml &&
                    <TiltedTextBlock
                        bgColor={bgColor}
                        fontColor={fontColor}
                        font={font}
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                        textPadding={textPadding}
                        textMargin={textMargin}
                        whiteSpace={whiteSpace}
                        mediumScreensFontSize={mediumScreensFontSize}
                        lineHeight={lineHeight}
                    >
                        {children}
                    </TiltedTextBlock>
                }
            </WrapperElement>
        );
    }

}
