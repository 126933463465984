import { createAction, handleActions } from 'redux-actions';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import { SEARCH } from 'constants/search';
import { searchProducts } from 'reducers/elasticsearch';

// Setting default zone to make testing easier
const initialState = { items: [] };

export const setZones = createAction('SET_ZONES');

export const getZones = () => {
    return (dispatch) => {
        const request = {
            bodyRequest: elasticSearchQueries.getAllZones()
        };

        const endpoint = SEARCH.URL.DEFAULT;

        return dispatch(searchProducts({ endpoint, request }))
            .then(results => dispatch(setZones(results.aggregations.tags.buckets.map(zone => zone.key))));
    };
};

export default handleActions(
    {
        [setZones]: (state, { payload }) => ({ items: payload }),
    },
    initialState
);
