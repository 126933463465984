import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import { styledProps, rem } from 'core/styled';
import scrollToTop from 'core/utils/scrollToTop';
import BTTArrow from 'assets/btt-button-arrow.svg';

const StyledButton = styled.button`
    color: ${styledProps('color', 'black')};
    background: none;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    margin: ${({ margin }) => margin ? rem(...margin) : `${rem(40)} auto`};
    text-transform: uppercase;
    border: none;
    font-size: ${rem(9)};
    display: block;
    font-weight: 600;
    outline: none;
    position: relative;
    cursor: pointer;
    
    ${greaterThan(breakpoints.small)(css`
        font-size: ${rem(12)};
    `)}
`;


export default class BackToTopButton extends Component {

    static propTypes = {
        margin: PropTypes.array
    };

    render() {
        return (
            <StyledButton margin={this.props.margin} type={'button'} onClick={() => scrollToTop()}>
                <img src={BTTArrow} alt={''} aria-hidden={true} role={'img'} /><br />
                Back to top
            </StyledButton>
        );
    }
}
