import React, { Component } from 'react';
import { styledProps, rem, greaterThan } from 'core/styled';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { LAYER } from 'constants/layers';
import { FOOTER_NAV } from './footerNavData';
import InternalLink from 'components/GlobalComponents/InternalLink/InternalLink';
import { breakpoints } from 'constants/theme';
import { EVENTS } from 'constants/analytics';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { analyticsTag } from 'reducers/analytics';

const FooterNavWrapper = styled.nav`
    background-color: ${styledProps('color', 'black')};
    color: white;
    height: calc(${rem(50)} + env(safe-area-inset-bottom, 0px));
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: ${LAYER.FOOTER_NAV};
    transition: height 0.2s ease-in;
    
    ${greaterThan(breakpoints.small)(css`
        display: none;
    `)}
`;

const FooterUL = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

const FooterLI = styled.li`
    display: flex;
    flex: 1 0 20%;
    margin: ${rem(5)};
    justify-content: center;
    
    img {
        max-width: 40px;
    }
`;

const eventAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'footer'
};

class FooterNav extends Component {

    static propTypes = {
        shrink: PropTypes.func,
        analyticsTag: PropTypes.func
    };

    footerLinkClick = (analyticsAction) => () => {
        this.props.analyticsTag({
            ...eventAnalytics,
            eventAction: analyticsAction
        }, { userInfo: true });
    };

    render() {
        const { shrink } = this.props;

        return (
            <FooterNavWrapper shrink={shrink} role={'navigation'}>
                <FooterUL>
                    {
                        FOOTER_NAV.map((item, index) => {
                            return (
                                <FooterLI key={index}>
                                    <InternalLink to={item.link} onClick={this.footerLinkClick(item.analyticsAction)}>
                                        <img src={item.image} alt={item.alt}/>
                                    </InternalLink>
                                </FooterLI>
                            );
                        })
                    }

                </FooterUL>
            </FooterNavWrapper>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ analyticsTag }, dispatch);

export default connect(null, mapDispatchToProps)(FooterNav);
