import moment from 'moment-timezone';

export const normalizePayments = payments => {
    return payments.map(payment => {
        const transactionDateTime = moment(payment.TransactionDate).tz('America/New_York');

        return {
            name: `${payment.FirstName} ${payment.LastName}`,
            email: payment.EmailAddress,
            amountPaid: payment.Amount,
            transactionDate: transactionDateTime.format('MM/DD/YYYY'),
            transactionTime: transactionDateTime.format('hh:mm A'),
            serviceName: payment.Name
        };
    });
};

const normalizeTransactions = transactions => {
    return transactions.map(transaction => {
        const transactionDateTime = moment(transaction.transactionDate).tz('America/New_York');
        const serviceDateTime = transaction.eventStartDateTime ?
            moment(transaction.eventStartDateTime).tz('America/New_York') :
            undefined;

        return {
            transactionId: transaction.txId,
            name: transaction.firstName && transaction.lastName ? `${transaction.firstName} ${transaction.lastName}` : 'Guest User',
            email: transaction.emailAddress,
            amountPaid: transaction.amount,
            transactionDate: transactionDateTime.format('MM/DD/YYYY'),
            transactionTime: transactionDateTime.format('hh:mm A'),
            serviceName: transaction.name,
            serviceId: transaction.eventId,
            serviceDate: serviceDateTime ? serviceDateTime.format('MM/DD/YYYY') : undefined,
            serviceTime: serviceDateTime ? serviceDateTime.format('hh:mm A') : undefined,
            serviceVisitIds: transaction.visitIds,
            serviceStaffName: transaction.staffName,
            refunded: transaction.refunded,
            amountRefunded: transaction.RefundAmount
        };
    });
};

export default normalizeTransactions;
