import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import styled, { css } from 'styled-components';
import { lessThan, rem } from 'core/styled';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import ProductCard from 'components/ProductComponents/ProductCard/ProductCard';
import Loader from 'components/GlobalComponents/Loader/Loader';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import BackToTopButton from 'components/GlobalComponents/BackToTopButton/BackToTopButton';
import MaxWidthWrapper from 'components/GlobalComponents/MaxWidthWrapper/MaxWidthWrapper';
import { analyticsTag, getPageType } from 'reducers/analytics';
import CustomerSearchModal from 'components/CustomerSearch/CustomerSearchModal';
import { EVENTS } from 'constants/analytics';

const ProductCardWrapper = styled(Box)`

    ${greaterThan(breakpoints.small)(css`
        width: ${({ mediumColumnWidth }) => mediumColumnWidth };
    `)};
    
    ${greaterThan(breakpoints.medium)(css`
        width: ${({ largeColumnWidth }) => largeColumnWidth };
    `)};

    ${greaterThan(breakpoints.large)(css`
        width: ${({ giantColumnWidth }) => giantColumnWidth };
    `)};
    
    ${lessThan(breakpoints.tiny)(css`
        padding: ${rem(20, 10)};
    `)}
`;

class ProductListing extends Component {

    static propTypes = {
        products: PropTypes.array.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        isStaff: PropTypes.bool.isRequired,
        response: PropTypes.object,
        isFetching: PropTypes.bool,
        onProductSelect: PropTypes.func,
        overridePDPRedirect: PropTypes.bool,
        renderLikeListButtons: PropTypes.bool,
        renderVTOButton: PropTypes.bool,
        renderProductRemoveButton: PropTypes.bool,
        withShades: PropTypes.bool,
        backToTop: PropTypes.bool,
        preventOrderChange: PropTypes.bool,
        cardPadding: PropTypes.array,
        mediumColumnWidth: PropTypes.string,
        largeColumnWidth: PropTypes.string,
        giantColumnWidth: PropTypes.string,
        id: PropTypes.string,
        favouriteListing: PropTypes.bool,
        favouriteMeetingId: PropTypes.string,
        isFragranceFlight: PropTypes.bool
    };

    static defaultProps = {
        withShades: true,
        backToTop: true,
        cardPadding: [20, 0],
        mediumColumnWidth: '50%',
        largeColumnWidth: '33.3%',
        giantColumnWidth: '33.3%',
        favouriteListing: false,
        renderVTOButton: false,
        isFragranceFlight: false
    };

    state = {
        likeListProducts: []
    };


    constructor(props) {
        super(props);
        this.customerModal = React.createRef();
    }

    componentDidMount() {
        const { location, analyticsTag } = this.props;

        const pageType = getPageType(location);
        // ProductListing is used for plp, search-results and pdp cross-sells
        // only tag this page load when it's plp
        if (pageType === 'plp') {
            analyticsTag({
                page_type: 'plp',
            }, { userInfo: true });
        }
    }

    analytics = (label) => {
        this.props.analyticsTag({
            event: EVENTS.GA,
            eventCategory: 'staffprofile',
            eventAction: 'atelier',
            eventLabel: label
        }, { userInfo: true });
    };

    /**
     * Render the product card
     * @param {object} products
     * @returns {Component} React component
     */
    renderProductCards(products) {
        const {
            overridePDPRedirect,
            renderLikeListButtons,
            onProductSelect,
            renderProductRemoveButton,
            withShades,
            preventOrderChange,
            cardPadding,
            mediumColumnWidth,
            largeColumnWidth,
            giantColumnWidth,
            favouriteListing,
            favouriteMeetingId,
            renderVTOButton,
            isFragranceFlight
        } = this.props;

        return products.map((results, key) => {

            return (
                <ProductCardWrapper
                    mediumColumnWidth={mediumColumnWidth}
                    largeColumnWidth={largeColumnWidth}
                    giantColumnWidth={giantColumnWidth}
                    padding={rem(...cardPadding)}
                    key={key} width={'initial'}>
                    <ProductCard
                        key={key}
                        isEven={key % 2 === 0}
                        overridePDPRedirect={overridePDPRedirect}
                        renderLikeListButtons={renderLikeListButtons}
                        renderProductRemoveButton={renderProductRemoveButton}
                        onProductSelect={onProductSelect}
                        products={withShades ? results : [results]}
                        browseAs={this._showCustomerSearchModal}
                        withShades={withShades}
                        preventOrderChange={preventOrderChange}
                        showFavouriteButton={renderLikeListButtons && favouriteListing}
                        favouriteMeetingId={favouriteMeetingId}
                        renderVTOButton={renderVTOButton}
                        isFragranceFlight={isFragranceFlight}
                    />
                </ProductCardWrapper>
            );
        });
    }

    _showCustomerSearchModal = () => {
        this.customerModal.current.toggleModal();
    };

    _renderCustomerSearchModal = () => {
        const { isStaff } = this.props;

        if (!isStaff) {
            return null;
        }

        return (
            <CustomerSearchModal ref={this.customerModal} tag={this.analytics}/>
        );
    };

    render() {
        const { isFetching, products, backToTop, id } = this.props;

        if (isFetching) {
            return <Loader fullScreen />;
        }

        return (
            <MaxWidthWrapper id={id ? id : null}>
                <Flex
                    backgroundColor={this.props.backgroundColor}
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                >
                    {this.renderProductCards(products)}
                    { this._renderCustomerSearchModal() }
                </Flex>
                { backToTop && <BackToTopButton /> }
            </MaxWidthWrapper>
        );
    }
}

const mapStateToProps = ({ globalLayout: { backgroundColor }, user: { auth }, router: { location } }) => ({
    backgroundColor,
    ...auth,
    location
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ push, analyticsTag }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
    ProductListing
);
