import eventsImage from './images/events.png';
import profileImage from './images/profile.png';
import productsImage from './images/products.png';
import servicesImage from './images/services.png';
import { ROUTES } from 'constants/routes';
import { DISCOVER_TABS } from 'constants/discover';

export const FOOTER_NAV = [
    {
        link: ROUTES.PRODUCTS,
        image: productsImage,
        analyticsAction: 'products',
        alt: 'products'
    },
    {
        link: `${ROUTES.DISCOVER}/${DISCOVER_TABS.CALENDAR}`,
        image: eventsImage,
        analyticsAction: 'events',
        alt: 'events'
    },
    {
        link: `${ROUTES.DISCOVER}/${DISCOVER_TABS.SERVICES}`,
        image: servicesImage,
        analyticsAction: 'services',
        alt: 'services'
    },
    {
        link: ROUTES.PROFILE,
        image: profileImage,
        analyticsAction: 'profile',
        alt: 'profile'
    }
];
