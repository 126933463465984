import styled from 'styled-components';
import { AccordionItemHeading } from 'react-accessible-accordion';
import { rem } from 'core/styled';

const StyledAccordionItemHeadingWrapper = styled(AccordionItemHeading)`
      margin: 0 ${rem(30)};
      position: relative;
`;

export default StyledAccordionItemHeadingWrapper;
