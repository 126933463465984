import { IGNORE_BROWSABLE_FLAG, ZONES } from 'constants/zones';

// Fixme: Ideally groupings would come from config somewhere
const COLORS = ['Nude', 'Pink', 'Plum/Berry', 'Red', 'Coral'];


const BASE_FILTER = [
    {
        term: {
            'active.site': true
        }
    },
    {
        term: {
            'active.pcm': true
        }
    },
    {
        range: {
            'activationDate': {
                lte: 'now',
                gt: '1970-01-01T00:00:00.000Z'
            }
        }
    }
];

const SEARCH_FILTERS = BASE_FILTER.concat([
    {
        term: {
            'searchable': true
        }
    }
]);

const BROWSE_FILTERS = BASE_FILTER.concat([
    {
        term: {
            'browsable': true
        }
    }
]);

const SEARCH_AND_BROWSE_SHOULD_FILTERS = [
    {
        term: {
            'searchable': true
        }
    },
    {
        term: {
            'browsable': true
        }
    }
];

const RANGE_QUERY_NEW_PRODUCTS = {
    range: {
        beginLife: {
            gte: 'now-180d/d',
            time_zone: '-04:00'
        }
    }
};

/**
 * If there is only 1 item in a query, we make that single item
 * a must. If there are many we keep the should clause between each item.
 *
 * @param {object} query
 * @returns {*} query
 */
const simplifyQuery = query => {
    if (query.bool.should.length === 0) {
        return undefined;
    } else if (query.bool.should.length === 1) {
        return query.bool.should[0];
    } else {
        return query;
    }
};

/**
 * Split color and shines into two groups and make each group a must but internally the values
 * are should.
 *
 * query should be like (COLOR_1 OR COLOR_2) AND (SHINE_1 OR SHINE_2 OR SHINE_3)
 *
 * @param {string} zone
 * @param {array} filters
 * @param {boolean} isNewQuery
 * @returns {*[]} query
 */
const buildColorQuery = (zone, filters, isNewQuery) => {
    const colors = {
        bool: {
            should: []
        }
    };
    const main = {
        bool: {
            should: []
        }
    };
    const colorQuery = [];

    const buildFiltersTerm = (key, string) => {
        const term = {
            term: {
                [key]: string
            }
        };

        if (COLORS.includes(string)) {
            colors.bool.should.push(term);

            if (isNewQuery) {
                colors.bool.should.push(RANGE_QUERY_NEW_PRODUCTS);
            }
        } else {
            main.bool.should.push(term);

            if (isNewQuery) {
                main.bool.should.push(RANGE_QUERY_NEW_PRODUCTS);
            }
        }
    };

    filters.forEach(filter => {
        // handle filter grouping
        if (filter.strings && filter.strings.length) {
            filter.strings.forEach(string => {
                buildFiltersTerm(filter.key, string);
            });
        } else if (filter.string) {
            buildFiltersTerm(filter.key, filter.string);
        }
    });

    const simpleColorQuery = simplifyQuery(colors);
    const simpleMainQuery = simplifyQuery(main);

    if (simpleColorQuery) {
        colorQuery.push(simpleColorQuery);
    }

    if (simpleMainQuery) {
        colorQuery.push(simpleMainQuery);
    }

    return colorQuery;
};

const elasticSearchQueries = {
    /**
     * Query all products by specific zone (category(
     * @param {string} zone
     * @returns {object} elastic search body request
     */
    queryByZone: zone => {
        return {
            query: {
                bool: {
                    must: {
                        term: {
                            zone
                        }
                    },
                    filter: {
                        bool: {
                            must: IGNORE_BROWSABLE_FLAG.includes(zone) ? BASE_FILTER : BROWSE_FILTERS
                        }
                    }
                }
            },
            aggs: {
                products: {
                    terms: {
                        field: 'plpGroup',
                        size: 500,
                        order: {
                            _term: 'asc'
                        }
                    },
                    aggs: {
                        top_products: {
                            top_hits: {
                                from: 0,
                                size: 100
                            }
                        }

                    }
                },
                categories: {
                    terms: { field: 'category' }
                },
                new: {
                    date_range: {
                        field: 'beginLife',
                        ranges: [
                            { from: 'now-180d' }
                        ]
                    }
                }

            }
        };
    },

    /**
     * Query all new products
     * @returns {object} elastic search body request
     */
    queryNewProducts: () => {
        return {
            query: {
                bool: {
                    must: RANGE_QUERY_NEW_PRODUCTS,
                    filter: {
                        bool: {
                            must: BROWSE_FILTERS
                        }
                    }
                }
            },
            aggs: {
                products: {
                    terms: {
                        field: 'plpGroup',
                        size: 500,
                        order: {
                            _term: 'asc'
                        }
                    },
                    aggs: {
                        top_products: {
                            top_hits: {
                                from: 0,
                                size: 100
                            }
                        }

                    }
                }
            }
        };
    },

    /**
     * Query all products by specific zone (category(
     * @param {string} zone
     * @param {array} filters
     * @returns {object} elastic search body request
     */
    filterByZone: (zone, filters) => {
        const isNewQuery = filters.some(filter => filter.string === ZONES.NEW);
        const filterQuery = buildColorQuery(zone, filters, isNewQuery);

        return {
            from: 0,
            size: 500,
            query: {
                bool: {
                    must: [
                        {
                            term: {
                                zone
                            }
                        },

                    ],

                    filter: {
                        bool: {
                            must: BROWSE_FILTERS.concat(filterQuery),
                        }
                    }
                }
            },
            aggs: {
                products: {
                    terms: {
                        field: 'plpGroup',
                        size: 1000,
                        order: {
                            _term: 'asc'
                        }
                    },
                    aggs: {
                        top_products: {
                            top_hits: {
                                from: 0,
                                size: 100
                            }
                        }
                    }
                }
            }
        };
    },

    /**
     * Query for search autocomplete
     * @param {string} query
     * @returns {object} elastic search body request
     */
    searchAutocompleteQuery: query => {
        return {
            _source: 'suggest',
            suggest: {
                products: {
                    prefix: query,
                    completion: {
                        field: 'suggest',
                        size: 5,
                        skip_duplicates: true
                    }
                }
            }
        };
    },

    /**
     * Query all products by specific search term
     * @param {string} search
     * @returns {object} elastic search body request
     */
    searchQuery: (search) => {
        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                product_text: {
                                    query: search,
                                    operator: 'and'
                                }
                            }
                        }
                    ],
                    should: [
                        {
                            match: {
                                // If user types in literal product title prefer to shade
                                'title.keyword': {
                                    query: search,
                                    boost: 20
                                }
                            }
                        },
                        {
                            match: {
                                shade: {
                                    query: search,
                                    boost: 7
                                }
                            }
                        },
                        {
                            match: {
                                skuName: {
                                    query: search,
                                    boost: 6
                                }
                            }
                        },
                        {
                            match: {
                                title: {
                                    query: search,
                                    boost: 6
                                }
                            }
                        },
                        {
                            match: {
                                briefDescription: {
                                    query: search,
                                    boost: 5
                                }
                            }
                        },
                        {
                            match: {
                                sku: {
                                    query: search
                                }
                            }
                        },
                        {
                            match: {
                                productCode: {
                                    query: search
                                }
                            }
                        }
                    ],
                    filter: {
                        bool: {
                            must: SEARCH_FILTERS,
                            should: [
                                // Here's an example of a term filter
                                /*
                                {
                                    term: {
                                        category: "Liner"
                                    }
                                }
                                */
                                // Just keep adding filters here
                                /*
                                {
                                    term: {
                                        category: "Mascara"
                                    }
                                },
                                {
                                    term: {
                                        color: "Red as blood or lust or both"
                                    }
                                },
                                */
                            ]
                        }
                    },
                }
            },
            aggs: {
                products: {
                    terms: {
                        field: 'productCode',
                        size: 500,
                        order: {
                            top_hit: 'desc'
                        }
                    },
                    aggs: {
                        top_products: {
                            top_hits: {
                                from: 0,
                                size: 50
                            }
                        },
                        top_hit: {
                            max: {
                                script: '_score'
                            }
                        }
                    }
                },
                facets: {
                    terms: { field: 'zone' }
                },
                catfacets: {
                    terms: { field: 'category' }
                }
            }
        };
    },

    /**
     * Fetch all products and variants for specific product
     * @param {string} productCode
     * @param {boolean} isStaff
     * @returns {object} elastic search body request
     */
    queryByProductCode: (productCode, isStaff) => {

        const boolFilter = {
            must: BASE_FILTER
        };

        if (!isStaff) {
            boolFilter.should = SEARCH_AND_BROWSE_SHOULD_FILTERS;
        }

        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                productCode: {
                                    query: productCode,
                                }
                            }
                        }
                    ],
                    filter: {
                        bool: boolFilter
                    }
                }
            }
        };
    },

    /**
     * Fetch all products and variants for specific product
     * @param {array} productCodes
     * @param {boolean} isStaff
     * @returns {object} elastic search body request
     */
    queryByMultipleProductCodes: (productCodes, isStaff) => {

        const boolFilter = {
            must: BASE_FILTER
        };

        if (!isStaff) {
            boolFilter.should = SEARCH_AND_BROWSE_SHOULD_FILTERS;
        }

        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            terms: {
                                productCode: productCodes,
                            }
                        }
                    ],
                    filter: {
                        bool: boolFilter
                    }
                }
            },
            aggs: {
                products: {
                    terms: {
                        field: 'plpGroup',
                        size: 500,
                        order: {
                            _term: 'asc'
                        }
                    },
                    aggs: {
                        top_products: {
                            top_hits: {
                                from: 0,
                                size: 100
                            }
                        }

                    }
                },
            }
        };
    },

    /**
     * Fetch all products which are marked for the fragrance experience.
     * @returns {object} elastic search body request
     */
    queryProductsInFragranceExperience: () => {
        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            exists: {
                                field: 'fragranceNumber'
                            }
                        },
                        {
                            exists: {
                                field: 'skuName' // ignore if data is not completed
                            }
                        },
                        {
                            range: {
                                'fragranceNumber': {
                                    gt: 0
                                }
                            }
                        },
                        {
                            range: {
                                'activationDate': { // ignore if activation is not set (default 1970-1-1) or later than now
                                    lte: 'now',
                                    gt: '1970-01-01T00:00:00.000Z'
                                }
                            }
                        }
                    ]
                }
            }
        };
    },

    /**
     * Fetch all products for a given workplan category
     * @param {string} routine - workplan routine (AM/PM)
     * @param {string} category - workplan category
     * @returns {object} elastic search body request
     */
    queryByWorkplanCategory: (routine, category) => {
        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                [routine]: {
                                    query: category,
                                }
                            }
                        }
                    ],
                    filter: {
                        bool: {
                            must: BASE_FILTER
                        }
                    }
                }
            }
        };
    },

    /**
     * Get a list of all zones
     * @returns {object} elastic search body request
     */
    getAllZones: () => {
        return {
            aggs: {
                tags: {
                    terms: {
                        field: 'zone'
                    }
                }
            }
        };
    },

    /**
     * Get product info for an array of skus
     * @param {array} skus
     * @returns {object} elastic search body request
     */
    queryBySkus(skus) {
        return {
            ids: skus
        };
    },

    // FIXME: Build dynamically using same parameters as normal search
    searchWorkplanProductsQuery: search => {
        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                product_text: {
                                    query: search,
                                    operator: 'and'
                                }
                            }
                        }
                    ],
                    should: [
                        {
                            match: {
                                // If user types in literal product title prefer to shade
                                'title.keyword': {
                                    query: search,
                                    boost: 20
                                }
                            }
                        },
                        {
                            match: {
                                shade: {
                                    query: search,
                                    boost: 7
                                }
                            }
                        },
                        {
                            match: {
                                skuName: {
                                    query: search,
                                    boost: 6
                                }
                            }
                        },
                        {
                            match: {
                                title: {
                                    query: search,
                                    boost: 6
                                }
                            }
                        },
                        {
                            match: {
                                briefDescription: {
                                    query: search,
                                    boost: 5
                                }
                            }
                        },
                        {
                            match: {
                                sku: {
                                    query: search
                                }
                            }
                        },
                        {
                            match: {
                                productCode: {
                                    query: search
                                }
                            }
                        }
                    ],
                    filter: {
                        bool: {
                            must: SEARCH_FILTERS,
                            should: []
                        }
                    },
                }
            },
        };
    },

    searchWorkPlanFragranceFlightQuery: search => {
        return {
            from: 0,
            size: 100,
            query: {
                bool: {
                    must: [
                        {
                            exists: {
                                field: 'fragranceNumber'
                            }
                        },
                        {
                            exists: {
                                field: 'skuName' // ignore if data is not completed
                            }
                        },
                        {
                            range: {
                                'fragranceNumber': {
                                    gt: 0
                                }
                            }
                        },
                        {
                            range: {
                                'activationDate': { // ignore if activation is not set (default 1970-1-1) or later than now
                                    lte: 'now',
                                    gt: '1970-01-01T00:00:00.000Z'
                                }
                            }
                        },
                        {
                            bool: {
                                should: [
                                    {
                                        match: {
                                            product_text: {
                                                query: search,
                                                operator: 'and'
                                            }
                                        },
                                    },
                                    {
                                        match: {
                                            fragranceNumber: {
                                                query: parseInt(search, 10) ? parseInt(search, 10) : 9999
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    ],
                    should: [
                        {
                            match: {
                                // If user types in literal product title prefer to shade
                                'title.keyword': {
                                    query: search,
                                    boost: 20
                                }
                            }
                        },
                        {
                            match: {
                                fragranceNumber: {
                                    query: parseInt(search, 10) ? parseInt(search, 10) : 9999,
                                    boost: 15
                                }
                            }
                        },
                        {
                            match: {
                                shade: {
                                    query: search,
                                    boost: 7
                                }
                            }
                        },
                        {
                            match: {
                                skuName: {
                                    query: search,
                                    boost: 6
                                }
                            }
                        },
                        {
                            match: {
                                title: {
                                    query: search,
                                    boost: 6
                                }
                            }
                        },
                        {
                            match: {
                                briefDescription: {
                                    query: search,
                                    boost: 5
                                }
                            }
                        },
                        {
                            match: {
                                sku: {
                                    query: search
                                }
                            }
                        },
                        {
                            match: {
                                productCode: {
                                    query: search
                                }
                            }
                        }
                    ],
                    filter: {
                        bool: {
                            must: SEARCH_FILTERS,
                            should: []
                        }
                    },
                }
            },
        };
    },

    queryAll: () => {
        return {
            size: 2000,
            sort: [
                {
                    'plpGroup': {
                        order: 'asc'
                    }
                },
                '_score'
            ],
            query: {
                bool: {
                    should: [
                        {
                            term: {
                                searchable: true
                            }
                        },
                        {
                            term: {
                                browsable: true
                            }
                        }
                    ],
                    minimum_should_match: 1,
                    filter: {
                        bool: {
                            must: BASE_FILTER
                        }
                    }
                }
            },
            aggs: {
                products: {
                    terms: {
                        field: 'plpGroup',
                        size: 500,
                        order: {
                            _key: 'asc'
                        }
                    },
                    aggs: {
                        top_products: {
                            top_hits: {
                                from: 0,
                                size: 100
                            }
                        }

                    }
                }
            }
        };
    }

};

export default elasticSearchQueries;
