import React, { Component } from 'react';
import { greaterThan, rem, styledProps } from 'core/styled/index';
import styled, { css }  from 'styled-components';
import { BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS } from 'constants/beautyplan';
import { breakpoints } from 'constants/theme';
import SimpleTextSection from './SimpleTextSection';
import SimpleSubSection from './SimpleSubSection';

const CategoryContainer = styled.div`
    padding-top: ${rem(40)};
    width: 100%;

    :nth-child(2) {
        padding-top: 0;
    }
`;

const CategoryTitle = styled.h2`
    width: 100%;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    text-align: center;
    font-size: ${rem(18)};
    margin: ${rem(50, 'auto', 10)};

    ${greaterThan(breakpoints.large)(css`
        font-size: ${rem(24)};
    `)}
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${rem(0, 30)};
    max-width: ${rem(972)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${rem(0, 'auto')};
`;
export default class FragranceExperience extends Component {

    stepConfig = BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.FRAGRANCE_EXPERIENCE];

    /**
     * Render the categories for choosing products.
     * @returns {object[]} - Rendered categories with subsections
     */
    renderCategories = () => {

        return this.stepConfig.categories.map((category, key) => {
            return (
                <CategoryContainer key={key}>
                    {category.showCategoryTitle && <CategoryTitle>{category.title}</CategoryTitle>}
                    { this.renderSubSections(category.subSections, category) }

                </CategoryContainer>
            );
        });
    };

    /**
     * Render the free-form text areas for the step.
     *
     * @returns {object[]} - Rendered text sections
     */
    renderTextSections = () => {
        return this.stepConfig.textSections.map((section, key) => {
            return (
                <SimpleTextSection sectionId={this.stepConfig.id} section={section} key={key} />
            );
        });
    };

    /**
     * Render the subsections for a category.
     * @param {object[]} subSections
     * @param {object} category
     * @returns {object[]} - Rendered subsections
     */
    renderSubSections = (subSections, category) => {

        return subSections.map((subSection, key) => {
            return (
                <SimpleSubSection id={this.stepConfig.id}
                    category={category}
                    subSection={subSection}
                    key={key}
                    first={key === 0}/>
            );
        });
    };

    render() {
        return (
            <Wrapper>
                { this.renderTextSections() }
                { this.renderCategories() }
            </Wrapper>
        );

    }

}
