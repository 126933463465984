// Like light
import LIKE_LIGHT_DEFAULT from 'assets/icons/like/like-light-default.svg';
import LIKE_LIGHT_HOVER from 'assets/icons/like/like-light-hover.svg';
import LIKE_LIGHT_CLICK from 'assets/icons/like/like-light-click.svg';
import LIKE_LIGHT_SELECTED from 'assets/icons/like/like-light-selected.svg';
import LIKE_LIGHT_DISABLED from 'assets/icons/like/like-light-disabled.svg';


// Like dark
import LIKE_DARK_DEFAULT from 'assets/icons/like/like-dark-default.svg';
import LIKE_DARK_HOVER from 'assets/icons/like/like-dark-hover.svg';
import LIKE_DARK_CLICK from 'assets/icons/like/like-dark-click.svg';
import LIKE_DARK_SELECTED from 'assets/icons/like/like-dark-selected.svg';
import LIKE_DARK_DISABLED from 'assets/icons/like/like-dark-disabled.svg';

// Dislike light
import DISLIKE_LIGHT_DEFAULT from 'assets/icons/dislike/dislike-light-default.svg';
import DISLIKE_LIGHT_HOVER from 'assets/icons/dislike/dislike-light-hover.svg';
import DISLIKE_LIGHT_CLICK from 'assets/icons/dislike/dislike-light-click.svg';
import DISLIKE_LIGHT_SELECTED from 'assets/icons/dislike/dislike-light-selected.svg';
import DISLIKE_LIGHT_DISABLED from 'assets/icons/dislike/dislike-light-disabled.svg';

// // Dislike dark
import DISLIKE_DARK_DEFAULT from 'assets/icons/dislike/dislike-dark-default.svg';
import DISLIKE_DARK_HOVER from 'assets/icons/dislike/dislike-dark-hover.svg';
import DISLIKE_DARK_CLICK from 'assets/icons/dislike/dislike-dark-click.svg';
import DISLIKE_DARK_SELECTED from 'assets/icons/dislike/dislike-dark-selected.svg';
import DISLIKE_DARK_DISABLED from 'assets/icons/dislike/dislike-dark-disabled.svg';

// Shop light
import SHOP_LIGHT_DEFAULT from 'assets/icons/shop/shop-light-default.svg';
import SHOP_LIGHT_SELECTED_HOVER from 'assets/icons/shop/shop-light-selected.svg';
import SHOP_LIGHT_CLICK from 'assets/icons/shop/shop-light-click.svg';
import SHOP_LIGHT_DISABLED from 'assets/icons/shop/shop-light-disabled.svg';

// // Shop dark
import SHOP_DARK_DEFAULT from 'assets/icons/shop/shop-dark-default.svg';
import SHOP_DARK_SELECTED_HOVER from 'assets/icons/shop/shop-dark-selected.svg';
import SHOP_DARK_CLICK from 'assets/icons/shop/shop-dark-click.svg';
import SHOP_DARK_DISABLED from 'assets/icons/shop/shop-dark-disabled.svg';

// Sample light
import SAMPLE_LIGHT_DEFAULT from 'assets/icons/sample/sample-light-default.svg';
import SAMPLE_LIGHT_HOVER from 'assets/icons/sample/sample-light-hover.svg';
import SAMPLE_LIGHT_CLICK from 'assets/icons/sample/sample-light-click.svg';
import SAMPLE_LIGHT_DISABLED from 'assets/icons/sample/sample-light-disabled.svg';

// // Sample dark
import SAMPLE_DARK_DEFAULT from 'assets/icons/sample/sample-dark-default.svg';
import SAMPLE_DARK_HOVER from 'assets/icons/sample/sample-dark-hover.svg';
import SAMPLE_DARK_CLICK from 'assets/icons/sample/sample-dark-click.svg';
import SAMPLE_DARK_DISABLED from 'assets/icons/sample/sample-dark-disabled.svg';

// Menu common
import MENU_CLICK from 'assets/icons/menu/main-menu-click.svg';

// Menu light
import MENU_LIGHT_DEFAULT_HOVER from 'assets/icons/menu/main-menu-light-default.svg';
import MENU_LIGHT_DISABLED from 'assets/icons/menu/main-menu-light-disabled.svg';

//Menu dark
import MENU_DARK_DEFAULT_HOVER from 'assets/icons/menu/main-menu-dark-default.svg';
import MENU_DARK_DISABLED from 'assets/icons/menu/main-menu-dark-disabled.svg';

//search common
import SEARCH_CLICK from 'assets/icons/search/search-click.svg';
import SEARCH_DISABLED from 'assets/icons/search/search-disabled.svg';

//search light
import SEARCH_LIGHT_DEFAULT_HOVER from 'assets/icons/search/search-light-default.svg';

//search dark
import SEARCH_DARK_DEFAULT_HOVER from 'assets/icons/search/search-dark-default.svg';

//styled like common
import STYLED_LIKE_CLICK from 'assets/icons/styledLike/styled-like-click.svg';

//styled like light
import STYLED_LIKE_LIGHT_DEFAULT from 'assets/icons/styledLike/styled-like-light-default.svg';
import STYLED_LIKE_LIGHT_HOVER from 'assets/icons/styledLike/styled-like-light-hover.svg';
import STYLED_LIKE_LIGHT_SELECTED from 'assets/icons/styledLike/styled-like-light-selected.svg';
import STYLED_LIKE_LIGHT_DISABLED from 'assets/icons/styledLike/styled-like-light-disabled.svg';
import STYLED_LIKE_LIGHT_ADDED from 'assets/icons/styledLike/styled-like-light-added.svg';
import STYLED_LIKE_LIGHT_REMOVED from 'assets/icons/styledLike/styled-like-light-removed.svg';

//styled like dark
import STYLED_LIKE_DARK_DEFAULT from 'assets/icons/styledLike/styled-like-dark-default.svg';
import STYLED_LIKE_DARK_HOVER from 'assets/icons/styledLike/styled-like-dark-hover.svg';
import STYLED_LIKE_DARK_SELECTED from 'assets/icons/styledLike/styled-like-dark-selected.svg';
import STYLED_LIKE_DARK_DISABLED from 'assets/icons/styledLike/styled-like-dark-disabled.svg';
import STYLED_LIKE_DARK_ADDED from 'assets/icons/styledLike/styled-like-dark-added.svg';
import STYLED_LIKE_DARK_REMOVED from 'assets/icons/styledLike/styled-like-dark-removed.svg';

// bag common
import BAG_CLICK from 'assets/icons/bag/bag-click.svg';

// bag light
import BAG_LIGHT_DEFAULT_HOVER from 'assets/icons/bag/bag-light-default.svg';
import BAG_LIGHT_SELECTED from 'assets/icons/bag/bag-light-selected.svg';
import BAG_LIGHT_DISABLED from 'assets/icons/bag/bag-light-disabled.svg';
import BAG_LIGHT_ADDED from 'assets/icons/bag/bag-light-added.svg';
import BAG_LIGHT_REMOVED from 'assets/icons/bag/bag-light-removed.svg';

// bag dark
import BAG_DARK_DEFAULT_HOVER from 'assets/icons/bag/bag-dark-default.svg';
import BAG_DARK_SELECTED from 'assets/icons/bag/bag-dark-selected.svg';
import BAG_DARK_DISABLED from 'assets/icons/bag/bag-dark-disabled.svg';
import BAG_DARK_ADDED from 'assets/icons/bag/bag-dark-added.svg';
import BAG_DARK_REMOVED from 'assets/icons/bag/bag-dark-removed.svg';

// styled profile common
import PROFILE_CLICK from 'assets/icons/profile/profile-click.svg';

// styled profile light
import PROFILE_LIGHT_DEFAULT_HOVER from 'assets/icons/profile/profile-light-default.svg';
import PROFILE_LIGHT_DISABLED from 'assets/icons/profile/profile-light-disabled.svg';
import PROFILE_LIGHT_SELECTED from 'assets/icons/profile/profile-light-selected.svg';
import PROFILE_LIGHT_ADDED from 'assets/icons/profile/profile-light-added.svg';
import PROFILE_LIGHT_REMOVED from 'assets/icons/profile/profile-light-removed.svg';

// styled profile dark
import PROFILE_DARK_DEFAULT_HOVER from 'assets/icons/profile/profile-dark-default.svg';
import PROFILE_DARK_DISABLED from 'assets/icons/profile/profile-dark-disabled.svg';
import PROFILE_DARK_SELECTED from 'assets/icons/profile/profile-dark-selected.svg';
import PROFILE_DARK_ADDED from 'assets/icons/profile/profile-dark-added.svg';
import PROFILE_DARK_REMOVED from 'assets/icons/profile/profile-dark-removed.svg';

// styled close common
import STYLED_CLOSE_CLICK from 'assets/icons/close/close-click.svg';

// styled close light
import STYLED_CLOSE_LIGHT_DEFAULT_HOVER_SELECTED from 'assets/icons/close/close-light-default.svg';
import STYLED_CLOSE_LIGHT_DISABLED from 'assets/icons/close/close-light-disabled.svg';

// styled close dark
import STYLED_CLOSE_DARK_DEFAULT_HOVER_SELECTED from 'assets/icons/close/close-dark-default.svg';
import STYLED_CLOSE_DARK_DISABLED from 'assets/icons/close/close-dark-disabled.svg';

// flower light
import FLOWER_LIGHT_DEFAULT_HOVER from 'assets/icons/flower/flower-light-default.svg';
import FLOWER_LIGHT_SELECTED from 'assets/icons/flower/flower-light-selected.svg';
import FLOWER_LIGHT_CLICK from 'assets/icons/flower/flower-light-click.svg';
import FLOWER_LIGHT_DISABLED from 'assets/icons/flower/flower-light-disabled.svg';

// flower dark
import FLOWER_DARK_DEFAULT_HOVER from 'assets/icons/flower/flower-dark-default.svg';
import FLOWER_DARK_SELECTED from 'assets/icons/flower/flower-dark-selected.svg';
import FLOWER_DARK_CLICK from 'assets/icons/flower/flower-dark-click.svg';
import FLOWER_DARK_DISABLED from 'assets/icons/flower/flower-dark-click.svg';

// camera light
import CAMERA_LIGHT_DEFAULT from 'assets/icons/camera/camera-light-default.svg';
import CAMERA_LIGHT_HOVER from 'assets/icons/camera/camera-light-hover.svg';
import CAMERA_LIGHT_CLICK from 'assets/icons/camera/camera-light-click.svg';
import CAMERA_LIGHT_SELECTED from 'assets/icons/camera/camera-light-selected.svg';
import CAMERA_LIGHT_DISABLED from 'assets/icons/camera/camera-light-disabled.svg';

// camera dark
import CAMERA_DARK_DEFAULT from 'assets/icons/camera/camera-dark-default.svg';
import CAMERA_DARK_HOVER from 'assets/icons/camera/camera-dark-hover.svg';
import CAMERA_DARK_CLICK from 'assets/icons/camera/camera-dark-clicked.svg';
import CAMERA_DARK_SELECTED from 'assets/icons/camera/camera-dark-selected.svg';
import CAMERA_DARK_DISABLED from 'assets/icons/camera/camera-dark-disabled.svg';

// plus light
import PLUS_LIGHT_DEFAULT from 'assets/icons/plus/plus-light-default.svg';
import PLUS_LIGHT_HOVER from 'assets/icons/plus/plus-light-hover.svg';
import PLUS_LIGHT_CLICK from 'assets/icons/plus/plus-light-click.svg';
import PLUS_LIGHT_SELECTED from 'assets/icons/plus/plus-light-selected.svg';
import PLUS_LIGHT_DISABLED from 'assets/icons/plus/plus-light-disabled.svg';

// plus dark
import PLUS_DARK_DEFAULT from 'assets/icons/plus/plus-dark-default.svg';
import PLUS_DARK_HOVER from 'assets/icons/plus/plus-dark-hover.svg';
import PLUS_DARK_CLICK from 'assets/icons/plus/plus-dark-click.svg';
import PLUS_DARK_SELECTED from 'assets/icons/plus/plus-dark-selected.svg';
import PLUS_DARK_DISABLED from 'assets/icons/plus/plus-dark-disabled.svg';

// star light
import STAR_LIGHT_DEFAULT from 'assets/icons/star/star-light-default.svg';
import STAR_LIGHT_HOVER from 'assets/icons/star/star-light-hover.svg';
import STAR_LIGHT_CLICK from 'assets/icons/star/star-light-click.svg';
import STAR_LIGHT_SELECTED from 'assets/icons/star/star-light-selected.svg';
import STAR_LIGHT_DISABLED from 'assets/icons/star/star-light-disabled.svg';

// star dark
import STAR_DARK_DEFAULT from 'assets/icons/star/star-dark-default.svg';
import STAR_DARK_HOVER from 'assets/icons/star/star-dark-hover.svg';
import STAR_DARK_CLICK from 'assets/icons/star/star-dark-click.svg';
import STAR_DARK_SELECTED from 'assets/icons/star/star-dark-selected.svg';
import STAR_DARK_DISABLED from 'assets/icons/star/star-dark-disabled.svg';

// spot light
import SPOT_LIGHT_DEFAULT from 'assets/icons/spot/spot-light-default.svg';
import SPOT_LIGHT_HOVER from 'assets/icons/spot/spot-light-hover.svg';
import SPOT_LIGHT_CLICK from 'assets/icons/spot/spot-light-click.svg';
import SPOT_LIGHT_SELECTED from 'assets/icons/spot/spot-light-selected.svg';
import SPOT_LIGHT_DISABLED from 'assets/icons/spot/spot-light-disabled.svg';

// spot dark
import SPOT_DARK_DEFAULT from 'assets/icons/spot/spot-dark-default.svg';
import SPOT_DARK_HOVER from 'assets/icons/spot/spot-dark-hover.svg';
import SPOT_DARK_CLICK from 'assets/icons/spot/spot-dark-click.svg';
import SPOT_DARK_SELECTED from 'assets/icons/spot/spot-dark-selected.svg';
import SPOT_DARK_DISABLED from 'assets/icons/spot/spot-dark-disabled.svg';

// list light
import LIST_LIGHT_DEFAULT from 'assets/icons/list/list-light-default.svg';
import LIST_LIGHT_HOVER from 'assets/icons/list/list-light-hover.svg';
import LIST_LIGHT_CLICK from 'assets/icons/list/list-light-click.svg';
import LIST_LIGHT_SELECTED from 'assets/icons/list/list-light-selected.svg';
import LIST_LIGHT_DISABLED from 'assets/icons/list/list-light-disabled.svg';

// list dark
import LIST_DARK_DEFAULT from 'assets/icons/list/list-dark-default.svg';
import LIST_DARK_HOVER from 'assets/icons/list/list-dark-hover.svg';
import LIST_DARK_CLICK from 'assets/icons/list/list-dark-click.svg';
import LIST_DARK_SELECTED from 'assets/icons/list/list-dark-selected.svg';
import LIST_DARK_DISABLED from 'assets/icons/list/list-dark-disabled.svg';

// remove light
import REMOVE_LIGHT_DEFAULT from 'assets/icons/remove/remove-light-default.svg';
import REMOVE_LIGHT_HOVER from 'assets/icons/remove/remove-light-hover.svg';
import REMOVE_LIGHT_SELECTED_CLICK from 'assets/icons/remove/remove-light-selected.svg';
import REMOVE_LIGHT_DISABLED from 'assets/icons/remove/remove-light-disabled.svg';

// remove dark
import REMOVE_DARK_DEFAULT from 'assets/icons/remove/remove-dark-default.svg';
import REMOVE_DARK_HOVER from 'assets/icons/remove/remove-dark-hover.svg';
import REMOVE_DARK_CLICK from 'assets/icons/remove/remove-dark-click.svg';
import REMOVE_DARK_SELECTED from 'assets/icons/remove/remove-dark-selected.svg';
import REMOVE_DARK_DISABLED from 'assets/icons/remove/remove-dark-disabled.svg';

// info light
import INFO_LIGHT_DEFAULT from 'assets/icons/info/info-light-default.svg';
import INFO_LIGHT_CLICK_HOVER_SELECTED from 'assets/icons/info/info-light-click.svg';
import INFO_LIGHT_DISABLED from 'assets/icons/info/info-light-disabled.svg';

// info dark
import INFO_DARK_DEFAULT from 'assets/icons/info/info-dark-default.svg';
import INFO_DARK_HOVER from 'assets/icons/info/info-dark-hover.svg';
import INFO_DARK_CLICK from 'assets/icons/info/info-dark-click.svg';
import INFO_DARK_SELECTED from 'assets/icons/info/info-dark-selected.svg';
import INFO_DARK_DISABLED from 'assets/icons/info/info-dark-disbaled.svg';

// vto light
import VTO_LIGHT_DEFAULT from 'assets/icons/vto/vto-light-default.svg';

// vto dark
import VTO_DARK_DEFAULT from 'assets/icons/vto/vto-dark-default.svg';

export const ICON_TYPES = {
    LIKE: 'like',
    DISLIKE: 'dislike',
    SHOP: 'shop',
    SAMPLE: 'sample',
    CAMERA: 'camera',
    ADD: 'add',
    STAR: 'star',
    MAP: 'map',
    CATALOG: 'catalog',
    INFO: 'info',
    REMOVE: 'remove',
    MENU: 'menu',
    SEARCH: 'search',
    STYLED_LIKE: 'styled-like',
    BAG: 'bag',
    PROFILE: 'profile',
    STYLED_CLOSE: 'close',
    FLOWER: 'flower',
    PLUS: 'plus',
    SPOT: 'spot',
    LIST: 'list',
    VTO: 'vto'
};

export const ICON_VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark'
};

export const ICON_DATA = {
    [ICON_TYPES.LIKE]: {
        light: {
            default: LIKE_LIGHT_DEFAULT,
            hover: LIKE_LIGHT_HOVER,
            click: LIKE_LIGHT_CLICK,
            selected: LIKE_LIGHT_SELECTED,
            disabled: LIKE_LIGHT_DISABLED,
            added: LIKE_LIGHT_CLICK,
            removed: LIKE_LIGHT_DEFAULT
        },
        dark: {
            default: LIKE_DARK_DEFAULT,
            hover: LIKE_DARK_HOVER,
            click: LIKE_DARK_CLICK,
            selected: LIKE_DARK_SELECTED,
            disabled: LIKE_DARK_DISABLED,
            added: LIKE_DARK_CLICK,
            removed: LIKE_DARK_DEFAULT
        },
        text: {
            successPositive: 'Like!',
            successNegative: 'Never mind!',
        }
    },
    [ICON_TYPES.DISLIKE]: {
        light: {
            default: DISLIKE_LIGHT_DEFAULT,
            hover: DISLIKE_LIGHT_HOVER,
            click: DISLIKE_LIGHT_CLICK,
            selected: DISLIKE_LIGHT_SELECTED,
            disabled: DISLIKE_LIGHT_DISABLED,
            added: DISLIKE_LIGHT_CLICK,
            removed: DISLIKE_LIGHT_DEFAULT
        },
        dark: {
            default: DISLIKE_DARK_DEFAULT,
            hover: DISLIKE_DARK_HOVER,
            click: DISLIKE_DARK_CLICK,
            selected: DISLIKE_DARK_SELECTED,
            disabled: DISLIKE_DARK_DISABLED,
            added: DISLIKE_DARK_CLICK,
            removed: DISLIKE_DARK_DEFAULT
        },
        text: {
            successPositive: 'Not for me!',
            successNegative: 'Reconsidering!',
        }
    },
    [ICON_TYPES.SHOP]: {
        light: {
            default: SHOP_LIGHT_DEFAULT,
            hover: SHOP_LIGHT_SELECTED_HOVER,
            click: SHOP_LIGHT_CLICK,
            selected: SHOP_LIGHT_SELECTED_HOVER,
            disabled: SHOP_LIGHT_DISABLED,
            added: SHOP_LIGHT_CLICK,
            removed: SHOP_LIGHT_DEFAULT
        },
        dark: {
            default: SHOP_DARK_DEFAULT,
            hover: SHOP_DARK_SELECTED_HOVER,
            click: SHOP_DARK_CLICK,
            selected: SHOP_DARK_SELECTED_HOVER,
            disabled: SHOP_DARK_DISABLED,
            added: SHOP_DARK_CLICK,
            removed: SHOP_DARK_DEFAULT
        },
        text: {
            successPositive: 'Added!',
            successNegative: 'Removed!',
        }
    },
    [ICON_TYPES.SAMPLE]: {
        light: {
            default: SAMPLE_LIGHT_DEFAULT,
            hover: SAMPLE_LIGHT_HOVER,
            click: SAMPLE_LIGHT_CLICK,
            selected: SAMPLE_LIGHT_CLICK,
            disabled: SAMPLE_LIGHT_DISABLED,
            added: SAMPLE_LIGHT_CLICK,
            removed: SAMPLE_LIGHT_DEFAULT
        },
        dark: {
            default: SAMPLE_DARK_DEFAULT,
            hover: SAMPLE_DARK_HOVER,
            click: SAMPLE_DARK_CLICK,
            selected: SAMPLE_DARK_HOVER,
            disabled: SAMPLE_DARK_DISABLED,
            added: SAMPLE_DARK_CLICK,
            removed: SAMPLE_DARK_DEFAULT
        }
    },
    [ICON_TYPES.MENU]: {
        light: {
            default: MENU_LIGHT_DEFAULT_HOVER,
            hover: MENU_LIGHT_DEFAULT_HOVER,
            click: MENU_CLICK,
            disabled: MENU_LIGHT_DISABLED,
        },
        dark: {
            default: MENU_DARK_DEFAULT_HOVER,
            hover: MENU_DARK_DEFAULT_HOVER,
            click: MENU_CLICK,
            disabled: MENU_DARK_DISABLED,
        }
    },
    [ICON_TYPES.SEARCH]: {
        light: {
            default: SEARCH_LIGHT_DEFAULT_HOVER,
            hover: SEARCH_LIGHT_DEFAULT_HOVER,
            click: SEARCH_CLICK,
            disabled: SEARCH_DISABLED,
        },
        dark: {
            default: SEARCH_DARK_DEFAULT_HOVER,
            hover: SEARCH_DARK_DEFAULT_HOVER,
            click: SEARCH_CLICK,
            disabled: SEARCH_DISABLED,
        }
    },
    [ICON_TYPES.STYLED_LIKE]: {
        light: {
            default: STYLED_LIKE_LIGHT_DEFAULT,
            hover: STYLED_LIKE_LIGHT_HOVER,
            click: STYLED_LIKE_CLICK,
            selected: STYLED_LIKE_LIGHT_SELECTED,
            disabled: STYLED_LIKE_LIGHT_DISABLED,
            added: STYLED_LIKE_LIGHT_ADDED,
            addedSelected: STYLED_LIKE_LIGHT_REMOVED
        },
        dark: {
            default: STYLED_LIKE_DARK_DEFAULT,
            hover: STYLED_LIKE_DARK_HOVER,
            click: STYLED_LIKE_CLICK,
            selected: STYLED_LIKE_DARK_SELECTED,
            disabled: STYLED_LIKE_DARK_DISABLED,
            added: STYLED_LIKE_DARK_ADDED,
            addedSelected: STYLED_LIKE_DARK_REMOVED
        }
    },
    [ICON_TYPES.BAG]: {
        light: {
            default: BAG_LIGHT_DEFAULT_HOVER,
            hover: BAG_LIGHT_DEFAULT_HOVER,
            click: BAG_CLICK,
            selected: BAG_LIGHT_SELECTED,
            disabled: BAG_LIGHT_DISABLED,
            added: BAG_LIGHT_ADDED,
            addedSelected: BAG_LIGHT_REMOVED
        },
        dark: {
            default: BAG_DARK_DEFAULT_HOVER,
            hover: BAG_DARK_DEFAULT_HOVER,
            click: BAG_CLICK,
            selected: BAG_DARK_SELECTED,
            disabled: BAG_DARK_DISABLED,
            added: BAG_DARK_ADDED,
            addedSelected: BAG_DARK_REMOVED
        }
    },
    [ICON_TYPES.PROFILE]: {
        light: {
            default: PROFILE_LIGHT_DEFAULT_HOVER,
            hover: PROFILE_LIGHT_DEFAULT_HOVER,
            click: PROFILE_CLICK,
            selected: PROFILE_LIGHT_SELECTED,
            disabled: PROFILE_LIGHT_DISABLED,
            added: PROFILE_LIGHT_ADDED,
            addedSelected: PROFILE_LIGHT_REMOVED
        },
        dark: {
            default: PROFILE_DARK_DEFAULT_HOVER,
            hover: PROFILE_DARK_DEFAULT_HOVER,
            click: PROFILE_CLICK,
            selected: PROFILE_DARK_SELECTED,
            disabled: PROFILE_DARK_DISABLED,
            added: PROFILE_DARK_ADDED,
            addedSelected: PROFILE_DARK_REMOVED
        }
    },
    [ICON_TYPES.STYLED_CLOSE]: {
        light: {
            default: STYLED_CLOSE_LIGHT_DEFAULT_HOVER_SELECTED,
            hover: STYLED_CLOSE_LIGHT_DEFAULT_HOVER_SELECTED,
            click: STYLED_CLOSE_CLICK,
            selected: STYLED_CLOSE_LIGHT_DEFAULT_HOVER_SELECTED,
            disabled: STYLED_CLOSE_LIGHT_DISABLED
        },
        dark: {
            default: STYLED_CLOSE_DARK_DEFAULT_HOVER_SELECTED,
            hover: STYLED_CLOSE_DARK_DEFAULT_HOVER_SELECTED,
            click: STYLED_CLOSE_CLICK,
            selected: STYLED_CLOSE_DARK_DEFAULT_HOVER_SELECTED,
            disabled: STYLED_CLOSE_DARK_DISABLED
        }
    },
    [ICON_TYPES.FLOWER]: {
        light: {
            default: FLOWER_LIGHT_DEFAULT_HOVER,
            hover: FLOWER_LIGHT_DEFAULT_HOVER,
            click: FLOWER_LIGHT_CLICK,
            selected: FLOWER_LIGHT_SELECTED,
            disabled: FLOWER_LIGHT_DISABLED,
            added: FLOWER_LIGHT_DEFAULT_HOVER
        },
        dark: {
            default: FLOWER_DARK_DEFAULT_HOVER,
            hover: FLOWER_DARK_DEFAULT_HOVER,
            click: FLOWER_DARK_CLICK,
            selected: FLOWER_DARK_SELECTED,
            disabled: FLOWER_DARK_DISABLED
        }
    },
    [ICON_TYPES.CAMERA]: {
        light: {
            default: CAMERA_LIGHT_DEFAULT,
            hover: CAMERA_LIGHT_HOVER,
            click: CAMERA_LIGHT_CLICK,
            selected: CAMERA_LIGHT_SELECTED,
            disabled: CAMERA_LIGHT_DISABLED
        },
        dark: {
            default: CAMERA_DARK_DEFAULT,
            hover: CAMERA_DARK_HOVER,
            click: CAMERA_DARK_CLICK,
            selected: CAMERA_DARK_SELECTED,
            disabled: CAMERA_DARK_DISABLED
        }
    },
    [ICON_TYPES.STAR]: {
        light: {
            default: STAR_LIGHT_DEFAULT,
            hover: STAR_LIGHT_HOVER,
            click: STAR_LIGHT_CLICK,
            selected: STAR_LIGHT_SELECTED,
            disabled: STAR_LIGHT_DISABLED
        },
        dark: {
            default: STAR_DARK_DEFAULT,
            hover: STAR_DARK_HOVER,
            click: STAR_DARK_CLICK,
            selected: STAR_DARK_SELECTED,
            disabled: STAR_DARK_DISABLED
        }
    },
    [ICON_TYPES.SPOT]: {
        light: {
            default: SPOT_LIGHT_DEFAULT,
            hover: SPOT_LIGHT_HOVER,
            click: SPOT_LIGHT_CLICK,
            selected: SPOT_LIGHT_SELECTED,
            disabled: SPOT_LIGHT_DISABLED
        },
        dark: {
            default: SPOT_DARK_DEFAULT,
            hover: SPOT_DARK_HOVER,
            click: SPOT_DARK_CLICK,
            selected: SPOT_DARK_SELECTED,
            disabled: SPOT_DARK_DISABLED
        }
    },
    [ICON_TYPES.LIST]: {
        light: {
            default: LIST_LIGHT_DEFAULT,
            hover: LIST_LIGHT_HOVER,
            click: LIST_LIGHT_CLICK,
            selected: LIST_LIGHT_SELECTED,
            disabled: LIST_LIGHT_DISABLED
        },
        dark: {
            default: LIST_DARK_DEFAULT,
            hover: LIST_DARK_HOVER,
            click: LIST_DARK_CLICK,
            selected: LIST_DARK_SELECTED,
            disabled: LIST_DARK_DISABLED
        }
    },
    [ICON_TYPES.REMOVE]: {
        light: {
            default: REMOVE_LIGHT_DEFAULT,
            hover: REMOVE_LIGHT_HOVER,
            click: REMOVE_LIGHT_SELECTED_CLICK,
            selected: REMOVE_LIGHT_SELECTED_CLICK,
            disabled: REMOVE_LIGHT_DISABLED
        },
        dark: {
            default: REMOVE_DARK_DEFAULT,
            hover: REMOVE_DARK_HOVER,
            click: REMOVE_DARK_CLICK,
            selected: REMOVE_DARK_SELECTED,
            disabled: REMOVE_DARK_DISABLED
        }
    },
    [ICON_TYPES.PLUS]: {
        light: {
            default: PLUS_LIGHT_DEFAULT,
            hover: PLUS_LIGHT_HOVER,
            click: PLUS_LIGHT_CLICK,
            selected: PLUS_LIGHT_SELECTED,
            disabled: PLUS_LIGHT_DISABLED
        },
        dark: {
            default: PLUS_DARK_DEFAULT,
            hover: PLUS_DARK_HOVER,
            click: PLUS_DARK_CLICK,
            selected: PLUS_DARK_SELECTED,
            disabled: PLUS_DARK_DISABLED
        }
    },
    [ICON_TYPES.INFO]: {
        light: {
            default: INFO_LIGHT_DEFAULT,
            hover: INFO_LIGHT_CLICK_HOVER_SELECTED,
            click: INFO_LIGHT_CLICK_HOVER_SELECTED,
            selected: INFO_LIGHT_CLICK_HOVER_SELECTED,
            disabled: INFO_LIGHT_DISABLED
        },
        dark: {
            default: INFO_DARK_DEFAULT,
            hover: INFO_DARK_HOVER,
            click: INFO_DARK_CLICK,
            selected: INFO_DARK_SELECTED,
            disabled: INFO_DARK_DISABLED
        }
    },
    [ICON_TYPES.VTO]: {
        light: {
            default: VTO_LIGHT_DEFAULT,
            hover: VTO_LIGHT_DEFAULT,
            click: VTO_LIGHT_DEFAULT,
            selected: VTO_LIGHT_DEFAULT,
            disabled: VTO_LIGHT_DEFAULT
        },
        dark: {
            default: VTO_DARK_DEFAULT,
            hover: VTO_DARK_DEFAULT,
            click: VTO_DARK_DEFAULT,
            selected: VTO_DARK_DEFAULT,
            disabled: VTO_DARK_DEFAULT
        }
    }
};
