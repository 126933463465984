import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'redux-first-history';
import { Route } from 'react-router-dom';
import { returnAfterLogin } from 'reducers/user';
import { ROUTES } from 'constants/routes';

class AuthRoute extends Component {
    static propTypes = {
        authenticated: PropTypes.bool.isRequired,
        returnAfterLogin: PropTypes.func.isRequired,
        path: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        replace: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        // When user not authenticated redirect to login page
        if (!props.authenticated) {
            props.returnAfterLogin(
                `${props.location.pathname}${props.location.search}`
            );
            props.replace(`${ROUTES.LOGIN}${props.location.search}`); // preserve querystring param (utm tags) if any
        }
    }

    render() {
        if (!this.props.authenticated) {
            return null;
        }

        return <Route {...this.props} />;
    }
}

const mapStateToProps = ({ user: { auth } }) => auth;

const mapDispatchToProps = dispatch =>
    bindActionCreators({ replace, returnAfterLogin }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
