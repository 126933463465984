const {
    REACT_APP_API_HOST: API_HOST = '',
    REACT_APP_API_PATH: API_PATH = '',
    REACT_APP_ELASTIC_SEARCH_HOST: ELASTIC_SEARCH_HOST = '',
    REACT_APP_ELASTIC_SEARCH_PORT: ELASTIC_SEARCH_PORT = '',
    REACT_APP_ELASTIC_SEARCH_PATH: ELASTIC_SEARCH_PATH = '',
    REACT_APP_PRISMIC_API_URL: PRISMIC_API_URL = '',
    REACT_APP_PRISMIC_ACCESS_TOKEN: PRISMIC_ACCESS_TOKEN = '',
    REACT_APP_EXTERNAL_CHECKOUT: EXTERNAL_CHECKOUT_HOST = '',
    REACT_APP_VIDEO_PLATFORM_BASE_URL: VIDEO_PLATFORM_BASE_URL = '',
    REACT_APP_VIDEO_PLATFORM_START_ENDPOINT: VIDEO_PLATFORM_START_ENDPOINT = '',
    REACT_APP_GIGYA_API_KEY: GIGYA_API_KEY = '',
    REACT_APP_OPEN_APP_DYNAMIC_LINK: OPEN_APP_DYNAMIC_LINK = '',
    REACT_APP_PIONEER_PRISMIC_UID: PIONEER_PRISMIC_UID = 'pioneer-config-prod'
} = process.env; // eslint-disable-line no-undef

const ELASTIC_SEARCH_PORT_WITH_COLON = ELASTIC_SEARCH_PORT
    ? `:${ELASTIC_SEARCH_PORT}`
    : '';

export const CONFIG = {
    API_URL: `${API_HOST}${API_PATH}`,
    ELASTIC_SEARCH_QUERY_URL: ELASTIC_SEARCH_HOST
        ? `${ELASTIC_SEARCH_HOST}${ELASTIC_SEARCH_PORT_WITH_COLON}${ELASTIC_SEARCH_PATH}`
        : ELASTIC_SEARCH_PATH,
    ELASTIC_SEARCH_PORT,
    ELASTIC_SEARCH_PATH,
    PRISMIC_API_URL,
    PRISMIC_ACCESS_TOKEN,
    EXTERNAL_CHECKOUT_HOST,
    VIDEO_PLATFORM_BASE_URL,
    VIDEO_PLATFORM_START_ENDPOINT,
    GIGYA_API_KEY,
    OPEN_APP_DYNAMIC_LINK,
    PIONEER_PRISMIC_UID
};

export const ATELIER_PHONE_NUMBER = '1-855-550-3033';
export const SESSION_ID_LOCALSTORAGE_KEY = 'siduuid';
