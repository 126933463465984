import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/GlobalComponents/Fonts/Header';
import Text from 'components/GlobalComponents/Fonts/Text';
import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';
import DropTop from 'assets/500-drop-top.png';
import DropBot from 'assets/500-drop-bot.png';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';
import { setHeaderColor } from 'reducers/globalLayout';
import { theme } from 'constants/theme';

const StyledHeader = styled(Header)`
  color: ${styledProps('color', 'black')};
`;

const StyledText = styled(Text)`
  font-family: ${styledProps('font', 'SofiaPro')};
  color: ${styledProps('color', 'black')};
  font-size: ${rem(14)};
  margin-top: ${({ marginTop }) => marginTop ? rem(marginTop) : ''};
  text-transform: unset;
`;

const DropTopImg = styled.img`
  width: 75%;
`;

const DropBotImg = styled.img`
  width: 75%;
`;

const NotFoundText = styled(Box)`
  text-align: center;
`;

const DropTopImgContainer = styled(Box)`
  text-align: right;
`;

const errorAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'errorpage',
    eventAction: '500',
};


class ServerErrorContainer extends Component {

    static propTypes = {
        analyticsTag: PropTypes.func.isRequired,
        setHeaderColor: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.setHeaderColor(theme.color.transparent);
        this.props.analyticsTag({
            ...errorAnalytics,
            eventLabel: 'initiated',
            url: window.location.href
        }, { userInfo: true });
    }

    render() {
        return (
            <Flex flexDirection={'column'}>
                <DropTopImgContainer>
                    <DropTopImg src={DropTop} alt={''}/>
                </DropTopImgContainer>
                <NotFoundText>
                    <StyledHeader>OOPS!</StyledHeader>
                    <StyledText marginTop={15}>Our site is getting some beauty rest.
                        <br/>Please check in with us later.
                    </StyledText>
                </NotFoundText>
                <DropBotImg src={DropBot} alt={''}/>
            </Flex>
        );
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({ analyticsTag, setHeaderColor }, dispatch);

export default connect(null, mapDispatchToProps)(ServerErrorContainer);
