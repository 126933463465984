import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styledProps } from 'core/styled';
import Clickable from 'components/GlobalComponents/Clickable/Clickable';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';

const parenthesisDistance = 5;

const StyledParenthesesButton = styled(NoStyleButton)`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: 9px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: ${styledProps('color', 'black')};
    text-transform: uppercase;
    border-bottom: 1px solid black;
    position: relative;
    margin: 3px ${parenthesisDistance}px;
    cursor: pointer;
    transition: 0.2s color, 0.2s border-color;
`;

export default class ParenthesesButton extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
    };

    render() {
        return (
            <Clickable {...this.props}>
                {({ loading, ...clickableProps }) => (
                    <StyledParenthesesButton {...clickableProps} type={'button'}>
                        {this.props.children}
                    </StyledParenthesesButton>
                )}
            </Clickable>
        );
    }
}
