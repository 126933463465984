import { FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';
import * as Prismic from '@prismicio/client';
import { PRISMIC_ENDPOINTS } from 'constants/prismic';

export const PRISMIC_FETCH = 'PRISMIC_FETCH';
export const PRISMIC = 'PRISMIC';
export const PRISMIC_MULTIPLE = `${PRISMIC}_MULTIPLE`;
export const PRISMIC_ERROR = 'PRISMIC_ERROR';

const prismicFetch = ({ dispatch }) => next => action => {
    if (action.type !== PRISMIC_FETCH) {
        return next(action);
    }

    const {
        payload: { endpoint, prefix, args, key },
        meta
    } = action;
    const typePrefix = prefix ? `${prefix}/` : '';

    dispatch({
        type: `${typePrefix}${endpoint}/${FETCH_PENDING}`
    });

    const prismicApi = Prismic.createClient(CONFIG.PRISMIC_API_URL, {
        accessToken: CONFIG.PRISMIC_ACCESS_TOKEN
    });

    let prismicArgs;

    if (endpoint === PRISMIC_ENDPOINTS.GET_BY_DOCTYPE) {
        prismicArgs = [
            {
                filters: Prismic.filter.at('document.type', args[0]),
                pageSize: 100
            }
        ];
    } else {
        prismicArgs = [...args];
    }

    return prismicApi[endpoint](...prismicArgs)
        .then(document => {
            dispatch({
                type: `${prefix}/${key || FETCH_SUCCESS}`,
                payload: document,
                meta
            });

            return document;
        })

        .catch(err => {
            dispatch({
                type: `${PRISMIC_ERROR}`,
                payload: err
            });
            throw err;
        });
};

export default prismicFetch;
