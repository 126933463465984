import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from 'constants/routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';
import { Flex } from 'components/GlobalComponents/FlexBox';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import Header from 'components/GlobalComponents/Fonts/Header';
import LikeListButtons from 'components/ProductComponents/LikeListButtons/LikeListButtons';
import PriceTag from 'components/GlobalComponents/Fonts/product/PriceTag';
import SubHeader from 'components/GlobalComponents/Fonts/SubHeader';
import StyledAccordionItem from 'components/BeautyPlans/Common/Accordion/StyledAccordionItem';
import StyledAccordionItemHeadingWrapper from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemHeadingWrapper';
import StyledAccordionItemPanel from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemPanel';
import { Accordion, AccordionItemButton } from 'react-accessible-accordion';
import Description from 'components/GlobalComponents/Fonts/Description';

const BeautyplanProductCardWrapper = styled(Flex)`
    text-align: center;
    min-height: auto;
`;

const ProductImage = styled.img`
    ${({ isFragranceFlight }) => `max-width: ${rem(isFragranceFlight ? 250 : 120)};`}
    width: 100%;
`;

const StyledTiltedText = styled(TiltedText)`
    max-width: 135px;
`;

const StyledPriceTag = styled(PriceTag)`
    margin: ${rem(10)} 0;
`;

const CardFlex = styled(Flex)`
    padding: 0 ${rem(60)};
`;

const NoteWrapper = styled.div`
    position: relative;

    .accordionArrow {
        right: 50%;
        transform: translate(50%, 0);
        bottom: ${rem(-10)};
        top: unset;
    }
`;

const ApplicationNoteTitleNew = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(16)};
    text-align: center;
    height: ${rem(42)};
`;

class BeautyPlanProductCard extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        isStaffProfile: PropTypes.bool.isRequired,
        isEven: PropTypes.bool,
        authenticated: PropTypes.bool.isRequired,
        withProductNote: PropTypes.bool,
        showBatchCode: PropTypes.bool,
        isFragranceFlight: PropTypes.bool
    };

    renderProductNote = product => {
        return (
            <NoteWrapper>
                <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={[product.sku]}>
                    <StyledAccordionItem uuid={product.sku}>
                        <StyledAccordionItemHeadingWrapper>
                            <AccordionItemButton>
                                <ApplicationNoteTitleNew>Notes</ApplicationNoteTitleNew>
                                <div className={'accordionArrow'} />
                            </AccordionItemButton>
                        </StyledAccordionItemHeadingWrapper>
                        <StyledAccordionItemPanel>
                            <Description fontSize={14} lineHeight={1.5} dangerouslySetInnerHTML={{ __html: product.note }} />
                        </StyledAccordionItemPanel>
                    </StyledAccordionItem>
                </Accordion>
            </NoteWrapper>
        );
    };

    render() {
        const { product, push, isEven, isStaffProfile, authenticated, withProductNote, showBatchCode, isFragranceFlight } = this.props;

        return (
            <BeautyplanProductCardWrapper
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <CardFlex
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() => push(`${ROUTES.PRODUCT_DESCRIPTION}/${product.productCode}`)}
                >
                    <ProductImage isFragranceFlight src={(isFragranceFlight && product.fragranceImage) ? product.fragranceImage : product.mediumImages[0]} alt={''} />
                    <Header cssMargin={[20, 0, 5, 0]} fontSize={26} lineHeight={1}>
                        <span translate={'no'} className={'notranslate'}>
                            {product.name}
                        </span>
                    </Header>
                    <SubHeader marginBottom={[5]} fontSize={10} dangerouslySetInnerHTML={{ __html: product.subName }} />
                    <StyledTiltedText fontSize={12}
                        rotate={ isEven ? -3 : 3}
                        textPadding={[0, 8]}
                        lineHeight={1.5}>{product.variantInfo}</StyledTiltedText>
                    {showBatchCode && product.batchCode &&
                        <SubHeader marginTop={10} marginBottom={5} fontSize={8}>
                            BATCH CODE {product.batchCode}
                        </SubHeader>
                    }
                    <StyledPriceTag>${product.price}</StyledPriceTag>
                </CardFlex>
                { !isStaffProfile && authenticated && <LikeListButtons iconPadding={[20, 15]} product={product} renderDislikeButton={false} /> }
                { withProductNote && product.note && this.renderProductNote(product) }
            </BeautyplanProductCardWrapper>
        );
    }
}

const mapStateToProps = ({ user: { auth: { authenticated, isStaff, isImpersonating } } }) => ({
    isStaffProfile: isStaff && !isImpersonating,
    authenticated
});

const mapDispatchToProps = dispatch => bindActionCreators({ push }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BeautyPlanProductCard);
