import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';
import { Accordion, AccordionItemButton } from 'react-accessible-accordion';
import StyledAccordionItem from 'components/BeautyPlans/Common/Accordion/StyledAccordionItem';
import StyledAccordionItemHeadingWrapper from 'components/BeautyPlans/Common/Accordion/StyledAccordionItemHeadingWrapper';
import StyledAccordionItemPanel from './Accordion/StyledAccordionItemPanel';
import Description from 'components/GlobalComponents/Fonts/Description';


const NoteWrapper = styled.div`
    position: relative;
    ${({ cssMarginTop }) => cssMarginTop ? `margin-top: ${rem(cssMarginTop)};` : null} 

    .accordionArrow {
        right: ${rem(-25)};
        top: calc(50% + 5px);
        transform: translateY(-50%) scale(.7);
    }
`;

const ApplicationNoteTitleNew = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-size: ${rem(10)};
    text-align: center;
    height: ${rem(25)};
    cursor: pointer;
`;

const NoteContainer = styled(StyledAccordionItemPanel)`
    padding: 0;
`;

export default class SimpleProductNote extends Component {

    static propTypes = {
        artist: PropTypes.string.isRequired,
        note: PropTypes.string.isRequired,
        cssMarginTop: PropTypes.number
    };

    render() {
        const { artist, note, cssMarginTop } = this.props;

        return (
            <NoteWrapper cssMarginTop={cssMarginTop}>
                <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['a']}>
                    <StyledAccordionItem uuid={'a'}>
                        <StyledAccordionItemHeadingWrapper>
                            <AccordionItemButton>
                                <ApplicationNoteTitleNew>A Note from {artist}</ApplicationNoteTitleNew>
                                <div className={'accordionArrow'} />
                            </AccordionItemButton>
                        </StyledAccordionItemHeadingWrapper>
                        <NoteContainer>
                            <Description fontSize={12} lineHeight={1.5} dangerouslySetInnerHTML={{ __html: note }} />
                        </NoteContainer>
                    </StyledAccordionItem>
                </Accordion>
            </NoteWrapper>
        );
    }
}
