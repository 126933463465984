import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { greaterThan, inputStyle } from 'core/styled';
import styled, { css } from 'styled-components';
import { theme, medium } from 'constants/theme';
import DateTime from './DateInput.styled';
import moment from 'moment';

const Input = styled.input`
    ${inputStyle};
    
    ${greaterThan(medium)(css`
        -webkit-appearance: none;
    `)};
    outline: none;
`;

const inputDateFormat = 'YYYY-MM-DD';

export default class DateInput extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        value: PropTypes.string,
        // Moment object to determine max date
        max: PropTypes.object,
        // Moment object to determine min date
        min: PropTypes.object,
        ariaLabel: PropTypes.string,
        alwaysMobile: PropTypes.bool,
        inputRef: PropTypes.object
    };

    static defaultProps = {
        alwaysMobile: false
    };

    initialValue = moment(this.props.value).format(inputDateFormat);

    state = {
        min: this.props.min ? this.props.min.format(inputDateFormat) : '',
        max: this.props.max ? this.props.max.format(inputDateFormat) : ''
    };

    isBefore = date => !this.state.min || date.isAfter(this.state.min);

    isAfter = date => !this.state.max || date.isBefore(this.state.max);

    isValidDate = current => this.isBefore(current) && this.isAfter(current);

    handleChange = moment => {
        this.props.onChange({
            target: {
                id: this.props.id,
                value: moment.format(inputDateFormat),
                form: this.input.form
            }
        });
    };

    isDesktop = window.matchMedia(`(min-width: ${theme.breakpoints[medium]})`)
        .matches;

    render() {
        const { onBlur, alwaysMobile, inputRef } = this.props;

        if (!alwaysMobile && this.isDesktop) {
            return (
                <DateTime
                    closeOnTab
                    closeOnSelect
                    isValidDate={this.isValidDate}
                    defaultValue={this.initialValue}
                    onChange={this.handleChange}
                    onBlur={onBlur}
                    timeFormat={false}
                    inputProps={{ ...this.props, onBlur: null }}
                    renderInput={props => {
                        const { ariaLabel, ...rest } = props;
                        return (
                            <Input
                                {...rest}
                                aria-label={ariaLabel}
                                type="text"
                                ref={input => (this.input = input)}
                            />
                        );
                    }}
                />
            );
        } else {
            return <Input {...{ ...this.props, ...this.state }} type="date" ref={inputRef} />;
        }
    }
}
