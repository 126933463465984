import 'core-js/stable';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ThemeProvider } from 'styled-components';
import { store, persistor, history } from './store';
import { theme } from 'constants/theme';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import App from 'components/App/App';
import Loader from 'components/GlobalComponents/Loader/Loader';
import { SESSION_ID_LOCALSTORAGE_KEY } from 'constants/config';
import { v4 as uuidv4 } from 'uuid';
import { PrismicToolbar } from '@prismicio/react';
// import { ROUTES } from 'constants/routes';
// import registerServiceWorker, { unregister } from './registerServiceWorker';

// polyfill for smooth scrolling (Safari doesn't support smooth scrolling: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView)
// more info: https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
smoothscroll.polyfill();

const PageLoader = (
    <Flex justifyContent="center" alignItems="center">
        <Box flex={1}>
            <Loader dotColor="black" />
        </Box>
    </Flex>
);

export const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error('container not found!');
}

// IE 11 forEach polyfill
/* eslint-disable */
(function() {
    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function forEach(callback, thisArg) {
            if (typeof callback !== 'function') {
                throw new TypeError(callback + ' is not a function');
            }
            var array = this;
            thisArg = thisArg || this;
            for (var i = 0, l = array.length; i !== l; ++i) {
                callback.call(thisArg, array[i], i, array);
            }
        };
    }
    if ('NodeList' in window && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function(callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
    /* eslint-enable */
})();

// generate session uuid
(() => {
    if (!window.localStorage.getItem(SESSION_ID_LOCALSTORAGE_KEY)) {
        window.localStorage.setItem(SESSION_ID_LOCALSTORAGE_KEY, uuidv4());
    }
})();

window.prerenderReady = false;

const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <PersistGate persistor={persistor} loading={PageLoader}>
                <Router history={history}>
                    <App />
                </Router>
            </PersistGate>
        </ThemeProvider>
        <PrismicToolbar repositoryName='atelier' />
    </Provider>
);

// Learn more about service workers: http://bit.ly/CRA-PWA
// Only register service worker on checkin route, as it causes caching issues throughout the application otherwise
//window.location.pathname === ROUTES.CHECK_IN ? registerServiceWorker() : unregister();
