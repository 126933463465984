import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { normalizePayments } from 'core/utils/normalizeTransactions';

const VIRTUAL_SERVICES_PAYMENTS = 'VIRTUAL_SERVICES_PAYMENTS';
const VIRTUAL_SERVICES_PAYMENTS_RESET = 'VIRTUAL_SERVICES_PAYMENTS_RESET';
const VIRTUAL_SERVICES_SKINCARE_WORKSHOPS = 'VIRTUAL_SERVICES_SKINCARE_WORKSHOPS';
const VIRTUAL_SERVICES_SKINCARE_WORKSHOPS_RESET = 'VIRTUAL_SERVICES_SKINCARE_WORKSHOPS_RESET';
const SET_DISPLAY_VIRTUAL_PAYMENTS_PAGE = 'SET_DISPLAY_VIRTUAL_PAYMENTS_PAGE';

export const resetVirtualServicesPayments = createAction(VIRTUAL_SERVICES_PAYMENTS_RESET);
export const resetVirtualServicesSkincareWorkshops = createAction(VIRTUAL_SERVICES_SKINCARE_WORKSHOPS_RESET);
export const setDisplayVirtualPaymentsPage = createAction(SET_DISPLAY_VIRTUAL_PAYMENTS_PAGE);

const fetchPaymentInformation = createAction(FETCH, (startDate, endDate, firstName, lastName) => {
    const queryParams = new URLSearchParams();

    if (startDate) {
        queryParams.append('startDate', startDate);
    }

    if (endDate) {
        queryParams.append('endDate', endDate);
    }

    if (firstName) {
        queryParams.append('firstName', encodeURIComponent(firstName));
    }

    if (lastName) {
        queryParams.append('lastName', encodeURIComponent(lastName));
    }

    return {
        prefix: VIRTUAL_SERVICES_PAYMENTS,
        endpoint: `${CONFIG.API_URL}/servicepayment/search${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
        options: {
            method: 'GET'
        }
    };
});

export const fetchPayments = (startDate, endDate, firstName, lastName) => {
    return (dispatch) => {
        return dispatch(fetchPaymentInformation(startDate, endDate, firstName, lastName));
    };
};

const fetchSkincareWorkshopBookings = createAction(FETCH, (startDate, endDate, artistName, sessionTypeId) => {
    const queryParams = new URLSearchParams();

    if (startDate) {
        queryParams.append('startDate', startDate);
    }

    if (endDate) {
        queryParams.append('endDate', endDate);
    }

    if (artistName) {
        queryParams.append('artistName', encodeURIComponent(artistName));
    }

    if (sessionTypeId) {
        queryParams.append('sessionTypeId', sessionTypeId);
    }

    return {
        prefix: VIRTUAL_SERVICES_SKINCARE_WORKSHOPS,
        endpoint: `${CONFIG.API_URL}/scheduling/skincareworkshop/search${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
        options: {
            method: 'GET'
        }
    };
});

export const fetchSkincareWorkshops = (startDate, endDate, artistName, sessionTypeIds) => {
    return (dispatch) => {
        return dispatch(fetchSkincareWorkshopBookings(startDate, endDate, artistName, sessionTypeIds));
    };
};

const virtualPaymentsPageInitialState = {
    displayVirtualPaymentsPage: false
};

const paymentsInitialState = {
    isFetching: false,
    error: null,
    payments: []
};

const skincareWorkshopsInitialState = {
    isFetching: false,
    error: null,
    bookings: []
};

export default handleActions({
    [VIRTUAL_SERVICES_PAYMENTS_RESET]: state => ({
        ...state,
        paymentsInitialState
    }),
    [VIRTUAL_SERVICES_SKINCARE_WORKSHOPS_RESET]: state => ({
        ...state,
        skincareWorkshopsInitialState
    }),
    [`${VIRTUAL_SERVICES_PAYMENTS}/${FETCH_PENDING}`]: state => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [`${VIRTUAL_SERVICES_PAYMENTS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [`${VIRTUAL_SERVICES_PAYMENTS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        payments: normalizePayments(payload)
    }),
    [`${VIRTUAL_SERVICES_SKINCARE_WORKSHOPS}/${FETCH_PENDING}`]: state => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [`${VIRTUAL_SERVICES_SKINCARE_WORKSHOPS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [`${VIRTUAL_SERVICES_SKINCARE_WORKSHOPS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        bookings: payload
    }),
    [SET_DISPLAY_VIRTUAL_PAYMENTS_PAGE]: (state, { payload }) => ({
        ...state,
        displayVirtualPaymentsPage: payload
    })
}, { ...virtualPaymentsPageInitialState, ...paymentsInitialState, ...skincareWorkshopsInitialState });
