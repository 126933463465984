/**
 * Scroll upp to the page top NOTICE: '.appWrapper' class is content top
 * @param { string } scrollToTopClass - scroll to first element by class name
 * @param { number } top - offset of div to scroll to
 * @param { bool } animate - whether or not to animate scrolling
 */
export default function scrollToTop(scrollToTopClass = null, top = 0, animate = true) {
    const container = scrollToTopClass ? document.querySelector(`.${scrollToTopClass}`) : window;
    container && container.scrollTo({
        top: top,
        behavior: animate ? 'smooth' : 'instant'
    });
}
