import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import { rem } from 'core/styled/index';
import Header from 'components/GlobalComponents/Fonts/Header';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import { connect } from 'react-redux';
import { addNotes } from 'reducers/beautyplan';
import { bindActionCreators } from 'redux';

const SubSectionHeaderOuter = styled(Flex)`
  width: 100%;
  padding-top: 20px;
`;

const StyledHeader = styled(Header)`
  padding: 0 ${rem(20, 20, 20)};
`;

const NotesContainer = styled(Box)`
    width: 80%;
    text-align: center;
    margin: auto;
`;

const StyledFormInput = styled(FormInput)`
  padding-bottom: ${rem(10)};
`;


class TextSection extends Component {

    static propTypes = {
        section: PropTypes.object.isRequired,
        sectionId: PropTypes.string.isRequired,
        addNotes: PropTypes.func.isRequired,
        note: PropTypes.string,
    };

    /**
     * Event handler on Blur to save field value
     * @param {object} e
     */
    onBlur = (e) => {
        const { sectionId } = this.props;
        const note = e.target.innerHTML;
        this.props.addNotes({ section: sectionId, note });
    };

    render() {

        const { section, note } = this.props;

        return (
            <div>
                <SubSectionHeaderOuter justifyContent='center' alignItems='center'>
                    <StyledHeader>
                        { section.title }
                    </StyledHeader>
                </SubSectionHeaderOuter>
                <NotesContainer>
                    <StyledFormInput
                        id='notes'
                        placeholder='Type any additional notes'
                        inputfont={'ABChanelPBL'}
                        labelFont={'SofiaPro'}
                        inputfontsize={14}
                        clearOption={true}
                        inputtextalign={'center'}
                        maxLength={1000}
                        label='notes'
                        onBlur={this.onBlur}
                        value={note}
                        multiLine
                    />
                </NotesContainer>
            </div>
        );
    }

}

const mapStateToProps =  ({ beautyplan: { currentPlan: { notes } } }, { sectionId }) => {
    let note = '';
    if (notes) {
        const noteInSection = notes.find(n => n.section === sectionId);
        note = noteInSection ? noteInSection.note : '';
    }

    return {
        note
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { addNotes },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TextSection);

