import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from 'constants/theme';
import { isGreaterThan } from 'core/styled/index';

export default class ConditionalBreak extends Component {
    static propTypes = {
        includeSpace: PropTypes.bool,
        showMobile: PropTypes.bool,
        showTablet: PropTypes.bool,
        showDesktop: PropTypes.bool
    };

    static defaultProps = {
        includeSpace: true,
        showMobile: true,
        showTablet: true,
        showDesktop: true
    };

    constructor(props) {
        super(props);

        this.state = {
            display: this.getDisplayState()
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDisplay);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDisplay);
    }

    /**
     * Returns either a <br>, a space, or empty string.
     * @returns {string} - <br>, space, or empty string
     */
    getDisplayState = () => {
        const { includeSpace, showMobile, showTablet, showDesktop } = this.props;
        let shouldShowBreak = showMobile;

        if (isGreaterThan(breakpoints.small)) {
            shouldShowBreak = showTablet;
        }

        if (isGreaterThan(breakpoints.medium)) {
            shouldShowBreak = showDesktop;
        }

        return shouldShowBreak ? <br/> : includeSpace ? ' ' : '';
    };

    /**
     * Update the state to trigger a render.
     */
    updateDisplay = () => {
        this.setState({
            display: this.getDisplayState()
        });
    };

    render() {
        return this.state.display;
    }
}
