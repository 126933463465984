import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';

const RSVP_FETCH = 'RSVP_FETCH';
const RSVP_CONFIRM = 'RSVP_CONFIRM';
const RSVP_REJECT = 'RSVP_REJECT';
const RSVP_RESET = 'RSVP_RESET';
const RSVP_SET_APPOINTMENT_ID = 'RSVP_SET_APPOINTMENT_ID';

const initialState = {
    appointmentId: null,
    isFetching: false,
    error: null,
    rsvpInfo: null,
    confirmedRSVP: null
};

const getRsvpInfo = createAction(FETCH, appointmentId => ({
    prefix: RSVP_FETCH,
    endpoint: `${CONFIG.API_URL}/scheduling/appointment/rsvp/${appointmentId}`
}));

const postRsvp = createAction(FETCH, (appointmentId, rsvpValue) => ({
    prefix: RSVP_CONFIRM,
    endpoint: `${CONFIG.API_URL}/scheduling/appointment/rsvp/${appointmentId}`,
    options: {
        method: 'POST',
        body: JSON.stringify({
            appointmentId,
            response: rsvpValue
        })
    }
}));

const confirmRsvp = createAction(RSVP_CONFIRM);
const rejectRsvp = createAction(RSVP_REJECT);
const setAppointmentId = createAction(RSVP_SET_APPOINTMENT_ID);
export const resetRsvp = createAction(RSVP_RESET);

export const fetchRsvpInfo = appointmentId => {
    return dispatch => {
        dispatch(setAppointmentId(appointmentId));
        return dispatch(getRsvpInfo(appointmentId));
    };
};

export const doRsvp = (rsvpValue) => {
    return (dispatch, getState) => {
        if (rsvpValue) {
            dispatch(confirmRsvp());
        } else {
            dispatch(rejectRsvp());
        }

        return dispatch(postRsvp(getState().rsvp.appointmentId, rsvpValue ? 'YES' : 'NO'));
    };
};

export default handleActions({
    [`${RSVP_FETCH}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        isFetching: true
    }),
    [`${RSVP_FETCH}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    }),
    [`${RSVP_FETCH}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        rsvpInfo: payload[0],
        isFetching: false
    }),
    [RSVP_CONFIRM]: (state) => ({
        ...state,
        confirmedRSVP: 'confirmed'
    }),
    [RSVP_REJECT]: (state) => ({
        ...state,
        confirmedRSVP: 'rejected'
    }),
    [RSVP_SET_APPOINTMENT_ID]: (state, { payload }) => ({
        ...state,
        appointmentId: payload
    }),
    [RSVP_RESET]: (state) => ({
        ...state,
        ...initialState
    })
}, initialState);
