import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { rem } from 'core/styled/index';
import styled from 'styled-components';
import { BEAUTYPLAN_CONFIG } from 'constants/beautyplan';
import SimpleTextSection from './SimpleTextSection';
import SimpleSubSection from './SimpleSubSection';

const CategoryContainer = styled.div`
    padding-top: ${rem(40)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${rem(0, 30)};
    max-width: ${rem(972)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${rem(0, 'auto')};
`;

export default class NewSimpleRoutine extends Component {

    static propTypes = {
        sectionConfig: PropTypes.string.isRequired
    };

    stepConfig = BEAUTYPLAN_CONFIG[this.props.sectionConfig];

    /**
     * Render the categories for choosing products.
     * @returns {object[]} - Rendered categories with subsections
     */
    renderCategories = () => {

        return this.stepConfig.categories.map((category, key) => {
            return (
                <CategoryContainer key={key}>

                    { this.renderSubSections(category.subSections, category) }

                </CategoryContainer>
            );
        });
    };

    /**
     * Render the free-form text areas for the step.
     *
     * @returns {object[]} - Rendered text sections
     */
    renderTextSections = () => {
        return this.stepConfig.textSections.map((section, key) => {
            return (
                <SimpleTextSection sectionId={this.stepConfig.id} section={section} key={key} />
            );
        });
    };

    /**
     * Render the subsections for a category.
     * @param {object[]} subSections
     * @param {object} category
     * @returns {object[]} - Rendered subsections
     */
    renderSubSections = (subSections, category) => {

        return subSections.map((subSection, key) => {
            return (
                <SimpleSubSection id={this.stepConfig.id}
                    category={category}
                    subSection={subSection}
                    key={key}
                    first={key === 0}/>
            );
        });
    };

    render() {
        return (
            <Wrapper>
                { this.renderTextSections() }
                { this.renderCategories() }
            </Wrapper>
        );
    }
}
