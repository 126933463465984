import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { PrimaryModal } from 'components/GlobalComponents/Modal/PrimaryModal';
import { Flex } from 'components/GlobalComponents/FlexBox';
import { greaterThan, rem, styledProps } from 'core/styled';
import Button from 'components/GlobalComponents/Button/Button';
import connect from 'react-redux/es/connect/connect';
import { breakpoints } from 'constants/theme';

const DEFAULT_IMAGE = 'https://images.prismic.io/atelier/d73574e1-2d6f-411d-83bc-ee40cdc71536_The+Atelier.jpg?auto=compress,format';

const ModalHeader = styled.h3`
    margin: ${rem(0)};
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-weight: 400;
    font-size: ${rem(20)};
    text-align: center;
    padding: ${rem(30, 0, 20)};
`;

const ResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${greaterThan(breakpoints.verticalIpad)(css`
        flex-wrap: wrap;
        flex-direction: row;
    `)}
`;

const ResultItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: ${rem(20, 10)};
    margin-bottom: ${rem(20)};
    position: relative;

    img {
        width: ${rem(150)};
        height: ${rem(150)};
        object-fit: cover;
        margin-bottom: ${rem(10)};
    }

    p.bp--servicename {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        font-size: ${rem(14)};
        margin: ${rem(10, 0, 0)};
    }

    button {
        margin-top: ${rem(10)}
    }

    ${greaterThan(breakpoints.verticalIpad)(css`
        width: 50%;
    `)}
`;

class SimpleSubSectionServiceModal extends Component {

    static propTypes = {
        subSection: PropTypes.object.isRequired,
        addService: PropTypes.func.isRequired,
        services: PropTypes.array.isRequired
    };

    state = {
        showModal: false,
    };

    addService = service => {
        this.props.addService(service);
        this.toggleModal();
    };

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    renderService = (service, index) => {
        const image = service.image || DEFAULT_IMAGE;

        const serviceDataToStore = {
            serviceId: service.serviceId
        };

        return (
            <ResultItemWrapper key={index}>
                <img src={image} alt={''} />
                <p className={'bp--servicename'}>{service.name}</p>
                <Button variant={'primary'} type={'button'} small onClick={() => this.addService(serviceDataToStore)}>Add Service</Button>
            </ResultItemWrapper>
        );
    };

    renderServices = () => {
        const { services } = this.props;

        return (
            <ResultsWrapper>
                {services.map(this.renderService)}
            </ResultsWrapper>
        );
    };

    render() {
        const { showModal } = this.state;

        if (!showModal) {
            return null;
        }

        return (
            <PrimaryModal onRequestClose={this.toggleModal}
                showClose={true}
                flexBody={false}
                fullScreen={true}
                previousScrollDisabledState={false}
                preventScrollToTopOnOpen={true}>
                <Flex flexDirection='column' justifyContent='flex-start' alignItems={'center'}>
                    <ModalHeader>Recommend a service</ModalHeader>
                    {this.renderServices()}
                </Flex>
            </PrimaryModal>
        );
    }
}

const mapStateToProps = ({ booking: { sessions } }) => ({
    services: Object.keys(sessions).map(key => {
        const serviceData = sessions[key].All;

        if (!serviceData.showInBeautyplan) {
            return null;
        }

        return {
            serviceId: serviceData.id[0],
            name: serviceData.beautyplanName.replace(/-/g, '\u2011'),
            subName: serviceData.beautyplanSubname,
            price: serviceData.price,
            image: serviceData.image
        };
    }).filter(s => s !== null)
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(SimpleSubSectionServiceModal);
