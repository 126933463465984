import { createAction } from 'redux-actions';
import createFetchReducer from 'reducers/createFetchReducer';
import { FETCH } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';

export const MAILER_RETURN = 'MAILER_RETURN';

export const returnMailer = createAction(FETCH, request => ({
    prefix: MAILER_RETURN,
    endpoint: `${CONFIG.API_URL}/mailer/return`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

export default createFetchReducer(MAILER_RETURN);
