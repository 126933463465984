import SkincareImage from 'assets/beautyplan/skinservice.png';
import SkincareImageDesktop from 'assets/beautyplan/skinservice-desktop.jpg';
import SkincareImageTablet from 'assets/beautyplan/skinservice-tablet.jpg';
import MakeupApplicationImage from 'assets/beautyplan/application.png';
import MakeupApplicationImageDesktop from 'assets/beautyplan/application-desktop.jpg';
import MakeupApplicationImageTablet from 'assets/beautyplan/application-tablet.jpg';
import LessonImage from 'assets/beautyplan/lesson.png';
import LessonImageDesktop from 'assets/beautyplan/lesson-desktop.jpg';
import LessonImageTablet from 'assets/beautyplan/lesson-tablet.jpg';
import CleanseImage from 'assets/beautyplan/cleanse.png';
import CleansePmImage from 'assets/beautyplan/cleanse-pm.png';
import ToneImage from 'assets/beautyplan/tone.png';
import TonePmImage from 'assets/beautyplan/tone-pm.png';
import TargetImage from 'assets/beautyplan/target.png';
import TargetPmImage from 'assets/beautyplan/target-pm.png';
import MoisturizeImage from 'assets/beautyplan/moisturize.png';
import MoisturizePmImage from 'assets/beautyplan/moisturize-pm.png';
import ProtectImage from 'assets/beautyplan/protect.png';
import EvenImage from 'assets/beautyplan/even.png';
import RemoverImage from 'assets/beautyplan/makeupremover-pm.png';
import SkinchatImage from 'assets/beautyplan/skinchat.jpg';
import MakeupchatImage from 'assets/beautyplan/makeupchat.jpg';
import FragranceExperienceImage from 'assets/beautyplan/fragranceexperience.png';
import BeautyGuideTalkImage from 'assets/beautyplan/beautyguidetalk.png';
import AtHomeFacialImage from 'assets/beautyplan/athomefacial.jpg';

import CleanseSeparatorDesktop from 'assets/beautyplan/cleanse-separator-desktop.jpg';
import CleanseSeparatorAMDesktop from 'assets/beautyplan/cleanse-am-separator-desktop.jpg';
import ToneSeparatorDesktop from 'assets/beautyplan/tone-separator-desktop.jpg';
import ToneSeparatorDesktopPM from 'assets/beautyplan/tone-separator-desktop-pm.jpg';

import TargetSeparatorDesktop from 'assets/beautyplan/target-separator-desktop.jpg';
import TargetSeparatorDesktopPM from 'assets/beautyplan/target-separator-desktop-pm.jpg';

import MoistSeparatorDesktop from 'assets/beautyplan/moist-separator-desktop.jpg';
import MoistSeparatorDesktopPM from 'assets/beautyplan/moist-separator-desktop-pm.jpg';

import ProtectSeparatorDesktop from 'assets/beautyplan/protect-separator-desktop.jpg';
import EvenSeparatorDesktop from 'assets/beautyplan/even-separator-desktop.jpg';
import MakeupSeparatorDesktop from 'assets/beautyplan/makeup-separator-desktop.jpg';

import CleanseSeparatorTablet from 'assets/beautyplan/cleanse-separator-tablet.jpg';
import ToneSeparatorTablet from 'assets/beautyplan/tone-separator-tablet.jpg';
import TargetSeparatorTablet from 'assets/beautyplan/target-separator-tablet.jpg';
import MoistSeparatorTablet from 'assets/beautyplan/moist-separator-tablet-am.jpg';
import MoistSeparatorTabletPM from 'assets/beautyplan/moist-separator-tablet.jpg';
import ProtectSeparatorTablet from 'assets/beautyplan/protect-separator-tablet.jpg';
import EvenSeparatorTablet from 'assets/beautyplan/even-separator-tablet.jpg';

import MakeupSeparatorTablet from 'assets/beautyplan/makeup-separator-desktop.jpg';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';

/*
  Never change this.
 */
export const NON_CHANEL = 'non-chanel';

export const BEAUTYPLAN_SECTIONS = {
    SAMPLE_KIT_5: 'SAMPLE_KIT_5',
    SAMPLE_KIT_3: 'SAMPLE_KIT_3',
    SKINCARE_ROUTINE: 'SKINCARE_ROUTINE',
    MAKEUP_ROUTINE: 'MAKEUP_ROUTINE',
    BROW_LOOK: 'BROW_LOOK',
    NEW_SKINCARE_ROUTINE: 'NEW_SKINCARE_ROUTINE',
    NEW_MAKEUP_ROUTINE: 'NEW_MAKEUP_ROUTINE',
    NEW_BROW_LOOK: 'NEW_BROW_LOOK',
    VIRTUAL_SKIN_CONSULTATION: 'VIRTUAL_SKIN_CONSULTATION',
    FRAGRANCE_EXPERIENCE: 'FRAGRANCE_EXPERIENCE',
    BEAUTY_GUIDE_TALK: 'BEAUTY_GUIDE_TALK',
    AT_HOME_FACIAL: 'AT_HOME_FACIAL',
    NEW_SKIN_ROUTINE: 'NEW_SKIN_ROUTINE',
    NEW_MKUP_ROUTINE: 'NEW_MKUP_ROUTINE'
};

export const BEAUTYPLAN_SEARCH_TYPE = {
    LIST: 'list',
    SEARCH: 'search',
    SEARCH_AND_NOTE: 'searchAndNote',
    EVENT: 'event',
    SERVICE: 'service'
};

export const BEAUTYPLAN_SKINCARE_TYPE = {
    AM: 'workplanCategoryAM',
    PM: 'workplanCategoryPM'
};

export const BEAUTYPLAN_CATEGORY = {
    CLEANSE: 'Cleanse',
    TONE: 'Tone',
    TARGET: 'Target',
    MOISTURIZE: 'Moisturize',
    PROTECT: 'Protect',
    EVEN: 'Even',
    FOUNDATION: 'Foundation',
    REMOVER: 'Makeup Remover'
};

export const STAFF_QUESTION_TYPE = {
    RADIO: 'radio'
};

// Define the background images for Customer view Beauty plan, based on subSection id
export const BEAUTYPLAN_CATEGORY_BACKGROUND = {
    cleanse: {
        am: {
            mobile: CleanseImage,
            desktop: CleanseSeparatorAMDesktop,
            tablet: CleanseSeparatorTablet
        },
        pm: {
            mobile: CleansePmImage,
            desktop: CleanseSeparatorDesktop,
            tablet: CleanseSeparatorTablet
        }
    },
    toner: {
        am: {
            mobile: ToneImage,
            desktop: ToneSeparatorDesktop,
            tablet: ToneSeparatorTablet
        },
        pm: {
            mobile: TonePmImage,
            desktop: ToneSeparatorDesktopPM,
            tablet: ToneSeparatorTablet
        }
    },
    target: {
        am: {
            mobile: TargetImage,
            desktop: TargetSeparatorDesktop,
            tablet: TargetSeparatorTablet
        },
        pm: {
            mobile: TargetPmImage,
            desktop: TargetSeparatorDesktopPM,
            tablet: TargetSeparatorTablet
        }
    },
    moisturize: {
        am: {
            mobile: MoisturizeImage,
            desktop: MoistSeparatorDesktop,
            tablet: MoistSeparatorTablet
        },
        pm: {
            mobile: MoisturizePmImage,
            desktop: MoistSeparatorDesktopPM,
            tablet: MoistSeparatorTabletPM
        }
    },
    protect: {
        am: {
            mobile: ProtectImage,
            desktop: ProtectSeparatorDesktop,
            tablet: ProtectSeparatorTablet
        },
        pm: {
            mobile: ProtectImage,
            desktop: ProtectSeparatorDesktop,
            tablet: ProtectSeparatorTablet
        }
    },
    even: {
        am: {
            mobile: EvenImage,
            desktop: EvenSeparatorDesktop,
            tablet: EvenSeparatorTablet
        },
        pm: {
            mobile: EvenImage,
            desktop: EvenSeparatorDesktop,
            tablet: EvenSeparatorTablet
        }
    },
    makeupRemover: {
        am: {
            mobile: RemoverImage,
            desktop: MakeupSeparatorDesktop,
            tablet: MakeupSeparatorTablet
        },
        pm: {
            mobile: RemoverImage,
            desktop: MakeupSeparatorDesktop,
            tablet: MakeupSeparatorTablet
        }
    }
};

export const sectionTitle = {
    cleanse: 'Cleanse',
    toner: 'Tone',
    target: 'Target',
    moisturize: 'Moisturize',
    protect: 'Protect',
    even: 'Even',
    makeupRemover: 'Makeup Remover',
    face: 'Face',
    eyes: 'Eyes',
    lips: 'Lips',
    productsUsed: 'Products Used',
    fragrance_1: 'Fragrance 1',
    fragrance_2: 'Fragrance 2',
    fragrance_3: 'Fragrance 3',
    products: 'Products',
    events: 'Events',
    services: 'Services',
    techniques: 'Techniques',
    gifting: 'Gifting',
    other: 'Other'
};

export const SECTION_ID = {
    TRIAL: 'trial',
    SKINCARE: 'skincare',
    MAKEUP: 'makeup',
    BROW_LOOK: 'browlook',
    FRAGRANCE: 'fragrance',
    BEAUTY_GUIDE_TALK: 'beautyguidetalk',
    AT_HOME_FACIAL: 'athomefacial',
    NEW_SKIN_ROUTINE: 'skinroutine',
    NEW_MAKEUP_LESSON: 'makeuplesson',
    NEW_MAKEUP_APPLICATION: 'makeupapplication',
    EYE_MAKEUP_APPLICATION: 'eyemakeupapplication'
};

export const SECTION_ANALYTICS = {
    [SECTION_ID.TRIAL]: 'trialkit',
    [SECTION_ID.SKINCARE]: 'skinroutine',
    [SECTION_ID.MAKEUP]: 'makeuproutine',
    [SECTION_ID.BROW_LOOK]: 'browlookproducts',
    [SECTION_ID.FRAGRANCE]: 'fragranceexperience',
    [SECTION_ID.BEAUTY_GUIDE_TALK]: 'beautyguidetalk',
    [SECTION_ID.AT_HOME_FACIAL]: 'athomefacial',
    [SECTION_ID.NEW_SKIN_ROUTINE]: 'skinroutine',
    [SECTION_ID.NEW_MAKEUP_LESSON]: 'makeuplesson',
    [SECTION_ID.NEW_MAKEUP_APPLICATION]: 'makeupapplication',
    [SECTION_ID.EYE_MAKEUP_APPLICATION]: 'eyemakeupapplication'
};

export const BEAUTYPLAN_SKINCARE_PRODUCT_ORDER = [
    {
        category: BEAUTYPLAN_CATEGORY.CLEANSE,
        isAm: true,
        isPm: true
    },
    {
        category: BEAUTYPLAN_CATEGORY.TONE,
        isAm: false,
        isPm: true
    },
    {
        category: BEAUTYPLAN_CATEGORY.TARGET,
        isAm: false,
        isPm: true
    },
    {
        category: BEAUTYPLAN_CATEGORY.MOISTURIZE,
        isAm: false,
        isPm: true
    },
    {
        category: BEAUTYPLAN_CATEGORY.PROTECT,
        isAm: false,
        isPm: true
    },
    {
        category: BEAUTYPLAN_CATEGORY.EVEN,
        isAm: false,
        isPm: true
    },
    {
        category: BEAUTYPLAN_CATEGORY.REMOVER,
        isAm: false,
        isPm: true
    }
];

export const BEAUTYPLAN_CONFIG = {
    [BEAUTYPLAN_SECTIONS.SAMPLE_KIT_3]: {
        id: SECTION_ID.TRIAL,
        title: 'The\n&nbsp;Kit&nbsp;',
        description:
            'Custom-made for you, your kit introduces new products into your everyday routine.',
        section: {
            id: 'sample-kit',
            type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
            limit: 3
        },
        validationRequired: true,
        analytics: SECTION_ANALYTICS[SECTION_ID.TRIAL]
    },

    [BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5]: {
        id: SECTION_ID.TRIAL,
        title: 'The\n&nbsp;Kit&nbsp;',
        description:
            'Custom-made for you, your kit introduces new products into your everyday routine.',
        section: {
            id: 'sample-kit',
            type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
            limit: 5
        },
        validationRequired: true,
        analytics: SECTION_ANALYTICS[SECTION_ID.TRIAL]
    },
    [BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE]: {
        id: SECTION_ID.SKINCARE,
        title: 'Skin\n Routine',
        description:
            'Introduce these new products to your daily regimen based on the instructions below. Take note of how your skin feels and reach out to [name] if you have any questions or would like a follow-up.',
        analytics: SECTION_ANALYTICS[SECTION_ID.SKINCARE],
        categories: [
            {
                title: 'Morning',
                id: BEAUTYPLAN_SKINCARE_TYPE.AM,
                timeofday: 'morning',
                subSections: [
                    {
                        id: 'cleanse',
                        title: sectionTitle.cleanse,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.CLEANSE,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'toner',
                        title: sectionTitle.toner,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.TONE,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'target',
                        title: sectionTitle.target,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.TARGET,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'moisturize',
                        title: sectionTitle.moisturize,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.MOISTURIZE,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'protect',
                        title: sectionTitle.protect,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.PROTECT,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'even',
                        title: sectionTitle.even,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.EVEN,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    }
                ]
            },
            {
                title: 'Evening',
                id: BEAUTYPLAN_SKINCARE_TYPE.PM,
                timeofday: 'evening',
                subSections: [
                    {
                        id: 'makeupRemover',
                        title: sectionTitle.makeupRemover,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.REMOVER,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'cleanse',
                        title: sectionTitle.cleanse,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.CLEANSE,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'toner',
                        title: sectionTitle.toner,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.TONE,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'target',
                        title: sectionTitle.target,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.TARGET,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'moisturize',
                        title: sectionTitle.moisturize,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        category: BEAUTYPLAN_CATEGORY.MOISTURIZE,
                        allowNonChanel: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    }
                ]
            }
        ],
        textSections: [{ title: 'Notes' }],
        staffQuestions: [
            {
                title: 'Have you ever used or purchased CHANEL skincare?',
                type: STAFF_QUESTION_TYPE.RADIO,
                id: 'purchased_used_skincare',
                questions: [
                    {
                        value: 'purchased',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'used',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'no',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    }
                ]
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE]: {
        id: SECTION_ID.MAKEUP,
        title: 'Makeup\n Routine',
        description:
            'Meet the products that your Artist used during your one-on-one booking.',
        analytics: SECTION_ANALYTICS[SECTION_ID.MAKEUP],
        categories: [
            {
                title: 'Products Used',
                id: 'products_used',
                subSections: [
                    { id: 'face', title: sectionTitle.face, type: 'search' },
                    { id: 'eyes', title: sectionTitle.eyes, type: 'search' },
                    { id: 'lips', title: sectionTitle.lips, type: 'search' }
                ]
            }
        ],
        textSections: [{ title: 'Notes' }],
        staffQuestions: [
            {
                title: 'Have you ever used or purchased CHANEL makeup?',
                type: STAFF_QUESTION_TYPE.RADIO,
                id: 'purchased_used_makeup',
                questions: [
                    {
                        value: 'purchased',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'used',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'no',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    }
                ]
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.BROW_LOOK]: {
        id: SECTION_ID.BROW_LOOK,
        title: 'Brow\n Look',
        description:
            'Meet the products that your Artist used during your one-on-one booking.',
        analytics: SECTION_ANALYTICS[SECTION_ID.BROW_LOOK],
        categories: [
            {
                title: 'Products Used',
                id: 'products_used',
                subSections: [
                    {
                        id: 'productsUsed',
                        title: sectionTitle.productsUsed,
                        type: 'search'
                    }
                ]
            }
        ],
        textSections: [{ title: 'Notes' }]
    },

    [BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE]: {
        id: SECTION_ID.MAKEUP,
        title: 'Makeup\n Routine',
        analytics: SECTION_ANALYTICS[SECTION_ID.MAKEUP],
        categories: [
            {
                title: 'Products Used',
                id: 'products_used',
                subSections: [
                    {
                        id: 'face',
                        title: sectionTitle.face,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        allowNonChanel: true,
                        allowNonChanelNote: true
                    },
                    {
                        id: 'eyes',
                        title: sectionTitle.eyes,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        allowNonChanel: true,
                        allowNonChanelNote: true
                    },
                    {
                        id: 'lips',
                        title: sectionTitle.lips,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        allowNonChanel: true,
                        allowNonChanelNote: true
                    }
                ]
            }
        ],
        textSections: [
            {
                title: 'A note from your',
                titleArtistType: true,
                showArtistPicture: true
            }
        ],
        staffQuestions: [
            {
                title: 'Have you ever used or purchased CHANEL makeup?',
                type: STAFF_QUESTION_TYPE.RADIO,
                id: 'purchased_used_makeup',
                questions: [
                    {
                        value: 'purchased',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'used',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'no',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    }
                ]
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.NEW_SKINCARE_ROUTINE]: {
        id: SECTION_ID.SKINCARE,
        title: 'Skin\n Routine',
        analytics: SECTION_ANALYTICS[SECTION_ID.SKINCARE],
        categories: [
            {
                title: 'Morning',
                id: BEAUTYPLAN_SKINCARE_TYPE.AM,
                timeofday: 'morning',
                subSections: [
                    {
                        id: 'cleanse',
                        title: sectionTitle.cleanse,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.CLEANSE,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'toner',
                        title: sectionTitle.toner,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.TONE,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'target',
                        title: sectionTitle.target,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.TARGET,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'moisturize',
                        title: sectionTitle.moisturize,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.MOISTURIZE,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'protect',
                        title: sectionTitle.protect,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.PROTECT,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    },
                    {
                        id: 'even',
                        title: sectionTitle.even,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.EVEN,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.AM
                    }
                ]
            },
            {
                title: 'Evening',
                id: BEAUTYPLAN_SKINCARE_TYPE.PM,
                timeofday: 'evening',
                subSections: [
                    {
                        id: 'makeupRemover',
                        title: sectionTitle.makeupRemover,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.REMOVER,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'cleanse',
                        title: sectionTitle.cleanse,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.CLEANSE,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'toner',
                        title: sectionTitle.toner,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.TONE,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'target',
                        title: sectionTitle.target,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.TARGET,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    },
                    {
                        id: 'moisturize',
                        title: sectionTitle.moisturize,
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        category: BEAUTYPLAN_CATEGORY.MOISTURIZE,
                        allowNonChanel: true,
                        allowNonChanelNote: true,
                        categoryId: BEAUTYPLAN_SKINCARE_TYPE.PM
                    }
                ]
            }
        ],
        textSections: [
            {
                title: 'A note from your',
                titleArtistType: true,
                showArtistPicture: true
            }
        ],
        staffQuestions: [
            {
                title: 'Have you ever used or purchased CHANEL skincare?',
                type: STAFF_QUESTION_TYPE.RADIO,
                id: 'purchased_used_skincare',
                questions: [
                    {
                        value: 'purchased',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'used',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    },
                    {
                        value: 'no',
                        checkboxName:
                            SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3
                    }
                ]
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.NEW_BROW_LOOK]: {
        id: SECTION_ID.BROW_LOOK,
        title: 'Brow\n Look',
        analytics: SECTION_ANALYTICS[SECTION_ID.BROW_LOOK],
        categories: [
            {
                title: 'Products Used',
                id: 'products_used',
                subSections: [
                    {
                        id: 'productsUsed',
                        title: sectionTitle.productsUsed,
                        type: 'search',
                        productNote: true
                    }
                ]
            }
        ],
        textSections: [{ title: 'A note from Jimena' }]
    },

    [BEAUTYPLAN_SECTIONS.FRAGRANCE_EXPERIENCE]: {
        id: SECTION_ID.FRAGRANCE,
        title: 'Fragrance\n Journey',
        analytics: SECTION_ANALYTICS[SECTION_ID.FRAGRANCE],
        categories: [
            {
                title: 'Fragrance Flight',
                id: 'fragrance_flight',
                showCategoryTitle: true,
                subSections: [
                    {
                        id: 'fragrance_1',
                        title: 'Fragrance 1',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        limit: 1,
                        allowNonChanel: false,
                        allowNonChanelNote: false,
                        isFragranceFlight: true
                    },
                    {
                        id: 'fragrance_2',
                        title: 'Fragrance 2',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        limit: 1,
                        allowNonChanel: false,
                        allowNonChanelNote: false,
                        isFragranceFlight: true
                    },
                    {
                        id: 'fragrance_3',
                        title: 'Fragrance 3',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        limit: 1,
                        allowNonChanel: false,
                        allowNonChanelNote: false,
                        isFragranceFlight: true
                    }
                ]
            },
            {
                title: 'Scent Kit',
                id: 'scent_kit',
                showCategoryTitle: true,
                subSections: [
                    {
                        id: 'scent_kit',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        limit: 16,
                        allowNonChanel: false,
                        allowNonChanelNote: false,
                        isFragranceFlight: true
                    }
                ]
            }
        ],
        textSections: [{ title: 'Service Notes' }]
    },

    [BEAUTYPLAN_SECTIONS.BEAUTY_GUIDE_TALK]: {
        id: SECTION_ID.BEAUTY_GUIDE_TALK,
        title: 'Talk to a\n Beauty Guide',
        analytics: SECTION_ANALYTICS[SECTION_ID.BEAUTY_GUIDE_TALK],
        categories: [
            {
                id: 'main',
                subSections: [
                    {
                        id: 'products',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        productNote: true,
                        title: sectionTitle.products
                    },
                    {
                        id: 'events',
                        type: BEAUTYPLAN_SEARCH_TYPE.EVENT,
                        productNote: true,
                        title: sectionTitle.events
                    },
                    {
                        id: 'services',
                        type: BEAUTYPLAN_SEARCH_TYPE.SERVICE,
                        productNote: true,
                        title: sectionTitle.services
                    }
                ]
            }
        ],
        textSections: [
            {
                id: 'techniques',
                title: sectionTitle.techniques,
                placeholder: 'Recap the techniques you discussed here'
            },
            {
                id: 'gifting',
                title: sectionTitle.gifting,
                placeholder: 'Recap your gifting suggestions here'
            },
            {
                id: 'other',
                title: sectionTitle.other,
                placeholder: 'Add your notes here'
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.AT_HOME_FACIAL]: {
        id: SECTION_ID.AT_HOME_FACIAL,
        title: 'At-Home Facial 101',
        analytics: SECTION_ANALYTICS[SECTION_ID.AT_HOME_FACIAL],
        categories: [
            {
                id: 'main',
                subSections: [
                    {
                        id: 'products',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH,
                        title: sectionTitle.products
                    }
                ]
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.NEW_SKIN_ROUTINE]: {
        id: SECTION_ID.SKINCARE,
        title: 'Skin Consultation',
        analytics: SECTION_ANALYTICS[SECTION_ID.SKINCARE],
        categories: [
            {
                id: 'main',
                subSections: [
                    {
                        id: 'cleanse',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Cleanse',
                        inKitOption: true,
                        sampleNumber: true,
                        allowNonChanel: true
                    },
                    {
                        id: 'tone',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Tone',
                        inKitOption: true,
                        sampleNumber: true,
                        allowNonChanel: true
                    },
                    {
                        id: 'target',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Target',
                        inKitOption: true,
                        sampleNumber: true,
                        allowNonChanel: true
                    },
                    {
                        id: 'moisturize',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Moisturize',
                        inKitOption: true,
                        sampleNumber: true,
                        allowNonChanel: true
                    },
                    {
                        id: 'protect',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Protect',
                        inKitOption: true,
                        sampleNumber: true,
                        allowNonChanel: true
                    },
                    {
                        id: 'even',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Even',
                        inKitOption: true,
                        sampleNumber: true,
                        allowNonChanel: true
                    }
                ]
            }
        ],
        textSections: [
            {
                id: 'servicenotes',
                title: 'Service Notes',
                placeholder: 'Add your service recap here'
            }
        ]
    },

    [BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE]: {
        id: SECTION_ID.MAKEUP,
        title: 'Makeup Lesson',
        analytics: SECTION_ANALYTICS[SECTION_ID.MAKEUP],
        categories: [
            {
                id: 'main',
                subSections: [
                    {
                        id: 'face',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Face',
                        allowNonChanel: true
                    },
                    {
                        id: 'eyes',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Eyes',
                        allowNonChanel: true
                    },
                    {
                        id: 'lips',
                        type: BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE,
                        title: 'Lips',
                        allowNonChanel: true
                    }
                ]
            }
        ],
        textSections: [
            {
                id: 'servicenotes',
                title: 'Service Notes',
                placeholder: 'Add your service recap here'
            }
        ]
    }
};

export const SERVICE_RECAP_QUESTION_TYPE = {
    OPTIONS: 'options',
    TEXT: 'text'
};

export const SERVICE_RECAP_SECTIONS = {
    MAKEUP_CHAT: 'MAKEUP_CHAT',
    SKIN_CHAT: 'SKIN_CHAT'
};

export const SERVICE_RECAP_CONFIG = {
    [SERVICE_RECAP_SECTIONS.MAKEUP_CHAT]: {
        title: 'Makeup Routine',
        questions: [
            {
                title: 'What competitive brands were mentioned?',
                type: SERVICE_RECAP_QUESTION_TYPE.OPTIONS,
                id: 'brands_mentioned',
                freeText: true,
                freeTextTitle: 'Other',
                freeTextPlaceholder: 'List any other brands mentioned here',
                options: [
                    'Anastasia',
                    'Benefit',
                    'Bobby Brown',
                    'By Terry',
                    'Charlotte Tilbury',
                    'Cle de Peau',
                    'Dior',
                    'Fenty',
                    'Glossier',
                    'Lancome',
                    'Laura Mercier',
                    'MAC',
                    'Nars',
                    'Nyx',
                    'Pat Mcgrath',
                    'Sephora brand',
                    'Smashbox',
                    'Stilla',
                    'Tom Ford',
                    'Trish Mcevoy',
                    'Urban Decay'
                ]
            },
            {
                title: 'Challenges/note',
                type: SERVICE_RECAP_QUESTION_TYPE.OPTIONS,
                id: 'challenges_note',
                freeText: true,
                freeTextTitle: 'Other',
                freeTextPlaceholder: 'List the concerns/topics here',
                options: [
                    'Call quality',
                    'Competitor',
                    'Connectivity',
                    'Guest lighting',
                    'Last minute booking',
                    'No show',
                    'Ran out of time'
                ]
            },
            {
                title: 'Anything else we should know?',
                type: SERVICE_RECAP_QUESTION_TYPE.TEXT,
                id: 'anything_else',
                freeTextPlaceholder: 'List the concerns/topics here'
            }
        ]
    },
    [SERVICE_RECAP_SECTIONS.SKIN_CHAT]: {
        title: 'Skin Routine',
        questions: [
            {
                title: 'What concerns/topics were evoked?',
                type: SERVICE_RECAP_QUESTION_TYPE.OPTIONS,
                id: 'concerns_topics',
                freeText: true,
                freeTextTitle: 'Other',
                freeTextPlaceholder: 'List the concerns/topics here',
                options: [
                    'Acids',
                    'Acne',
                    'Anti age: fine lines, lifting',
                    'Brightening',
                    'Dehydration',
                    'Evenness of skin',
                    'Exfoliating',
                    'Facial massage/exercises',
                    'Hyperpigmentation',
                    'Lip care',
                    'Poor size or clogged pores',
                    'Redness',
                    'Retinol',
                    'Rosacea',
                    'Sensitive skin',
                    'Skin elasticity',
                    'Under eye care',
                    'Vitamin C'
                ]
            },
            {
                title: 'What competitive brands were mentioned?',
                type: SERVICE_RECAP_QUESTION_TYPE.OPTIONS,
                id: 'brands_mentioned',
                freeText: true,
                freeTextTitle: 'Other',
                freeTextPlaceholder: 'List any other brands mentioned here',
                options: [
                    'Aesop',
                    'Belif',
                    'Biologique Recherche',
                    'Cetaphil',
                    'Cle de Peau',
                    'Clinique',
                    'Dematologica',
                    'Dior',
                    'Dr. Barbara Strum',
                    'Dr. Jart',
                    'Drunk Elephant',
                    'Estee Lauder',
                    'Fresh',
                    'Glossier',
                    'Glow Recipe',
                    'Innisfree',
                    'Kiehl’s',
                    'La Mer',
                    'La Roche Posey',
                    'Lancome',
                    'Mario Badescu',
                    'Murad',
                    'Philosophy',
                    'Sheseido',
                    'SkII',
                    'Super Goop',
                    'Tata Harper',
                    'The Ordinary',
                    'Vitner’s Daughter'
                ]
            },
            {
                title: 'Challenges/note',
                type: SERVICE_RECAP_QUESTION_TYPE.OPTIONS,
                id: 'challenges_note',
                freeText: true,
                freeTextTitle: 'Other',
                freeTextPlaceholder: 'List the concerns/topics here',
                options: [
                    'Call quality',
                    'Competitor',
                    'Connectivity',
                    'Guest lighting',
                    'Last minute booking',
                    'No show',
                    'Ran out of time'
                ]
            },
            {
                title: 'Anything else we should know?',
                type: SERVICE_RECAP_QUESTION_TYPE.TEXT,
                id: 'anything_else',
                freeTextPlaceholder: 'List the concerns/topics here'
            }
        ]
    }
};

export const getImageByServiceType = serviceType => {
    switch (serviceType) {
        case 'SKINCARE_SERVICE_3_SAMPLES':
        case 'SKINCARE_SERVICE_5_SAMPLES':
        case 'VIRTUAL_SKIN_CONSULTATION':
            return SkincareImage;
        case 'MAKEUP_APPLICATION':
            return MakeupApplicationImage;
        case 'SKINCARE_LESSON_PEAK':
        case 'SKINCARE_LESSON_OFF_PEAK':
            return LessonImage;
        default:
            return SkincareImage;
    }
};

export const getBeautyPlanTitleByServiceType = serviceType => {
    switch (serviceType) {
        case 'SKINCARE_SERVICE_3_SAMPLES':
        case 'SKINCARE_SERVICE_5_SAMPLES':
        case 'VIRTUAL_SKIN_CONSULTATION':
            return 'Skin service';
        case 'MAKEUP_APPLICATION':
            return 'Application';
        case 'SKINCARE_LESSON_PEAK':
        case 'SKINCARE_LESSON_OFF_PEAK':
            return 'Lesson';
        case 'HAPPY_HOUR_LOOK':
            return 'Happy Hour';
        case 'MAKEUP_LOOK':
            return 'Makeup Look';
        case 'BROW_LOOK':
        case 'NEW_BROW_LOOK':
            return 'Brow Look';
        case 'SKINCARE_CHAT':
            return 'Skincare Chat';
        case 'NEW_SKINCARE_CHAT':
            return 'Skin Chat';
        case 'NEW_MAKEUP_CHAT':
        case 'MAKEUP_CHAT':
            return 'Makeup Chat';
        case 'GROUP_CHAT':
            return 'Group Chat';
        case 'FRAGRANCE_EXPERIENCE':
            return 'Fragrance Experience';
        case 'BEAUTY_GUIDE_TALK':
            return 'Service Summary';
        case 'AT_HOME_FACIAL':
            return 'At-Home Facial 101';
        case 'NEW_SKIN_ROUTINE':
            return 'Skin Consultation';
        case 'NEW_MAKEUP_LESSON':
            return 'Makeup Lesson';
        case 'NEW_MAKEUP_APPLICATION':
            return 'Makeup Application';
        case 'EYE_MAKEUP_APPLICATION':
            return 'Eye Makeup Application';
        default:
            return 'Lesson';
    }
};

/**
 * This is used for the /my-beauty-plans page. It is a mapping of images displayed.
 *
 * @param {string} serviceType
 * @param {string} defaultImage
 * @returns {string} - Image url
 */
export const getBeautyPlanImageByServiceType = (serviceType, defaultImage) => {
    switch (serviceType) {
        case 'SKINCARE_SERVICE_3_SAMPLES':
        case 'SKINCARE_SERVICE_5_SAMPLES':
        case 'VIRTUAL_SKIN_CONSULTATION':
            return 'https://images.prismic.io/atelier/d44f6b35-e136-4c63-9f20-2c9e267e3fc6_skin-con-my-bp.jpg?auto=compress,format';
        case 'MAKEUP_APPLICATION':
            return 'https://images.prismic.io/atelier/29879303-fde9-4f35-ae62-34b50e2f53b5_makeup-my-bp.jpg?auto=compress,format';
        case 'SKINCARE_LESSON_PEAK':
        case 'SKINCARE_LESSON_OFF_PEAK':
            return 'https://images.prismic.io/atelier/9141e7d5-a8bc-4132-8f8c-521b950ef7ea_lesson-my-bp.jpg?auto=compress,format';
        case 'HAPPY_HOUR_LOOK':
            return 'https://images.prismic.io/atelier/150e81c8-8105-4279-93b7-267e8d1a1039_happy-my-bp.jpg?auto=compress,format';
        case 'MAKEUP_LOOK':
            return 'https://images.prismic.io/atelier/29879303-fde9-4f35-ae62-34b50e2f53b5_makeup-my-bp.jpg?auto=compress,format';
        case 'BROW_LOOK':
        case 'NEW_BROW_LOOK':
            return 'https://images.prismic.io/atelier/acd5b82a-07f8-4316-8ee6-88cb76da51ce_brow-my-bp.jpg?auto=compress,format';
        case 'SKINCARE_CHAT':
            return 'https://images.prismic.io/atelier/d44f6b35-e136-4c63-9f20-2c9e267e3fc6_skin-con-my-bp.jpg?auto=compress,format';
        case 'NEW_SKINCARE_CHAT':
            return 'https://images.prismic.io/atelier/d44f6b35-e136-4c63-9f20-2c9e267e3fc6_skin-con-my-bp.jpg?auto=compress,format';
        case 'NEW_MAKEUP_CHAT':
        case 'MAKEUP_CHAT':
            return 'https://images.prismic.io/atelier/9141e7d5-a8bc-4132-8f8c-521b950ef7ea_lesson-my-bp.jpg?auto=compress,format';
        case 'GROUP_CHAT':
            return 'https://images.prismic.io/atelier/d44f6b35-e136-4c63-9f20-2c9e267e3fc6_skin-con-my-bp.jpg?auto=compress,format';
        case 'FRAGRANCE_EXPERIENCE':
            return 'https://images.prismic.io/atelier/ba66de98-2639-4a4a-8b3d-fa5895a8ae8e_frag-disc-my-bp.jpg?auto=compress,format';
        case 'BEAUTY_GUIDE_TALK':
            return 'https://images.prismic.io/atelier/30ce88d6-d6d3-43c7-beb3-3e452c9b0018_ser-sum-my-bp.png?auto=compress,format';
        case 'AT_HOME_FACIAL':
            return 'https://images.prismic.io/atelier/ca90f2f5-31a9-42b1-956a-f6b8ff2062c1_ah101-my-bp.jpg?auto=compress,format';
        default:
            return defaultImage;
    }
};

export const SKINCARE_SERVICE_OPTIONS = [
    {
        id: 'SKINCARE_SERVICE_5_SAMPLES',
        display: 'create your routine - 5 piece'
    }
];

// This is used for the Beauty plan creation tabs
export const BEAUTYPLAN_TYPE = {
    SKINCARE_SERVICE_3_SAMPLES: {
        id: 'SKINCARE_SERVICE_3_SAMPLES',
        title: 'Skin service',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SAMPLE_KIT_3],
        image: SkincareImage,
        imageDesktop: SkincareImageDesktop,
        imageTablet: SkincareImageTablet,
        skinService: true,
        sku: '8E',
        ean: '8E',
        analyticsCategory: 'workplan-skinservice',
        steps: [
            BEAUTYPLAN_SECTIONS.SAMPLE_KIT_3,
            BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE
        ]
    },
    SKINCARE_SERVICE_5_SAMPLES: {
        id: 'SKINCARE_SERVICE_5_SAMPLES',
        title: 'Skin service',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5],
        image: SkincareImage,
        imageDesktop: SkincareImageDesktop,
        imageTablet: SkincareImageTablet,
        skinService: true,
        sku: 'BA',
        ean: 'BA',
        analyticsCategory: 'workplan-skinservice',
        steps: [
            BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5,
            BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE
        ],
        serviceRecap: SERVICE_RECAP_SECTIONS.SKIN_CHAT
    },
    MAKEUP_APPLICATION: {
        id: 'MAKEUP_APPLICATION',
        title: 'Application',
        image: MakeupApplicationImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-application',
        steps: [BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE]
    },
    SKINCARE_LESSON_PEAK: {
        id: 'SKINCARE_LESSON_PEAK',
        title: 'Lesson',
        image: LessonImage,
        imageDesktop: LessonImageDesktop,
        imageTablet: LessonImageTablet,
        analyticsCategory: 'workplan-lesson',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5],
        steps: [
            BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5,
            BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE,
            BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE
        ]
    },
    SKINCARE_LESSON_OFF_PEAK: {
        id: 'SKINCARE_LESSON_OFF_PEAK',
        title: 'Lesson',
        image: LessonImage,
        imageDesktop: LessonImageDesktop,
        imageTablet: LessonImageTablet,
        analyticsCategory: 'workplan-lesson',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5],
        steps: [
            BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5,
            BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE,
            BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE
        ]
    },
    HAPPY_HOUR_LOOK: {
        id: 'HAPPY_HOUR_LOOK',
        title: 'Happy Hour Look',
        image: MakeupApplicationImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-happyhour',
        steps: [BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE]
    },
    MAKEUP_LOOK: {
        id: 'MAKEUP_LOOK',
        title: 'Makeup Look',
        image: MakeupApplicationImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-makeuplook',
        steps: [BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE]
    },
    BROW_LOOK: {
        id: 'BROW_LOOK',
        title: 'Brow Look',
        image: MakeupApplicationImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-browLook',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.BROW_LOOK],
        steps: [BEAUTYPLAN_SECTIONS.BROW_LOOK]
    },
    SKINCARE_CHAT: {
        id: 'SKINCARE_CHAT',
        title: 'Skincare Chat',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5],
        image: SkincareImage,
        imageDesktop: SkincareImageDesktop,
        imageTablet: SkincareImageTablet,
        skinService: true,
        analyticsCategory: 'workplan-skincarechat',
        steps: [
            BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5,
            BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE
        ]
    },
    MAKEUP_CHAT: {
        id: 'MAKEUP_CHAT',
        title: 'Makeup Chat',
        image: MakeupApplicationImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-makeupchat',
        steps: [BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE]
    },
    GROUP_CHAT: {
        id: 'GROUP_CHAT',
        title: 'Group Chat',
        image: MakeupApplicationImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-groupchat',
        steps: [BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE]
    },
    NEW_SKINCARE_CHAT: {
        id: 'NEW_SKINCARE_CHAT',
        title: 'Skin',
        titlePink: 'Chat',
        image: SkinchatImage,
        imageDesktop: SkincareImageDesktop,
        imageTablet: SkincareImageTablet,
        analyticsCategory: 'workplan-skincarechat',
        steps: [BEAUTYPLAN_SECTIONS.NEW_SKINCARE_ROUTINE],
        serviceRecap: SERVICE_RECAP_SECTIONS.SKIN_CHAT
    },
    NEW_MAKEUP_CHAT: {
        id: 'NEW_MAKEUP_CHAT',
        title: 'Makeup',
        titlePink: 'Chat',
        image: MakeupchatImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-makeupchat',
        steps: [BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE],
        serviceRecap: SERVICE_RECAP_SECTIONS.MAKEUP_CHAT
    },
    NEW_BROW_LOOK: {
        id: 'NEW_BROW_LOOK',
        title: 'Brow Look',
        image: MakeupchatImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        analyticsCategory: 'workplan-browlook',
        steps: [BEAUTYPLAN_SECTIONS.NEW_BROW_LOOK],
        newHeader: true,
        subTitle: 'Brow Chart',
        imageUpload: true,
        imageUploadOnlyStaff: true
    },
    VIRTUAL_SKIN_CONSULTATION: {
        id: 'VIRTUAL_SKIN_CONSULTATION',
        title: 'Skin service',
        config: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5],
        image: SkincareImage,
        imageDesktop: SkincareImageDesktop,
        imageTablet: SkincareImageTablet,
        skinService: true,
        analyticsCategory: 'workplan-virtualskinconsultation',
        steps: [
            BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5,
            BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE
        ],
        serviceRecap: SERVICE_RECAP_SECTIONS.SKIN_CHAT
    },
    FRAGRANCE_EXPERIENCE: {
        id: 'FRAGRANCE_EXPERIENCE',
        title: 'Fragrance Experience',
        image: FragranceExperienceImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        imageHeader: 'https://images.prismic.io/atelier/8d12ae43-405d-47b7-9b99-3c25b3d6b231_frag-bp.jpg?auto=compress,format',
        analyticsCategory: 'workplan-fragranceexperience',
        steps: [BEAUTYPLAN_SECTIONS.FRAGRANCE_EXPERIENCE],
        simpleDesign: true,
        newDesign: true
    },
    BEAUTY_GUIDE_TALK: {
        id: 'BEAUTY_GUIDE_TALK',
        title: 'Service Summary',
        image: BeautyGuideTalkImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        simpleHeaderImageMobile:
            'https://images.prismic.io/atelier/4ad83598-95f2-4e34-994c-552c8b4c9491_TWABG_Service+Summary+Header_Mobile.jpg?auto=compress,format',
        simpleHeaderImageDesktop:
            'https://images.prismic.io/atelier/94c4c764-a9ec-4b60-b368-24ec9c909250_TWABG_Service+Summary+Header_Desktop.jpg?auto=compress,format',
        analyticsCategory: 'workplan-beautyguidetalk',
        steps: [BEAUTYPLAN_SECTIONS.BEAUTY_GUIDE_TALK],
        simpleDesign: true,
        simpleHeaderSingleRow: true
    },
    AT_HOME_FACIAL: {
        id: 'AT_HOME_FACIAL',
        title: 'At-Home Facial 101',
        subTitle: 'Beauty Plan',
        image: AtHomeFacialImage,
        imageDesktop: MakeupApplicationImageDesktop,
        imageTablet: MakeupApplicationImageTablet,
        simpleHeaderImageMobile:
            'https://images.prismic.io/atelier/466f4aa3-53e8-459f-b8c6-02350625eac9_athomefacialsimpleheader.png?auto=compress,format',
        simpleHeaderImageDesktop:
            'https://images.prismic.io/atelier/466f4aa3-53e8-459f-b8c6-02350625eac9_athomefacialsimpleheader.png?auto=compress,format',
        simpleHeaderSingleRow: true,
        analyticsCategory: 'workplan-athomefacial',
        steps: [BEAUTYPLAN_SECTIONS.AT_HOME_FACIAL],
        simpleDesign: true
    },
    NEW_SKIN_ROUTINE: {
        id: 'NEW_SKIN_ROUTINE',
        title: 'Skin Consultation',
        subTitle: 'Service Summary',
        image: SkincareImage,
        imageHeader:
            'https://images.prismic.io/atelier/032dd437-de67-446f-af27-00c6e95c4fae_skin-con-bp.jpg?auto=compress,format',
        analyticsCategory: 'workplan-skinroutine',
        steps: [BEAUTYPLAN_SECTIONS.NEW_SKIN_ROUTINE],
        simpleDesign: true,
        newDesign: true
    },
    NEW_MAKEUP_LESSON: {
        id: 'NEW_MAKEUP_LESSON',
        title: 'Makeup Lesson',
        subTitle: 'Service Summary',
        image: LessonImage,
        imageHeader:
            'https://images.prismic.io/atelier/35fd5dbe-b91a-4995-9ac1-f4fce83877e4_mk-les-bp.jpg?auto=compress,format',
        analyticsCategory: 'workplan-makeuplesson',
        steps: [BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE],
        simpleDesign: true,
        newDesign: true
    },
    NEW_MAKEUP_APPLICATION: {
        id: 'NEW_MAKEUP_APPLICATION',
        title: 'Makeup Application',
        subTitle: 'Service Summary',
        image: MakeupApplicationImage,
        imageHeader:
            'https://images.prismic.io/atelier/3f565c36-76bd-4d49-8541-f4848c6a3611_mk-app-bp.jpg?auto=compress,format',
        analyticsCategory: 'workplan-makeupapplication',
        steps: [BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE],
        simpleDesign: true,
        newDesign: true
    },
    EYE_MAKEUP_APPLICATION: {
        id: 'EYE_MAKEUP_APPLICATION',
        title: 'Eye Makeup Application',
        subTitle: 'Service Summary',
        image: MakeupApplicationImage,
        imageHeader:
            'https://images.prismic.io/atelier/81a664f4-cf51-46c1-98af-05aee7dea82a_eye-app-bp.jpg?auto=compress,format',
        analyticsCategory: 'workplan-eyemakeupapplication',
        steps: [BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE],
        simpleDesign: true,
        newDesign: true
    }
};

export const getEventAssociatedBeautyPlanType = eventType => {
    switch (eventType) {
        case 'fragrance_discovery_group':
            return BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE;
        default:
            return null;
    }
};
