import { ZONES } from 'constants/zones';
import {
    SWATCH_CHECKBOX_NAMES,
    SWATCH_IMAGE_CATEGORIES,
    SWATCH_COLOR_GROUP
} from 'constants/swatchCheckbox/names';

import unselectedImage from './assets/Empty.png';
import unselectedVector from './assets/empty.svg';

import EyeSelection1 from './assets/Eye/1.png';
import EyeSelection2 from './assets/Eye/2.png';
import EyeSelection3 from './assets/Eye/3.png';
import EyeSelection4 from './assets/Eye/4.png';
import EyeSelection5 from './assets/Eye/5.png';
import EyeSelection6 from './assets/Eye/6.png';
import EyeSelection7 from './assets/Eye/7.png';
import EyeSelection8 from './assets/Eye/8.png';
import EyeSelection9 from './assets/Eye/9.png';
import EyeSelection10 from './assets/Eye/10.png';
import EyeSelection11 from './assets/Eye/11.png';
import EyeSelection12 from './assets/Eye/12.png';
import EyeSelection13 from './assets/Eye/13.png';
import EyeSelection14 from './assets/Eye/14.png';
import EyeSelection15 from './assets/Eye/15.png';
import EyeSelection16 from './assets/Eye/16.png';

import LipsSelection1 from './assets/Lips/1.png';
import LipsSelection2 from './assets/Lips/2.png';
import LipsSelection3 from './assets/Lips/3.png';
import LipsSelection4 from './assets/Lips/4.png';
import LipsSelection5 from './assets/Lips/5.png';
import LipsSelection6 from './assets/Lips/6.png';
import LipsSelection7 from './assets/Lips/7.png';
import LipsSelection8 from './assets/Lips/8.png';
import LipsSelection9 from './assets/Lips/9.png';
import LipsSelection10 from './assets/Lips/10.png';
import LipsSelection11 from './assets/Lips/11.png';
import LipsSelection12 from './assets/Lips/12.png';
import LipsSelection13 from './assets/Lips/13.png';
import LipsSelection14 from './assets/Lips/14.png';
import LipsSelection15 from './assets/Lips/15.png';

import NailsSelection1 from './assets/Nails/1.png';
import NailsSelection2 from './assets/Nails/2.png';
import NailsSelection3 from './assets/Nails/3.png';
import NailsSelection4 from './assets/Nails/4.png';
import NailsSelection5 from './assets/Nails/5.png';

import PowderSelection1 from './assets/Powder/1.png';
import PowderSelection2 from './assets/Powder/2.png';
import PowderSelection3 from './assets/Powder/3.png';
import PowderSelection4 from './assets/Powder/4.png';
import PowderSelection5 from './assets/Powder/5.png';
import PowderSelection6 from './assets/Powder/6.png';
import PowderSelection7 from './assets/Powder/7.png';
import PowderSelection8 from './assets/Powder/8.png';
import PowderSelection9 from './assets/Powder/9.png';
import PowderSelection10 from './assets/Powder/10.png';
import PowderSelection11 from './assets/Powder/11.png';
import PowderSelection12 from './assets/Powder/12.png';

import SkincareSelection1 from './assets/Skincare/1.png';
import SkincareSelection2 from './assets/Skincare/2.png';
import SkincareSelection3 from './assets/Skincare/3.png';
import SkincareSelection4 from './assets/Skincare/4.png';
import SkincareSelection5 from './assets/Skincare/5.png';
import SkincareSelection6 from './assets/Skincare/6.png';
import SkincareSelection7 from './assets/Skincare/7.png';
import SkincareSelection8 from './assets/Skincare/8.png';
import SkincareSelection9 from './assets/Skincare/9.png';

import CleanseSelection1 from './assets/Cleanse/1.png';
import CleanseSelection2 from './assets/Cleanse/2.png';
import CleanseSelection3 from './assets/Cleanse/3.png';
import CleanseSelection4 from './assets/Cleanse/4.png';
import CleanseSelection5 from './assets/Cleanse/5.png';
import CleanseSelection6 from './assets/Cleanse/6.png';

import SkinEnhancersSelection1 from './assets/SkinEnhancers/1.png';
import SkinEnhancersSelection2 from './assets/SkinEnhancers/2.png';
import SkinEnhancersSelection3 from './assets/SkinEnhancers/3.png';
import SkinEnhancersSelection4 from './assets/SkinEnhancers/4.png';
import SkinEnhancersSelection5 from './assets/SkinEnhancers/5.png';
import SkinEnhancersSelection6 from './assets/SkinEnhancers/6.png';
import SkinEnhancersSelection7 from './assets/SkinEnhancers/7.png';
import SkinEnhancersSelection8 from './assets/SkinEnhancers/8.png';

import FaceDefinersSelection1 from './assets/FaceDefiners/1.png';
import FaceDefinersSelection2 from './assets/FaceDefiners/2.png';
import FaceDefinersSelection3 from './assets/FaceDefiners/3.png';
import FaceDefinersSelection4 from './assets/FaceDefiners/4.png';
import FaceDefinersSelection5 from './assets/FaceDefiners/5.png';
import FaceDefinersSelection6 from './assets/FaceDefiners/6.png';

import HairSelection1 from './assets/Hair/1.png';
import HairSelection2 from './assets/Hair/2.png';

import OtherSelection1 from './assets/Others/1.png';
import OtherSelection2 from './assets/Others/2.png';
import OtherSelection3 from './assets/Others/3.png';
import OtherSelection4 from './assets/Others/4.png';
import OtherSelection5 from './assets/Others/5.svg';

// Config for all swatch checkboxes
// zones is an array of all possible zones that the checkbox could be rendered on
// TODO: may need to add another attribute similar to zones if other places besides PLP page will need limited, but still randomized, checkboxes
export const SWATCH_CHECKBOXES = [
    // EYE
    {
        unselected: unselectedImage,
        selected: EyeSelection1,
        zones: [ZONES.EYE_DEFINITION, ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.BLUE_EYE_1,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLUE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection2,
        zones: [ZONES.EYE_DEFINITION, ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_2,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection3,
        zones: [ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_3,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection4,
        zones: [ZONES.EYE_DEFINITION, ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.BLUE_EYE_4,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLUE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection5,
        zones: [ZONES.EYE_COLOR, ZONES.EYE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.BROWN_EYE_5,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BROWN, SWATCH_COLOR_GROUP.BEIGE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection6,
        zones: [ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.WHITE_EYE_6,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection7,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.WHITE_EYE_7,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection8,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_8,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection9,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_9,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection10,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_10,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection11,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_11,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection12,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BLACK_EYE_12,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection13,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.WHITE_EYE_13,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection14,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.WHITE_EYE_14,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection15,
        zones: [ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.EYE_CREAM_15,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: EyeSelection16,
        zones: [ZONES.EYE_COLOR],
        name: SWATCH_CHECKBOX_NAMES.EYE_POWDER_16,
        category: SWATCH_IMAGE_CATEGORIES.EYE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    // LIPS
    {
        unselected: unselectedImage,
        selected: LipsSelection1,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_1,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection2,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_2,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection3,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.NUDE_LIPS_3,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.NUDE, SWATCH_COLOR_GROUP.BEIGE]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection4,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_4,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection5,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.PINK_LIPS_5,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection6,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_6,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection7,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.PINK_LIPS_7,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection8,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.PINK_LIPS_8,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection9,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_9,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection10,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_10,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection11,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.BROWN_LIPS_11,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.BROWN]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection12,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.PINK_LIPS_12,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.PINK, SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection13,
        zones: [ZONES.LIP_BAR],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_13,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection14,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.PINK_LIPS_14,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: LipsSelection15,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.RED_LIPS_15,
        category: SWATCH_IMAGE_CATEGORIES.LIPS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },

    // Nails
    {
        unselected: unselectedImage,
        selected: NailsSelection1,
        zones: [ZONES.COLOR_WALL],
        name: SWATCH_CHECKBOX_NAMES.PINK_NAILS_1,
        category: SWATCH_IMAGE_CATEGORIES.NAILS,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: NailsSelection2,
        zones: [ZONES.COLOR_WALL],
        name: SWATCH_CHECKBOX_NAMES.RED_NAILS_2,
        category: SWATCH_IMAGE_CATEGORIES.NAILS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: NailsSelection3,
        zones: [ZONES.COLOR_WALL],
        name: SWATCH_CHECKBOX_NAMES.GREEN_NAILS_3,
        category: SWATCH_IMAGE_CATEGORIES.NAILS,
        colors: [SWATCH_COLOR_GROUP.GREEN]
    },
    {
        unselected: unselectedImage,
        selected: NailsSelection4,
        zones: [ZONES.COLOR_WALL],
        name: SWATCH_CHECKBOX_NAMES.YELLOW_NAILS_4,
        category: SWATCH_IMAGE_CATEGORIES.NAILS,
        colors: [SWATCH_COLOR_GROUP.YELLOW]
    },
    {
        unselected: unselectedImage,
        selected: NailsSelection5,
        zones: [ZONES.COLOR_WALL],
        name: SWATCH_CHECKBOX_NAMES.BLACK_NAILS_5,
        category: SWATCH_IMAGE_CATEGORIES.NAILS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },

    // Powder
    {
        unselected: unselectedImage,
        selected: PowderSelection1,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.PINK_POWDER_1,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection2,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_2,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE, SWATCH_COLOR_GROUP.BROWN]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection3,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.NUDE_POWDER_3,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.PINK, SWATCH_COLOR_GROUP.NUDE]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection4,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_4,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE, SWATCH_COLOR_GROUP.NUDE, SWATCH_COLOR_GROUP.BROWN]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection5,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_5,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE, SWATCH_COLOR_GROUP.BLUE, SWATCH_COLOR_GROUP.BROWN]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection6,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_6,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE, SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection7,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_7,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE, SWATCH_COLOR_GROUP.BROWN]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection8,
        zones: [ZONES.FACE_DEFINITION, ZONES.CARE_ENHANCE],
        name: SWATCH_CHECKBOX_NAMES.NUDE_POWDER_8,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE, SWATCH_COLOR_GROUP.NUDE]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection9,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_POWDER_9,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.BEIGE]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection10,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.NUDE_POWDER_10,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.NUDE]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection11,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.PINK_POWDER_11,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: PowderSelection12,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.YELLOW_POWDER_12,
        category: SWATCH_IMAGE_CATEGORIES.POWDER,
        colors: [SWATCH_COLOR_GROUP.YELLOW]
    },

    // Skincare
    {
        unselected: unselectedImage,
        selected: SkincareSelection1,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_1,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection2,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_2,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection3,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection4,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_4,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection5,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_5,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE, SWATCH_COLOR_GROUP.OFF_WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection6,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_6,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE, SWATCH_COLOR_GROUP.OFF_WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection7,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_7,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE, SWATCH_COLOR_GROUP.OFF_WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection8,
        zones: [ZONES.SKIN_CARE, ZONES.CARE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_8,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkincareSelection9,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_9,
        category: SWATCH_IMAGE_CATEGORIES.SKINCARE,
        colors: [SWATCH_COLOR_GROUP.OFF_WHITE]
    },

    // CLEANSE
    {
        unselected: unselectedImage,
        selected: CleanseSelection1,
        zones: [ZONES.CLEANSE],
        name: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_1,
        category: SWATCH_IMAGE_CATEGORIES.CLEANSE,
        colors: [SWATCH_COLOR_GROUP.BLUE]
    },
    {
        unselected: unselectedImage,
        selected: CleanseSelection2,
        zones: [ZONES.CLEANSE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_2,
        category: SWATCH_IMAGE_CATEGORIES.CLEANSE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: CleanseSelection3,
        zones: [ZONES.CLEANSE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_3,
        category: SWATCH_IMAGE_CATEGORIES.CLEANSE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: CleanseSelection4,
        zones: [ZONES.CLEANSE],
        name: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_4,
        category: SWATCH_IMAGE_CATEGORIES.CLEANSE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: CleanseSelection5,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.WHITE_CLEANSE_5,
        category: SWATCH_IMAGE_CATEGORIES.CLEANSE,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: CleanseSelection6,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BLUE_CLEANSE_6,
        category: SWATCH_IMAGE_CATEGORIES.CLEANSE,
        colors: [SWATCH_COLOR_GROUP.BLUE]
    },

    // SKIN ENHANCERS
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection1,
        zones: [ZONES.SKIN_ENHANCERS],
        name: SWATCH_CHECKBOX_NAMES.GRAY_SKIN_ENHANCERS_1,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.GRAY]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection2,
        zones: [ZONES.SKIN_ENHANCERS],
        name: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_2,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.NUDE]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection3,
        zones: [ZONES.SKIN_ENHANCERS],
        name: SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection4,
        zones: [ZONES.SKIN_ENHANCERS],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_4,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.BEIGE]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection5,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.GREEN_SKIN_ENHANCERS_5,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.GREEN]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection6,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.BEIGE_SKIN_ENHANCERS_6,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.BEIGE]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection7,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.WHITE_SKIN_ENHANCERS_7,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: SkinEnhancersSelection8,
        zones: [],
        name: SWATCH_CHECKBOX_NAMES.NUDE_SKIN_ENHANCERS_8,
        category: SWATCH_IMAGE_CATEGORIES.SKIN_ENHANCERS,
        colors: [SWATCH_COLOR_GROUP.NUDE]
    },

    // FACE DEFINERS
    {
        unselected: unselectedImage,
        selected: FaceDefinersSelection1,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.WHITE_FACE_DEFINERS_1,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: FaceDefinersSelection2,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.PINK_FACE_DEFINERS_2,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.PINK]
    },
    {
        unselected: unselectedImage,
        selected: FaceDefinersSelection3,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.MULTI_COLOR_FACE_DEFINERS_3,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.MULTI_COLOR]
    },
    {
        unselected: unselectedImage,
        selected: FaceDefinersSelection4,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.GRAY_FACE_DEFINERS_4,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.GRAY]
    },
    {
        unselected: unselectedImage,
        selected: FaceDefinersSelection5,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.NUDE_FACE_DEFINERS_5,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.NUDE]
    },
    {
        unselected: unselectedImage,
        selected: FaceDefinersSelection6,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.BROWN_FACE_DEFINERS_6,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.BROWN]
    },

    // HAIR TEXTURES
    {
        unselected: unselectedImage,
        selected: HairSelection1,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.BLACK_HAIR_TEXTURE_1,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: HairSelection2,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.BLACK_HAIR_TEXTURE_2,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },

    // OTHER TEXTURES
    {
        unselected: unselectedImage,
        selected: OtherSelection1,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.OTHERS_1,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.WHITE]
    },
    {
        unselected: unselectedImage,
        selected: OtherSelection2,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.OTHERS_2,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedImage,
        selected: OtherSelection3,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.OTHERS_3,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.RED]
    },
    {
        unselected: unselectedImage,
        selected: OtherSelection4,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.OTHERS_4,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    },
    {
        unselected: unselectedVector,
        selected: OtherSelection5,
        zones: [ZONES.FACE_DEFINITION],
        name: SWATCH_CHECKBOX_NAMES.OTHERS_5,
        category: SWATCH_IMAGE_CATEGORIES.FACE_DEFINERS,
        colors: [SWATCH_COLOR_GROUP.BLACK]
    }
];
