import { setIsMobileKeyboardOpen } from 'reducers/globalLayout';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { setIsMobileKeyboardOpen },
        dispatch
    );

const withMobileKeyboard = Component => connect(null, mapDispatchToProps)(Component);

export default withMobileKeyboard;
