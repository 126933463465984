import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrimaryModal from 'components/GlobalComponents/Modal/PrimaryModal';
import CustomerSearch from 'components/CustomerSearch/CustomerSearch';

export default class CustomerSearchModal extends Component {

    static propTypes = {
        tag: PropTypes.func
    };

    static defaultProps = {
        tag: () => {}
    };

    state = {
        isModalVisible: false,
    };

    /**
     * Toggle for search modal display state
     */
    toggleModal() {
        if (this.state.isModalVisible) {
            this.props.tag('searchoverlay-close');
        }
        this.setState({
            isModalVisible: !this.state.isModalVisible
        });
    }

    render() {
        if (!this.state.isModalVisible) {
            return null;
        }

        return (
            <PrimaryModal
                onRequestClose={() => this.toggleModal()}
                showClose={true}
                fullScreen={true}
                padding={[25, 0]}
            >
                <div>
                    <CustomerSearch isModalOpen={this.state.isModalVisible} onRequestClose={() => this.toggleModal()} tag={this.props.tag}/>
                </div>
            </PrimaryModal>
        );
    }

}
