import { boxStyle, fontStyle, inputStyle, styledProps, rem } from 'core/styled';
import styled, { css, keyframes } from 'styled-components';

export const InputContainer = styled.div`
    ${boxStyle};
    ${({ noPadding }) => noPadding ? 'padding: 0;' : 'padding: 10px 8px;'}
    ${({ cssPadding }) => (cssPadding && cssPadding.length) ? `padding: ${rem(...cssPadding)};` : null}
    width: 100%;
    
    input,select {
        width: 100%;
    }
`;

const placeholder = ({ isPlaceholder, textAlign, labelfontSize }) => {
    if (isPlaceholder) {
        if (textAlign === 'center') {
            return css`
                transform: translate3d(0, 22px, 0);
                ${labelfontSize ? `
                    &&& {
                        font-size: ${rem(labelfontSize)}
                    }` : null}
            `;
        }

        return css`
            transform: translate3d(8px, 22px, 0);
            ${labelfontSize ? `
                &&& {
                    font-size: ${rem(labelfontSize)}
                }` : null}
        `;
    }
};

const required = ({ required }) =>
    required &&
    css`
        &:after {
            content: '*';
        }
    `;

export const InputLabel = styled.label`
    ${fontStyle};
    display: block;
    // Since the label is placed on top of the input as a placeholder
    // disable pointer events on the label to focus directly on the
    // input under it
    pointer-events: none;
    font-family: ${styledProps('font', 'SofiaPro')};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${styledProps('color', 'adaGrey')};
    transition: all 0.2s ease-in-out;
    ${placeholder};
    ${required};
`;

export const TextInput = styled.input`
    ${inputStyle};
    outline: none;
`;

const resolveConditionalStyle = (bool, styleValue) => bool && styleValue;

const borderColor = (inactiveColor, overrideFocusColor) => ({
    loading,
    isActive,
    hasError,
    ...props
}) => {
    const color =
        resolveConditionalStyle(loading, inactiveColor) ||
        resolveConditionalStyle(hasError, 'monza') ||
        resolveConditionalStyle(isActive, overrideFocusColor ? overrideFocusColor : 'black') ||
        inactiveColor;

    return styledProps('color', color)(props);
};

const fadeIn = keyframes`
  0%, 100% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
`;

const animationRule = css`
    ${fadeIn} 1.5s linear infinite;
`;

export const InputUnderline = styled.div`
    ${({ isLoading }) => isLoading && css`animation: ${animationRule}`};
    border-top: solid 1px ${({ overrideBorderColor }) => borderColor('warmGrey', overrideBorderColor)};
    border-bottom: solid 1px ${({ overrideBorderColor }) => borderColor('transparent', overrideBorderColor)};
    transition: 0.2s border-color;
`;

export const InputCaption = styled.p`
    ${fontStyle};
    font-family: ${({ fontFamily }) => styledProps('font', fontFamily ? fontFamily : 'SofiaPro')};
    color: ${styledProps('color', 'seriousGray')};
    margin-top: 4px;
    padding: ${({ padding }) => padding ? rem(padding) : rem(5, 10, 0, 10) };
    font-size: 0.8em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.58;
    letter-spacing: 0.1px;
`;

export const InputError = styled(InputCaption)`
    color: ${styledProps('color', 'errorRed')};
    &::first-letter {
        text-transform: uppercase;
    }
`;
