import { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'core/utils';

/**
 * Handles mouse/touch interactions.
 * Accepts a function as a children which will be passed the state
 * and event handlers as a single object argument.
 * The children function can decide which React element should be
 * bound to the state and handlers.
 * @example
 * // language=JavaScript
 * class Button extends Component {
 *      render() {
 *          return (
 *              // Remember to pass Button props to Clickable
 *              // in order to use actual event handlers.
 *              <Clickable {...this.props}>
 *                  {({ loading, ...clickableProps }) => (
 *                      // The StyledButton component will be bound
 *                      // to the event handler functions.
 *                      <StyledButton {...clickableProps}>
 *                          Click me!
 *                      </StyledButton>
 *                  )}
 *              </Clickable>
 *          );
 *      }
 * }
 * @class Clickable
 */
export default class Clickable extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        selected: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        onMouseDown: PropTypes.func,
        onMouseUp: PropTypes.func,
        onClick: PropTypes.func,
        allowSelectedClick: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        loading: false,
        selected: false,
        onMouseEnter: noop,
        onMouseLeave: noop,
        onMouseDown: noop,
        onMouseUp: noop,
        onClick: noop,
        allowSelectedClick: false
    };

    state = {
        isHovered: false,
        isClicked: false,
        isTouch: false
    };

    events = {
        mouseEnter: { isHovered: true },
        mouseLeave: { isHovered: false, isClicked: false, isTouch: false },
        mouseDown: { isClicked: true },
        mouseUp: { isClicked: false },
        touchStart: { isHovered: false, isClicked: true, isTouch: true },
        touchEnd: { isHovered: false, isClicked: false }
    };

    handlers = {
        onMouseEnter: event => {
            if (this.state.isTouch) {
                return;
            }
            this.setState(this.events.mouseEnter);
            this.props.onMouseEnter(event);
        },
        onMouseLeave: event => {
            this.setState(this.events.mouseLeave);
            this.props.onMouseLeave(event);
        },
        onMouseDown: event => {
            if (this.state.isTouch) {
                return;
            }
            this.setState(this.events.mouseDown);
            this.props.onMouseDown(event);
        },
        onMouseUp: event => {
            this.setState(this.events.mouseUp);
            this.props.onMouseUp(event);
        },
        // We'll use mouse handlers for touch events to simplify
        // the Clickable interface and abstract it's client of
        // the device's pointer.
        onTouchStart: event => {
            this.setState(this.events.touchStart);
            this.props.onMouseDown(event);
        },
        onTouchEnd: event => {
            this.setState(this.events.touchEnd);
            this.props.onMouseUp(event);
        },
        onClick: event =>
            this.props.disabled || this.props.loading || (!this.props.allowSelectedClick && this.props.selected)
                ? event.preventDefault()
                : this.props.onClick(event)
    };

    render() {
        return this.props.children({
            ...this.props,
            ...this.state,
            ...this.handlers
        });
    }
}
