import { combineActions, handleActions } from 'redux-actions';
import {
    FETCH_ERROR,
    FETCH_PENDING,
    FETCH_RESET,
    FETCH_SUCCESS
} from 'middlewares/fetch';

const initialState = {
    isFetching: false,
    error: null,
    response: null
};

/**
 * Create fetch based reducer
 * @param {string} prefix - Fetch actions prefix
 * @return {object} reducer
 */
export default function createFetchReducer(prefix) {
    const typePrefix = prefix ? `${prefix}/` : '';

    return handleActions(
        {
            [`${typePrefix}${FETCH_PENDING}`]: state => ({
                ...state,
                ...initialState,
                isFetching: true
            }),
            [`${typePrefix}${FETCH_SUCCESS}`]: (state, { payload }) => ({
                ...state,
                ...initialState,
                response: payload
            }),
            [`${typePrefix}${FETCH_ERROR}`]: (state, { payload }) => ({
                ...state,
                ...initialState,
                error: payload
            }),
            [combineActions(
                FETCH_RESET,
                `${typePrefix}${FETCH_RESET}`
            )]: () => initialState
        },
        initialState
    );
}
