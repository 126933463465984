import normalizeAppointment from './normalizeAppointment';
import { UserNotificationTypes, NOTIFICATION_HOUSE_RULES } from 'constants/notifications';
import moment from 'moment-timezone';
import { store } from 'store';
import { isMyAtelierMember } from 'constants/membership';

const getApptFreePassName = (serviceKey) => {
    switch (serviceKey) {
        case 'makeup_application':
        case 'focused_makeup_application':
            return 'Application';
        case 'makeup_lesson':
        case 'focused_makeup_lesson':
            return 'Lesson';
        case 'makeup_chat':
            return 'Virtual Makeup Lesson';
        case 'skincare_chat':
            return 'Virtual Skin Consultation';
        case 'group_chat':
            return 'Virtual Skin Workshop';
        case 'fragrance_discovery_in_person':
            return 'Fragrance Discovery';
        case 'fragrance_discovery_virtual':
            return 'Fragrance Discovery';
        case 'fragrance_immersion_virtual':
            return 'Fragrance Immersion';
        case 'group_fragrance_discovery_in_person':
            return 'Fragrance Discovery';
        case 'at_home_facial':
            return 'At-Home Facial 101';
        default:
            return null;
    }
};

const normalizeNotification = (makeupArtists) => (notification) => {

    if ([
        NOTIFICATION_HOUSE_RULES,
        UserNotificationTypes.MEMBERSHIP_PRIVATE_EVENT,
        UserNotificationTypes.MEMBERSHIP_PRODUCT_PREVIEW
    ].includes(notification.type)) {
        return notification;
    }

    if (notification.type ===  UserNotificationTypes.FREE_PASS) {
        const { user: { auth: { profile: { vip } } } } = store.getState();

        // do not show regular freepasses when being a my atelier member
        if (isMyAtelierMember(vip)) {
            return null;
        }

        const { Program: { Name, ScheduleType }, ServiceKey } = notification.freePass;
        let freePassFor = '';
        const sessionTypes = [];

        // Free pass for event (e.g. Make up Happy Hour), get the Program Name
        if (ScheduleType === 'DropIn') {
            freePassFor = Name;

            if (freePassFor.toLowerCase().includes('master class')) {
                freePassFor = 'Master Class';
            }
        } else if (ScheduleType === 'Appointment') { // Free pass for Appointment
            if (!ServiceKey) {
                return null;
            }

            freePassFor = getApptFreePassName(ServiceKey);

            if (!freePassFor) {
                return null;
            }
        }

        return {
            ...notification,
            freePassFor,
            isEvent: ScheduleType === 'DropIn',
            sessionTypes,
        };
    }

    if (notification.type === UserNotificationTypes.RSVP_CONFIRMATION) {
        const { user: { auth: { profile: { email } } } } = store.getState();

        const guestInfo = notification.appointment && notification.appointment.guestInfo ?
            notification.appointment.guestInfo.find(info => info.email.toLowerCase() === email.toLowerCase()) :
            undefined;

        // do not show other RSVP nor old RSVP nor declined RSVP
        if (!guestInfo ||
            guestInfo.rsvp === 'NO' ||
            notification.appointment.status === 'cancelled' ||
            !notification.appointment.appointmentStartDateTime ||
            moment.tz(notification.appointment.appointmentStartDateTime, 'America/New_York').isSameOrBefore(moment())) {
            return null;
        }

        return {
            ...notification,
            isQuestionnaire: guestInfo.rsvp === 'YES'
        };
    }

    // do not show rating when being a my atelier member
    if (notification.type === UserNotificationTypes.REVIEW_MAKEUP_ARTIST) {
        const { user: { auth: { profile: { vip } } } } = store.getState();

        if (isMyAtelierMember(vip)) {
            return null;
        }
    }

    // ignore other notifications not in the handled ones
    if (!notification.appointment) {
        return null;
    }

    const appointment = normalizeAppointment(makeupArtists)(notification.appointment);

    // ignore no shows
    if (appointment.isNoShow) {
        return null;
    }

    return {
        ...notification,
        appointment
    };

};

export default normalizeNotification;
