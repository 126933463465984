import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { push } from 'redux-first-history';
import { FETCH, FETCH_SUCCESS } from 'middlewares/fetch';
import { logoutUser as clearUserSession } from 'reducers/user';
import { ROUTES } from 'constants/routes';
import { resetLayout } from 'reducers/globalLayout';
import { resetCart } from 'reducers/cart';
import { resetLists } from 'reducers/userList';

const initialState = {
    isLoggedOut: false
};

export const LOGOUT = 'LOGOUT';

export const logoutUserAction = createAction(
    FETCH,
    () => ({
        prefix: LOGOUT,
        endpoint: `${CONFIG.API_URL}/auth/session`,
        options: {
            method: 'DELETE'
        }
    }),
    () => ({
        analytics: {
            event: 'logout'
        }
    })
);

export const logoutUser = () => {
    return (dispatch, getState) => {
        const { user: { auth: { isStaff, isFreelancer } } } = getState();
        if (isStaff && !isFreelancer) {
            return dispatch(clearUserSession());
        } else {
            dispatch(logoutUserAction());
            dispatch(clearUserSession());
            dispatch(resetCart());
            dispatch(resetLists());
            if (isFreelancer) {
                dispatch(resetLayout());
            }
            dispatch(push(ROUTES.HOME));
        }

    };
};

export default handleActions(
    {
        [`${LOGOUT}/${FETCH_SUCCESS}`]: (state) => ({
            ...state,
            isLoggedOut: true
        })
    },
    initialState
);


