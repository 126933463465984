import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'core/styled';
import LoaderAnimation from 'assets/loader.gif';
import LoaderAnimationWebp from 'assets/loader.webp';
import DotAnimationLight from 'assets/buttonLoaderLight.png';
import DotAnimationDark from 'assets/buttonLoaderDark.png';
import { LAYER } from 'constants/layers';

const LoaderAnimationStyle = styled.img`
    max-width: 100px;
    margin: ${({ marginPx }) => marginPx ? rem(...marginPx) : '29vw'};
`;

const DotAnimationStyle = styled.img`
  height: ${rem(15)};
`;

const CenterStyle = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${rem(10)};
`;

const Rounded = styled.div`
  border-radius: ${rem(20)};
`;

const FullScreen = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  ${({ overlay }) => overlay ? `z-index: ${LAYER.HEADER} ;` : ''}
`;

export default class Loader extends Component {
    static propTypes = {
        fullScreen: PropTypes.bool,
        fullContainer: PropTypes.bool,
        forButton: PropTypes.bool,
        dark: PropTypes.bool,
        overlay: PropTypes.bool,
        margin: PropTypes.array
    };

    static defaultProps = {
        fullScreen: false,
        fullContainer: true,
        forButton: false,
        dark: false,
        overlay: false,
        margin: null
    };

    render() {
        const { fullScreen, fullContainer, forButton, dark, overlay, margin } = this.props;
        const LoaderImage = (
            <picture>
                <source srcSet={LoaderAnimationWebp} type="image/webp"/>
                <source srcSet={LoaderAnimation} type="image/gif"/>
                <LoaderAnimationStyle src={LoaderAnimation} marginPx={margin} alt={''}/>
            </picture>
        );

        switch (true) {
            case forButton:
                return <CenterStyle><DotAnimationStyle src={dark ? DotAnimationDark : DotAnimationLight} alt={''} /></CenterStyle>;
            case fullScreen:
                return <FullScreen overlay={overlay}>{LoaderImage}</FullScreen>;
            case fullContainer:
                return <CenterStyle><Rounded>{LoaderImage}</Rounded></CenterStyle>;
            default:
                return LoaderImage;
        }
    }
}
