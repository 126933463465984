/**
 * Get nested value of an object by recursing a list of props
 * @example
 * ```js
 * const obj = {
 *      foo: {
 *          bar: 1
 *      }
 * };
 * getNestedValue(obj, ['foo', 'bar']); // 1
 * ```
 * @param {object} obj
 * @param {array} props
 * @return {any} value
 */
export default function getNestedValue(obj, props) {
    try {
        return props.reduce((childObj, prop) => childObj[prop], obj);
    } catch (e) {
        throw new Error(`No ${props.join('.')} prop found in ${obj}`);
    }
}
