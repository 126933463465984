const vip = {
    REGULAR: {
        COLOR: 'pink',
        STRING: 'regular',
        DISPLAY_TEXT: 'Member'
    },
    FOUNDER: {
        COLOR: 'monza',
        STRING: 'founding',
        DISPLAY_TEXT: 'Founding Member'
    },
    MY_ATELIER: {
        COLOR: 'milanoRed',
        STRING: 'my-atelier',
        DISPLAY_TEXT: 'My Atelier Member'
    },
    FREELANCER: {
        COLOR: 'finn',
        STRING: 'Freelancer',
        DISPLAY_TEXT: 'Freelancer'
    },
    NON_MEMBER: {
        STRING: 'non-member'
    }
};

export default vip;
