import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'components/GlobalComponents/FlexBox';
import SubSection from 'components/BeautyPlans/Staff/SubSection';
import { bindActionCreators } from 'redux';
import { pickSampleKitSize } from 'reducers/beautyplan';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

class TrialKit extends Component {

    static propTypes = {
        pickSampleKitSize: PropTypes.func.isRequired,
        service: PropTypes.object.isRequired,
        editing: PropTypes.bool.isRequired
    };

    render() {

        const { service: { config } } = this.props;

        return (
            <Flex flexDirection='column' justifyContent='center'>
                <SubSection id={config.id} category={{ id: 'trial-kit' }} subSection={config.section} />
            </Flex>
        );

    }
}

const mapStateToProps = ({ beautyplan: { currentPlan: { service }, editing } }) => ({
    service, editing
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push, pickSampleKitSize },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TrialKit);
