/**
 * Convert string to dash case, snake case or any separator
 * @param {string} string
 * @param {string} separator
 * @return {string} lowercase string with separator between words
 */
export default function dasherize(string, separator = '-') {
    const [head, ...tail] = string;
    const camelCase = `${head.toLowerCase()}${tail.join('')}`;

    return camelCase.replace(
        /([A-Z])/g,
        g => `${separator}${g[0].toLowerCase()}`
    );
}
