import { combineReducers } from 'redux';
import welcome from './welcome';
import myProducts from './myProducts';
import beautyplans from './beautyplans';
import notifications from './myNotifications';
import myAppointments from './myAppointments';

export default combineReducers({
    welcome, myProducts, beautyplans, notifications, myAppointments
});
