import { ZONES } from 'constants/zones';
import { BADGE } from 'constants/badges';
import { getFragranceImage } from './getFragranceImage';

// Single use case for product time gap when it's marked with NEW tag
const SECONDS = 1000;
const HOURS = 3600 * SECONDS;
const DAYS = 24 * HOURS;
const gapTimeForNewState = 180 * DAYS;
const imageTransform = 'q_auto,f_jpg,fl_lossy,dpr_2'; // default image transform, dpr (device pixel ratio) set to 2
// image sizes are / 2 because of the dpr setting above (2). Should this setting change, sizes need to be adjusted accordingly
const IMAGE_SIZES = {
    SMALL: 75,
    MEDIUM: 150,
    MEDIUM_LARGE: 300,
    LARGE: 535 // max size displayed in PDP.
};

export const SORT_ORDER = {
    SIZE: 'sortableSize',
    SHADE: 'sortableShade'
};

/**
 * Analytics information
 * @param {object} product
 * @param {object} productModel
 * @return {object} analytics e-commerce object
 */
const getProductEcommerceAnalyticsInformation = (product, productModel) => {

    try {
        return {
            name: `${productModel.name} ${productModel.subName}`,
            id: productModel.productCode,
            price: productModel.price,
            brand: 'fnb',
            category: `fnb/${productModel.zone}/${productModel.category}/${productModel.name}`,
            variant: `${product._source.color || '(not set)'}/${product._source.size.amount} ${product._source.size.unit}`,
            dimension8: productModel.id, // product sku (PCM)
            dimension18: productModel.name // title (PCM)
        };
    } catch (err) {
        return {};
    }

};

/**
 * Normalize product data into more user friendly format for FE
 * @param {object} product
 * @return {object} normalized product
 */
export default function normalizeProduct(product) {
    if (typeof product._source === 'undefined') {

        return null;
    }
    // base transformation defined by Chanel
    const productAssets = product._source.assets; // api response path to all image assets
    const assets = normalizeImageUrls(product._source, productAssets);
    const badge = setBadge(product._source);

    const isChanelFactory5 = ['CHANEL Factory 5'].includes(product._source.primaryCategory);

    // workaround for issues with online inventory for these N5 products
    if (isChanelFactory5) {
        if (product._source.inventory) {
            if (!Object.prototype.hasOwnProperty.call(product._source.inventory, 'online')) {
                product._source.inventory.online = 999;
            }
        } else {
            product._source.inventory = {
                online: 999
            };
        }
    }

    const isLimitedEditionDescription = !!product._source.briefDescription && product._source.briefDescription.toLowerCase().startsWith('limited-edition');
    const limitedEditionSubName = isLimitedEditionDescription ? product._source.briefDescription.slice(16) : product._source.briefDescription;

    const productModel = {
        beginLife: product._source.beginLife,
        id: product._source.sku,
        name: product._source.title,
        skuName: product._source.skuName,
        category: product._source.primaryCategory,
        shadeName: product._source.shade || '',
        size: product._source.size ? `${product._source.size.amount} ${product._source.size.unit}` : '',
        sortableSize: product._source.size ? product._source.size.amount : 0, // in order to sort by size, we want a number
        subName: product._source.briefDescription,
        price: product._source.price ? product._source.price.amount : '',
        shadeHexCode: product._source.shadeHexCode || '000000',
        description: product._source.fullDescription,
        productCode: product._source.productCode,
        sku: product._source.sku,
        ean: product._source.ean,
        packshot: assets.packshot,
        texture: assets.texture,
        images: assets.images,
        mediumImages: assets.mediumImages,
        zone: product._source.primaryZone,
        zones: product._source.zone,
        crossSell: product._source.crossSell,
        availableOnline: product._source.inventory
            ? product._source.inventory.online > 0
            : false,
        availableInStore: product._source.inventory
            ? product._source.inventory.in_store > 0
            : false,
        // Austin availability
        availableInStore2: product._source.inventory
            ? product._source.inventory.in_store2 > 0
            : false,
        isLowStock: product._source.inventory && (product._source.inventory.in_store > 0 && product._source.inventory.in_store <= 2),
        // Austin low stock
        isLowStock2: product._source.inventory && (product._source.inventory.in_store2 > 0 && product._source.inventory.in_store2 <= 2),
        activationDate: product._source.activationDate,
        isLimitedEdition: product._source.isLimitedEdition,
        mostLiked: product._source.mostLiked,
        emblematic: product._source.emblematic,
        fullDescription: product._source.fullDescription,
        howToUse: product._source.howToUse,
        ingredient: product._source.ingredient,
        ingredientList: product._source.ingredientList,
        sortField: product._source.shade ? SORT_ORDER.SHADE : SORT_ORDER.SIZE,
        workplanCategoryAM: product._source.workplanCategoryAM,
        workplanCategoryPM: product._source.workplanCategoryPM,
        fragranceNumber: product._source.fragranceNumber,
        fragranceHint: product._source.facts && product._source.facts.length ? product._source.facts[0] : null,
        fragranceNotes: product._source.fragranceNotes,
        fragranceImage: !!product._source.fragranceNumber && product._source.fragranceNumber > 0 ? getFragranceImage(product._source.fragranceNumber) : null,
        skinService: product._source.active && product._source.active.service,
        active: product._source.active,
        newBadge: product._source.newBadge,
        badge,
        browsable: product._source.browsable,
        searchable: product._source.searchable,
        shouldAddImagePadding: isChanelFactory5,
        isLimitedEditionDescription,
        limitedEditionSubName
    };


    // Variant display logic
    productModel.variantInfo = productModel.shadeName ? productModel.shadeName : productModel.size;
    productModel.thumbnail = productModel.shadeName ? productModel.texture : productModel.packshot;
    productModel.pickVariantText = productModel.sortField === SORT_ORDER.SHADE ? 'shades' : 'sizes';
    productModel.sortableShade = (() => {
        const shadeNumber = productModel.shadeName.match(/\d+/g);
        if (shadeNumber) {
            return shadeNumber.map(Number).find(n => n);
        }
        return 0;
    })();

    // Domain
    productModel.isOutOfStock = !productModel.availableInStore && !productModel.availableOnline;
    // Out of stock austin
    productModel.isOutOfStock2 = !productModel.availableInStore2 && !productModel.availableOnline;
    productModel.isNew = Date.now() - Date.parse(productModel.beginLife) < gapTimeForNewState;


    productModel.showNewBadge = productModel.badge === BADGE.NEW;

    // special case purchase if out of stock or only available online
    productModel.specialCasePurchaseLabel = ((product) => {
        if (product.isOutOfStock) {
            return 'OUT OF STOCK';
        } else if (product.isLowStock) {
            return 'RUNNING LOW';
        } else if (product.availableOnline && !product.availableInStore) {
            return 'BUY ON CHANEL.COM';
        }
        return null;
    })(productModel);

    // austin labels
    productModel.specialCasePurchaseLabel2 = ((product) => {
        if (product.isOutOfStock2) {
            return 'OUT OF STOCK';
        } else if (product.isLowStock2) {
            return 'RUNNING LOW';
        } else if (product.availableOnline && !product.availableInStore2) {
            return 'BUY ON CHANEL.COM';
        }
        return null;
    })(productModel);

    productModel.isSpecialCasePurchase = productModel.specialCasePurchaseLabel !== null;
    productModel.isSpecialCasePurchase2 = productModel.specialCasePurchaseLabel2 !== null;
    productModel.atelierOnly = productModel.availableInStore && !productModel.availableOnline;
    productModel.onlineOnly = !productModel.availableInStore && productModel.availableOnline;

    // austin atelier/online only booleans
    productModel.atelierOnly2 = productModel.availableInStore2 && !productModel.availableOnline;
    productModel.onlineOnly2 = !productModel.availableInStore2 && productModel.availableOnline;

    productModel.checkoutTag = ((product) => {
        if (product.isOutOfStock) {
            return 'Out of stock';
        } else if (product.isLowStock) {
            return 'running low';
        } else if (product.atelierOnly) {
            return 'Atelier Only';
        } else if (product.onlineOnly) {
            return 'Buy On Chanel.com';
        }
        return null;
    })(productModel);

    // austin checkouttag
    productModel.checkoutTag2 = ((product) => {
        if (product.isOutOfStock2) {
            return 'Out of stock';
        } else if (product.isLowStock2) {
            return 'running low';
        } else if (product.atelierOnly2) {
            return 'Atelier Only';
        } else if (product.onlineOnly2) {
            return 'Buy On Chanel.com';
        }
        return null;
    })(productModel);

    // Ecommerce information for analytics
    productModel.ecommerce = getProductEcommerceAnalyticsInformation(product, productModel);
    return productModel;

}

const CLOUDINARY_TRANS_ADD_WHITE_BORDER = 'bo_500px_solid_white';

function setBadge(product) {

    switch (true) {
        case !product.zone:
            return null;
        case product.zone.some(zone => zone === ZONES.FAVORITES):
            return BADGE.FAVORITES;
        case Date.now() - Date.parse(product.beginLife) < gapTimeForNewState:
            return BADGE.NEW;
        default:
            return null;
    }
}

/**
 * By default this should produce the transformation set in the pcm3div file.
 * Exceptions:
 *      - During indexing, if the pcm file is missing a tranformation a default one is set
 *      - If the site import file specifies assetMargin == true, set a margin transform overriding the default
 * @param {object} product
 * @param {object} image
 * @return {string} image transformation
 */
function baseTransformation(product, image) {
    if (image.type === 'PACKSHOT_DEFAULT' && product.assetMargin === true) {
        return image.cloudinaryTransformation ? `${image.cloudinaryTransformation},${CLOUDINARY_TRANS_ADD_WHITE_BORDER}` : CLOUDINARY_TRANS_ADD_WHITE_BORDER;
    } else {
        return image.cloudinaryTransformation ? image.cloudinaryTransformation : '';
    }
}

function normalizeImageUrls(product, assets) {
    const ret = {
        images: [],
        mediumImages: [],
        packshot: { exists: false, small: () => null, medium: () => null, mediumLarge: () => null, large: () => null },
        texture: { exists: false, small: () => null, medium: () => null, mediumLarge: () => null, large: () => null }
    };
    // map image file names to actual image paths
    const imageAssets = ['PACKSHOT_DEFAULT', 'BASIC_TEXTURE', 'APPLICATION_VISUAL_A', 'APPLICATION_VISUAL_B', 'APPLICATION_VISUAL_C'];

    // Specs from here: https://digitalwiki.valtech.com:8443/display/CHAN/Cloudinary+-+Developer+Handbook
    if (assets) {
        imageAssets.forEach(assetType => {
            const image = assets.find(image => image.type === assetType);
            const imageUrl = (size) => () => {
                if (image.baseUrl) {
                    return `${image.baseUrl}/${baseTransformation(product, image)}/${imageTransform}/w_${size},h_${size},c_pad/${image.filename}`;
                }
                return image.fullUrl; // if there's no base url, it's a static asset, do not try to resize
            };

            if (image) {
                ret.images.push(imageUrl(IMAGE_SIZES.LARGE)());
                ret.mediumImages.push(imageUrl(IMAGE_SIZES.MEDIUM)());

                if (assetType === 'PACKSHOT_DEFAULT') {
                    ret.packshot = {
                        small: imageUrl(IMAGE_SIZES.SMALL),
                        medium: imageUrl(IMAGE_SIZES.MEDIUM),
                        mediumLarge: imageUrl(IMAGE_SIZES.MEDIUM_LARGE),
                        large: imageUrl(IMAGE_SIZES.LARGE),
                        exists: true
                    };
                }

                if (assetType === 'BASIC_TEXTURE') {
                    ret.texture = {
                        small: imageUrl(IMAGE_SIZES.SMALL),
                        medium: imageUrl(IMAGE_SIZES.MEDIUM),
                        mediumLarge: imageUrl(IMAGE_SIZES.MEDIUM_LARGE),
                        large: imageUrl(IMAGE_SIZES.LARGE),
                        exists: true
                    };
                }
            }
        });
    }

    return ret;
}

/**
 * COH-5292 do not show NEW SHADES badges for Fragrance and Skincare products
 *
 * @param {object} current
 * @param {array} products
 * @return {boolean} Display new shades badge
 */
function showNewShadesBadge(current, products) {
    const newShades = products.filter(product => product.showNewBadge);
    return newShades.length > 0 && (!current.zones.includes(ZONES.FRAGRANCE)) && (!current.zones.includes(ZONES.CARE)) && (!current.zones.includes(ZONES.CLEANSE));
}

export const setNewBadgeType = function(current, products) {
    if (products.some(product => product.badge === BADGE.FAVORITES)) {
        return BADGE.FAVORITES;
    } else if (current.showNewBadge) {
        return BADGE.NEW;
    } else if (showNewShadesBadge(current, products)) {
        return BADGE.NEW_SHADES;
    } else {
        return null;
    }
};
