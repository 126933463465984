import { ROUTES } from './routes';

export const MENU_LINKS = {
    CUSTOMER: [
        {
            text: 'ABOUT',
            link: ROUTES.NEW_TO_ATELIER
        },
        {
            text: 'VISIT',
            link: ROUTES.VISIT_ATELIER
        },
        {
            text: 'DISCOVER',
            link: ROUTES.DISCOVER
        },
        {
            text: 'PRODUCTS',
            link: ROUTES.PRODUCTS
        },
        {
            text: 'MEMBERSHIP',
            link: ROUTES.MEMBERSHIP_LANDING
        },
        {
            text: 'GIFTS',
            link: ROUTES.GIFTS
        }
    ],
    CUSTOMER_LOGIN: [
        {
            text: 'MY PROFILE',
            link: ROUTES.PROFILE
        },
        {
            text: 'DISCOVER',
            link: ROUTES.DISCOVER
        },
        {
            text: 'PRODUCTS',
            link: ROUTES.PRODUCTS
        },
        {
            text: 'MEMBERSHIP',
            link: ROUTES.MEMBERSHIP_LANDING
            // notMemberRequired: true
        },
        // {
        //     text: 'MEMBERSHIP HUB',
        //     link: ROUTES.MEMBERS_HUB,
        //     memberRequired: true
        // },
        {
            text: 'GIFTS',
            link: ROUTES.GIFTS
        }
    ],
    STAFF: [
        {
            text: 'MY DASHBOARD',
            link: ROUTES.PROFILE
        },
        {
            text: 'OUR GUESTS',
            link: ROUTES.STAFF_OUR_GUESTS
        },
        {
            text: 'DISCOVER',
            link: ROUTES.DISCOVER
        },
        {
            text: 'PRODUCTS',
            link: ROUTES.PRODUCTS
        },
        {
            text: 'TODAY’S BOOKINGS',
            link: ROUTES.BOOKINGS
        }
    ],
    CUSTOMER_SUB_LINKS: [
        {
            text: 'CREATE MY PROFILE',
            link: ROUTES.REGISTER
        },
        {
            text: 'LOG IN',
            link: ROUTES.LOGIN
        }
    ],
    CUSTOMER_LOGIN_SUB_LINKS: [
        {
            text: 'MY ACCOUNT',
            link: ROUTES.ACCOUNT_INFORMATION
        },
        {
            text: 'VISIT',
            link: ROUTES.VISIT_ATELIER
        }
    ],
    STAFF_SUB_LINKS: [
        {
            text: 'VIRTUAL PAYMENTS',
            link: ROUTES.VIRTUAL_SERVICES_PAYMENTS,
            freelancerHidden: true
        },
        {
            text: 'SKINCARE WORKSHOPS',
            link: ROUTES.VIRTUAL_SERVICES_SKINCARE_WORKSHOPS,
            freelancerHidden: true
        },
        // {
        //     text: 'REFUNDS',
        //     link: ROUTES.REFUNDS,
        //     freelancerHidden: true,
        //     experienceLeadRoleRequired: true
        // },
        {
            text: 'BOOKINGS',
            link: ROUTES.VIRTUAL_SERVICES_BOOKINGS
        },
        {
            text: 'RETURNED MAILERS',
            link: ROUTES.RETURNED_MAILERS
        },
        {
            text: 'TRACKING EMAILS',
            link: ROUTES.TRACKING_EMAIL
        },
        {
            text: 'MANAGE MEMBERS',
            link: ROUTES.MEMBERSHIP_STAFF_HOME
        }
    ]
};
