import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PrimaryModal } from 'components/GlobalComponents/Modal/PrimaryModal';
import { Box, Flex } from 'components/GlobalComponents/FlexBox';
import { FormInput } from 'components/GlobalComponents/FormInput/FormInput';
import Text from 'components/GlobalComponents/Text/Text';
import { triggerAutocompleteSearch, resetAutocompleteResults } from 'reducers/productSearch';
import {
    fetchWorkplanProducts,
} from 'reducers/beautyplan';
import Loader from 'components/GlobalComponents/Loader/Loader';
import Header from 'components/GlobalComponents/Fonts/Header';
import { rem, styledProps } from 'core/styled';
import Button from 'components/GlobalComponents/Button/Button';
import ProductListing from 'components/ProductListing/ProductListing';
import SubHeader from 'components/GlobalComponents/Fonts/SubHeader';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';


const SearchAgainText = styled(Text)`
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    color: ${styledProps('color', 'black')};
`;

const StyledSearchAgainButton = styled(Button)`
  margin-top: ${rem(20)};
`;

const SearchButton = styled(Button)`
    min-width: 233px;
`;

const AutocompleteSuggestions = styled.div`    
    ul {
        li {
            display: block;
            padding: ${rem(10)};
            cursor: pointer;
            
            div {
                box-sizing: border-box;
            }
        }
    }
`;

const AutocompleteText = styled.div`
    text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
    text-align: center;
    margin: ${rem(50, 0)};
`;

const SearchAgainContainer = styled(Box)`
  text-align: center; 
  padding: ${rem(60, 0)};
`;

class SimpleSubSectionProductModal extends Component {

    static propTypes = {
        autocompleteResults: PropTypes.array,
        triggerAutocompleteSearch: PropTypes.func.isRequired,
        resetAutocompleteResults: PropTypes.func.isRequired,
        fetchWorkplanProducts: PropTypes.func.isRequired,
        getSectionIndex: PropTypes.func.isRequired,
        subSection: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        products: PropTypes.array.isRequired,
        addProduct: PropTypes.func.isRequired
    };

    state = {
        showModal: false,
        showProducts: false,
        search: ''
    };

    componentDidUpdate(prevProps, prevState) {
        // if search is cleared out, then reset autocomplete as well
        if (prevState.search !== this.state.search && this.state.search.trim().length === 0) {
            this.props.resetAutocompleteResults();
        }
    }

    toggleModal = () => {
        this.props.resetAutocompleteResults();
        this.setState({
            showModal: !this.state.showModal,
            showProducts: false,
            search: ''
        });
    };

    handleInputChange = event => {
        const target = event.target;
        const value = (target.textContent || target.innerText || '').trim();

        // trigger autocomplete search when more than 2 characters entered
        if (value.length > 2) {
            this.props.triggerAutocompleteSearch(value);
        }

        this.setState({
            search: value
        });
    };

    handleInputReset = () => {
        this.setState({
            search: '',
        });
    };

    /**
     * It fetches and shows the products.
     * @param {string} search
     */
    handleSubmit = (search) => {
        const { subSection } = this.props;

        if (this.state.search.length <= 0) {
            return;
        }
        this.props.fetchWorkplanProducts(search, subSection.isFragranceFlight);
        this.setState({
            showProducts: true,
            search
        });
    };

    addProduct = (product, sectionIndexOverride) => {
        this.props.addProduct(product, sectionIndexOverride);
        this.toggleModal();
    };

    renderContent() {
        const { products, isFetching, subSection } = this.props;

        if (isFetching) {
            return <Loader />;
        }

        return (
            <Flex flexDirection="column">
                <Header>“{this.state.search}”</Header>
                <SubHeader>Results ({products.length})</SubHeader>
                {products.length > 0 && <ProductListing
                    renderLikeListButtons={false}
                    products={products}
                    withShades={false}
                    onProductSelect={this.addProduct}
                    overridePDPRedirect={true}
                    backToTop={false}
                    isFragranceFlight={!!subSection.isFragranceFlight}
                    mediumColumnWidth={'50%'}
                    largeColumnWidth={'50%'}
                    giantColumnWidth={'50%'}
                />}

                <SearchAgainContainer>
                    <SearchAgainText> Didn’t find what you were looking for? </SearchAgainText>
                    <StyledSearchAgainButton variant={'primary'}
                        onClick={() => this.setState({ showProducts: false, search: '' })}>Search
                        Again</StyledSearchAgainButton>
                </SearchAgainContainer>
            </Flex>);
    }

    handleShowProducts(search, showProducts) {
        const { autocompleteResults } = this.props;

        if (showProducts) {
            return this.renderContent();
        } else {
            return (<Flex flexDirection="column" justifyContent={'center'} paddingTop={rem(40)}>
                <Flex flexDirection="column">
                    <form>
                        <FormInput
                            id="search"
                            label="Search, type, find your beauty weapons!"
                            inputfont={'ABChanelPBL'}
                            inputfontsize={33}
                            labelFont={'SofiaPro'}
                            labelFontSize={14}
                            clearOption={true}
                            maxLength={200}
                            multiLine
                            onSubmit={() => this.handleSubmit(search)}
                            onChange={this.handleInputChange}
                            onClear={this.handleInputReset}
                            value={search}
                        />
                    </form>
                    {autocompleteResults.length > 0 && <AutocompleteSuggestions>
                        <ul>
                            {
                                autocompleteResults.map((result, index) => {
                                    return (
                                        <li key={index} onClick={() => this.handleSubmit(result)}>
                                            <AutocompleteText>
                                                <strong>{result.substring(0, search.length)}</strong>{result.substring(search.length)}
                                            </AutocompleteText>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </AutocompleteSuggestions>
                    }

                    <ButtonWrapper>
                        <SearchButton
                            type='submit'
                            variant={'primary'}
                            disabled={!search.length}
                            onClick={() => this.handleSubmit(search)}
                        > SEARCH </SearchButton>
                    </ButtonWrapper>
                </Flex>
            </Flex>);
        }
    }

    render() {
        const { showModal, showProducts, search } = this.state;

        if (!showModal) {
            return null;
        }

        return (
            <PrimaryModal onRequestClose={this.toggleModal}
                showClose={true}
                flexBody={false}
                fullScreen={true}
                previousScrollDisabledState={false}
                preventScrollToTopOnOpen={true}>
                <Flex flexDirection='column' justifyContent='center'>
                    {this.handleShowProducts(search, showProducts)}
                </Flex>
            </PrimaryModal>
        );
    }
}

const mapStateToProps = ({ beautyplan: { products, isFetching }, productSearch: { autocompleteResults } }) => ({
    products,
    isFetching,
    autocompleteResults
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchWorkplanProducts,
            triggerAutocompleteSearch,
            resetAutocompleteResults
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SimpleSubSectionProductModal);
