import styled from 'styled-components';
import { greaterThan } from 'core/styled/index';
import { breakpoints } from 'constants/theme';
import { Flex } from 'components/GlobalComponents/FlexBox/index';
import { rem } from 'core/styled';
import { css } from 'styled-components';

const ProductSectionWrapper = styled(Flex)`
    padding-bottom: ${rem(55)};
    width: 100%;
     ${greaterThan(breakpoints.medium)(css`
       flex-direction: column;
       flex-wrap: wrap;
       justify-content: space-around;
    `)};
`;

export default ProductSectionWrapper;
