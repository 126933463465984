import { createAction, handleActions } from 'redux-actions';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';

const VTO_FETCH = 'VTO_FETCH';

const initialState = {
    vtoProducts: [],
    isFetching: false,
    error: null,
    fetched: false
};

const fetchVtoProducts = createAction(FETCH, () => ({
    prefix: VTO_FETCH,
    endpoint: `${CONFIG.API_URL}/products/vto`
}));

export const fetchVtos = () => {
    return (dispatch, getState) => {
        const { products: { fetched } } = getState();

        if (!fetched) {
            return dispatch(fetchVtoProducts());
        } else {
            return Promise.resolve();
        }
    };
};

export default handleActions({
    [`${VTO_FETCH}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        isFetching: true
    }),
    [`${VTO_FETCH}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    }),
    [`${VTO_FETCH}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        vtoProducts: payload,
        isFetching: false,
        fetched: true
    })
}, initialState);
