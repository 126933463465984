import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import { styledProps, rem } from 'core/styled';

// TODO: possibly add default styling per Zeplin updates, however this needs to be tested everywhere across all devices first
// font-weight: normal;
// font-style: normal;
// font-stretch: normal;
// line-height: 1.5;
// letter-spacing: 0.2px

const Description = styled.div`
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(18)};
    font-family: ${styledProps('font', 'SofiaPro')};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};
    ${({ padding }) => padding ? `padding:  ${rem(...padding)}` : ''}; 
    ${({ lineHeight }) => lineHeight ? `line-height: ${lineHeight}` : ''};
    color: ${({ fontColor }) => fontColor ? styledProps('color', fontColor) : styledProps('color', 'black') };
    
    ${greaterThan(breakpoints.small)(css`
        font-size: ${({ mediumScreensFontSize }) => rem(mediumScreensFontSize)}
    `)}
`;

export default Description;
