import { createAction, handleAction } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';
import { combineReducers } from 'redux';
import { FETCH_SUCCESS } from 'middlewares/fetch';

const AVAILABLE_STAFF = 'AVAILABLE_STAFF';
const TRACKING_EMAIL = 'TRACKING_EMAIL';

const fetchAvailableStaff = createAction(FETCH, (workshopId, secretKey) => ({
    prefix: AVAILABLE_STAFF,
    endpoint: `${CONFIG.API_URL}/scheduling/schedules/staff?staffIds=0&workshopId=${workshopId}&secretKey=${secretKey}`,
    options: {
        method: 'GET'
    }
}));

export const sendTrackingEmail = createAction(
    FETCH,
    (emailType, email, trackingNumber) => ({
        prefix: TRACKING_EMAIL,
        endpoint: `${CONFIG.API_URL}/tracking/email`,
        options: {
            method: 'POST',
            body: JSON.stringify({
                emailType,
                email,
                trackingNumber
            })
        }
    })
);

export const getAvailableStaff = () => {
    return (dispatch, getState) => {
        const {
            workshop: { workshopId },
            secretKey: { secretKey }
        } = getState();
        return dispatch(fetchAvailableStaff(workshopId, secretKey));
    };
};

export default combineReducers({
    fetch: createFetchReducer(AVAILABLE_STAFF),
    availableStaff: handleAction(
        `${AVAILABLE_STAFF}/${FETCH_SUCCESS}`,
        (state, { payload }) => [...payload],
        []
    )
});
