import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { PrimaryModal } from 'components/GlobalComponents/Modal/PrimaryModal';
import { greaterThan, rem, styledProps } from 'core/styled';
import { breakpoints } from 'constants/theme';
import Button from 'components/GlobalComponents/Button/Button';
import { LOCATIONS } from 'constants/locations';
import ConditionalBreak from 'components/GlobalComponents/ConditionalBreak/ConditionalBreak';
import { ROUTES } from 'constants/routes';
import { Flex } from 'components/GlobalComponents/FlexBox';
import { CHECKOUT_METHOD } from 'constants/checkoutMethods';

const ModalContentBodyWrapper = styled.div`
    padding: ${rem(0, 0, 15, 0)};
    text-align: center;
`;

const ModalHeader = styled.p`
    margin: 0;
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    font-size: ${rem(54)};
    font-weight: 300;
    line-height: 0.93;
    letter-spacing: ${rem(1)};
    padding-bottom: ${rem(5)};
    text-transform: uppercase;
`;

const ModalBodyText = styled.p`
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: ${rem(0.2)};
    padding-bottom: ${rem(10)};
    margin: ${rem(20, 'auto', 0)};
    max-width: ${rem(400)};

    ${greaterThan(breakpoints.small)(css`
        padding-bottom: ${rem(25)};
        font-size: ${rem(14)};
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: ${rem(0.2)};
    `)}
`;

const StyledButton = styled(Button)`
    margin-top: ${rem(20)};
`;

const CheckoutModalFlex = styled(Flex)`
    margin-top: ${rem(30)};
`;

export default class CartCheckoutModal extends Component {
    static propTypes = {
        showModal: PropTypes.bool.isRequired,
        products: PropTypes.array.isRequired,
        authenticated: PropTypes.bool.isRequired,
        returnAfterLogin: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        selectedCheckoutMethod: PropTypes.string,
        cartLocation: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
        handleOnlineCheckout: PropTypes.func.isRequired,
        proceedToCheckoutPage: PropTypes.func.isRequired
    };

    renderContent = () => {
        const {
            products,
            authenticated,
            returnAfterLogin,
            replace,
            selectedCheckoutMethod,
            cartLocation,
            toggleModal,
            handleOnlineCheckout,
            proceedToCheckoutPage
        } = this.props;
        let notAvailableInStore;

        if (cartLocation === LOCATIONS.AUSTIN) {
            notAvailableInStore = products.filter(p => !p.availableInStore2);
        } else {
            notAvailableInStore = products.filter(p => !p.availableInStore);
        }

        // copy varies based on number of products not available for in store checkout
        const inStoreCheckoutBodyCopy =
            notAvailableInStore.length === 1
                ? `One of these products is not available in the Atelier right now. It will be saved to your bag so you can order it through chanel.com. Next-day shipping is on us!`
                : `Some of your products are not available in the Atelier right now. They will be saved to your bag so you can order them through chanel.com. Next-day shipping is on us!`;

        // copy for online checkout modal
        const onlineCheckoutCopy = {
            heading: (
                <span>
                    BUY ONLINE
                    <br /> AND
                    <ConditionalBreak showTablet={false} showDesktop={false} />
                    SHIP HOME
                </span>
            ),
            copy: `The items in your bag will be transferred to chanel.com, where you will finalize the details of your order.  Next day shipping is on us! `,
            primaryCta: `Continue to Chanel.com`
        };

        // copy for atelier checkout modal, if there are online-only products in the cart as well
        const inStoreCheckoutCopy = {
            heading: <span>REMEMBER!</span>,
            copy: inStoreCheckoutBodyCopy,
            primaryCta: `Confirm and review`,
            secondaryCta: 'return to shopping bag'
        };

        // analytics for online checkout
        const onCheckout = () => {
            if (!authenticated) {
                returnAfterLogin(`${ROUTES.CART}?startCartTransfer=true`);
                replace(ROUTES.LOGIN);
            } else {
                handleOnlineCheckout();
            }
        };

        const modalCopy =
            selectedCheckoutMethod === CHECKOUT_METHOD.ONLINE
                ? onlineCheckoutCopy
                : inStoreCheckoutCopy;
        const primaryAction =
            selectedCheckoutMethod === CHECKOUT_METHOD.ONLINE
                ? onCheckout
                : proceedToCheckoutPage;

        return (
            <ModalContentBodyWrapper>
                <ModalHeader>{modalCopy.heading}</ModalHeader>
                <ModalBodyText>{modalCopy.copy}</ModalBodyText>
                <CheckoutModalFlex
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Button type="submit" onClick={primaryAction}>
                        {modalCopy.primaryCta}
                    </Button>
                    {modalCopy.secondaryCta && (
                        <StyledButton
                            variant={'simpleBlack'}
                            onClick={toggleModal}
                        >
                            {modalCopy.secondaryCta}
                        </StyledButton>
                    )}
                </CheckoutModalFlex>
            </ModalContentBodyWrapper>
        );
    };

    render() {
        const { showModal, toggleModal } = this.props;

        if (!showModal) {
            return null;
        }

        return (
            <PrimaryModal
                isOpen={showModal}
                closePadding={[0, 0, 19, 0]}
                onRequestClose={toggleModal}
                atScreenCenter
                showClose
                isDialogue
                previousScrollDisabledState={false}
            >
                {this.renderContent()}
            </PrimaryModal>
        );
    }
}
