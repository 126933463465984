import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';
import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';

const KitWrapper = styled.div`
    margin-top: ${rem(30)};
`;

const Title = styled.p`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(10)};
    font-weight: bold;
`;

const StyledSwatchCheckboxWrapper = styled.div`
    display: flex;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
    justify-content: center;
    padding-left: ${rem(8)};

    > div {
        padding: 0 20px;
    }
`;

const CheckboxLabel = styled.div`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(9)};
    font-weight: bold;
    text-align: center;
    width: 100%;
    padding-right: ${rem(6)};
    margin-top: ${rem(5)};
    opacity: 0.7;
    ${({ selected }) => (selected ? 'opacity: 1;' : null)}
`;

export default class AddInKitOptions extends Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        addProductInKit: PropTypes.func.isRequired,
        removeProductInKit: PropTypes.func.isRequired
    };

    _handleCheckboxSelect = isAdd => () => {
        const { product, addProductInKit, removeProductInKit } = this.props;

        if (isAdd) {
            addProductInKit({
                sku: product.sku
            });
        } else {
            removeProductInKit({
                sku: product.sku
            });
        }
    };

    render() {
        const { inKit } = this.props.product;

        return (
            <KitWrapper>
                <Title>Included in kit?</Title>
                <StyledSwatchCheckboxWrapper>
                    <SwatchCheckbox
                        name={SWATCH_CHECKBOX_NAMES.OTHERS_4}
                        value={'yes'}
                        flexDirection={'column'}
                        imageOrder={0}
                        onSelect={this._handleCheckboxSelect(true)}
                        textAlign={'center'}
                        display={'flex'}
                        isSelected={!!inKit}
                    >
                        <CheckboxLabel selected={!!inKit}>Yes</CheckboxLabel>
                    </SwatchCheckbox>

                    <SwatchCheckbox
                        name={SWATCH_CHECKBOX_NAMES.OTHERS_4}
                        value={'no'}
                        flexDirection={'column'}
                        imageOrder={0}
                        onSelect={this._handleCheckboxSelect(false)}
                        textAlign={'center'}
                        display={'flex'}
                        isSelected={!inKit}
                    >
                        <CheckboxLabel selected={!inKit}>No</CheckboxLabel>
                    </SwatchCheckbox>
                </StyledSwatchCheckboxWrapper>
            </KitWrapper>
        );
    }
}
