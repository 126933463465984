import { createAction } from 'redux-actions';
import createFetchReducer from 'reducers/createFetchReducer';
import { FETCH } from 'middlewares/fetch';
import { API_ENDPOINT_TYPES } from 'constants/apiEndpoints';
import generateContextualApiUrl from 'core/utils/generateContextualApiUrl';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import normalizeProduct from 'core/utils/normalizeProduct';
import { SEARCH as SEARCH_CONSTANT } from 'constants/search';
export const SEARCH = 'SEARCH';

// Elastic Search Docs (For more details: https://www.elastic.co/guide/en/elasticsearch/reference/current/search-request-body.html)

// QUERY PARAMS
// from:                To retrieve hits from a certain offset. Defaults to 0.
//
// size:                The number of hits to return. Defaults to 10. If you do not care about getting some hits back
//
//                      but only about the number of matches and/or aggregations, setting the value to 0 will help performance.
// timeout:             Search timeout
//
// search_type:         The type of the search operation to perform. Can be dfs_query_then_fetch or query_then_fetch. Defaults to query_then_fetch.
//
// request_cache:       Set to true or false to enable or disable the caching of search results for requests where size is 0
// terminate_after:     The maximum number of documents to collect for each shard, upon reaching which the query execution will terminate early.
//                      Set to 1 to only return first result
//batched_reduce_size:  The number of shard results that should be reduced at once on the coordinating node.

/**
 * Generate query URL off of requested data type. See above for documentation notes.
 * @param {Object} params
 * @param {String} endpoint
 * @returns {string} url
 */
//TODO: allow multiple endpoints for mget vs actual search
const generateQueryUrl = (endpoint, params) => {
    let apiPath = '';
    const additionalParam = [];

    // TODO: streamling Default vs Get API url, pending any other API options and confirmation on how pagination works for them
    if (endpoint === 'DEFAULT') {
        apiPath = generateContextualApiUrl(API_ENDPOINT_TYPES.PRODUCT_SEARCH, 'DEFAULT');

        //TODO: FIX THIS MESS
        if (params) {

            // prettier-ignore
            // eslint-disable-next-line max-len
            if (params.currentPage && params.perPag) {
                additionalParam.push({ key: 'from', value: params.currentPage * params.perPage });
                additionalParam.push({ key: 'size', value: params.perPage });
            }

            if (params.terminate_after) {
                additionalParam.push({ key: 'terminate_after', value: params.terminate_after });
            }

            if (params.timeout) {
                additionalParam.push({ key: 'timeout', value: params.timeout });
            }
        }
    }

    if (endpoint === 'MGET') {
        apiPath = generateContextualApiUrl(API_ENDPOINT_TYPES.PRODUCT_SEARCH, 'MGET');
    }

    const queryUrl = additionalParam.length === 0
        ? apiPath
        : `${apiPath}${additionalParam.reduce((acc, param, index) =>
            `${acc}${index > 0 ? '&' : ''}${param.key}=${param.value}`, '?')}`;

    return queryUrl;
};

export const searchProducts = createAction(FETCH, ({ endpoint, request, signal }, prefix = SEARCH) => ({
    prefix,
    endpoint: generateQueryUrl(endpoint, request.params),
    options: {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(request.bodyRequest),
        signal
    }
}));

export const searchProductsBySkus = (skus, prefix) => {
    const request = {
        bodyRequest: elasticSearchQueries.queryBySkus(skus),
        params: {
            perPage: SEARCH_CONSTANT.ZONE.perPage,
            currentPage: 0
        }
    };
    const endpoint = SEARCH_CONSTANT.URL.MGET;

    return searchProducts({ endpoint, request }, prefix);
};

/**
 * Given a product code, return normalized product data
 * @param {string} productCode
 * @param {boolean} isStaff
 * @returns {function(*): *} - Dispatched action for Product array
 */
export const fetchProductByProductCode = (productCode, isStaff = false) => {
    return (dispatch) => {
        const request = {
            bodyRequest: elasticSearchQueries.queryByProductCode(productCode, isStaff)
        };

        const endpoint = SEARCH_CONSTANT.URL.DEFAULT;

        return dispatch(searchProducts({ endpoint, request }))
            .then(({ hits: { hits: results } }) => {
                return results.map(normalizeProduct);
            });
    };
};

/**
 * Return normalized product data for the Fragrance Experience
 * @returns {function(*): *} - Dispatched action for Product array
 */
export const fetchFragranceExperienceProducts = () => {
    return (dispatch) => {
        const request = { bodyRequest: elasticSearchQueries.queryProductsInFragranceExperience() };
        const endpoint = SEARCH_CONSTANT.URL.DEFAULT;

        return dispatch(searchProducts({ endpoint, request }))
            .then(({ hits: { hits: results } }) => {
                return results.map(normalizeProduct);
            });
    };
};

/**
 * Given an array of skus, return normalized product data
 * @param {array} skuIds
 * @returns {function(*): *} - Dispatched action for Product array
 */
export const fetchProductsBySkus = (skuIds) => {
    return (dispatch) => {
        const request = {
            bodyRequest: elasticSearchQueries.queryBySkus(skuIds)
        };
        const endpoint = SEARCH_CONSTANT.URL.MGET;

        return dispatch(searchProducts({ endpoint, request }))
            .then(({ docs: results }) => {
                return results.filter(product => product.found).map(normalizeProduct);
            });
    };
};

export default createFetchReducer(SEARCH);
