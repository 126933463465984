import moment from 'moment';
import formatMask from 'core/utils/formatMask';

export const CHECKIN_CREATED = 'created';
export const CHECKED_IN_STATUS = 'checkedin';
const CHECKED_OUT_STATUS = 'checkedout';
const ANALOG_CHECKIN_STATUS = 'analog';
export const ANONYMOUS_DISPLAY = '"Anonymous"';

export const normalizeCheckins = ({ checkin, profile }) => {
    const result = {
        profile: null,
        created: moment(checkin.created).format('h:mmA'),
        isAnalog: checkin.status === ANALOG_CHECKIN_STATUS,
        display: ANONYMOUS_DISPLAY, // default,
        isAnonymous: false,
        checkInToken: checkin.token,
        tourist: checkin.tourist,
    };

    // user is checked-in and logged in
    if (checkin.status === CHECKED_IN_STATUS || checkin.status === CHECKED_OUT_STATUS) {
        result.display = `${profile.firstName} ${profile.lastName}`;
        result.profile = profile;
        return result;
    }

    // user is analog or hasn't logged in yet
    if (checkin.phone) { // First check phone
        result.display = formatMask(checkin.phone, '(###) ###-####');
    } else if (checkin.email) { // Second, Email
        result.display = checkin.email;
    } else if (checkin.firstName || checkin.lastName) { // try the name first
        result.display = `${checkin.firstName || ''} ${checkin.lastName || ''}`.trim();
    }

    result.isAnonymous = result.display === ANONYMOUS_DISPLAY;

    return result;
};
