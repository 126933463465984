import { createAction } from 'redux-actions';
import createFetchReducer from 'reducers/createFetchReducer';
import { FETCH } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';

export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH';

export const searchCustomers = createAction(FETCH, query => ({
    prefix: CUSTOMER_SEARCH,
    endpoint: `${CONFIG.API_URL}/profiles/search`,
    options: {
        method: 'POST',
        body: JSON.stringify({ query: query })
    }
}));

export default createFetchReducer(CUSTOMER_SEARCH);
