import styled from 'styled-components';
import { styledProps, rem } from 'core/styled';

const ChromosomeTitle = styled.span`
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(12)};
    font-family: ${styledProps('font', 'Chromosome')};
    color: ${({ fontColor }) => fontColor ? styledProps('color', fontColor) : styledProps('color', 'black')};
    text-align: center;
    text-transform: uppercase;
    display:  ${({ display }) => display ? display : 'inline-block'};
    margin-top: ${({ marginTop }) => marginTop ? rem(marginTop) : ''};
`;

export default ChromosomeTitle;
