import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import {
    Accordion,
    AccordionItemHeading,
    AccordionItemButton
} from 'react-accessible-accordion';
import { rem, styledProps } from 'core/styled';
import formatPhoneNumber from 'core/utils/formatPhoneNumber';
import { ROUTES } from 'constants/routes';
import VIP from 'constants/vip';
import { QUESTIONNAIRE_TYPES } from 'constants/questionnaire';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';
import { getLocationByID, LOCATIONS, LOCATION_TEXT } from 'constants/locations';
import { setAppointmentContext } from 'reducers/questionnaire';
import { fetchCustomerAppointments } from 'reducers/profileModules/myAppointments';
import { listCheckins, CHECKIN_STATUS_FILTER } from 'reducers/checkIn';
import { lookupCustomerProfile } from 'reducers/customerProfileLookup';
import {
    impersonate,
    updateVipStatus,
    updateFreelancerStatus,
    saveEnvelopeDetails,
    setEmployeeStatus
} from 'reducers/user';
import Header from 'components/GlobalComponents/Fonts/Header';
import SmallNotice from 'components/GlobalComponents/Fonts/SmallNotice';
import Button from 'components/GlobalComponents/Button/Button';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import Loader from 'components/GlobalComponents/Loader/Loader';
import {
    AccordionItemStyled,
    AccordionBodyStyled
} from 'components/GlobalComponents/Accordion';
import EnvelopeForm from 'components/ProfileComponents/EnvelopeForm';

const ProfileSnapShotWrapper = styled.div`
    margin: ${rem(25, 30, 50, 30)};
    min-height: auto;
    text-align: center;
`;

const CustomerVisitsCountBlock = styled.div`
    border-top: 2px solid ${styledProps('color', 'mercury')};
    border-bottom: 2px solid ${styledProps('color', 'mercury')};
    padding: ${rem(25)} 0;
    margin: ${rem(30)} 0;
    width: 100%;
    min-height: auto;
`;

const StyledButton = styled(Button)`
    width: 180px;
    ${({ margin }) => (margin ? `margin: ${rem(...margin)};` : null)}
`;

const AppointmentsBlock = styled.div`
    min-height: auto;
    text-align: center;
`;

const AppointmentType = styled.p`
    font-family: ${styledProps('font', 'ABChanelPBL')};
    font-size: ${rem(20)};
    font-weight: 600;
    margin: ${rem(20, 0, 10, 0)};
    text-align: center;
`;

const StyledTiltedText = styled(TiltedText)`
    margin-bottom: ${rem(15)};
`;

const ProfileVIPForm = styled.div`
    background: white;
    width: 100%;
    margin: 0;
    left: 0;
    min-height: 170px;
`;

const AccordionItemHeadingStyled = styled(AccordionItemHeading)`
    position: relative;
    border-top: 2px solid ${styledProps('color', 'mercury')};
    border-bottom: 2px solid ${styledProps('color', 'black')};
    padding-top: ${rem(34)};
    padding-bottom: ${rem(17)};

    .accordionArrow {
        margin-top: 0;
    }
`;

const MembershipWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    ${({ padding }) => (padding ? `padding: ${rem(...padding)};` : '')}

    > div {
        width: 50%;
    }
`;

const MEMBER = VIP.REGULAR.STRING;
const FOUNDING_MEMBER = VIP.FOUNDER.STRING;
const NON_MEMBER = VIP.NON_MEMBER.STRING;
const FREELANCER = VIP.FREELANCER.STRING;
const MY_ATELIER = VIP.MY_ATELIER.STRING;

class ProfileSnapshot extends Component {
    static propTypes = {
        userInfo: PropTypes.object.isRequired,
        impersonateFetching: PropTypes.bool.isRequired,
        appointment: PropTypes.object,
        fetchCustomerAppointments: PropTypes.func.isRequired,
        lookupCustomerProfile: PropTypes.func.isRequired,
        onRequestClose: PropTypes.func.isRequired,
        setAppointmentContext: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        myAppointments: PropTypes.object.isRequired,
        impersonate: PropTypes.func.isRequired,
        updateVipStatus: PropTypes.func.isRequired,
        customerLookup: PropTypes.object.isRequired,
        needsFetchProfile: PropTypes.bool,
        tag: PropTypes.func,
        listCheckins: PropTypes.func,
        idToSessionMap: PropTypes.object,
        updateFreelancerStatus: PropTypes.func.isRequired,
        isFreelancer: PropTypes.bool.isRequired,
        sessions: PropTypes.object.isRequired,
        saveEnvelopeDetails: PropTypes.func.isRequired,
        savingEnvelope: PropTypes.bool,
        setEmployeeStatus: PropTypes.func.isRequired
    };

    static defaultProps = {
        needsFetchProfile: false,
        appointment: null,
        tag: () => {}
    };

    state = {
        memberStatus: '',
        userInfo: {},
        isEnvelopeReceived: false,
        envelopeSubmitted: false,
        now: new Date().getTime()
    };

    constructor(props) {
        super(props);
        if (props.needsFetchProfile) {
            props.lookupCustomerProfile(props.userInfo.profile.externalId);
        }
    }

    componentDidMount() {
        const {
            userInfo: {
                profile: { externalId }
            },
            fetchCustomerAppointments,
            tag
        } = this.props;
        tag('customersnapshot-initiated');
        // fetch appointments for customer
        fetchCustomerAppointments(externalId);
        this._getProfile();
    }

    /**
     * Start impersonation, then navigate to the questionnaire.
     */
    navigateToQuestionnaire() {
        const {
            impersonate,
            userInfo: { profile },
            push,
            setAppointmentContext,
            appointment,
            appointment: { questionnaireId },
            tag
        } = this.props;
        const id = questionnaireId || QUESTIONNAIRE_TYPES.MAKEUP_SKINCARE.id;

        setAppointmentContext(appointment);
        impersonate(profile.externalId).then(() => {
            tag('customersnapshot-questionnaire');
            push(`${ROUTES.QUESTIONNAIRE}/${QUESTIONNAIRE_TYPES.getUrl(id)}`);
        });
    }

    /**
     * Render appointments data for customer
     * @returns {component} component
     * @private
     */
    _renderAppointments() {
        const {
            myAppointments: { isFetching },
            appointment,
            idToSessionMap,
            sessions
        } = this.props;

        if (isFetching) {
            return <Loader />;
        }

        if (appointment) {
            if (!idToSessionMap[appointment.typeId]) {
                return null;
            }

            const isResidency =
                idToSessionMap &&
                idToSessionMap[appointment.typeId] === 'Residency';
            const name =
                idToSessionMap &&
                idToSessionMap[appointment.typeId] === 'Residency'
                    ? appointment.name
                    : sessions[idToSessionMap[appointment.typeId]].All
                        .cardDisplay;

            return (
                <AppointmentsBlock
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Header fontSize={26}>UPCOMING BOOKING:</Header>
                    <AppointmentType> {name} </AppointmentType>
                    <SmallNotice fontSize={11} margin={[0]}>
                        {appointment.weekDay} {appointment.month}{' '}
                        {appointment.day}
                    </SmallNotice>
                    <SmallNotice fontSize={11} margin={[0]}>
                        with {appointment.artistName}
                    </SmallNotice>
                    {!isResidency && (
                        <div onClick={() => this.navigateToQuestionnaire()}>
                            <SwatchCheckbox
                                value={'quest'}
                                name={
                                    SWATCH_CHECKBOX_NAMES.OFF_WHITE_SKINCARE_7
                                }
                                isSelected={!appointment.needsQuestionnaire}
                                disabled={true}
                            >
                                <SmallNotice fontSize={11} margin={[5]}>
                                    QUESTIONNAIRE
                                </SmallNotice>
                            </SwatchCheckbox>
                        </div>
                    )}
                </AppointmentsBlock>
            );
        }

        return null;
    }

    triggerImpersonation(externalId) {
        const { impersonate, onRequestClose, tag } = this.props;
        tag('customersnapshot-browseas');
        impersonate(externalId).then(onRequestClose);
    }

    _getProfile = () => {
        const {
            needsFetchProfile,
            customerLookup: { response },
            userInfo
        } = this.props;

        switch (true) {
            case !needsFetchProfile:
                this.setState({
                    userInfo,
                    memberStatus: this.getMembershipStatus(userInfo.profile),
                    isEnvelopeReceived: userInfo.profile.isEnvelopeReceived,
                    envelopeSubmitted: userInfo.profile.isEnvelopeReceived
                });
                break;
            case response === null || response.length === 0:
                this.setState({
                    userInfo: {
                        profile: null,
                        checkins: null
                    }
                });
                break;
            default:
                this.setState({
                    userInfo: response[0],
                    memberStatus: this.getMembershipStatus(response[0]),
                    isEnvelopeReceived: response[0].isEnvelopeReceived,
                    envelopeSubmitted: response[0].isEnvelopeReceived
                });
        }
    };

    /**
     * Get membership status from profile data, if available
     * @param {object} profile
     * @returns {string} membership status
     */
    getMembershipStatus(profile) {
        if (profile.isStaff && profile.staffRole === FREELANCER) {
            return FREELANCER;
        }

        return profile.vip && profile.vip.end > this.state.now
            ? profile.vip.status
            : '';
    }

    /**
     * Get the display text to be displayed on top of the name
     * @return {string} text to display
     */
    getMemberDisplayText() {
        const { memberStatus, isEnvelopeReceived } = this.state;

        switch (memberStatus) {
            case FREELANCER:
                return VIP.FREELANCER.DISPLAY_TEXT;
            case FOUNDING_MEMBER:
                return VIP.FOUNDER.DISPLAY_TEXT;
            case MEMBER:
                return VIP.REGULAR.DISPLAY_TEXT;
            case MY_ATELIER:
                return VIP.MY_ATELIER.DISPLAY_TEXT;
            default:
                return isEnvelopeReceived ? 'Envelope Received' : '';
        }
    }

    /**
     * Get the stamp color to be displayed on top of the name
     * @return {string} stamp color
     */
    getMemberDisplayColor() {
        const { memberStatus, isEnvelopeReceived } = this.state;

        switch (memberStatus) {
            case FREELANCER:
                return VIP.FREELANCER.COLOR;
            case FOUNDING_MEMBER:
                return VIP.FOUNDER.COLOR;
            case MEMBER:
                return VIP.REGULAR.COLOR;
            case MY_ATELIER:
                return VIP.MY_ATELIER.COLOR;
            default:
                return isEnvelopeReceived ? 'killarney' : 'transparent';
        }
    }

    /**
     * Update membership (VIP and Freelancers)
     * @param {string} id
     * @param {string} externalId
     * @returns {function} function
     */
    toggleMembership = (id, externalId) => selectedStatus => {
        const {
            updateVipStatus,
            updateFreelancerStatus,
            listCheckins
        } = this.props;
        const { memberStatus } = this.state;

        // Only need to update freelancer status if the user was a non member or freelancer and have selected freelancer
        if (
            selectedStatus === FREELANCER &&
            (!memberStatus ||
                memberStatus === FREELANCER ||
                memberStatus === NON_MEMBER)
        ) {
            // Set freelancer to true if the user is currently a non_member, false if it is currently a freelancer
            updateFreelancerStatus(
                externalId,
                memberStatus !== FREELANCER
            ).then(response => {
                // update list of checked in users to reflect new membership state
                listCheckins({ status: CHECKIN_STATUS_FILTER });

                this.setState({
                    memberStatus:
                        this.getMembershipStatus(response) || NON_MEMBER,
                    userInfo: {
                        profile: response,
                        checkins: this.state.userInfo.checkins
                    }
                });
            });

            // Only need to update vip status if the user was not a freelancer and have not selected freelancer
        } else if (
            selectedStatus !== FREELANCER &&
            memberStatus !== FREELANCER
        ) {
            const newMemberStatus =
                this.state.memberStatus === selectedStatus
                    ? NON_MEMBER
                    : selectedStatus;

            updateVipStatus(id, newMemberStatus).then(res => {
                // update list of checked in users to reflect new membership state
                listCheckins({ status: CHECKIN_STATUS_FILTER });

                this.setState({
                    memberStatus: newMemberStatus,
                    userInfo: {
                        profile: res,
                        checkins: this.state.userInfo.checkins
                    }
                });
            });

            // Need to update both if the user was freelancer and is now a vip, or viceversa
        } else {
            const newVipStatus =
                selectedStatus === FREELANCER ? NON_MEMBER : selectedStatus;
            const newFreelancerStatus = selectedStatus === FREELANCER;

            updateVipStatus(id, newVipStatus).then(() => {
                updateFreelancerStatus(externalId, newFreelancerStatus).then(
                    response => {
                        // update list of checked in users to reflect new membership state
                        listCheckins({ status: CHECKIN_STATUS_FILTER });

                        this.setState({
                            memberStatus:
                                this.getMembershipStatus(response) ||
                                NON_MEMBER,
                            userInfo: {
                                profile: response,
                                checkins: this.state.userInfo.checkins
                            }
                        });
                    }
                );
            });
        }
    };

    toggleEnvelopeReceived = () => {
        this.setState({
            isEnvelopeReceived: !this.state.isEnvelopeReceived,
            envelopeSubmitted: false
        });
    };

    saveEnvelopeDetails = (envelopeDate, envelopeNumber) => {
        const {
            userInfo: {
                profile: { externalId }
            }
        } = this.state;
        return this.props.saveEnvelopeDetails(
            externalId,
            envelopeDate,
            envelopeNumber
        );
    };

    toggleEmployeeStatus = () => {
        const { setEmployeeStatus } = this.props;
        const { userInfo } = this.state;

        setEmployeeStatus(
            userInfo.profile.id,
            !userInfo.profile.isEmployee
        );

        this.setState({
            userInfo: {
                ...userInfo,
                profile: {
                    ...userInfo.profile,
                    isEmployee: !userInfo.profile.isEmployee
                }
            }
        });
    };

    render() {
        const {
            onRequestClose,
            impersonateFetching,
            customerLookup: { isFetching },
            tag,
            isFreelancer,
            savingEnvelope
        } = this.props;
        const {
            memberStatus,
            userInfo: { profile, checkins },
            isEnvelopeReceived,
            envelopeSubmitted
        } = this.state;

        if (isFetching || !profile) {
            return (
                <ProfileSnapShotWrapper
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {isFetching && <Loader />}
                </ProfileSnapShotWrapper>
            );
        }

        const displayLocation = getLocationByID(profile.location) || 'NYC';

        const membershipExpiration =
            memberStatus === MY_ATELIER
                ? ` (Exp:\u00a0${moment(profile.vip.end).format('M/D')})`
                : '';

        return (
            <ProfileSnapShotWrapper>
                <SmallNotice fontSize={14} margin={[0, 0, 10]}>
                    {LOCATION_TEXT[LOCATIONS[displayLocation]]}
                </SmallNotice>
                {((memberStatus && memberStatus !== NON_MEMBER) ||
                    isEnvelopeReceived) && (
                    <StyledTiltedText
                        fontSize={18}
                        bgColor={this.getMemberDisplayColor()}
                        fontColor={'white'}
                        rotate={5}
                    >
                        {this.getMemberDisplayText()}
                    </StyledTiltedText>
                )}
                {checkins &&
                    checkins.inStore &&
                    checkins.inStore.length > 0 && (
                    <TiltedText rotate={-5}>CHECKED-IN</TiltedText>
                )}
                {profile.isEmployee && <SmallNotice fontSize={12} margin={[10, 0, 0]}>
                    CHANEL EMPLOYEE
                </SmallNotice>}
                <Header
                    cssMargin={[10, 0, 10, 0]}
                    lineHeight={0.93}
                    cssColor={
                        memberStatus === FREELANCER
                            ? 'finn'
                            : isEnvelopeReceived
                                ? 'killarney'
                                : 'black'
                    }
                >
                    <span translate={'no'} className={'notranslate'}>
                        {profile.firstName}
                    </span>{' '}
                    <br />
                    <span translate={'no'} className={'notranslate'}>
                        {profile.lastName}
                    </span>
                </Header>
                {profile.email && (
                    <SmallNotice fontSize={12} margin={[0]}>
                        {profile.email}
                    </SmallNotice>
                )}
                {profile.phone && (
                    <SmallNotice fontSize={12} margin={[0]}>
                        {formatPhoneNumber(profile.phone)}
                    </SmallNotice>
                )}

                {((checkins && checkins.visits > 0) ||
                    (profile && profile.visits > 0)) && (
                    <CustomerVisitsCountBlock>
                        <Header fontSize={26}>
                            TOTAL ATELIER VISITS:{' '}
                            {profile.visits ? profile.visits : checkins.visits}
                        </Header>
                    </CustomerVisitsCountBlock>
                )}

                {this._renderAppointments()}

                <AppointmentsBlock>
                    <StyledButton
                        onClick={() =>
                            this.triggerImpersonation(profile.externalId)
                        }
                        margin={[30, 0, 10, 0]}
                        loading={impersonateFetching}
                        disabled={memberStatus === FREELANCER}
                    >
                        browse as
                    </StyledButton>
                    <StyledButton
                        onClick={() => {
                            onRequestClose();
                            tag('customersnapshot-back');
                        }}
                        variant={'simpleBlack'}
                    >
                        back
                    </StyledButton>
                </AppointmentsBlock>

                {!isFreelancer && (
                    <ProfileVIPForm>
                        <Accordion allowZeroExpanded>
                            <AccordionItemStyled>
                                <AccordionItemHeadingStyled>
                                    <AccordionItemButton>
                                        <SmallNotice fontSize={12} margin={[0]}>
                                            Mark guest
                                        </SmallNotice>
                                        <div className={'accordionArrow'} />
                                    </AccordionItemButton>
                                </AccordionItemHeadingStyled>
                                <AccordionBodyStyled>
                                    <MembershipWrapper padding={[20, 0, 0]}>
                                        <SwatchCheckbox
                                            name={
                                                SWATCH_CHECKBOX_NAMES.PINK_LIPS_14
                                            }
                                            text={'Freelancer'}
                                            value={FREELANCER}
                                            onSelect={this.toggleMembership(
                                                profile.id,
                                                profile.externalId
                                            )}
                                            isSelected={
                                                memberStatus === FREELANCER
                                            }
                                            font={'ABChanelPBL'}
                                        />
                                        <SwatchCheckbox
                                            name={
                                                SWATCH_CHECKBOX_NAMES.RED_LIPS_15
                                            }
                                            text={`My Atelier Member${membershipExpiration}`}
                                            value={MY_ATELIER}
                                            onSelect={this.toggleMembership(
                                                profile.id,
                                                profile.externalId
                                            )}
                                            isSelected={
                                                memberStatus === MY_ATELIER
                                            }
                                            font={'ABChanelPBL'}
                                        />
                                    </MembershipWrapper>
                                    <MembershipWrapper>
                                        <SwatchCheckbox
                                            name={
                                                SWATCH_CHECKBOX_NAMES.GREEN_NAILS_3
                                            }
                                            text={'envelope received'}
                                            value={FOUNDING_MEMBER}
                                            onSelect={
                                                this.toggleEnvelopeReceived
                                            }
                                            isSelected={isEnvelopeReceived}
                                            font={'ABChanelPBL'}
                                        />
                                        <SwatchCheckbox
                                            name={
                                                SWATCH_CHECKBOX_NAMES.BLACK_NAILS_5
                                            }
                                            text={'Chanel Employee'}
                                            value={'employee'}
                                            onSelect={
                                                this.toggleEmployeeStatus
                                            }
                                            isSelected={profile.isEmployee}
                                            font={'ABChanelPBL'}
                                        />
                                    </MembershipWrapper>
                                    {isEnvelopeReceived && (
                                        <EnvelopeForm
                                            saveEnvelopeDetails={
                                                this.saveEnvelopeDetails
                                            }
                                            isLoading={savingEnvelope}
                                            startSubmitted={envelopeSubmitted}
                                        />
                                    )}
                                </AccordionBodyStyled>
                            </AccordionItemStyled>
                        </Accordion>
                    </ProfileVIPForm>
                )}
            </ProfileSnapShotWrapper>
        );
    }
}

const mapStateToProps = ({
    questionnaire,
    profileModules: { myAppointments },
    user: {
        auth: { impersonateFetching, isFreelancer },
        savingEnvelope
    },
    customerLookup,
    booking: { sessions, idToSessionMap }
}) => ({
    ...questionnaire,
    myAppointments,
    appointment: myAppointments.response
        ? myAppointments.response.find(a => !a.isPast)
        : null,
    impersonateFetching,
    customerLookup,
    idToSessionMap,
    isFreelancer,
    sessions,
    savingEnvelope
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            push,
            listCheckins,
            setAppointmentContext,
            fetchCustomerAppointments,
            lookupCustomerProfile,
            impersonate,
            updateVipStatus,
            updateFreelancerStatus,
            saveEnvelopeDetails,
            setEmployeeStatus
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSnapshot);
