import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import normalizeTransactions from 'core/utils/normalizeTransactions';

const TRANSACTIONS_FETCH = 'TRANSACTIONS_FETCH';
const TRANSACTIONS_RESET = 'TRANSACTIONS_RESET';
const TRANSACTIONS_SET_REFUND = 'TRANSACTION_SET_REFUND';
const TRANSACTIONS_REFUND = 'TRANSACTION_REFUND';
const TRANSACTION_RESET_REFUND = 'TRANSACTION_RESET_REFUND';

const initialState = {
    isFetching: false,
    error: null,
    transactions: [],
    transactionToRefund: null,
    isRefunding: false
};

const getTransactions = createAction(FETCH, (
    firstName,
    lastName,
    startTransactionDate,
    endTransactionDate,
    startServiceDate,
    endServiceDate,
    staffName,
    serviceName) => {

    const queryParams = new URLSearchParams();

    if (firstName) {
        queryParams.append('firstName', encodeURIComponent(firstName));
    }

    if (lastName) {
        queryParams.append('lastName', encodeURIComponent(lastName));
    }

    if (startTransactionDate) {
        queryParams.append('startTransactionDate', startTransactionDate);
    }

    if (endTransactionDate) {
        queryParams.append('endTransactionDate', endTransactionDate);
    }

    if (startServiceDate) {
        queryParams.append('startServiceDate', startServiceDate);
    }

    if (endServiceDate) {
        queryParams.append('endServiceDate', endServiceDate);
    }

    if (staffName) {
        queryParams.append('staffName', encodeURIComponent(staffName));
    }

    if (serviceName) {
        queryParams.append('serviceName', encodeURIComponent(serviceName));
    }

    return {
        prefix: TRANSACTIONS_FETCH,
        endpoint: `${CONFIG.API_URL}/transactions/search${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
    };
});

const refundTransaction = createAction(FETCH, (refund) => ({
    prefix: TRANSACTIONS_REFUND,
    endpoint: `${CONFIG.API_URL}/transactions/refund`,
    options: {
        method: 'POST',
        body: JSON.stringify(refund)
    }
}));

export const resetTransactions = createAction(TRANSACTIONS_RESET);

export const fetchTransactions = (firstname, lastName, startTransactionDate, endTransactionDate,
    startServiceDate, endServiceDate, staffName, serviceName) => {
    return (dispatch) => {
        return dispatch(getTransactions(firstname, lastName, startTransactionDate, endTransactionDate,
            startServiceDate, endServiceDate, staffName, serviceName));
    };
};

export const refundSelectedTransaction = (note, fullRefund) => {
    return (dispatch, getState) => {
        const txId = getState().transactions.transactionToRefund.transactionId;

        return dispatch(refundTransaction({
            txId,
            note,
            fullRefund
        }));
    };
};

const setTransactionToRefund = createAction(TRANSACTIONS_SET_REFUND);

export const setRefundTransaction = transaction => {
    return (dispatch) => {
        return Promise.resolve(dispatch(setTransactionToRefund(transaction)));
    };
};

export const resetRefundTransaction = createAction(TRANSACTION_RESET_REFUND);

export default handleActions({
    [`${TRANSACTIONS_FETCH}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [`${TRANSACTIONS_FETCH}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    }),
    [`${TRANSACTIONS_FETCH}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        transactions: normalizeTransactions(payload),
        isFetching: false
    }),
    [TRANSACTIONS_SET_REFUND]: (state, { payload }) => ({
        ...state,
        transactionToRefund: payload
    }),
    [TRANSACTION_RESET_REFUND]: (state) => ({
        ...state,
        transactionToRefund: null,
        refundError: null
    }),
    [TRANSACTIONS_RESET]: (state) => ({
        ...state,
        ...initialState
    }),
    [`${TRANSACTIONS_REFUND}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        isRefunding: true,
        refundError: null
    }),
    [`${TRANSACTIONS_REFUND}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        refundError: payload,
        isRefunding: false
    }),
    [`${TRANSACTIONS_REFUND}/${FETCH_SUCCESS}`]: (state) => ({
        ...state,
        isRefunding: false,
        refundError: null
    })
}, initialState);
