/**
 * Returns a function which can be used to determine if the browser is mobile and which OS.
 * @type {{Android: function(): (RegExpMatchArray | null), BlackBerry: function(): (RegExpMatchArray | null), iOS: function(): (RegExpMatchArray | null), Opera: function(): (RegExpMatchArray | null), Windows: function(): (RegExpMatchArray | null), any: function(): *}}
 */
export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
