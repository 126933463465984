const SEVEN_DAYS = 604800000; // seven days in ms

/**
 * Normalize orders for BOH operations
 * @param {object} order - from the BE
 * @return {object} order - ready to use for FE
 */
const normalizeOrders = (order) => ({
    id: order.id,
    transactionId: order.xstoreTransactionId || order.orderId,
    cart: order.cart,
    status: order.status,
    created: order.created,
    updated: order.lastUpdated,
    firstName: order.profile ? order.profile.firstName : '',
    lastName: order.profile ? order.profile.lastName : '',
    phone: order.checkin ? order.checkin.phone : '',
    externalId: order.profile ? order.profile.externalId : '',
    pickedUpTime: order.pickedUp,
    itemsVisible: false,
    name: ((order.profile ? order.profile.firstName : '') + ' ') + ((order.profile ? order.profile.lastName : '') ? (order.profile ? order.profile.lastName : '').charAt(0).toUpperCase() + '.' : ''),
    checkinCount: order.checkinCount,
    reservedOnline: order.pickup,
    paid: order.paid,
    disableCancel: order.disableCancel,
    errorMessage: order.errorMessage,
    showScalefastCancelMessage: order.paid && Date.now() > (order.created + SEVEN_DAYS)
});

export default normalizeOrders;
