import { createAction, handleActions } from 'redux-actions';
import { fetchPagesByID } from './prismicFetch';
import formatPrismicData from 'core/utils/formatPrismicData';
import { fetchProductsBySkus } from './elasticsearch';
import { PRISMIC_DATA_FORMAT, PRISMIC_ARTIST_TYPE } from 'constants/prismic';
import { store } from 'store';

const SET_MAKEUP_ARTIST_PAGE_IDS = 'SET_MAKEUP_ARTIST_PAGE_IDS';
const SET_RESIDENCY_ARTIST_PAGE_IDS = 'SET_RESIDENCY_ARTIST_PAGE_IDS';
const SET_VIRTUAL_ARTIST_PAGE_IDS = 'SET_VIRTUAL_ARTIST_PAGE_IDS';
const SET_MAKEUP_ARTISTS = 'SET_MAKEUP_ARTISTS';
const SET_RESIDENCY_ARTISTS = 'SET_RESIDENCY_ARTISTS';
const SET_VIRTUAL_ARTISTS = 'SET_VIRTUAL_ARTISTS';
const SET_MAKEUP_ARTISTS_PRODUCTS = 'SET_MAKEUP_ARTISTS_PRODUCTS';
const SET_CURRENT_MUA_DATA = 'SET_CURRENT_MUA_DATA';
const SET_FREELANCER_STAFF_ID = 'SET_FREELANCER_STAFF_ID';

export const setMakeupArtistPageIds = createAction(SET_MAKEUP_ARTIST_PAGE_IDS);
export const setResidencyArtistPageIds = createAction(SET_RESIDENCY_ARTIST_PAGE_IDS);
export const setVirtualArtistPageIds = createAction(SET_VIRTUAL_ARTIST_PAGE_IDS);

export const setMakeupArtistsData = createAction(SET_MAKEUP_ARTISTS);
export const setResidencyArtistsData = createAction(SET_RESIDENCY_ARTISTS);
export const setVirtualArtistsData = createAction(SET_VIRTUAL_ARTISTS);

export const setMakeupArtistProducts = createAction(SET_MAKEUP_ARTISTS_PRODUCTS);
export const setCurrentMUAData = createAction(SET_CURRENT_MUA_DATA);
export const setFreelancerStaffId = createAction(SET_FREELANCER_STAFF_ID);

/**
 * Fetch the makeup artist data with the pre-fetched artists page IDs.
 * @returns {function(*, *): *} - Dispatched action for artist data
 */
export const getMakeupArtists = () => {

    return (dispatch, getState) => {
        const { makeupArtists: { pageIds }  } = getState();

        return dispatch(fetchPagesByID(pageIds)).then(() => {
            const { prismic } = getState();
            const artistsData = formatPrismicData(prismic, PRISMIC_DATA_FORMAT.MAKEUP_ARTISTS, PRISMIC_ARTIST_TYPE.MASTER_ARTIST);

            dispatch(setMakeupArtistsData(artistsData));

            // fetch products for all artists
            const productSkus = [...new Set(artistsData.map(artist => artist.productData.map(product => product.skuId)).reduce((acc, val) => acc.concat(val), []))];
            return dispatch(fetchProductsBySkus(productSkus))
                .then((products) => {
                    dispatch(setMakeupArtistProducts({
                        products
                    }));
                });

        });
    };
};

/**
 * Fetch the residency artist data with the pre-fetched artists page IDs.
 * @returns {function(*, *): *} - Dispatched action for artist data
 */
export const getResidencyArtists = () => {

    return (dispatch, getState) => {
        const { makeupArtists: { residencyPageIds }  } = getState();

        return dispatch(fetchPagesByID(residencyPageIds)).then(() => {
            const { prismic } = getState();
            const artistsData = formatPrismicData(prismic, PRISMIC_DATA_FORMAT.MAKEUP_ARTISTS, PRISMIC_ARTIST_TYPE.RESIDENCY_ARTIST);

            dispatch(setResidencyArtistsData(artistsData));

            // fetch products for all artists
            const productSkus = [...new Set(artistsData.map(artist => artist.productData.map(product => product.skuId)).reduce((acc, val) => acc.concat(val), []))];
            return dispatch(fetchProductsBySkus(productSkus))
                .then((products) => {
                    dispatch(setMakeupArtistProducts({
                        products
                    }));
                });

        });
    };
};


/**
 * Fetch the virtual artist data with the pre-fetched artists page IDs.
 * @returns {function(*, *): *} - Dispatched action for artist data
 */
export const getVirtualArtists = () => {

    return (dispatch, getState) => {
        const { makeupArtists: { virtualPageIds }  } = getState();

        return dispatch(fetchPagesByID(virtualPageIds)).then(() => {
            const { prismic } = getState();
            const artistsData = formatPrismicData(prismic, PRISMIC_DATA_FORMAT.MAKEUP_ARTISTS, PRISMIC_ARTIST_TYPE.VIRTUAL_ARTIST);

            return dispatch(setVirtualArtistsData(artistsData));
        });
    };
};

/**
 * Fetch the featured products for the artist.
 * @param {string} artistId
 * @returns {function(*, *): *} - Dispatched action for products
 */
export const getMakeupArtistProducts = (artistId) => {
    return (dispatch, getState) => {
        const { makeupArtists: { artistData } } = getState();
        const currentArtist = artistData.find(a => a.id === artistId);
        return dispatch(fetchProductsBySkus(currentArtist.productData.map(product => product.skuId)))
            .then((products) => {
                dispatch(setMakeupArtistProducts({
                    artistId,
                    products
                }));
            });
    };
};

export const getStaffIdFromUID = uid => {
    const makeupArtists = store.getState().makeupArtists;
    const artists = [
        ...makeupArtists.artistData,
        ...makeupArtists.residencyArtistData,
        ...makeupArtists.virtualArtistData
    ];

    const artist = artists.find(mua => mua.id === uid);

    if (artist && artist.staffid) {
        return artist.staffid;
    }

    return null;
};

export default handleActions({
    [setMakeupArtistPageIds]: (state, { payload }) => ({ ...state, pageIds: payload }),
    [setResidencyArtistPageIds]: (state, { payload }) => ({ ...state, residencyPageIds: payload }),
    [setVirtualArtistPageIds]: (state, { payload }) => ({ ...state, virtualPageIds: payload }),
    [setMakeupArtistsData]: (state, { payload }) => ({ ...state, artistData: payload }),
    [setResidencyArtistsData]: (state, { payload }) => ({ ...state, residencyArtistData: payload }),
    [setVirtualArtistsData]: (state, { payload }) => ({ ...state, virtualArtistData: payload }),
    [setMakeupArtistProducts]: (state, { payload }) => (
        {
            ...state,
            artistProducts: {
                ...state.artistProducts,
                ...payload.products.reduce((result, product) => {
                    result[product.id] = product;
                    return result;
                }, {})
            }
        }),
    [setCurrentMUAData]: (state, { payload }) => ({ ...state, currentMUAData: payload }),
    [setFreelancerStaffId]: (state, { payload }) => ({ ...state, freelancerStaffId: payload })
}, { pageIds: [], residencyPageIds: [], virtualPageIds: [], artistData: [], residencyArtistData: [], virtualArtistData: [], artistProducts: {}, currentMUAData: {}, freelancerStaffId: [] });
