import { createAction, handleActions } from 'redux-actions';
import { theme } from 'constants/theme';

export const HEADER_ICONS = {
    DEFAULT: 'default',
    INVERTED: 'inverted'
};

const fixedInitialState = {
    webView: false
};

// TODO: implement header theme properly: this is for icon colors in the header
const initialState = {
    backgroundColor: `${theme.color.white}`,
    headerBackground: `${theme.color.white}`,
    headerIcons: HEADER_ICONS.DEFAULT,
    isHeaderShrunk: false,
    scrollTop: 0,
    isFragrance: false,
    disableScroll: false,
    isHeaderStaffTheme: false,
    selectedFragrances: [],
    fragranceModalOpen: false,
    useParfumLogo: false,
    isMobileKeyboardOpen: false,
    showHeaderTicket: false,
    overrideHeaderTicketBehavior: false,
    inverted: false,
    hideFooter: false,
    preventModalOpenScrollToTop: false,
    isHeaderSearchOpen: false,
    forcedWhiteTicketHeader: false,
    cartOpen: false
};

const fragranceTheme = {
    backgroundColor: `${theme.color.black}`,
    headerBackground: `${theme.color.black}`,
    headerIcons: HEADER_ICONS.INVERTED,
    isHeaderShrunk: false,
    isFragrance: true,
    useParfumLogo: true
};

const fragranceTeaserTheme = {
    backgroundColor: `${theme.color.black}`,
    headerBackground: `${theme.color.black}`,
    headerIcons: HEADER_ICONS.INVERTED,
    isHeaderShrunk: false,
    isFragrance: false,
    useParfumLogo: true,
    disableScroll: false,
    inverted: true,
    hideFooter: true
};

const fragranceRevealTheme = {
    backgroundColor: `${theme.color.white}`,
    headerBackground: `${theme.color.white}`,
    headerIcons: HEADER_ICONS.DEFAULT,
    isHeaderShrunk: false,
    scrollTop: 0,
    isFragrance: false,
    disableScroll: false,
    isHeaderStaffTheme: false,
    fragranceModalOpen: false,
    useParfumLogo: true,
    inverted: false

};

const darkTheme = {
    headerBackground: `${theme.color.black}`,
    headerIcons: HEADER_ICONS.INVERTED,
    isHeaderShrunk: false,
    disableScroll: false,
    isFragrance: false,
    useParfumLogo: false,
    inverted: true
};

const staffTheme = {
    ...darkTheme,
    backgroundColor: `${theme.color.black}`,
    isHeaderStaffTheme: true
};


export const setBackgroundColor = createAction('SET_BACKGROUND_COLOR');

export const setHeaderColor = createAction('SET_HEADER_COLOR');

export const setHeaderShrunkState = createAction('SET_HEADER_SHRUNK_STATE');

export const setFragranceLayout = createAction('SET_FRAGRANCE_THEME');

export const disableScroll = createAction('DISABLE_SCROLL');
export const triggerPreventScrollToTop = createAction('PREVENT_SCROLL_TO_TOP');

export const resetLayout = createAction('RESET_LAYOUT');

export const setStaffTheme = createAction('SET_STAFF_THEME');

export const setDarkTheme = createAction('SET_DARK_THEME');

//TODO: remove invert icon method, in favor of below on that just sets the icon state manually
export const invertHeaderIcons = createAction('INVERT_HEADER_ICONS');

export const setHeaderIcons = createAction('SET_HEADER_ICONS');

export const setFragranceHeaderFlower = createAction('SHOW_FRAGRANCE_FLOWER');

export const setFragranceSelections = createAction('SET_FRAGRANCE_SELECTIONS');

export const setFragranceRevealTheme = createAction('SET_FRAGRANCE_REVEAL_THEME');

export const setFragranceTeaserTheme = createAction('SET_FRAGRANCE_TEASER_THEME');

export const setFragranceModalOpen = createAction('SET_FRAGRANCE_MODAL');

export const setIsMobileKeyboardOpen = createAction('SET_IS_MOBILE_KEYBOARD_OPEN');

export const setHeaderTicketVisible = createAction('SET_HEADER_TICKET_VISIBLE');

export const setHeaderTicketOverride = createAction('SET_HEADER_TICKET_OVERRIDE');

export const setIsHeaderSearchOpen = createAction('SET_IS_HEADER_SEARCH_OPEN');

export const setHideFooter = createAction('HIDE_FOOTER');

export const setUseParfumLogo = createAction('PARFUM_LOGO');

export const forceWhiteHeaderTicket = createAction('FORCE_WHITE_TICKET_HEADER');

export const setWebView = createAction('SET_WEB_VIEW');

export const setCartOpen = createAction('SET_CART_OPEN');

export default handleActions(
    {
        SET_BACKGROUND_COLOR: (state, { payload }) => ({ ...state, backgroundColor: payload }),
        SET_HEADER_COLOR: (state, { payload }) => ({ ...state, headerBackground: payload }),
        DISABLE_SCROLL: (state, payload) => ({ ...state, disableScroll: payload.payload }),
        PREVENT_SCROLL_TO_TOP: (state, payload) => ({ ...state, preventModalOpenScrollToTop: payload.payload }),
        SET_HEADER_SHRUNK_STATE: (state, { payload }) => ({ ...state, isHeaderShrunk: payload }),
        SET_FRAGRANCE_THEME: state => ({ ...state, ...fragranceTheme }),
        RESET_LAYOUT: (state) => ({ ...state, ...initialState }),
        SET_STAFF_THEME: (state) => ({ ...state, ...staffTheme }),
        SET_DARK_THEME: (state) => ({ ...state, ...darkTheme }),
        INVERT_HEADER_ICONS: (state) => ({ ...state, headerIcons: HEADER_ICONS.INVERTED }),
        SET_HEADER_ICONS: (state, { payload }) => ({ ...state, headerIcons: payload }),
        SHOW_FRAGRANCE_FLOWER: (state, { payload }) => ({ ...state, showFragranceHeaderFlower: payload }),
        SET_FRAGRANCE_SELECTIONS: (state, { payload }) => ({ ...state, selectedFragrances: payload }),
        SET_FRAGRANCE_MODAL: (state, { payload }) => ({ ...state, fragranceModalOpen: payload }),
        SET_FRAGRANCE_REVEAL_THEME: (state) => ({ ...state, ...fragranceRevealTheme }),
        SET_IS_MOBILE_KEYBOARD_OPEN: (state, { payload }) => ({ ...state, isMobileKeyboardOpen: payload }),
        SET_HEADER_TICKET_VISIBLE: (state, { payload }) => ({ ...state, showHeaderTicket: payload }),
        SET_HEADER_TICKET_OVERRIDE: (state, { payload }) => ({ ...state, overrideHeaderTicketBehavior: payload }),
        SET_IS_HEADER_SEARCH_OPEN: (state, { payload }) => ({ ...state, isHeaderSearchOpen: payload }),
        [setHideFooter]: (state, { payload }) => ({ ...state, hideFooter: payload }),
        [setUseParfumLogo]: (state, { payload }) => ({ ...state, useParfumLogo: payload }),
        [setFragranceTeaserTheme]: (state) => ({ ...state, ...fragranceTeaserTheme }),
        FORCE_WHITE_TICKET_HEADER: (state, { payload }) => ({ ...state, forcedWhiteTicketHeader: payload }),
        SET_WEB_VIEW: (state, { payload }) => ({ ...state, webView: payload }),
        SET_CART_OPEN: (state, { payload }) => ({ ...state, cartOpen: payload })
    },
    { ...fixedInitialState, ...initialState }
);
