import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_RESET, FETCH_SUCCESS } from 'middlewares/fetch';
import { PassState, ErrorMapping, DefaultDisplayError } from 'constants/myPasses';
import moment from 'moment';

const MY_PASSES = 'MY_PASSES';
const MY_PASSES_REDEEM = 'MY_PASSES_REDEEM';
const MY_PASSES_REMOVE = 'MY_PASSES_REMOVE';
const MY_PASSES_READY = 'MY_PASSES_READY';
const MY_PASSES_BY_SERVICE = 'MY_PASSES_BY_SERVICE';

const myPassesReady = createAction(MY_PASSES_READY);

const getMyPasses = createAction(FETCH, externalId => ({
    prefix: MY_PASSES,
    endpoint: `${CONFIG.API_URL}/comps?owner=${externalId}`
}));

const redeemPass = createAction(FETCH, code => ({
    prefix: MY_PASSES_REDEEM,
    endpoint: `${CONFIG.API_URL}/codes/${encodeURIComponent(code)}`,
    options: {
        method: 'POST'
    }
}));

const removePass = createAction(FETCH, compId => ({
    prefix: MY_PASSES_REMOVE,
    endpoint: `${CONFIG.API_URL}/comps/${compId}`,
    options: {
        method: 'DELETE'
    }
}));

const getPassesByService = createAction(FETCH, (externalId, service) => ({
    prefix: MY_PASSES_BY_SERVICE,
    endpoint: `${CONFIG.API_URL}/comps?owner=${externalId}&service=${service}`
}));

const fetchMyPasses = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        return dispatch(getMyPasses(externalId));
    };
};

const normalizeMyPasses = (passes) =>
    passes.filter(pass => pass.state === PassState.New)
        .map(pass => ({ ...pass, expires: pass.expires ? pass.expires : moment(pass.created).add(365, 'd').format('x') }))
        .sort((a, b) => parseInt(a.expires, 10) - parseInt(b.expires, 10));

export const fetchPasses = () => {
    return (dispatch) => {
        return dispatch(fetchMyPasses())
            .then(passes => {
                // console.warn(passes);
                return dispatch(myPassesReady(normalizeMyPasses(passes)));
            });
    };
};

export const getMyPassesByService = (service) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        return dispatch(getPassesByService(externalId, service));
    };
};

export const redeemAPass = (code) => {
    return (dispatch) => {
        return dispatch(redeemPass(code));
    };
};

export const removeAPass = (passId) => {
    return (dispatch) => {
        return dispatch(removePass(passId));
    };
};

const initialState = {
    isFetching: false,
    error: null,
    passes: []
};

export const normalizePassesError = (error) => {
    if (!error.message) {
        return error;
    }

    const mappedError = ErrorMapping.find((er) => error.message.includes(er.backendError));

    return {
        message: mappedError ? mappedError.displayError : DefaultDisplayError,
        key: mappedError ? mappedError.errorKey : undefined
    };
};

export default handleActions({
    [`${MY_PASSES}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        ...initialState,
        isFetching: true
    }),
    [`${MY_PASSES}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        ...initialState,
        error: payload
    }),
    [`${MY_PASSES_REDEEM}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: normalizePassesError(payload)
    }),
    [myPassesReady]: (state, { payload }) => ({
        ...state,
        ...initialState,
        passes: payload
    }),
    [`${MY_PASSES_BY_SERVICE}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        isFetching: true
    }),
    [`${MY_PASSES_BY_SERVICE}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [`${MY_PASSES_BY_SERVICE}/${FETCH_SUCCESS}`]: (state) => ({
        ...state,
        isFetching: false
    }),
    [FETCH_RESET]: () => initialState
}, initialState);
