import { CONFIG, SESSION_ID_LOCALSTORAGE_KEY } from 'constants/config';
import {
    INTERACTION_LS_KEYS,
    INTERACTION_TYPES
} from 'constants/recommendations';
import log from 'core/log';
import normalizeProduct from 'core/utils/normalizeProduct';
import { FETCH } from 'middlewares/fetch';
import { createAction, handleActions } from 'redux-actions';
import { searchProductsBySkus } from './elasticsearch';

const RECOMMENDATIONS_FETCHING = 'RECOMMENDATIONS_FETCHING';
const RECOMMENDATIONS_RELATED_FETCHING = 'RECOMMENDATIONS_RELATED_FETCHING';
const RECOMMENDATIONS_PRODUCTS = 'RECOMMENDATIONS_PRODUCTS';
const RECOMMENDATIONS_RELATED_PRODUCTS = 'RECOMMENDATIONS_RELATED_PRODUCTS';
const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
const PUSH_INTERACTIONS = 'PUSH_INTERACTIONS';
const SET_RECOMMENDATIONS_ENABLED = 'SET_RECOMMENDATIONS_ENABLED';
const GET_ITEMS_TO_ITEMS_RECOMMENDATIONS = 'GET_ITEMS_TO_ITEMS_RECOMMENDATIONS';

const setFetching = createAction(RECOMMENDATIONS_FETCHING);
const setRelatedFetching = createAction(RECOMMENDATIONS_RELATED_FETCHING);
const setProducts = createAction(RECOMMENDATIONS_PRODUCTS);
const setRelatedProducts = createAction(RECOMMENDATIONS_RELATED_PRODUCTS);

export const setRecommendationsEnabled = createAction(
    SET_RECOMMENDATIONS_ENABLED
);

const getRecommendations = createAction(FETCH, (sessionId, skus) => ({
    prefix: GET_RECOMMENDATIONS,
    endpoint: `${CONFIG.API_URL}/recommendations?sessionId=${sessionId}${
        skus ? `&skus=${skus}` : ''
    }`
}));

const pushUserInteraction = createAction(
    FETCH,
    ({ interactionType, skus }) => ({
        prefix: PUSH_INTERACTIONS,
        endpoint: `${CONFIG.API_URL}/recommendations`,
        options: {
            method: 'POST',
            body: JSON.stringify({
                sessionId: window.localStorage.getItem(
                    SESSION_ID_LOCALSTORAGE_KEY
                ),
                interactionType,
                skus
            })
        }
    })
);

const getItemsToItemsRecommendations = createAction(FETCH, sku => ({
    prefix: GET_ITEMS_TO_ITEMS_RECOMMENDATIONS,
    endpoint: `${CONFIG.API_URL}/recommendations/${sku}`,
    options: {
        method: 'GET'
    }
}));

const initialState = {
    products: [],
    fetching: false,
    enabled: false,
    relatedProducts: [],
    relatedFetching: false
};

export const getUserRecommendation = () => {
    return (dispatch, getState) => {
        if (getState().recommendations.enabled) {
            const { authenticated } = getState().user.auth;

            let sessionSkus = '';

            if (!authenticated) {
                const addedToBag = window.localStorage.getItem(
                    INTERACTION_LS_KEYS[INTERACTION_TYPES.ADD_TO_BAG]
                );
                const liked = window.localStorage.getItem(
                    INTERACTION_LS_KEYS[INTERACTION_TYPES.LIKE]
                );
                const searched = window.localStorage.getItem(
                    INTERACTION_LS_KEYS[INTERACTION_TYPES.SEARCH]
                );

                if (addedToBag) {
                    sessionSkus += addedToBag;
                }
                if (liked) {
                    sessionSkus
                        ? (sessionSkus += `,${liked}`)
                        : (sessionSkus += liked);
                }
                if (searched) {
                    sessionSkus
                        ? (sessionSkus += `,${searched}`)
                        : (sessionSkus += searched);
                }
            }

            dispatch(setFetching(true));
            dispatch(
                getRecommendations(
                    window.localStorage.getItem(SESSION_ID_LOCALSTORAGE_KEY),
                    sessionSkus
                )
            )
                .then(response => {
                    dispatch(searchProductsBySkus(response.items_id)).then(
                        response => {
                            const products = response.docs
                                .filter(
                                    product =>
                                        product.found &&
                                        product._source &&
                                        product._source.title
                                ) // filter empty products
                                .map(product => [normalizeProduct(product)]);
                            dispatch(setProducts(products));
                            dispatch(setFetching(false));
                        }
                    );
                })
                .catch(() => {
                    dispatch(setFetching(false));
                });
        } else {
            return Promise.resolve();
        }
    };
};

export const pushInteraction = ({ interactionType, skus }) => {
    return (dispatch, getState) => {
        if (getState().recommendations.enabled) {
            // store skus to add after login
            if (!getState().user.auth.authenticated) {
                const storedInteractedSkus = window.localStorage.getItem(
                    INTERACTION_LS_KEYS[interactionType]
                );

                try {
                    window.localStorage.setItem(
                        INTERACTION_LS_KEYS[interactionType],
                        storedInteractedSkus
                            ? storedInteractedSkus + ',' + skus.join(',')
                            : skus.join(',')
                    );
                } catch (e) {
                    log.info('Could not store interaction in localstorage');
                }
            }

            return dispatch(pushUserInteraction({ interactionType, skus }));
        }
        return Promise.resolve();
    };
};

export const transferAnonymousInteractionsToUser = () => {
    return (dispatch, getState) => {
        if (
            getState().user.auth.authenticated &&
            getState().recommendations.enabled
        ) {
            try {
                for (const key in INTERACTION_TYPES) {
                    const items = window.localStorage.getItem(
                        INTERACTION_LS_KEYS[INTERACTION_TYPES[key]]
                    );
                    if (items) {
                        dispatch(
                            pushInteraction({
                                interactionType: INTERACTION_TYPES[key],
                                skus: items.split(',')
                            })
                        );
                        window.localStorage.removeItem(
                            INTERACTION_LS_KEYS[INTERACTION_TYPES[key]]
                        );
                    }
                }
            } catch (err) {
                log.info('Could not transfer interactions to user');
            }
        }
    };
};

export const getItemRecommendations = sku => {
    return (dispatch, getState) => {
        if (getState().recommendations.enabled) {
            dispatch(setRelatedProducts([]));
            dispatch(setRelatedFetching(true));
            dispatch(getItemsToItemsRecommendations(sku))
                .then(response => {
                    dispatch(searchProductsBySkus(response.items_id)).then(
                        response => {
                            const products = response.docs
                                .filter(
                                    product =>
                                        product.found &&
                                        product._source &&
                                        product._source.title
                                ) // filter empty products
                                .map(product => [normalizeProduct(product)]);
                            dispatch(setRelatedProducts(products));
                            dispatch(setRelatedFetching(false));
                        }
                    );
                })
                .catch(() => {
                    dispatch(setRelatedFetching(false));
                });
        }
    };
};

export default handleActions(
    {
        [RECOMMENDATIONS_FETCHING]: (state, { payload }) => ({
            ...state,
            fetching: payload
        }),
        [RECOMMENDATIONS_RELATED_FETCHING]: (state, { payload }) => ({
            ...state,
            relatedFetching: payload
        }),
        [RECOMMENDATIONS_PRODUCTS]: (state, { payload }) => ({
            ...state,
            products: payload,
            fetching: false
        }),
        [RECOMMENDATIONS_RELATED_PRODUCTS]: (state, { payload }) => ({
            ...state,
            relatedProducts: payload,
            relatedFetching: false
        }),
        [SET_RECOMMENDATIONS_ENABLED]: (state, { payload }) => ({
            ...state,
            enabled: !!payload
        })
    },
    initialState
);
