/**
 * Sort function for objects with the specified property.
 * @param {string} property
 * @param {boolean} reverse - If true, sorts in the other direction
 * @returns {function} - sort function
 */
const itemSort = (property, reverse = false) => (a, b) => {
    if (a[property] < b[property]) {
        return reverse ? 1 : -1;
    }

    if (a[property] > b[property]) {
        return reverse ? -1 : 1;
    }

    return 0;
};

export default itemSort;
