import styled from 'styled-components';
import { styledProps } from 'core/styled';
import { FORM_MAX_WIDTH } from 'constants/theme';

const MaxWidthWrapper = styled.div`
    max-width: ${({ isForm }) =>
        isForm ? FORM_MAX_WIDTH : styledProps('global', 'maxWidth')};;
    margin: auto;

    ${({ asFlex }) =>
        asFlex
            ? `
        display: flex;
        flex-direction: column;
        align-items: center;
    `
            : null}

    ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : null)}
`;

export default MaxWidthWrapper;
