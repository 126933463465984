/**
 * Given an object with boolean values and an object
 * with the same keys, resolves into the value of the second
 * object that matches the first key which has a truthy value.
 * @example
 * const o1 = {
 *      a: 'a',
 *      b: 'b'
 * };
 * const o2 = {
 *      a: false,
 *      b: true
 * };
 * resolveBooleanKey(o1, o2); // 'b'
 * @param {object} obj
 * @param {object} booleanObj
 * @return {*} resolved value
 */
export default function resolveBooleanKey(obj, booleanObj) {
    return Object.keys(obj).reduce(
        (resolvedValue, key) =>
            resolvedValue || (booleanObj[key] ? obj[key] : ''),
        ''
    );
}
