import { styledProps, rem } from 'core/styled';
import styled from 'styled-components';

const PriceTag = styled.span`
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(10)};
    color: ${styledProps('color', 'black')};
    font-family: ${styledProps('font', 'ABChanelPBM')};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};
    display: block;
`;

export default PriceTag;
