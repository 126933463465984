/**
 * Default method to handle error
 *
 * @param message An error message to report
 * @param data An object representing context data for the error
 *
 */
/* eslint-disable */
export default function notifyNewrelic (message, data) {
    if (newrelic) {
        // If newrelic is enabled, report error
        newrelic.noticeError(message, data);
    }
};
/* eslint-enable */
