import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { styledProps } from 'core/styled';
import Clickable from 'components/GlobalComponents/Clickable/Clickable';

const color = ({ isHovered, cssColor, hover, ...props }) =>
    styledProps('color', isHovered ? hover : cssColor)(props);

const StyledLink = styled.span`
    a {
        font-family: ${styledProps('font', 'ABChanelPBM')};
        text-transform: uppercase;
        color: ${color};
        text-decoration: underline;
        cursor: pointer;
        transition: 0.2s color;
    }
`;

class InternalLink extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        to: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
        keepSearch: PropTypes.bool,
        cssColor: PropTypes.string
    };

    static defaultProps = {
        cssColor: 'black'
    };

    render() {
        const { to, keepSearch, children, search } = this.props;
        const toObject = {
            pathname: to,
            search: keepSearch ? search : ''
        };

        return (
            <Clickable {...this.props}>
                {({ loading, ...clickableProps }) => (
                    <StyledLink {...clickableProps}>
                        <Link to={toObject}>{children}</Link>
                    </StyledLink>
                )}
            </Clickable>
        );
    }
}

const mapStateToProps = ({ router: { location } }) => location;

export default connect(mapStateToProps)(InternalLink);
