import moment from 'moment-timezone';
import { getBookingLocation, getQuestionnaireTypeForSessionType } from 'constants/booking';
import log from 'core/log';
import { getSessionType } from 'reducers/booking';
import { store } from 'store';
import isNumeric from './isNumeric';
import { LOCATIONS, LOCATION_TEXT, LOCATION_TIMEZONE } from 'constants/locations';

export const sortByStartDateDescending = (a, b) => moment(a.startDateTime).diff(moment(b.startDateTime));

const isComplimentaryAppointment = sessionTypeId => {
    const { booking: { sessions, idToSessionMap } } = store.getState();

    let isComplimentary = false;
    const sessionType = idToSessionMap[sessionTypeId];

    if (sessionType) {
        isComplimentary = sessions[sessionType].All.nycCompId === sessionTypeId || sessions[sessionType].All.austinCompId === sessionTypeId;
    }

    return isComplimentary;
};

const isPast = (startDateTime, endDateTime, sessionTypeId) => {
    const { booking: { sessions, idToSessionMap } } = store.getState();

    let endTime = endDateTime;

    const sessionName = idToSessionMap[sessionTypeId];

    if (sessionName) {
        const sessionLength = sessions[sessionName].All.length;

        if (sessionLength && isNumeric(sessionLength)) {
            endTime = startDateTime.clone().add(parseInt(sessionLength, 10), 'minute');
        }
    }

    return moment().isSameOrAfter(endTime, 'minute');
};

const normalizeGuestAppointment = (appointment, makeupArtists) => {
    const sessionType = getSessionType(parseInt(appointment.appointmentSessionTypeID, 10))(null, store.getState);
    const startDateTime = moment.tz(appointment.appointmentStartDateTime, 'America/New_York');
    const sessionEndDateTime = moment(startDateTime).add(sessionType.length, 'm');
    const makeupArtist = makeupArtists.find(m => m.name === appointment.appointmentStaffName);
    const locationText = getBookingLocation(appointment.appointmentSessionTypeID);


    return {
        id: appointment.appointmentID,
        typeId: appointment.appointmentSessionTypeID,
        name: appointment.appointmentSessionTypeName,
        sessionType,
        weekDay: startDateTime.format('dddd'),
        month: startDateTime.format('MMMM'),
        day: startDateTime.format('D'),
        startTime: startDateTime.format('LT'),
        endTime: sessionEndDateTime.format('LT'),
        artistName: appointment.appointmentStaffName,
        startDateTime,
        endDateTime: sessionEndDateTime,
        artistId: makeupArtist ? makeupArtist.id : null,
        artistImgUrl: makeupArtist ? makeupArtist.image : null,
        artistImgWideUrl: makeupArtist ? makeupArtist.imageWide : null,
        artistImgSuperWideUrl: makeupArtist ? makeupArtist.imageSuperWide : null,
        artistStaffId: makeupArtist ? makeupArtist.staffId : null,
        artistType: makeupArtist && makeupArtist.artistType ? makeupArtist.artistType : null,
        questionnaire: getQuestionnaireTypeForSessionType(appointment.appointmentSessionTypeID),
        isPast: isPast(startDateTime, sessionEndDateTime, appointment.appointmentSessionTypeID),
        hasStarted: moment().isSameOrAfter(startDateTime, 'minute'),
        booking: {
            startTime: startDateTime,
            locationId: Location.ID,
            staffId: makeupArtist ? makeupArtist.staffId : null,
            sessionType: appointment.appointmentSessionTypeID,
            id: appointment.appointmentID
        },
        isNoShow: appointment.status === 'NoShow',
        bookerId: appointment.bookerId || appointment.appointmentClientID,
        bookedBy: appointment.bookedBy,
        locationText
    };
};

// guest appointment has lowercase appointmentID
const isGuestAppointment = appointment => (!!appointment.appointmentID);

const normalizeAppointment = (makeupArtists) => (appointment) => {
    if (isGuestAppointment(appointment)) {
        return normalizeGuestAppointment(appointment, makeupArtists);
    }

    const startDateTime = moment.tz(appointment.StartDateTime, 'America/New_York');
    const endDateTime = moment.tz(appointment.EndDateTime, 'America/New_York');
    const { Staff, Location, Client } = appointment;
    const makeupArtist = makeupArtists.find(m => m.staffid.toString() === Staff.ID);
    const sessionType = getSessionType(parseInt(appointment.SessionTypeID, 10))(null, store.getState);

    const locationText = getBookingLocation(appointment.SessionTypeID);
    const isAustin = locationText === LOCATION_TEXT[LOCATIONS.AUSTIN];

    const timezonedStartDateTime = moment(startDateTime).tz(isAustin ? LOCATION_TIMEZONE[LOCATIONS.AUSTIN] : LOCATION_TIMEZONE[LOCATIONS.NYC]);

    // Do not use the endTime from Mindbody because it might contain padding. Use the session length instead
    const sessionEndDateTime = moment(timezonedStartDateTime).add(sessionType.length, 'm');

    //todo: we probably need to 'gracefully' support this issue
    if (!makeupArtist) {
        log.error(`Could not find makeup artist with ID ${Staff.ID}`);
    }

    return {
        id: appointment.AppointmentID,
        typeId: appointment.SessionTypeID,
        name: appointment.SessionType.Name,
        sessionType,
        weekDay: timezonedStartDateTime.format('dddd'),
        month: timezonedStartDateTime.format('MMMM'),
        day: timezonedStartDateTime.format('D'),
        startTime: timezonedStartDateTime.format('LT'),
        endTime: sessionEndDateTime.format('LT'),
        artistName: Staff.Name,
        startDateTime,
        timezonedStartDateTime,
        endDateTime,
        artistId: makeupArtist ? makeupArtist.id : null,
        artistImgUrl: makeupArtist ? makeupArtist.image : Staff.ImageURL,
        artistImgWideUrl: makeupArtist ? makeupArtist.imageWide : Staff.ImageURL,
        artistImgSuperWideUrl: makeupArtist ? makeupArtist.imageSuperWide : Staff.ImageURL,
        artistStaffId: Staff.ID,
        artistType: makeupArtist && makeupArtist.artistType ? makeupArtist.artistType : null,
        questionnaireId: appointment.questionnaireId,
        needsQuestionnaire: appointment.needsQuestionnaire,
        questionnaire: getQuestionnaireTypeForSessionType(appointment.SessionTypeID),
        isPast: isPast(startDateTime, endDateTime, appointment.SessionTypeID),
        hasStarted: moment().isSameOrAfter(startDateTime, 'minute'),
        isRated: !!appointment.rating,
        booking: {
            startTime: startDateTime,
            locationId: Location.ID,
            staffId: Staff.ID,
            sessionType: appointment.SessionTypeID,
            id: appointment.AppointmentID
        },
        isNoShow: appointment.Status === 'NoShow',
        workplan: appointment.workplan || undefined,
        isComplimentary: isComplimentaryAppointment(appointment.SessionTypeID),
        locationText,
        forGuest: appointment.forGuest,
        guestCount: appointment.guestCount,
        guestToken: appointment.token,
        bookerId: Client.ID
    };
};

export const normalizeStaffAppointment = (makeupArtists, location) => (appointment) => {
    const { Client, Staff, StartDateTime, EndDateTime, SessionType, questionnaireId, needsQuestionnaire, SessionTypeID, AppointmentID } = appointment;
    const startDateTime = moment.tz(StartDateTime, 'America/New_York');
    const endDateTime = moment.tz(EndDateTime, 'America/New_York');

    const makeupArtist = makeupArtists.find(m => m.staffid.toString() === Staff.ID);
    //todo: we probably need to 'gracefully' support this issue
    if (!makeupArtist) {
        log.error(`Could not find makeup artist with ID ${Staff.ID}`);
    }
    const sessionType = getSessionType(parseInt(SessionType.ID, 10))(null, store.getState);

    const timezonedStartDateTime = moment.tz(startDateTime, LOCATION_TIMEZONE[location]);

    return {
        id: AppointmentID,
        clientFullName: `${Client.FirstName} ${Client.LastName}`,
        clientId: Client.ID,
        staffName: Staff.Name,
        staffId: Staff.ID,
        artistId: makeupArtist ? makeupArtist.id : null,
        artistImgUrl: makeupArtist ? makeupArtist.image : Staff.ImageURL,
        time: `${timezonedStartDateTime.format('h:mm')}-${moment(timezonedStartDateTime).add(sessionType.length, 'm').format('h:mm a')} ${location === LOCATIONS.AUSTIN ? 'CT' : 'ET'}`,
        sessionTypeId: SessionTypeID,
        sessionName: SessionType.Name,
        startDateTime: startDateTime,
        isPast: isPast(startDateTime, endDateTime, SessionType.ID),
        questionnaireId: questionnaireId,
        needsQuestionnaire: needsQuestionnaire,
        questionnaire: getQuestionnaireTypeForSessionType(SessionType.ID)
    };
};

/**
 * Number of minutes between time slots. It used to be set to the session type length, now it's 15 minutes
 * @type {number}
 */
const TIME_SLOT_INTERVAL = 15;
// const THIRTY_MINUTES_INTERVAL = 30;
const FORTY_FIVE_MINUTES_INTERVAL = 45;
const SIXTY_MINUTES_INTERVAL = 60;

/**
 * Given an artist booking availability, computes the available time slots
 * @param {object} booking
 * @return {Array} time slots
 */
export const normalizeBookingAvailability = (booking) => {
    const { Staff, SessionType, Location } = booking;
    const { booking: { idToSessionMap } } = store.getState();

    const availability = {
        staffId: Staff.ID,
        staffName: Staff.Name,
        sessionType: SessionType.ID,
        sessionLength: SessionType.DefaultTimeLength,
        sessionName: SessionType.Name,
        locationId: Location.ID
    };

    let slotInterval, timeSlotIncrement;

    switch (idToSessionMap[SessionType.ID]) {
        case 'Residency':
            slotInterval = FORTY_FIVE_MINUTES_INTERVAL;
            timeSlotIncrement = TIME_SLOT_INTERVAL;
            break;
        case 'SkincareChat':
            slotInterval = TIME_SLOT_INTERVAL;
            timeSlotIncrement = TIME_SLOT_INTERVAL;
            break;
        case 'VirtualBrowService':
            slotInterval = SIXTY_MINUTES_INTERVAL;
            timeSlotIncrement = SIXTY_MINUTES_INTERVAL;
            break;
        default:
            slotInterval = TIME_SLOT_INTERVAL;
            timeSlotIncrement = TIME_SLOT_INTERVAL;
    }

    // add a session to startTime...
    let startTime = moment.tz(booking.StartDateTime, 'America/New_York');

    // startTime should start at :00, :15, :30 or :45
    // not starting on these minutes occurs on a Mindbody 700 error
    const timeModulus = startTime.minute() % timeSlotIncrement;
    if (timeModulus > 0) {
        const remainder = timeSlotIncrement - timeModulus;
        startTime = moment(startTime).add(remainder, 'minutes');
    }

    const endTime = moment.tz(booking.BookableEndDateTime, 'America/New_York');
    const timeSlots = [];

    // ... check if before or same as endTime
    while (startTime.isSameOrBefore(endTime, 'minute')) {

        // if so, add time slot
        const timeSlot = {
            startTime: moment(startTime).toDate(),
            endTime: moment(startTime).add(availability.sessionLength, 'minute').toDate(),
            ...availability
        };
        timeSlots.push(timeSlot);

        // exit case
        startTime = moment(startTime).add(slotInterval, 'minute'); // increment time slots
    }

    return timeSlots;
};

export const filterOutPastTimeslots = (availability) => {
    const { booking: { idToSessionMap } } = store.getState();

    const now = new Date();

    const currentSessionType = idToSessionMap[availability.sessionType];

    if (['FragranceDiscoveryVirtual', 'FragranceImmersion', 'AtHomeFacial'].includes(currentSessionType)) {
        now.setDate(now.getDate() + 7);
    } else if (['ExperienceIconicLook', 'ExperienceHappyHour', 'ExperienceIconicLookDeluxe', 'ExperienceDayNightBeauty', 'ExperienceDayNightBeautyDeluxe'].includes(currentSessionType)) {
        // allow booking only 24 hours in advance
        now.setDate(now.getDate() + 1);
    } else {
        now.setMinutes(now.getMinutes() + 9);
    }

    return availability.startTime.getTime() > now.getTime();
};

//export const filterOutPastTimeslots = (availability) => moment.tz(availability.startTime, 'America/New_York').isAfter(moment(), 'minute');

/**
 * Normalize a booking confirmation
 * @param {array} makeupArtists
 * @param {object} booking
 * @return {object} normalized appointment
 */
export const normalizeConfirmedBooking = (makeupArtists, booking) => {

    const { Staff, SessionType, Location } = booking;
    const makeupArtist = makeupArtists.find(m => m.staffid.toString() === Staff.ID);

    return {
        artistId: makeupArtist ? makeupArtist.id : null,
        artistName: Staff.Name,
        staffId: Staff.ID,
        sessionType: SessionType.ID,
        locationId: Location.ID,
        questionnaire: getQuestionnaireTypeForSessionType(SessionType.ID),
        startTime: moment(booking.StartDateTime),
        endTime: moment(booking.EndDateTime),
        id: booking.ID
    };

};

export default normalizeAppointment;
