import { css } from 'styled-components';
import { theme } from 'constants/theme';

/**
 * Gets the breakpoint size from the current theme
 * @param {string} breakpoint
 * @return {function({theme: object}): string} breakpoint size
 */
export const getBreakpointSize = breakpoint => ({ theme }) => {
    if (!theme.breakpoints) {
        return '';
    }

    return theme.breakpoints[breakpoint] || breakpoint;
};

export const getBreakpointSizeAS = breakpoint => ({ theme }) => {
    if (!theme.breakpoints) {
        return '';
    }

    return theme.breakpoints[breakpoint] || breakpoint;
};

/**
 * Styles that will apply for viewports which width are less
 * than the specified breakpoint size
 * @param {string} breakpoint
 * @return {function(styles): styles} styles
 */
export const lessThan = breakpoint => styles => css`
    @media (max-width: ${getBreakpointSize(breakpoint)}) {
        ${styles};
    }
`;

/**
 * Styles that will apply for viewports which width are greater
 * than the specified breakpoint size
 * @param {string} breakpoint
 * @return {function(styles): styles} styles
 */
export const greaterThan = breakpoint => styles => css`
    @media (min-width: ${getBreakpointSize(breakpoint)}) {
        ${styles};
    }
`;

/**
 * Preform check if window width is greater than breakpoint passed for in script usage
 * @param {string} brakePointInPX - break point to much against
 * @return {boolean} - is window inner width is greater that break point passed
 */
export const isGreaterThan = brakePointInPX => window.innerWidth >= parseInt(getBreakpointSizeAS(brakePointInPX)({ theme }), 10);

/**
 * Styles that will apply for viewports which width are between
 * the specified from and to breakpoints
 * @param {string} fromBreakpoint
 * @param {string} toBreakpoint
 * @return {function(styles): styles} styles
 */
export const between = (fromBreakpoint, toBreakpoint) => styles => css`
    // prettier-ignore
    @media (min-width: ${getBreakpointSize(fromBreakpoint)})
    and (max-width: ${getBreakpointSize(toBreakpoint)}) {
        ${styles};
    }
`;

/**
 * Styles that will apply for viewports which width are less
 * than the specified height
 * @param {string} pixelHeight
 * @return {function(styles): styles} styles
 */
export const heightLessThan = pixelHeight => styles => css`
    @media (max-height: ${pixelHeight}) {
        ${styles};
    }
`;

/**
 * Styles that will apply for viewports which width are greater
 * than the specified height
 * @param {string} pixelHeight
 * @return {function(styles): styles} styles
 */
export const heightGreaterThan = pixelHeight => styles => css`
    @media (min-height: ${pixelHeight}) {
        ${styles};
    }
`;

/**
 * Styles that will apply for viewports which width are between
 * the specified from and to heights
 * @param {string} fromPixelHeight
 * @param {string} toPixelHeight
 * @return {function(styles): styles} styles
 */
export const heightBetween = (fromPixelHeight, toPixelHeight) => styles => css`
    // prettier-ignore
    @media (min-height: ${fromPixelHeight})
    and (max-height: ${toPixelHeight}) {
        ${styles};
    }
`;

/**
 * Hack to apply styles only for ie browser
 * @return {function(styles): styles} styles
 */

export const internetExplorerOnlyStyles = styles => css`
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
        /* IE10+ specific styles go here */
        ${styles}    
    }
`;
