import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import PrimaryModal from 'components/GlobalComponents/Modal/PrimaryModal';
import { styledProps, rem, greaterThan } from 'core/styled';
import LazyLoadImage from 'components/GlobalComponents/LazyLoadImage/LazyLoadImage.js';
import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import { compareValues } from 'core/utils';
import { analyticsTag } from 'reducers/analytics';
import Header from 'components/GlobalComponents/Fonts/Header';
import { breakpoints } from 'constants/theme';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import { EVENTS } from 'constants/analytics';
import { BADGE, BADGE_IMAGE } from 'constants/badges';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import scrollToTop from 'core/utils/scrollToTop';

// transition delay is to allow the modal to fade in first, then have the swatches fade in
// this makes the animation smoother
const Image = styled(LazyLoadImage)`
    width: ${({ isQuickModal }) => (isQuickModal ? rem(120) : '100%')};
    height: auto;
    transition-delay: 0.2s;
    border: ${({ isSelected }) => (isSelected ? `2px solid black` : 'none')};
    display: block;
`;

const VariantSelectorWrapper = styled(Flex)`
    ${greaterThan(breakpoints.small)(css`
        max-width: 440px;
        margin: 0 auto;
    `)}

    ${greaterThan(breakpoints.medium)(css`
        flex-wrap: wrap;
        justify-content: start;
    `)}
`;

const QuickShopSelectorWrapper = styled(Flex)`
    margin: 0 auto;
`;

const ShadeWrapper = styled(Box)`
    width: calc(100% / 3);
    margin: ${({ isPreview }) =>
        isPreview ? `0 ${rem(7)}` : rem(0, 0, 25, 0)};
    text-align: center;
    position: relative;
`;

const ShadeName = styled(ChromosomeTitle)`
    padding: ${rem(5)} ${rem(10)};
    white-space: pre-wrap;
`;

const QuickShopModalShadeName = styled.p`
    white-space: pre-wrap;
    font-family: ${styledProps('font', 'default')};
    font-size: ${rem(12)};
    text-align: center;
    margin-top: ${rem(20)};
    ${({ isSelected }) => (isSelected ? 'font-weight: bold;' : null)}
`;

const ShadeAvailableNoStyleButton = styled(NoStyleButton)`
    width: 100%;
    margin: 0;
    padding: 0;
    display: initial;
    cursor: pointer;
    font-family: ${styledProps('font', 'Chromosome')};
    text-decoration: underline;
    font-size: ${rem(12)};
    text-align: left;

    ${({ isQuickShop }) => (isQuickShop ? css`
        text-align: center;
        font-family: ${styledProps('font', 'default')};
        padding-top: ${rem(12)};
        font-size: ${rem(10)};
        text-transform: uppercase;
    ` : null)}
`;

const StyledFlex = styled(Flex)`
    min-height: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-top: ${rem(45)};

    ${greaterThan(breakpoints.medium)(css`
        overflow-y: hidden;
    `)}
`;

const MoreShadesPDP = styled(Flex)`
    ${greaterThan(breakpoints.medium)(css`
        justify-content: flex-start;
        margin: ${rem(10)} 0;
    `)}
`;

const ShadeAvailablePopup = styled.p`
    font-family: ${styledProps('font', 'ABChanelPBL')};
    text-align: center;
    font-size: ${rem(14)};
    font-weight: 400;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
`;

const QuickShopAvailablePopup = styled.p`
    font-family: ${styledProps('font', 'default')};
    text-align: center;
    font-size: ${rem(14)};
    width: 100%;
    margin: 0;
    text-transform: capitalize;
`;

const PickYourVariantText = styled.p`
    font-family: ${styledProps('font', 'SofiaPro')};
    text-align: center;
    font-size: ${rem(14)};
    font-weight: 400;
    width: 100%;
    margin: ${rem(20)} 0;
`;

const ShadesWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const ProductBadge = styled.img`
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 50%;
    max-width: 80px;

    ${greaterThan(breakpoints.medium)(css`
        max-width: 100px;
    `)}
`;

const ShadeNoStyleButton = styled(NoStyleButton)`
    cursor: pointer;
`;

const MoreShadesNoStyleButton = styled(NoStyleButton)`
    font-family: ${({ isQuickShop }) =>
        styledProps('font', isQuickShop ? 'default' : 'Chromosome')};
    text-decoration: underline;
    font-size: ${rem(12)};
    cursor: pointer;
    ${({ isQuickShop }) => (isQuickShop ? 'text-transform: uppercase;' : null)}
`;

const SwatchNoStyleButton = styled(NoStyleButton)`
    cursor: pointer;
    border: ${({ isSelected }) => (isSelected ? `2px solid black` : 'none')};
`;

const ModalHeader = styled(Header)`
    font-size: ${rem(50)};
    margin-bottom: ${rem(10)};

    ${({ isQuickShop }) =>
        isQuickShop
            ? css`
                  font-size: ${rem(18)};
                  line-height: ${rem(35)};
                  font-family: ${styledProps('font', 'ABChanelPBM')};
                  margin: 0;
              `
            : null}
`;

class ProductVariantSelector extends Component {
    static propTypes = {
        products: PropTypes.array.isRequired,
        selectSku: PropTypes.func.isRequired,
        selectedSku: PropTypes.string,
        renderSwatches: PropTypes.bool,
        analyticsTag: PropTypes.func,
        isQuickShop: PropTypes.bool
    };

    state = {
        showModal: false,
        sortedProducts: []
    };

    componentDidMount() {
        const { products } = this.props;

        // sort shades based on size or shade name
        let sortedProducts = [
            ...products
                .sort(compareValues(this.getSortField()))
                .filter(s => !s.emblematic)
        ];

        // make the emblematic sku the first element
        const emblematicSku = products.find(product => product.emblematic);

        if (emblematicSku) {
            sortedProducts = [emblematicSku, ...sortedProducts];
        }

        this.setState({
            sortedProducts
        });
    }

    getSortField = () => this.props.products[0].sortField;

    getVariantText = () => this.props.products[0].pickVariantText;

    /**
     * Handle selecting a variant outside the modal.
     * @param {string} sku
     */
    swatchOnClick = sku => {
        const { selectSku, isQuickShop } = this.props;

        selectSku(sku);

        isQuickShop
            ? scrollToTop()
            : document.getElementById('atelier-pdp--heading').focus();

        this.props.analyticsTag(
            {
                event: EVENTS.GA,
                eventCategory: 'product',
                eventAction: 'shadeselected',
                eventLabel: sku
            },
            { userInfo: true }
        );
    };

    /**
     * Renders the variants as swatches
     * @param {array} variants - variants to be rendered
     * @param {boolean} isPreview - remove bottom margin for PDP preview block
     * @returns {any[]} component
     * @private
     */
    _renderSwatches = (variants, isPreview) => {
        const { selectedSku } = this.props;

        return variants.map((variant, key) => {
            const isSelected = variant.sku === selectedSku;

            return (
                <ShadeWrapper key={key} isPreview={isPreview}>
                    <SwatchNoStyleButton
                        onClick={() => this.swatchOnClick(variant.sku)}
                        type={'button'}
                        aria-current={isSelected}
                        isSelected={isSelected}
                    >
                        <Image
                            src={variant.thumbnail.small()}
                            alt={variant.variantInfo}
                        />
                    </SwatchNoStyleButton>
                </ShadeWrapper>
            );
        });
    };

    /**
     * Render 3 shades as swatches at time, starting from currently selected swatch
     * @returns {any[]} array of swatches
     */
    getVariantSwatches() {
        const { selectedSku } = this.props;
        const { sortedProducts } = this.state;
        const selectedShadeIndex = sortedProducts.findIndex(
            product => product.sku === selectedSku
        );
        const numVariants = sortedProducts.length;

        // if 3 or less variants
        if (numVariants <= 3) {
            return this._renderSwatches(sortedProducts, true);
        } else {
            return this._renderSwatches(
                [
                    sortedProducts[selectedShadeIndex],
                    sortedProducts[(selectedShadeIndex + 1) % numVariants],
                    sortedProducts[(selectedShadeIndex + 2) % numVariants]
                ],
                true
            );
        }
    }

    /**
     * Render shades inside modal
     * @returns {any[]} component
     */
    getVariantModalSwatches() {
        const { selectedSku, isQuickShop } = this.props;
        const { sortedProducts } = this.state;

        return sortedProducts.map((product, key) => {
            const isSelected = product.sku === selectedSku;
            return (
                <ShadeWrapper
                    key={key}
                    flex="0 1 50%"
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <ShadeNoStyleButton
                        type="button"
                        onClick={() => this.selectShadeCloseModal(product.sku)}
                    >
                        <Image
                            isQuickModal={isQuickShop}
                            isSelected={isQuickShop && isSelected}
                            src={product.thumbnail.medium()}
                            alt=""
                        />
                        {!isQuickShop && product.badge === BADGE.NEW && (
                            <ProductBadge
                                src={BADGE_IMAGE[BADGE.NEW_SHADE]}
                                alt={''}
                            />
                        )}
                        {!isQuickShop && isSelected && (
                            <TiltedText
                                rotate={3}
                                fontSize={12}
                                fontWeight={900}
                                textPadding={[0, 8]}
                                lineHeight={1}
                                display={'inline-block'}
                            >
                                <span className="sr-only">Choose </span>
                                {product.variantInfo}
                            </TiltedText>
                        )}
                        {!isQuickShop && !isSelected && (
                            <ShadeName>
                                <span className="sr-only">Choose </span>
                                {product.variantInfo}
                            </ShadeName>
                        )}
                        {isQuickShop && (
                            <QuickShopModalShadeName isSelected={isSelected}>
                                <span className="sr-only">Choose </span>
                                {product.variantInfo}
                            </QuickShopModalShadeName>
                        )}
                    </ShadeNoStyleButton>
                </ShadeWrapper>
            );
        });
    }

    /**
     * Open shade modal
     */
    showModal = () => {
        this.props.analyticsTag(
            {
                event: EVENTS.GA,
                eventCategory: 'product',
                eventAction: 'shadeselector',
                eventLabel: 'opened'
            },
            { userInfo: true }
        );

        this.setState({
            showModal: true
        });
    };

    /**
     * Close shade modal
     */
    closeModal = () => {
        this.setState({
            showModal: false
        });
    };

    /**
     * Select current shade
     * @param {string} sku
     */
    selectShadeCloseModal(sku) {
        const { analyticsTag, selectSku, isQuickShop } = this.props;

        analyticsTag(
            {
                event: EVENTS.GA,
                eventCategory: 'product',
                eventAction: 'shadeselected',
                eventLabel: sku
            },
            { userInfo: true }
        );

        selectSku(sku);
        this.closeModal();
        isQuickShop && scrollToTop();
    }

    /**
     * Trigger text depending on if there is a swatch preview or not (PDP vs PLP/Search page)
     * @returns {string} text
     */
    shadeModalTriggerText() {
        const variantText = this.getVariantText();
        const { renderSwatches, products, isQuickShop } = this.props;

        if (renderSwatches) {
            return (
                <MoreShadesPDP alignItems={'center'} justifyContent={'center'}>
                    <MoreShadesNoStyleButton
                        type="button"
                        onClick={this.showModal}
                        isQuickShop={isQuickShop}
                    >
                        More {variantText}
                    </MoreShadesNoStyleButton>
                </MoreShadesPDP>
            );
        }

        const buttonText = isQuickShop ?
            `${products.length} ${variantText}` :
            `${products.length} ${variantText} Available`;

        return (
            <div>
                <ShadeAvailableNoStyleButton
                    type="button"
                    onClick={this.showModal}
                    isQuickShop={isQuickShop}
                >
                    {buttonText}
                    <span className="sr-only">Choose one</span>
                </ShadeAvailableNoStyleButton>
            </div>
        );
    }

    render() {
        const { renderSwatches, products, isQuickShop } = this.props;
        const { showModal } = this.state;
        const productName = products.length ? products[0].name : '';
        const variantCopy = this.getVariantText();

        const Wrapper = isQuickShop
            ? QuickShopSelectorWrapper
            : VariantSelectorWrapper;

        return (
            <Wrapper
                flexDirection={'row'}
                justifyContent="center"
                alignItems="center"
                fullHeight={false}
            >
                {renderSwatches && (
                    <ShadesWrapper>{this.getVariantSwatches()}</ShadesWrapper>
                )}
                {this.shadeModalTriggerText()}
                {showModal && (
                    <PrimaryModal
                        fullScreen={true}
                        showClose={true}
                        cutEdge={false}
                        padding={[10, 20, 0, 20]}
                        closePadding={[30, 0, 0, 0]}
                        onRequestClose={() => this.closeModal()}
                        preventScrollToTopOnOpen={true}
                        ariaDescribedby={'modal-shades-title'}
                    >
                        <StyledFlex flexDirection={'row'} flexWrap={'wrap'}>
                            <ModalHeader
                                id={'modal-shades-title'}
                                isQuickShop={isQuickShop}
                            >
                                {productName}
                            </ModalHeader>
                            {isQuickShop ? (
                                <QuickShopAvailablePopup>
                                    {products.length} {variantCopy} Available
                                </QuickShopAvailablePopup>
                            ) : (
                                <ShadeAvailablePopup>
                                    {variantCopy} available ({products.length})
                                </ShadeAvailablePopup>
                            )}
                            {!isQuickShop && (
                                <PickYourVariantText>
                                    Pick your{' '}
                                    {variantCopy.substr(
                                        0,
                                        variantCopy.length - 1
                                    )}
                                    !
                                </PickYourVariantText>
                            )}
                            {showModal && this.getVariantModalSwatches()}
                        </StyledFlex>
                    </PrimaryModal>
                )}
            </Wrapper>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ analyticsTag }, dispatch);

export default connect(null, mapDispatchToProps)(ProductVariantSelector);
