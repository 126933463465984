import { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'core/utils/noop';
import { emptyArray, mapFieldErrors } from 'core/utils';

export default class FormWithValidation extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        error: PropTypes.object,
        onSubmit: PropTypes.func,
        onChange: PropTypes.func
    };

    static defaultProps = {
        onSubmit: noop,
        onChange: noop
    };

    state = {
        fieldErrors: {}
    };

    handlers = {
        onSubmit: event => {
            event.preventDefault();
            this.props.onSubmit(event);
        },
        onChange: event => {
            this.setState({ fieldErrors: {} });
            this.props.onChange(event);
        }
    };

    // TODO this seems to return reversed value ?
    /**
     * Validate form fields
     * @param {object} form
     * @return {boolean} is form invalid
     */
    validateForm = form => {
        // Create an empty array of the form fields length
        return (
            emptyArray(form.length)
                // Map the array with the boolean value of each validation message
                .map((_, index) => Boolean(form[index].validationMessage))
                // Reduce the fields validity to determine the form validity
                .reduce(
                    (inputInvalid, formInvalid) => formInvalid || inputInvalid,
                    false
                )
        );
    };

    static getDerivedStateFromProps = ({ error }) => {
        // Map error details into a dictionary
        if (error && error.details instanceof Array) {
            const fieldErrors = mapFieldErrors(error);

            return { fieldErrors };
        }
        return null;
    };

    render() {
        return this.props.children({
            ...this.props,
            ...this.state,
            ...this.handlers,
            validateForm: this.validateForm
        });
    }
}
