import moment from 'moment-timezone';
import { computeEventTax } from 'constants/events';
import { store } from 'store';

export const EVENT = 'Event';
export const APPOINTMENT = 'Appointment';
export const NONE = 'None';

export const getServiceName = service => {
    if (service.dropdownType === EVENT) {
        return service.bookableInfo.name;
    }

    return service.name;
};

export const getServiceDateTime = service => {
    const info = service.bookableInfo;

    if (service.dropdownType === EVENT) {
        return `${info.weekDay}, ${info.month} ${info.day} ${info.startTime} ET`;
    } else if (service.dropdownType === APPOINTMENT) {
        return moment(info.startTime).tz('America/New_York').format('dddd, MMMM D LT') + ' ET';
    }
};

export const getPricing = service => {
    const { price } = service;

    if (price === 0) {
        return {
            total: 0,
            totalDisplay: 'FREE'
        };
    } else {
        const subtotal = price;
        const tax = computeEventTax(subtotal);
        const total = parseFloat(subtotal, 10) + parseFloat(tax);

        return {
            subtotal,
            tax,
            total,
            totalDisplay: total.toFixed(2)
        };
    }
};

export const getKeyFromSessionTypeID = (sessionTypeID, serviceType) => {
    const { definitions } = store.getState().services;

    if (!definitions) {
        return null;
    }

    return Object.keys(definitions).find(
        service => definitions[service].type === serviceType &&
            definitions[service].session_types &&
            definitions[service].session_types.length &&
            definitions[service].session_types.includes(parseInt(sessionTypeID, 10))
    );
};

export const getGiftErrorMessage = response => {
    if (!response || !response.msg) {
        return 'We are currently experiencing some technical issues. We apologize for the inconvenience. Please try again later.';
    }

    switch (response.msg) {
        case 'nomatch':
            return 'Gift code is not valid for this service.';
        case 'expired':
            return 'Gift code has already expired.';
        case 'used':
            return 'Gift code has already been redeemed.';
        case 'invalid':
        case 'not found':
        default:
            return 'Enter a valid gift code.';

    }
};
