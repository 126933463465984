import styled from 'styled-components';
import { styledProps, rem } from 'core/styled';

const SpecialTag = styled.p`
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(12)};
    font-family: ${styledProps('font', 'ABChanelPBL')};
    color: ${styledProps('color', 'silverChalice')};
    border: 1px solid ${styledProps('color', 'silverChalice')};  
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    display: inline-block;
    padding: ${rem(5)} ${rem(10)};
    margin: 0 auto;
`;

export default SpecialTag;
