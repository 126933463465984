/**
 * Takes an object payload and structures it how the List API expects.
 *
 * The payload should be structured in the following way:
 *
 * {
 *  sku: <skuId>,
 *  options: {<options>},
 *  ... <other keys>
 *
 * }
 *
 * @param {string} action
 * @param {object} payload
 * @returns {object} list
 */
export const packageLikeListPayload = (action, payload) => {
    const { sku, options, ...userData } = payload;
    switch (action.toLowerCase()) {
        case 'add':
            return {
                sku: sku,
                ...options,
                userdata: {
                    ...userData
                }
            };
        case 'update':
            return {
                ...userData
            };
        default:
            return payload;
    }
};
