import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Route } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import staffRoles from 'constants/staffRoles';

class StaffRoute extends Component {
    static propTypes = {
        authenticated: PropTypes.bool.isRequired,
        isStaff: PropTypes.bool.isRequired,
        push: PropTypes.func.isRequired,
        requiresDirectorRole: PropTypes.bool,
        requiresExperienceLeadRole: PropTypes.bool,
        isImpersonating: PropTypes.bool,
        profile: PropTypes.object
    };

    static defaultProps = {
        requiresDirectorRole: false,
        requiresExperienceLeadRole: false
    };

    constructor(props) {
        super(props);

        const { authenticated, isStaff, isImpersonating } = props;

        // When user not authenticated redirect to login page
        if (!authenticated || !isStaff) {
            props.push(ROUTES.STAFF_LOGIN);
        }

        // enforce the director role
        if (isStaff && !isImpersonating && props.requiresDirectorRole) {
            if (!staffRoles.isAtelierDirectorRole(props.profile.staffRole)) {
                props.push(ROUTES.HOME);
            }
        }

        // experience lead or director role
        if (isStaff && !isImpersonating && props.requiresExperienceLeadRole) {
            if (!staffRoles.isAtelierDirectorRole(props.profile.staffRole) && !staffRoles.isExperienceLeadRole(props.profile.staffRole)) {
                props.push(ROUTES.HOME);
            }
        }

    }

    render() {
        if (!this.props.authenticated || !this.props.isStaff) {
            return null;
        }

        return <Route {...this.props} />;
    }
}

const mapStateToProps = ({ user: { auth } }) => auth;

const mapDispatchToProps = dispatch => bindActionCreators({ push }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StaffRoute);
