import { createAction, handleActions } from 'redux-actions';
import { FETCH } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';
import { ORDER_STATUS } from 'constants/orders';
import { FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import moment from 'moment';
import normalizeOrders from 'core/utils/normalizeOrders';
import { SEARCH } from 'constants/search';
import normalizeProduct from 'core/utils/normalizeProduct';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import { searchProducts } from 'reducers/elasticsearch';
import { LOCATIONS } from 'constants/locations';

export const BACK_OF_HOUSE = 'BACK_OF_HOUSE';
const BACK_OF_HOUSE_SET = 'BACK_OF_HOUSE_SET';
const BACK_OF_HOUSE_PRODUCTS = 'BOH_PRODUCT_SEARCH';
const SET_BACK_OF_HOUSE_LOCATION = 'SET_BACK_OF_HOUSE_LOCATION';

const fetchBOHOrders = createAction(FETCH, (workshopId, secretKey, status = null, order = 'asc', location) => ({
    prefix: BACK_OF_HOUSE,
    endpoint: `${CONFIG.API_URL}/checkouts?workshopId=${workshopId}&secretKey=${secretKey}&since=${moment().subtract(15, 'd').startOf('day').valueOf()}${status ? `&status=${status}` : ''}&order=${order}&location=${location}`,
    options: {
        method: 'GET'
    }
}));

const setOrderStatus = createAction(FETCH, (workshopId, secretKey, orderId, status) => ({
    prefix: BACK_OF_HOUSE_SET,
    endpoint: `${CONFIG.API_URL}/checkouts/${orderId}?workshopId=${workshopId}&secretKey=${secretKey}`,
    options: {
        method: 'PUT',
        body: JSON.stringify({ status })
    }
}));

/**
 * Pull latest orders and set them for display.
 * @param {string} status
 * @param {string} order
 * @returns {Function} Dispatched request
 */
export const retrieveOrders = (status = null, order = 'asc') => {
    return (dispatch, getState) => {
        const { workshop: { workshopId }, secretKey: { secretKey }, backOfHouseOrders: { bohLocation } } = getState();
        if (!workshopId || !secretKey) {
            return;
        }
        return dispatch(fetchBOHOrders(workshopId, secretKey, status, order, bohLocation)).then(() => {
            const { backOfHouseOrders: { products: availableProducts, orders } } = getState();
            const availableSkus = availableProducts.map(p => p.sku);

            // get skus from cartItems in each orders, flatten, filter out skus for which we already have product data, distinct
            const skus = orders.map(o => {
                // only check order that are NEW, PACKED, or PICKED UP, as all others (cancelled, closed, etc) are not displayed on the BOH screen
                return [ORDER_STATUS.NEW, ORDER_STATUS.PACKED, ORDER_STATUS.PICKED_UP].includes(o.status) ? o.cart.map(c => c.sku) : null;

            }).reduce((acc, val) => acc.concat(val), [])
                .filter(sku => sku && !availableSkus.includes(sku));

            if (skus.length === 0) {
                return;
            }

            // if any sku, get product data
            const request = {
                bodyRequest: elasticSearchQueries.queryBySkus(skus)
            };
            const endpoint = SEARCH.URL.MGET;
            return dispatch(searchProducts({ endpoint, request }, BACK_OF_HOUSE_PRODUCTS));
        });
    };
};

export const updateOrderStatus = (orderId, status) => {
    return (dispatch, getState) => {
        const { workshop: { workshopId }, secretKey: { secretKey } } = getState();
        return dispatch(setOrderStatus(workshopId, secretKey, orderId, status)).then(() => dispatch(retrieveOrders()));
    };
};

const setBOHLocation = createAction(SET_BACK_OF_HOUSE_LOCATION);

export const setBackOfHouseLocation = (location) => {
    return (dispatch) => {
        return Promise.resolve(dispatch(setBOHLocation(location)));
    };
};

const initialState = {
    isFetching: false,
    error: null,
    orders: [],
    products: [],
    bohLocation: LOCATIONS.NYC
};

export default handleActions({
    [`${BACK_OF_HOUSE}/${FETCH_PENDING}`]: state => ({
        ...state,
        isFetching: true
    }),
    [`${BACK_OF_HOUSE}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        orders: payload.map(normalizeOrders)
    }),
    [`${BACK_OF_HOUSE_PRODUCTS}/${FETCH_SUCCESS}`]: (state, { payload: { docs: results } }) => ({
        ...state,
        products: [...state.products, ...results.filter(product => product.found).map(normalizeProduct)]
    }),
    [`${BACK_OF_HOUSE}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [setBOHLocation]: (state, { payload }) => ({
        ...state,
        bohLocation: payload
    })
}, initialState);
