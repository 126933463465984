import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import LazyLoadImage from 'components/GlobalComponents/LazyLoadImage/LazyLoadImage';
import { rem, styledProps } from 'core/styled';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';
import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import {
    SECTION_ID,
    BEAUTYPLAN_SKINCARE_TYPE,
    NON_CHANEL
} from 'constants/beautyplan';
import NonChanelProduct from 'components/BeautyPlans/Common/NonChanelProduct';
import BatchCodeOptions from './BatchCodeOptions';
import AddInKitOptions from './AddInKitOptions';

const ProductCardWrapper = styled.div`
    position: relative;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-bottom: ${rem(20)};
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 100%;
        height: auto;
    }
`;

const NonChanelProductWrapper = styled.div`
    position: relative;
    text-align: center;
    margin: auto;
    width: 100%;
`;

const ProductName = styled.p`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(10)};
    line-height: ${rem(13)};
    width: ${rem(160)};
`;

const RemoveButton = styled.span`
    position: absolute;
    left: calc(50% + ${rem(100)});
    transform: translateX(-50%);
    top: 0;
    width: 15px;
    height: 15px;
    z-index: 3;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        border-top: 1px solid ${styledProps('color', 'black')};
        position: absolute;
        left: 0;
        top: 7px;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

const ProductWrapper = styled.div`
    width: 100%;
`;

const ProductSubTitle = styled.p`
    font-family: ${styledProps('font', 'default')};
    font-size: ${rem(12)};
    line-height: ${rem(17)};
    margin: ${rem(5, 0)};
    width: ${rem(160)};
    text-align: center;
`;

const ProductVariant = styled(ProductSubTitle)`
    font-size: ${rem(10)};
    line-height: ${rem(12)};
`;

const ProductPrice = styled(ProductName)`
    margin-top: ${rem(10)};
`;

const ProductSelectOptions = styled.div`
    img {
        width: 54px;
    }
`;

const StyledSwatchCheckboxWrapper = styled.div`
    display: flex;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
    justify-content: center;

    > div {
        padding: 0 20px;
    }
`;

const StyledChromosomeTitle = styled(ChromosomeTitle)`
    padding: ${rem(2)} ${rem(10)};
`;

const NonChanelNote = styled.p`
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    text-transform: uppercase;
    text-align: center;
    font-size: ${rem(20)};
    line-height: 1.7;
`;

const StyledLazyLoadImage = styled(LazyLoadImage)`
    width: ${rem(200)} !important;
    height: auto;
`;

const NonChanelBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(153)};
    height: ${rem(153)};
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    font-size: ${rem(10)};
    line-height: ${rem(13)};
    background-color: ${styledProps('color', 'wildSand')};
    margin: ${rem(40, 'auto')};
`;

export default class SimpleBeautyplanProductCard extends Component {
    static propTypes = {
        onProductRemove: PropTypes.func.isRequired,
        onProductAdd: PropTypes.func.isRequired,
        products: PropTypes.array.isRequired,
        nonChanelProducts: PropTypes.array.isRequired,
        showNonChanelProduct: PropTypes.bool.isRequired,
        sectionIndex: PropTypes.object.isRequired,
        allowNonChanelNote: PropTypes.bool,
        productNote: PropTypes.bool,
        updateProductNote: PropTypes.func.isRequired,
        updateProductBatchCode: PropTypes.func.isRequired,
        removeProductBatchCode: PropTypes.func.isRequired,
        isFragranceFlight: PropTypes.bool,
        showInKit: PropTypes.bool,
        showBatch: PropTypes.bool,
        addProductInKit: PropTypes.func.isRequired,
        removeProductInKit: PropTypes.func.isRequired
    };

    state = {
        amProducts: [],
        pmProducts: [],
        finalProducts: this.props.products
    };

    componentDidMount() {
        this.normalizeProducts();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.products !== this.props.products ||
            prevProps.nonChanelProducts !== this.props.nonChanelProducts
        ) {
            this.normalizeProducts();
        }
    }

    /**
     * Normalize skincare products to render with am/pm checkboxes under each product properly
     */
    normalizeProducts() {
        const { products, nonChanelProducts, allowNonChanelNote } = this.props;
        // get any non-Chanel products first, since they need am/pm checkboxes as well
        // if non chanel notes is enable, treat them as normal products, and then display it differently while rendering
        let amProducts = [];
        let pmProducts = [];

        if (!allowNonChanelNote) {
            amProducts = nonChanelProducts.filter(
                prod => prod.categoryId === BEAUTYPLAN_SKINCARE_TYPE.AM
            );
            pmProducts = nonChanelProducts.filter(
                prod => prod.categoryId === BEAUTYPLAN_SKINCARE_TYPE.PM
            );
        }

        let finalProducts = this.props.products;

        if (
            allowNonChanelNote &&
            this.props.sectionIndex.id !== SECTION_ID.SKINCARE
        ) {
            finalProducts = [...finalProducts, ...nonChanelProducts];
        }

        if (this.props.sectionIndex.id === SECTION_ID.SKINCARE) {
            // extract out AM/PM skincare products
            const allProducts = !allowNonChanelNote
                ? products
                : [...products, ...nonChanelProducts];

            finalProducts = allProducts
                .map(product => {
                    if (product.categoryId === BEAUTYPLAN_SKINCARE_TYPE.AM) {
                        amProducts.push(product);
                    }

                    if (product.categoryId === BEAUTYPLAN_SKINCARE_TYPE.PM) {
                        pmProducts.push(product);
                    }

                    return product;
                })
                .filter((product, index, array) => {
                    // remove any duplicate products for proper rendering (since am/pm products come in separately)
                    return (
                        array.findIndex(prod => prod.sku === product.sku) ===
                        index
                    );
                });
        }

        this.setState({
            amProducts,
            pmProducts,
            finalProducts
        });
    }

    /**
     * Toggle if product is in trial kit or not
     * @param {object} product
     * @param {bool} isInTrialKit
     */
    toggleTrialKitProduct(product, isInTrialKit) {
        // TODO: below strings could be made constants, however they are unique to trial kit, so isn't necessary right now
        const sectionIndex = {
            categoryId: 'trial-kit',
            id: SECTION_ID.TRIAL,
            sectionId: 'sample-kit'
        };

        this.toggleProduct(product, isInTrialKit, sectionIndex);
    }

    /**
     * Add/Remove AM/PM skincare products
     * @param {object} product
     * @param {bool} isInCategory
     * @param {string} category
     */
    toggleSkincareProduct(product, isInCategory, category) {
        const {
            sectionIndex: { id, sectionId }
        } = this.props;

        const sectionIndex = {
            categoryId: category,
            id,
            sectionId
        };

        this.toggleProduct(product, isInCategory, sectionIndex);
    }

    /**
     * Add/remove product
     * @param {object} product
     * @param {bool} isInSection
     * @param {object} sectionIndex
     */
    toggleProduct(product, isInSection, sectionIndex) {
        if (isInSection) {
            this.props.onProductRemove(product, sectionIndex);
        } else {
            this.props.onProductAdd(product, sectionIndex);
        }
    }

    /**
     * Remove products
     * Product can be an AM/PM product which would show up as 2 different products
     * in this case, we need to remove both the AM and PM one when the remove button is clicked
     * @param {object} products
     * @param {object} selectedProduct
     */
    removeProduct(products, selectedProduct) {
        const {
            sectionIndex: { id, sectionId }
        } = this.props;

        const productsToRemove = selectedProduct
            ? products.filter(
                product =>
                    product.name === selectedProduct.name &&
                    product.note === selectedProduct.note
            )
            : products;

        productsToRemove.map(product => {
            const sectionIndex = {
                categoryId: product.categoryId,
                id,
                sectionId
            };

            return this.triggerRemoveProduct(product, sectionIndex);
        });
    }

    /**
     * Make sure removing a product also removes it from the trial kit
     * @param {object} product
     * @param {object} sectionIndex
     */
    triggerRemoveProduct(product, sectionIndex = null) {
        this.props.onProductRemove(product, sectionIndex);
        this.toggleTrialKitProduct(product, true);
    }

    addProductNote = product => e => {
        const { updateProductNote } = this.props;

        updateProductNote({
            sku: product.sku,
            note: e.target.innerHTML
        });
    };

    /**
     * Render selected vs unselected checkbox label
     * @param {string} text
     * @param {bool} isSelected
     * @param {number} rotation
     * @returns {component} component
     */
    renderCheckboxLabel(text, isSelected, rotation) {
        if (isSelected) {
            return (
                <TiltedText
                    fontSize={12}
                    rotate={rotation}
                    bgColor={'black'}
                    fontColor={'white'}
                >
                    {text}
                </TiltedText>
            );
        }

        return (
            <StyledChromosomeTitle fontSize={12} display={'block'}>
                {text}
            </StyledChromosomeTitle>
        );
    }

    /**
     * Render checkboxes for skincare products
     * @param {object} product
     * @returns {Component} AM/PM checkboxes
     */
    renderSkincareCheckboxes(product) {
        const isAmProd = this.state.amProducts.some(
            prod => prod.sku === product.sku
        );
        const isPmProd = this.state.pmProducts.some(
            prod => prod.sku === product.sku
        );

        return (
            <StyledSwatchCheckboxWrapper>
                <SwatchCheckbox
                    name={SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3}
                    value={product.sku}
                    flexDirection={'column'}
                    imageOrder={0}
                    onSelect={() =>
                        this.toggleSkincareProduct(
                            product,
                            isAmProd,
                            BEAUTYPLAN_SKINCARE_TYPE.AM
                        )
                    }
                    textAlign={'center'}
                    display={'flex'}
                    isSelected={isAmProd}
                >
                    {this.renderCheckboxLabel('morning', isAmProd, 2)}
                </SwatchCheckbox>

                <SwatchCheckbox
                    name={SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_4}
                    value={product.sku}
                    flexDirection={'column'}
                    imageOrder={0}
                    onSelect={() =>
                        this.toggleSkincareProduct(
                            product,
                            isPmProd,
                            BEAUTYPLAN_SKINCARE_TYPE.PM
                        )
                    }
                    textAlign={'center'}
                    display={'flex'}
                    isSelected={isPmProd}
                >
                    {this.renderCheckboxLabel('evening', isPmProd, -2)}
                </SwatchCheckbox>
            </StyledSwatchCheckboxWrapper>
        );
    }

    _renderInKitOption = product => {
        const { addProductInKit, removeProductInKit } = this.props;

        return (
            <ProductSelectOptions>
                <AddInKitOptions
                    product={product}
                    addProductInKit={addProductInKit}
                    removeProductInKit={removeProductInKit}
                />
            </ProductSelectOptions>
        );
    };

    _renderBatchCodeOptions = product => {
        const { updateProductBatchCode, removeProductBatchCode } = this.props;

        return (
            <ProductSelectOptions>
                <BatchCodeOptions
                    product={product}
                    updateProductBatchCode={updateProductBatchCode}
                    removeProductBatchCode={removeProductBatchCode}
                    simpleUI
                />
            </ProductSelectOptions>
        );
    };

    /**
     * Render Chanel product
     * For now, only skincare section has changed for staff, so keeping makeup section the same as before
     * @returns {Component} - Product card
     */
    renderChanelProduct() {
        const {
            products,
            nonChanelProducts,
            sectionIndex: { id },
            isFragranceFlight,
            showInKit,
            showBatch
        } = this.props;

        this.batchCodeRefs = [];

        return this.state.finalProducts.map((product, key) => {
            // non chanel will only appear in finalproducts if allowNonChanelNote is enabled
            const isNonChanelProduct = product.sku.startsWith(NON_CHANEL);

            // for skincare category, we want to remove multiple products (AM/PM), but for other sections, we just remove one product at a time on click of X
            return (
                <ProductCardWrapper
                    key={key}
                    isLast={products.length === key + 1}
                    isNonChanel={isNonChanelProduct}
                >
                    <RemoveButton
                        isNonChanel={isNonChanelProduct}
                        onClick={
                            id === SECTION_ID.SKINCARE
                                ? () =>
                                    this.removeProduct(
                                        !isNonChanelProduct
                                            ? products
                                            : nonChanelProducts,
                                        product
                                    )
                                : () => this.triggerRemoveProduct(product)
                        }
                    />
                    {!isNonChanelProduct ? (
                        <>
                            <StyledLazyLoadImage
                                src={
                                    isFragranceFlight && product.fragranceImage
                                        ? product.fragranceImage
                                        : product.mediumImages[0]
                                }
                                alt={product.name}
                            />
                            <ProductName>
                                <span
                                    translate={'no'}
                                    className={'notranslate'}
                                >
                                    {product.name}
                                </span>
                            </ProductName>
                            <ProductSubTitle
                                dangerouslySetInnerHTML={{
                                    __html: product.subName
                                }}
                            />
                            {product.variantInfo && (
                                <ProductVariant
                                    dangerouslySetInnerHTML={{
                                        __html: product.variantInfo
                                    }}
                                />
                            )}
                            <ProductPrice>${product.price}</ProductPrice>
                        </>
                    ) : (
                        <NonChanelProductWrapper>
                            <NonChanelProduct />
                            {product.note && (
                                <NonChanelNote>{product.note}</NonChanelNote>
                            )}
                        </NonChanelProductWrapper>
                    )}
                    {showInKit && this._renderInKitOption(product)}
                    {showBatch && this._renderBatchCodeOptions(product)}
                </ProductCardWrapper>
            );
        });
    }

    /**
     * Render non-Chanel product
     * @returns {Component} - Non-Chanel product
     */
    renderNonChanelProducts() {
        const { nonChanelProducts } = this.props;

        return (
            <NonChanelProductWrapper>
                <RemoveButton
                    onClick={() => this.removeProduct(nonChanelProducts)}
                />
                <NonChanelBox>
                    Your
                    <br />
                    Current
                    <br />
                    Product
                </NonChanelBox>
            </NonChanelProductWrapper>
        );
    }

    render() {
        const { products, nonChanelProducts, allowNonChanelNote } = this.props;
        const showNonChanelProduct = nonChanelProducts.length > 0;

        if ((!products || products.length === 0) && !showNonChanelProduct) {
            return null;
        }

        return (
            <ProductWrapper>
                {this.renderChanelProduct()}
                {!allowNonChanelNote &&
                    showNonChanelProduct &&
                    this.renderNonChanelProducts()}
                {/* allowNonChanelNote && showNonChanelProduct && this.renderAllNonChanelProducts() */}
            </ProductWrapper>
        );
    }
}
