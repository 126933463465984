import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inputStyle } from 'core/styled';
import styled from 'styled-components';
import moment from 'moment';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import { rem, styledProps } from 'core/styled';
import { InputLabel } from './FormInput.styled';
import ErrorMessage from 'components/GlobalComponents/ErrorMessage/ErrorMessage';

const Input = styled.input`
    ${inputStyle};

    font-family: ${({ inputfont }) => inputfont ? styledProps('font', inputfont) : ''};
    font-size: ${({ inputfontsize }) => inputfontsize ? rem(inputfontsize) : '' };
    border: ${rem(1)} solid black;
    padding: ${rem(8, 4)};
    width: ${({ fieldWidth }) => rem(fieldWidth)} !important;
    text-align: center;
    margin: ${rem(4, 8)};
    height: unset;
    -moz-appearance: textfield;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${styledProps('color', 'black')};
        opacity: 1;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${styledProps('color', 'black')};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${styledProps('color', 'black')};
    }
`;

const ThreeFieldsDateWrapper = styled(Flex)`
    width: 100%;
`;

const DateWrapper = styled(Flex)`
    width: 100%;
`;

const FieldWrapper = styled(Flex)`
    margin: ${rem(8, 0)};
`;

const StyledFieldLabel = styled(InputLabel)`
    font-size: ${rem(12)};
`;

const inputDateFormat = 'YYYY-MM-DD';

export default class ThreeFieldsDate extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        value: PropTypes.string,
        // Moment object to determine max date
        max: PropTypes.object,
        // Moment object to determine min date
        min: PropTypes.object,
        ariaLabel: PropTypes.string,
        inputRef: PropTypes.object,
        inputfontsize: PropTypes.number,
        inputfont: PropTypes.string,
        maxErrorMessage: PropTypes.string,
        minErrorMessage: PropTypes.string,
        alwaysMobile: PropTypes.bool
    };

    static defaultProps = {
        min: moment('1900-01-01'),
        max: moment('2099-12-31')
    };

    state = {
        errorMessage: '',
        min: this.props.min.format(inputDateFormat),
        max: this.props.max.format(inputDateFormat),
        year: this.props.value ? moment(this.props.value).format('YYYY') : '',
        month: this.props.value ? moment(this.props.value).format('MM') : '',
        day: this.props.value ? moment(this.props.value).format('DD') : '',
        minErrorMessage: this.props.minErrorMessage ? this.props.minErrorMessage : `Please enter a date after ${moment(this.props.min).format('MM/DD/YYYY')}`,
        maxErrorMessage: this.props.maxErrorMessage ? this.props.maxErrorMessage : `Please enter a date before ${moment(this.props.max).format('MM/DD/YYYY')}`
    };

    dateInput = React.createRef();
    yearInput = React.createRef();

    isBefore = date => !this.state.min || date.isSameOrAfter(this.state.min);

    isAfter = date => !this.state.max || date.isSameOrBefore(this.state.max);

    isValidDate = current => this.isBefore(current) && this.isAfter(current);

    handleChange = moment => {
        const value = moment ? moment.format(inputDateFormat) : '';

        this.props.onChange({
            target: {
                id: this.props.id,
                value,
                form: this.input.form
            }
        });
    };

    handleDayField = e => {
        const { month, year, minErrorMessage, maxErrorMessage } = this.state;

        let value = e.target.value;
        let errorMessage = '';

        if (value.length > 2) {
            return;
        }

        // not allow day below 1
        if (value && value.length > 1 && value < 1) {
            value = 1;
        }

        // not allow day above 31
        if (value && value.length > 1 && value > 31) {
            value = 31;
        }

        if (value && month && year) {
            // if other values are completed check max and min dates
            const selectedDate = moment([year, month - 1, value]);
            if (selectedDate.isValid()) {

                if (!this.isBefore(selectedDate)) {
                    // is before than min date, show error message
                    errorMessage = minErrorMessage;
                } else if (!this.isAfter(selectedDate)) {
                    // is after than max date, show error message
                    errorMessage = maxErrorMessage;
                }

                // is a valid day between min and max
                this.setState({
                    day: value,
                    errorMessage
                });
            } else {
                // is invalid date (like 30 of february) place value and handle on blur
                this.setState({
                    day: value,
                    errorMessage: 'Enter a valid date'
                });
            }

        } else {
            // enter day value when date is not complete, error message if date is not complete
            errorMessage = (value || month || year) ? 'Enter a valid date' : errorMessage;
            this.setState({
                day: value,
                errorMessage
            });
        }
    };

    handleMonthField = e => {
        const { day, year, minErrorMessage, maxErrorMessage } = this.state;

        let value = e.target.value;
        let errorMessage = '';

        if (value.length > 2) {
            return;
        }

        // not allow month below 1
        if (value && value.length > 1 && value < 1) {
            value = 1;
        }

        // not allow month above 12
        if (value && value.length > 1 && value > 12) {
            value = 12;
        }

        if (value && day && year) {
            // if other values are completed check max and min dates
            const selectedDate = moment([year, value - 1, day]);
            if (selectedDate.isValid()) {

                if (!this.isBefore(selectedDate)) {
                    // is before than min date, show error message
                    errorMessage = minErrorMessage;
                } else if (!this.isAfter(selectedDate)) {
                    // is after than max date, show error message
                    errorMessage = maxErrorMessage;
                }

                // is a valid month between min and max
                this.setState({
                    month: value,
                    errorMessage
                });
            } else {
                // is invalid date (like 30 of february) place value and handle on blur
                this.setState({
                    month: value,
                    errorMessage: 'Enter a valid date'
                });
            }

        } else {
            // enter day value when date is not complete, error message if date is not complete
            errorMessage = (value || year || day) ? 'Enter a valid date' : errorMessage;
            this.setState({
                month: value,
                errorMessage
            });
        }
    };

    handleYearField = e => {
        const { day, month, min, max, minErrorMessage, maxErrorMessage } = this.state;

        const value = e.target.value;
        let errorMessage = '';

        if (value.length > 4) {
            return;
        }

        // not allow year below min (when 4 digits are entered)
        if (value && value.length > 3 && value < moment(min).year()) {
            errorMessage = minErrorMessage;
        }

        // not allow year above max (when 4 digits are entered)
        if (value && value.length > 3 && value > moment(max).year()) {
            errorMessage = maxErrorMessage;
        }

        if (value && value.length > 3 && day && month) {
            // if other values are completed check max and min dates
            const selectedDate = moment([value, month - 1, day]);
            if (selectedDate.isValid()) {
                if (!this.isBefore(selectedDate)) {
                    // is before than min date
                    errorMessage = minErrorMessage;

                } else if (!this.isAfter(selectedDate)) {
                    // is after than max date
                    errorMessage = maxErrorMessage;
                }
                // is a valid year between min and max
                this.setState({
                    year: value,
                    errorMessage
                });
            } else {
                // is invalid date (like 29 of february in non leap-years) place value and handle on blur
                this.setState({
                    year: value,
                    errorMessage: 'Enter a valid date'
                });
            }

        } else {
            // enter day value when date is not complete, error message if date is not complete
            errorMessage = (value || month || day) ? 'Enter a valid date' : errorMessage;
            this.setState({
                year: value,
                errorMessage
            });
        }
    };

    handleDateBlur = e => {
        const { year, month, day } = this.state;

        if (year || month || day) {
            const selectedDate = moment([year, month ? month - 1 : undefined, day]);
            this.handleChange(selectedDate);
        } else {
            this.handleChange('');
        }
        this.props.onBlur(e);
    };

    getFieldWidth = (width = 16, characters) => width * characters * 1.25 + 8;

    handleInputFocusChange = currentField => e => {
        if (((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) && e.target.value.length === 2) {
            if (currentField === 'month') {
                this.dateInput.current.focus();
            } else if (currentField === 'day') {
                this.yearInput.current.focus();
            }
        }
    };

    render() {
        const { id, onBlur, inputRef, ariaLabel, inputfont, inputfontsize, alwaysMobile, minErrorMessage, maxErrorMessage, ...rest } = this.props;
        const { year, month, day, max, min, errorMessage } = this.state;

        const maxMoment = max ? moment(max) : null;
        const maxYear = maxMoment ? parseInt(maxMoment.format('YYYY'), 10) : 2099;
        const maxMonth = (maxMoment && year === maxYear.toString()) ? parseInt((maxMoment).format('MM'), 10) : 12;
        const maxDay = (maxMoment && year === maxYear.toString() && month === maxMonth.toString()) ? parseInt((maxMoment).format('DD'), 10) : 31;

        const minMoment = min ? moment(min) : null;
        const minYear = minMoment ? parseInt(minMoment.format('YYYY'), 10) : 1900;
        const minMonth = (minMoment && year === minYear.toString()) ? parseInt((minMoment).format('MM'), 10) : 1;
        const minDay = (minMoment && year === minYear.toString() && month === minMonth.toString()) ? parseInt((minMoment).format('DD'), 10) : 1;

        return (
            <ThreeFieldsDateWrapper flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <DateWrapper flexDirection={'row'} alignItems={'center'} justifyContent={'center'} onBlur={this.handleDateBlur}>
                    <FieldWrapper flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <StyledFieldLabel aria-hidden={'true'}>Month</StyledFieldLabel>
                        <Input
                            id={id + '__month'}
                            aria-label={ariaLabel + ' month'}
                            min={minMonth}
                            max={maxMonth}
                            type={'number'}
                            placeholder={'MM'}
                            value={month}
                            onChange={this.handleMonthField}
                            inputfont={inputfont}
                            inputfontsize={inputfontsize}
                            onKeyUp={this.handleInputFocusChange('month')}
                            fieldWidth={this.getFieldWidth(inputfontsize, 2)}/>
                    </FieldWrapper>
                    <FieldWrapper flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <StyledFieldLabel aria-hidden={'true'}>Day</StyledFieldLabel>
                        <Input
                            id={id + '__day'}
                            aria-label={ariaLabel + ' day'}
                            min={minDay}
                            max={maxDay}
                            type={'number'}
                            placeholder={'DD'}
                            value={day}
                            onChange={this.handleDayField}
                            inputfont={this.props.inputfont}
                            inputfontsize={this.props.inputfontsize}
                            fieldWidth={this.getFieldWidth(inputfontsize, 2)}
                            onKeyUp={this.handleInputFocusChange('day')}
                            ref={this.dateInput}/>
                    </FieldWrapper>
                    <FieldWrapper flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <StyledFieldLabel aria-hidden={'true'}>Year</StyledFieldLabel>
                        <Input
                            id={id + '__year'}
                            aria-label={ariaLabel + ' year'}
                            min={minYear}
                            max={maxYear}
                            type={'number'}
                            placeholder={'YYYY'}
                            value={year}
                            onChange={this.handleYearField}
                            inputfont={this.props.inputfont}
                            inputfontsize={this.props.inputfontsize}
                            fieldWidth={this.getFieldWidth(inputfontsize, 4)}
                            ref={this.yearInput}/>
                    </FieldWrapper>
                    <input
                        id={id}
                        hidden
                        {...rest}
                        type="text"
                        pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                        ref={input => (this.input = input)}
                    />
                </DateWrapper>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </ThreeFieldsDateWrapper>
        );
    }
}
