import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import { rem, styledProps } from 'core/styled';
import connect from 'react-redux/es/connect/connect';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import SimpleProductNote from './SimpleProductNote';
import { ROUTES } from 'constants/routes';
import Button from 'components/GlobalComponents/Button/Button';
import { DISCOVER_TABS } from 'constants/discover';

const DEFAULT_IMAGE = 'https://images.prismic.io/atelier/d73574e1-2d6f-411d-83bc-ee40cdc71536_The+Atelier.jpg?auto=compress,format';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const ServiceItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: ${rem(20, 10)};
    margin-bottom: ${rem(0)};
    position: relative;

    img {
        width: ${rem(150)};
        height: ${rem(150)};
        object-fit: cover;
        margin-bottom: ${rem(10)};
    }

    p.bp--servicename {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        font-size: ${rem(14)};
        margin: ${rem(10, 0, 0)};
    }

    p.bp--servicesubtext {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(10)};
        margin: ${rem(5, 0, 0)};
    }

    p.bp--servicedescription {
        text-align: center;
        font-family: ${styledProps('font', 'SofiaPro')};
        font-size: ${rem(14)};
        line-height: 1.5;
        margin: ${rem(15, 0, 0)};
        width: 100%;
        max-width: ${rem(300)};
    }

    p.bp--serviceprice {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-size: ${rem(10)};
        font-weight: bold;
        margin: ${rem(5, 0, 0)};
    }
`;

const RemoveButton = styled(NoStyleButton)`
    position: absolute;
    left: calc(50% + ${rem(100)});
    transform: translateX(-50%);
    top: 0;
    width: 15px;
    height: 15px;
    z-index: 3;
    cursor: pointer;
    
    &:before,
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        border-top: 1px solid ${styledProps('color', 'black')};
        position: absolute;
        left: 0;
        top: 7px;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

const StyledFormInputWrapper = styled.div`
    margin: ${rem(20, 'auto', 30)};
    text-align: left;
    border: ${rem(1)} solid black;
    min-height: ${rem(100)};
    max-width: ${rem(300)};
    width: 100%;

    > div {
        margin-top: ${rem(-15)};
    }
`;

const NoteInput = styled(FormInput)`
    line-height: 1.5;
`;

const SmallButton = styled(Button).attrs({
    variant: 'primary',
    type: 'button',
    small: true
})`
    margin: ${rem(15, 'auto')};
`;

class SimpleBeautyPlanServiceList extends Component {

    static propTypes = {
        services: PropTypes.array.isRequired,
        removeService: PropTypes.func,
        sessions: PropTypes.object.isRequired,
        idToSessionMap: PropTypes.object.isRequired,
        productNote: PropTypes.bool,
        updateServiceNote: PropTypes.func,
        viewMode: PropTypes.bool,
        artist: PropTypes.string,
        push: PropTypes.func
    };

    static defaultProps = {
        push: () => {}
    };

    removeService = serviceItem => {
        this.props.removeService(serviceItem);
    };

    addServiceNote = service => e => {
        const { updateServiceNote } = this.props;

        updateServiceNote({
            service,
            note: e.target.innerHTML
        });
    };

    renderService = (item, index) => {
        const { idToSessionMap, sessions, productNote, viewMode, artist, push } = this.props;

        const serviceType = idToSessionMap[item.service.serviceId];

        if (!serviceType) {
            return null;
        }

        const serviceData = sessions[serviceType];

        if (!serviceData) {
            return null;
        }

        const serviceDataAll = serviceData.All;

        if (viewMode) {
            return (
                <ServiceItem key={index}>
                    <img src={serviceDataAll.image || DEFAULT_IMAGE} alt={''}/>
                    <p className={'bp--servicename'}>{serviceDataAll.beautyplanName}</p>
                    {serviceDataAll.beautyplanSubname && <p className={'bp--servicesubtext'}>{serviceDataAll.beautyplanSubname}</p>}
                    {serviceDataAll.description && <p className={'bp--servicedescription'}>{serviceDataAll.description}</p>}
                    <p className={'bp--serviceprice'}>{serviceDataAll.price}</p>
                    <SmallButton onClick={() => push(`${ROUTES.DISCOVER}/${DISCOVER_TABS.SERVICES}`)}>Learn more and book</SmallButton>
                    {item.note && <SimpleProductNote note={item.note} artist={artist} />}
                </ServiceItem>
            );
        }

        return (
            <ServiceItem key={index}>
                <RemoveButton onClick={() => this.removeService(item)} />
                <img src={serviceDataAll.image || DEFAULT_IMAGE} alt={''}/>
                <p className={'bp--servicename'}>{serviceDataAll.beautyplanName}</p>
                {serviceDataAll.beautyplanSubname && <p className={'bp--servicesubtext'}>{serviceDataAll.beautyplanSubname}</p>}
                {serviceDataAll.description && <p className={'bp--servicedescription'}>{serviceDataAll.description}</p>}
                <p className={'bp--serviceprice'}>{serviceDataAll.price}</p>
                {productNote &&
                    <StyledFormInputWrapper>
                        <NoteInput
                            key={`${index}-${item.note}`}
                            id={`beautyplan-servicenote_${index}`}
                            placeholder={'Add service notes here'}
                            inputtextalign={'left'}
                            onBlur={this.addServiceNote(item)}
                            value={item.note}
                            renderUnderline={false}
                            hideLabelOnFocus
                            multiLine
                            multiLineInitialBlockHeight={rem(80)}
                            inputPadding={[5, 5]}
                            inputFullWidth
                            inputfont={'SofiaPro'}
                            inputfontsize={12}
                        />
                    </StyledFormInputWrapper>
                }
            </ServiceItem>
        );
    };

    render() {
        const { services } = this.props;

        // do not render if no events are available
        if (!services || !services.length) {
            return null;
        }

        return (
            <Wrapper>
                {services.map(this.renderService)}
            </Wrapper>
        );
    }
}

const mapStateToProps = ({ booking: { sessions, idToSessionMap } }) => ({
    sessions,
    idToSessionMap
});

export default connect(mapStateToProps)(SimpleBeautyPlanServiceList);
