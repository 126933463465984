export const UserNotificationTypes = {
    FILL_QUESTIONNAIRE: 'FILL_QUESTIONNAIRE',
    REVIEW_MAKEUP_ARTIST: 'REVIEW_MAKEUP_ARTIST',
    FREE_PASS: 'FREE_PASS',
    UPCOMING_APPOINTMENT: 'UPCOMING_APPOINTMENT',
    RSVP_CONFIRMATION: 'RSVP_CONFIRMATION',

    // My Atelier membership notifications
    MEMBERSHIP_PRIVATE_EVENT: 'MEMBERSHIP_PRIVATE_EVENT',
    MEMBERSHIP_PRODUCT_PREVIEW: 'MEMBERSHIP_PRODUCT_PREVIEW',
};

export const NotificationSlideLink = {
    rating: 'REVIEW_MAKEUP_ARTIST',
    questionnaire: 'UpcomingAppointmentSlide',
    houseRules: 'houseRulesModal',
    welcome: 'welcome'
};

export const NotificationLinking = {
    NOTIFICATION_TYPE: 'notification',
    APPOINTMENT_ID: 'aptId',
    RATING: 'star'
};

export const NOTIFICATION_HOUSE_RULES = 'HOUSE_RULES';
