import styled from 'styled-components';
import PropTypes from 'prop-types';
import { boxStyle, styledBreakpointProp } from 'core/styled';

const defaultProps = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    flex: 'initial',
    fullHeight: true
};

const Flex = styled.div`
    ${boxStyle};
    ${styledBreakpointProp('display', defaultProps)};
    ${styledBreakpointProp('flexDirection', defaultProps)};
    ${styledBreakpointProp('flexWrap', defaultProps)};
    ${styledBreakpointProp('alignItems', defaultProps)};
    ${styledBreakpointProp('justifyContent', defaultProps)};
    ${styledBreakpointProp('flex', defaultProps)};
    ${({ fullHeight }) => fullHeight && 'min-height: 100%'};
`;

Flex.propTypes = {
    id: PropTypes.string,
    display: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf(['flex', 'flex-inline'])
    ]),
    flexDirection: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse'])
    ]),
    flexWrap: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse'])
    ]),
    alignItems: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf(['stretch', 'flex-start', 'flex-end', 'center', 'end'])
    ]),
    justifyContent: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf([
            'stretch',
            'flex-start',
            'flex-end',
            'center',
            'space-around',
            'space-between',
            'space-evenly',
            'end'
        ])
    ]),
    flex: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.oneOf(['initial', 'auto', 'none'])
    ]),
    fullHeight: PropTypes.bool
};

Flex.defaultProps = defaultProps;

export default Flex;
