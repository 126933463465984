export const LOCATIONS = {
    NYC: 1,
    AUSTIN: 2
};

export const VIRTUAL_LOCATION = 'Virtual';

export const LOCATION_TIMEZONE = {
    [LOCATIONS.NYC]: 'America/New_York',
    [LOCATIONS.AUSTIN]: 'America/Chicago'
};

export const LOCATION_TEXT = {
    [LOCATIONS.NYC]: 'NYC',
    [LOCATIONS.AUSTIN]: 'Austin'
};

export const LOCATION_ANALYTICS = {
    [LOCATIONS.NYC]: 'nyc',
    [LOCATIONS.AUSTIN]: 'austin'
};

export const getLocationByID = id => {
    return Object.keys(LOCATIONS).find(key => LOCATIONS[key] === id);
};

export const isAustin = location => LOCATIONS.AUSTIN === location;
