import { createAction, handleAction } from 'redux-actions';
import { combineReducers } from 'redux';
import createFetchReducer from 'reducers/createFetchReducer';
import { CONFIG } from 'constants/config';
import { FETCH } from 'middlewares/fetch';

const FETCH_DEFAULT_WORKSHOP = 'FETCH_DEFAULT_WORKSHOP';
const SET_WORKSHOP_ID = 'SET_WORKSHOP_ID';
const SET_WORKSHOP_DATA = 'SET_WORKSHOP_DATA';
const SET_WORKSHOP_SERVER_ERROR = 'SET_WORKSHOP_SERVER_ERROR';

export const setWorkshopId = createAction(SET_WORKSHOP_ID);
export const setWorkshopData = createAction(SET_WORKSHOP_DATA);
export const setWorkshopServerError = createAction(SET_WORKSHOP_SERVER_ERROR);

export const fetchDefaultWorkshop = createAction(FETCH, () => ({
    prefix: FETCH_DEFAULT_WORKSHOP,
    endpoint: `${CONFIG.API_URL}/workshops/default`,
    options: {
        method: 'GET'
    }
}));

export const setWorkshopContext = () => {
    return (dispatch) => {
        dispatch(setWorkshopServerError(false));
        return dispatch(fetchDefaultWorkshop())
            .then(response => {
                dispatch(setWorkshopData(response));
                dispatch(setWorkshopId(response.id));
            })
            .catch(() => {
                dispatch(setWorkshopServerError(true));
            });
    };
};

export default combineReducers({
    defaultWorkshop: createFetchReducer(FETCH_DEFAULT_WORKSHOP),
    workshopId: handleAction(
        SET_WORKSHOP_ID,
        (state, { payload }) => (payload),
        ''
    ),
    workshopData: handleAction(
        SET_WORKSHOP_DATA,
        (state, { payload }) => (payload),
        {}
    ),
    workshopServerError: handleAction(
        SET_WORKSHOP_SERVER_ERROR,
        (state, { payload }) => (payload),
        false
    )
});
