import isProd from 'core/utils/isProd';

const staffRoles = {
    isAtelierDirectorRole: (role) => role && (role.toLowerCase().includes('director') || role.toLowerCase().includes('lead digital it') || role.toLowerCase().includes('head of on-site strategy & operations') || (!isProd() && role.toLowerCase().includes('atelier default role'))),
    isExperienceLeadRole: (role) => {
        if (role) {
            const parts = role.toLowerCase().split(' ');
            return ((parts.includes('experience') && parts.includes('lead')) || (!isProd() && role.toLowerCase().includes('atelier default role')));
        }
        return false;
    },
    ATELIER_DIRECTOR_ROLE: 'director',
    ATELIER_HOST_ROLE: 'Atelier Beaute Host',
    ATELIER_MUA_ROLE: 'Atelier Beaute Makeup Artist in Residence'
};

export default staffRoles;
