import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import { rem, styledProps } from 'core/styled';
import styled, { css } from 'styled-components';
import { EVENTS } from 'constants/analytics';
import { analyticsTag } from 'reducers/analytics';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import FocusTrap from 'focus-trap-react';
import CART_BACK from 'assets/cart-back.svg';
import CartSection from './CartSection';
import { LAYER } from 'constants/layers';

const PageWrapper = styled.nav`
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100%;
    /* visibility: ${({ showHamburgerMenu }) =>
        showHamburgerMenu ? 'visible' : 'hidden'}; */
    background-color: transparent;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
    
    ${greaterThan(breakpoints.medium)(css`
        padding-bottom: 0;
    `)};
`;

const MenuWrapper = styled(Flex)`
    position: absolute;
    padding: ${rem(0, 0, 50)};
    min-height: 100vh;
    width: 100%;
    background: ${styledProps('color', 'white')};
    left: 0;
    top: 0;
`;

/* const MenuBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 110vh;
    margin-top: -50px;
    background: ${({ bgColor }) => styledProps('color', bgColor)};
    transition: left 0.3s linear;
    z-index: -1;
    left: 0;
    top: 0;
`; */

const MenuSpacer = styled.div`
    display: none;
    position: relative;
    min-height: 100vh;
    background-color: red;
    width: 100%;
`;

const TopSection = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: ${rem(50)};
    background-color: ${styledProps('color', 'wildSand')};
    z-index: ${LAYER.HAMBURGER_MENU + 1};
`;

const TopSectionTouchable = styled(NoStyleButton)`
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${rem(0, 20, 0, 15)};
    font-size: ${rem(10)};
    letter-spacing: -0.015em;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;

    p {
        display: flex;
        align-items: center;
        color: ${styledProps('color', 'black')};
    }
`;

const menuAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'header',
    eventAction: 'cart'
};

class CartMenu extends Component {
    static propTypes = {
        toggleCart: PropTypes.func.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        staffMode: PropTypes.bool,
        navigate: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        showCartMenu: PropTypes.bool.isRequired,
        cartItems: PropTypes.array.isRequired,
        closingCart: PropTypes.bool.isRequired
    };

    state = {
        isCloseModalOpen: false,
        focusTrapActive: true
    };


    componentDidUpdate(prevProps) {
        // when closing menu clear the interval
        if (prevProps.showCartMenu && !this.props.showCartMenu) {
            this.setState({
                focusTrapActive: true
            });
        }
    }

    /**
     * Toggle showing logout modal
     */
    toggleLogoutModal() {
        this.setState({
            isCloseModalOpen: !this.state.isCloseModalOpen
        });
    }

    /**
     * Analytics on links
     * @param {string} label
     */
    analytics = label => {
        this.props.analyticsTag(
            {
                ...menuAnalytics,
                eventLabel: label
            },
            { userInfo: true }
        );
    };

    closeMenu = e => {
        e.preventDefault();
    };

    render() {
        const { showCartMenu, toggleCart, cartItems, closingCart } = this.props;
        const { focusTrapActive } = this.state;

        return (
            <PageWrapper
                showHamburgerMenu={showCartMenu}
                id="atelier--hamburgermenu-wrapper"
                role={'navigation'}
            >
                {/* <MenuBackground bgColor={theme.bgColor} showHamburgerMenu={showHamburgerMenu} /> */}
                <FocusTrap active={showCartMenu && focusTrapActive}>
                    <MenuWrapper
                        flexDirection={'column'}
                        alignItems={'center'}
                        showHamburgerMenu={showCartMenu}
                        id="atelier--hamburgermenu-content"
                    >
                        {/* This ID is needed for ensuring the hamburger close button does not respond to scrolling events */}
                        <TopSection id={'hamburgerCloseIcon'}>
                            <TopSectionTouchable
                                type="button"
                                onClick={() => toggleCart()}
                            >
                                <p>
                                    <img src={CART_BACK} alt={''} />
                                    &nbsp;&nbsp;BACK
                                </p>
                                <Icon
                                    type={ICON_TYPES.BAG}
                                    selected={!!cartItems.length}
                                    showAnimatedDot={true}
                                    pixelHeight={19}
                                    variant={'light'}
                                    alt={''}
                                />
                            </TopSectionTouchable>
                        </TopSection>
                        {(showCartMenu || closingCart) && (
                            <CartSection toggleCart={toggleCart} />
                        )}
                    </MenuWrapper>
                </FocusTrap>
                <MenuSpacer onClick={this.closeMenu} />
            </PageWrapper>
        );
    }
}

const mapStateToProps = ({ cart }) => ({
    cartItems: cart.items
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ analyticsTag }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu);
