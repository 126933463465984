import { createAction, handleActions } from 'redux-actions';
import { fetchProductByProductCode, fetchProductsBySkus } from 'reducers/elasticsearch';

const initialState = {
    crossSellProducts: [],
    isFetching: false,
    isFetchingCrossSell: false,
    postAddRedirectUrl: null
};

export const setPDPProducts = createAction('SET_PDP_PRODUCTS');
export const setPDPCrossSellProducts = createAction('SET_CROSS_SELL_PRODUCTS');
export const productsFetching = createAction('PDP_IS_FETCHING');
export const crossSellFetching = createAction('CROSS_SELL_FETCHING');
export const setPostAddRedirectUrl = createAction('SET_POST_ADD_REDIRECT_URL');

/**
 * Logic to select a product
 * @param {array} products
 * @param {string} sku
 * @return {any} selectedProduct
 */
export const pickProduct = (products, sku) => {

    if (!products) {
        return null;
    }

    // if no sku is specified, default to the emblematic sku or the 1st product if there's no emblematic sku
    if (!sku) {
        return products.find(p => p.emblematic) || products[0];
    }

    return products.find(p => p.sku === sku);
};

/**
 * Fetch all products from cross sell param of currently selected product
 * NOTE: Cross sell products are SKU assigned, but creative shows by PID so need to do the following:
 * 1. fetch data by sku
 * 2. pull out product code from results
 * 3. fetch data by product code to be able to aggregate shades
 * @param {object} selectedProduct
 * @return {function(*, *): *} - promise
 */
export const getCrossSellProducts = selectedProduct => {
    return dispatch => {
        dispatch(crossSellFetching(true));
        // given skus, fetch product data for sku
        return dispatch(fetchProductsBySkus(selectedProduct.crossSell))
            .then(products => {
                // set product data of all sku cross sell products
                dispatch(
                    setPDPCrossSellProducts(
                        products
                            .filter(
                                item =>
                                    item &&
                                    item._source &&
                                    item._source.active &&
                                    item._source.active.site &&
                                    item._source.active.pcm
                            )
                            .map(item => [item])
                    )
                );
                dispatch(crossSellFetching(false));
            })
            .catch(() => dispatch(crossSellFetching(false)));
    };
};

/**
 * Fetch product and all product shades data as separate products
 * @param {string} productCode
 * @param {string} sku - optional sku
 * @param {boolean} isStaff - staff searching. It allows see non browsable items for them
 * @return {function(*, *): *} - promise
 */
export const getPDPProductData = (productCode, sku, isStaff = false) => {
    return (dispatch) => {

        // product is fetching
        dispatch(productsFetching(true));

        return dispatch(fetchProductByProductCode(productCode, isStaff))
            .then(normalizedProducts => {

                dispatch(setPDPProducts({ productCode, products: normalizedProducts }));
                const selectedProduct = pickProduct(normalizedProducts, sku);
                if (selectedProduct.crossSell) {
                    dispatch(getCrossSellProducts(selectedProduct));
                }

                dispatch(productsFetching(false));
                return normalizedProducts;

            }).catch(() => dispatch(productsFetching(false)));
    };
};

export default handleActions(
    {
        [productsFetching]: (state, { payload }) => ({
            ...state,
            isFetching: payload,
        }),
        [crossSellFetching]: (state, { payload }) => ({
            ...state,
            isFetchingCrossSell: payload,
        }),
        [setPDPProducts]: (state, { payload: { productCode, products } }) => ({
            ...state,
            [productCode]: products,
            crossSellProducts: []
        }),
        [setPDPCrossSellProducts]: (state, { payload }) => {
            return {
                ...state,
                crossSellProducts: payload,
            };
        },
        [setPostAddRedirectUrl]: (state, { payload }) => ({
            ...state,
            postAddRedirectUrl: payload
        })
    },
    initialState
);

