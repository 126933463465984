// Link resolver for prismic preview
// https://prismic.io/docs/reactjs/beyond-the-api/link-resolving
import { ROUTES } from 'constants/routes';

export const linkResolver = (doc) => {
    switch (doc.type) {
        case 'service_offering':
        case 'new_services':
            return ROUTES.SERVICE_OFFER;
        case 'login_page':
            return ROUTES.LOGIN;
        case 'visit_atelier_2021':
            return ROUTES.VISIT_ATELIER;
        case 'contact':
            return ROUTES.CONTACT;
        case 'frequently_asked_questions':
            return ROUTES.HELP_FAQ;
        case 'registration_page':
            return ROUTES.REGISTER;
        case 'home_page':
        case 'footer':
        case 'header':
        case 'atelier':
            return ROUTES.HOME;
        case 'careers':
            return ROUTES.CAREERS;
        case 'legals_and_privacy':
            return ROUTES.LEGAL_STATEMENT;
        case 'events':
            return ROUTES.EVENTS;
        case 'map_content_page':
            return `${ROUTES.PROFILE}?tab=atelier`; // there's no shortcut to go to a map content page
        case 'gift_landing_2021':
            return ROUTES.GIFTS;
        case 'gifts_purchase':
            return ROUTES.GIFTS_PURCHASE;
        case 'booking':
            return ROUTES.APPOINTMENT_BOOKING;
        case 'membership_landing':
            return ROUTES.MEMBERSHIP_LANDING;
        case 'membership_terms':
            return ROUTES.MEMBERSHIP_TERMS;
        default:
            return ROUTES.HOME;
    }
};
