import styled from 'styled-components';
import { AccordionItem } from 'react-accessible-accordion';

const StyledAccordionItem = styled(AccordionItem)`

    .accordionArrow {
        display: inline-block;
        position: relative;
        width: 24px;
        height: 12px;
        position: absolute;
        top: 50%;
        right: ${({ right }) => right ? `${right}px` : '0'};
        margin-top: -6px;
        
            &:after,
            &:before {
                display: block;
                position: absolute;
                top: 50%;
                width: 10px;
                height: 2px;
                background-color: currentColor;
                content: '';
                transition: transform 0.25s ease, -webkit-transform 0.25s ease;
            }
            
            &:before {
                left: 4px;
                transform: rotate(45deg);
            }
            
            &:after {
                right: 4px;
                transform: rotate(-45deg);
            }
    }
    
    [aria-expanded='true'], 
    [aria-selected='true'] {
    
        .accordionArrow {
             &:before {
                left: 4px;
                transform: rotate(-45deg);
            }
            
            &:after {
                right: 4px;
                transform: rotate(45deg);
            }
        }
    }
    
`;

export default StyledAccordionItem;
