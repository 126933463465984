import React, { Component } from 'react';
import { Flex } from 'components/GlobalComponents/FlexBox/index';
import styled, { css } from 'styled-components';
import { styledProps, rem } from 'core/styled';
import PropTypes from 'prop-types';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';

const DayTimeSwatchWrapper = styled(Flex)`
    width: calc(100% - ${rem(40)});
    border-bottom: 2px solid ${styledProps('color', 'black')};
    padding: ${({ newBeautyPlan }) => rem(newBeautyPlan ? 0 : 25, 0, 10, 0)};
    margin: 0 auto ${rem(35)} auto;
    
    ${greaterThan(breakpoints.small)(css`
        width: calc(90% - ${rem(40)});
    `)};   
    
    ${greaterThan(breakpoints.large)(css`
        width: calc(50% - ${rem(40)});
    `)};
`;

const SwatchCheckboxContainer = styled.div`
    margin: 0 ${rem(35)};
`;
export default class SkincareTabs extends Component {
    static propTypes = {
        amTab: PropTypes.any,
        pmTab: PropTypes.any,
        borderTop: PropTypes.bool,
        newBeautyPlan: PropTypes.bool
    };

    state = {
        showAMRoutine: true,
        showPMRoutine: true,
        newBeautyPlan: false
    };

    /**
     * Toggle AM routine
     */
    toggleAMRoutine() {
        this.setState({
            showAMRoutine: !this.state.showAMRoutine
        });
    }

    /**
     * Toggle PM routine
     */
    togglePMRoutine() {
        this.setState({
            showPMRoutine: !this.state.showPMRoutine
        });
    }

    render() {
        const { showAMRoutine, showPMRoutine } = this.state;
        const { newBeautyPlan } = this.props;

        return (
            <div>
                <DayTimeSwatchWrapper justifyContent={'center'} newBeautyPlan={newBeautyPlan}>
                    <SwatchCheckboxContainer>
                        <SwatchCheckbox
                            name={SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_3}
                            text={'morning'}
                            value={'morning'}
                            onSelect={() => this.toggleAMRoutine()}
                            textTilt={-3}
                            isSelected={showAMRoutine} />
                    </SwatchCheckboxContainer>
                    <SwatchCheckboxContainer>
                        <SwatchCheckbox
                            name={SWATCH_CHECKBOX_NAMES.WHITE_SKINCARE_4}
                            text={'evening'}
                            value={'evening'}
                            textTilt={-3}
                            onSelect={() => this.togglePMRoutine()}
                            isSelected={showPMRoutine} />
                    </SwatchCheckboxContainer>
                </DayTimeSwatchWrapper>
                {showAMRoutine && this.props.amTab}
                {showPMRoutine && this.props.pmTab}
            </div>


        );
    }
}
