const sendPostMessage = message => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
};

const sendPaymentSuccess = (apptId, token) => {
    const message = {
        type: 'payment-success'
    };

    if (apptId && token) {
        message.apptId = apptId;
        message.token = token;
    }

    sendPostMessage(message);
};

const sendJoinedWaitlist = () => {
    const message = {
        type: 'joined-waitlist'
    };

    sendPostMessage(message);
};

const sendPaymentError = errorType => {
    const message = {
        type: 'payment-error',
        errorType
    };

    sendPostMessage(message);
};

const sendPaymentCancel = () => {
    const message = {
        type: 'payment-cancel'
    };

    sendPostMessage(message);
};

const sendFreePassNotification = () => {
    const message = {
        type: 'freepass-applied'
    };

    sendPostMessage(message);
};

const sendQuestionnaireError = errorType => {
    const message = {
        type: 'questionnaire-error',
        errorType
    };

    sendPostMessage(message);
};

const sendQuestionnaireCompleted = () => {
    const message = {
        type: 'questionnaire-completed'
    };

    sendPostMessage(message);
};

const sendNavigate = location => {
    const message = {
        type: 'navigate',
        to: location
    };

    sendPostMessage(message);
};

const sendCloseWebView = () => {
    const message = {
        type: 'close-webview'
    };

    sendPostMessage(message);
};

const reactNativeWebViewHelper = {
    sendPaymentSuccess,
    sendJoinedWaitlist,
    sendPaymentError,
    sendPaymentCancel,
    sendFreePassNotification,
    sendQuestionnaireError,
    sendQuestionnaireCompleted,
    sendNavigate,
    sendCloseWebView
};

export default reactNativeWebViewHelper;
