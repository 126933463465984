export const SEARCH = {
    ZONE: {
        perPage: 20,
        pageCount: 0
    },

    SEARCH: {
        perPage: 0,
        pageCount: 0
    },

    PDP: {
        perPage: 1,
        pageCount: 0
    },
    CHECKOUT: {
        perPage: 9,
        pageCount: 9
    },
    URL: {
        DEFAULT: `DEFAULT`,
        MGET: `MGET`
    }
};
