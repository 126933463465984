import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SmallHeader } from 'components/GlobalComponents/Fonts/Header';
import { rem, greaterThan } from 'core/styled';
import { breakpoints } from 'constants/theme';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import { STAFF_QUESTION_TYPE } from 'constants/beautyplan';
import SwatchCheckbox from 'components/GlobalComponents/SwatchCheckbox/SwatchCheckbox';
import { SWATCH_CHECKBOX_NAMES } from 'constants/swatchCheckbox/names';

const StyledHeader = styled(SmallHeader)`
    padding: 0 ${rem(20, 15, 20)};
    position: relative;
    font-size: ${rem(32)};

    ${greaterThan(breakpoints.large)(css`
        font-size: ${rem(50)}; 
    `)}; 
`;

const StaffQuestionsWrapper = styled(Flex)`
    width: calc(100% - ${rem(40)});
    margin: 0 auto ${rem(35)};
    border-bottom: ${rem(2)} solid black;
    
    ${greaterThan(breakpoints.small)(css`
        width: calc(90% - ${rem(40)});
    `)};   
    
    ${greaterThan(breakpoints.large)(css`
        width: calc(50% - ${rem(40)});
    `)};
`;

const SwatchCheckboxWrapper = styled(Flex)``;

export default class StaffQuestionView extends Component {
    static propTypes = {
        staffQuestions: PropTypes.arrayOf(
            PropTypes.shape({
                question: PropTypes.string.isRequired,
                answer: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                allOptions: PropTypes.arrayOf(PropTypes.string)
            })
        )
    };

    render() {
        const { staffQuestions } = this.props;

        if (staffQuestions && staffQuestions.length) {
            return (
                <StaffQuestionsWrapper flexDirection={'column'} alignItems={'center'}>
                    {staffQuestions.map((questionItem, key) => {
                        if (questionItem.type === STAFF_QUESTION_TYPE.RADIO) {
                            return (
                                <div key={key}>
                                    <StyledHeader>{questionItem.question}</StyledHeader>
                                    <SwatchCheckboxWrapper justifyContent={'space-around'}>
                                        {questionItem.allOptions.map((option, key) => {
                                            return (
                                                <SwatchCheckbox
                                                    key={key}
                                                    name={SWATCH_CHECKBOX_NAMES.BLACK_SKIN_ENHANCERS_3}
                                                    text={option}
                                                    value={option}
                                                    textTilt={-3}
                                                    isSelected={option === questionItem.answer}
                                                    disabled/>
                                            );
                                        })}
                                    </SwatchCheckboxWrapper>
                                </div>
                            );
                        }

                        return null;
                    })}
                </StaffQuestionsWrapper>
            );
        }

        return null;
    }
}
