/**
 * Resolves the value of the key found in the object,
 * if no key found resolves in defaultValue.
 * @param {object} obj
 * @param {string} key
 * @param {*} defaultValue
 * @return {*} resolved value
 */
export default function resolveKey(obj, key, defaultValue = undefined) {
    return obj[key] || defaultValue;
}
