import merge from 'core/utils/merge';
import { ANALYTICS } from 'reducers/analytics';

let prevAnalytics = {};

/**
 * Flush previous analytics properties
 * Based on https://nicolas.guelpa.me/blog/2017/07/30/google-tag-manager-react-redux.html
 * @param {object} analytics
 * @return {object} flushed analytics
 */
function flushAnalytics(analytics) {
    const flushedAnalytics = {
        ...Object.keys(prevAnalytics)
            .map(key => ({ [key]: undefined }))
            .reduce(merge, {}),
        ...analytics
    };
    prevAnalytics = analytics;
    return flushedAnalytics;
}

/**
 * GTM Analytics middleware
 * @return {function(next: function): function(action: object)} higher-order function
 */
const analytics = () => next => action => {
    if (action.type !== ANALYTICS) {
        return next(action);
    }

    let { payload } = action;

    // Flush previous analytics values
    payload = flushAnalytics(payload);

    // Push event to data layer
    window.dataLayer.push(payload);

    // Continue with other reducers
    next(action);
};

export default analytics;
