import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
    transition: opacity .2s ease-in;
    opacity:  ${({ loaded }) => loaded ? `1` : `0`};
`;

export default class LazyLoadImage extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string
    };

    state = {
        loaded: false
    };

    /**
     * On image load
     */
    onLoad = () => {
        this.setState({
            loaded: true
        });
    };

    render() {
        const { src, alt } = this.props;
        const { loaded } = this.state;

        return (
            <Image
                {...this.props}
                src={src}
                onLoad={this.onLoad}
                alt={alt}
                loaded={loaded} />
        );
    }
}
