import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_RESET } from 'middlewares/fetch';
import normalizeNotification from 'core/utils/normalizeNotification';

export const MY_NOTIFICATIONS = 'MY_NOTIFICATIONS';
const MY_NOTIFICATIONS_READY = 'MY_NOTIFICATIONS_READY';

export const myNotificationsReady = createAction(MY_NOTIFICATIONS_READY);

export const getMyNotifications = createAction(FETCH, externalId => ({
    prefix: MY_NOTIFICATIONS,
    endpoint: `${CONFIG.API_URL}/profiles/${externalId}/modules/notifications`
}));

export const fetchMyNotifications = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        return dispatch(getMyNotifications(externalId));
    };
};

const sendReview = createAction(FETCH, (request) => ({
    prefix: FETCH,
    endpoint: `${CONFIG.API_URL}/ratings`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

export const fetchNotifications = () => {
    return (dispatch, getState) => {
        return dispatch(fetchMyNotifications())
            .then(notifications => {
                const { makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();
                const allArtistData = [...artistData, ...residencyArtistData, ...virtualArtistData];

                return dispatch(myNotificationsReady(notifications.map(normalizeNotification(allArtistData)).filter(n => n)));
            });
    };
};

export const rateArtist = ({ rating, feedback, appointmentId, makeupArtist, appointmentType }) => {
    return (dispatch) => {
        const request = {
            stars: parseInt(rating, 10),
            appointmentId,
            review: feedback,
            makeupArtist: `${makeupArtist}`,
            appointmentType: `${appointmentType}`
        };

        return dispatch(sendReview(request));
    };
};

export const rateEvent = ({ rating, feedback, eventId, makeupArtist, eventName }) => {
    return (dispatch) => {
        const request = {
            stars: parseInt(rating, 10),
            eventId,
            review: feedback,
            makeupArtist: `${makeupArtist}`,
            appointmentType: `${eventName}`
        };

        return dispatch(sendReview(request));
    };
};

const initialState = {
    isFetching: false,
    error: null,
    response: null
};

export default handleActions({
    [`${MY_NOTIFICATIONS}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        ...initialState,
        isFetching: true
    }),
    [`${MY_NOTIFICATIONS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        ...initialState,
        error: payload
    }),
    [myNotificationsReady]: (state, { payload }) => ({
        ...state,
        ...initialState,
        response: payload
    }),
    [FETCH_RESET]: () => initialState
}, initialState);
