import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { lessThan, greaterThan, styledProps } from 'core/styled/index';
import { breakpoints } from 'constants/theme';
import { Flex, Box } from 'components/GlobalComponents/FlexBox/index';
import Text from 'components/GlobalComponents/Text/Text';
import Footer from 'components/Footer/Footer';
import PageMobileHeader from 'components/PageMobileHeader/PageMobileHeader';
import FormWrapper from 'components/GlobalComponents/FormWrapper/FormWrapper';

const MobileContainer = styled.div`
    padding: 0 20px 20px;
    width: 100%;
    min-height: 100%;

    ${greaterThan(breakpoints.medium)(css`
        padding: 0;
        margin: 20px;
        width: 340px;
        border: solid 3px ${styledProps('color', 'bone')};
    `)};
`;

const HeroBox = styled(Box)`
    background: ${styledProps('color', 'mineShaft')};
`;

const FlexForm = styled(Flex)`
    background: ${styledProps('color', 'white')};

    ${greaterThan(breakpoints.medium)(css`
        background: ${styledProps('color', 'white')};
    `)};
`;

const AbsoluteText = styled(Text)`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    text-align: center;
    position: absolute;
    top: 50%;
    text-transform: uppercase;
`;

const LeftAbsoluteText = styled(AbsoluteText)`
    color: ${styledProps('color', 'white')};
    transform: rotate(-90deg);
    left: -25px;
`;

const RightAbsoluteText = styled(AbsoluteText)`
    color: ${styledProps('color', 'bone')};
    transform: rotate(-270deg);
    right: -50px;
    ${lessThan(breakpoints.medium)(css`
        display: none;
    `)};
`;

export default class FormPageLayout extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired
    };

    toggleHamburger() {
        //TODO: won't used in the future, so  this is just a dummy method to prevent errors
    }

    render() {
        return (
            <Flex>
                <HeroBox width={0.5} display={{ initial: 'none', md: 'block' }}>
                    <Flex alignItems="center" justifyContent="center">
                        <img
                            src="https://placehold.it/300x300"
                            alt="Atelier Beauté CHANEL"
                        />
                        <LeftAbsoluteText>beauty at work</LeftAbsoluteText>
                    </Flex>
                </HeroBox>
                <Box
                    width={{ initial: 1, md: 0.5 }}
                    overflowY={{ initial: '', md: 'auto' }}
                >
                    <FlexForm
                        flexDirection={{ initial: 'column', md: 'row' }}
                        alignItems="center"
                        justifyContent={{
                            initial: 'space-between',
                            md: 'center'
                        }}
                    >
                        <MobileContainer>
                            <PageMobileHeader toggleHamburger={this.toggleHamburger}/>
                            <FormWrapper>{this.props.children}</FormWrapper>
                        </MobileContainer>
                        <RightAbsoluteText>
                            discover, define, declare
                        </RightAbsoluteText>
                        <Footer
                            address=''
                            chanelHomeUrl=''
                            links={[]}
                            logoUrl=''
                        />
                    </FlexForm>
                </Box>
            </Flex>
        );
    }
}
