import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import { rem, styledProps } from 'core/styled/index';
import Header from 'components/GlobalComponents/Fonts/Header';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import { connect } from 'react-redux';
import { addNotes } from 'reducers/beautyplan';
import { bindActionCreators } from 'redux';
import { PRISMIC_ARTIST_TYPE } from 'constants/prismic';

const SubSectionHeaderOuter = styled(Flex)`
  width: 100%;
  padding-top: ${rem(20)};
`;

const StyledHeader = styled(Header)`
  font-family: ${styledProps('font', 'ABChanelPBM')};
  font-weight: bold;
  padding: 0 ${rem(20, 20, 20)};
  font-size: ${rem(16)};
  line-height: 1.3;
`;

const NotesContainer = styled(Box)`
    width: 80%;
    margin: auto;
`;

const StyledFormInput = styled(FormInput)`
  padding-bottom: ${rem(10)};
`;


class NewTextSection extends Component {

    static propTypes = {
        section: PropTypes.object.isRequired,
        sectionId: PropTypes.string.isRequired,
        addNotes: PropTypes.func.isRequired,
        note: PropTypes.string,
        lastName: PropTypes.string.isRequired,
        artists: PropTypes.array.isRequired
    };

    /**
     * Event handler on Blur to save field value
     * @param {object} e
     */
    onBlur = (e) => {
        const { sectionId } = this.props;
        const note = e.target.innerHTML;

        this.props.addNotes({ section: sectionId, note });
    };

    renderTitle = () => {
        const { section: { titleArtistType, title }, artists, lastName } = this.props;

        if (!titleArtistType) {
            return title;
        }

        const artist = artists.find(a => a.name.toLowerCase().includes(lastName.toLowerCase()));

        if (artist) {
            const artistType = artist.artistType === PRISMIC_ARTIST_TYPE.MASTER_ARTIST ? 'master artist' : 'beauty guide';

            return `${title}<br/>${artistType}`;
        }

        return `${title}<br/>beauty guide`;
    };

    render() {

        const { note } = this.props;

        return (
            <div>
                <SubSectionHeaderOuter justifyContent='center' alignItems='center'>
                    <StyledHeader dangerouslySetInnerHTML={{ __html: this.renderTitle() }}/>
                </SubSectionHeaderOuter>
                <NotesContainer>
                    <StyledFormInput
                        id='notes'
                        placeholder='Type any additional notes'
                        inputfontsize={14}
                        clearOption={true}
                        label='notes'
                        onBlur={this.onBlur}
                        value={note}
                        multiLine
                        hideLabelOnFocus
                    />
                </NotesContainer>
            </div>
        );
    }

}

const mapStateToProps =  ({ beautyplan: { currentPlan: { notes } }, user: { auth: { staffProfile: { lastName } = {} } }, makeupArtists: { artistData, residencyArtistData, virtualArtistData } }, { sectionId }) => {
    let note = '';
    if (notes) {
        const noteInSection = notes.find(n => n.section === sectionId);
        note = noteInSection ? noteInSection.note : '';
    }

    return {
        note,
        lastName,
        artists: [...artistData, ...residencyArtistData, ...virtualArtistData],
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { addNotes },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NewTextSection);

