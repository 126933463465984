import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import PrimaryModal from 'components/GlobalComponents/Modal/PrimaryModal';
import Button from 'components/GlobalComponents/Button/Button';
import styled from 'styled-components';
import Description from 'components/GlobalComponents/Fonts/Description';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import Header from 'components/GlobalComponents/Fonts/Header';

const ModalContentBodyWrapper = styled.div`
    text-align: center;
`;

export default class EnvelopeConfirmationModal extends Component {

    static propTypes = {
        date: PropTypes.string,
        number: PropTypes.string,
        handleConfirm: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    state = {
        isModalVisible: false
    };

    /**
     * Toggle for search modal display state
     */
    toggleModal() {
        this.setState({
            isModalVisible: !this.state.isModalVisible,
        });
    }

    _cancelEnvelope = () => {
        this.toggleModal();
    };

    _confirmEnvelope = () => {
        this.props.handleConfirm();
    };

    render() {
        const { isModalVisible } = this.state;
        const { date, number, isLoading } = this.props;

        if (!isModalVisible) {
            return null;
        }

        return (
            <PrimaryModal
                onRequestClose={() => this._cancelEnvelope()}
                isDialogue
                showClose
                ariaDescribedby={'modal-confirm-envelope-title'}
            >
                <ModalContentBodyWrapper>
                    <Header marginTop={6} marginBottom={10}>Envelope Confirmation</Header>
                    <Description as={'p'} lineHeight={1.5} fontSize={14} padding={[20, 5, 30, 5]}>
                        Please confirm the following envelope:<br/>
                        Date: {moment(date).format('MMMM Do, YYYY')}<br/>
                        Number: {number}
                    </Description>
                    <Flex justifyContent={'center'} flexDirection={'column'} alignItems='center' fullHeight={false}>
                        <Button onClick={this._confirmEnvelope} loading={isLoading}>Confirm Envelope</Button>
                        <Button variant={'simpleBlack'} onClick={() => this._cancelEnvelope()}>Cancel Envelope</Button>
                    </Flex>
                </ModalContentBodyWrapper>
            </PrimaryModal>
        );
    }
}
