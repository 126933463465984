import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { analyticsTag } from 'reducers/analytics';
import { LAYER } from 'constants/layers';
import { breakpoints } from 'constants/theme';
import { eCommProductClick } from 'constants/analytics';
import { BADGE_IMAGE, BADGE_ALT } from 'constants/badges';
import { LOCATIONS } from 'constants/locations';
import { isGreaterThan, rem, styledProps } from 'core/styled';
import { greaterThan } from 'core/styled/index';
import { setNewBadgeType } from 'core/utils/normalizeProduct';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import TiltedText from 'components/GlobalComponents/TiltedText/TiltedText';
import ProductVariantSelector from 'components/ProductComponents/ProductVariantSelector/ProductVariantSelector';
import { SmallHeader } from 'components/GlobalComponents/Fonts/Header';
import ProductSubTitle from 'components/GlobalComponents/Fonts/product/ProductSubTitle';
import LikeListButtons from 'components/ProductComponents/LikeListButtons/LikeListButtons';
import LazyLoadImage from 'components/GlobalComponents/LazyLoadImage/LazyLoadImage';
import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import PriceTag from 'components/GlobalComponents/Fonts/product/PriceTag';
import CloseIcon from 'components/GlobalComponents/Modal/CloseIcon';
import Button from 'components/GlobalComponents/Button/Button';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';

const ProductCardBoxWrapper = styled(Flex)`
    order: ${({ isEven }) => (isEven ? '2' : '0')};
    text-align: ${({ isEven }) => (isEven ? 'right' : 'left')};
    flex-direction: ${({ isEven }) => (isEven ? 'row' : 'row-reverse')};
    align-content: flex-end;
    position: relative;
`;

const ProductName = styled(SmallHeader)`
    margin: 0;
    line-height: 1;
    text-align: ${({ isEven }) => (isEven ? 'right' : 'left')};
    cursor: pointer;
`;

const ShadeImage = styled.img`
    max-width: 80px;
    height: auto;
    transform: ${({ isEven }) => (isEven ? 'none' : 'scaleX(-1)')};
`;

const DefaultShadeBadgeWrapper = styled(Flex)`
    flex-direction: ${({ isEven }) => (isEven ? 'row-reverse' : 'row')};
    justify-content: flex-start;
    align-items: center;
    height: ${rem(80)};
`;

const PriceTagStyled = styled(PriceTag).attrs({
    as: 'p'
})`
    text-align: ${({ isEven }) => (isEven ? 'right' : 'left')};
    margin-top: ${rem(5)};
    ${greaterThan(breakpoints.small)(css`
        font-size: ${rem(12)};
    `)};
`;

const ProductThumbnailWrapper = styled(Flex)`
    position: relative;
    width: 40%;
    align-self: flex-end;
    ${({ addPadding }) => (addPadding ? `padding: ${rem(18)};` : null)}

    ${greaterThan(breakpoints.small)(css`
        width: 50%;
    `)}
`;

const ProductThumbnail = styled(LazyLoadImage)`
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: ${rem(10)};
    cursor: pointer;
`;

const ShadesAvailableText = styled(ChromosomeTitle)`
    margin-top: ${rem(15)};
    text-align: ${({ isEven }) => (isEven ? 'right' : 'left')};
`;

const LikeListButtonsWrapper = styled.div`
    width: 100%;
    order: 3;
    border-bottom: 1px solid ${styledProps('color', 'black')};
    ${greaterThan(breakpoints.medium)(css`
        border-bottom: none;
    `)}
`;

const StyledButton = styled(Button)`
    background-color: black;
    color: white;
    border: none;
    margin: ${rem(40, 'auto')};
`;

const AddProductContainer = styled(Box)`
    margin: auto;
    text-align: center;
    width: 100%;
    border-bottom: 2px solid black;
`;

const ProductInfoBox = styled(Box)`
    z-index: ${LAYER.BODY};
    margin-bottom: ${rem(15)};
    align-self: center;
    margin-top: ${({ addTopMargin }) => (addTopMargin ? rem(25) : '0')};

    ${greaterThan(breakpoints.small)(css`
        width: 50%;
    `)}
`;

const ProductBadge = styled.img`
    width: 64px;
    height: auto;
    ${({ isEven, addMargin }) =>
        addMargin
            ? isEven
                ? `margin-right: ${rem(80)};`
                : `margin-left: ${rem(80)};`
            : ''}
`;

const ClickableProduct = styled(NoStyleButton)`
    cursor: pointer;
    text-align: inherit;
`;

class ProductCard extends Component {
    static propTypes = {
        products: PropTypes.array.isRequired,
        push: PropTypes.func.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        browseAs: PropTypes.func,
        isEven: PropTypes.bool,
        overridePDPRedirect: PropTypes.bool,
        renderLikeListButtons: PropTypes.bool,
        renderProductRemoveButton: PropTypes.bool,
        renderVTOButton: PropTypes.bool,
        onProductSelect: PropTypes.func,
        showVariant: PropTypes.bool,
        withShades: PropTypes.bool,
        preventOrderChange: PropTypes.bool,
        showFavouriteButton: PropTypes.bool,
        favouriteMeetingId: PropTypes.string,
        isFragranceFlight: PropTypes.bool,
        cartLocation: PropTypes.number
    };

    static defaultProps = {
        browseAs: () => {},
        showVariant: true,
        withShades: true,
        showFavouriteButton: false,
        renderVTOButton: false,
        isFragranceFlight: false
    };

    state = {
        products: this.props.products,
        product: this.props.products[0] // preselect first product as default shade
    };

    static getDerivedStateFromProps({ products }) {
        if (products && products.length > 0) {
            return { products };
        }
        return null;
    }

    /**
     * On variant selection, update currently selected variant
     * @param {string} sku
     */
    handleVariantSelection = sku => {
        const { products } = this.state;

        this.setState({
            product: products.find(product => product.sku === sku)
        });
    };

    /**
     * Product page switch or pass back clicked product
     * @param {object} product - product data
     */
    handleProductClick(product) {
        const {
            overridePDPRedirect,
            renderLikeListButtons,
            onProductSelect,
            analyticsTag
        } = this.props;

        analyticsTag(eCommProductClick(product), { userInfo: true });

        if (overridePDPRedirect) {
            onProductSelect(product, renderLikeListButtons);
        }
    }

    render() {
        const { product, products } = this.state;
        const {
            renderProductRemoveButton,
            renderLikeListButtons,
            onProductSelect,
            isEven,
            browseAs,
            showVariant,
            withShades,
            preventOrderChange,
            showFavouriteButton,
            favouriteMeetingId,
            renderVTOButton,
            isFragranceFlight,
            cartLocation,
            overridePDPRedirect
        } = this.props;
        const switchOrder = preventOrderChange ? !preventOrderChange : isEven;
        if (!product) {
            return null;
        }

        const badgeType = setNewBadgeType(product, products);
        const badge = BADGE_IMAGE[badgeType];

        const isLowStock =
            cartLocation === LOCATIONS.AUSTIN
                ? product.isLowStock2
                : product.isLowStock;

        return (
            <ProductCardBoxWrapper isEven={switchOrder} flexWrap={'wrap'}>
                <ProductInfoBox
                    width={0.6}
                    isEven={switchOrder}
                    addTopMargin={product.badge && products.length === 1}
                >
                    <ClickableProduct
                        as={!overridePDPRedirect ? Link : undefined}
                        to={
                            !overridePDPRedirect
                                ? `${ROUTES.PRODUCT_DESCRIPTION}/${product.productCode}/${product.sku}`
                                : undefined
                        }
                        onClick={() => this.handleProductClick(product)}
                    >
                        {(product.texture.exists || badge) && (
                            <DefaultShadeBadgeWrapper isEven={switchOrder}>
                                {product.texture.exists && (
                                    <ShadeImage
                                        key={`shade-${moment().valueOf()}`}
                                        isEven={switchOrder}
                                        src={product.texture.small()}
                                        alt=""
                                    />
                                )}
                                {badge && (
                                    <ProductBadge
                                        src={badge}
                                        isEven={switchOrder}
                                        addMargin={!product.texture.exists}
                                        role={'img'}
                                        alt={BADGE_ALT[badgeType]}
                                    />
                                )}
                            </DefaultShadeBadgeWrapper>
                        )}

                        {/* {isFragranceFlight && product.fragranceNumber && <FragranceNumber>#{product.fragranceNumber}</FragranceNumber>} */}
                        {isFragranceFlight && product.fragranceNumber && (
                            <TiltedText
                                rotate={switchOrder ? -3 : 3}
                                fontSize={
                                    isGreaterThan(breakpoints.small) ? 16 : 14
                                }
                                fontWeight={900}
                                textPadding={[0, 8]}
                                lineHeight={1}
                                asParagraph={true}
                            >
                                #{product.fragranceNumber}
                            </TiltedText>
                        )}
                        <ProductName
                            fontSize={
                                isGreaterThan(breakpoints.small) ? 30 : 26
                            }
                            isEven={switchOrder}
                        >
                            <span translate={'no'} className={'notranslate'}>
                                {product.name}
                            </span>
                        </ProductName>
                        <ProductSubTitle
                            fontSize={
                                isGreaterThan(breakpoints.small) ? 12 : 10
                            }
                            dangerouslySetInnerHTML={{
                                __html: product.subName
                            }}
                        />
                        {product.variantInfo && (
                            <TiltedText
                                rotate={switchOrder ? -3 : 3}
                                fontSize={
                                    isGreaterThan(breakpoints.small) ? 14 : 12
                                }
                                fontWeight={900}
                                textPadding={[0, 8]}
                                lineHeight={1}
                                asParagraph={true}
                            >
                                {product.variantInfo}
                            </TiltedText>
                        )}
                        <PriceTagStyled isEven={switchOrder}>
                            {' '}
                            ${product.price}{' '}
                        </PriceTagStyled>
                    </ClickableProduct>
                    {showVariant && products.length > 1 && (
                        <ShadesAvailableText isEven={switchOrder}>
                            <ProductVariantSelector
                                products={products}
                                selectedSku={product.sku}
                                selectSku={this.handleVariantSelection}
                            />
                        </ShadesAvailableText>
                    )}
                </ProductInfoBox>

                {product.packshot.exists && (
                    <ProductThumbnailWrapper
                        width={0.4}
                        position="relative"
                        alignItems="center"
                        justifyContent="center"
                        className={isLowStock ? 'low-stock' : ''}
                        addPadding={product.shouldAddImagePadding}
                    >
                        <ClickableProduct
                            as={!overridePDPRedirect ? Link : undefined}
                            to={
                                !overridePDPRedirect
                                    ? `${ROUTES.PRODUCT_DESCRIPTION}/${product.productCode}/${product.sku}`
                                    : undefined
                            }
                            onClick={() => this.handleProductClick(product)}
                            tabIndex={-1}
                        >
                            <ProductThumbnail
                                key={`packshot-${moment().valueOf()}`}
                                src={
                                    isFragranceFlight && product.fragranceImage
                                        ? product.fragranceImage
                                        : product.packshot.medium()
                                }
                                alt={''}
                                role={'img'}
                            />
                        </ClickableProduct>
                    </ProductThumbnailWrapper>
                )}
                {renderLikeListButtons && (
                    <LikeListButtonsWrapper>
                        <LikeListButtons
                            product={product}
                            browseAs={browseAs}
                            iconPadding={[10, 15, 20]}
                            renderDislikeButton={false}
                            showFavouriteButton={showFavouriteButton}
                            favouriteMeetingId={favouriteMeetingId}
                            renderVTOButton={renderVTOButton}
                        />
                    </LikeListButtonsWrapper>
                )}
                {renderProductRemoveButton && (
                    <CloseIcon
                        onClick={() =>
                            onProductSelect(product, renderLikeListButtons)
                        }
                    />
                )}
                {!withShades && (
                    <AddProductContainer>
                        <StyledButton
                            onClick={() => this.handleProductClick(product)}
                        >
                            {' '}
                            ADD PRODUCT{' '}
                        </StyledButton>
                    </AddProductContainer>
                )}
            </ProductCardBoxWrapper>
        );
    }
}

const mapStateToProps = ({
    user: { auth },
    router: { location },
    cart: { cartLocation }
}) => ({
    ...auth,
    location,
    cartLocation
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ push, analyticsTag }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
