import React, { Component } from 'react';
import Header from 'components/GlobalComponents/Fonts/Header';
import Button from 'components/GlobalComponents/Button/Button';
import PropTypes from 'prop-types';
import { styledProps, rem } from 'core/styled/index';
import styled from 'styled-components';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    addSelectedProduct,
    removeSelectedProduct,
    addSubsectionNote,
    removeSubsectionNote,
    updateProductNote,
    updateProductBatchCode,
    removeProductBatchCode,
    addSelectedEvent,
    removeSelectedEvent,
    updateEventNote,
    addSelectedService,
    removeSelectedService,
    updateServiceNote,
    removeProductInKit,
    addProductInKit
} from 'reducers/beautyplan';
import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import ProductDropdown from 'components/BeautyPlans/Staff/ProductDropdown';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';
import { BEAUTYPLAN_SEARCH_TYPE, NON_CHANEL, SECTION_ID, SECTION_ANALYTICS, BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS, BEAUTYPLAN_SKINCARE_TYPE } from 'constants/beautyplan';
import { setIsHeaderSearchOpen } from 'reducers/globalLayout';
import SimpleBeautyplanProductCard from './SimpleBeautyplanProductCard';
import SimpleSubSectionProductModal from './SimpleSubSectionProductModal';
import SimpleSubSectionEventModal from './SimpleSubSectionEventModal';
import SimpleSubSectionServiceModal from './SimpleSubSectionServiceModal';
import SimpleBeautyPlanServiceList from 'components/BeautyPlans/Common/SimpleBeautyPlanServiceList';
import SimpleBeautyPlanEventList from 'components/BeautyPlans/Common/SimpleBeautyPlanEventList';

const SubSectionHeaderOuter = styled(Flex)`
    width: 100%;
    padding-top: ${rem(20)};
`;

const SubSectionContainer = styled(Flex)`
    text-align: center;
    width: 100%;
`;

const StyledHeader = styled(Header)`
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-weight: 400;
    padding: 0 ${rem(20, 15, 20)};
    position: relative;
    font-size: ${rem(18)};
`;

const StyledPlusIcon = styled(Box)`
  margin-top: ${rem(20)};
`;

const AddProductButtonWrapper = styled(Flex)`
    width: 80%;
`;

const StyledHr = styled.hr`
    width: 100%;
    margin-top: ${rem(30)};
`;

const SubSectionNoteContainer = styled(Flex)`
    width: 100%;
`;

const SubSectionNoteHeader = styled.p`
    text-align: center;
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${rem(12)};
`;

const NotesContainer = styled(Box)`
    width: 100%;
    max-width: ${rem(400)};
    margin: auto;
    text-align: left;
`;

const StyledFormInput = styled(FormInput)`
    padding-bottom: ${rem(10)};
`;

const StyledNonChanelInput = styled(FormInput)`
    width: ${rem(120)} !important;
`;

const StyledFormInputWrapper = styled.div`
    margin: ${rem(20, 0, 30)};
    text-align: left;
    border: ${rem(1)} solid black;
    min-height: ${rem(120)};

    > div {
        margin-top: ${rem(-15)};
    }
`;

const ProductButton = styled(Button)`
    width: ${rem(190)};
    ${({ cssMargin }) => cssMargin ? `margin: ${rem(...cssMargin)};` : null }
`;

class SimpleSubSection extends Component {

    static propTypes = {
        subSection: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        category: PropTypes.object.isRequired,
        addSelectedProduct: PropTypes.func.isRequired,
        removeSelectedProduct: PropTypes.func.isRequired,
        addSubsectionNote: PropTypes.func.isRequired,
        removeSubsectionNote: PropTypes.func.isRequired,
        products: PropTypes.array.isRequired,
        productsInCategory: PropTypes.array.isRequired,
        subSectionNotes: PropTypes.array,
        isFetching: PropTypes.bool.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        currentPlan: PropTypes.object.isRequired,
        first: PropTypes.bool,
        last: PropTypes.bool,
        autocompleteResults: PropTypes.array.isRequired,
        resetAutocompleteResults: PropTypes.func.isRequired,
        setIsHeaderSearchOpen: PropTypes.func.isRequired,
        updateProductNote: PropTypes.func.isRequired,
        updateProductBatchCode: PropTypes.func.isRequired,
        removeProductBatchCode: PropTypes.func.isRequired,
        services: PropTypes.array.isRequired,
        events: PropTypes.array.isRequired,
        addSelectedEvent: PropTypes.func.isRequired,
        removeSelectedEvent: PropTypes.func.isRequired,
        updateServiceNote: PropTypes.func.isRequired,
        updateEventNote: PropTypes.func.isRequired,
        removeSelectedService: PropTypes.func.isRequired,
        addSelectedService: PropTypes.func.isRequired,
        addProductInKit: PropTypes.func.isRequired,
        removeProductInKit: PropTypes.func.isRequired
    };

    static defaultProps = {
        first: false
    };

    state = {
        nonChanelNote: ''
    };

    getTimeOfDayFromCategoryId = categoryId => {
        let timeOfDay = null;

        if (categoryId === BEAUTYPLAN_SKINCARE_TYPE.AM) {
            timeOfDay = 'morning';
        } else if (categoryId === BEAUTYPLAN_SKINCARE_TYPE.PM) {
            timeOfDay = 'evening';
        }

        return timeOfDay;
    };

    analyticsAddRemoveProduct = (product, isAdd = false, categoryId) => {
        const { analyticsTag, currentPlan: { service }, subSection: { id: subSectionId }, id: sectionId } = this.props;
        let eventLabel = '';

        const action = isAdd ? 'add' : 'remove';
        if (sectionId === SECTION_ID.TRIAL) {
            eventLabel = `${action}-${product.sku}`;
        } else if (sectionId === SECTION_ID.MAKEUP) {
            eventLabel = `${subSectionId}-${action}-${product.sku}`;
        } else if (sectionId === SECTION_ID.SKINCARE) {
            const timeOfDay = this.getTimeOfDayFromCategoryId(categoryId);
            eventLabel = `${timeOfDay ? `${timeOfDay}-` : ''}${subSectionId}-${action}-${product.sku}`;
        } else if (product.sku.startsWith('a') || product.sku.startsWith('e')) {
            // services and events
            eventLabel = `${action}-${product.sku}`;
        }

        analyticsTag({
            event: EVENTS.GA,
            eventCategory: service.analyticsCategory,
            eventAction: SECTION_ANALYTICS[sectionId],
            eventLabel
        }, { userInfo: true });
    };


    constructor(props) {
        super(props);

        this.state = {
            showEventsModal: false,
            showServicesModal: false,
            search: '',
            showProducts: false,
            showNonChanelProduct: this.productsInSection(true).length > 0
        };

        this.productModal = React.createRef();
        this.eventModal = React.createRef();
        this.serviceModal = React.createRef();
    }

    /**
     * This function shows the products modal when search.
     * @return {void} shows the product search modal
     */
    showProductsModal = () => {
        this.props.setIsHeaderSearchOpen(false);
        this.productModal.current.toggleModal();
    };

    /**
     * This function shows the products modal when search.
     * @return {void} shows the product search modal
     */
    showEventsModal = () => {
        this.eventModal.current.toggleModal();
    };

    /**
     * This function shows the products modal when search.
     * @return {void} shows the product search modal
     */
    showServicesModal = () => {
        this.serviceModal.current.toggleModal();
    };

    /**
     * Index or product in the store
     * @return {object} product index
     */
    getSectionIndex = () => {
        const { subSection: { id: sectionId }, category: { id: categoryId }, id } = this.props;
        return { id, sectionId, categoryId };
    };

    /**
     * Products added to current section
     * @param {boolean} nonChanel - if true, returns non chanel product in section, if false, filter them out
     * @return {array} products
     */
    productsInSection = (nonChanel = false) => {
        const index = this.getSectionIndex();
        return this.props.items.filter(i => i.id === index.id && i.sectionId === index.sectionId)
            .map(i => {
                return { ...i.product, note: i.note, batchCode: i.batchCode, inKit: i.inKit, ...{ categoryId: i.categoryId } };
            }).filter(p => nonChanel ? p.sku.startsWith(NON_CHANEL) : !p.sku.startsWith(NON_CHANEL));
    };

    /**
     * Events added to current section
     * @return {array} events
     */
    eventsInSection = () => {
        const index = this.getSectionIndex();
        return this.props.events.filter(i => i.id === index.id && i.sectionId === index.sectionId);
        // .map(i => {
        //     return { ...i.product, note: i.note, batchCode: i.batchCode, ...{ categoryId: i.categoryId } };
        // }).filter(p => nonChanel ? p.sku.startsWith(NON_CHANEL) : !p.sku.startsWith(NON_CHANEL));
    };

    /**
     * Services added to current section
     * @return {array} services
     */
    servicesInSection = () => {
        const index = this.getSectionIndex();
        return this.props.services.filter(i => i.id === index.id && i.sectionId === index.sectionId);
        // .map(i => {
        //     return { ...i.product, note: i.note, batchCode: i.batchCode, ...{ categoryId: i.categoryId } };
        // }).filter(p => nonChanel ? p.sku.startsWith(NON_CHANEL) : !p.sku.startsWith(NON_CHANEL));
    };

    /**
     * Add product to the selectedProducts
     * Products can now be added/removed across sections, so need an override
     * @param {Object} product - selected product
     * @param {object} sectionIndexOverride
     */
    addProduct = (product, sectionIndexOverride) => {
        const sectionIndex = sectionIndexOverride ? sectionIndexOverride : this.getSectionIndex();
        this.props.addSelectedProduct({ product, note: product.note, ...sectionIndex });

        this.analyticsAddRemoveProduct(product, true, sectionIndex.categoryId);
    };

    /**
     * Remove product
     * Products can now be added/removed across sections, so need an override
     * @param {object} product
     * @param {object} sectionIndexOverride
     */
    removeProduct = (product, sectionIndexOverride) => {
        const sectionIndex = sectionIndexOverride ? sectionIndexOverride : this.getSectionIndex();

        const { removeSelectedProduct } = this.props;

        this.analyticsAddRemoveProduct(product, false, sectionIndex.categoryId);
        removeSelectedProduct({ product, note: product.note, ...sectionIndex });
    };

    addEvent = (event) => {
        const sectionIndex = this.getSectionIndex();

        this.analyticsAddRemoveProduct({ sku: `e${event.detailId}` } , true, sectionIndex.categoryId);
        this.props.addSelectedEvent({ event, ...sectionIndex });
    };

    removeEvent = (event) => {
        const sectionIndex = this.getSectionIndex();

        this.analyticsAddRemoveProduct({ sku: `e${event.detailId}` } , false, sectionIndex.categoryId);
        this.props.removeSelectedEvent(event);
    };

    addService = (service) => {
        const sectionIndex = this.getSectionIndex();

        this.analyticsAddRemoveProduct({ sku: `a${service.serviceId}` } , true, sectionIndex.categoryId);
        this.props.addSelectedService({ service, ...sectionIndex });
    };

    removeService = (service) => {
        const sectionIndex = this.getSectionIndex();

        this.analyticsAddRemoveProduct({ sku: `a${service.serviceId}` } , false, sectionIndex.categoryId);
        this.props.removeSelectedService(service);
    };

    /**
     * Render producst for given section
     * @returns {Component} - Beauty plan product card
     */
    renderSelectedProducts() {
        const { currentPlan, items, id, subSection, updateProductNote, updateProductBatchCode, removeProductBatchCode, addProductInKit, removeProductInKit } = this.props;

        const currentPlanTrialKit = currentPlan.service.steps[0];
        const planHasTrialKit = currentPlanTrialKit === BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5;
        const trialKitProductLimit = planHasTrialKit ? BEAUTYPLAN_CONFIG[currentPlanTrialKit].section.limit : 0;
        const trialKitProducts = items.filter(item => item.id === SECTION_ID.TRIAL).map(section => section.product);

        return (
            <SimpleBeautyplanProductCard
                products={this.productsInSection()}
                nonChanelProducts={this.productsInSection(true)}
                showNonChanelProduct={this.state.showNonChanelProduct}
                onProductRemove={this.removeProduct}
                onProductAdd={this.addProduct}
                trialKitProducts={trialKitProducts}
                trialKitLimit={trialKitProductLimit}
                categoryId={id}
                sectionIndex={this.getSectionIndex()}
                allowNonChanelNote={subSection.allowNonChanelNote}
                productNote={subSection.productNote}
                updateProductNote={updateProductNote}
                updateProductBatchCode={updateProductBatchCode}
                removeProductBatchCode={removeProductBatchCode}
                isFragranceFlight={subSection.isFragranceFlight}
                showInKit={subSection.inKitOption}
                showBatch={subSection.sampleNumber}
                addProductInKit={addProductInKit}
                removeProductInKit={removeProductInKit}
            />
        );
    }

    renderSelectedEvents = () => {
        const { events, subSection, updateEventNote } = this.props;

        return (
            <SimpleBeautyPlanEventList
                events={events}
                removeEvent={this.removeEvent}
                productNote={subSection.productNote}
                updateEventNote={updateEventNote}
            />
        );
    };

    renderSelectedServices = () => {
        const { services, subSection, updateServiceNote } = this.props;

        return (
            <SimpleBeautyPlanServiceList
                services={services}
                removeService={this.removeService}
                productNote={subSection.productNote}
                updateServiceNote={updateServiceNote}
            />
        );
    };

    renderProductPicker = () => {
        const { type, limit } = this.props.subSection;

        if (type === BEAUTYPLAN_SEARCH_TYPE.SEARCH || type === BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE) {

            // do not display the + if limit has been reached
            if (limit && this.productsInSection().length === limit) {
                return null;
            }

            return (
                <div>
                    <ProductButton variant={'primary'} type={'button'} small onClick={() => this.showProductsModal()}>Add products</ProductButton>
                    <SimpleSubSectionProductModal
                        ref={this.productModal}
                        getSectionIndex={this.getSectionIndex}
                        analyticsAddRemoveProduct={this.analyticsAddRemoveProduct}
                        subSection={this.props.subSection}
                        addProduct={this.addProduct}
                    />
                </div>
            );
        } else if (type === BEAUTYPLAN_SEARCH_TYPE.EVENT) {
            // do not display the + if limit has been reached
            if (limit && this.eventsInSection().length === limit) {
                return null;
            }

            return (
                <div>
                    <Button variant={'primary'} type={'button'} small onClick={() => this.showEventsModal()}>Add events</Button>
                    <SimpleSubSectionEventModal
                        ref={this.eventModal}
                        getSectionIndex={this.getSectionIndex}
                        addEvent={this.addEvent}
                    />
                </div>
            );
        } else if (type === BEAUTYPLAN_SEARCH_TYPE.SERVICE) {
            // do not display the + if limit has been reached
            if (limit && this.servicesInSection().length === limit) {
                return null;
            }

            return (
                <div>
                    <Button variant={'primary'} type={'button'} small onClick={() => this.showServicesModal()}>Add services</Button>
                    <SimpleSubSectionServiceModal
                        ref={this.serviceModal}
                        getSectionIndex={this.getSectionIndex}
                        addService={this.addService}
                    />
                </div>
            );
        } else if (type === BEAUTYPLAN_SEARCH_TYPE.LIST) {
            const { productsInCategory } = this.props;
            return (
                <div>
                    <ProductDropdown addProduct={this.addProduct} products={productsInCategory}/>
                </div>
            );
        }

        return null;

    };

    renderNonChanelProductCheckbox = () => {
        return (
            <ProductButton
                variant={'primary'}
                type={'button'}
                small
                onClick={() => this.toggleShowNonChanelProduct()}
                cssMargin={[10, 0, 0]}
            >
                Add a non Chanel product
            </ProductButton>
        );
    };

    renderNonChanelProductNote = () => {
        const { nonChanelNote } = this.state;
        const { subSection: { title } } = this.props;

        return (
            <StyledPlusIcon>
                <Icon type={ICON_TYPES.PLUS} pixelHeight={32} onClick={() => {
                    this.addShowNonChanelProduct(nonChanelNote);
                    this.setState({ nonChanelNote: '' });
                }}/>
                <ChromosomeTitle fontSize={12} display={'block'}>
                    add a non<br/>chanel product
                </ChromosomeTitle>
                <StyledNonChanelInput
                    id={`non-chanel-input_${title}`}
                    placeholder={'Product'}
                    value={nonChanelNote}
                    onChange={e => {
                        this.setState({ nonChanelNote: e.target.value });
                    }}/>
            </StyledPlusIcon>
        );
    };

    addShowNonChanelProduct = note => {
        const { addSelectedProduct } = this.props;

        if (note) {
            const customSku = `${NON_CHANEL}_${note.replace(/\s/g, '')}`;

            addSelectedProduct({
                product: { sku: customSku },
                note,
                ...this.getSectionIndex()
            });
            this.analyticsAddRemoveProduct({ sku: 'nonchanel' }, true);
        }
    };

    toggleShowNonChanelProduct() {
        const { removeSelectedProduct, addSelectedProduct } = this.props;
        // remove non-chanel product
        if (this.state.showNonChanelProduct) {
            removeSelectedProduct({ product: { sku: NON_CHANEL }, ...this.getSectionIndex() });
            this.analyticsAddRemoveProduct({ sku: 'nonchanel' });
        } else { // add non-chanel product
            addSelectedProduct({ product: { sku: NON_CHANEL }, ...this.getSectionIndex() });
            this.analyticsAddRemoveProduct({ sku: 'nonchanel' }, true);
        }

        this.setState({
            showNonChanelProduct: !this.state.showNonChanelProduct
        });
    }

    noteOnBlur = (e) => {
        const note = e.target.innerHTML;

        if (note) {
            this.props.addSubsectionNote({ note: note, ...this.getSectionIndex() });
        } else {
            this.noteOnClear();
        }
    };

    noteOnClear = () => {
        this.props.removeSubsectionNote(this.getSectionIndex());
    };

    renderSubSectionNote = () => {
        const { subSection: { title }, subSectionNotes } = this.props;

        const savedValue = subSectionNotes.find(n => n.id === this.getSectionIndex().id && n.categoryId === this.getSectionIndex().categoryId && n.sectionId === this.getSectionIndex().sectionId);
        const value = savedValue ? savedValue.note : '';

        return (
            <SubSectionNoteContainer flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <SubSectionNoteHeader>Category Notes</SubSectionNoteHeader>
                <NotesContainer>
                    <StyledFormInputWrapper>
                        <StyledFormInput
                            id={`notes_${title}`}
                            placeholder='Type any additional notes'
                            inputfontsize={14}
                            clearOption={true}
                            label='notes'
                            onBlur={this.noteOnBlur}
                            onClear={this.noteOnClear}
                            value={value}
                            multiLine
                            hideLabelOnFocus
                            renderUnderline={false}
                            multiLineInitialBlockHeight={rem(100)}
                            inputPadding={[5, 5]}
                            inputFullWidth
                            inputfont={'default'}
                        />
                    </StyledFormInputWrapper>
                </NotesContainer>
            </SubSectionNoteContainer>
        );
    };

    render() {
        const { id, subSection: { title, allowNonChanel, allowNonChanelNote, type }, first, last } = this.props;

        return (
            <SubSectionContainer first={first} flexDirection='column' justifyContent='center' alignItems='center'>
                {!first && id !== SECTION_ID.TRIAL && <StyledHr/>}
                {title &&
                <SubSectionHeaderOuter
                    justifyContent='center'
                    alignItems='center'
                >
                    <StyledHeader as={'h3'}>
                        {title}
                    </StyledHeader>
                </SubSectionHeaderOuter>
                }
                {type === BEAUTYPLAN_SEARCH_TYPE.SEARCH_AND_NOTE && this.renderSubSectionNote()}
                {type !== BEAUTYPLAN_SEARCH_TYPE.EVENT && type !== BEAUTYPLAN_SEARCH_TYPE.SERVICE && this.renderSelectedProducts()}
                {type === BEAUTYPLAN_SEARCH_TYPE.EVENT && this.renderSelectedEvents()}
                {type === BEAUTYPLAN_SEARCH_TYPE.SERVICE && this.renderSelectedServices()}
                <AddProductButtonWrapper flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    {this.renderProductPicker()}
                    {allowNonChanel && !allowNonChanelNote && this.renderNonChanelProductCheckbox()}
                    {allowNonChanel && allowNonChanelNote && this.renderNonChanelProductNote()}
                </AddProductButtonWrapper>
                {last && id !== SECTION_ID.TRIAL && <StyledHr isLast={last}/>}
            </SubSectionContainer>
        );
    }
}

const mapStateToProps = ({ beautyplan: { category: id,  subSection: category }, beautyplan, productSearch: { autocompleteResults } }) => ({
    ...beautyplan,
    productsInCategory: beautyplan.workplanProducts.filter(p => p[id] && p[id] === category),
    autocompleteResults
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addSelectedProduct,
            removeSelectedProduct,
            analyticsTag,
            setIsHeaderSearchOpen,
            addSubsectionNote,
            removeSubsectionNote,
            updateProductNote,
            updateProductBatchCode,
            removeProductBatchCode,
            addSelectedEvent,
            removeSelectedEvent,
            updateEventNote,
            addSelectedService,
            removeSelectedService,
            updateServiceNote,
            addProductInKit,
            removeProductInKit
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSubSection);
