import { createAction } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { CONFIG, SESSION_ID_LOCALSTORAGE_KEY } from 'constants/config';
import { FETCH, FETCH_RESET } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';
import { postAuthCheckin } from './checkIn';
import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';
import { fetchAtelier } from './prismicFetch';
import { PRISMIC_PAGE_IDS } from 'constants/prismic';
import { transferAnonymousInteractionsToUser } from './recommendations';
import { NEED_CODE_STATUS } from 'constants/auth';
import { requestNewCode } from './login';
import { resetMemberHub } from './memberHub';

export const REGISTRATION = 'REGISTRATION';

export const registerUser = createAction(FETCH, user => ({
    prefix: REGISTRATION,
    endpoint: `${CONFIG.API_URL}/auth/register`,
    options: {
        method: 'POST',
        body: JSON.stringify(user)
    }
}));

export const registerUserWithCode = createAction(FETCH, user => ({
    prefix: REGISTRATION,
    endpoint: `${CONFIG.API_URL}/auth/otplogin`,
    options: {
        method: 'POST',
        body: JSON.stringify(user)
    }
}));

export const resetRegistrationError = createAction(`${REGISTRATION}/${FETCH_RESET}`);

const handleRegistrationSuccess = dispatch => {
    dispatch(
        analyticsTag(
            {
                event: EVENTS.GA,
                eventCategory: 'profile',
                eventAction: 'registration',
                eventLabel: 'completed'
            },
            { userInfo: true }
        )
    );

    // on post registration login, fetch all necessary Atelier data
    // TODO: this could be further broken out to fetch only needed data per page while browsing, however performance isn't an issue at this time so not needed
    dispatch(fetchAtelier(PRISMIC_PAGE_IDS.ATELIER_SOHO));

    // reset hub
    dispatch(resetMemberHub());

    dispatch(transferAnonymousInteractionsToUser());
    return dispatch(postAuthCheckin());
};

export const register = user => {
    return (dispatch, getState) => {
        const {
            user: {
                auth: {
                    authenticated,
                    profile: { cartId }
                }
            }
        } = getState();

        let deviceId = localStorage.getItem(SESSION_ID_LOCALSTORAGE_KEY);

        if (!deviceId) {
            deviceId = uuidv4();
            window.localStorage.setItem(SESSION_ID_LOCALSTORAGE_KEY, deviceId);
        }

        user.deviceId = deviceId;

        // send guestCartId so it can be merged after registration
        if (!authenticated && cartId) {
            user.guestCartId = cartId;
        }

        return dispatch(registerUser(user)).then(response => {
            if (response.status === NEED_CODE_STATUS) {
                return Promise.resolve({
                    needsCode: true,
                    vToken: response.vToken
                });
            } else {
                return handleRegistrationSuccess(dispatch);
            }
        });
    };
};

export const validateCodeAndRegister = userWithCodeAndToken => (
    dispatch,
    getState
) => {
    const {
        user: {
            auth: {
                authenticated,
                profile: { cartId }
            }
        }
    } = getState();

    let deviceId = localStorage.getItem(SESSION_ID_LOCALSTORAGE_KEY);

    if (!deviceId) {
        deviceId = uuidv4();
        window.localStorage.setItem(SESSION_ID_LOCALSTORAGE_KEY, deviceId);
    }

    const loginRequest = { ...userWithCodeAndToken, deviceId };

    // send guestCartId so it can be merged after login
    if (!authenticated && cartId) {
        loginRequest.guestCartId = cartId;
    }

    return dispatch(registerUserWithCode(loginRequest)).then(() => {
        return handleRegistrationSuccess(dispatch);
    });
};

export const requestRegisterCode = ({ username }) => {
    return dispatch => {
        return dispatch(requestNewCode({ username }));
    };
};

export default createFetchReducer(REGISTRATION);
