import { combineReducers } from 'redux';
import { createAction, handleAction, handleActions } from 'redux-actions';
import log from 'core/log';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';
import { getCart } from 'reducers/cart';
import { setWorkshopContext } from 'reducers/workshop';
import { fetchUserLists } from 'reducers/userList';
import { analyticsTag } from 'reducers/analytics';
import Gigya from 'core/utils/Gigya';
import { CHECKIN_CREATED, normalizeCheckins } from 'core/utils/normalizeCheckins';
import { EVENTS } from 'constants/analytics';
import moment from 'moment';

export const CHECK_IN = 'CHECK_IN';
export const CHECK_IN_COMPLETE = 'CHECK_IN_COMPLETE';
export const CHECKED_IN_GUESTS = 'CHECKED_IN_GUESTS';
const CLEAR_CHECK_IN = 'CLEAR_CHECK_IN';
export const CHECKIN_STATUS_FILTER = 'created,checkedin,analog,checkedout';

let checkinsTimer = null;

// Check for checked-in guests every minute
const CHECKED_GUEST_POLLING_INTERVAL = 60000;


// action used to clear the checkin data state
export const clearCheckin = createAction(CLEAR_CHECK_IN);

export const createCheckIn = createAction(
    FETCH,
    checkIn => ({
        prefix: CHECK_IN,
        endpoint: `${CONFIG.API_URL}/auth/checkin`,
        options: {
            method: 'POST',
            body: JSON.stringify(checkIn)
        }
    })
);

export const createAnalogCheckIn = createAction(
    FETCH,
    checkIn => ({
        prefix: CHECK_IN,
        endpoint: `${CONFIG.API_URL}/checkin/analog`,
        options: {
            method: 'POST',
            body: JSON.stringify(checkIn)
        }
    })
);

export const validateCheckin = createAction('FETCH', checkInToken => ({
    prefix: CHECK_IN_COMPLETE,
    endpoint: `${CONFIG.API_URL}/auth/checkin/${checkInToken}`,
    options: {
        method: 'GET'
    }
}));

export const useCheckIn = createAction('FETCH', checkInToken => ({
    prefix: CHECK_IN_COMPLETE,
    endpoint: `${CONFIG.API_URL}/auth/checkin/${checkInToken}`,
    options: {
        method: 'POST'
    }
}));

const convertAnalogCheckin = createAction(
    FETCH,
    checkInData => ({
        prefix: CHECK_IN,
        endpoint: `${CONFIG.API_URL}/auth/checkin/${checkInData.checkInToken}`,
        options: {
            method: 'PUT',
            body: JSON.stringify(checkInData)
        }
    })
);

const startPolling = createAction('CHECKINS_START_POLLING');
export const stopPolling = createAction('CHECKINS_STOP_POLLING');

export const stopPollingPromise = () => {
    return (dispatch) => {
        return Promise.resolve(dispatch(stopPolling()));
    };
};

const renderQueryString = (query) => {
    let ret = '';
    let sep = '?';
    for (const key in query) {
        ret += sep;
        sep = '&';
        const value = query[key];
        ret += `${key}=${value}`;
    }
    return ret;
};


export const listCheckins = createAction(FETCH, (query) => ({
    prefix: CHECKED_IN_GUESTS,
    endpoint: `${CONFIG.API_URL}/auth/checkin${renderQueryString(query)}`,
    options: {
        method: 'GET'
    }
}));

export const touchScreenListCheckins = () => {
    return (dispatch, getState) => {
        const { workshop: { workshopId }, secretKey: { secretKey } } = getState();
        return dispatch(listCheckins({ workshopId, secretKey, status: CHECKIN_STATUS_FILTER }));
    };
};

export const startPollingCheckins = () => {
    return (dispatch, getState) => {
        const { checkIn: { checkedInGuests: { isPolling } }, user: { auth: { staffLocation } } } = getState();
        if (isPolling) {
            return;
        }
        dispatch(startPolling());
        dispatch(listCheckins({ status: CHECKIN_STATUS_FILTER, location: staffLocation }));
        checkinsTimer = setInterval(() => {
            const { checkIn: { checkedInGuests: { isPolling } } } = getState();
            if (!isPolling) {
                clearTimeout(checkinsTimer);
                return;
            }
            dispatch(listCheckins({ status: CHECKIN_STATUS_FILTER, location: staffLocation }));
        }, CHECKED_GUEST_POLLING_INTERVAL);
    };
};

export const setStaffCustomerCheckInComplete = createAction('STAFF_CUSTOMER_CHECKIN_COMPLETE');

/**
 * Dispatches the correct check-in type based on provided data.
 * @param {object} payload
 * @returns {Function} Dispatched action
 */
export const handleStaffCustomerCheckIn = (payload) => {
    return (dispatch) => {
        if (payload.email || payload.phone) {
            if (payload.checkInToken) {
                return dispatch(convertAnalogCheckin(payload)).then(() => {
                    dispatch(listCheckins());
                    dispatch(setStaffCustomerCheckInComplete(true));
                });
            } else {
                return dispatch(createCheckIn(payload)).then(() => {
                    dispatch(setStaffCustomerCheckInComplete(true));
                });
            }
        } else {
            return dispatch(createAnalogCheckIn(payload)).then(() => {
                dispatch(setStaffCustomerCheckInComplete(true));
            });
        }
    };
};

/**
 * Computes check-in method based on checkInData
 * @param {object} checkInData
 * @return {string} check-in method
 */
export const getCheckinMethod = (checkInData) => {

    if (checkInData === null || !checkInData.status) {
        return 'none';
    }

    const { email, phone, name } = checkInData;

    if (email) {
        return 'email';
    } else if (phone) {
        return 'phone';
    } else if (name) {
        return 'name';
    }

    return 'none';

};

export const completeCheckIn = (token) => {
    return (dispatch, getState) => {
        return dispatch(useCheckIn(token))
            .then(() => {
                const { checkIn: { checkInData } } = getState();

                dispatch(analyticsTag({
                    event: EVENTS.GA,
                    eventAction: 'checkin',
                    eventCategory: 'checkin',
                    eventLabel: 'completed',
                    checkin: 1,
                    checkin_type: 'digital',
                    checkin_method: getCheckinMethod(checkInData),
                }, { userInfo: true }));

            });
    };
};

/**
 * Validate that the check-in data is still valid.
 * @return {Function} Thunk
 */
export const checkExpiredCheckin = () => {
    return (dispatch, getState) => {
        const { checkIn: { checkInData } } = getState();

        // no checkin data = user isn't checked-in, nothing to do
        if (checkInData === null || !checkInData.status) {
            return;
        }

        // user was checked-in and it has since expired
        if (moment().isSameOrAfter(moment(checkInData.validUntil))) {
            dispatch(clearCheckin());
        }
    };
};

export const postAuthCheckin = () => {
    return (dispatch, getState) => {
        const { checkIn: { checkInData }, user: { auth, auth: { profile: { externalId } } } } = getState();

        // notify gigya that login happened
        try {
            new Gigya().postLogin();
        } catch (err) {
            log.error('Gigya Error trying to postLogin', err);
        }

        // user is checking in
        if (checkInData && checkInData.status) {

            // if checkin is created, then it's okay to complete the checkin sequence
            checkInData.status === CHECKIN_CREATED && dispatch(completeCheckIn(checkInData.token));

            // if checkin exists but the logged in user has a different id, clear out the check-in data
            checkInData.status !== CHECKIN_CREATED && externalId !== checkInData.uid && dispatch(clearCheckin());
        }

        // setWorkshop Context
        dispatch(setWorkshopContext());

        // Fetch the cart information
        dispatch(getCart());

        // Fetch the like list information
        dispatch(fetchUserLists(true));

        return auth;
    };
};

export const setCheckInProfile = createAction('SET_PROFILE');
export const setApptData = createAction('SET_APPOINTMENT_DATA');
export const setEventData = createAction('SET_EVENT_DATA');

/**
 * Whenever appointment data becomes available, save it as part of checkin data
 * @param {object} appointments
 * @returns {Function} dispatch analytics
 */
export const setAnalyticsAppointmentData = (appointments) => (dispatch) => {
    const hasAppointmentToday = appointments.some(appt => {
        return moment().isSame(appt.startDateTime, 'day');
    });

    dispatch(setApptData({ hasAppointmentToday: hasAppointmentToday ? 1 : 0 }));
};

/**
 * Whenever appointment data becomes available, save it as part of checkin data
 * @param {object} events
 * @returns {Function} dispatch analytics
 */
export const setAnalyticsEventData = (events) => (dispatch) => {
    const hasEventToday = events.some(event => {
        return moment().isSame(moment(event.StartDateTime), 'day');
    });
    dispatch(setEventData({ hasEventToday: hasEventToday ? 1 : 0 }));
};


const initialCheckinState = {
    hasAppointmentToday: null,
    hasEventToday: null
};

export default combineReducers({
    profile: handleAction(
        setCheckInProfile,
        (state, { payload }) => ({ ...state, ...payload }),
        {}
    ),
    fetch: createFetchReducer(CHECK_IN),
    checkInData: handleActions({
        [`${CHECK_IN_COMPLETE}/${FETCH_SUCCESS}`]: (state, { payload }) => ({ ...state, ...payload }),
        [setApptData]: (state, { payload }) => ({ ...state, ...payload }),
        [setEventData]: (state, { payload }) => ({ ...state, ...payload }),
        [clearCheckin]: () => ({ initialCheckinState }) // reset checkin state
    }, initialCheckinState
    ),
    staffCustomerCheckInComplete: handleAction(
        setStaffCustomerCheckInComplete,
        (state, { payload }) => payload,
        false
    ),
    checkedInGuests: handleActions({
        [startPolling]: (state) => ({ ...state, isPolling: true }),
        [stopPolling]: (state) => ({ ...state, isPolling: false }),
        [`${CHECKED_IN_GUESTS}/${FETCH_PENDING}`]: (state) => ({ ...state, isFetching: true }),
        [`${CHECKED_IN_GUESTS}/${FETCH_ERROR}`]: (state, { payload }) => ({ ...state, isFetching: false, error: payload }),
        [`${CHECKED_IN_GUESTS}/${FETCH_SUCCESS}`]: (state, { payload }) => (
            {
                ...state,
                isFetching: false,
                guests: payload.filter(c => c.checkin.status !== 'timedout').map(normalizeCheckins)
            }),
    }, { isFetching: false, guests: [], error: null, isPolling: false })
});
