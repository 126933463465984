import { createAction } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH } from 'middlewares/fetch';
import createFetchReducer from 'reducers/createFetchReducer';

export const MY_PRODUCTS = 'MY_PRODUCTS';

export const getMyProducts = createAction(FETCH, externalId => ({
    prefix: MY_PRODUCTS,
    endpoint: `${CONFIG.API_URL}/profiles/${externalId}/modules/products`
}));

export const fetchMyProducts = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        return dispatch(getMyProducts(externalId));
    };
};

export default createFetchReducer(MY_PRODUCTS);
