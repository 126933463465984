import { SEARCH } from 'constants/search';
import elasticSearchQueries from 'core/utils/elasticSearchQueries';
import normalizeProduct from 'core/utils/normalizeProduct';
import { combineActions, createAction, handleActions } from 'redux-actions';
import { searchProducts, searchProductsBySkus } from 'reducers/elasticsearch';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import { CONFIG } from 'constants/config';
import {
    BEAUTYPLAN_CONFIG,
    BEAUTYPLAN_SECTIONS,
    BEAUTYPLAN_TYPE,
    getEventAssociatedBeautyPlanType,
    NON_CHANEL
} from 'constants/beautyplan';

import { push } from 'redux-first-history';
import { ROUTES } from 'constants/routes';
import { normalizeBeautyPlan } from 'core/utils/normalizeBeautyPlan';
import { getAssociatedBeautyPlanType } from 'reducers/booking';
import groupBy from 'core/utils/groupBy';
import log from 'core/log';
import { analyticsTag } from 'reducers/analytics';

const BEAUTYPLAN = 'BEAUTYPLAN';
const BEAUTYPLAN_SUBMIT = 'BEAUTYPLAN_SUBMIT';
const BEAUTYPLAN_QUERY = 'BEAUTYPLAN_QUERY';
const BEAUTYPLAN_DELETE = 'BEAUTYPLAN_DELETE';

const initialState = {
    products: [],
    searchTerm: '',
    isFetching: false,
    items: [],
    image: '',
    workplanProducts: [],
    isFetchingProducts: false,
    isWorkplanValid: false,
    currentPlan: null,
    editing: false,
    productsInPlan: [],
    saved: false,
    events: [],
    services: []
};

export const isFetching = createAction('IS_FETCHING_WORKPLAN');
export const setBeautyplanImage = createAction('SET_BEAUTYPLAN_IMAGE');
export const setFoundProducts = createAction('SET_FOUND_PRODUCTS_WORKPLAN');
export const setSearchTerm =  createAction('SET_SEARCH_TERM_WORKPLAN');
export const addSelectedProduct =  createAction('ADD_SELECTED_PRODUCT_WORKPLAN');
export const updateProductNote = createAction('UPDATE_NOTE_PRODUCT_WORKPLAN');
export const updateEventNote = createAction('UPDATE_NOTE_EVENT_WORKPLAN');
export const updateServiceNote = createAction('UPDATE_NOTE_SERVICE_WORKPLAN');
export const updateProductBatchCode = createAction('UPDATE_BATCH_CODE_PRODUCT_WORKPLAN');
export const removeProductBatchCode = createAction('REMOVE_BATCH_CODE_PRODUCT_WORKPLAN');
export const addProductInKit = createAction('ADD_PRODUCT_IN_KIT_WORKPLAN');
export const removeProductInKit = createAction('REMOVE_PRODUCT_IN_KIT_WORKPLAN');
export const addSelectedEvent = createAction('ADD_SELECTED_EVENT_WORKPLAN');
export const addSelectedService = createAction('ADD_SELECTED_SERVICE_WORKPLAN');
export const addSubsectionNote = createAction('ADD_SUBSECTION_NOTE_WORKPLAN');
export const removeSelectedProduct =  createAction('REMOVE_SELECTED_PRODUCT_WORKPLAN');
export const removeSelectedEvent =  createAction('REMOVE_SELECTED_EVENT_WORKPLAN');
export const removeSelectedService =  createAction('REMOVE_SELECTED_SERVICE_WORKPLAN');
export const removeSubsectionNote = createAction('REMOVE_SUBSECTION_NOTE_WORKPLAN');
export const setWorkplanCategoryProducts = createAction('SET_WORKPLAN_PRODUCTS');
export const initWorkplanCreation = createAction('INIT_WORKPLAN_CREATION');
export const pickSampleKitSize = createAction('SET_SKIN_SERVICE_TYPE');
export const setProductsInPlan = createAction('SET_PRODUCTS_IN_PLAN');
export const addNotes = createAction('SET_BEAUTYPLAN_NOTES');
export const addStaffQuestions = createAction('SET_BEAUTYPLAN_STAFF_QUESTIONS');
export const setEditMode = createAction('SET_EDIT_MODE');
export const setSaved = createAction('SET_SAVED');
const setServiceRecap = createAction('SET_BEAUTYPLAN_SERVICE_RECAP');
const fetchingProducts = createAction('FETCHING_BEAUTYPLAN_PRODUCTS');
const setTrialKitProducts = createAction('SET_TRIALKIT_PRODUCTS');
const setBrowLookProducts = createAction('SET_BROW_LOOK_PRODUCTS');
const setSkinRoutineProducts = createAction('SET_SKINROUTINE_PRODUCTS');
const setApplicationProducts = createAction('SET_APPLICATION_PRODUCTS');
const setFragranceExperienceProducts = createAction('SET_FRAGRANCE_EXPERIENCE_PRODUCTS');
const setBeautyGuideTalkProducts = createAction('SET_BEAUTY_GUIDE_TALK_PRODUCTS');
const setAtHomeFacialProducts = createAction('SET_AT_HOME_FACIAL_PRODUCTS');
const setServiceSummaryProducts = createAction('SET_SERVICE_SUMMARY_PRODUCTS');
const setPlanItems = createAction('SET_PLAN_ITEMS');
const beautyplanReady = createAction('BEAUTYPLAN_READY');

const submitNewWorkplan = createAction(FETCH, request => ({
    prefix: BEAUTYPLAN_SUBMIT,
    endpoint: `${CONFIG.API_URL}/beautyplans`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

const getBeautyPlanById = createAction(FETCH, id => ({
    prefix: BEAUTYPLAN,
    endpoint: `${CONFIG.API_URL}/beautyplans/${id}`,
    options: {
        method: 'GET'
    }
}));

/**
 * Query beauty plans by appointment Id
 * @type {actionCreator} Action creator
 */
export const queryBeautyPlans = createAction(FETCH, (id, appointmentId) => ({
    prefix: BEAUTYPLAN_QUERY,
    endpoint: `${CONFIG.API_URL}/beautyplans?owner=${id}&appointmentId=${appointmentId.toString()}`,
    options: {
        method: 'GET'
    }
}));

/**
 * Query beauty plans by event Id
 * @type {actionCreator} Action creator
 */
export const queryBeautyPlansByEventId = createAction(FETCH, (id, eventId) => ({
    prefix: BEAUTYPLAN_QUERY,
    endpoint: `${CONFIG.API_URL}/beautyplans?owner=${id}&eventId=${eventId.toString()}`,
    options: {
        method: 'GET'
    }
}));

const uploadBeautyPlanImage = createAction(FETCH, (id, imageData) => ({
    prefix: BEAUTYPLAN_QUERY,
    endpoint: `${CONFIG.API_URL}/beautyplans/${id}`,
    options: {
        method: 'PUT',
        body: JSON.stringify({ imageData })
    }
}));

/**
 * Update an existing beauty plan
 */
const updateBeautyPlan = createAction(FETCH, (id, request) => ({
    prefix: BEAUTYPLAN,
    endpoint: `${CONFIG.API_URL}/beautyplans/${id}`,
    options: {
        method: 'POST',
        body: JSON.stringify(request)
    }
}));

/**
 * Delete a beauty plan by id
 */
const deleteBeautyPlan = createAction(FETCH, (id) => ({
    prefix: BEAUTYPLAN_DELETE,
    endpoint: `${CONFIG.API_URL}/lists/${id}`,
    options: {
        method: 'DELETE'
    }
}));

/**
 * Sends image and get a normalized image in return (correct orientation, smaller size, 1024 width max)
 * @type {actionCreator} Action creator
 */
export const getImageWithProperOrientation = createAction(FETCH, (imageData) => ({
    prefix: 'IMAGE_TRANSFORM',
    endpoint: `${CONFIG.API_URL}/files/normalize`,
    options: {
        method: 'POST',
        body: JSON.stringify({ imageData })
    }
}));

/**
 * Update the image for the current beauty plan
 * @param {string} imageData
 * @return {function(*, *): *} Thunk
 */
export const updateBeautyPlanImage = (imageData) => {
    return (dispatch, getState) => {
        const { beautyplan: { currentPlan } } = getState();
        return dispatch(uploadBeautyPlanImage(currentPlan.id, imageData)).then(() => dispatch(setBeautyplanImage(imageData)));
    };
};

/**
 * Trigger search
 * @param {string} search - user request
 * @param {boolean} isFragranceFlight - search fragrances (with fragrance number) for Fragrance Experience beautyplans
 * @param {number} currentPage - curent page for search
 * @returns {function(*, *): *} promise
 */
export const fetchWorkplanProducts = (search, isFragranceFlight = false, currentPage = 0) => {

    return (dispatch) => {
        dispatch(isFetching(true));
        dispatch(setSearchTerm(search));

        const elasticQuery = isFragranceFlight ?
            elasticSearchQueries.searchWorkPlanFragranceFlightQuery(search) :
            elasticSearchQueries.searchWorkplanProductsQuery(search);

        const request = {
            bodyRequest: elasticQuery,
            params: {
                perPage: SEARCH.SEARCH.perPage,
                currentPage: currentPage
            }
        };

        const endpoint = SEARCH.URL.DEFAULT;

        dispatch(searchProducts({ endpoint, request })).then(({ hits: { hits: results } }) => {
            const searchResults = results.map(normalizeProduct);
            dispatch(setFoundProducts(searchResults));
            dispatch(isFetching(false));
        }
        ).catch(() => {
            dispatch(isFetching(false));
        });
    };
};

export const fetchProductsByWorkplanCategories = (workplanCategories) => {

    return (dispatch, getState) => {

        const { beautyplan: { workplanProducts, isFetchingProducts } } = getState();

        // do not run the search if it has already been populated or is currently fetching
        if (workplanProducts.length > 0 || isFetchingProducts) {
            return;
        }

        dispatch(fetchingProducts());

        const productSearch = workplanCategories.map(({ routine, categories }) => {
            return categories.map(category => {
                const request = {
                    bodyRequest: elasticSearchQueries.queryByWorkplanCategory(routine, category),
                    params: {
                        perPage: SEARCH.SEARCH.perPage,
                        currentPage: 0
                    }
                };

                const endpoint = SEARCH.URL.DEFAULT;
                return dispatch(searchProducts({ endpoint, request }));
            });
        }).reduce((acc, val) => acc.concat(val), []); // flatten

        // fetch products for all given workplan categories
        Promise.all(productSearch).then(searchResults => {
            // normalize and flatten
            const workplanProducts = searchResults
                .map(({ hits: { hits: results } }) => results.map(normalizeProduct)) // normalize
                .reduce((acc, val) => acc.concat(val), []) // flatten
                .filter((product, i, products) => products.findIndex(p => p.id === product.id) === i); // remove duplicates

            // add to state
            dispatch(setWorkplanCategoryProducts(workplanProducts)); // avoid duplicating products
        }).catch(err => {
            log.error('Error while fetching beauty plans products', err);
            // handle errors and reset state
            dispatch(setWorkplanCategoryProducts([]));
        });

    };
};

/**
 * Delete the current beauty plan
 * @return {any} thunk
 */
export const deleteCurrentBeautyPlan = () => {
    return (dispatch, getState) => {
        const { beautyplan: { currentPlan: { id } } } = getState();
        dispatch(deleteBeautyPlan(id)).then(() => dispatch(push(ROUTES.PROFILE)));
    };
};

/**
 * Fetch a beauty plan by id, normalize it, prepare the plan's product data
 * @param {string} id
 * @return {function(*, *): *} thunk
 */
const prepareBeautyPlan = (id) => {
    return (dispatch, getState) => {
        return dispatch(getBeautyPlanById(id)).then((plan) => {
            const { makeupArtists: { artistData } } = getState();
            dispatch(beautyplanReady(normalizeBeautyPlan(artistData)(plan)));
            const skus = plan.products.map(p => p.sku).filter(p => !p.startsWith(NON_CHANEL));
            return skus.length > 0 ? dispatch(searchProductsBySkus(skus)) : null;
        }).then(products => {
            return products ? dispatch(setProductsInPlan(products.docs.map(normalizeProduct))) : null;
        }).then(() => dispatch(isFetching(false)));
    };
};

/**
 * Fetch single beauty plan
 * @param {string} id
 * @return {function(*, *): *} Thunk
 */
export const fetchBeautyPlan = (id) => {
    return (dispatch, getState) => {
        return dispatch(prepareBeautyPlan(id))
            .then(() => {
                const { beautyplan: { currentPlan, currentPlan: { products, service }, productsInPlan } } = getState();

                // prepare data for tabs, for optimization purposes
                // TrialKit products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.SAMPLE_KIT_5)) {
                    const trialKitProducts = products.filter(p => p.section === service.config.id)
                        .map(kit => ({ ...kit, ...productsInPlan.find(p => p.id === kit.sku) }));
                    dispatch(setTrialKitProducts(trialKitProducts));
                }
                // Skin Routine products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.SKINCARE_ROUTINE].id);

                    const skinRoutineProducts = [...productsInSection.filter(p => !p.sku.startsWith(NON_CHANEL)).map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem
                    })), ...productsInSection.filter(p => p.sku.startsWith(NON_CHANEL))];
                    dispatch(setSkinRoutineProducts(skinRoutineProducts));
                }
                // Makeup Application products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.MAKEUP_ROUTINE].id);
                    const makeupApplicationProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setApplicationProducts(groupBy(makeupApplicationProducts, 'subSection')));
                }
                // Brow Look products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.BROW_LOOK) || service.steps.includes(BEAUTYPLAN_SECTIONS.NEW_BROW_LOOK)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.BROW_LOOK].id);
                    const browLookProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setBrowLookProducts(groupBy(browLookProducts, 'subSection')));
                }
                // New Makeup Routine products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].id);
                    const makeupApplicationProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setApplicationProducts(groupBy(makeupApplicationProducts, 'subSection')));
                }
                // New Skin Routine products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.NEW_SKINCARE_ROUTINE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_SKINCARE_ROUTINE].id);

                    const skinRoutineProducts = [...productsInSection.filter(p => !p.sku.startsWith(NON_CHANEL)).map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem
                    })), ...productsInSection.filter(p => p.sku.startsWith(NON_CHANEL))];
                    dispatch(setSkinRoutineProducts(skinRoutineProducts));
                }
                // Fragrance Experience products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.FRAGRANCE_EXPERIENCE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.FRAGRANCE_EXPERIENCE].id);
                    const fragranceExperienceProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setFragranceExperienceProducts(fragranceExperienceProducts));
                }
                // Beauty guide talk products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.BEAUTY_GUIDE_TALK)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.BEAUTY_GUIDE_TALK].id);
                    const beautyGuideTalkProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setBeautyGuideTalkProducts(beautyGuideTalkProducts));
                }
                // At-home facial products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.AT_HOME_FACIAL)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.AT_HOME_FACIAL].id);
                    const atHomeFacialProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setAtHomeFacialProducts(atHomeFacialProducts));
                }
                // NewSkinRoutine products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.NEW_SKIN_ROUTINE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_SKIN_ROUTINE].id);
                    const routineProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setServiceSummaryProducts(routineProducts));
                }
                // NewMkupRoutine products
                if (service.steps.includes(BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE)) {
                    const productsInSection = products.filter(p => p.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MKUP_ROUTINE].id);
                    const routineProducts = [...productsInSection.map(planItem => ({
                        ...productsInPlan.find(p => p.id === planItem.sku),
                        ...planItem,
                    }))];
                    dispatch(setServiceSummaryProducts(routineProducts));
                }
                return currentPlan;
            });
    };
};

/**
 * Fetch a beauty plan info by id and prepare the data for edit
 * @param {string} id
 * @return {function(*, *): *} thunk
 */
export const fetchBeautyPlanForEdit = (id) => {
    return (dispatch, getState) => {
        dispatch(setEditMode(true));
        return dispatch(prepareBeautyPlan(id))
            .then(() => {
                const { beautyplan: { currentPlan: { products }, productsInPlan } } = getState();
                const items = products.map(item => {
                    return {
                        id: item.section,
                        section: item.section,
                        categoryId: item.category,
                        sectionId: item.subSection,
                        note: item.note,
                        batchCode: item.batchCode,
                        inKit: item.inKit,
                        product: item.sku.startsWith(NON_CHANEL)
                            ? { sku: item.sku }
                            : productsInPlan.find(p => p.sku === item.sku)
                    };
                });
                dispatch(setPlanItems(items));
            });
    };
};

/**
 * Initialize the creation of a Beauty plan - Skin service
 * @return {Function} Thunk
 */
export const initializeSkinService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.SKINCARE_SERVICE_5_SAMPLES.id, // by default, initialize a 3 piece sample kit
            service: BEAUTYPLAN_TYPE.SKINCARE_SERVICE_5_SAMPLES, // 3 piece sample kit
            image: BEAUTYPLAN_TYPE.SKINCARE_SERVICE_5_SAMPLES.image,
            imageDesktop: BEAUTYPLAN_TYPE.SKINCARE_SERVICE_5_SAMPLES.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.SKINCARE_SERVICE_5_SAMPLES.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Happy Hour Look
 * @return {Function} Thunk
 */
export const initializeHappyHourLookService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.HAPPY_HOUR_LOOK.id,
            service: BEAUTYPLAN_TYPE.HAPPY_HOUR_LOOK,
            image: BEAUTYPLAN_TYPE.HAPPY_HOUR_LOOK.image,
            imageDesktop: BEAUTYPLAN_TYPE.HAPPY_HOUR_LOOK.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.HAPPY_HOUR_LOOK.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Makeup Look
 * @return {Function} Thunk
 */
export const initializeMakeupLookService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.MAKEUP_LOOK.id,
            service: BEAUTYPLAN_TYPE.MAKEUP_LOOK,
            image: BEAUTYPLAN_TYPE.MAKEUP_LOOK.image,
            imageDesktop: BEAUTYPLAN_TYPE.MAKEUP_LOOK.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.MAKEUP_LOOK.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Brow Look
 * @return {Function} Thunk
 */
export const initializeBrowLookService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.BROW_LOOK.id,
            service: BEAUTYPLAN_TYPE.BROW_LOOK,
            image: BEAUTYPLAN_TYPE.BROW_LOOK.image,
            imageDesktop: BEAUTYPLAN_TYPE.BROW_LOOK.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.BROW_LOOK.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Application or Lesson, from existing appointment
 * @param {object} appointment
 * @return {Function} Thunk
 */
export const initializeAppointmentBeautyPlan = (appointment) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const service = getAssociatedBeautyPlanType(appointment.typeId)(null, getState);
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: service.id,
            service,
            image: service.image,
            imageDesktop: service.imageDesktop,
            imageTablet: service.imageTablet,
            appointmentId: appointment.id.toString()
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan, from existing event
 * @param {string} eventType,
 * @param {string} eventId,
 * @return {Function} Thunk
 */
export const initializeEventBeautyPlan = (eventType, eventId) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const service = getEventAssociatedBeautyPlanType(eventType);
        if (service) {
            const beautyPlan = {
                owner: profile.externalId,
                name: `${profile.firstName} ${profile.lastName}`,
                notes: [],
                staffQuestions: [],
                makeupArtistName: `${firstName} ${lastName}`,
                makeupArtistId: id,
                serviceType: service.id,
                service,
                image: service.image,
                imageDesktop: service.imageDesktop,
                imageTablet: service.imageTablet,
                eventId: eventId.toString()
            };
            dispatch(initWorkplanCreation(beautyPlan));
            dispatch(push(ROUTES.BEAUTY_PLAN));
        }
    };
};

/**
 * Initialize the creation of a Beauty plan - Makeup Chat
 * @return {Function} Thunk
 */
export const initializeMakeupChatService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.NEW_MAKEUP_CHAT.id,
            service: BEAUTYPLAN_TYPE.NEW_MAKEUP_CHAT,
            image: BEAUTYPLAN_TYPE.NEW_MAKEUP_CHAT.image,
            imageDesktop: BEAUTYPLAN_TYPE.NEW_MAKEUP_CHAT.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.NEW_MAKEUP_CHAT.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Makeup Chat
 * @return {Function} Thunk
 */
export const initializeGroupChatService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.GROUP_CHAT.id,
            service: BEAUTYPLAN_TYPE.GROUP_CHAT,
            image: BEAUTYPLAN_TYPE.GROUP_CHAT.image,
            imageDesktop: BEAUTYPLAN_TYPE.GROUP_CHAT.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.GROUP_CHAT.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Fragrance Experience
 * @return {Function} Thunk
 */
export const initializeFragranceExperienceService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE.id,
            service: BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE,
            image: BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE.image,
            imageDesktop: BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.FRAGRANCE_EXPERIENCE.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Initialize the creation of a Beauty plan - Talk to a beauty guide
 * @return {Function} Thunk
 */
export const initializeBeautyGuideTalkService = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile, staffProfile: { id, firstName, lastName } } } } = getState();
        const beautyPlan = {
            owner: profile.externalId,
            name: `${profile.firstName} ${profile.lastName}`,
            notes: [],
            staffQuestions: [],
            makeupArtistName: `${firstName} ${lastName}`,
            makeupArtistId: id,
            serviceType: BEAUTYPLAN_TYPE.BEAUTY_GUIDE_TALK.id,
            service: BEAUTYPLAN_TYPE.BEAUTY_GUIDE_TALK,
            image: BEAUTYPLAN_TYPE.BEAUTY_GUIDE_TALK.image,
            imageDesktop: BEAUTYPLAN_TYPE.BEAUTY_GUIDE_TALK.imageDesktop,
            imageTablet: BEAUTYPLAN_TYPE.BEAUTY_GUIDE_TALK.imageTablet,
        };
        dispatch(initWorkplanCreation(beautyPlan));
        dispatch(push(ROUTES.BEAUTY_PLAN));
    };
};

/**
 * Submit the beauty plan
 * @param {object} submitAnalytics - Object with analytics to fire after submission
 * @return {Function} promise
 */
export const submitBeautyplan = (submitAnalytics) => {
    return (dispatch, getState) => {
        const { beautyplan: { currentPlan: { id, owner, image, serviceType, appointmentId, eventId, makeupArtistName, makeupArtistId, notes, staffQuestions, serviceRecap }, items, editing, subSectionNotes, events, services } } = getState();

        const request = {
            owner,
            imageData: image.startsWith('data') ? image : undefined,
            serviceType,
            makeupArtistId,
            makeupArtistName,
            appointmentId, // can be undefined
            eventId, // can be undefined
            notes,
            staffQuestions,
            subSectionNotes: subSectionNotes.map(note => ({
                note: note.note,
                section: note.id,
                subSection: note.sectionId,
                category: note.categoryId
            })),
            products: items.map(item => {
                return {
                    sku: item.product.sku,
                    section: item.id,
                    subSection: item.sectionId,
                    category: item.categoryId,
                    note: item.note,
                    batchCode: item.batchCode,
                    inKit: item.inKit
                };
            }),
            serviceRecap,
            events,
            services
        };

        if (editing) {
            return dispatch(updateBeautyPlan(id, request)).then(() => {
                let eventLabel = `${submitAnalytics.eventLabel};${id}`;

                if (request.appointmentId) {
                    eventLabel = `${eventLabel};a${request.appointmentId}`;
                } else if (request.eventId) {
                    eventLabel = `${eventLabel};e${request.eventId}`;
                }

                dispatch(setEditMode(false));
                dispatch(setSaved(true));
                dispatch(analyticsTag({
                    ...submitAnalytics,
                    eventLabel
                }, { userInfo: true }));
            });
        }

        return dispatch(submitNewWorkplan(request)).then((createdWorkplan) => {
            let eventLabel = `${submitAnalytics.eventLabel};${createdWorkplan.id}`;

            if (request.appointmentId) {
                eventLabel = `${eventLabel};a${request.appointmentId}`;
            } else if (request.eventId) {
                eventLabel = `${eventLabel};e${request.eventId}`;
            }

            dispatch(analyticsTag({
                ...submitAnalytics,
                eventLabel
            }, { userInfo: true }));
        });
    };
};

export const setServiceRecapAnswers = answers => {
    return (dispatch => {
        return Promise.resolve(dispatch(setServiceRecap(answers)));
    });
};

export default handleActions(
    {
        [setProductsInPlan]: (state, { payload }) => ({
            ...state,
            productsInPlan: payload
        }),
        [addNotes]: (state, { payload: { section, note, id } }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                notes: [...state.currentPlan.notes.filter(n => n.section !== section || n.id !== id), { section, id, note }]
            }
        }),
        [addStaffQuestions]: (state, { payload: { question, answer } }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                staffQuestions: [...state.currentPlan.staffQuestions.filter(q => q.question !== question), { question, answer }]
            }
        }),
        [setServiceRecap]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                serviceRecap: payload
            }
        }),
        [combineActions(`${BEAUTYPLAN_SUBMIT}/${FETCH_PENDING}`,
            `${BEAUTYPLAN}/${FETCH_PENDING}`
        )]: (state) => ({
            ...state,
            isFetching: true
        }),
        [beautyplanReady]: (state, { payload }) => ({
            ...state,
            subSectionNotes: payload.subSectionNotes || [],
            events: payload.events || [],
            services: payload.services || [],
            currentPlan: payload,
        }),
        [`${BEAUTYPLAN_SUBMIT}/${FETCH_SUCCESS}`]: (state) => ({
            ...state,
            isFetching: false
        }),
        [combineActions(`${BEAUTYPLAN}/${FETCH_ERROR}`,
            `${BEAUTYPLAN_SUBMIT}/${FETCH_ERROR}`)]: (state, { payload }) => ({
            ...state,
            error: payload,
            isFetching: false
        }),
        [initWorkplanCreation]: (state, { payload }) => ({
            ...state,
            currentPlan: payload,
            items: [],
            subSectionNotes: [],
            events: [],
            services: []
        }),
        [pickSampleKitSize]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                serviceType: payload,
                service: BEAUTYPLAN_TYPE.SKINCARE_SERVICE_3_SAMPLES.id === payload
                    ? BEAUTYPLAN_TYPE.SKINCARE_SERVICE_3_SAMPLES
                    : BEAUTYPLAN_TYPE.SKINCARE_SERVICE_5_SAMPLES
            }
        }),
        [isFetching]: (state, { payload }) => ({ ...state, isFetching: payload }),
        [setBeautyplanImage]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                image: payload
            }
        }),
        [setFoundProducts]: (state, { payload }) => ({ ...state, products: payload }),
        [setSearchTerm]: (state, { payload }) => ({ ...state, searchTerm: payload }),
        [addSelectedProduct]: (state, { payload: { product, note, id, categoryId, sectionId } }) => {
            const items = [...state.items, { product, note, id, categoryId, sectionId }];
            return {
                ...state,
                items,
                currentPlan: {
                    ...state.currentPlan,
                    products: items
                }
            };
        },
        [updateProductNote]: (state, { payload: { sku, note } }) => {
            const updatedProductsList = state.items.map(item => {
                if (item.product && item.product.sku === sku) {
                    return { ...item, note };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                items: updatedProductsList,
                currentPlan: {
                    ...state.currentPlan,
                    products: updatedProductsList
                }
            };
        },
        [updateServiceNote]: (state, { payload: { service, note } }) => {
            const updatedServicesList = state.services.map(item => {
                if (
                    item.service &&
                    item.service.serviceId === service.service.serviceId &&
                    item.id === service.id &&
                    item.categoryId === service.categoryId &&
                    item.sectionId === service.sectionId
                ) {
                    return { ...item, note };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                services: updatedServicesList
            };
        },
        [updateEventNote]: (state, { payload: { event, note } }) => {
            const updatedEventsList = state.events.map(item => {
                if (
                    item.event &&
                    item.event.eventId === event.event.eventId &&
                    item.id === event.id &&
                    item.categoryId === event.categoryId &&
                    item.sectionId === event.sectionId
                ) {
                    return { ...item, note };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                events: updatedEventsList
            };
        },
        [updateProductBatchCode]: (state, { payload: { sku, batchCode } }) => {
            const updatedProductsList = state.items.map(item => {
                if (item.product && item.product.sku === sku) {
                    return { ...item, batchCode };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                items: updatedProductsList,
                currentPlan: {
                    ...state.currentPlan,
                    products: updatedProductsList
                }
            };
        },
        [removeProductBatchCode]: (state, { payload: { sku } }) => {
            const updatedProductsList = state.items.map(item => {
                if (item.product && item.product.sku === sku) {
                    const { batchCode, ...rest } = item;
                    return { ...rest };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                items: updatedProductsList,
                currentPlan: {
                    ...state.currentPlan,
                    products: updatedProductsList
                }
            };
        },
        [addProductInKit]: (state, { payload: { sku } }) => {
            const updatedProductsList = state.items.map(item => {
                if (item.product && item.product.sku === sku) {
                    return { ...item, inKit: true };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                items: updatedProductsList,
                currentPlan: {
                    ...state.currentPlan,
                    products: updatedProductsList
                }
            };
        },
        [removeProductInKit]: (state, { payload: { sku } }) => {
            const updatedProductsList = state.items.map(item => {
                if (item.product && item.product.sku === sku) {
                    const { inKit, ...rest } = item;
                    return { ...rest };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                items: updatedProductsList,
                currentPlan: {
                    ...state.currentPlan,
                    products: updatedProductsList
                }
            };
        },
        [addSelectedEvent]: (state, { payload: { event, id, categoryId, sectionId } }) => {
            const events = [...state.events, { event, id, categoryId, sectionId }];
            return {
                ...state,
                events
            };
        },
        [addSelectedService]: (state, { payload: { service, id, categoryId, sectionId } }) => {
            const services = [...state.services, { service, id, categoryId, sectionId }];
            return {
                ...state,
                services
            };
        },
        [addSubsectionNote]: (state, { payload: { note, id, categoryId, sectionId } }) => {
            let subSectionNotes;
            const existingSectionNoteIndex = state.subSectionNotes.findIndex(n => n.id === id && n.categoryId === categoryId && n.sectionId === sectionId);

            if (existingSectionNoteIndex < 0) {
                // create note if not existing
                subSectionNotes = [...state.subSectionNotes, { note, id, categoryId, sectionId }];
            } else {
                // update note if existing
                subSectionNotes = state.subSectionNotes;
                subSectionNotes[existingSectionNoteIndex].note = note;
            }

            return {
                ...state,
                subSectionNotes,
                currentPlan: {
                    ...state.currentPlan,
                    subSectionNotes: subSectionNotes
                }
            };
        },
        [removeSubsectionNote]: (state, { payload: { id, categoryId, sectionId } }) => {
            // remove the note with same id, category and section
            const subSectionNotes = state.subSectionNotes.filter(n => n.id !== id || n.categoryId !== categoryId || n.sectionId !== sectionId);
            return {
                ...state,
                subSectionNotes,
                currentPlan: {
                    ...state.currentPlan,
                    subSectionNotes: subSectionNotes
                }
            };
        },
        [setPlanItems]: (state, { payload }) => ({
            ...state,
            items: payload
        }),
        [setEditMode]: (state, { payload }) => ({ ...state, editing: payload }),
        [setSaved]: (state, { payload }) => ({ ...state, saved: payload }),
        [removeSelectedProduct]: (state, { payload: { product, note, id, categoryId, sectionId } }) => {
            let itemRemoved = false;
            // remove 1 product based on sku in a step / category / section
            const items = state.items.filter(s => {
                if (s.product.sku !== product.sku || s.id !== id || s.sectionId !== sectionId || s.categoryId !== categoryId || s.note !== note) {
                    return true;
                } else if (!itemRemoved) {
                    itemRemoved = true;
                    return false;
                }
                return true;
            });

            return {
                ...state,
                items
            };
        },
        [removeSelectedEvent]: (state, { payload: { event, id, categoryId, sectionId, note } }) => {
            let itemRemoved = false;
            // remove 1 product based on sku in a step / category / section
            const events = state.events.filter(s => {
                if (s.event.eventId !== event.eventId || s.id !== id || s.sectionId !== sectionId || s.categoryId !== categoryId || s.note !== note) {
                    return true;
                } else if (!itemRemoved) {
                    itemRemoved = true;
                    return false;
                }
                return true;
            });

            return {
                ...state,
                events
            };
        },
        [removeSelectedService]: (state, { payload: { service, id, categoryId, sectionId, note } }) => {
            let itemRemoved = false;
            // remove 1 product based on sku in a step / category / section
            const services = state.services.filter(s => {
                if (s.service.serviceId !== service.serviceId || s.id !== id || s.sectionId !== sectionId || s.categoryId !== categoryId || s.note !== note) {
                    return true;
                } else if (!itemRemoved) {
                    itemRemoved = true;
                    return false;
                }
                return true;
            });

            return {
                ...state,
                services
            };
        },
        [setWorkplanCategoryProducts]: (state, { payload }) => ({
            ...state,
            workplanProducts: payload,
            isFetchingProducts: false
        }),
        [fetchingProducts]: (state) => ({
            ...state,
            isFetchingProducts: true
        }),
        [setTrialKitProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                trialKitProducts: payload
            }
        }),
        [setBrowLookProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                browLookProducts: payload
            }
        }),
        [setSkinRoutineProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                skinRoutineProducts: payload
            }
        }),
        [setApplicationProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                applicationProducts: payload
            }
        }),
        [setFragranceExperienceProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                fragranceProducts: payload
            }
        }),
        [setBeautyGuideTalkProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                beautyGuideTalkProducts: payload
            }
        }),
        [setAtHomeFacialProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                atHomeFacialProducts: payload
            }
        }),
        [setServiceSummaryProducts]: (state, { payload }) => ({
            ...state,
            currentPlan: {
                ...state.currentPlan,
                serviceSummaryProducts: payload
            }
        })
    },
    initialState
);
