import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import { rem, styledProps } from 'core/styled';
import { MENU_LINKS } from 'constants/menuLinks';
import styled, { css } from 'styled-components';
import SmallNotice from 'components/GlobalComponents/Fonts/SmallNotice';
import { EVENTS } from 'constants/analytics';
import { analyticsTag } from 'reducers/analytics';
import PrimaryModal from 'components/GlobalComponents/Modal/PrimaryModal';
import Header from 'components/GlobalComponents/Fonts/Header';
import Description from 'components/GlobalComponents/Fonts/Description';
import Button from 'components/GlobalComponents/Button/Button';
import { breakpoints } from 'constants/theme';
import { greaterThan, lessThan } from 'core/styled';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import FocusTrap from 'focus-trap-react';
import { ROUTES } from 'constants/routes';
import staffRoles from 'constants/staffRoles';
import { isMyAtelierMember } from 'constants/membership';

export const MENU_WIDTH = {
    DEFAULT: 330,
    TINY: 320,
    LARGE: 400
};

const PageWrapper = styled.nav`
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100%;
    /* visibility: ${({ showHamburgerMenu }) => showHamburgerMenu ? 'visible' : 'hidden'}; */
    background-color: transparent;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
    
    ${greaterThan(breakpoints.medium)(css`
        padding-bottom: 0;
    `)};
`;

const MenuWrapper = styled(Flex)`
    position: absolute;
    padding: ${rem(100, 40, 170, 40)};
    min-height: 100vh;
    width: 100%;
    background: ${({ bgColor }) => styledProps('color', bgColor)};
    left: 0;
    top: 0;

    ${({ isStaff }) => isStaff ? `padding-top: ${rem(100)}` : null}
    
`;

/* const MenuBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 110vh;
    margin-top: -50px;
    background: ${({ bgColor }) => styledProps('color', bgColor)};
    transition: left 0.3s linear;
    z-index: -1;
    left: 0;
    top: 0;
`; */

const MenuSpacer = styled.div`
    display: none;
    position: relative;
    min-height: 100vh;
    background-color: red;
    width: 100%;
`;

const CloseIcon = styled.div`
    position: absolute;
    top: 50px;
    left: ${MENU_WIDTH.DEFAULT / 2}px;
    transform: translate(-50%);
    
    ${greaterThan(breakpoints.medium)(css`
        left: ${MENU_WIDTH.LARGE / 2}px;
    `)};
    
    ${lessThan(breakpoints.tiny)(css`
        left: ${MENU_WIDTH.TINY / 2}px;
    `)};
`;

const StyledLink = styled.a`
    color: ${({ cssColor }) => styledProps('color', cssColor)};
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(40)};
    line-height: ${({ lineheight }) => lineheight ? lineheight : '1.4'};
    font-weight: 300;
    padding: ${rem(10)} 0;
    cursor: pointer;
    width: ${rem(250)};
`;

const BottomBlock = styled(Flex)`
    position: relative;
    width: 100%;
    max-width: 250px;
    height: 120px;
    border-top: 1px solid ${({ borderColor }) => styledProps('color', borderColor)};
    min-height: auto;
    margin-top: ${({ isStaff }) => isStaff ? rem(70) : rem(40)};
`;

const SubLinksBlock = styled(Flex)`
    margin-top: ${rem(15)};
`;

const StyledButton = styled(Button)`
    margin-bottom: ${rem(20)};
`;

const ParagraphDescription = styled(Description).attrs({
    as: 'p'
})`
`;

const MenuTheme = {
    customer: {
        iconTheme: 'light',
        bgColor: 'white',
        fontColor: 'black',
        borderColor: 'silverChalice'
    },
    staff: {
        iconTheme: 'dark',
        bgColor: 'black',
        fontColor: 'white',
        borderColor: 'white'
    }
};

const menuAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'header',
    eventAction: 'menu'
};

const SpanSmallNotice = styled(SmallNotice).attrs({
    as: 'span'
})`
    ${({ underlined }) => underlined ? 'text-decoration: underline;' : ''}
`;

const LogOutNoStyleButton = styled(NoStyleButton)`
    cursor: pointer;
`;

class HamburgerMenu extends Component {

    static propTypes = {
        authenticated: PropTypes.bool.isRequired,
        isLoggedOut: PropTypes.bool.isRequired,
        profile: PropTypes.object,
        toggleHamburger: PropTypes.func.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        staffMode: PropTypes.bool,
        navigate: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        showHamburgerMenu: PropTypes.bool.isRequired,
        isFreelancer: PropTypes.bool.isRequired,
        displayVirtualPaymentsPage: PropTypes.bool.isRequired,
        displayGiftsPage: PropTypes.bool,
        displayMembershipPage: PropTypes.bool,
        sessions: PropTypes.object.isRequired,
        isMember: PropTypes.bool.isRequired
    };

    static defaultProps = {
        displayGiftsPage: false,
        displayMembershipPage: false
    };

    state = {
        isCloseModalOpen: false,
        authenticated: this.props.authenticated,
        focusTrapActive: true
    };

    componentDidUpdate(prevProps) {
        if (this.state.authenticated !== this.props.authenticated) {
            this.setState({
                authenticated: this.props.authenticated
            });
        }

        // when closing menu clear the interval
        if (prevProps.showHamburgerMenu && !this.props.showHamburgerMenu) {
            this.setState({
                focusTrapActive: true
            });
        }
    }

    /**
     * Toggle showing logout modal
     */
    toggleLogoutModal() {
        this.setState({
            isCloseModalOpen: !this.state.isCloseModalOpen
        });
    }

    /**
     * Analytics on links
     * @param {string} label
     */
    analytics = (label) => {
        this.props.analyticsTag({
            ...menuAnalytics,
            eventLabel: label
        }, { userInfo: true });
    };

    keepMeLoggedIn() {
        this.analytics('keepmeloggedin');
        this.toggleLogoutModal();
    }

    /**
     * Logout user and redirect him to the home page
     */
    handleLogout() {
        this.analytics('logmeout');
        this.toggleLogoutModal();
        this.props.logout();
    }

    /**
     * Prevent anchor tag from default redirect with page refresh
     * @param {string} label
     * @param {string} page
     * @returns {function} function
     */
    onMenuLinkClick = (label, page) => e => {
        e.preventDefault();
        this.analytics(label);
        this.props.navigate(page);
    };

    /**
     * Render main menu links
     * @param {array} links - main links
     * @param {object} theme - menu color theme
     * @return {array} - react component collection
     */
    renderMainMenuLinks(links, theme) {
        const { isFreelancer, displayGiftsPage, displayMembershipPage, isMember } = this.props;

        return links.map((link, key) => {
            return ((isFreelancer && link.freelancerHidden) ||
                (link.link === ROUTES.GIFTS && !displayGiftsPage) ||
                (link.link === ROUTES.MEMBERSHIP_LANDING && !displayMembershipPage) ||
                (link.notMemberRequired && isMember) ||
                (link.memberRequired && !isMember)) ? null :
                (
                    <StyledLink key={key}
                        lineheight={'28px'}
                        fontSize={32}
                        onClick={this.onMenuLinkClick(link.text, link.link) }
                        href={link.link}
                        cssColor={theme.fontColor}>{link.text}</StyledLink>
                );
        });
    }

    /**
     * Render bottom user profile links
     * @param {boolean} staffMode - is staff member & not impersonating
     * @param {object} theme - menu color theme
     * @return {component} - react component
     */
    renderUser(staffMode, theme) {
        const onNoticeClick = () => {
            this.analytics('logout');
            this.toggleLogoutModal();
        };

        return (
            <BottomBlock borderColor={theme.borderColor} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} isStaff={staffMode}>
                <Icon added={!staffMode} type={ICON_TYPES.PROFILE} pixelHeight={27} variant={theme.iconTheme} alt={''} cursor={'default'}/>
                <SpanSmallNotice fontColor={theme.fontColor} fontWight={400} fontSize={12} margin={[5, 0]}>
                    <span translate={'no'} className={'notranslate'}>
                        {this.props.profile.firstName}
                        { staffMode && ` ${this.props.profile.lastName}`}
                    </span>
                </SpanSmallNotice>
                <LogOutNoStyleButton type={'button'} onClick={onNoticeClick}>
                    <SpanSmallNotice fontColor={theme.fontColor} fontWight={400} fontSize={12} margin={[0, 20, 0]} underlined>
                        LOG OUT
                    </SpanSmallNotice>
                </LogOutNoStyleButton>
            </BottomBlock>
        );
    }

    /**
     * Render sub links for authorized customer
     * @param {array} links - sub links
     * @param {object} theme - menu color theme
     * @return {array} - react component collection
     */
    renderSubLinks(links, theme) {
        const { isFreelancer, displayVirtualPaymentsPage, profile, sessions } = this.props;
        const skincareWorkshopsEnabled = !!sessions && !!sessions.GroupChat && !!sessions.GroupChat.All && sessions.GroupChat.All.is_enabled;

        return links.map((link, key) => {
            return ((isFreelancer && link.freelancerHidden) ||
                (link.link === ROUTES.VIRTUAL_SERVICES_PAYMENTS && !displayVirtualPaymentsPage) ||
                (link.link === ROUTES.VIRTUAL_SERVICES_SKINCARE_WORKSHOPS && !skincareWorkshopsEnabled) ||
                (link.experienceLeadRoleRequired && !staffRoles.isExperienceLeadRole(profile.staffRole) && !staffRoles.isAtelierDirectorRole(profile.staffRole))) ? null :
                (
                    <StyledLink onClick={this.onMenuLinkClick(link.text, link.link)}
                        cssColor={theme.fontColor}
                        href={link.link}
                        lineheight={'26px'}
                        fontSize={24}
                        key={key}>{link.text}</StyledLink>
                );
        });
    }

    closeMenu = (e) => {
        e.preventDefault();
    };

    render() {
        const { authenticated, staffMode, showHamburgerMenu } = this.props;
        const { focusTrapActive } = this.state;
        const mainLinks = authenticated ? staffMode ? MENU_LINKS.STAFF : MENU_LINKS.CUSTOMER_LOGIN : MENU_LINKS.CUSTOMER;
        const theme = staffMode ? MenuTheme.staff : MenuTheme.customer;

        return (
            <PageWrapper showHamburgerMenu={showHamburgerMenu} id="atelier--hamburgermenu-wrapper" role={'navigation'}>
                {/* <MenuBackground bgColor={theme.bgColor} showHamburgerMenu={showHamburgerMenu} /> */}
                <FocusTrap active={showHamburgerMenu && focusTrapActive}>
                    <MenuWrapper bgColor={theme.bgColor} flexDirection={'column'} isStaff={staffMode} showHamburgerMenu={showHamburgerMenu} id='atelier--hamburgermenu-content'>
                        {/* This ID is needed for ensuring the hamburger close button does not respond to scrolling events */}
                        <CloseIcon id={'hamburgerCloseIcon'}>
                            <NoStyleButton type="button" onClick={() => this.props.toggleHamburger(true)}>
                                <Icon type={ICON_TYPES.STYLED_CLOSE} pixelHeight={27} variant={theme.iconTheme} alt={'close menu'} role={'img'}/>
                            </NoStyleButton>
                        </CloseIcon>

                        { this.renderMainMenuLinks(mainLinks, theme) }
                        <SubLinksBlock flexDirection={'column'}>
                            {(authenticated && !staffMode) && this.renderSubLinks(MENU_LINKS.CUSTOMER_LOGIN_SUB_LINKS, theme)}
                            {(authenticated && staffMode) && this.renderSubLinks(MENU_LINKS.STAFF_SUB_LINKS, theme)}
                            {!authenticated && this.renderSubLinks(MENU_LINKS.CUSTOMER_SUB_LINKS, theme)}
                        </SubLinksBlock>
                        { authenticated && this.renderUser(staffMode, theme) }
                    </MenuWrapper>
                </FocusTrap>
                {this.state.isCloseModalOpen &&
                    <PrimaryModal onRequestClose={() => this.toggleLogoutModal()}
                        showClose
                        isDialogue
                        ariaDescribedby={'modal-logout-title'}>
                        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                            <Header id={'modal-logout-title'}>LOG OUT?</Header>
                            <ParagraphDescription padding={[7, 0, 32, 0]} fontSize={14}>
                                Are you sure you want to log out <br/>
                                of your account?
                            </ParagraphDescription>
                            <StyledButton onClick={() => this.keepMeLoggedIn()}>
                                Keep Me Logged In
                            </StyledButton>
                            <Button variant={'simpleBlack'} onClick={() => this.handleLogout()}>
                                log Me Out
                            </Button>
                        </Flex>
                    </PrimaryModal>
                }
                <MenuSpacer onClick={this.closeMenu}/>
            </PageWrapper>
        );
    }
}

const mapStateToProps = ({ logout: { isLoggedOut }, user: { auth: { authenticated, isStaff, profile, isImpersonating, isFreelancer } }, virtualServices: { displayVirtualPaymentsPage }, gifts: { displayGiftsPage }, booking: { sessions }, membership: { displayMembershipPage } }) => ({
    authenticated,
    isLoggedOut,
    staffMode: !isImpersonating && isStaff,
    profile,
    isFreelancer,
    displayVirtualPaymentsPage,
    displayGiftsPage,
    displayMembershipPage,
    sessions,
    isMember: isMyAtelierMember(profile?.vip)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { analyticsTag },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(
    HamburgerMenu
);
