// TODO: Some of the below URLs are hard coded in Prismic, MAKE SURE TO CHECK PRISMIC
// TODO: most importantly: footer and home page URLs
export const ROUTES = {
    CHECK_IN: '/check-in',
    CHECK_IN_FORM: '/check-in/form',
    CHECK_IN_CONFIRMATION: '/check-in/confirmation',
    CHECK_IN_LOGIN: '/check-in/login',
    CHECK_IN_TOKEN_ERROR: '/check-in/token-error',
    NOT_FOUND: '/not-found',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    CONFIRM_PROFILE: '/confirm-profile',
    PRODUCT_SEARCH: '/product/search',
    ACCOUNT_INFORMATION: '/profile/account',
    PROFILE: '/profile',
    WORKPLANS: '/beautyplans',
    PRODUCTS: '/products',
    PRODUCT_LISTING: '/productlisting',
    PRODUCT_DESCRIPTION: '/product',
    STAFF_OUR_GUESTS: '/profile?tab=1',
    HOUSE_RULES: '/profile?notification=houseRules',
    PROFILE_WELCOME_BACK: '/profile?notification=welcome',
    TRIED_LIST_SHORTLINK: '/tl',
    TRIED_LIST: '/triedlist',
    RESET_PASSWORD: '/reset-password',
    CART: '/cart',
    BACK_OF_HOUSE: '/back-of-house',
    STAFF_LOGIN: '/us/staff',
    BEAUTY_PLAN: '/beauty-plan',
    MY_BEAUTY_PLANS: '/my-beauty-plans',
    QUESTIONNAIRE: '/questionnaire',
    EVENT_QUESTIONNAIRE: '/event/questionnaire',
    CHECKOUT: '/checkout',
    VISIT_ATELIER: '/visit-the-atelier',
    NEW_TO_ATELIER: '/new-to-the-atelier',
    DISCOVER: '/discover',
    HELP_FAQ: '/help-faq',
    CAREERS: '/careers',
    CONTACT: '/customer-service-contact-us',
    FRAGRANCE: '/fragrance',
    FRAGRANCE_SELECTOR: '/fragrance/selector',
    FRAGRANCE_REVEAL: '/fragrance/reveal',
    FRAGRANCE_QUESTIONNAIRE: '/questionnaire/fragrance-experience',
    FRAGRANCE_QUESTIONNAIRE_STAFF: '/questionnaire/fragrance-experience/staff',
    HOME: '/',
    SERVICE_OFFER: '/services',
    MAKEUP_APPLICATION_AND_LESSONS: '/makeup-application-and-lessons',
    STAFF_CUSTOMER_CHECKIN: '/staff-customer-checkin',
    ORDERS_READY: '/orders-ready',
    CHECKIN_COMPLETE: '/checkin-complete',
    PAST_PURCHASES: '/past-purchases',
    EVENTS: '/events',
    EVENTS_SELECTION: '/events/selection',
    EVENTS_SELECTED: '/events/selected',
    EVENTS_PAYMENT: '/events/payment',
    EVENTS_CONFIRMATION: '/events/confirmation',
    EVENT_ALREADY_ATTENDING: '/events/already-attending',
    MY_EVENTS: '/my-events',
    APPOINTMENT_BOOKING: '/book',
    APPOINTMENT_FOLLOWUP_BOOKING: '/book/followup',
    APPOINTMENT_BOOKING_REVIEW: '/book/review',
    APPOINTMENT_BOOKING_CONFIRMATION: '/book/confirmation',
    BOOKING: '/booking',
    BOOKING_SELECTION: '/booking/selection',
    BOOKING_SELECTED: '/booking/selected',
    BOOKING_REVIEW: '/booking/review',
    BOOKING_PAYMENT: '/booking/payment',
    BOOKING_CONFIRMATION: '/booking/confirmation',
    BOOKING_RSVP: '/rsvp',
    LEGAL_STATEMENT: '/legal-statement',
    TOUCH_SCREEN: '/touch-screen',
    TOUCH_SCREEN_AUSTIN: '/touch-screen-austin',
    APPOINTMENTS: '/appointments',
    BOOKINGS: '/bookings',
    MEMBERSHIP_LANDING: '/membership',
    MEMBERSHIP_APPLY: '/membership/apply',
    MEMBERSHIP_TERMS: '/membership/terms',
    MEMBERSHIP_BOOKING: '/membership/book', // old booking
    MEMBERSHIP_INTAKE_BOOKING: '/membership/intake-booking',
    MEMBERSHIP_FORM: '/membership/form',
    MEMBERSHIP_CONFIG: '/membership/config',
    MEMBERSHIP_STAFF_HOME: '/membership/staff',
    MEMBERSHIP_STAFF_VISIT_QR: '/membership/staff/visit-qr',
    MEMBERSHIP_STAFF_RECYCLE_QR: '/membership/staff/recycle-qr',
    MEMBERSHIP_STAFF_MEMBER_LISTING: '/membership/staff/member-listing',
    MEMBERSHIP_STAFF_MINT_TOKEN: '/membership/staff/mint-token',
    MEMBERSHIP_PAYMENT: '/membership/payment',
    MEMBERSHIP_VISIT: '/membership/visit',
    MEMBERSHIP_RECYCLE: '/membership/recycle',
    MEMBERSHIP_RSVP: '/membership/rsvp',
    MEMBERS_HUB: '/my-membership',
    MEMBERS_HUB_BENEFITS: '/my-membership/benefits',
    MEMBERS_HUB_EARN_PETALS: '/my-membership/earn-petals',
    MEMBERS_HUB_BOOK: '/my-membership/book',
    MEMBERS_HUB_CONNECT: '/my-membership/connect',
    MEMBERS_HUB_CHAT: '/my-membership/chat',
    MEMBERS_HUB_LEVELS: '/my-membership/levels',
    MEMBERS_HUB_FRIEND_PASSES: '/my-membership/friend-passes',
    MEMBERS_HUB_SOCIAL_ACCOUNTS: '/my-membership/social-accounts',
    GIFTS: '/gifts',
    GIFTS_PURCHASE: '/gifts/purchase',
    GIFTS_REVIEW: '/gifts/review',
    GIFTS_CONFIRMATION: '/gifts/confirmation',
    SUBMIT_PAYMENT: '/submit-payment',
    SUBMIT_PAYMENT_CONFIRMATION: '/submit-payment/confirmation',
    VIRTUAL_SERVICES_PAYMENTS: '/virtual-services/payments',
    VIRTUAL_SERVICES_SKINCARE_WORKSHOPS: '/virtual-services/skincare-workshops',
    VIRTUAL_SERVICES_BOOKINGS: '/virtual-services/bookings',
    RSVP: '/rsvp',
    RSVP_REVIEW: '/rsvp/review',
    RSVP_CONFIRMATION: '/rsvp/confirmation',
    RSVP_QUESTIONNAIRE: '/rsvp/questionnaire',
    VIDEO_START: '/video/start',
    VIDEO_ARTIST_START: '/video/artist-start',
    VIDEO_THANK_YOU: '/video/thank-you',
    SERVER_ERROR: '/500',
    PRISMIC_PREVIEW: '/preview',
    STATUS: '/_status',
    REFUNDS: '/refunds',
    RETURNED_MAILERS: '/returned-mailers',
    MIX_AND_MATCH: '/mix-and-match',
    N1_DE_CHANEL: '/n1-de-chanel',
    CURATED_GIFTS: '/curated-gifts',
    GUEST_BOOK_REVIEW: '/guest-book/review',
    GUEST_BOOK_CONFIRMATION: '/guest-book/confirmation',
    GUEST_BOOK_CANCELLED: '/guest-book/cancelled',
    APP_APPOINTMENT_PAYMENT: '/mobile/pay-appointment',
    APP_EVENT_PAYMENT: '/mobile/pay-event',
    APP_SUCCESS_PAYMENT: '/mobile/pay-success',
    APP_ERROR_PAYMENT: '/mobile/pay-error',
    SHOPPING_FAQ: '/help-faq/shopping',
    BEAUTY_PREFERENCES: '/beauty-preferences',
    QUICK_SHOP: '/quick-shop',
    QUICK_SHOP_PDP: '/quick-shop/product',
    QUICK_SHOP_FAVORITES: '/quick-shop/like',
    QUICK_SHOP_BAG: '/quick-shop/cart',
    TRACKING_EMAIL: '/tracking-email',
    APP: '/app',
    EXT: {
        DOTCOM_PRIVACY_POLICY: 'https://services.chanel.com/en_US/policies/privacy',
        MB_PRIVACY_POLICY: 'http://www.mindbodyonline.com/privacy-policy',
        MB_TERMS_CONDITIONS: 'https://www.mindbodyonline.com/consumer-agreement'
    },
};
