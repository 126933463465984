import styled, { css } from 'styled-components';
import { styledProps, rem, greaterThan } from 'core/styled';
import { breakpoints } from 'constants/theme';

export default styled.p`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(10)};
    line-height: 1.29;
    ${({ center }) => center ? 'text-align: center;' : ''}
    margin: 0 0 ${rem(10)} 0;
    text-transform: uppercase;

    ${greaterThan(breakpoints.medium)(css`
        text-align: initial;
    `)}
`;
