/**
 * Pick a random item from an array
 *
 * @param {array<T>} array - array to get element to be picked
 * @returns {T} item picked from array
 */
export const pickRandomArrayItem = array => {
    return array[Math.floor(Math.random() * array.length)];
};

export const pickMultipleRandomArrayItems = (array, items) => {
    const shuffled = [...array].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, items);
};

export default pickRandomArrayItem;
