import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import user from './user';
import login from './login';
import registration from './registration';
import logout from './logout';
import resetPassword from './resetPassword';
import checkIn from './checkIn';
import workshop from './workshop';
import secretKey from './setSecretKey';
import elasticsearch from './elasticsearch';
import profileModules from './profileModules';
import cart from './cart';
import zones from './zones';
import globalLayout from './globalLayout';
import backOfHouseOrders from './backOfHouse';
import userList from './userList';
import makeupArtists from './makeupArtists';
import prismicReducer from './prismicFetch';
import productListing from './productListing';
import PDPProduct from './PDPProduct';
import productSearch from './productSearch';
import questionnaire from './questionnaire';
import customerSearch from 'reducers/customerSearch';
import beautyplan from 'reducers/beautyplan';
import pastPurchases from 'reducers/pastPurchases';
import customerLookup from 'reducers/customerProfileLookup';
import booking from 'reducers/booking';
import events from 'reducers/events';
import touchScreen from './touchScreen';
import staff from './staff';
import fragranceApp from './fragranceApp';
import services from './services';
import myPasses from './myPasses';
import submitPayment from './submitPayment';
import virtualServices from './virtualServices';
import rsvp from './rsvp';
import gifts from './gifts';
import transactions from './transactions';
import video from './video';
import products from './products';
import mailer from './mailer';
import membership from './membership';
import recommendations from './recommendations';
import config from './config';
import memberHub from './memberHub';

const authPersistConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['auth']
};

const workshopPersistConfig = {
    key: 'workshop',
    storage: storage
};

const secretKeyPersistConfig = {
    key: 'secretKey',
    storage: storage
};

const checkinPersistConfig = {
    key: 'checkIn',
    storage,
    whitelist: ['checkInData']
};

const userListPersistConfig = {
    key: 'userList',
    storage,
    whitelist: ['like']
};

const reducers = (routerReducer) => combineReducers({
    router: routerReducer,
    user: persistReducer(authPersistConfig, user),
    workshop: persistReducer(workshopPersistConfig, workshop),
    prismic: prismicReducer,
    secretKey: persistReducer(secretKeyPersistConfig, secretKey),
    config,
    login,
    registration,
    logout,
    resetPassword,
    checkIn: persistReducer(checkinPersistConfig, checkIn),
    elasticsearch,
    profileModules,
    cart,
    zones,
    globalLayout,
    backOfHouseOrders,
    userList: persistReducer(userListPersistConfig, userList),
    makeupArtists,
    pastPurchases,
    products,
    productSearch,
    productListing,
    PDPProduct,
    questionnaire,
    customerSearch,
    customerLookup,
    beautyplan,
    booking,
    events,
    touchScreen,
    staff,
    fragranceApp,
    services,
    myPasses,
    submitPayment,
    virtualServices,
    rsvp,
    gifts,
    transactions,
    video,
    mailer,
    membership,
    recommendations,
    memberHub
});

export default reducers;
