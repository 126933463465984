import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ICON_DATA, ICON_VARIANTS, ICON_TYPES } from './IconVariants';
import Clickable from 'components/GlobalComponents/Clickable/Clickable';
import preloadImages from 'core/utils/preloadImages';
import { styledProps } from 'core/styled';

export const MAX_FRAGRANCES = 3;
export const APPEAR_DURATION = 300;
export const DISAPPEAR_DURATION = 400;
export const TRANSITION_NAME = `fade`;

const IconContainer = styled.div`
  display: ${({ nonFlexContainer }) => nonFlexContainer ? 'block' : 'flex'};
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  flex-direction: column;
  cursor: ${({ cursor }) => cursor ? cursor : 'pointer'};
   
   :after {
        display: ${({ displayAnimatedDot }) => displayAnimatedDot ? 'block' : 'none'};
        background-color: ${({ variant }) =>  variant === ICON_VARIANTS.LIGHT ? styledProps('color', 'black') : styledProps('color', 'white')};
        border-radius: 50%;
        content: "";
        height: 5px;
        margin-top: -10px;
        position: absolute;
        right: -6px;
        top: auto;
        width: 5px;
        animation: ${({ successPositive }) => successPositive ? '.7s ease-in-out 8 alternate pulse' : 'none'};
    }
    
    @keyframes pulse{
        0% {
            transform:scale(1);
            opacity:1
        }
        to { 
            transform:scale(.8);
            opacity:.3
        }
    }
`;

const SuccessMessage = styled.span`
    animation: ${({ show }) => show ? 'fadeinout 1s linear forwards' : 'none'};
    opacity: ${props => props.show ? 1 : 0 };
    text-transform: uppercase;
    font-family: ${styledProps('font', 'CervoNeueNeueThin')};   
    white-space: nowrap;

    @keyframes fadeinout {
      0%,100% { opacity: 0; }
      50% { opacity: 1; }
    }
`;

export default class Icon extends Component {
    static propTypes = {
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
        added: PropTypes.bool,
        type: PropTypes.oneOf(Object.values(ICON_TYPES)),
        variant: PropTypes.oneOf(Object.values(ICON_VARIANTS)), // Light/Dark
        pixelHeight: PropTypes.number,
        preload: PropTypes.bool,
        successPositive: PropTypes.bool,
        successNegative: PropTypes.bool,
        renderSuccessMessage: PropTypes.bool,
        showAnimatedDot: PropTypes.bool,
        onClick: PropTypes.func,
        nonFlexContainer: PropTypes.bool,
        dotAnimationKey: PropTypes.any, //need a unique key to trigger dot animation
        alt: PropTypes.string,
        role: PropTypes.string,
        cursor: PropTypes.string,
        ignoreSelectedForDot: PropTypes.bool
    };

    static defaultProps = {
        selected: false,
        added: false,
        variant: ICON_VARIANTS.LIGHT,
        pixelHeight: 95,
        preload: true,
        renderSuccessMessage: false,
        showAnimatedDot: false,
        nonFlexContainer: false,
        role: 'img',
        ignoreSelectedForDot: false
    };

    state = {
        successPositive: this.props.successPositive,
        successNegative: this.props.successNegative
    };

    events = {
        mouseEnter: { isHovered: true },
        mouseLeave: { isHovered: false, isClicked: false },
        mouseDown: { isClicked: true },
        mouseUp: { isClicked: false }
    };

    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };

        if (this.props.preload) {
            preloadImages(Object.values(ICON_DATA[props.type][props.variant]));
        }
    }

    onMouseEnter = () => {
        this.setState(this.events.mouseEnter);
    };

    onMouseLeave = () => {
        this.setState(this.events.mouseLeave);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.successPositive !== this.props.successPositive) {
            this.setState({
                successPositive: this.props.successPositive
            });
        }

        if (prevProps.successNegative !== this.props.successNegative) {
            this.setState({
                successNegative: this.props.successNegative
            });
        }
    }

    getIconText() {
        const { successPositive, successNegative } = this.state;
        const { type } = this.props;

        switch (true) {
            case (successPositive):
                return ICON_DATA[type].text.successPositive;
            case (successNegative):
                return ICON_DATA[type].text.successNegative;
            default:
                return '&nbsp;';
        }
    }

    render() {
        const { type, variant, selected, added, disabled, pixelHeight, showAnimatedDot, renderSuccessMessage, dotAnimationKey, onClick, nonFlexContainer, alt, role, cursor, ignoreSelectedForDot } = this.props;
        const { isHovered, successPositive, successNegative } = this.state;
        const iconBase = ICON_DATA[type][variant];
        const displayAnimatedDot = showAnimatedDot && (selected || ignoreSelectedForDot);

        let icon;

        switch (true) {
            case added:
                icon = iconBase.added;
                break;
            case disabled:
                icon = iconBase.disabled;
                break;
            case isHovered:
                icon =  iconBase.hover;
                break;
            case selected:
                icon =  iconBase.selected;
                break;
            default:
                icon =  iconBase.default;
                break;
        }

        return (
            <Clickable onClick={onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                {({ loading, ...clickableProps }) => (
                    <IconContainer
                        {...clickableProps}
                        nonFlexContainer={nonFlexContainer}
                        key={dotAnimationKey}
                        height={pixelHeight}
                        displayAnimatedDot={displayAnimatedDot}
                        successPositive={successPositive}
                        variant={variant}
                        cursor={cursor}>
                        <img height={pixelHeight} key={icon} src={icon} alt={alt} role={role}/>
                        {renderSuccessMessage && <SuccessMessage key={this.getIconText()} show={successPositive || successNegative}>{this.getIconText()}</SuccessMessage>}
                    </IconContainer>
                )}
            </Clickable>
        );
    }
}
