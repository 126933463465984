import { createAction, handleActions } from 'redux-actions';

const initialState = { secretKey: '' };

export const setSecretKey = createAction(
    'SET_SECRET_KEY',
    secretKey => secretKey
);

export default handleActions(
    {
        SET_SECRET_KEY: (state, { payload }) => ({ secretKey: payload })
    },
    initialState
);
