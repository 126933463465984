export const EVENTS = {
    GA: 'GAEvent',
    Pageview: 'PageView',
    Ecomm: 'enhancedecommerce'
};

/**
 * Ecommerce product click event
 * @param {object} product
 * @return {object} event
 */
export const eCommProductClick = (product) => {
    return {
        event: 'productClick',
        eventCategory: EVENTS.Ecomm,
        eventAction: 'product click',
        eventLabel: product.sku,
        ecommerce: {
            click: {
                products: [product.ecommerce]
            }
        }
    };
};

export const eCommProductView = (product, isQuickShop) => {
    return {
        event: 'productView',
        eventCategory: EVENTS.Ecomm,
        eventAction: 'product view',
        eventLabel: product.sku,
        page_type: isQuickShop ? 'quickshoppdp' : 'pdp',
        ecommerce: {
            detail: {
                products: [product.ecommerce]
            }
        }
    };
};

export const eCommAddToBag = (product, quantity = 1) => {
    return {
        event: 'addToCart',
        eventCategory: EVENTS.Ecomm,
        eventAction: 'add to cart',
        eventLabel: product.sku,
        ecommerce: {
            currencyCode: 'USD',
            add: {
                products: [{ ...product.ecommerce, quantity }]
            }
        }
    };
};

export const eCommRemoveFromBag = (product, quantity) => {
    return {
        event: 'removeFromCart',
        eventCategory: EVENTS.Ecomm,
        eventAction: 'remove from cart',
        eventLabel: product.sku,
        ecommerce: {
            currencyCode: 'USD',
            remove: {
                products: [{ ...product.ecommerce, quantity }]
            }
        }
    };
};

export const eCommCheckout = (location, products) => {
    return {
        event: 'checkout',
        eventCategory: EVENTS.Ecomm,
        eventAction: 'cart checkout',
        eventLabel: location,
        ecommerce: {
            checkout: {
                actionField: { step: 1, option: location },
                products: products.map(p => {
                    return { ...p.ecommerce, quantity: p.quantity };
                })
            }
        }
    };
};
