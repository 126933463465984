import styled from 'styled-components';
import { styledProps, rem } from 'core/styled';

const SmallNotice = styled.p`
    font-weight: ${({ fontWight }) => fontWight ? fontWight : '600'};
    text-decoration: none;
    color: ${({ fontColor }) => fontColor ? styledProps('color', fontColor) : styledProps('color', 'black')};
    font-family: ${styledProps('font', 'ABChanelPBM')};
    display: ${({ display }) => display ? display : 'block'};
    margin: ${({ margin }) => margin ? rem(...margin) : rem(14, 0, 30, 0)};
    font-size: ${({ fontSize }) => fontSize ? rem(fontSize) : rem(9)};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'center'};
`;

export default SmallNotice;
