import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import { rem, styledProps } from 'core/styled';
import { Flex, Box } from 'components/GlobalComponents/FlexBox';
import Header from 'components/GlobalComponents/Fonts/Header';
import PriceTag from 'components/GlobalComponents/Fonts/product/PriceTag';
import { LAYER } from 'constants/layers';
const sliderGutter = 38;
const slideGutter = sliderGutter / 2;

export const Separator = styled.hr`
  border: solid 1px ${styledProps('color', 'black')};
  margin: ${({ marginTop, marginBottom }) => rem(marginTop ? marginTop : 0, 20, marginBottom ? marginBottom : 0)};
`;

export const BeautyplanContainer = styled(Flex)`
    position: relative;
    ${(props) => props.noBorder ? '' : `border-top: 1px solid ${styledProps('color', 'black')(props)};`}
    ${({ eventBeautyPlan }) => eventBeautyPlan ? `margin: ${rem(0, 30)}` : null};
    
    ${greaterThan(breakpoints.medium)(css`
        width: 100%;
        max-width: ${({ eventBeautyPlan }) => rem(eventBeautyPlan ? 750 : 790)};
        margin: 0 auto;
    `)}

    ${greaterThan(breakpoints.large)(css`
        max-width: ${({ eventBeautyPlan }) => rem(eventBeautyPlan ? 750 : 860)};
    `)}
`;

export const BeautyPlan = styled(Box).attrs(() => ({ flex: 1 }))`
    padding: ${slideGutter}px ${rem(15)};
    display: inline-block;
    vertical-align: top;
    width: 100%;

    img {
        display: block;
        width: 100%;
    }
    
    .titleContainer {
        width: 100%;
        height: ${rem(185)};
    }
    
    ${greaterThan(breakpoints.tiny)(css`
        .titleContainer {
            height: ${rem(250)};
        }
    `)}
    
    ${greaterThan(breakpoints.medium)(css`
        display: flex;
        justify-content: center;
        
        .titleContainer {
            font-size: ${rem(42)};
        }
        
        img, .titleContainer {
            width: 285px;
            height: 285px;
            margin-right: ${rem(25)};
        }
    `)}
    
    ${greaterThan(breakpoints.medium)(css`
        img, .titleContainer {
            width: 365px;
            height: 365px;
        }
    `)}
`;

export const BeautyPlanCaption = styled.div`
    margin-top: ${rem(36)};
    text-transform: uppercase;
    font-size: ${rem(14)};
    text-align: left;
    
    ${greaterThan(breakpoints.medium)(css`
        margin-top: 0;
        max-width: 225px;
    `)}
`;

export const StampImg = styled.img`
    position: absolute;
    transform: rotate(-30deg);
    top: 218px;
    left: 37px;
    width: 110px;
    z-index: ${LAYER.BODY};
    
    ${greaterThan(breakpoints.medium)(css`
        left: 70px;
        top: 190px;
        width: 164px;
    `)}a
`;

export const BeautyplanService = styled(Header)`
    font-size: ${rem(34)}; 
    text-align: left;
    line-height: 0.94;
    
    ${greaterThan(breakpoints.medium)(css`
        font-size: ${rem(38)};
    `)};
`;

export const ArtistSpan = styled(PriceTag)`
    text-align: left;
    
    ${greaterThan(breakpoints.medium)(css`
        font-size: ${rem(12)};
    `)}
`;

export const SmallServiceName = styled(ArtistSpan)`
    margin-bottom: ${rem(15)};
`;

