import { combineActions, createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import moment from 'moment-timezone';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';
import {
    normalizeEvent,
    normalizeEventBooking,
    normalizeEventDetails,
    normalizeMyEvent
} from 'core/utils/normalizeEvents';
import { sortByStartDateDescending } from 'core/utils/normalizeAppointment';
import { setAnalyticsEventData } from 'reducers/checkIn';
import { setAttendedEvents } from 'reducers/user';

const EVENT_LISTING = 'EVENT_LISTING';
const MY_EVENTS = 'MY_EVENTS';
const EVENT_DETAILS = 'EVENT_DETAILS';
const EVENT_SELECTION = 'EVENT_SELECTION';
const EVENT_BOOKING = 'EVENT_BOOKING';
const MY_EVENT_DETAILS = 'MY_EVENT_DETAILS';
const EVENT_CANCEL = 'EVENT_CANCEL';
const SET_EVENT_BOOKING_LOCATION = 'SET_EVENT_BOOKING_LOCATION';
const SET_EVENT_COVID_LEGALS = 'SET_EVENT_COVID_LEGALS';

const initialState = {
    isFetching: false,
    eventsFetched: false,
    isFetchingMyEvents: false,
    isFetchingEventBooking: false,
    cancelLoading: false,
    error: null,
    events: [],
    eventDetails: null,
    myEvents: [],
    myEvent: null,
    currentEventBooking: null,
    confirmedEvent: null,
    eventBookingLocation: null,
    covidLegals: null
};

/**
 * Action to save and event and it's booking information
 * @type {actionCreator}
 */
export const saveCurrentEvent = createAction(EVENT_SELECTION);

export const saveCurrentEventPromise = currentEvent => dispatch => {
    dispatch(saveCurrentEvent(currentEvent));
    return Promise.resolve();
};

/**
 * Get list of scheduled events
 * @type {actionCreator} fetch all events
 */
export const fetchEventListing = createAction(
    FETCH, withPrice => ({
        prefix: EVENT_LISTING,
        endpoint: `${CONFIG.API_URL}/scheduling/events` +
                  `?startDate=${moment().tz('America/New_York').format('YYYY-MM-DD')}` +
                  `&endDate=${moment().tz('America/New_York').add(6, 'month').format('YYYY-MM-DD')}` +
                  `${withPrice ? '&withPrice=true' : ''}`,
        options: {
            method: 'GET'
        }
    })
);

const cancelTickets = createAction(
    FETCH, (tickets, eventId, detailId) => ({
        prefix: EVENT_CANCEL,
        endpoint: `${CONFIG.API_URL}/scheduling/events?visitIds=${tickets.join(',')}&eventId=${eventId}&detailId=${detailId}`,
        options: {
            method: 'DELETE'
        }
    }));

/**
 * Get the details for a given event
 * @type {function} fetch details
 */
export const fetchEventDetails = createAction(FETCH, (id) => ({
    prefix: EVENT_DETAILS,
    endpoint: `${CONFIG.API_URL}/scheduling/events/${id}`,
    options: {
        method: 'GET'
    }
}));

/**
 * Fetch current user events (upcoming / past)
 * @type {actionCreator}
 */
export const fetchUserEvents = createAction(FETCH, () => ({
    prefix: MY_EVENTS,
    endpoint: `${CONFIG.API_URL}/scheduling/myevents`,
    options: {
        method: 'GET'
    }
}));


/**
 * Fetch current user events (upcoming / past)
 * @type {actionCreator}
 */
export const fetchUserUpcomingEvents = createAction(FETCH, () => ({
    prefix: MY_EVENTS,
    endpoint: `${CONFIG.API_URL}/scheduling/myevents` +
        `?startDate=${moment().tz('America/New_York').format('YYYY-MM-DD')}` +
        `&endDate=${moment().tz('America/New_York').add(6, 'month').format('YYYY-MM-DD')}`,
    options: {
        method: 'GET'
    }
}));

/**
 * Schedule event
 * @type {function} schedule event action
 */
const scheduleEvent = createAction(FETCH, (rsvp) => ({
    prefix: EVENT_BOOKING,
    endpoint: `${CONFIG.API_URL}/scheduling/events`,
    options: {
        method: 'POST',
        body: JSON.stringify(rsvp)
    }
}));

/**
 * Fetch a single event details (event already booked)
 * @type {actionCreator}
 */
export const fetchMyEventDetails = createAction(FETCH, (classId) => ({
    prefix: MY_EVENT_DETAILS,
    endpoint: `${CONFIG.API_URL}/scheduling/myevents/${classId}`,
    options: {
        method: 'GET'
    }
}));

/**
 * Book an event
 * @param {object} paymentInformation
 * @param {object} shippingAddress
 * @param {string} giftCode - null or undefined to normal book without giftCode
 * @param {boolean} fromApp - indicates is the booking has been done via the app
 * @return {Function} Thunk
 */
export const bookEvent = (paymentInformation = undefined, shippingAddress = undefined, giftCode = undefined, fromApp = false) => {
    return (dispatch, getState) => {
        const { events: { currentEventBooking: { event, ticketCount }, eventBookingLocation } } = getState();
        const rsvp = {
            classId: event.id,
            numberOfSpots: ticketCount,
            freePassId: event.hasComplimentaryPass ? parseInt(event.complimentaryPass.ID, 10) : undefined,
            addToWaitlist: event.isOnWaitlist,
            creditCardData: paymentInformation,
            shippingAddress,
            giftCode,
            location: eventBookingLocation,
            fromApp
        };

        return dispatch(scheduleEvent(rsvp));
    };
};

/**
 * Cancel all visits for a given event
 * @param {array} tickets - ticket ids to cancel
 * @param {number} eventId - event id to cancel
 * @param {number} detailId - detail id of event to cancel
 * @return {Function} Thunk
 */
export const cancelEvent = (tickets, eventId, detailId) => {
    return (dispatch) => {
        return dispatch(cancelTickets(tickets, eventId, detailId));
    };
};

export const setCurrentEvent = currentEvent => {
    return dispatch => {
        return Promise.resolve(dispatch(saveCurrentEvent(currentEvent)));
    };
};

export const fetchMyEvents = () => (dispatch) => {
    dispatch(fetchUserEvents()).then(events => {
        dispatch(setAnalyticsEventData(events));
    });
};

export const getAttendedEvents = () => (dispatch) => {
    dispatch(fetchUserEvents()).then(events => {
        const attendedEvents = events.map(e => normalizeMyEvent(e))
            .filter(e => {
                const signings = e.myTickets.map(t => t.visits).reduce((acc, val) => acc.concat(val), []).map(t => t.SignedIn);
                return e.isPast && signings.some(s => s);
            }).length;

        dispatch(setAttendedEvents(attendedEvents));
    });
};

export const setEventBookingLocation = createAction(SET_EVENT_BOOKING_LOCATION);
export const setEventCovidLegals = createAction(SET_EVENT_COVID_LEGALS);

export default handleActions({
    [saveCurrentEvent]: (state, { payload }) => ({
        ...state,
        currentEventBooking: payload
    }),
    [`${EVENT_LISTING}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        events: payload.map(normalizeEvent).filter(e => (!(e.isLivestream || e.isHappyHour) && !e.hasStarted) || ((e.isLivestream || e.isHappyHour) && !e.isPast)),
        eventsFetched: true
    }),
    [`${EVENT_DETAILS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        eventDetails: normalizeEventDetails(payload)
    }),
    [`${EVENT_BOOKING}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetchingEventBooking: false,
        error: null,
        confirmedEvent: normalizeEventBooking(payload)
    }),
    [`${MY_EVENTS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetchingMyEvents: false,
        error: null,
        myEvents: payload.map(normalizeMyEvent).sort(sortByStartDateDescending)
    }),
    [`${MY_EVENT_DETAILS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        myEvent: normalizeMyEvent(payload)
    }),
    [`${EVENT_CANCEL}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        cancelLoading: true
    }),
    [`${EVENT_CANCEL}/${FETCH_SUCCESS}`]: (state) => ({
        ...state,
        cancelLoading: false,
        error: null,
        myEvent: null,
        confirmedEvent: null
    }),
    [combineActions(`${EVENT_LISTING}/${FETCH_PENDING}`,
        `${EVENT_DETAILS}/${FETCH_PENDING}`,
        `${MY_EVENT_DETAILS}/${FETCH_PENDING}`)]: (state) => ({
        ...state,
        error: null,
        isFetching: true
    }),
    [ `${EVENT_BOOKING}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        error: null,
        isFetchingEventBooking: true
    }),
    [ `${MY_EVENTS}/${FETCH_PENDING}`]: (state) => ({
        ...state,
        error: null,
        isFetchingMyEvents: true
    }),
    [`${EVENT_BOOKING}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetchingEventBooking: false,
        error: payload
    }),
    [`${MY_EVENTS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetchingMyEvents: false,
        error: payload
    }),
    [`${EVENT_LISTING}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [SET_EVENT_BOOKING_LOCATION]: (state, { payload }) => ({
        ...state,
        eventBookingLocation: payload
    }),
    [SET_EVENT_COVID_LEGALS]: (state, { payload }) => ({
        ...state,
        covidLegals: payload
    })
}, initialState);
