import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import { getEventDatas } from 'constants/events';
import { rem, styledProps } from 'core/styled';
import FormInput from 'components/GlobalComponents/FormInput/FormInput';
import SimpleProductNote from './SimpleProductNote';
import Button from 'components/GlobalComponents/Button/Button';
import { ROUTES } from 'constants/routes';
import { DISCOVER_TABS } from 'constants/discover';

const DEFAULT_IMAGE = 'https://images.prismic.io/atelier/d73574e1-2d6f-411d-83bc-ee40cdc71536_The+Atelier.jpg?auto=compress,format';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const EventItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: ${rem(20, 10)};
    margin-bottom: ${rem(20)};
    position: relative;

    p.bp--eventmemberonly {
        position: absolute;
        top: ${rem(5)};
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        color: ${styledProps('color', 'milanoRed')};
        font-size: ${rem(10)};
    }

    img {
        width: ${rem(150)};
        height: ${rem(150)};
        object-fit: cover;
        margin-bottom: ${rem(10)};
    }

    p.bp--eventname {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-weight: bold;
        font-size: ${rem(14)};
        margin: ${rem(5, 0, 3)};
    }

    p.bp--eventdatetime {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(10)};
        margin: ${rem(10, 0, 0)};
    }

    p.bp--eventwith {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        font-size: ${rem(10)};
        margin: ${rem(2, 0, 0)};
    }

    p.bp--eventdescription {
        text-align: center;
        font-family: ${styledProps('font', 'SofiaPro')};
        font-size: ${rem(14)};
        line-height: 1.5;
        margin: ${rem(15, 0, 0)};
        width: 100%;
        max-width: ${rem(300)};
    }

    p.bp--eventprice {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-size: ${rem(10)};
        font-weight: bold;
        margin: ${rem(5, 0, 0)};
    }

    p.bp--eventmemberdescription {
        text-align: center;
        font-family: ${styledProps('font', 'SofiaPro')};
        font-size: ${rem(14)};
        line-height: 1.5;
        margin: ${rem(10, 0, 0)};
        width: 100%;
        max-width: ${rem(300)};
    }

    p.bp--eventmemberlogo {
        text-align: center;
        font-family: ${styledProps('font', 'ABChanelCorpo')};
        letter-spacing: .15em;
        font-size: ${rem(16)};
        margin: ${rem(0)};

        span {
            color: ${styledProps('color', 'milanoRed')};
        }
    }
`;

const RemoveButton = styled(NoStyleButton)`
    position: absolute;
    left: calc(50% + ${rem(100)});
    transform: translateX(-50%);
    top: 0;
    width: 15px;
    height: 15px;
    z-index: 3;
    cursor: pointer;
    
    &:before,
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        border-top: 1px solid ${styledProps('color', 'black')};
        position: absolute;
        left: 0;
        top: 7px;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

const StyledFormInputWrapper = styled.div`
    margin: ${rem(20, 'auto', 30)};
    text-align: left;
    border: ${rem(1)} solid black;
    min-height: ${rem(100)};
    max-width: ${rem(300)};
    width: 100%;

    > div {
        margin-top: ${rem(-15)};
    }
`;

const NoteInput = styled(FormInput)`
    line-height: 1.5;
`;

const SmallButton = styled(Button).attrs({
    type: 'button',
    small: true
})`
    margin: ${rem(15, 'auto')};
`;

export default class SimpleBeautyPlanEventList extends Component {

    static propTypes = {
        events: PropTypes.array.isRequired,
        removeEvent: PropTypes.func,
        productNote: PropTypes.bool,
        updateEventNote: PropTypes.func,
        viewMode: PropTypes.bool,
        artist: PropTypes.string,
        push: PropTypes.func
    };

    static defaultProps = {
        push: () => {}
    };

    constructor(props) {
        super(props);
        this.eventDatas = getEventDatas();
    }

    removeEvent = eventItem => {
        this.props.removeEvent(eventItem);
    };

    addEventNote = event => e => {
        const { updateEventNote } = this.props;

        updateEventNote({
            event,
            note: e.target.innerHTML
        });
    };

    handleButtonClick = (memberOnly) => () => {
        const { push } = this.props;

        if (memberOnly) {
            push(ROUTES.MEMBERSHIP_LANDING);
        } else {
            push(`${ROUTES.DISCOVER}/${DISCOVER_TABS.CALENDAR}`);
        }
    };

    renderEvent = (item, index) => {
        const { viewMode, artist } = this.props;
        const eventData = this.eventDatas[item.event.detailId];
        const image = eventData && eventData.obsess_image && eventData.obsess_image.url ?
            eventData.obsess_image.url : DEFAULT_IMAGE;
        const isMemberOnly = eventData && eventData.service_type && eventData.service_type === 'member_only';
        const eventType = eventData ? eventData.new_listing_event_sub_name : null;

        if (viewMode) {
            return (
                <EventItem key={index}>
                    <img src={image} alt={''}/>
                    <p className={'bp--eventdatetime'}>{item.event.date}</p>
                    <p className={'bp--eventname'}>{item.event.name}</p>
                    {eventType && <p className={'bp--eventwith'}>{eventType}</p>}
                    <p className={'bp--eventwith'}>With {item.event.staffName}</p>
                    {eventData && eventData.description && <p className={'bp--eventdescription'}>{eventData.description}</p>}
                    {!isMemberOnly && eventData && (eventData.price || eventData.price === 0) && <p className={'bp--eventprice'}>{eventData.price === 0 ? 'Complimentary' : `$${eventData.price}`}</p>}
                    {isMemberOnly && <p className={'bp--eventmemberdescription'}>Reserved for My Atelier members</p>}
                    {isMemberOnly && <p className={'bp--eventmemberlogo'}><span>MY</span> ATELIER</p>}
                    <SmallButton variant={isMemberOnly ? 'primaryRed' : 'primary'} onClick={this.handleButtonClick(isMemberOnly)}>{isMemberOnly ? 'Become a member' : 'Learn more and book'}</SmallButton>
                    {item.note && <SimpleProductNote note={item.note} artist={artist} />}
                </EventItem>
            );
        }

        return (
            <EventItem key={index}>
                <RemoveButton onClick={() => this.removeEvent(item)} />
                {isMemberOnly && <p className={'bp--eventmemberonly'}>Members only</p>}
                <img src={image} alt={''}/>
                <p className={'bp--eventdatetime'}>{item.event.date}</p>
                <p className={'bp--eventname'}>{item.event.name}</p>
                {eventType && <p className={'bp--eventwith'}>{eventType}</p>}
                <p className={'bp--eventwith'}>With {item.event.staffName}</p>
                {eventData && eventData.description && <p className={'bp--eventdescription'}>{eventData.description}</p>}
                {eventData && (eventData.price || eventData.price === 0) && <p className={'bp--eventprice'}>${eventData.price}</p>}
                {this.props.productNote &&
                    <StyledFormInputWrapper>
                        <NoteInput
                            key={`${index}-${item.note}`}
                            id={`beautyplan-eventnote_${index}`}
                            placeholder={'Add event notes here'}
                            inputtextalign={'left'}
                            onBlur={this.addEventNote(item)}
                            value={item.note}
                            renderUnderline={false}
                            hideLabelOnFocus
                            multiLine
                            multiLineInitialBlockHeight={rem(80)}
                            inputPadding={[5, 5]}
                            inputFullWidth
                            inputfont={'SofiaPro'}
                            inputfontsize={12}
                        />
                    </StyledFormInputWrapper>
                }
            </EventItem>
        );
    };

    render() {
        const { events } = this.props;

        // do not render if no events are available
        if (!events || !events.length) {
            return null;
        }

        return (
            <Wrapper>
                {events.map(this.renderEvent)}
            </Wrapper>
        );
    }
}
