import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styledProps, rem, greaterThan } from 'core/styled';
import styled, { css } from 'styled-components';
import Header from 'components/GlobalComponents/Fonts/Header';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import SimpleBeautyPlanProductCard from './SimpleBeautyPlanProductCard';
import { BEAUTYPLAN_CONFIG } from 'constants/beautyplan';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { breakpoints } from 'constants/theme';
import PREV_ARROW from 'assets/beautyplan/prev-bp.svg';
import NEXT_ARROW from 'assets/beautyplan/next-bp.svg';

const CategoryContainer = styled.div`
    padding-top: ${rem(5)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${rem(0, 30)};
    max-width: ${rem(1098)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${rem(20, 'auto', 60)};
`;

const SubSectionContainer = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledHeader = styled(Header).attrs({
    as: 'h3'
})`
    font-family: ${styledProps('font', 'ABChanelPBM')};
    font-weight: bold;
    padding: 0 ${rem(20, 5, 20)};
    position: relative;
    font-size: ${({ isSubSection }) => rem(isSubSection ? 18 : 12)};
`;

const NoteWrapper = styled.div`
    padding-top: ${rem(30)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: ${rem(1)} solid ${styledProps('color', 'lightGray')};
    margin-bottom: ${rem(30)};
`;

const Note = styled.div`
    width: 100%;
    max-width: ${rem(300)};
    font-family: ${styledProps('font', 'default')};
    font-size: ${rem(14)};
    text-align: center;
    line-height: 1.5;
    margin: ${rem(0, 'auto', 50)};
`;

const StyledDivider = styled.div`
    width: 100%;
    height: ${rem(1)};
    background-color: ${styledProps('color', 'black')};
    margin-top: ${rem(10)};

    &.bp--ttabg-servicedivider {
        margin: ${rem(10, 'auto', 30)};
    }

    &.bp--ttabg-notedivider {
        margin: ${rem(10, 'auto', 30)};
    }
`;

const ProductsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${rem(346)};

    ${greaterThan(breakpoints.medium)(css`
        max-width: ${rem(692)};
    `)}

    ${greaterThan(breakpoints.large)(css`
        max-width: ${rem(1038)};
    `)}
`;

const Divider = styled.div`
    height: ${rem(1)};
    width: 100%;
    margin-bottom: ${rem(30)};
    background-color: ${styledProps('color', 'lightGray')};
`;

const StyledSlider = styled(Slider)`
    width: 100%;

    .slick-arrow {
        background-repeat: no-repeat;
        background-position: center;
        z-index: 10;
        height: ${rem(40)};
        width: ${rem(40)};

        &.slick-prev {
            background-image: url(${PREV_ARROW});
            left: ${rem(-10)};
        }

        &.slick-next {
            background-image: url(${NEXT_ARROW});
            right: ${rem(-10)};
        }

        &.slick-disabled {
            opacity: 0.25;
        }

        &:before {
            content: '';
        }
    }

    .slick-dots {
        bottom: ${rem(10)};
    }
`;

const sliderSettings = {
    dots: true,
    infinite: false,
    autoplay: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1099,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 799,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

class NewSimpleRoutineCustomer extends Component {
    static propTypes = {
        products: PropTypes.array,
        push: PropTypes.func.isRequired,
        staffFirstName: PropTypes.string.isRequired,
        notes: PropTypes.array,
        subSectionNotes: PropTypes.array,
        sectionConfig: PropTypes.string.isRequired
    };

    renderSubsection = (subSection, index) => {
        const { staffFirstName } = this.props;

        return (
            <SubSectionContainer key={subSection.id}>
                {index !== 0 && <Divider />}
                <StyledHeader isSubSection>{subSection.title}</StyledHeader>
                {subSection.note && subSection.note.note && (
                    <>
                        <StyledHeader>
                            A note from {staffFirstName}
                        </StyledHeader>
                        <Note
                            dangerouslySetInnerHTML={{
                                __html: subSection.note.note
                            }}
                        />
                    </>
                )}
                <ProductsWrapper>
                    <StyledSlider { ...sliderSettings }>
                        {subSection.products.map((product, index) => (
                            <SimpleBeautyPlanProductCard
                                isEven={index % 2 === 0}
                                key={index}
                                product={product}
                                withProductNote
                                artist={staffFirstName}
                                cssMaxWidth={346}
                                showBatchCode
                            />
                        ))}
                    </StyledSlider>
                </ProductsWrapper>
            </SubSectionContainer>
        );
    };

    renderCategories = () => {
        const { products, subSectionNotes, sectionConfig } = this.props;

        if (!products.length) {
            return null;
        }

        const subSections = BEAUTYPLAN_CONFIG[sectionConfig].categories[0].subSections
            .map(subSection => {
                const productsInSection = products.filter(
                    prod => prod.subSection === subSection.id
                );

                const noteInSection = subSectionNotes.find(
                    subSectionNote => subSectionNote.sectionId === subSection.id
                );

                if (!productsInSection.length && !noteInSection) {
                    return null;
                }

                return {
                    id: subSection.id,
                    title: subSection.title,
                    products: productsInSection,
                    note: noteInSection
                };
            })
            .filter(p => p);

        return (
            <CategoryContainer>
                {subSections.length && subSections.map(this.renderSubsection)}
            </CategoryContainer>
        );
    };

    renderNote = (note, index, title) => (
        <NoteWrapper key={index}>
            {index > 0 && <StyledDivider className={'bp--ttabg-notedivider'} />}
            <StyledHeader>{title}</StyledHeader>
            <Note dangerouslySetInnerHTML={{ __html: note.note }} />
        </NoteWrapper>
    );

    renderNotes = () => {
        const { notes, sectionConfig } = this.props;

        if (notes && notes.length) {
            return this.renderNote(
                notes[0],
                0,
                BEAUTYPLAN_CONFIG[sectionConfig]
                    .textSections[0].title
            );
        }

        return null;
    };

    render() {
        const { products } = this.props;

        if (!products) {
            return null;
        }

        return (
            <Wrapper id="atelier-workplan--newsimpleroutine">
                {this.renderNotes()}
                {this.renderCategories()}
            </Wrapper>
        );
    }
}

const mapStateToProps = ({
    beautyplan: {
        currentPlan: {
            serviceSummaryProducts,
            notes,
            makeupArtistName,
            subSectionNotes
        }
    }
}) => {
    return {
        products: serviceSummaryProducts,
        notes,
        subSectionNotes,
        staffFirstName: makeupArtistName.split(' ')[0]
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ push }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewSimpleRoutineCustomer);
