
// Names for all possible variants of swatch checkbox components
// having them in a separate file will allow loading a checkbox by name only on any page
export const SWATCH_CHECKBOX_NAMES = {
    BLUE_EYE_1: 'blue-eye-1',
    BLACK_EYE_2: 'black-eye-2',
    BLACK_EYE_3: 'black-eye-3',
    BLACK_EYE_8: 'black-eye-8',
    BLACK_EYE_9: 'brown-eye-9',
    BLACK_EYE_10: 'black-eye-10',
    BLACK_EYE_11: 'black-eye-11',
    BLUE_EYE_4: 'blue-eye-4',
    BROWN_EYE_5: 'brown-eye-5',
    WHITE_EYE_6: 'white-eye-6',
    WHITE_EYE_7: 'white-eye-7',
    BLACK_EYE_12: 'black-eye-12',
    WHITE_EYE_13: 'white-eye-13',
    WHITE_EYE_14: 'white-eye-14',
    EYE_CREAM_15: 'eye-cream-15',
    EYE_POWDER_16: 'eye-powder-16',


    RED_LIPS_1: 'red-lips-1',
    RED_LIPS_2: 'red-lips-2',
    NUDE_LIPS_3: 'nude-lips-3',
    RED_LIPS_4: 'red-lips-4',
    PINK_LIPS_5: 'pink-lips-5',
    RED_LIPS_6: 'red-lips-6',
    PINK_LIPS_7: 'pink-lips-7',
    PINK_LIPS_8: 'pink-lips-8',
    RED_LIPS_9: 'red-lips-9',
    RED_LIPS_10: 'red-lips-10',
    BROWN_LIPS_11: 'brown-lips-11',
    PINK_LIPS_12: 'pink-lips-12',
    RED_LIPS_13: 'red-lips-13',
    PINK_LIPS_14: 'pink-lips-14',
    RED_LIPS_15: 'red-lips-15',

    PINK_NAILS_1: 'pink-nails-1',
    RED_NAILS_2: 'red-nails-2',
    GREEN_NAILS_3: 'green-nails-3',
    YELLOW_NAILS_4: 'yellow-nails-4',
    BLACK_NAILS_5: 'black-nails-5',

    PINK_POWDER_1: 'pink-powder-1',
    BEIGE_POWDER_2: 'beige-powder-2',
    NUDE_POWDER_3: 'nude-powder-3',
    BEIGE_POWDER_4: 'beige-powder-4',
    BEIGE_POWDER_5: 'beige-powder-5',
    BEIGE_POWDER_6: 'beige-powder-6',
    BEIGE_POWDER_7: 'beige-powder-7',
    NUDE_POWDER_8: 'nude-powder-8',
    BEIGE_POWDER_9: 'beige-powder-9',
    NUDE_POWDER_10: 'nude-powder-10',
    PINK_POWDER_11: 'pink-powder-11',
    YELLOW_POWDER_12: 'yellow-powder-12',

    WHITE_SKINCARE_1: 'white-skincare-1',
    WHITE_SKINCARE_2: 'white-skincare-2',
    WHITE_SKINCARE_3: 'white-skincare-3',
    WHITE_SKINCARE_4: 'white-skincare-4',
    OFF_WHITE_SKINCARE_5: 'off-white-skincare-5',
    OFF_WHITE_SKINCARE_6: 'off-white-skincare-6',
    OFF_WHITE_SKINCARE_7: 'off-white-skincare-7',
    WHITE_SKINCARE_8: 'white-skincare-8',
    OFF_WHITE_SKINCARE_9: 'off-white-skincare-9',

    BLUE_CLEANSE_1: 'blue-cleanse-1',
    WHITE_CLEANSE_2: 'white-cleanse-2',
    WHITE_CLEANSE_3: 'white-cleanse-3',
    WHITE_CLEANSE_4: 'white-cleanse-4',
    WHITE_CLEANSE_5: 'white-cleanse-5',
    BLUE_CLEANSE_6: 'blue-cleanse-6',

    GRAY_SKIN_ENHANCERS_1: 'gray-skin-enhancers-1',
    NUDE_SKIN_ENHANCERS_2: 'nude-skin-enhancers-2',
    BLACK_SKIN_ENHANCERS_3: 'black-skin-enhancers-3',
    BEIGE_SKIN_ENHANCERS_4: 'beige-skin-enhancers-4',
    GREEN_SKIN_ENHANCERS_5: 'green-skin-enhancers-5',
    BEIGE_SKIN_ENHANCERS_6: 'beige-skin-enhancers-6',
    WHITE_SKIN_ENHANCERS_7: 'white-skin-enhancers-7',
    NUDE_SKIN_ENHANCERS_8: 'nude-skin-enhancers-8',

    WHITE_FACE_DEFINERS_1: 'white-face-definers-1',
    PINK_FACE_DEFINERS_2: 'pink-face-definers-2',
    MULTI_COLOR_FACE_DEFINERS_3: 'multi-color-face-definers-3',
    GRAY_FACE_DEFINERS_4: 'gray-face-definers-4',
    NUDE_FACE_DEFINERS_5: 'white-face-definers-5',
    BROWN_FACE_DEFINERS_6: 'brown-face-definers-6',

    BLACK_HAIR_TEXTURE_1: 'black-hair-texture-1',
    BLACK_HAIR_TEXTURE_2: 'black-hair-texture-2',

    OTHERS_1: 'others-1',
    OTHERS_2: 'others-2',
    OTHERS_3: 'others-3',
    OTHERS_4: 'others-4',
    OTHERS_5: 'others-5'
};

export const SWATCH_IMAGE_CATEGORIES = {
    EYE: 'EYE',
    LIPS: 'LIPS',
    NAILS: 'NAILS',
    POWDER: 'POWDER',
    SKINCARE: 'SKINCARE',
    CLEANSE: 'CLEANSE',
    SKIN_ENHANCERS: 'SKIN_ENHANCERS',
    FACE_DEFINERS: 'FACE_DEFINERS',
    HAIR: 'HAIR'
};

export const SWATCH_COLOR_GROUP = {
    BLUE: 'BLUE',
    RED: 'RED',
    WHITE: 'WHITE',
    OFF_WHITE: 'OFF_WHITE',
    PINK: 'PINK',
    YELLOW: 'YELLOW',
    BEIGE: 'BEIGE',
    GREEN: 'GREEN',
    BLACK: 'BLACK',
    BROWN: 'BROWN',
    NUDE: 'NUDE',
    GRAY: 'GRAY',
    MULTI_COLOR: 'MULTI_COLOR'
};
