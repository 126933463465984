import { createAction, handleActions } from 'redux-actions';
import { fetchAllDocumentsByType } from './prismicFetch';

const SET_HOSTS_DATA = 'SET_HOSTS_DATA';

export const setHostsData = createAction(SET_HOSTS_DATA);

export const fetchStaffHosts = () => {
    return (dispatch) => {

        return dispatch(fetchAllDocumentsByType('host')).then((response) => {
            dispatch(setHostsData(response.results.map(hostData => {
                return {
                    firstName: hostData.data.first_name,
                    lastName: hostData.data.last_name,
                    image: hostData.data.image.url,
                    staffId: hostData.data.mindbody_id || ''
                };
            })));
        });
    };
};

export default handleActions({
    [setHostsData]: (state, { payload }) => ({ ...state, hosts: payload }),
}, { hosts: [] });
