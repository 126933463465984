import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import connect from 'react-redux/es/connect/connect';
import styled, { css } from 'styled-components';
import { ROUTES } from 'constants/routes';
import { styledProps, rem, greaterThan } from 'core/styled';
import { triggerAutocompleteSearch, resetAutocompleteResults } from 'reducers/productSearch';
import { TextInput, InputContainer } from 'components/GlobalComponents/FormInput/FormInput.styled';
import { LAYER } from 'constants/layers';
import { breakpoints, theme } from 'constants/theme';
import { ICON_DATA, ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import { KEY_CODES } from 'constants/keycodes';
import { isFirefox } from 'core/utils/browserDetect';

const StyledInputContainer = styled(InputContainer)`
    position: absolute;
    transition: top 0.25s ease-in, opacity 0.25s ease-in, visibility 0.25s ease-in;
    top: ${({ isOpen, useParfumLogo }) => isOpen ? (useParfumLogo ? '94px' : '60px') : '0px'};
    left: 0;
    background: ${({ backgroundColor }) => backgroundColor};
    z-index: ${LAYER.HEADER - 1};
    opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
    border-bottom: 1px solid ${styledProps('color', 'dustyGrey')};
    border-top: 1px solid ${styledProps('color', 'dustyGrey')};
    visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};

    ${greaterThan(breakpoints.small)(css`
        top: ${({ isOpen, useParfumLogo }) => isOpen ? (useParfumLogo ? '94px' : '80px') : '0px'};
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
    `)}
`;

const SearchInput = styled(TextInput)`
    border: 1px solid ${styledProps('color', 'dustyGrey')};
    width: 100%;
    text-transform: uppercase;
    height: 44px;
    font-family: ${styledProps('font', 'ABChanelPBL')};
    ${({ invertedColor }) => invertedColor ? `color: ${theme.color.white};` : ''}
    border-radius: 0;
    background-clip: padding-box;
    
    &::placeholder {
        color: ${({ invertedColor }) => styledProps('color', invertedColor ? 'white' : 'adaGrey')};
        text-transform: none;
        font-family: ${styledProps('font', 'SofiaPro')};
        font-size: ${rem(13)};
        font-stretch: normal;
        line-height: ${isFirefox() ? '40px' : '22px'};
        letter-spacing: normal;
        opacity: 1;
    }
`;

const ClearSearchNoStyleButton = styled(NoStyleButton)`
    position: absolute;
    top: ${rem(10)};
    right: ${rem(8)};
    width: ${rem(40)};
    height: ${rem(44)};
    z-index: 10;

    ${greaterThan(breakpoints.small)(css`
        right: ${rem(32)};
    `)}
`;

const ClearSearchIcon = styled.img`
    position: relative;
    height: ${rem(12)};
    width: ${rem(12)};
    right: ${rem(-5)};
`;

const AutocompleteSuggestions = styled.div`    
    ul {
        li {
            display: block;
            padding: ${rem(10)};
            cursor: pointer;
            
            div {
                box-sizing: border-box;
            }
        }
    }
`;

const AutocompleteText = styled.a`
    padding: ${rem(5)};
    text-transform: uppercase;
    ${({ invertedColor }) => invertedColor ? `color: ${theme.color.white};` : `color: ${theme.color.black};`}
    
    &:focus {
        background-color: ${({ invertedColor }) => invertedColor ? theme.color.white : theme.color.black};
        color: ${({ invertedColor }) => invertedColor ? theme.color.black : theme.color.white};
    }
`;

class HeaderSearch extends Component {
    static propTypes = {
        push: PropTypes.func.isRequired,
        triggerAutocompleteSearch: PropTypes.func.isRequired,
        resetAutocompleteResults: PropTypes.func.isRequired,
        autocompleteResults: PropTypes.array.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onRequestClose: PropTypes.func,
        useParfumLogo: PropTypes.bool,
        forwardRef: PropTypes.object,
        searchButtonRef: PropTypes.object
    };

    state = {
        search: ''
    };

    componentDidMount() {
        this.props.resetAutocompleteResults();
    }

    componentDidUpdate(prevProps, prevState) {
        // if search is cleared out, then reset autocomplete as well
        if (prevState.search !== this.state.search && this.state.search.trim().length === 0) {
            this.props.resetAutocompleteResults();
        }
    }

    /**
     * On search input change
     * @param {object} event
     */
    handleInputChange = event => {
        const value = event.target.value;

        // trigger autocomplete search when more than 2 characters entered
        if (value.length > 2) {
            this.props.triggerAutocompleteSearch(value);
        }

        this.setState({
            search: value
        });
    };

    /**
     * Handle user search clear button Tap
     */
    handleInputReset = () => {
        const { searchButtonRef } = this.props;

        this.props.onRequestClose();
        this.setState({
            search: '',
        });
        searchButtonRef.current.focus();
    };

    /**
     * Handle triggering search
     * @param {string} search
     */
    handleSubmit(search) {
        if (search.length <= 0) {
            return;
        }
        const { onRequestClose, push, resetAutocompleteResults } = this.props;
        this.handleInputReset();
        resetAutocompleteResults();
        onRequestClose();

        push(`${ROUTES.PRODUCT_SEARCH}?query=${search}`);
    }

    /**
     * Handle up and down keypresses on autosuggest options
     * @param {number} index - current option index focused
     * @param {number} autocompleteLength - amount of options existent
     * @return {Function} - Handler for specific item
     */
    handleKeyDownAutocomplete = (index, autocompleteLength) => e => {
        if (e.keyCode === KEY_CODES.DOWN && index < autocompleteLength - 1) {
            e.preventDefault();
            document.getElementById(`autocomplete-option-${index + 1}`).focus();
        } else if (e.keyCode === KEY_CODES.UP) {
            if (index > 0) {
                e.preventDefault();
                document.getElementById(`autocomplete-option-${index - 1}`).focus();
            } else if (index === 0) {
                e.preventDefault();
                this.props.forwardRef.current.focus();
            }
        } else if (e.keyCode === KEY_CODES.ESC) {
            this.handleInputReset();
        }
    };

    /**
     * Handle down keypress on input
     * @param {number} autocompleteLength - amount of option existent
     * @return {Function} - Handler for input field
     */
    handleKeyDownInput = autocompleteLength => e => {
        if (e.keyCode === KEY_CODES.DOWN && autocompleteLength > 0) {
            e.preventDefault();
            document.getElementById('autocomplete-option-0').focus();
        } else if (e.keyCode === KEY_CODES.ESC) {
            this.handleInputReset();
        }
    };

    render() {
        const { search } = this.state;
        const { autocompleteResults, backgroundColor, isOpen, useParfumLogo, forwardRef } = this.props;

        // Invert input and suggestions colors when background is black
        const invertedColor = backgroundColor === theme.color.black;

        return (
            <StyledInputContainer backgroundColor={backgroundColor} isOpen={isOpen} useParfumLogo={useParfumLogo}>
                <form autoComplete="off" onSubmit={(e) => {
                    e.preventDefault();
                    this.handleSubmit(search);
                }}>
                    <SearchInput
                        id="search"
                        type="text"
                        placeholder="Search by product name or shade"
                        onChange={this.handleInputChange}
                        onSubmit={() => this.handleSubmit(search)}
                        onKeyDown={this.handleKeyDownInput(autocompleteResults.length)}
                        value={search}
                        invertedColor={invertedColor}
                        autoComplete="off"
                        ref={forwardRef}
                        role="search"
                        title="Search by product name or shade"
                    />
                    <ClearSearchNoStyleButton type={'button'} onClick={this.handleInputReset}>
                        <ClearSearchIcon
                            className="closeIcon"
                            src={ICON_DATA[ICON_TYPES.REMOVE].light.disabled}
                            alt={'close search product field'}
                        />
                    </ClearSearchNoStyleButton>
                    {autocompleteResults.length > 0 && <AutocompleteSuggestions>
                        <ul>
                            {
                                autocompleteResults.map((result, index) => {
                                    return (
                                        <li key={index}>
                                            <AutocompleteText href={'#'} invertedColor={invertedColor} id={'autocomplete-option-' + index}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.handleSubmit(result);
                                                }}
                                                onKeyDown={this.handleKeyDownAutocomplete(index, autocompleteResults.length)}>
                                                <strong>{result.substring(0, search.length)}</strong>{result.substring(search.length)}
                                            </AutocompleteText>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </AutocompleteSuggestions>
                    }
                </form>
            </StyledInputContainer>

        );
    }

}

const mapStateToProps = ({ productSearch, globalLayout: { useParfumLogo } }) => ({ ...productSearch, useParfumLogo });

const mapDispatchToProps = dispatch => bindActionCreators({ triggerAutocompleteSearch, resetAutocompleteResults, push }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
