import { createConfirmation } from 'react-confirm';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { confirmModalOptions } from 'constants/confirmationModal';

const confirmFunc = createConfirmation(ConfirmationModal);

// the confirmation is a key to get all the confirm options for the modal
const confirm = (confirmation) => {
    try {
        const confirmationObj = JSON.parse(confirmation);
        return confirmFunc({ ...confirmModalOptions[confirmationObj.key](confirmationObj.param) });
    } catch (e) {
        return confirmFunc({ ...confirmModalOptions[confirmation] });
    }
};

export default confirm;
