import { LOCATION_CHANGE } from 'redux-first-history';
// import { rootElement } from 'index';
import { ROUTES } from 'constants/routes';

const rootElement = document.getElementById('root');

const scrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
};

const location = () => next => action => {
    next(action);

    if (action.type === LOCATION_CHANGE) {
        if (!action.payload.location.pathname.includes(ROUTES.PRODUCT_DESCRIPTION)) {
            rootElement.scrollIntoView(scrollIntoViewOptions);
        }
    }
};

export default location;
