import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { disableScroll, triggerPreventScrollToTop } from 'reducers/globalLayout';
import PropTypes from 'prop-types';
import noop from 'core/utils/noop';
import { LAYER } from 'constants/layers';
import { rem, styledProps } from 'core/styled';
import ModalLogo from './modal-logo.svg';
import styled, { css } from 'styled-components';
import { breakpoints } from 'constants/theme';
import { greaterThan } from 'core/styled/index';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import dotWhite from 'assets/perf-edge-white.svg';
import Button from 'components/GlobalComponents/Button/Button';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import FocusTrap from 'focus-trap-react';
import { KEY_CODES } from 'constants/keycodes';

const rootElement = document.getElementById('root');
const modalRoot = document.getElementById('root-modal');

const ModalWrapper =  styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${LAYER.MODAL};
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    // Using display none here! Setting opacity will cause the modal to be invisible but still scrollable which prevents the page from scrolling.
    // If opacity is needed (for animation) please check scrolling on pages that have a modal, open/close them and check
    // that the page will still scroll.
    display: ${({ isDialogue }) => isDialogue ? 'flex' : ''};
    justify-content: ${({ isDialogue }) => isDialogue ? 'center' : ''};
    align-items: ${({ isDialogue }) => isDialogue ? 'center' : ''};
    padding: ${({ fullScreen }) => fullScreen ? '0' : rem(25)};
    
    ${greaterThan(breakpoints.medium)(css`
        visibility: visible;
        display: ${({ isDialogueDesktop }) => isDialogueDesktop ? 'flex' : 'block'};
        justify-content: ${({ isDialogueDesktop }) => isDialogueDesktop ? 'center' : ''};
        align-items: ${({ isDialogueDesktop }) => isDialogueDesktop ? 'center' : ''};
    `)};
`;

const StyledBackdrop = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    position: fixed;
    touch-action: none;
    background-color: ${({ darkenOverlay }) => darkenOverlay ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)'};
    -webkit-tap-highlight-color: transparent;
`;

const StyledModalContentContainer = styled.div`
    outline: none;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    max-width: ${({ fullScreen, overrideMaxWidth }) => fullScreen ? 'none' : overrideMaxWidth ? rem(overrideMaxWidth) : '600px'};
    height: ${({ fullScreen }) => fullScreen ? '100%' : 'auto'};
    width: 100%;
    -webkit-overflow-scrolling: touch;
    padding: ${({ fullScreen, padding }) => padding ? rem(...padding) : fullScreen ? rem(0, 30, 69, 30) : rem(24)};
    background: ${({ inverted, hideBackground }) => hideBackground ? 'transparent' : inverted ? styledProps('color', 'black') : styledProps('color', 'white') };
    color: ${({ inverted }) => inverted ? styledProps('color', 'white') : styledProps('color', 'black') };
    border: ${({ showBorder }) => showBorder ? '4px solid black' : 'none'};
    margin: 0 auto;
    top: ${({ isBackOfHouse }) => isBackOfHouse ? '50%' : 'auto'};
    transform: ${({ isBackOfHouse }) => isBackOfHouse ? 'translateY(-50%)' : 'none'};
    z-index: -1;
    
    ${greaterThan(breakpoints.medium)(css`
        max-width: ${({ noMaxWidth, fullScreenDesktop, overrideMaxWidth }) => (noMaxWidth || fullScreenDesktop) ? 'none' : overrideMaxWidth ? rem(overrideMaxWidth) : '600px'};
        height: ${({ fullScreenDesktop }) => fullScreenDesktop ? '100%' : 'auto'};
        margin: ${({ isBackOfHouse, fullScreenDesktop, desktopContentVerticalMargin }) => (isBackOfHouse || fullScreenDesktop) ? '0 auto' : `${rem(desktopContentVerticalMargin)} auto`};
        ${({ overrideDesktopWidth }) => overrideDesktopWidth ? `width: ${overrideDesktopWidth};` : null}
    `)};
`;

const CloseIcon = styled.div`
    ${({ padding, fullScreen }) => padding.length > 0
        ? `padding: ${rem(...padding)};`
        : `padding: ${fullScreen ? rem(69, 0, 19, 0) : rem(24, 0, 19, 0)};`}
    position: ${({ overlayCloseIcon }) => overlayCloseIcon ? 'absolute' : 'relative'};
    left: ${({ overlayCloseIcon }) => overlayCloseIcon ? '50%' : 'auto'};
    transform: ${({ overlayCloseIcon }) => overlayCloseIcon ? 'translateX(-50%)' : 'none'};
    text-align: center;
    z-index: ${LAYER.MODAL + 1};
`;

const Header = styled.div`
    width: 100%;
    text-align: center;
`;

const TopCutEdge = styled.div`
    width: 100%;
    height: 6px;
    background: url(${dotWhite});
    position: absolute;
    bottom: -50px;
    z-index: ${LAYER.BODY};
`;

const BottomLink = styled(Button)`
  border: none;    
  position: absolute;
  width: 100%;
  bottom: -40%;
  color: ${styledProps('color', 'white')};
  font-family: ${styledProps('font', 'ABChanelPBL')};
  font-size: ${rem(12)};
  max-width: initial;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.67;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: ${rem(20)};
  left: 0;
  right: 0;
  margin: ${rem(50)} auto;
`;

const UpArrow = styled.div`
  &:after {
    width: 15px;
    height: 15px;
    position: absolute;
    margin: 0 auto;
    top: -17px;
    left: 0;
    right: 0;
    content: '';
    display: block;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: ${styledProps('color', 'white')};
    transform: rotate(225deg);
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin-top: ${rem(100)};
  button {
    margin: 0 auto;
  }
`;

export class PrimaryModal extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        disableScroll: PropTypes.func.isRequired,
        triggerPreventScrollToTop: PropTypes.func.isRequired,
        preventScrollToTopOnOpen: PropTypes.bool,
        didLeave: PropTypes.func,
        onRequestClose: PropTypes.func,
        isOpen: PropTypes.bool,
        showLogo: PropTypes.bool,
        padding: PropTypes.array,
        showClose: PropTypes.bool,
        inverted: PropTypes.bool, // make modal black with white text
        isDialogue: PropTypes.bool, // if modal is small, set to true. If modal will have long content that needs to scroll, set to false
        isDialogueDesktop: PropTypes.bool, // if modal is small, set to true. If modal will have long content that needs to scroll, set to false
        fullScreen: PropTypes.bool, // full screen modal would be a search modal, for example
        fullScreenDesktop: PropTypes.bool, // same as above, but for desktop
        hideBackground: PropTypes.bool, // toggle modal background, hidden in Fragrance experience, for example
        overlayCloseIcon: PropTypes.bool, // render close icon over background (eg: browse atelier by map zone pages view)
        showAlternateClose: PropTypes.bool, // back of house screen shows different close icon from normal modals
        previousScrollDisabledState: PropTypes.bool.isRequired,
        closePadding: PropTypes.array,
        cutEdge: PropTypes.bool,
        showBottomLink: PropTypes.bool,
        bottomLinkText: PropTypes.string,
        bottomLinkOnClick: PropTypes.func,
        noMaxWidth: PropTypes.bool, // notifications modal should not force a max width on larger screens
        darkenOverlay: PropTypes.bool, // make overlay darker than usual (used in notifications panel)
        flexBody: PropTypes.bool, // If false, this prevent to set the body display to flex. This is causing a lot of problems in beauty plan product modal.
        isLoader: PropTypes.bool, // if modal is a loader, don't disable scroll, just show a dark overlay and a loader
        isBackOfHouse: PropTypes.bool, // BOH does not use the global layout container, so modal needs a position tweak
        ariaDescribedby: PropTypes.string,
        focusTrapActive: PropTypes.bool,
        showBorder: PropTypes.bool,
        overrideMaxWidth: PropTypes.number,
        overrideDesktopWidth: PropTypes.string, // any valid css width
        desktopContentVerticalMargin: PropTypes.number
    };

    static defaultProps = {
        didLeave: noop,
        onRequestClose: noop,
        disableScroll: noop,
        triggerPreventScrollToTop: noop,
        preventScrollToTopOnOpen: false,
        windowHeight: 0,
        showClose: true,
        showLogo: false,
        fullscreen: false,
        fullscreenDesktop: false,
        isDialogue: false,
        isDialogueDesktop: false,
        hideBackground: false,
        overlayCloseIcon: false,
        cutEdge: false,
        closePadding: [],
        flexBody: true,
        noMaxWidth: false,
        darkenOverlay: false,
        isLoader: false,
        isBackOfHouse: false,
        focusTrapActive: true,
        showBorder: false,
        desktopContentVerticalMargin: 25
    };

    state = {
        showChildren: true,
        componentMounted: false
    };

    el = document.createElement('div');

    constructor(props) {
        super(props);
        this.modalContainerRef = React.createRef();
        this.cachedScrollState = this.props.previousScrollDisabledState; // Cache scroll state
    }

    componentDidMount() {
        const { disableScroll, isLoader, preventScrollToTopOnOpen, triggerPreventScrollToTop, showClose } = this.props;
        modalRoot.appendChild(this.el);
        rootElement.setAttribute('aria-hidden', 'true');

        // when the modal mounts (i.e. opens), disable scroll and update layout to prevent scrolling to top (unless it's a loader)
        !isLoader && preventScrollToTopOnOpen && triggerPreventScrollToTop(true);
        !isLoader && disableScroll(true);

        if (showClose) {
            document.addEventListener('keydown', this.handleEscKey, false);
        }

        this.setState({
            windowHeight: window.innerHeight,
            componentMounted: true
        });
    }

    componentWillUnmount() {
        const { disableScroll, isLoader, preventScrollToTopOnOpen, triggerPreventScrollToTop, showClose } = this.props;
        !isLoader && disableScroll(this.cachedScrollState);
        !isLoader && preventScrollToTopOnOpen && triggerPreventScrollToTop(false);

        if (showClose) {
            document.removeEventListener('keydown', this.handleEscKey, false);
        }

        rootElement.removeAttribute('aria-hidden');
        modalRoot.removeChild(this.el);
    }

    // Handle modal backdrop click to close modal
    onRequestClose = () => {
        this.props.onRequestClose();
    };

    // Handle ESC button
    handleEscKey = event => {
        if (event.keyCode === KEY_CODES.ESC) {
            this.onRequestClose();
        }
    };

    /**
     * Render the Chanel logo in the modal header, if needed
     * @returns {component} component
     */
    renderLogo() {
        return (
            <Header>
                <img src={ModalLogo} alt=""/>
            </Header>
        );
    }

    renderModal() {
        const { children, showClose, fullScreen, fullScreenDesktop, showLogo,
            padding, inverted, isDialogue, isDialogueDesktop, hideBackground, overlayCloseIcon,
            closePadding, cutEdge, showBottomLink, bottomLinkText, bottomLinkOnClick, noMaxWidth,
            ariaDescribedby, darkenOverlay, isBackOfHouse, focusTrapActive, showBorder, overrideMaxWidth,
            overrideDesktopWidth, desktopContentVerticalMargin } = this.props;
        const { showChildren, componentMounted } = this.state;

        return (
            <FocusTrap active={componentMounted && focusTrapActive}>
                <ModalWrapper
                    isDialogue={ isDialogue }
                    isDialogueDesktop={isDialogueDesktop}
                    fullScreen={fullScreen}
                    role={'dialog'}
                    aria-describedby={ariaDescribedby}
                    className={'abc-modal'}
                >
                    <StyledBackdrop fullScreen={fullScreen} onClick={() => this.onRequestClose()} darkenOverlay={darkenOverlay} />
                    <StyledModalContentContainer
                        fullScreen={fullScreen}
                        fullScreenDesktop={fullScreenDesktop}
                        inverted={inverted}
                        padding={padding}
                        ref={this.modalContainerRef}
                        hideBackground={hideBackground}
                        overlayCloseIcon={overlayCloseIcon}
                        noMaxWidth={noMaxWidth}
                        isBackOfHouse={isBackOfHouse}
                        role={'document'}
                        showBorder={showBorder}
                        overrideMaxWidth={overrideMaxWidth}
                        overrideDesktopWidth={overrideDesktopWidth}
                        desktopContentVerticalMargin={desktopContentVerticalMargin}
                        className={'abc-modal-content'}
                    >
                        {showLogo && this.renderLogo()}
                        {showClose && <CloseIcon fullScreen={fullScreen} overlayCloseIcon={overlayCloseIcon} padding={closePadding}>
                            <NoStyleButton type="button" onClick={() => this.onRequestClose()}>
                                <Icon
                                    type={ICON_TYPES.STYLED_CLOSE}
                                    variant={inverted ? 'dark' : 'light'}
                                    pixelHeight={32}
                                    alt={'close'}
                                />
                            </NoStyleButton>
                            { cutEdge && <TopCutEdge /> }
                        </CloseIcon>}
                        {showChildren && children}
                    </StyledModalContentContainer>

                    {showBottomLink && bottomLinkText && bottomLinkOnClick &&
                    <ButtonWrapper>
                        <BottomLink onClick={bottomLinkOnClick}>
                            <UpArrow/>
                            {bottomLinkText}
                        </BottomLink>
                    </ButtonWrapper>
                    }
                </ModalWrapper>
            </FocusTrap>
        );
    }

    render() {
        return ReactDOM.createPortal(this.renderModal(), this.el);
    }
}

const mapStateToProps = ({ globalLayout: { disableScroll } }) => ({ previousScrollDisabledState: disableScroll });

const mapDispatchToProps = dispatch =>
    bindActionCreators({ disableScroll, triggerPreventScrollToTop }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
    PrimaryModal
);
