import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_SUCCESS } from 'middlewares/fetch';

const SUBMIT_PAYMENTS = 'SUBMIT_PAYMENTS';
const SUBMIT_PAYMENTS_RESET = 'SUBMIT_PAYMENTS_RESET';
const SET_SUBMIT_PAYMENTS_INFORMATION = 'SET_SUBMIT_PAYMENTS_INFORMATION';

export const resetPayments = createAction(SUBMIT_PAYMENTS_RESET);
export const setServiceInformation = createAction(SET_SUBMIT_PAYMENTS_INFORMATION);

const sendPaymentInformation = createAction(FETCH, (paymentInformation) => ({
    prefix: SUBMIT_PAYMENTS,
    endpoint: `${CONFIG.API_URL}/servicepayment`,
    options: {
        method: 'POST',
        body: JSON.stringify(paymentInformation)
    }
}));

export const submitPayment = (paymentToken) => {
    return (dispatch, getState) => {
        const { submitPayment: { serviceInformation: { key, pricing } } } = getState();
        const paymentInformation = {
            serviceKey: key,
            serviceSubtotal: pricing.subtotal,
            serviceTaxes: pricing.tax,
            serviceTotal: pricing.total,
            creditCardData: paymentToken
        };

        return dispatch(sendPaymentInformation(paymentInformation));
    };
};

const initialState = {
    isFetching: false,
    error: null,
    paymentInformation: null,
    serviceInformation: null
};

export default handleActions({
    [SUBMIT_PAYMENTS_RESET]: state => ({
        ...state,
        initialState
    }),
    [SET_SUBMIT_PAYMENTS_INFORMATION]: (state, { payload }) => ({
        ...state,
        serviceInformation: payload
    }),
    [`${SUBMIT_PAYMENTS}/${FETCH_PENDING}`]: state => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [`${SUBMIT_PAYMENTS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [`${SUBMIT_PAYMENTS}/${FETCH_SUCCESS}`]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        paymentInformation: payload
    }),
}, initialState);
