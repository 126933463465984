export const INTERACTION_TYPES = {
    LIKE: 'like',
    PURCHASE: 'purchase',
    ADD_TO_BAG: 'addtoyourbag',
    SEARCH: 'product_search',
    VIEW: 'product_view',
    UNDO_LIKE: 'undolike'
};

export const INTERACTION_LS_KEYS = {
    [INTERACTION_TYPES.LIKE]: 'int-l',
    [INTERACTION_TYPES.PURCHASE]: 'int-p',
    [INTERACTION_TYPES.ADD_TO_BAG]: 'int-b',
    [INTERACTION_TYPES.SEARCH]: 'int-s',
    [INTERACTION_TYPES.VIEW]: 'int-v',
    [INTERACTION_TYPES.UNDO_LIKE]: 'int-u'
};

export const RECOMMENDED_QUERY_PARAM = 'rp';
export const RECOMMENDED_QUERY_PARAM_VALUE = '1';
