import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styledProps, rem } from 'core/styled';
import styled from 'styled-components';
import Header from 'components/GlobalComponents/Fonts/Header';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import SimpleBeautyPlanEventList from 'components/BeautyPlans/Common/SimpleBeautyPlanEventList';
import SimpleBeautyPlanServiceList from 'components/BeautyPlans/Common/SimpleBeautyPlanServiceList';
import SimpleBeautyPlanProductCard from './SimpleBeautyPlanProductCard';
import { BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS } from 'constants/beautyplan';


const CategoryContainer = styled.div`
    padding-top: ${rem(5)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${rem(0, 30)};
    max-width: ${rem(972)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${rem(0, 'auto', 60)};
`;

const SubSectionContainer = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledHeader = styled(Header).attrs({
    as: 'h3'
})`
    font-family: ${styledProps('font', 'ABChanelCorpo')};
    font-weight: 400;
    padding: 0 ${rem(20, 5, 20)};
    position: relative;
    font-size: ${rem(22)};
`;

const NoteWrapper = styled.div`
    padding-top: ${rem(30)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const Note = styled.div`
    width: 100%;
    max-width: ${rem(300)};
    font-family: ${styledProps('font', 'SofiaPro')};
    font-size: ${rem(14)};
    text-align: center;
    line-height: 1.5;
    margin: ${rem(-10, 'auto', 5)};
`;

const StyledDivider = styled.div`
    width: 100%;
    height: ${rem(1)};
    background-color: ${styledProps('color', 'black')};
    margin-top: ${rem(10)};

    &.bp--ttabg-servicedivider {
        margin: ${rem(10, 'auto', 30)};
    }

    &.bp--ttabg-notedivider {
        margin: ${rem(10, 'auto', 30)};
    }
`;

class BeautyGuideTalkCustomer extends Component {
    static propTypes = {
        products: PropTypes.object,
        notes: PropTypes.string,
        services: PropTypes.array.isRequired,
        events: PropTypes.array.isRequired,
        push: PropTypes.func.isRequired,
        staffFirstName: PropTypes.string.isRequired,
    };

    renderProducts = products => {
        const { staffFirstName } = this.props;

        return (
            <SubSectionContainer>
                <StyledHeader>Products</StyledHeader>
                {products.map((product, index) => (
                    <SimpleBeautyPlanProductCard
                        isEven={index % 2 === 0}
                        key={index}
                        product={product}
                        withProductNote
                        artist={staffFirstName}
                    />
                ))}
            </SubSectionContainer>
        );
    };

    renderEvents = (events, first) => {
        const { staffFirstName, push } = this.props;

        return (
            <SubSectionContainer>
                {!first && <StyledDivider className={'bp--ttabg-servicedivider'} />}
                <StyledHeader>Events</StyledHeader>
                <SimpleBeautyPlanEventList
                    events={events}
                    viewMode
                    productNode
                    artist={staffFirstName}
                    push={push}
                />
            </SubSectionContainer>
        );
    };

    renderServices = (services, first) => {
        const { staffFirstName, push } = this.props;

        return (
            <SubSectionContainer>
                {!first && <StyledDivider className={'bp--ttabg-servicedivider'} />}
                <StyledHeader>Services</StyledHeader>
                <SimpleBeautyPlanServiceList
                    services={services}
                    viewMode
                    productNode
                    artist={staffFirstName}
                    push={push}
                />
            </SubSectionContainer>
        );
    };

    renderCategories = () => {
        const { products, events, services } = this.props;

        if (!products.length && !events.length && !services.length) {
            return null;
        }

        return (
            <CategoryContainer>
                {!!products.length && this.renderProducts(products)}
                {!!events.length && this.renderEvents(events, !products.length)}
                {!!services.length && this.renderServices(services, !products.length && !events.length)}
            </CategoryContainer>
        );
    };

    renderNote = (note, index, title) => (
        <NoteWrapper key={index}>
            {index > 0 && <StyledDivider className={'bp--ttabg-notedivider'} />}
            <StyledHeader>{title}</StyledHeader>
            <Note dangerouslySetInnerHTML={{ __html: note.note }} />
        </NoteWrapper>
    );

    renderNotes = () => {
        const { notes } = this.props;
        const BEAUTY_GUIDE_CONFIG_KEY = BEAUTYPLAN_SECTIONS.BEAUTY_GUIDE_TALK;

        const noteComponents = [];

        const techNotes = notes.find(note =>
            note.id === BEAUTYPLAN_CONFIG[BEAUTY_GUIDE_CONFIG_KEY].textSections[0].id);

        techNotes && techNotes.note && noteComponents.push(this.renderNote(techNotes, 0, BEAUTYPLAN_CONFIG[BEAUTY_GUIDE_CONFIG_KEY].textSections[0].title));

        const giftNotes = notes.find(note =>
            note.id === BEAUTYPLAN_CONFIG[BEAUTY_GUIDE_CONFIG_KEY].textSections[1].id);

        giftNotes && giftNotes.note && noteComponents.push(this.renderNote(giftNotes, 1, BEAUTYPLAN_CONFIG[BEAUTY_GUIDE_CONFIG_KEY].textSections[1].title));

        const otherNotes = notes.find(note =>
            note.id === BEAUTYPLAN_CONFIG[BEAUTY_GUIDE_CONFIG_KEY].textSections[2].id);

        otherNotes && otherNotes.note && noteComponents.push(this.renderNote(otherNotes, 2, BEAUTYPLAN_CONFIG[BEAUTY_GUIDE_CONFIG_KEY].textSections[2].title));

        if (noteComponents.length) {
            noteComponents.unshift(<StyledDivider key={-1} />);
        }

        return noteComponents;
    };

    render() {
        const { products } = this.props;

        if (!products) {
            return null;
        }

        return (
            <Wrapper id='atelier-workplan--beautyguidetalk'>
                {this.renderCategories()}
                {this.renderNotes()}
            </Wrapper>
        );
    }
}

const mapStateToProps = ({ beautyplan: { currentPlan: { beautyGuideTalkProducts, notes, services, events, makeupArtistName } } }) => {
    return {
        products: beautyGuideTalkProducts,
        notes,
        services,
        events,
        staffFirstName: makeupArtistName.split(' ')[0]
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BeautyGuideTalkCustomer);
