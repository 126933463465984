import React, { Component, Suspense, lazy } from 'react';
import styled from 'styled-components';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ROUTES } from 'constants/routes';
import { META_DATA, DEFAULT_META_DATA, ARTIST_NAME } from 'constants/metaData';
import AuthRoute from 'components/AuthRoute/AuthRoute';
import Gigya from 'core/utils/Gigya';
import { fetchAtelier } from 'reducers/prismicFetch';
import { getCart } from 'reducers/cart';
import { fetchUserLists } from 'reducers/userList';
import { PRISMIC_PAGE_IDS } from 'constants/prismic';
import PropTypes from 'prop-types';
import StaffRoute from 'components/StaffRoute/StaffRoute';
import { setWorkshopContext } from 'reducers/workshop';
import Preview from 'containers/PrismicContainer/Preview';
import { Helmet } from 'react-helmet';
import { getAnalyticsInfo } from 'reducers/analytics';
import 'core/styled/fonts.css';
import { GlobalStyle } from 'core/styled/global';
import SuspensePage from 'pages/SuspensePage/SuspensePage';
import { getVipStatus } from 'reducers/user';
import { refreshSession } from 'reducers/login';
import { setWebView } from 'reducers/globalLayout';
import Loader from 'components/GlobalComponents/Loader/Loader';

const StaffLoginContainer = lazy(() => import('containers/StaffLogin/StaffLoginContainer'));
const CheckInApp = lazy(() => import('components/CheckInApp/CheckInApp'));
const FragranceApp = lazy(() => import('components/FragranceComponents/FragranceApp'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage/NotFoundPage'));
const RegistrationPage = lazy(() => import('pages/RegistrationPage/RegistrationPage'));
const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage/ForgotPasswordPage'));
const ProductSearchPage = lazy(() => import('pages/ProductSearchPage/ProductSearchPage'));
const ProductListingPage = lazy(() => import('pages/ProductListingPage/ProductListingPage'));
const ProductDescriptionPage = lazy(() => import('pages/ProductDescriptionPage/ProductDescriptionPage'));
const AccountInformationPage = lazy(() => import('pages/AccountInformationPage/AccountInformationPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage/ProfilePage'));
const TriedListPage = lazy(() => import('pages/TriedListPage/TriedListPage'));
const CartPage = lazy(() => import('pages/CartPage/CartPage'));
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage/ResetPasswordPage'));
const BackOfHousePage = lazy(() => import('pages/BackOfHousePage/BackOfHousePage'));
const CreateBeautyPlanPage = lazy(() => import('pages/BeautyPlan/Staff/CreateBeautyPlanPage'));
const QuestionnairePage = lazy(() => import('pages/QuestionnairePage/QuestionnairePage'));
const VisitAtelierPage = lazy(() => import('pages/VisitAtelierPage/VisitAtelierPage'));
const DiscoverPage = lazy(() => import('pages/DiscoverPage/DiscoverPage'));
const HelpPage = lazy(() => import('pages/HelpPage/HelpPage'));
const CareersPage = lazy(() => import('pages/CareersPage/CareersPage'));
const ContactPage = lazy(() => import('pages/ContactPage/ContactPage'));
const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const BeautyPlanLessonPage = lazy(() => import('pages/BeautyPlan/Customer/BeautyPlanLessonPage'));
const ServiceOfferingPage = lazy(() => import('pages/ServiceOfferingPage/ServiceOfferingPage'));
const StaffCustomerCheckinPage = lazy(() => import('pages/CheckInFormPage/StaffCustomerCheckinPage'));
const CheckinCompletePage = lazy(() => import('pages/CheckinCompletePage/CheckinCompletePage'));
const OrdersReadyPage = lazy(() => import('pages/OrdersReadyPage/OrdersReadyPage'));
const PastPurchasesPage = lazy(() => import('pages/PastPurchasesPage/PastPurchasesPage'));
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage/PrivacyPolicyPage'));
const AppointmentsPage = lazy(() => import('pages/AppointmentsPage/AppointmentsPage'));
const TouchScreenPage = lazy(() => import('pages/TouchScreenPage/TouchScreenPage'));
const AustinTouchScreenPage = lazy(() => import('pages/TouchScreenPage/AustinTouchScreenPage'));
const AppointmentBookingPage = lazy(() => import('pages/AppointmentBooking/AppointmentBookingPage'));
const AppointmentBookingReviewPage = lazy(() => import('pages/AppointmentBooking/AppointmentBookingReviewPage'));
const AppointmentBookingConfirmationPage = lazy(() => import('pages/AppointmentBooking/AppointmentBookingConfirmationPage'));
const BookingApp = lazy(() => import('pages/BookingApp/BookingApp'));
const AppointmentDetailsPage = lazy(() => import('pages/AppointmentsPage/AppointmentDetailsPage'));
const ServerErrorPage = lazy(() => import('pages/ServerErrorPage/ServerErrorPage'));
const EventsPage = lazy(() => import('pages/EventsPage/EventsPage'));
const MyEventDetailPage = lazy(() => import('pages/EventsPage/MyEventDetailPage'));
const AppointmentFollowUpBookingPage = lazy(() => import('pages/AppointmentBooking/AppointmentFollowUpBookingPage'));
const EditBeautyPlanPage = lazy(() => import('pages/BeautyPlan/Staff/EditBeautyPlanPage'));
const StatusPage = lazy(() => import('pages/Status/StatusPage'));
const GiftsPage = lazy(() => import('pages/Gifts/GiftsPage'));
const SubmitPaymentPage = lazy(() => import('pages/SubmitPaymentPage/SubmitPaymentPage'));
const SubmitPaymentConfirmationPage = lazy(() => import('pages/SubmitPaymentConfirmationPage/SubmitPaymentConfirmationPage'));
const GiftsPurchasePage = lazy(() => import('pages/Gifts/GiftsPurchasePage'));
const GiftsReviewPage = lazy(() => import('pages/Gifts/GiftsReviewPage'));
const GiftsConfirmationPage = lazy(() => import('pages/Gifts/GiftsConfirmationPage'));
const VirtualServicesPaymentsPage = lazy(() => import('pages/VirtualServicesPage/VirtualServicesPaymentsPage'));
const VirtualServicesSkincareWorkshopsPage = lazy(() => import('pages/VirtualServicesPage/VirtualServicesSkincareWorkshopsPage'));
const RSVPReviewPage = lazy(() => import('pages/RSVPPage/RSVPReviewPage'));
const RSVPConfirmationPage = lazy(() => import('pages/RSVPPage/RSVPConfirmationPage'));
const RSVPQuestionnairePage = lazy(() => import('pages/RSVPPage/RSVPQuestionnairePage'));
const NewToTheAtelierPage = lazy(() => import('pages/NewToTheAtelierPage/NewToTheAtelierPage'));
const RefundsPage = lazy(() => import('pages/RefundsPage/RefundsPage'));
const VideoStartPage = lazy(() => import('pages/Video/VideoStartPage'));
const VideoThankYouPage = lazy(() => import('pages/Video/VideoThankYouPage'));
const VideoBookingsPage = lazy(() => import('pages/Video/VideoBookingsPage'));
const ReturnedMailersPage = lazy(() => import('pages/Mailers/ReturnedMailersPage'));
const EventAlreadyAttendingPage = lazy(() => import('pages/EventsPage/EventAlreadyAttendingPage'));
const ZoneListingPage = lazy(() => import('pages/ZoneListingPage/ZoneListingPage'));
const FragranceQuesionnairePage = lazy(() => import('pages/FragranceExperience/FragranceQuestionnairePage'));
const StaffFragranceQuestionnairePage = lazy(() => import('pages/FragranceExperience/StaffFragranceQuestionnairePage'));
const MembershipLandingPage = lazy(() => import('pages/Membership/MembershipLandingPage'));
const MembershipTermsPage = lazy(() => import('pages/Membership/MembershipTermsPage'));
const MembershipBookingPage = lazy(() => import('pages/Membership/MembershipBookingPage'));
const MembershipIntakeBookingPage = lazy(() => import('pages/Membership/MembershipIntakeBookingPage'));
const MembershipFormPage = lazy(() => import('pages/Membership/MembershipFormPage'));
const MembershipConfigPage = lazy(() => import('pages/Membership/MembershipConfigPage'));
const MembershipApplyPage = lazy(() => import('pages/Membership/MembershipApplyPage'));
const MembershipPaymentPage = lazy(() => import('pages/Membership/MembershipPaymentPage'));
const MixAndMatchPage = lazy(() => import('pages/MixAndMatchPage/MixAndMatchPage'));
const N1DeChanelPage = lazy(() => import('pages/N1DeChanelPage/N1DeChanelPage'));
const GuestBookingReviewPage = lazy(() => import('pages/GuestBooking/GuestBookingReviewPage'));
const GuestBookingConfirmationPage = lazy(() => import('pages/GuestBooking/GuestBookingConfirmationPage'));
const GuestBookingCancelPage = lazy(() => import('pages/GuestBooking/GuestBookingCancelPage'));
const AppAppointmentPaymentPage = lazy(() => import('pages/MobileApp/AppAppointmentPaymentPage'));
const AppEventPaymentPage = lazy(() => import('pages/MobileApp/AppEventPaymentPage'));
const AppSuccessPaymentPage = lazy(() => import('pages/MobileApp/AppSuccessPaymentPage'));
const AppErrorPaymentPage = lazy(() => import('pages/MobileApp/AppErrorPaymentPage'));
const MyBeautyPlansPage = lazy(() => import('pages/BeautyPlan/Customer/MyBeautyPlansPage'));
const BeautyPreferencesPage = lazy(() => import('pages/BeautyPreferences/BeautyPreferencesPage'));
const EventQuestionnairePage = lazy(() => import('pages/QuestionnairePage/EventQuestionnairePage'));
const OpenInAppPage = lazy(() => import('pages/OpenInAppPage/OpenInAppPage'));
const QuickShopApp = lazy(() => import('pages/QuickShopApp/QuickShopApp'));
const BookingRsvpPage = lazy(() => import('pages/BookingApp/BookingRsvpPage'));
const EventsApp = lazy(() => import('pages/EventsApp/EventsApp'));
const TrackingEmailPage = lazy(() => import('pages/TrackingEmailPage/TrackingEmailPage'));
const CuratedGiftsPage = lazy(() => import('pages/Gifts/CuratedGiftsPage'));
const MembershipStaffHomePage = lazy(() => import('pages/Membership/Staff/MembershipStaffHomePage'));
const MembershipStaffMemberListingPage = lazy(() => import('pages/Membership/Staff/MembershipStaffMemberListingPage'));
const MembershipStaffVisitQRPage = lazy(() => import('pages/Membership/Staff/MembershipStaffVisitQRPage'));
const MembershipStaffRecycleQRPage = lazy(() => import('pages/Membership/Staff/MembershipStaffRecycleQRPage'));
const MembershipStaffMintTokenPage = lazy(() => import('pages/Membership/Staff/MembershipStaffMintTokenPage'));
const MembershipVisitPage = lazy(() => import('pages/Membership/MembershipVisitPage'));
const MembershipRecyclePage = lazy(() => import('pages/Membership/MembershipRecyclePage'));
const MembershipRsvpPage = lazy(() => import('pages/Membership/MembershipRsvpPage'));
// const MembershipHub = lazy(() => import('pages/Membership/Hub/MembershipHub'));

const RoutesFlexContainer = styled.div`
    display: flex;
    height: 100%;

    > div {
        flex: 1;
    }
`;

const attachTabHandler = () => {
    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            document.body.classList.add('atelier-tabbing');

            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    }

    function handleMouseDownOnce() {
        document.body.classList.remove('atelier-tabbing');

        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    }

    window.addEventListener('keydown', handleFirstTab);
};

class App extends Component {

    static propTypes = {
        fetchAtelier: PropTypes.func.isRequired,
        getCart: PropTypes.func.isRequired,
        fetchUserLists: PropTypes.func.isRequired,
        setWorkshopContext: PropTypes.func.isRequired,
        authenticated: PropTypes.bool.isRequired,
        isStaff: PropTypes.bool.isRequired,
        isImpersonating: PropTypes.bool.isRequired,
        location: PropTypes.object,
        currentMUAData: PropTypes.object,
        getAnalyticsInfo: PropTypes.func.isRequired,
        getVipStatus: PropTypes.func.isRequired,
        refreshSession: PropTypes.func.isRequired,
        setWebView: PropTypes.func.isRequired
    };

    state = {
        metaData: {},
        loading: false
    };

    constructor(props) {
        super(props);

        const {
            setWorkshopContext,
            getAnalyticsInfo,
            setWebView,
            authenticated,
            getCart,
            fetchUserLists,
            getVipStatus,
            refreshSession
        } = props;

        attachTabHandler();
        setWorkshopContext();
        getAnalyticsInfo();

        // logic to hide header/footer for webview
        const search = new URLSearchParams(window.location.search);

        let webView = false;

        if (search.get('webview') === 'true') {
            webView = true;
            setWebView(webView);
        }

        if (webView && !authenticated) {
            this.state.loading = true;
            refreshSession().then(() => {
                getCart();
                fetchUserLists();
                getVipStatus();

                this.setState({ loading: false });
            }).catch(() => {
                getCart();

                this.setState({ loading: false });
            });
        }
    }

    componentDidMount = () => {
        const { fetchAtelier, getCart, fetchUserLists, authenticated, isStaff, isImpersonating, getVipStatus } = this.props;
        new Gigya().init();
        fetchAtelier(PRISMIC_PAGE_IDS.ATELIER_SOHO);

        if (authenticated && (!isStaff || isImpersonating)) {
            getCart();
            fetchUserLists();
            getVipStatus();
        } else if (!authenticated) {
            // get cart for non-logged in if cartId is defined in profile
            getCart();
        }

        this.setMetaData();

    };

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location || prevProps.currentMUAData !== this.props.currentMUAData) {
            this.setMetaData();
        }
    }

    /**
     * Set meta data for given route
     */
    setMetaData() {
        const ROUTE = this.props.location.pathname;
        // check if there is meta data for specific page
        const pageMetaData = META_DATA.find(data => ROUTE.includes(data.route));
        // if not, then assign the page the default meta data
        let metaData = pageMetaData ? pageMetaData : DEFAULT_META_DATA;

        // Right now, we're only dynamically replacing the artist's name. If that changes, we might need a more scalable solution to this.
        if (metaData.title.includes(ARTIST_NAME)) {
            const artistName = this.props.currentMUAData.name;

            metaData = {
                ...metaData,
                title: metaData.title.replace(ARTIST_NAME, artistName),
                description: metaData.description.replace(ARTIST_NAME, artistName)
            };
        }

        this.setState({
            metaData
        });
    }

    render() {
        const { metaData, loading } = this.state;

        if (loading) {
            return (
                <>
                    <Helmet>
                        <title>{metaData.title}</title>
                        <meta name="description" content={metaData.description} />
                        <meta property="og:title" content={DEFAULT_META_DATA.title} />
                        <meta property="og:description" content={DEFAULT_META_DATA.description} />
                        <meta property="og:image" content={DEFAULT_META_DATA.image} />
                    </Helmet>
                    <Loader />
                </>
            );
        }

        return (
            <RoutesFlexContainer>
                <Helmet>
                    <title>{metaData.title}</title>
                    <meta name="description" content={metaData.description} />
                    <meta property="og:title" content={DEFAULT_META_DATA.title} />
                    <meta property="og:description" content={DEFAULT_META_DATA.description} />
                    <meta property="og:image" content={DEFAULT_META_DATA.image} />
                </Helmet>
                <Suspense fallback={<SuspensePage/>}>
                    <Switch>
                        <Route path={ROUTES.HOME} exact component={HomePage} />
                        <Route path={ROUTES.CHECK_IN} component={CheckInApp} />
                        <Route path={ROUTES.FRAGRANCE} component={FragranceApp} />
                        <Route path={ROUTES.QUICK_SHOP} component={QuickShopApp} />
                        <StaffRoute exact path={ROUTES.STAFF_CUSTOMER_CHECKIN} component={StaffCustomerCheckinPage} />
                        <StaffRoute path={ROUTES.CHECKIN_COMPLETE} component={CheckinCompletePage} />
                        <Route path={ROUTES.VISIT_ATELIER} component={VisitAtelierPage} />
                        <Route path={ROUTES.NEW_TO_ATELIER} component={NewToTheAtelierPage} />
                        <Route path={`${ROUTES.DISCOVER}/:tab?`} component={DiscoverPage} />
                        <Route path={ROUTES.NEW_TO_ATELIER} component={NewToTheAtelierPage} />
                        <Route path={ROUTES.HELP_FAQ} component={HelpPage} />
                        <Route path={ROUTES.HELP_FAQ} exact component={HelpPage} />
                        <Route path={ROUTES.SHOPPING_FAQ} render={() => <HelpPage page={'shopping_faq'} />} />
                        <Route path={ROUTES.CAREERS} component={CareersPage} />
                        <Route path={ROUTES.CONTACT} component={ContactPage} />
                        <Route path={ROUTES.CHECK_IN} component={CheckInApp} />
                        <Route path={ROUTES.LOGIN} component={LoginPage} />
                        <Route
                            path={ROUTES.REGISTER}
                            component={RegistrationPage}
                        />
                        <Route
                            path={ROUTES.FORGOT_PASSWORD}
                            component={ForgotPasswordPage}
                        />
                        <AuthRoute
                            exact
                            path={ROUTES.MY_EVENTS}
                            component={EventsPage}
                        />
                        <AuthRoute
                            exact
                            path={`${ROUTES.MY_EVENTS}/:id`}
                            component={MyEventDetailPage}
                        />
                        <Route
                            path={ROUTES.EVENTS}
                            component={EventsApp}
                        />
                        <Route
                            path={ROUTES.LEGAL_STATEMENT}
                            component={PrivacyPolicyPage}
                        />
                        <AuthRoute exact
                            path={ROUTES.BOOKINGS}
                            component={AppointmentsPage}
                        />
                        <AuthRoute exact path={ROUTES.APPOINTMENTS}>
                            <Redirect to={ROUTES.BOOKINGS} />
                        </AuthRoute>
                        <AuthRoute exact path={`${ROUTES.BOOKINGS}/:id`}
                            component={AppointmentDetailsPage}
                        />
                        <AuthRoute exact path={`${ROUTES.APPOINTMENTS}/:id`}
                            render={props => (
                                <Redirect to={`${ROUTES.BOOKINGS}/${props.match.params.id}`}/>
                            )}
                        />
                        <Route
                            path={ROUTES.PRODUCTS}
                            component={ZoneListingPage}
                        />
                        <Route
                            path={ROUTES.PRODUCT_SEARCH}
                            component={ProductSearchPage}
                        />
                        <Route
                            path={`${ROUTES.PRODUCT_LISTING}/:zone`}
                            component={ProductListingPage}
                        />
                        <Route
                            path={`${ROUTES.PRODUCT_DESCRIPTION}/:code/:sku?`}
                            component={ProductDescriptionPage}
                        />
                        <StaffRoute path={`${ROUTES.MEMBERSHIP_FORM}/:groupNumber`} component={MembershipFormPage} />
                        <StaffRoute path={`${ROUTES.MEMBERSHIP_CONFIG}/:groupNumber`} component={MembershipConfigPage} />
                        <StaffRoute path={ROUTES.MEMBERSHIP_STAFF_VISIT_QR} component={MembershipStaffVisitQRPage} />
                        <StaffRoute path={ROUTES.MEMBERSHIP_STAFF_RECYCLE_QR} component={MembershipStaffRecycleQRPage} />
                        <StaffRoute path={ROUTES.MEMBERSHIP_STAFF_MINT_TOKEN} component={MembershipStaffMintTokenPage} />
                        <StaffRoute exact path={ROUTES.MEMBERSHIP_STAFF_MEMBER_LISTING} component={MembershipStaffMemberListingPage} />
                        <StaffRoute path={ROUTES.MEMBERSHIP_STAFF_HOME} component={MembershipStaffHomePage} />
                        <AuthRoute path={ROUTES.MEMBERSHIP_VISIT} component={MembershipVisitPage} />
                        <AuthRoute path={ROUTES.MEMBERSHIP_RECYCLE} component={MembershipRecyclePage} />
                        <AuthRoute path={ROUTES.MEMBERSHIP_RSVP} component={MembershipRsvpPage} />
                        <AuthRoute exact path={ROUTES.MEMBERSHIP_BOOKING} component={MembershipBookingPage} />
                        <AuthRoute exact path={ROUTES.MEMBERSHIP_INTAKE_BOOKING} component={MembershipIntakeBookingPage} />
                        <AuthRoute exact path={ROUTES.MEMBERSHIP_APPLY} component={MembershipApplyPage} />
                        <AuthRoute exact path={ROUTES.MEMBERSHIP_PAYMENT} component={MembershipPaymentPage} />
                        <Route exact path={ROUTES.MEMBERSHIP_TERMS} component={MembershipTermsPage} />
                        <Route exact path={ROUTES.MEMBERSHIP_LANDING} component={MembershipLandingPage} />
                        {/* <AuthRoute path={ROUTES.MEMBERS_HUB} component={MembershipHub} /> */}
                        <Route path={ROUTES.TRIED_LIST} component={TriedListPage} />
                        <AuthRoute exact path={ROUTES.TRIED_LIST_SHORTLINK}
                            render={() => (
                                <Redirect to={{
                                    pathname: ROUTES.TRIED_LIST,
                                    search: '?utm_source=owned&utm_medium=text&utm_campaign=thankyou'
                                }} />
                            )}
                        />
                        <Route path={ROUTES.CART} component={CartPage} />
                        <Route
                            path={`${ROUTES.RESET_PASSWORD}`}
                            component={ResetPasswordPage}
                        />
                        <AuthRoute exact path={ROUTES.APPOINTMENT_BOOKING} component={AppointmentBookingPage}/>
                        <AuthRoute exact path={ROUTES.APPOINTMENT_FOLLOWUP_BOOKING} component={AppointmentFollowUpBookingPage}/>
                        <AuthRoute exact path={ROUTES.APPOINTMENT_BOOKING_REVIEW} component={AppointmentBookingReviewPage}/>
                        <AuthRoute exact path={ROUTES.APPOINTMENT_BOOKING_CONFIRMATION} component={AppointmentBookingConfirmationPage}/>
                        <AuthRoute path={ROUTES.BOOKING} component={BookingApp} />
                        <Route path={ROUTES.BACK_OF_HOUSE} component={BackOfHousePage} />
                        <Route path={ROUTES.STAFF_LOGIN} component={StaffLoginContainer}/>
                        <Route path={ROUTES.TOUCH_SCREEN} component={TouchScreenPage}/>
                        <Route path={ROUTES.TOUCH_SCREEN_AUSTIN} component={AustinTouchScreenPage}/>
                        <AuthRoute exact path={ROUTES.BEAUTY_PLAN} component={CreateBeautyPlanPage} />
                        <StaffRoute exact path={`${ROUTES.BEAUTY_PLAN}/:id/edit`} component={EditBeautyPlanPage} />
                        <StaffRoute path={ROUTES.FRAGRANCE_QUESTIONNAIRE_STAFF} component={StaffFragranceQuestionnairePage} />
                        <AuthRoute path={ROUTES.FRAGRANCE_QUESTIONNAIRE} component={FragranceQuesionnairePage} />
                        <AuthRoute path={`${ROUTES.QUESTIONNAIRE}/:id`} component={QuestionnairePage} />
                        <AuthRoute path={`${ROUTES.EVENT_QUESTIONNAIRE}/:id`} component={EventQuestionnairePage} />
                        <Route
                            path={ROUTES.CHECKOUT}
                            component={CartPage}
                        />
                        <AuthRoute
                            path={ROUTES.ACCOUNT_INFORMATION}
                            component={AccountInformationPage}
                        />
                        <Route
                            path={ROUTES.GIFTS_PURCHASE}
                            component={GiftsPurchasePage}
                        />
                        <AuthRoute
                            path={ROUTES.GIFTS_REVIEW}
                            component={GiftsReviewPage}
                        />
                        <AuthRoute
                            path={ROUTES.GIFTS_CONFIRMATION}
                            component={GiftsConfirmationPage}
                        />
                        <Route
                            path={ROUTES.GIFTS}
                            component={GiftsPage}
                        />
                        <AuthRoute path={ROUTES.PROFILE} component={ProfilePage} />
                        <AuthRoute exact path={`${ROUTES.BEAUTY_PLAN}/:id`} component={BeautyPlanLessonPage} />
                        <AuthRoute path={ROUTES.PAST_PURCHASES} component={PastPurchasesPage} />
                        <Route path={ROUTES.MAKEUP_APPLICATION_AND_LESSONS}>
                            <Redirect to={ROUTES.SERVICE_OFFER} />
                        </Route>
                        <Route path={ROUTES.SERVICE_OFFER} component={ServiceOfferingPage} />
                        <AuthRoute path={ROUTES.RSVP_REVIEW} component={RSVPReviewPage} />
                        <AuthRoute path={ROUTES.RSVP_CONFIRMATION} component={RSVPConfirmationPage} />
                        <AuthRoute path={ROUTES.RSVP_QUESTIONNAIRE} component={RSVPQuestionnairePage} />
                        <AuthRoute exact path={`${ROUTES.BOOKING_RSVP}/:id/:token`} component={BookingRsvpPage} />
                        <AuthRoute path={`${ROUTES.VIDEO_START}/:id`} component={VideoStartPage} />
                        <Route path={`${ROUTES.VIDEO_ARTIST_START}/:id`} component={VideoStartPage} />
                        <AuthRoute path={`${ROUTES.VIDEO_THANK_YOU}/:id`} component={VideoThankYouPage} />
                        <StaffRoute path={ROUTES.VIRTUAL_SERVICES_BOOKINGS} component={VideoBookingsPage}/>
                        <Route path={`${ROUTES.ORDERS_READY}`} component={OrdersReadyPage} />
                        <Route path={`${ROUTES.SERVER_ERROR}`} component={ServerErrorPage} />
                        <AuthRoute path={ROUTES.SUBMIT_PAYMENT_CONFIRMATION} component={SubmitPaymentConfirmationPage} />
                        <AuthRoute path={ROUTES.SUBMIT_PAYMENT} component={SubmitPaymentPage} />
                        <StaffRoute path={ROUTES.VIRTUAL_SERVICES_PAYMENTS} component={VirtualServicesPaymentsPage} />
                        <StaffRoute path={ROUTES.VIRTUAL_SERVICES_SKINCARE_WORKSHOPS} component={VirtualServicesSkincareWorkshopsPage} />
                        <StaffRoute path={ROUTES.REFUNDS} component={RefundsPage} />
                        <StaffRoute path={ROUTES.RETURNED_MAILERS} component={ReturnedMailersPage} />
                        <StaffRoute path={ROUTES.TRACKING_EMAIL} component={TrackingEmailPage} />
                        <AuthRoute path={ROUTES.GUEST_BOOK_REVIEW} component={GuestBookingReviewPage} />
                        <AuthRoute path={ROUTES.GUEST_BOOK_CONFIRMATION} component={GuestBookingConfirmationPage} />
                        <AuthRoute path={ROUTES.GUEST_BOOK_CANCELLED} component={GuestBookingCancelPage} />
                        <Route path={`${ROUTES.MIX_AND_MATCH}/:id`} component={MixAndMatchPage} />
                        <Route path={`${ROUTES.N1_DE_CHANEL}/:id`} component={N1DeChanelPage} />
                        <Route path={`${ROUTES.CURATED_GIFTS}/:id`} component={CuratedGiftsPage} />
                        <AuthRoute path={ROUTES.APP_APPOINTMENT_PAYMENT} component={AppAppointmentPaymentPage} />
                        <AuthRoute path={ROUTES.APP_EVENT_PAYMENT} component={AppEventPaymentPage} />
                        <AuthRoute path={ROUTES.APP_SUCCESS_PAYMENT} component={AppSuccessPaymentPage} />
                        <AuthRoute path={ROUTES.APP_ERROR_PAYMENT} component={AppErrorPaymentPage} />
                        <AuthRoute path={ROUTES.MY_BEAUTY_PLANS} component={MyBeautyPlansPage} />
                        <AuthRoute path={`${ROUTES.BEAUTY_PREFERENCES}/:id`} component={BeautyPreferencesPage} />
                        <AuthRoute path={ROUTES.BEAUTY_PREFERENCES} component={BeautyPreferencesPage} />
                        <Route path={ROUTES.EVENT_ALREADY_ATTENDING} component={EventAlreadyAttendingPage} />
                        <Route path={ROUTES.APP} component={OpenInAppPage} />
                        <Route exact path={ROUTES.PRISMIC_PREVIEW} render={routeProps => <Preview {...routeProps} />} />
                        <Route path={ROUTES.STATUS} component={StatusPage} />
                        <Route component={NotFoundPage} />
                    </Switch>
                </Suspense>
                <GlobalStyle />
            </RoutesFlexContainer>
        );
    }
}

const mapStateToProps = ({ user: { auth: { authenticated, isStaff, isImpersonating } }, makeupArtists: { currentMUAData } }) => ({
    authenticated,
    isStaff,
    isImpersonating,
    currentMUAData
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchAtelier, getCart, fetchUserLists, setWorkshopContext, getAnalyticsInfo, getVipStatus, setWebView, refreshSession },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
