import styled from 'styled-components';
import { boxStyle, styledProps, rem } from 'core/styled';

const ErrorMessage = styled.div`
    ${boxStyle};
    text-align: ${styledProps('textAlign')};
    font-family: ${styledProps('font', 'SofiaPro')};
    color: ${styledProps('color', 'errorRed')};
    font-size: ${rem(12)};
    line-height: 1.33;
    letter-spacing: 0.4px;
    word-wrap: balance;
`;

export default ErrorMessage;
