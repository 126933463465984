import { css } from 'styled-components';
import { camelCaseToDash, getNestedValue } from 'core/utils';

/**
 * Resolves styled components props and/or current theme into css values
 * @param {string} styleProps - props which are passed to the styled component
 * @return {function({theme: object, props: array}): string} css value
 */
export const styledProps = (...styleProps) => ({ theme = {}, ...props }) => {
    // First check if the component was passed a prop as the style prop value
    const [propName] = styleProps;
    const stylePropValue = props[propName];
    if (stylePropValue) {
        return stylePropValue;
    }
    // Then check if the current theme has a value for the style props
    let themeStyleValue;
    try {
        themeStyleValue = getNestedValue(theme, styleProps);
    } catch (e) {
        themeStyleValue = undefined;
    }
    if (typeof themeStyleValue === 'object') {
        if (!themeStyleValue.default) {
            throw new Error(
                `No default value found for ${styleProps.join('.')}`
            );
        }
        return themeStyleValue.default;
    }
    return themeStyleValue;
};

/**
 * Map breakpoint object to media queries or fallback to single value prop
 * @param {string} styledProp - prop name
 * @param {object} defaultProps - these will be used for undefined breakpoints
 * @param {function} valueMapper - prop value mapper
 * @return {function({theme: object, props: array}): array|string} css value/s
 */
export const styledBreakpointProp = (
    styledProp,
    defaultProps,
    valueMapper = (value) => value
) => ({ theme = { breakpoints: {} }, ...props }) => {
    const styledPropValue = props[styledProp];
    // prettier-ignore
    const mapBreakpoint = breakpoint => css`
    @media (min-width: ${theme.breakpoints[breakpoint]}) {
        ${camelCaseToDash(styledProp)}: ${valueMapper(styledPropValue[breakpoint], props)};
    }
    `;

    // Check if prop value is a breakpoint object
    if (typeof styledPropValue === 'object') {
        return [
            // Default prop value for undefined breakpoints
            css`${camelCaseToDash(styledProp)}: ${valueMapper(
                styledProps(styledProp)({ theme, ...defaultProps }),
                { theme, ...props }
            )};`,
            // Prop values for defined breakpoints
            ...Object.keys(styledPropValue).map(mapBreakpoint)
        ];
    }

    // Fallback to non breakpoint prop
    return css`${camelCaseToDash(styledProp)}: ${valueMapper(
        styledProps(styledProp)({ theme, ...props }),
        { theme, ...props }
    )};`;
};
