import { createAction, handleActions } from 'redux-actions';

const SET_DEFINITIONS = 'SET_DEFINITIONS';

const initialState = {
    definitions: {}
};

export const setDefinitions = createAction(SET_DEFINITIONS);

export default handleActions({
    [SET_DEFINITIONS]: (state, { payload }) => ({
        ...state,
        definitions: payload
    })
}, initialState);
