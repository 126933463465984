import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ROUTES } from 'constants/routes';
import { styledProps, rem, greaterThan } from 'core/styled';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setHeaderShrunkState, HEADER_ICONS, setFragranceModalOpen, setHeaderTicketVisible, setIsHeaderSearchOpen } from 'reducers/globalLayout';
import { Flex, Box } from 'components/GlobalComponents/FlexBox/index';
import HeaderSearch from './HeaderSearch';
import { LAYER } from 'constants/layers';
import { push } from 'redux-first-history';
import windowScrollPosition from 'core/utils/windowScrollPosition';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';
import SkipLinks from 'components/SkipLinks/SkipLinks';

// images
import { breakpoints, theme } from 'constants/theme';

// icons
import logo from 'assets/logo.svg';
import logoInverted from 'assets/white-logo.svg';
import newLogo from 'assets/logo-alternative.svg';
import newLogoInverted from 'assets/logo-alternative-inverted.svg';
import logoShrunk from 'assets/logo_shrunk.svg';
import logoShrunkInverted from 'assets/logo_shrunk-inverted.svg';
import logoParfum from 'assets/fragranceApp/logo-parfum-black.svg';
import logoParfumInverted from 'assets/fragranceApp/logo-parfum-white.svg';
import headerEdge from 'assets/perf-edge-white.svg';
import headerEdgeInverted from 'assets/perf-edge-black.svg';

import { analyticsTag } from 'reducers/analytics';
import { EVENTS } from 'constants/analytics';

import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';

// special case for fragrance
import CameliaImage from 'assets/fragranceApp/camelia.svg';
import CameliaImageActive from 'assets/fragranceApp/camelia-active.svg';
import reactNativeWebViewHelper from 'core/utils/reactNativeWebViewHelper';

const TICKET_PIXEL_HEIGHT = 60;
const DESKTOP_TICKET_PIXEL_HEIGHT = 80;
const HEADER_ANIMATION_DURATION = '0.2s';
const MENU_BLOCK_WIDTH = '25%';

// TODO: after VX validates, add cross-browser support and ability to change gradient color from props
// GRADIENT: https://www.cssmatic.com/gradient-generator#'\-moz\-linear\-gradient\%28top\%2C\%20rgba\%28255\%2C255\%2C255\%2C1\%29\%200\%25\%2C\%20rgba\%28255\%2C255\%2C255\%2C0\.64\%29\%2060\%25\%2C\%20rgba\%28255\%2C255\%2C255\%2C0\.4\%29\%20100\%25\%29\%3B'
const HeaderWrapper = styled(Flex)`
    box-sizing: border-box;
    width: 100%;
    background: ${({ headerBackground }) => headerBackground};
    z-index: ${LAYER.HEADER};
    position: fixed;
    padding: ${({ isImpersonating }) => isImpersonating ? rem(140, 0, 0, 0) :  rem(0, 0, 0, 0)};
    flex-direction: column;
    ${({ showBorder, headerBackground }) => showBorder ? (headerBackground === theme.color.white || headerBackground === 'white' ? `border-bottom: 1px solid ${theme.color.dustyGrey}` : `border-bottom: 1px solid ${headerBackground}`) : ''};
`;

const HeaderContentWrapper = styled.header`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const HeaderTicket = styled.div`
  position: absolute;
  background-color: ${({ inverted }) => inverted ? styledProps('color', 'black') : styledProps('color', 'white')};
  top: ${({ showTicket }) => showTicket ?  0 : `-${TICKET_PIXEL_HEIGHT + 10}px`};
  left: 0;
  width: 100vw;
  height: ${TICKET_PIXEL_HEIGHT}px;
  transition: all ${HEADER_ANIMATION_DURATION};
  z-index: ${LAYER.HEADER - 1};
  ${({ inverted }) => !inverted ? `border-bottom: 1px solid ${theme.color.dustyGrey};` : ''}
  box-sizing: content-box;

  ${greaterThan(breakpoints.small)(css`
    top: ${({ showTicket }) => showTicket ? 0 : `-${DESKTOP_TICKET_PIXEL_HEIGHT + 10}px`};
    height: ${DESKTOP_TICKET_PIXEL_HEIGHT}px;
  `)}
`;

const HeaderInner = styled(Flex)`
    width: 100%;
    z-index: ${LAYER.HEADER};
    background: ${({ headerBackground }) => headerBackground};
    padding: ${({ isImpersonating, parfumFullLogo }) => isImpersonating ? (parfumFullLogo ? rem(180, 15, 5, 15) : rem(160, 15, 12, 15)) : (parfumFullLogo ? rem(32, 15, 5, 15) : rem(12, 15, 12, 15))};

    ${greaterThan(breakpoints.small)(css`
        padding: ${({ isImpersonating, parfumFullLogo }) => isImpersonating ? (parfumFullLogo ? rem(180, 15, 5, 15) : rem(165, 15, 17, 15)) : (parfumFullLogo ? rem(32, 15, 5, 15) : rem(17, 15, 17, 15))};
    `)}
`;

const LogoImg = styled.img`
    width: ${({ parfumLogo }) => parfumLogo ? '111px' : '140px'};
    height: ${({ parfumLogo }) => parfumLogo ? '70px' : '30px'};
    transition: all 0.6s;
    position: relative;
    margin-top: ${({ shrinkHeader }) => shrinkHeader ? '-30px' : '0'};
    
    ${greaterThan(breakpoints.small)(css`
        width: ${({ parfumLogo }) => parfumLogo ? '111px' : '180px'};
        height: ${({ parfumLogo }) => parfumLogo ? '70px' : '40px'};
    `)}
`;

const StyledLogo = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    opacity: ${props => props.showLogo ? 1 : 0};
    transition: all 0.2s;
    height: ${({ parfumLogo, isHeaderShrunk }) => parfumLogo ? (isHeaderShrunk ? '45px' : '70px') : '36px'};
    
    ${greaterThan(breakpoints.small)(css`
        height: ${({ parfumLogo, isHeaderShrunk }) => parfumLogo ? (isHeaderShrunk ? '45px' : '70px') : '46px'};
    `)}
`;

const IconWrapper = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FragranceExitButton = styled.div`
    color: white;
    font-size: ${rem(10)};
    text-transform: uppercase;
    margin-left: ${rem(17)};
    letter-spacing: 1px;
`;

const FragranceCamelia = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    opacity: ${({ visible }) => visible ? 1 : 0};
    transition: opacity 2.5s;
    margin-right: ${rem(20)};
`;

const FragranceCount = styled.div`
  color: ${styledProps('color', 'white')};
  font-family: ${styledProps('font', 'ABChanelPBM')};
  font-size: ${rem(10)};
  position: absolute;
  top: -7px;
  right: -12px;
`;

const IconPlaceHolder = styled(Box)`
    width: 40px;
`;

const IconOuter = styled(Box)`
  flex: none;
  width: 40px;
`;

const MenuBlock = styled(Flex)`
    width: ${MENU_BLOCK_WIDTH};
    justify-content: ${({ isLogin }) => isLogin ? 'space-around' : 'flex-start'};
    
    ${greaterThan(breakpoints.small)(css`
        padding-left: 2.5vw;
        justify-content: flex-start;
         > div {
            margin: 0 5px; 
         }
    `)}
`;
const AccountBlock = styled(Flex)`
    width: ${MENU_BLOCK_WIDTH};
    justify-content: ${({ isLogin }) => isLogin ? 'space-around' : 'flex-end'};
    
    ${greaterThan(breakpoints.small)(css`
        padding-right: 2.5vw;
        justify-content: flex-end;
         > div {
            margin: 0 10px; 
         }
    `)}
`;
const LogoBlock = styled(Flex)`
    width: ${MENU_BLOCK_WIDTH};
    justify-content: center;
`;

const iconsSets = {
    [HEADER_ICONS.DEFAULT]: {
        logo: logo,
        newLogo: newLogo,
        logoShrunk: logoShrunk,
        logoParfum: logoParfum,
        headerEdge: headerEdge
    },
    [HEADER_ICONS.INVERTED]: {
        logo: logoInverted,
        newLogo: newLogoInverted,
        logoShrunk: logoShrunkInverted,
        logoParfum: logoParfumInverted,
        headerEdge: headerEdgeInverted
    }
};

const headerAnalytics = {
    event: EVENTS.GA,
    eventCategory: 'header'
};

class PageMobileHeader extends Component {

    static propTypes = {
        cartItems: PropTypes.array.isRequired,
        likedItems: PropTypes.array.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        headerBackground: PropTypes.string.isRequired,
        setHeaderShrunkState: PropTypes.func.isRequired,
        isFragrance: PropTypes.bool.isRequired,
        isHeaderStaffTheme: PropTypes.bool.isRequired,
        headerIcons: PropTypes.string.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        toggleHamburger: PropTypes.func,
        inverted: PropTypes.bool,
        isHeaderShrunk: PropTypes.bool,
        showLogo: PropTypes.bool,
        showFragranceHeaderFlower: PropTypes.bool,
        selectedFragrances: PropTypes.array,
        push: PropTypes.func.isRequired,
        showFullLogo: PropTypes.bool,
        setFragranceModalOpen: PropTypes.func.isRequired,
        fragranceModalOpen: PropTypes.bool,
        useParfumLogo: PropTypes.bool,
        showHeaderTicket: PropTypes.bool,
        overrideHeaderTicketBehavior: PropTypes.bool,
        setHeaderTicketVisible: PropTypes.func.isRequired,
        disableHeaderShrink: PropTypes.bool,
        isImpersonating: PropTypes.bool,
        noImpersonation: PropTypes.bool,
        profile: PropTypes.object,
        cartTotalItems: PropTypes.number,
        automaticSearchExpansion: PropTypes.bool,
        isHeaderSearchOpen: PropTypes.bool.isRequired,
        setIsHeaderSearchOpen: PropTypes.func.isRequired,
        workshopServerError: PropTypes.bool,
        showHamburgerMenu: PropTypes.bool.isRequired,
        forcedWhiteTicketHeader: PropTypes.bool,
        toggleCart: PropTypes.func.isRequired,
        showCartMenu: PropTypes.bool.isRequired
    };

    static defaultProps = {
        showLogo: true,
        disableHeaderShrink: false,
        automaticSearchExpansion: false,
        workshopServerError: false
    };

    state = {
        isModalVisible: false,
        addedToCart: false,
        addedToLikeDislikeList: false
    };

    searchInputRef = React.createRef();
    searchButtonRef = React.createRef();

    /**
     * Send analytics tag
     * @param {object} properties
     */
    analytics = (properties) => {
        this.props.analyticsTag({
            ...headerAnalytics,
            ...properties
        }, { userInfo: true });
    };

    componentDidMount() {
        // Need to bind to parent container scroll, because header itself doesn't scroll
        this.parentContainer = window;
        this.parentContainer.addEventListener('scroll', this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        this.parentContainer.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.cartItems.length > prevProps.cartItems.length) {
            this.setState({
                addedToCart: true
            });
        }

        if (this.props.likedItems !== prevProps.likedItems) {
            this.setState({
                addedToLikeDislikeList: true
            });
        }
    }

    /**
     * Handle page scroll
     */
    handleScroll = () => {
        const { isHeaderShrunk, setHeaderShrunkState, setHeaderTicketVisible, overrideHeaderTicketBehavior, disableHeaderShrink, showHeaderTicket, automaticSearchExpansion, isHeaderSearchOpen, setIsHeaderSearchOpen } = this.props;
        const scrollTop = windowScrollPosition();
        const shouldTrigger = scrollTop > 0;

        if (!disableHeaderShrink && isHeaderShrunk !== shouldTrigger) {
            setHeaderShrunkState(shouldTrigger);
        }

        if (!overrideHeaderTicketBehavior && showHeaderTicket !== shouldTrigger) {
            setHeaderTicketVisible(shouldTrigger);
        }

        if (automaticSearchExpansion && isHeaderSearchOpen !== shouldTrigger) {
            setIsHeaderSearchOpen(shouldTrigger);
        }
    };

    /**
     * Toggle for search modal display state
    */
    toggleSearch() {
        const { isHeaderSearchOpen, setIsHeaderSearchOpen } = this.props;
        const isOpening = !isHeaderSearchOpen;

        setIsHeaderSearchOpen(!isHeaderSearchOpen);

        if (isOpening) {
            setTimeout(() => {
                this.searchInputRef.current.focus();
            }, 250);
        }
    }

    /**
     * Determine if we need to display the parfum logo in full size
     * @returns {boolean} - Boolean indicating logo is parfum
     */
    isParfumLogo = () => {
        const { isFragrance, useParfumLogo } = this.props;

        return isFragrance || useParfumLogo;
    };

    handleFragranceExit = () => {
        const { push } = this.props;

        reactNativeWebViewHelper.sendCloseWebView();
        push(ROUTES.PRODUCTS);
    };

    /**
     * Renders the fragrance app version of the header.
     * @returns {object} Component
     */
    renderFragranceHeader = () => {
        const { selectedFragrances, showFragranceHeaderFlower, setFragranceModalOpen, fragranceModalOpen, headerBackground, isHeaderShrunk } = this.props;
        return (
            <HeaderInner justifyContent='space-between' alignItems={'flex-end'} headerBackground={headerBackground} parfumLogo={this.isParfumLogo() && !isHeaderShrunk}>
                <FragranceExitButton onClick={this.handleFragranceExit}>Exit</FragranceExitButton>
                { this.renderLogo() }
                <FragranceCamelia visible={showFragranceHeaderFlower} onClick={() => setFragranceModalOpen(true)}>
                    <img src={fragranceModalOpen ? CameliaImageActive : CameliaImage} alt='' />
                    <FragranceCount>{ selectedFragrances.length ? selectedFragrances.length : null }</FragranceCount>
                </FragranceCamelia>
            </HeaderInner>
        );
    };

    /**
     * Renders the header based on the context.
     * @returns {object} Component
     */
    renderHeader = () => {
        const { likedItems,  cartItems, toggleHamburger, isLoggedIn, isHeaderStaffTheme, headerIcons, push,
            cartTotalItems, headerBackground, workshopServerError, showHamburgerMenu,
            isHeaderSearchOpen, toggleCart, showCartMenu } =  this.props;
        const { addedToCart, addedToLikeDislikeList } = this.state;

        const iconsVariant = headerIcons === 'inverted' ? 'dark' : 'light';
        const iconPixelHeight = 19;

        return (
            <HeaderInner alignItems='center' justifyContent='space-between' headerBackground={headerBackground}>
                <MenuBlock isLogin={isLoggedIn} id='atelier--header-menublock'>
                    <IconOuter>
                        <IconWrapper>
                            <NoStyleButton type={'button'} aria-expanded={showHamburgerMenu} onClick={() => {
                                toggleHamburger();
                                this.analytics({ eventAction: 'menu' });
                            }}>
                                <Icon
                                    type={ICON_TYPES.MENU}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    alt={showHamburgerMenu ? 'close menu' : 'open menu'}
                                />
                            </NoStyleButton>
                        </IconWrapper>
                    </IconOuter>
                    <IconOuter>
                        <IconWrapper>
                            <NoStyleButton type={'button'} aria-expanded={isHeaderSearchOpen} ref={this.searchButtonRef} onClick={() => {
                                if (!workshopServerError) {
                                    this.toggleSearch();
                                }
                                this.analytics({ eventAction: 'product_search' });
                            }}>
                                <Icon
                                    type={ICON_TYPES.SEARCH}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    alt={isHeaderSearchOpen ? 'close search field' : 'open search field'}
                                />
                            </NoStyleButton>
                        </IconWrapper>
                    </IconOuter>
                </MenuBlock>
                <LogoBlock justifyContent={'center'}>
                    { this.renderLogo() }
                </LogoBlock>
                <AccountBlock isLogin={isLoggedIn} id='atelier--header-accountblock'>
                    {!isLoggedIn &&
                    <IconOuter>
                        <IconWrapper>
                            <a href={ROUTES.LOGIN} onClick={e => {
                                e.preventDefault();
                                workshopServerError ? window.location.pathname = ROUTES.LOGIN : push(ROUTES.LOGIN);
                            }}>
                                <Icon
                                    type={ICON_TYPES.PROFILE}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    alt={'Go to the login page'}
                                />
                            </a>
                        </IconWrapper>
                    </IconOuter>}

                    {isLoggedIn && !isHeaderStaffTheme &&
                    <IconOuter>
                        <IconWrapper>
                            <a href={ROUTES.TRIED_LIST} onClick={e => {
                                e.preventDefault();
                                workshopServerError ? window.location.pathname = ROUTES.TRIED_LIST : push(ROUTES.TRIED_LIST);
                                this.analytics({ eventAction: 'tried_list' });
                            }}>
                                <Icon
                                    type={ICON_TYPES.STYLED_LIKE}
                                    selected={!!likedItems.length}
                                    showAnimatedDot={true}
                                    successPositive={addedToLikeDislikeList}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    dotAnimationKey={likedItems}
                                    alt={likedItems.length ? 'Go to the tried list page, at least one item selected' : 'Go to the tried list page'}
                                />
                            </a>
                        </IconWrapper>
                    </IconOuter>}

                    {!isHeaderStaffTheme &&
                    <IconOuter>
                        {/* <IconWrapper>
                            <a href={ROUTES.CART} onClick={e => {
                                e.preventDefault();
                                workshopServerError ? window.location.pathname = ROUTES.CART : push(ROUTES.CART);
                                this.analytics({ eventAction: 'cart' });
                            }}>
                                <Icon
                                    type={ICON_TYPES.BAG}
                                    selected={!!cartItems.length}
                                    showAnimatedDot={true}
                                    successPositive={addedToCart}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    dotAnimationKey={cartTotalItems}
                                    alt={!!cartItems.length ? 'Go to the cart page, at least one item selected' : 'Go to the cart page'}
                                />
                            </a>
                        </IconWrapper> */}
                        <IconWrapper>
                            <NoStyleButton type={'button'} aria-expanded={showCartMenu} onClick={() => {
                                toggleCart();
                                this.analytics({ eventAction: 'cart' });
                            }}>
                                <Icon
                                    type={ICON_TYPES.BAG}
                                    selected={!!cartItems.length}
                                    showAnimatedDot={true}
                                    successPositive={addedToCart}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    dotAnimationKey={cartTotalItems}
                                    alt={cartItems.length ? 'Go to the cart page, at least one item selected' : 'Go to the cart page'}
                                />
                            </NoStyleButton>
                        </IconWrapper>
                    </IconOuter>}

                    {isHeaderStaffTheme && <IconPlaceHolder />}
                    {isHeaderStaffTheme &&
                    <IconOuter>
                        <IconWrapper>
                            <a href={ROUTES.PROFILE} onClick={e => {
                                e.preventDefault();
                                push(ROUTES.PROFILE);
                            }}>
                                <Icon
                                    type={ICON_TYPES.PROFILE}
                                    variant={iconsVariant}
                                    pixelHeight={iconPixelHeight}
                                    alt={'Go to the profile page'}
                                />
                            </a>
                        </IconWrapper>
                    </IconOuter>}

                </AccountBlock>
            </HeaderInner>
        );
    };

    /**
     * Renders the logo based on the context.
     * @returns {object} Component
     */
    renderLogo = () => {
        //const { isHeaderShrunk, showLogo, headerIcons, isFragrance, showFullLogo, useParfumLogo, isLoggedIn } = this.props;
        const { showLogo, headerIcons, isLoggedIn, isFragrance, useParfumLogo, isHeaderShrunk, workshopServerError } = this.props;
        const icons = iconsSets[headerIcons];

        // show either full or shrunk logo, and handle parfum experience logo
        // const logoImage  = isHeaderShrunk && !showFullLogo ? icons.logoShrunk :
        //     isFragrance || useParfumLogo ? icons.logoParfum : icons.logo;

        const logoImage = isFragrance || useParfumLogo ? (isHeaderShrunk ? icons.logoShrunk : icons.logoParfum) : icons.newLogo;

        // don't shrink header if showing full logo
        // const shrinkHeader = isHeaderShrunk && !showFullLogo;

        if (workshopServerError) {
            return (
                <StyledLogo showLogo parfumLogo={false} isHeaderShrunk={false} id='atelier--logo'>
                    <LogoImg
                        src={icons.newLogo}
                        alt='Atelier Beaute CHANEL, go to the homepage'
                        onClick={() => {
                            this.analytics({ eventAction: 'logo' });
                            window.location.pathname = ROUTES.HOME;
                        }}
                        shrinkHeader={false}
                        parfumLogo={false}
                        role={'img'}
                    />
                </StyledLogo>
            );
        }

        return (
            <Link to={ROUTES.HOME}>
                <StyledLogo showLogo={showLogo} parfumLogo={this.isParfumLogo()} isHeaderShrunk={isHeaderShrunk} id='atelier--logo'>
                    <LogoImg
                        src={logoImage}
                        alt={ isLoggedIn ? 'Atelier Beaute CHANEL, go to profile' : 'Atelier Beaute CHANEL, go to the homepage' }
                        onClick={() => this.analytics({ eventAction: 'logo' })}
                        shrinkHeader={this.isParfumLogo() && isHeaderShrunk}
                        parfumLogo={this.isParfumLogo()}
                        role={'img'}
                    />
                </StyledLogo>
            </Link>
        );
    };

    render() {
        const { isFragrance, headerBackground, inverted, showHeaderTicket, profile, isImpersonating, overrideHeaderTicketBehavior, noImpersonation, useParfumLogo, isHeaderSearchOpen, forcedWhiteTicketHeader } =  this.props;
        // The conditional for the isStaff or useParfumLogo is for when the user is staff, not impersonating, and accessing the fragrance experience.
        const shouldInvertIcons = inverted || (profile.isStaff && !useParfumLogo);

        // If the user is staff, we want to always show the cut edges on the header unless we are specifically
        // overriding the behavior (as in the case of the profile which has stacked headers). This is because
        // the header is black when the user is staff and the background of the pages are white. When a
        // regular user is logged in, it's white/white which is invisible anyway.
        const shouldShowHeaderTicketEdges = showHeaderTicket || (profile.isStaff && !overrideHeaderTicketBehavior);

        // We don't want to display the header bottom border when we have a transparent header. The HeaderTicket
        // component will display the border instead.
        const shouldDisplayHeaderWrapperBorder = headerBackground !== theme.color.transparent && !useParfumLogo;

        return (
            <HeaderWrapper
                id={'main-header-wrapper'}
                isImpersonating={isImpersonating && !noImpersonation}
                isFragrance={isFragrance}
                justifyContent='center'
                fullHeight={false}
                headerBackground={headerBackground}
                showBorder={shouldDisplayHeaderWrapperBorder}
            >
                <SkipLinks/>
                <HeaderContentWrapper role={'banner'} tabIndex={-1}>
                    <HeaderTicket showTicket={shouldShowHeaderTicketEdges} inverted={!forcedWhiteTicketHeader && shouldInvertIcons}/>
                    { isFragrance ? this.renderFragranceHeader() : this.renderHeader() }
                    <HeaderSearch
                        isOpen={isHeaderSearchOpen}
                        backgroundColor={headerBackground === theme.color.transparent ? theme.color.white : headerBackground}
                        onRequestClose={() => this.toggleSearch()}
                        forwardRef={this.searchInputRef}
                        searchButtonRef={this.searchButtonRef}/>
                </HeaderContentWrapper>
            </HeaderWrapper>
        );
    }
}

const mapStateToProps = ({
    user: { auth: { profile, authenticated, isImpersonating } },
    cart,
    userList,
    globalLayout: {
        headerBackground,
        headerTheme,
        isFragrance,
        isHeaderShrunk,
        isHeaderStaffTheme,
        headerIcons,
        showFragranceHeaderFlower,
        selectedFragrances,
        fragranceModalOpen,
        useParfumLogo,
        showHeaderTicket,
        overrideHeaderTicketBehavior,
        inverted,
        isHeaderSearchOpen,
        forcedWhiteTicketHeader
    }
}) => ({
    profile,
    cartItems: cart.items,
    cartTotalItems: cart.items.reduce((total, item) => {
        return total += item.userdata.qty;
    }, 0),
    likedItems: userList.like,
    isLoggedIn: authenticated,
    isHeaderStaffTheme,
    headerBackground,
    headerTheme,
    isFragrance,
    isImpersonating,
    isHeaderShrunk,
    headerIcons,
    showFragranceHeaderFlower,
    selectedFragrances,
    fragranceModalOpen,
    useParfumLogo,
    showHeaderTicket,
    overrideHeaderTicketBehavior,
    inverted,
    isHeaderSearchOpen,
    forcedWhiteTicketHeader
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push, setHeaderShrunkState, setFragranceModalOpen, analyticsTag , setHeaderTicketVisible, setIsHeaderSearchOpen },
        dispatch
    );


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageMobileHeader));
