import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styledProps, rem, greaterThan } from 'core/styled';
import styled, { css } from 'styled-components';
import BeautyPlanProductCard from './BeautyPlanProductCard';
import Header from 'components/GlobalComponents/Fonts/Header';
import ProductSectionWrapper from 'components/BeautyPlans/Common/ProductSectionWrapper';
import { BEAUTYPLAN_CONFIG, BEAUTYPLAN_SECTIONS, sectionTitle, NON_CHANEL } from 'constants/beautyplan';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import Notes from 'components/BeautyPlans/Common/Notes';
import { breakpoints } from 'constants/theme';
import StaffQuestionView from 'components/BeautyPlans/Common/StaffQuestionView';
import NonChanelProduct from 'components/BeautyPlans/Common/NonChanelProduct';

const StyledHeader = styled(Header)`
    padding: 0 ${rem(20, 20, 20)};
    ${({ containNote }) => containNote ? `margin-bottom: ${rem(0)};` : `margin-bottom: ${rem(45)};`}
    position: relative;
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        border-bottom: 2px solid ${styledProps('color', 'black')};
        width: calc(100% - ${rem(40)});
        margin: 0 auto;
    }
    
     ${greaterThan(breakpoints.medium)(css`
        width: 50%;
        margin: auto;
    `)};  
`;

const Title = styled.div`
    font-family: ${styledProps('font', 'ABChanelPBS')};
    font-size: ${rem(14)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: -0.2px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: ${rem(5)};
`;

const NonChanelNote = styled.p`
    font-family: ${styledProps('font', 'CervoNeueNeue')};
    text-transform: uppercase;
    text-align: center;
    font-size: ${rem(20)};
    line-height: 1.7;
`;

class NewMakeupCustomer extends Component {
    static propTypes = {
        products: PropTypes.object,
        note: PropTypes.string,
        isImpersonating: PropTypes.bool.isRequired,
        staffQuestions: PropTypes.array,
        noteTitle: PropTypes.string,
        subSectionNotes: PropTypes.array,
        noteTitleArtistReplace: PropTypes.bool,
        showArtistPicture: PropTypes.bool
    };

    /**
     * Render non chanel product
     * @param {number} key
     * @param {string} note
     * @return {*} component
     */
    renderNonChanelProduct = (key, note) => (
        <div>
            <NonChanelProduct key={key}/>
            {note && <NonChanelNote>{note}</NonChanelNote>}
        </div>

    );

    render() {
        const { products, note, isImpersonating, staffQuestions, noteTitle, subSectionNotes, noteTitleArtistReplace, showArtistPicture } = this.props;

        if (!products) {
            return null;
        }

        return (
            <div id='atelier-workplan--makeupcontent'>
                {isImpersonating && <StaffQuestionView staffQuestions={staffQuestions} />}
                <Notes note={note} title={noteTitle} noBorder newBeautyPlan noteTitleArtistReplace={noteTitleArtistReplace} showArtistPicture={showArtistPicture}/>
                <Title>Products used</Title>
                { Object.keys(products).map((subSection, key) => {
                    const subSectionNote = subSectionNotes.find(note =>
                        note.sectionId === subSection &&
                        note.id === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].id);

                    return (
                        <ProductSectionWrapper flexDirection={'column'} key={key}>
                            <StyledHeader fontSize={34} cssMargin={[0]} containNote={!!subSectionNote}>{sectionTitle[subSection]}</StyledHeader>
                            {subSectionNote && <Notes note={subSectionNote.note} title={'Category notes'} noBorder/>}
                            {products[subSection].map((product, key) => {
                                if (product.sku.startsWith(NON_CHANEL)) {
                                    return this.renderNonChanelProduct(key, product.note);
                                }
                                return (<BeautyPlanProductCard isEven={key % 2 === 0} key={key} product={product} />);
                            })}
                        </ProductSectionWrapper>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = ({ beautyplan: { currentPlan: { applicationProducts, notes, staffQuestions, subSectionNotes } }, user: { auth: { isImpersonating } } }) => {

    const sectionNotes = notes ? notes.find(n => n.section === BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].id) : null;
    const staffQuestionsConfig = BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].staffQuestions;

    const normalizedQuestions = staffQuestions.map(staffQuestion => {
        const staffQuestionConfig = staffQuestionsConfig.find(question => question.id === staffQuestion.question);

        if (staffQuestionConfig) {
            return {
                question: staffQuestionConfig.title,
                answer: staffQuestion.answer,
                type: staffQuestionConfig.type,
                allOptions: staffQuestionConfig.questions.map(item => item.value)
            };
        }

        return null;
    }).filter(question => !!question);

    return {
        products: applicationProducts,
        note: sectionNotes ? sectionNotes.note : '',
        noteTitle: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].textSections[0].title,
        noteTitleArtistReplace: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].textSections[0].titleArtistType,
        showArtistPicture: BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_MAKEUP_ROUTINE].textSections[0].showArtistPicture,
        staffQuestions: normalizedQuestions,
        isImpersonating,
        subSectionNotes
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NewMakeupCustomer);
