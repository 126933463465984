import React, { Component } from 'react';
import styled from 'styled-components';
import { rem, styledProps } from 'core/styled';
import { LAYER } from 'constants/layers';
import NoStyleButton from 'components/GlobalComponents/Button/NoStyleButton';

const SkipLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${styledProps('color', 'black')};
    height: ${rem(0)};
    z-index: ${LAYER.HEADER};
    overflow: hidden;
    transition: height 100ms ease-in;

    &:focus-within {
        height: ${rem(23)};
    }

    button {
        padding: ${rem(5, 10)};
        color: ${styledProps('color', 'white')};
        font-family: ${styledProps('font', 'ABChanelPBM')};
        font-size: ${rem(10)};
        font-weight: bold;
        cursor: pointer;
    }
`;

const _handleFocus = (element, role) => () => {
    const e = document.querySelector(`${element}[role=${role}]`);

    if (e) {
        e.focus();
    }
};

export default class SkipLinks extends Component {
    render() {
        return (
            <SkipLinkWrapper>
                <NoStyleButton role={'link'} onClick={_handleFocus('main', 'main')}>MAIN CONTENT</NoStyleButton>
                <NoStyleButton role={'link'} onClick={_handleFocus('header', 'banner')}>MAIN NAVIGATION</NoStyleButton>
            </SkipLinkWrapper>
        );
    }
}
