import moment from 'moment-timezone';

const normalizeBookings = results => {
    return results
        .reduce((accum, booking) => {
            // same id (events or group virtual bookings)
            const index = accum.findIndex(
                normalizedBooking =>
                    normalizedBooking.id === booking.eventID &&
                    normalizedBooking.type === booking.type
            );

            if (index >= 0) {
                // if there is an issue in the order of the group, try to fix that.
                if (!!booking.guestCount && !booking.bookedByGuest) {
                    accum[index].isGroupBooking = true;
                    accum[index].unclaimedTickets =
                        booking.guestCount - booking.rsvpCount;
                }

                // Found booking, add customer data only
                accum[index].customers.push({
                    id: booking.clientID,
                    firstName: booking.clientFirstName,
                    name: booking.clientName
                        ? booking.clientName
                        : `${booking.clientFirstName} ${booking.clientLastName}`,
                    email: booking.clientEmail,
                    shippingAddress: booking.shippingAddress,
                    trackingNumber: booking.trackingNumber,
                    trackingNumber2: booking.trackingNumber2 || null,
                    numberOfTickets: booking.visitIds
                        ? booking.visitIds.length
                        : undefined,
                    phone: booking.clientPhone,
                    staffName: booking.staffName
                        ? booking.staffName
                        : `${booking.staffFirstName} ${booking.staffLastName}`
                });
            } else {
                const inPersonGroupIndex = accum.findIndex(
                    normalizedBooking =>
                        normalizedBooking.type === booking.type &&
                        normalizedBooking.startDateTime ===
                            booking.startDateTime &&
                        normalizedBooking.isGroupBooking
                );

                // in person group appointment
                if (inPersonGroupIndex >= 0) {
                    accum[inPersonGroupIndex].inPersonGroup = true;

                    // only show one entry per customer
                    const alreadyInCustomerList = accum[
                        inPersonGroupIndex
                    ].customers.some(
                        customer => customer.id === booking.clientID
                    );

                    if (!alreadyInCustomerList) {
                        accum[inPersonGroupIndex].customers.push({
                            id: booking.clientID,
                            firstName: booking.clientFirstName,
                            name: booking.clientName
                                ? booking.clientName
                                : `${booking.clientFirstName} ${booking.clientLastName}`,
                            email: booking.clientEmail,
                            shippingAddress: booking.shippingAddress,
                            trackingNumber: booking.trackingNumber,
                            trackingNumber2: booking.trackingNumber2 || null,
                            numberOfTickets: booking.visitIds
                                ? booking.visitIds.length
                                : undefined,
                            phone: booking.clientPhone,
                            staffName: booking.staffName
                                ? booking.staffName
                                : `${booking.staffFirstName} ${booking.staffLastName}`
                        });
                    }
                } else {
                    // New booking, add all data
                    accum.push({
                        id: booking.eventID,
                        bookingType: booking.booking_type,
                        type: booking.type,
                        name: booking.name,
                        startDateTime: booking.startDateTime,
                        staffName: booking.staffName
                            ? booking.staffName
                            : `${booking.staffFirstName} ${booking.staffLastName}`,
                        status: booking.status,
                        classDescriptionId: booking.classDescriptionID,
                        sessionTypeId: booking.sessionTypeId,
                        hasVideo: !!booking.video,
                        videoToken: booking.video
                            ? booking.video.token
                            : undefined,
                        location: booking.location || 1,
                        isGroupBooking: !!booking.guestCount,
                        unclaimedTickets: booking.guestCount
                            ? booking.guestCount - booking.rsvpCount
                            : 0,
                        customers: [
                            {
                                id: booking.clientID,
                                firstName: booking.clientFirstName,
                                name: booking.clientName
                                    ? booking.clientName
                                    : `${booking.clientFirstName} ${booking.clientLastName}`,
                                email: booking.clientEmail,
                                shippingAddress: booking.shippingAddress,
                                trackingNumber: booking.trackingNumber,
                                trackingNumber2:
                                    booking.trackingNumber2 || null,
                                numberOfTickets: booking.visitIds
                                    ? booking.visitIds.length
                                    : undefined,
                                phone: booking.clientPhone,
                                staffName: booking.staffName
                                    ? booking.staffName
                                    : `${booking.staffFirstName} ${booking.staffLastName}`
                            }
                        ]
                    });
                }
            }

            return accum;
        }, [])
        .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)));
};

export default normalizeBookings;
