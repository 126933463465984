import { getPrismicData } from 'reducers/prismicFetch';
import { PRISMIC_DATA_FORMAT } from 'constants/prismic';

/**
 * Takes the state of the fetched Prismic documents and returns specific documents
 * as indicated by the type param. This method is mostly used for moving the
 * Prismic data transformations out of the components.
 * @param {array} prismicState
 * @param {string} type
 * @param {string} subType
 * @returns {any} - Processed data
 */
export default function formatPrismicData(prismicState, type, subType = null) {
    const documents = getPrismicData(prismicState);
    switch (type) {
        case PRISMIC_DATA_FORMAT.FOOTER:
            // There should only be one footer so we can locate by document type
            const footer = documents.find(i => i.type === 'footer');
            let footerData = null;
            if (footer) {
                const footerLinks = footer.data.links.map(linkData => {
                    return { text: linkData.link_text[0].text, url: linkData.link_or_path, newWindow: linkData.open_in_new_window === 'true' };
                });
                const miniFooterLinks = footer.data.footer_mini_links ? footer.data.footer_mini_links.map(linkData => {
                    return { text: linkData.link_text, url: linkData.link_or_path, newWindow: linkData.open_in_new_window === 'true' };
                }) : null;

                // Only set the state of a mounted component, otherwise Promise is sometimes resolved after component is unmounted
                footerData = {
                    address: footer.data.address,
                    addressText: footer.data.address_text,
                    chanelHomeUrl: footer.data.chanelhome.url,
                    logoUrl: '',
                    links: footerLinks,
                    miniLinks: miniFooterLinks
                };
            }
            return footerData;

        case PRISMIC_DATA_FORMAT.MAKEUP_ARTISTS:
            const makeupArtistPages = documents.filter(document => document.type === 'makeup_artist' && document.data.artist_type === subType);

            return makeupArtistPages.map(makeupArtistPage => {
                const { data } = makeupArtistPage;

                const productData = data.products ? data.products.map((product) => {
                    return {
                        skuId: product.sku_id && product.sku_id.length > 0 ? product.sku_id[0].text : null,
                        quoteStart: product.quote_start && product.quote_start.length > 0 ? product.quote_start[0].text : null,
                        quoteEnd: product.quote_ending && product.quote_ending.length > 0 ? product.quote_ending[0].text : null
                    };
                }) : [];

                return {
                    id: makeupArtistPage.uid,
                    staffid: data.mindbody_staffid,
                    artistType: data.artist_type,
                    image: data.image ? data.image.url : null,
                    imageWide: data.artist_image_wide ? data.artist_image_wide.url : null,
                    imageSuperWide: data.artist_image_super_wide ? data.artist_image_super_wide.url : null,
                    imageTouchScreen: data.artist_image_touch_screen ? data.artist_image_touch_screen.url : null,
                    imagePosition: data.artist_image_position ? data.artist_image_position : null,
                    polaroid: data.polaroid ? data.polaroid.url : null,
                    waitroomPicture: data.waitroom_picture ? data.waitroom_picture.url : null,
                    name: data.name && data.name.length ? data.name[0].text : null,
                    bio: data.bio && data.bio.length ? data.bio[0].text : null,
                    philosophy: data.philosophy && data.philosophy.length ? data.philosophy[0].text : null,
                    expertise: data.expertise && data.expertise.length ? data.expertise[0].text : null,
                    passion: data.passion && data.passion.length ? data.passion[0].text : null,
                    admire: data.admire && data.admire.length ? data.admire[0].text : null,
                    productData,
                    portfolioQuote: data.portfolio_quote && data.portfolio_quote.length ? data.portfolio_quote[0].text : null,
                    portfolioImages: data.portfolio_images ? data.portfolio_images.map(imageData => imageData.portfolio_image.url) : null,
                    portfolioImagesWide: data.portfolio_images_wide ? data.portfolio_images_wide.map(imageData => imageData.portfolio_image_wide.url) : null,
                    portfolioImagesSuperWide: data.portfolio_images_super_wide ? data.portfolio_images_super_wide.map(imageData => imageData.portfolio_image_super_wide.url) : null,
                    chatIcon: data.video_chat_image ? data.video_chat_image.url : null
                };
            });

        default:
            return null;
    }
}
