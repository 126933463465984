import { createAction, handleActions } from 'redux-actions';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_ERROR, FETCH_PENDING, FETCH_RESET } from 'middlewares/fetch';
import { normalizeBeautyPlan } from 'core/utils/normalizeBeautyPlan';
import moment from 'moment';

const BEAUTY_PLANS = 'BEAUTYPLANS';
const foundBeautyplans = createAction('BEAUTYPLANS_FOUND');

const getBeautyPlans = createAction(FETCH, externalId => ({
    prefix: BEAUTY_PLANS,
    endpoint: `${CONFIG.API_URL}/profiles/${externalId}/modules/beautyplans`
}));

export const fetchBeautyPlans = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        return dispatch(getBeautyPlans(externalId));
    };
};

const staffBeautyPlans = createAction(FETCH, () => ({
    prefix: BEAUTY_PLANS,
    endpoint: `${CONFIG.API_URL}/beautyplans/staff`
}));


const initialState = {
    isFetching: false,
    error: null,
    response: null
};

export const fetchProfileBeautyPlans = () => {
    return (dispatch, getState) => {
        return dispatch(fetchBeautyPlans()).then((beautyplans) => {
            const { makeupArtists: { artistData } } = getState();
            dispatch(foundBeautyplans(beautyplans.map(normalizeBeautyPlan(artistData)).filter(bp => bp)));
        });
    };
};

export const fetchRecentBeautyPlans = () => {
    return (dispatch, getState) => {
        return dispatch(staffBeautyPlans()).then((beautyplans) => {
            const { makeupArtists: { artistData } } = getState();
            dispatch(foundBeautyplans(beautyplans.map(normalizeBeautyPlan(artistData)).filter(bp => bp)));
        });
    };
};

/**
 * Create fetch based reducer
 * @param {string} prefix - Fetch actions prefix
 * @return {object} reducer
 */
export default handleActions(
    {
        [`${BEAUTY_PLANS}/${FETCH_PENDING}`]: state => ({
            ...state,
            ...initialState,
            isFetching: true
        }),
        [foundBeautyplans]: (state, { payload }) => ({
            ...state,
            ...initialState,
            response: payload.sort((a, b) => moment(a.createdDateTime).diff(moment(b.createdDateTime)))
        }),
        [`${BEAUTY_PLANS}/${FETCH_ERROR}`]: (state, { payload }) => ({
            ...state,
            ...initialState,
            error: payload
        }),
        [FETCH_RESET]: () => initialState
    },
    initialState
);

