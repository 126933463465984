const validationMessages = {
    FORGOT_PASSWORD: {
        400: 'Sorry, that does not match an email address in our system, please try again.',
        500: 'Whoops! That doesn’t seem right. Please check your email and try again.'
    },
    invalidCharacters: (fieldName) => `Please enter a valid ${fieldName}`,
    default: 'Invalid entry. Try again.',
    invalidFormat: 'Please match the requested format.',
    invalidEmailFormat: 'Please enter a valid email address in the following format: abc@def.gh',
    emailsDoNotMatch: 'The Email Addresses do not match, please try again',
    invalidPassword: 'Please enter a valid password. It must contain at least 8 characters and these 4 character types: uppercase, lowercase, numbers and special characters (@$!%*?&)',
};

export default validationMessages;
