import { combineActions, createAction, handleActions } from 'redux-actions';
import moment from 'moment-timezone';
import { CONFIG } from 'constants/config';
import { FETCH, FETCH_RESET } from 'middlewares/fetch';
import { FETCH_ERROR, FETCH_PENDING } from 'middlewares/fetch';
import normalizeAppointment, {
    normalizeStaffAppointment,
    sortByStartDateDescending
} from 'core/utils/normalizeAppointment';
import { queryBeautyPlans } from 'reducers/beautyplan';
import { setAnalyticsAppointmentData } from 'reducers/checkIn';
import { normalizeBeautyPlan } from 'core/utils/normalizeBeautyPlan';
import { setAttendedAppointments } from 'reducers/user';

// Date query format
const DATE_FORMAT = 'YYYY-MM-DD';

export const MY_APPOINTMENTS = 'MY_APPOINTMENTS';
export const STAFF_APPOINTMENTS = 'STAFF_APPOINTMENTS';
const MY_APPOINTMENTS_READY = 'MY_APPOINTMENTS_READY';
const ANALYTICS_APPOINTMENTS = 'ANALYTICS_APPOINTMENTS';
const RESET_MY_APPOINTMENTS = 'RESET_MY_APPOINTMENTS';

const myAppointmentsReady = createAction(MY_APPOINTMENTS_READY);
const allMyAppointments = createAction('ALL_MY_APPOINTMENTS');
const appointmentDetails = createAction('APPOINTMENT_DETAILS');
export const resetMyAppointments = createAction(RESET_MY_APPOINTMENTS);

const fetchAppointments = createAction(FETCH, ({ externalId, startDate, endDate, sessionTypeIds, staffIds, prefix = MY_APPOINTMENTS, withGuests }) => ({
    prefix,
    endpoint: `${CONFIG.API_URL}/scheduling/appointments/customer?customerId=${externalId}` +
              `&startDate=${startDate}&endDate=${endDate}${sessionTypeIds ? `&sessionTypeIds=${sessionTypeIds.join(',')}` : ''}` +
              `${staffIds ? `&staffIds=${staffIds.join(',')}` : ''}` +
              `${withGuests ? '&guestAppointments=true' : ''}`
}));

const fetchStaffAppointments = createAction(FETCH, ({ startDate, endDate, location }) => ({
    prefix: STAFF_APPOINTMENTS,
    endpoint: `${CONFIG.API_URL}/scheduling/appointments/staff?startDate=${startDate}&endDate=${endDate}${location ? `&location=${location}` : ''}`
}));

const fetchAppointmentDetailsById = createAction(FETCH, (id, bookerId) => ({
    prefix: MY_APPOINTMENTS,
    endpoint: `${CONFIG.API_URL}/scheduling/appointments/${id}${bookerId ? `?bookerId=${bookerId}` : ''}`
}));

/**
 * Trigger request to fetch appointments for customer
 * @param {string} customerId
 * @param {boolean} withGuests
 * @return {function(*, *): *} - fetch
 */
export const fetchCustomerAppointments = (customerId = null, withGuests = false) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId } } } } = getState();
        const startDate = moment().format(DATE_FORMAT);
        const endDate = moment().add(1, 'y').format(DATE_FORMAT);
        return dispatch(fetchAppointments({ externalId: customerId || externalId, startDate, endDate, withGuests }))
            .then(response => {
                const { makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();
                const allArtistData = [...artistData, ...residencyArtistData, ...virtualArtistData];

                const appointments = response.map(normalizeAppointment(allArtistData)).sort(sortByStartDateDescending).filter(a => !a.isPast);
                dispatch(myAppointmentsReady(appointments));
                dispatch(setAnalyticsAppointmentData(appointments));
            });
    };
};

/**
 * Fetch user appointments
 * @param {boolean} withGuests - retrieve also bookings where customer is a guest
 * @return {function(*, *): *} - fetch
 */
export const fetchMyAppointments = (withGuests = false) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { isStaff, isImpersonating }, staffLocation } }, makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();
        const today = moment().format(DATE_FORMAT);
        if (isStaff && !isImpersonating) {
            return dispatch(fetchStaffAppointments({ startDate: today, endDate: today, location: staffLocation })) // fetch for current day only
                .then(response => {
                    const allArtistData = [...artistData, ...residencyArtistData, ...virtualArtistData];
                    dispatch(myAppointmentsReady(response.map(normalizeStaffAppointment(allArtistData, staffLocation)).sort(sortByStartDateDescending).filter(a => !a.isPast))); // filter out past appointments
                });
        } else {
            return dispatch(fetchCustomerAppointments(null, withGuests));
        }
    };
};

export const fetchAppointmentDetails = (appointmentId, bookerId) => {
    return (dispatch, getState) => {
        return dispatch(fetchAppointmentDetailsById(appointmentId, bookerId)).then(appointment => {
            const { user: { auth: { profile: { externalId } } }, makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();
            const allArtistData = [...artistData, ...residencyArtistData, ...virtualArtistData];

            const normalizedAppointment = normalizeAppointment(allArtistData)(appointment);

            dispatch(queryBeautyPlans(externalId, appointmentId)).then(beautyPlan => {
                dispatch(appointmentDetails({ ...normalizedAppointment , beautyPlan: normalizeBeautyPlan(null)(beautyPlan) }));
            });

            return normalizedAppointment;
        });
    };
};

export const fetchAllAppointments = (withGuests = false) => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId }, isStaff, isImpersonating, staffLocation } }, makeupArtists: { artistData, residencyArtistData, virtualArtistData } } = getState();
        const startDate = moment().subtract(6, 'months').format(DATE_FORMAT);
        const endDate = moment().add(3, 'months').endOf('month').format(DATE_FORMAT);
        const today = moment().tz('America/New_York').format('YYYY-MM-DD');
        const allArtistData = [...artistData, ...residencyArtistData, ...virtualArtistData];

        if (isStaff && !isImpersonating) {
            return dispatch(fetchStaffAppointments({ startDate: today, endDate: today })).then(response => {
                dispatch(allMyAppointments(response.map(normalizeStaffAppointment(allArtistData, staffLocation)).sort(sortByStartDateDescending)));
            });
        } else {
            return dispatch(fetchAppointments({ externalId, startDate, endDate, withGuests })).then(response => {
                return dispatch(allMyAppointments(response.sort().map(normalizeAppointment(allArtistData))));
            });
        }

    };
};

export const getAttendedAppointments = () => {
    return (dispatch, getState) => {
        const { user: { auth: { profile: { externalId }, isStaff, isImpersonating } } } = getState();

        if (!isStaff || (isStaff && isImpersonating)) {
            const startDate = '2019-01-01';
            const endDate = moment().format('YYYY-MM-DD');
            dispatch(fetchAppointments({ externalId, startDate, endDate, prefix: ANALYTICS_APPOINTMENTS }))
                .then(response => {
                    const attendedAppointment = response.filter(appointment => appointment.Status === 'Completed').length;
                    dispatch(setAttendedAppointments(attendedAppointment));
                });
        }
    };
};

const initialState = {
    isFetching: false,
    error: null,
    response: null,
    allAppointments: [],
    appointmentDetails: []
};

export default handleActions({
    [combineActions(`${MY_APPOINTMENTS}/${FETCH_PENDING}`,
        `${STAFF_APPOINTMENTS}/${FETCH_PENDING}`)]: (state) => ({
        ...initialState,
        ...state,
        isFetching: true
    }),
    [`${MY_APPOINTMENTS}/${FETCH_ERROR}`]: (state, { payload }) => ({
        ...initialState,
        ...state,
        isFetching: false,
        error: payload
    }),
    [myAppointmentsReady]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: null,
        response: payload
    }),
    [allMyAppointments]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        allAppointments: payload
    }),
    [appointmentDetails]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        appointmentDetails: [...state.appointmentDetails.filter(a => a.id !== payload.id), payload]
    }),
    [FETCH_RESET]: (state) => ({
        ...initialState,
        appointmentDetails: state.appointmentDetails, // don't reset appointment details
        allAppointments: state.allAppointments // don't reset all appointments
    }),
    [resetMyAppointments]: state => ({
        ...state,
        initialState
    })
}, initialState);
