import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    BEAUTYPLAN_CONFIG,
    BEAUTYPLAN_SEARCH_TYPE,
    BEAUTYPLAN_SECTIONS,
    BEAUTYPLAN_SKINCARE_PRODUCT_ORDER
} from 'constants/beautyplan';
import { searchProducts } from 'reducers/elasticsearch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubSection from 'components/BeautyPlans/Staff/SubSection';
import NewTextSection from 'components/BeautyPlans/Staff/NewTextSection';
import { fetchProductsByWorkplanCategories } from 'reducers/beautyplan';
import StaffQuestions from 'components/BeautyPlans/Staff/StaffQuestions';

const Container = styled.div`
  width: 100%;
`;

class NewSkincare extends Component {

    static propTypes = {
        stepData: PropTypes.array,
        searchProducts: PropTypes.func.isRequired,
        fetchProductsByWorkplanCategories: PropTypes.func.isRequired
    };

    state = {
        categoriesData: [],
        selectedProducts: []
    };

    stepConfig = BEAUTYPLAN_CONFIG[BEAUTYPLAN_SECTIONS.NEW_SKINCARE_ROUTINE];

    componentDidMount() {
        const subSections = this.stepConfig.categories.map(category => category.subSections);
        const productSections = [...subSections[0], ...subSections[1]];
        const categoriesData = BEAUTYPLAN_SKINCARE_PRODUCT_ORDER.map(category => {
            return { ...category, ...productSections.find(section => section.category === category.category) };
        });

        this.setState({
            categoriesData
        });

    }

    constructor(props) {
        super(props);
        const workplanCategories = this.stepConfig.categories.map(c => ({ routine: c.id, sections: c.subSections.filter(s => s.type === BEAUTYPLAN_SEARCH_TYPE.LIST) }))
            .map(({ routine, sections }) => ({ routine, categories: sections.map(s => s.category) }));

        props.fetchProductsByWorkplanCategories(workplanCategories);
    }

    /**
     * Render the free-form text areas for the step.
     *
     * @returns {object[]} - Rendered text sections
     */
    renderTextSections = () => {

        return this.stepConfig.textSections.map((section, key) => {
            return (
                <NewTextSection sectionId={this.stepConfig.id} section={section} key={key} />
            );
        });
    };

    /**
     * Render sections for current routing
     * @param {string} routineType
     * @returns {component} React component
     */
    renderRoutine() {
        return (
            <div>
                {this.state.categoriesData.map((category, key) => {
                    return (
                        <SubSection id={this.stepConfig.id}
                            category={category}
                            subSection={category}
                            key={key}
                            first={key === 0}
                            last={key === this.state.categoriesData.length - 1}
                        />
                    );
                })
                }
            </div>
        );
    }

    renderStaffFacingQuestions = () => {
        const staffQuestions = this.stepConfig.staffQuestions;

        if (!staffQuestions || !staffQuestions.length) {
            return;
        }

        return staffQuestions.map((question, key) => {
            return (
                <StaffQuestions
                    question={question}
                    key={key}
                />
            );
        });
    };

    render() {
        return (
            <Container>
                { this.renderStaffFacingQuestions() }
                { this.renderTextSections() }
                { this.renderRoutine() }
            </Container>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ searchProducts, fetchProductsByWorkplanCategories }, dispatch);

export default connect(null, mapDispatchToProps)(NewSkincare);
