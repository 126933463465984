import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageMobileHeader from 'components/PageMobileHeader/PageMobileHeader';
import FooterNav from 'components/FooterNav/FooterNav';
import Footer from 'components/Footer/Footer';
import styled, { createGlobalStyle, css } from 'styled-components';
import { connect } from 'react-redux';
import { breakpoints } from 'constants/theme';
import { greaterThan, lessThan } from 'core/styled';
import { resetLayout, setHeaderColor, setBackgroundColor, setStaffTheme, invertHeaderIcons, setHeaderIcons, setHideFooter, setCartOpen } from 'reducers/globalLayout';
import { bindActionCreators } from 'redux';
import { push } from 'redux-first-history';
import FormPageLayout from 'pages/layouts/FormPageLayout';
import { styledProps, rem } from 'core/styled';
import { LAYER } from 'constants/layers';
import formatPrismicData from 'core/utils/formatPrismicData';
import Loader from 'components/GlobalComponents/Loader/Loader';
import { PRISMIC_DATA_FORMAT } from 'constants/prismic';
import { endImpersonation } from 'reducers/user';
import ChromosomeTitle from 'components/GlobalComponents/Fonts/ChromosomeTitle';
import Header from 'components/GlobalComponents/Fonts/Header';
import Flex from 'components/GlobalComponents/FlexBox/Flex';
import HamburgerMenu, { MENU_WIDTH } from 'components/HamburgerMenu/HamburgerMenu';
import { analyticsTag } from 'reducers/analytics';
import Icon from 'components/GlobalComponents/Icons/Icon';
import { ICON_TYPES } from 'components/GlobalComponents/Icons/IconVariants';
import { ROUTES } from 'constants/routes';
import { withRouter } from 'react-router-dom';
import { logoutUser } from 'reducers/logout';
import ServerErrorContainer from 'containers/ServerErrorContainer/ServerErrorContainer';
import CartMenu from 'components/CartMenu/CartMenu';
import QuickShopFooter from 'components/QuickShopApp/QuickShopFooter';
import QuickShopHeader from 'components/QuickShopApp/QuickShopHeader';
import { PrivateEventBotStyle } from 'components/Discover/Discover.styled';

const HAMBURGER_MENU_OVERLAY_ID = 'hamburger-menu-overlay';

const AppWrapper = styled.div``;

const HamburgerMenuWrapper = styled.div`
    outline: none;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: ${MENU_WIDTH.DEFAULT}px;
    height: 100vh;
    -webkit-overflow-scrolling: touch;
    border: none;
    z-index: ${({ showHamburgerMenu }) => showHamburgerMenu ? LAYER.HAMBURGER_MENU : 0};
    visibility: ${({ showHamburgerMenu }) => showHamburgerMenu ? 'visible' : 'hidden'};


    left: ${({ showHamburgerMenu }) => showHamburgerMenu ? '0' : rem(-MENU_WIDTH.DEFAULT)};
    transition: ${({ closingMenu }) => closingMenu ? 'left 0.3s ease-in, z-index 0s 0.3s, visibility 0s 0.3s' : 'left 0.3s ease-out, z-index 0s, visibility 0s'};
    
    ${greaterThan(breakpoints.medium)(css`
        width: ${MENU_WIDTH.LARGE}px;
        left: ${({ showHamburgerMenu }) => showHamburgerMenu ? '0' : rem(-MENU_WIDTH.LARGE)};
    `)};
    
    ${lessThan(breakpoints.tiny)(css`
        width: ${MENU_WIDTH.TINY}px;
        left: ${({ showHamburgerMenu }) => showHamburgerMenu ? '0' : rem(-MENU_WIDTH.TINY)};
    `)};
`;

const CartMenuWrapper = styled.div`
    outline: none;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    -webkit-overflow-scrolling: touch;
    border: none;
    z-index: ${({ showHamburgerMenu }) => showHamburgerMenu ? LAYER.HAMBURGER_MENU : 0};
    visibility: ${({ showHamburgerMenu }) => showHamburgerMenu ? 'visible' : 'hidden'};

    right: ${({ showHamburgerMenu }) => showHamburgerMenu ? '0' : '-100%'};
    transition: ${({ closingMenu }) => closingMenu ? 'right 0.3s ease-in, z-index 0s 0.3s, visibility 0s 0.3s' : 'right 0.3s ease-out, z-index 0s, visibility 0s'};

    ${greaterThan(rem(400))(css`
        width: ${MENU_WIDTH.LARGE}px;
        right: ${({ showHamburgerMenu }) => showHamburgerMenu ? '0' : rem(-MENU_WIDTH.LARGE)};
    `)};
`;

const MenuOverlay = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    opacity: ${({ showMenu }) => showMenu ? '0.5' : '0'};
    z-index: ${({ showMenu }) => showMenu ? LAYER.HAMBURGER_MENU_OVERLAY : -1};
    transition: ${({ closingMenu }) => closingMenu ? 'opacity 0.7s, z-index 0s 0.7s' : 'opacity 0.7s, z-index 0s'};
`;

const LayoutOuter = styled.div`
  height: ${({ isFragrance }) => isFragrance ? '100vh' : 'auto'};
  position: ${({ disableScroll, showMenu, preventModalOpenScrollToTop }) => (disableScroll && !preventModalOpenScrollToTop) || showMenu ? 'fixed' : 'relative'};
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: left 0.4s linear;
  overflow: ${({ disableScroll }) => disableScroll ? 'hidden' : 'visible'};
`;

const ContentWrapper = styled.main`
    position: relative;
    width: 100vw;
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding-top: ${({ overlayHeader, isImpersonating }) => isImpersonating ? overlayHeader ? rem(140) : rem(200) : overlayHeader ? '0' : styledProps('global', 'headerHeight')};
    z-index: ${LAYER.BODY};
    min-height: 100%;
    overflow: ${({ disableScroll }) => disableScroll ? 'hidden' : 'visible'};
    
    ${greaterThan(breakpoints.small)(css`
        padding-top: ${({ overlayHeader, isImpersonating }) => isImpersonating ? overlayHeader ? rem(140) : rem(220) : overlayHeader ? '0' : styledProps('global', 'desktopHeaderHeight')};
    `)};
    
    ${greaterThan(breakpoints.medium)(css`
        width: 100%;
    `)};
`;

const EmptyFooter = styled.div`
    height: 50px;
    width: 100%;
    background: ${styledProps('color', 'black')};
`;

const StyledHeader = styled(Header)`
    position: relative;
    display: inline-block;
    width: fit-content;
    padding: ${rem(0, 45, 10, 45)};
`;

const StopImpersonationIcon = styled.div`
    position: absolute;
    right: 6vw;
    top: 50%;
    transform: translateY(-50%);
`;

const ImpersonationContainer = styled(Flex)`
    background-color: ${styledProps('color', 'black')};
    top: 0;
    position: fixed;
    z-index: ${LAYER.IMPERSONATION_HEADER};
    color: ${styledProps('color', 'white')};
    text-align: center;
    margin: 0;
    padding: 0 0 ${rem(10)} 0;
    transition: all 0.2s ease;
    height: 140px;
    min-height: auto;
    width: 100%;
`;

const StyledChromosomeTitle = styled(ChromosomeTitle)`
    display: block;
`;

const AdaTitle = styled.p`
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
`;

export const HideCookieLawStyle = createGlobalStyle`
    #onetrust-banner-sdk {
        display: none;
    }
`;

class LayoutContainer extends Component {
    static propTypes = {
        children: PropTypes.any.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        resetLayout: PropTypes.func.isRequired,
        setHeaderColor: PropTypes.func.isRequired,
        setBackgroundColor: PropTypes.func.isRequired,
        endImpersonation: PropTypes.func.isRequired,
        isAtelierReady: PropTypes.bool.isRequired,
        disableScroll: PropTypes.bool.isRequired,
        preventModalOpenScrollToTop: PropTypes.bool.isRequired,
        auth: PropTypes.object.isRequired,
        setStaffTheme: PropTypes.func.isRequired,
        invertHeaderIcons: PropTypes.func.isRequired,
        setHeaderIcons: PropTypes.func.isRequired,
        analyticsTag: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        logoutUser: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
        isForm: PropTypes.bool,
        showHeader: PropTypes.bool,
        showFooter: PropTypes.bool,
        headerColor: PropTypes.string,
        headerInvertedIconsColor: PropTypes.bool,
        pageBackgroundColor: PropTypes.string,
        overlayHeader: PropTypes.bool,
        footerData: PropTypes.object,
        showHeaderLogo: PropTypes.bool,
        showFullLogo: PropTypes.bool,
        isMobileKeyboardOpen: PropTypes.bool.isRequired,
        headerIcons: PropTypes.string,
        disableHeaderShrink: PropTypes.bool,
        isImpersonating: PropTypes.bool,
        headerBackground: PropTypes.string,
        showEmptyFooter: PropTypes.bool,
        isFragrance: PropTypes.bool,
        hideFooter: PropTypes.bool.isRequired,
        setHideFooter: PropTypes.func.isRequired,
        automaticSearchExpansion: PropTypes.bool,
        isHeaderShrunk: PropTypes.bool,
        workshopServerError: PropTypes.bool.isRequired,
        hideNavFooter: PropTypes.bool,
        ariaPageName: PropTypes.string,
        webView: PropTypes.bool.isRequired,
        cartOpen: PropTypes.bool.isRequired,
        setCartOpen: PropTypes.func.isRequired,
        showQuickShopHeader: PropTypes.oneOf(['collapsed', 'expanded']),
        showQuickShopFooter: PropTypes.bool
    };

    static defaultProps = {
        showHeader: true,
        showFooter: true,
        overlayHeader: false,
        showHeaderLogo: true,
        pageBackgroundColor: '#ffffff',
        showFullLogo: false,
        headerInvertedIconsColor: false,
        disableHeaderShrink: false,
        showEmptyFooter: true,
        automaticSearchExpansion: false,
        hideNavFooter: false
    };

    state = {
        headerData: {},
        showHamburgerMenu: false,
        showCartMenu: false,
        closingMenu: false,
        closingCart: false
    };

    adaTitle = React.createRef();
    adaLoader = React.createRef();

    componentDidMount() {
        // If staff, then set staff theme
        const { auth, setHideFooter, showFooter } =  this.props;

        // update header color, if needed
        if (this.props.headerColor) {
            this.props.setHeaderColor(this.props.headerColor);
        }

        // If staff, then set staff theme
        if (auth.isStaff && !auth.isImpersonating) {
            this.props.setStaffTheme();
        }

        // invert header icons, if needed
        if (this.props.headerInvertedIconsColor) {
            this.props.invertHeaderIcons();
        }

        // invert header icons, if needed
        if (this.props.headerIcons) {
            this.props.setHeaderIcons(this.props.headerIcons);
        }

        // update page background color, if needed
        if (this.props.pageBackgroundColor) {
            this.props.setBackgroundColor(this.props.pageBackgroundColor);
        }

        if (this.props.isAtelierReady && this.props.ariaPageName && this.adaTitle.current) {
            this.adaTitle.current.focus();
        }

        if (!this.props.isAtelierReady && this.adaLoader.current) {
            this.adaLoader.current.focus();
        }

        setHideFooter(!showFooter);

    }

    /**
     * Calls prevent default on event param.
     * @param {object} e
     */
    preventDefault = (e) => {
        e.preventDefault();
    };

    /**
     * Add event listeners to hamburger overlay items to prevent background scrolling.
     */
    preventHamburgerBackgroundScroll = () => {
        // iOS has a bug with fixed position elements. This detects the hamburger overlay and prevents touch events
        if (!this.overlayFixed) {
            const hamburgerOverlay = document.getElementById(`${HAMBURGER_MENU_OVERLAY_ID}`);
            if (hamburgerOverlay) {
                hamburgerOverlay.addEventListener('touchstart', this.preventDefault, { passive: true });
                this.overlayFixed = true;
            }
        }

        if (!this.closeIconFixed) {
            const modalCloseIcon = document.getElementById('hamburgerCloseIcon');
            if (modalCloseIcon) {
                modalCloseIcon.addEventListener('touchmove', this.preventDefault, { passive: true });
                this.closeIconFixed = true;
            }
        }
    };

    /**
     * Remove event listeners from hamburger overlay items.
     */
    removeHamburgerBackgroundScrollListeners = () => {
        const modalCloseIcon = document.getElementById('hamburgerCloseIcon');
        const hamburgerOverlay = document.getElementById(`${HAMBURGER_MENU_OVERLAY_ID}`);

        if (hamburgerOverlay) {
            hamburgerOverlay.removeEventListener('touchstart', this.preventDefault, false);
        }

        if (modalCloseIcon) {
            modalCloseIcon.removeEventListener('touchmove', this.preventDefault, false);
        }
    };

    /**
     * Resets the body element style to be able to scroll after this component is unmounted
     */
    resetBodyStyle = () => {
        document.body.style.overflow = 'visible';
        document.body.style.height = 'auto';
        document.body.style.webkitOverflowScrolling = 'touch';
        document.body.style.overflowY = 'scroll';
        document.body.style.position = null;
    };

    componentWillUnmount() {
        this.removeHamburgerBackgroundScrollListeners();
        this.resetBodyStyle();
        this.props.resetLayout();
    }

    componentDidUpdate(prevProps, prevState) {
        const { auth: { isImpersonating }, headerColor, headerBackground, disableScroll, preventModalOpenScrollToTop, isAtelierReady, ariaPageName, cartOpen, showFooter, setHideFooter } = this.props;
        const { showHamburgerMenu, showCartMenu } = this.state;

        this.preventHamburgerBackgroundScroll();

        // if switching from impersonation to regular staff mode, need to update the theme
        if (isImpersonating !== prevProps.auth.isImpersonating) {
            if (isImpersonating) {
                this.props.resetLayout();
                // if page header color is pre defined set it
                if (headerColor !== headerBackground) {
                    this.props.setHeaderColor(headerColor);
                }
            } else {
                this.props.setStaffTheme();
            }
        }

        // If disabling scroll or toggling hamburger menu, then adjust body element styles
        if (prevProps.disableScroll !== disableScroll || prevState.showHamburgerMenu !== showHamburgerMenu || prevState.showCartMenu !== showCartMenu) {
            // set overflow state of body to prevent whole page scroll
            // set height so that overflow property is respected
            document.body.style.overflow = disableScroll || showHamburgerMenu || showCartMenu ? 'hidden' : 'visible';
            document.body.style.height = disableScroll || showHamburgerMenu || showCartMenu ? '100vh' : 'auto';
            document.body.style.webkitOverflowScrolling = disableScroll ? 'hidden' : 'touch';
            document.body.style.overflowY = disableScroll ? 'hidden' : 'scroll';
            document.body.style.position = disableScroll && !preventModalOpenScrollToTop ? 'fixed' : null;
        }

        if (!prevProps.isAtelierReady && isAtelierReady) {
            if (ariaPageName && this.adaTitle.current) {
                this.adaTitle.current.focus();
            }
        }

        if (!isAtelierReady && document.activeElement.id !== 'ada--loader' && this.adaLoader && this.adaLoader.current) {
            this.adaLoader.current.focus();
        }

        if (!prevProps.cartOpen && cartOpen) {
            this.toggleCart();
        }

        if (prevProps.showFooter !== showFooter) {
            setHideFooter(!showFooter);
        }
    }

    handleOverlayClick = () => {
        const { showCartMenu, showHamburgerMenu } = this.state;

        if (showCartMenu) {
            this.toggleCart();
        }

        if (showHamburgerMenu) {
            this.toggleHamburger();
        }
    };

    /**
     * render impersonation header banner
     * @return {component} - react component
     */
    _renderImpersonation = () => {
        const { auth, endImpersonation } = this.props;

        if (auth.authenticated && auth.isImpersonating) {
            return (
                <ImpersonationContainer flexDirection={'column'} justifyContent={'flex-end'} alignItems={'center'}>
                    <StyledChromosomeTitle fontColor={'white'}>
                        BROWSING AS…
                    </StyledChromosomeTitle>
                    <StyledHeader fontSize={26} cssColor={'white'}>
                        <span translate={'no'} className={'notranslate'}>
                            {auth.profile.firstName} {auth.profile.lastName}
                        </span>
                    </StyledHeader>
                    <StopImpersonationIcon onClick={endImpersonation}>
                        <Icon type={ICON_TYPES.STYLED_CLOSE} variant={'dark'} pixelHeight={27} />
                    </StopImpersonationIcon>
                </ImpersonationContainer>
            );
        }

        return null;
    };

    /**
     * Disable touch move to prevent scrolling of div on iOS (which apparently doesn't respect overflow: hidden like everyone else does)
     * Some more details on that here: https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
     * @param {object} e
     */
    disableTouchMove = (e) => {
        if (e.target.id === HAMBURGER_MENU_OVERLAY_ID) {
            e.preventDefault();
        }
    };

    /**
     * Toggles the menu.
     */
    toggleHamburger = () => {
        const showHamburgerMenu = !this.state.showHamburgerMenu;
        const { disableScroll }  = this.props;

        // toggle touchmove event listner to prevent body scroll when menu is open
        if (showHamburgerMenu) {
            document.body.addEventListener('touchmove', this.disableTouchMove, { passive: true });
        } else {
            document.body.removeEventListener('touchmove', this.disableTouchMove, false);
            document.body.style.overflow = disableScroll || showHamburgerMenu ? 'hidden' : 'visible';
        }

        this.setState({
            showHamburgerMenu,
            closingMenu: !showHamburgerMenu
        }, () => {
            if (this.state.closingMenu) {
                setTimeout(() => this.setState({ closingMenu: false }), 300);
            }
        });
    };

    /**
     * Toggles the cart.
     */
    toggleCart = () => {
        const showCartMenu = !this.state.showCartMenu;
        const { disableScroll, setCartOpen }  = this.props;

        // toggle touchmove event listner to prevent body scroll when menu is open
        if (showCartMenu) {
            document.body.addEventListener('touchmove', this.disableTouchMove, { passive: true });
        } else {
            document.body.removeEventListener('touchmove', this.disableTouchMove, false);
            document.body.style.overflow = disableScroll || showCartMenu ? 'hidden' : 'visible';
            setCartOpen(false);
        }

        this.setState({
            showCartMenu,
            closingCart: !showCartMenu
        }, () => {
            if (this.state.closingCart) {
                setTimeout(() => this.setState({ closingCart: false }), 300);
            }
        });
    };

    navigateToLink = (url) => {
        const { push } = this.props;
        this.toggleHamburger();
        push(url);
    };

    logout = () => {
        const { logoutUser } = this.props;
        this.toggleHamburger();
        logoutUser();
    };

    /**
     * Returns true if logic dictates the impersonation banner should be hidden.
     * @returns {boolean} - whether or not to hide impersonation banner
     */
    shouldHideImpersonation = () => {
        const { location } = this.props;

        switch (true) {
            case location.pathname.startsWith(ROUTES.BEAUTY_PLAN):
            case location.pathname.startsWith(ROUTES.FRAGRANCE):
            case location.pathname.startsWith(ROUTES.FRAGRANCE_QUESTIONNAIRE) && !location.pathname.startsWith(ROUTES.FRAGRANCE_QUESTIONNAIRE_STAFF):
            case location.pathname.startsWith(ROUTES.MEMBERSHIP_FORM):
            case location.pathname.startsWith(ROUTES.MEMBERSHIP_CONFIG):
                return true;
            default:
                return false;
        }
    };

    _renderADATitle = ariaPageName => {
        return (
            <AdaTitle tabIndex={'-1'} ref={this.adaTitle}>{ariaPageName} Page - Atelier Chanel Beauté</AdaTitle>
        );
    };

    render() {
        const {
            showHeader,
            showHeaderLogo,
            isForm,
            overlayHeader,
            analyticsTag,
            backgroundColor,
            footerData,
            isAtelierReady,
            disableScroll,
            preventModalOpenScrollToTop,
            showFullLogo,
            isMobileKeyboardOpen,
            disableHeaderShrink,
            auth,
            showEmptyFooter,
            isFragrance,
            hideFooter,
            automaticSearchExpansion,
            workshopServerError,
            hideNavFooter,
            ariaPageName,
            webView,
            showQuickShopHeader,
            showQuickShopFooter,
            location
        } = this.props;
        const { showHamburgerMenu, closingMenu, showCartMenu, closingCart } = this.state;
        const hideImpersonation = this.shouldHideImpersonation();

        // webview related
        const nonFragranceWebView = webView && !isFragrance;
        const displayHeader = !nonFragranceWebView && showHeader && !showQuickShopHeader;
        const showFooter = !nonFragranceWebView && !hideFooter;
        const showFooterNav = !nonFragranceWebView && !hideNavFooter && (auth.authenticated && !isFragrance) && !auth.isStaff;
        const displayEmptyFooter = !nonFragranceWebView && showEmptyFooter;
        const displayHeaderLogo = !webView && showHeaderLogo;

        // TODO: consolidate all layouts into this one, can be addressed when VX is available
        if (isForm) {
            return <FormPageLayout>{this.props.children}</FormPageLayout>;
        }

        if (isAtelierReady) {
            //TODO: Events page has something strange happening which causes the footer cut edges offset to not work as expected on mobile. Below isEventsPage var is a hack, but it works!
            return (
                <AppWrapper showHamburgerMenu={showHamburgerMenu} isFragrance={isFragrance} disableScroll={disableScroll} disableMobileScroll={isMobileKeyboardOpen}>
                    {ariaPageName && this._renderADATitle(ariaPageName)}
                    <MenuOverlay showMenu={showHamburgerMenu || showCartMenu} onMouseUp={this.handleOverlayClick} id={HAMBURGER_MENU_OVERLAY_ID} closingMenu={closingMenu || closingCart} />
                    <HamburgerMenuWrapper showHamburgerMenu={showHamburgerMenu} closingMenu={closingMenu}>
                        <HamburgerMenu toggleHamburger={this.toggleHamburger} navigate={this.navigateToLink} logout={this.logout} showHamburgerMenu={showHamburgerMenu}/>
                    </HamburgerMenuWrapper>

                    <CartMenuWrapper showHamburgerMenu={showCartMenu} closingMenu={closingCart}>
                        <CartMenu toggleCart={this.toggleCart} navigate={this.navigateToLink} logout={this.logout} showCartMenu={showCartMenu} closingCart={closingCart}/>
                    </CartMenuWrapper>

                    <LayoutOuter backgroundColor={backgroundColor} showMenu={showHamburgerMenu || showCartMenu} disableScroll={disableScroll} preventModalOpenScrollToTop={preventModalOpenScrollToTop} isFragrance={isFragrance}>
                        {!hideImpersonation && this._renderImpersonation()}
                        {displayHeader && <PageMobileHeader noImpersonation={hideImpersonation} showLogo={displayHeaderLogo} toggleHamburger={this.toggleHamburger} showFullLogo={showFullLogo} disableHeaderShrink={disableHeaderShrink} automaticSearchExpansion={automaticSearchExpansion} showHamburgerMenu={showHamburgerMenu} toggleCart={this.toggleCart} showCartMenu={showCartMenu} />}
                        {!!showQuickShopHeader && <QuickShopHeader state={showQuickShopHeader} />}
                        <ContentWrapper backgroundColor={backgroundColor} isImpersonating={auth.isImpersonating && !hideImpersonation} overlayHeader={overlayHeader || nonFragranceWebView} showFooter={showFooter} disableScroll={disableScroll} aria-hidden={showHamburgerMenu ? 'true' : null} role={'main'} tabIndex={-1}>
                            {this.props.children}
                        </ContentWrapper>


                        {showFooter && footerData &&
                        <Footer
                            address={footerData.address}
                            addressText={footerData.addressText}
                            chanelHomeUrl={footerData.chanelHomeUrl}
                            logoUrl={footerData.logoUrl}
                            links={footerData.links}
                            miniLinks={footerData.miniLinks}
                            analyticsTag={analyticsTag}
                        />}
                        {!showFooter && displayEmptyFooter && <EmptyFooter/>}
                    </LayoutOuter>
                    {showFooterNav && <FooterNav shrink={this.props.isHeaderShrunk} />}
                    {showQuickShopFooter && <QuickShopFooter location={location} />}
                    {webView && <HideCookieLawStyle/>}
                    <PrivateEventBotStyle />
                </AppWrapper>
            );
        }

        // Api error trying to load the application
        if (workshopServerError) {
            return (
                <div>
                    <AppWrapper showHamburgerMenu={showHamburgerMenu} isFragrance={isFragrance} disableScroll={disableScroll} disableMobileScroll={isMobileKeyboardOpen}>
                        {displayHeader && <PageMobileHeader noImpersonation={hideImpersonation} showLogo={displayHeaderLogo} toggleHamburger={this.toggleHamburger} showFullLogo={showFullLogo} disableHeaderShrink={disableHeaderShrink} workshopServerError={true} showHamburgerMenu={showHamburgerMenu} toggleCart={this.toggleCart} showCartMenu={showCartMenu}/>}
                        <ServerErrorContainer/>
                    </AppWrapper>
                    {webView && <HideCookieLawStyle/>}
                </div>
            );
        }

        // Per design, render header even while app is loading
        return (
            <div>
                <AppWrapper showHamburgerMenu={showHamburgerMenu} isFragrance={isFragrance} disableScroll={disableScroll} disableMobileScroll={isMobileKeyboardOpen}>
                    {displayHeader && <PageMobileHeader noImpersonation={hideImpersonation} showLogo={displayHeaderLogo} toggleHamburger={this.toggleHamburger} showFullLogo={showFullLogo} disableHeaderShrink={disableHeaderShrink} showHamburgerMenu={showHamburgerMenu}  toggleCart={this.toggleCart} showCartMenu={showCartMenu}/>}
                    <p id={'ada--loader'} className={'sr-only'} tabIndex={'-1'} ref={this.adaLoader}>Loading in progress</p>
                    <Loader fullScreen/>
                </AppWrapper>
                {webView && <HideCookieLawStyle/>}
            </div>
        );
    }
}

const mapStateToProps = ({ globalLayout: { headerBackground, backgroundColor, disableScroll, preventModalOpenScrollToTop, isHeaderStaffTheme, isMobileKeyboardOpen, isFragrance, hideFooter, webView, cartOpen }, prismic, user: { auth }, workshop: { workshopServerError } }, { headerInvertedIconsColor }) => ({
    auth,
    headerBackground,
    backgroundColor,
    disableScroll,
    preventModalOpenScrollToTop,
    hideFooter,
    headerInvertedIconsColor: headerInvertedIconsColor || isHeaderStaffTheme,
    footerData: prismic.isAtelierReady ? formatPrismicData(prismic, PRISMIC_DATA_FORMAT.FOOTER) : {},
    isAtelierReady: prismic.isAtelierReady,
    isMobileKeyboardOpen,
    isFragrance,
    workshopServerError,
    webView,
    cartOpen
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { push, resetLayout, setHeaderColor, setBackgroundColor, endImpersonation, setStaffTheme, invertHeaderIcons, setHeaderIcons, analyticsTag, logoutUser, setHideFooter, setCartOpen },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
    LayoutContainer
));

