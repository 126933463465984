import { CONFIG } from 'constants/config';
import { API_ENDPOINT_TYPES } from 'constants/apiEndpoints';

const DEFAULT_WORKSHOP_PATH_SEGMENT = 'soho';

/**
 *
 * @param {string} path
 * @param {object} pathSegment
 * @returns {string} url
 */
const addWorkshopContext = (path, pathSegment) => {
    return path.replace('_WORKSHOP_PATH_SEGMENT_', pathSegment);
};

/**
 * Get the workshop context from localStorage
 *
 * @returns {object} context
 */
const getWorkshopData = () => {
    const workshop = JSON.parse(localStorage['persist:workshop']);
    return JSON.parse(workshop.workshopData);
};

/**
 * Detects the workshop context and returns workshop specific API urls.
 *
 * @param {string} type
 * @param {string} endpoint
 * @returns {string} url
 */
export default function generateContextualApiUrl(type, endpoint) {
    const workshop = getWorkshopData();
    const pathSegment = workshop.pathSegment || DEFAULT_WORKSHOP_PATH_SEGMENT;
    switch (type) {
        case API_ENDPOINT_TYPES.PRODUCT_SEARCH:
            switch (endpoint) {
                case 'MGET':
                    return `${CONFIG.API_URL}/products/${pathSegment}/mget`;
                default:
                    return addWorkshopContext(CONFIG.ELASTIC_SEARCH_QUERY_URL, pathSegment);
            }
        default:
            return '';
    }
}
