import React, { Component } from 'react';
import { Flex } from 'components/GlobalComponents/FlexBox/index';
import { rem } from 'core/styled';
import styled  from 'styled-components';
import NonChanelProductImage from 'assets/beautyplan/your-current-product.svg';

const NonChanelContainer = styled(Flex)`
    position: relative;
    margin-top: ${rem(50)};
    min-height: auto;
`;

export default class NonChanelProduct extends Component {

    render() {
        return (
            <NonChanelContainer alignItems='center' justifyContent='center'>
                <img src={NonChanelProductImage} alt='Non Chanel Product'/>
            </NonChanelContainer>
        );
    }

}
